
import { conformToMask } from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export interface DollarAmountInputRef {
  focus: (str_value?: string) => void;
  init: (str_value?: string, unfocus?:boolean) => void;
  clear: () => void;
  getText: () => string;
  updateInputWidth: () => void;
}
export const dollarAmountNumberMask = createNumberMask({
  prefix: '',
  suffix: '',
  allowDecimal: false,
  decimalSymbol: '.',
  decimalLimit: 2,
  allowNegative: false,
  allowLeadingZeroes: false,
});

export const parseInputValue = (valueProp?: number | null) => {
  const conformedPhoneNumber = conformToMask(
    valueProp === 0 ? '' : valueProp?.toString() || '',
    dollarAmountNumberMask,
    { guide: false }
  );
  const newValue = conformedPhoneNumber.conformedValue;
  return newValue;
};
export const parseStrInputValue = (valueProp?: string | null) => {
  const conformedPhoneNumber = conformToMask(
    valueProp || '',
    dollarAmountNumberMask,
    { guide: false }
  );
  const newValue = conformedPhoneNumber.conformedValue;
  return newValue;
};

export const parseOutputValue = (value: string) => {
  return parseFloat((+(value?.replaceAll('_', '')?.replaceAll('$', '')?.replaceAll(',', '') ?? '')).toFixed(2));
};
