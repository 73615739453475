import Button from 'components/Button';
import Text from 'components/Text';
import { ICategoryItemData } from 'services/shop/types/categories';
import styled from 'styled-components';
import ArrangeCateModal from '../../ArrangeCateModal';
import AddCateButton from './AddCateButton';
import ConfigCategories from './ConfigCategories';
import { IEditCateBodyType } from 'features/settingService/services/types/api';
import clsx from 'clsx';
import service_delete_icon from '../../../v2/icons/service-delete-icon.svg';
import service_edit_icon from '../../../v2/icons/service-edit-icon.svg';
import ModalEditName, { useModalEditNameRef } from '../ModalEditName';
import RequestAdminPWCallback, { useRequestAdminPWCallbackRef } from 'widgets/RequestAdminLocalPW/Callback';
type Props = {
  v2?: boolean;
  data: ICategoryItemData[];
  activeCate: ICategoryItemData | null;
  onChooseCate: (activeCate: ICategoryItemData) => void;
  onAddCategory: (body: IEditCateBodyType) => void;
  onEditNameCate: (cateId: string, value: string) => void;
  onDeleteCategory: (cateId: string) => void;
};
const CategoryPart = ({ activeCate, data = [], onChooseCate, onAddCategory, v2, onEditNameCate, onDeleteCategory }: Props) => {
  const modalEditNameRef = useModalEditNameRef();
  const pwRef = useRequestAdminPWCallbackRef();

  const handleEditNameCate = () => {
    modalEditNameRef.current?.open(activeCate?.categoryName || '');
  };
  const handleDeleteCategory = () => {
    if (!activeCate) return;
    pwRef.current?.open(() => onDeleteCategory(activeCate.id));
  };

  if (v2) {
    return (
      <Container className='v2'>
        <CategoriesListStyled>
          <CategoriesListOverflow>
            <div className="content-scroll">
              {data.map(o => (
                <CategoryButtonV2
                  key={o.id}
                  type='button'
                  className={clsx(activeCate?.id === o?.id && 'active')}
                  onClick={() => onChooseCate(o)}
                >
                  {activeCate?.id === o?.id ?
                    <div className="active-wrapper">
                      <div className='category-item-wrap'>
                        <Text className='cate-name'>{o.categoryName || ''}</Text>
                        <Text className='cate-services'>{o.services.length || 0} items</Text>
                      </div>
                      {activeCate.id !== 'add-on' && <div className="action-container">
                        <button type='button' onClick={handleDeleteCategory} className="btn"><img src={service_delete_icon} alt="service_delete_icon" style={{ width: 32, height: 32 }} /></button>
                        <button type='button' onClick={handleEditNameCate} className="btn btn-edit"><img src={service_edit_icon} alt="service_edit_icon" style={{ width: 32, height: 32 }} /></button>
                      </div>}
                    </div>
                    :
                    <div className='category-item-wrap'>
                      <Text className='cate-name'>{o.categoryName || ''}</Text>
                      <Text className='cate-services'>{o.services.length || 0} items</Text>
                    </div>}
                </CategoryButtonV2>
              ))}
            </div>
          </CategoriesListOverflow>
        </CategoriesListStyled>
        <AddCateButton onAddCategory={onAddCategory} />
        <Text mb={1} />
        <div className="title-div">
          <ConfigCategories v2 />
          <ArrangeCateModal v2 block height={3} icon='sortService' title='Pre-Order' />
        </div>
        <RequestAdminPWCallback v2 ref={pwRef} onlyAdmin />
        <ModalEditName v2 ref={modalEditNameRef} onSubmit={(value) => onEditNameCate(activeCate?.id || '', value)} />
      </Container>
    );
  }

  return (
    <Container>
      <div className="title-div">
        <ConfigCategories />
        <ArrangeCateModal block height={3} icon='sortService' title='Pre-Order' />
      </div>
      <CategoriesListStyled>
        <AddCateButton onAddCategory={onAddCategory} />
        <CategoriesListOverflow>
          <div className="content-scroll">
            {data.map(o => (
              <Button
                key={o.id}
                ntype={activeCate?.id === o?.id ? 'LIGHT_PRIMARY' : 'GREY'} className='category-item'
                onClick={() => onChooseCate(o)}
              >
                <div className='category-item-wrap'>
                  <Text className='cate-name'>{o.categoryName || ''}</Text>
                  <Text className='cate-services'>{o.services.length || 0} items</Text>
                </div>
              </Button>
            ))}
          </div>
        </CategoriesListOverflow>
      </CategoriesListStyled>
    </Container>
  );
};

export default CategoryPart;

const CategoryButtonV2 = styled.button`
  display: flex;
  height: 77px;
  padding: 8px 16px;
  align-items: center;
  align-self: stretch;
  border: 1px solid #E9EDF2;
  background: #FCFCFD;
  box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;

  .category-item-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;

    .cate-name {
      color: #1D2129;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .cate-services {
      color: #505050;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }


  &.active {
    border: 1px solid #2D6772;
    background: #232F3E;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;

    .active-wrapper {
      display: flex;
      flex: 1;
      align-items: center;
      .category-item-wrap {
        flex-direction: column;
        align-items: start;
        .cate-name {
          color: #FFF;
        }
        .cate-services {
          color: #CBD5E1;
        }
      }

      .action-container {
        display: flex;
        align-items: center;
        gap: 16px;

        button.btn {
          display: flex;
          width: 40px;
          height: 40px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 4px;
          background: #FFF1F6;

          &.btn-edit {
            border-radius: 4px;
            background: #C6E9EF;
          }
        }
      }
    }
  }
`;

const CategoriesListOverflow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
  position: relative;
  z-index: 1;
  .content-scroll {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox 64 */
    &::-webkit-scrollbar {
      width: 0px;
      height: 8px;
      background-color: #F5F5F5;
    }
  }
`;

const CategoriesListStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
  .category-item {
    box-shadow: none;
    
    .CONTENT_2 {
      flex: 1;
    }
    .category-item-wrap {
      display: flex;
      flex: 1;
      align-items: center;
      .cate-name {
        flex: 1;
        text-align: left;
        color: #1D2129;
        font-family: "Open Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px; /* 166.667% */
        text-transform: uppercase;
      }

      .cate-services {
        color: #767676;
        font-family: "Open Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
`;

const Container = styled.div`
  width: 21rem;
  display: flex;
  flex-direction: column;
  .title-div {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    gap: 16px;
    .title-text {
      flex: 1;
      color: #1D2129;
      font-family: "Open Sans";
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  &.v2 {
    width: 22rem;
    background: #fff;
    padding: 16px 16px;

    .content-scroll {
      gap: 8px;
    }
  }
`;