import Box from 'components/Box';
import Form, { useForm } from 'components/Form';
import Modal from 'components/Modal';
import CurrencyKeyBoard, { CurrencyKeyBoardRef } from 'components/NumberKeyBoard/Currency';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import { useTicketWrapper } from 'features/ShopWrapperLayout/TicketWrapperContext';
import cashierSelectors from 'features/cashier/services/selectors';
import ConfirmTipTicket, { useConfirmTipTicketRef } from 'features/payment/components/ConfirmTipTicket';
import PaymentLoading, { IPaymentLoadingRef } from 'features/payment/components/PaymentLoading';
import CountingFooter from 'features/payment/pages/PaymentPage/CashierSide/components/LoyaltyPoint/CountingFooter';
import multiplePaymentActions from 'features/payment/pages/PaymentPage/services/actions';
import paymentApis from 'features/payment/services/apis';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import { IBodyAPIPayment } from 'features/payment/services/types/api';
import { useSocketContext } from 'hooks/useSocket';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IResponseDataBody } from 'services/response';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import storage from 'utils/sessionStorage';
import CashierActionButton from '../CashierActionBtn';
import useGetDetailTicket from '../useGetDetailTicket';
import useUpdateTicket from '../useUpdateTicket';
import { InputWithKeyboardStyled, useDollarAmountInputRef } from 'components/DollarAmount/Input';
import DollarAmountKeyboard from 'components/DollarAmount/Keyboard';
import { cashierBtnMultiPayV3 } from './ButtonMultiPayV3';
type Props = {
  v2?: boolean;
};
const ButtonLoyaltyPoint = ({ v2 }: Props) => {
  const [form] = useForm();
  const currencyRef = useRef<CurrencyKeyBoardRef>(null);
  const dollarRef = useDollarAmountInputRef();
  const loadingRef = useRef<IPaymentLoadingRef>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const updateTicket = useUpdateTicket();
  const loyaltySetting = shopSelectors.data.getLoyaltySetting();
  const ticketContext = useTicketWrapper();
  const socketContext = useSocketContext();
  const completedTicketModalRef = useConfirmTipTicketRef();
  const allSetting = shopSelectors.data.allSetting();

  const customer = cashierSelectors.getSelectedCustomer();
  const [visible, setVisible] = useState(false);
  const [detailTicket, getDetailTicket] = useGetDetailTicket();

  const [amount, setAmount] = useState<number | null>(null);

  const exchangeDollar = (point: number) => {
    if (!loyaltySetting) return 0;
    const val = +(point) / loyaltySetting?.oneDollarToPoint;
    return val;
  };

  const _amount = amount || 0;
  const redeemPoint = Math.round(loyaltySetting ? (+_amount) * (loyaltySetting?.oneDollarToPoint || 0) : 0);
  const balancePoint = +(customer?.point || '') - redeemPoint;

  const onLoyalty = async () => {
    try {
      const rest: { billId: string } | null = await updateTicket();
      if (!rest) return;

      const billId = rest.billId;
      const detail = await getDetailTicket(billId);
      if (!detail) return;
      const availablePointEx = exchangeDollar(customer?.point || 0);
      const amountVal = availablePointEx - detail?.total < 0 ? availablePointEx : detail?.total;
      socketContext.switchCusPayment(billId);
      setTimeout(() => {
        currencyRef.current?.setValue(amountVal?.toFixed(2));
        dollarRef.current?.init(amountVal.toString());
      }, 100);
      setVisible(true);
    } catch (error) { }
  };

  const onClose = () => {
    form.resetFields();
    socketContext.backToCheckIn();
    setVisible(false);
  };

  const handleSubmit = async () => {
    if (!detailTicket) return;
    onClose();
    const loyaltyPointVal = {
      amount: _amount,
      point: redeemPoint,
    };
    if (_amount < detailTicket.total) {
      cashierBtnMultiPayV3.current?.continueLoyaltyPayWithMultiPay(detailTicket.billId, loyaltyPointVal);
      return;
    }

    const body: IBodyAPIPayment = {
      billId: detailTicket.billId,
      paymentInfo: [
        {
          paymentType: PAYMENT_TYPE.LOYALTY_POINT,
          amount: _amount,
          point: redeemPoint,
        },
      ]
    };

    loadingRef.current?.setVisible(true);
    try {
      const res: IResponseDataBody<true> = await paymentApis.payment(body);
      if (res?.data?.data) {
        loadingRef.current?.setVisible(false);
        socketContext.switchCusRating(detailTicket.billId);

        const callback = () => ticketContext.completedTicket(detailTicket?.billId, true);
        if (!completedTicketModalRef.current) callback();
        else completedTicketModalRef.current.open(detailTicket.billId, callback);

      } else {
        loadingRef.current?.setVisible(false);
      }
    } catch (error) {
      loadingRef.current?.setVisible(false);
    }
  };

  const BalancePoint = () => {
    if (!loyaltySetting) return <>{('0 point = $0')}</>;
    if (balancePoint < 0) {
      return <>0 Point = $0</>;
    }
    return (
      <>
        {balancePoint || 0} point = {formatCurrency(exchangeDollar(+(balancePoint || '')))}
      </>
    );
  };

  const AvailablePoint = () => {
    return (
      <>
        {customer?.point || 0} point = {formatCurrency(exchangeDollar(+(customer?.point || '')))}
      </>
    );
  };

  const FooterContent = () => {
    return <ContainerAmountLoyaltyPoint>
      <div className="amount-item">
        <div className="amount-label">Available:</div>
        <div className="amount-value"><AvailablePoint /></div>
      </div>
      <div className="amount-item">
        <div className="amount-label">Balance:</div>
        <div className="amount-value"><BalancePoint /></div>
      </div>
    </ContainerAmountLoyaltyPoint>;
  };

  if (!loyaltySetting?.active) return null;

  return (
    <div style={{ alignSelf: 'stretch', display: 'flex' }}>
      <CashierActionButton v2UI={v2} onClick={onLoyalty} disabled={!customer?.point} feature='LOYALTY' ntype='YELLOW' label='Points' />
      {v2 ?
        <Modal
          visible={visible}
          modalTitle='POINTS'
          showClose
          okTitle={'Redeem'}
          noneFooterStyle
          className='modal-overflow-unset modal-non-opacity'
          containerPadding={0}
          disableOk={!(_amount > 0 && !(balancePoint < 0))}
          onClose={onClose}
          onSubmit={handleSubmit}
          v2
          noneBodyStyle
          width={'1000px'}
        >
          {
            allSetting?.activeFormatMoney ?
              <Box display='flex' flexDirection='column' gap='2'>
                <Text className='modal-keyboard-label' mt={1} mb={-0.5}>
                  Enter Amount:
                </Text>
                <InputWithKeyboardStyled
                  ref={dollarRef}
                  value={amount}
                  onChange={setAmount}
                  inputMode='none'
                  autoFocus
                />
                <FooterContent />
                <DollarAmountKeyboard
                  controlRef={dollarRef}
                  value={amount}
                  onChange={setAmount}
                />
              </Box>
              : <>
                <CurrencyKeyBoard v2
                  ref={currencyRef}
                  value={amount} onChange={val => setAmount(val || 0)}
                  FooterContent={FooterContent}
                />
              </>
          }
        </Modal>
        :
        <Modal
          // width="auto"
          footerBgColor={colorTheme.fill_5}
          headerBgColor={colorTheme.info_bg_3}
          visible={visible}
          onClose={onClose}
          onSubmit={onClose}
          modalTitle="POINTS"
          width={800}
          noneBodyStyle
          footer={
            <CountingFooter
              submitButtonTitle="Redeem"
              isValid={_amount > 0 && !(balancePoint < 0)}
              onSubmit={handleSubmit}
              handleCloseModal={onClose}
              style={{ padding: '1.5rem', paddingTop: '1rem' }}
            >
              <Box className="center">
                <Box display="flex" alignItems="center" gap="4">
                  <Text variant="CONTENT_1" color="text_3" style={{ whiteSpace: 'nowrap' }}>
                    Balance point:
                  </Text>
                  <Text variant="H5" color="text_3" whiteSpace='nowrap' >
                    <BalancePoint />
                  </Text>
                </Box>
              </Box>
              <Text mb={1} />
            </CountingFooter>
          }
        >
          <BodyFormStyled form={form} initialValues={{ amount: '$' }}>
            <Box>
              <Box className="space-between" mb="5">
                <Text variant="CONTENT_1" color="text_3">
                  Available Point:
                </Text>
                <Text variant="H9" color="text_3">
                  <AvailablePoint />
                </Text>
              </Box>
              <Text variant="H6" color="text_3">
                Redeem
              </Text>
              <CurrencyKeyBoard ref={currencyRef} value={amount} onChange={val => setAmount(val || 0)} />
            </Box>
          </BodyFormStyled>
        </Modal>}
      <PaymentLoading ref={loadingRef} />
      <ConfirmTipTicket ref={completedTicketModalRef} />
    </div>
  );
};

export default ButtonLoyaltyPoint;
const BodyFormStyled = styled(Form)`
padding: 1rem 3rem;
`;

export const ContainerAmountLoyaltyPoint = styled.div`
display: flex;
height: 49px;
justify-content: center;
align-items: center;
gap: 32px;
align-self: stretch;
background: #F5F5F5;
margin: 0 32px;
.amount-item {
  display: flex;
  align-items: center;
  gap: 6px;
  .amount-label {
    color: #1D2129;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .amount-value {
    color: #1D2129;
    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
`;