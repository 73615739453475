import { Col, Form, InputRef, Row, Select } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import CurrencyInputPrecision from 'components/CurrencyInputPrecision';
import PercentDecimalInputPrecision from 'components/PercentDecimalInputPrecision';
import Text from 'components/Text';
import settingServiceActions from 'features/settingService/services/actions';
import { DISCOUNT_VALUE_TYPE } from 'features/settingService/services/constants';
import React, { useEffect, useRef } from 'react';
import shopSelectors from 'services/shop/selectors';
import { IDiscountSettingData } from 'services/shop/types/discount';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import SwitchBtn from '../../ServicesSettingV2/SwitchBtn';
import ActionButtons from './ActionButtons';
type IDiscountPercentageProps = {
  v2?: boolean;
};
const DiscountPercentage: React.FC<IDiscountPercentageProps> = ({ v2 }) => {
  const dispatch = useAppDispatch();
  const discountStore = shopSelectors.data.discountSetting();
  const [form] = Form.useForm<IDiscountSettingData>();
  const inputPerRef = useRef<InputRef>(null);
  const inputDolRef = useRef<InputRef>(null);
  const handleChangeType = (e: DISCOUNT_VALUE_TYPE) => {
    setTimeout(() => {
      if (e === DISCOUNT_VALUE_TYPE.DOLLAR) {
        inputDolRef.current?.focus();
      } else {
        inputPerRef.current?.focus();
      }
    }, 100);
  };
  const onFinish = (values: IDiscountSettingData) => {
    dispatch(settingServiceActions.postDiscount.fetch(values));
  };

  const handleStaffChange = (val: number | null, targetName: string) => {
    form.setFieldsValue({
      [targetName]: 100 - (val || 0),
    });
  };

  useEffect(() => {
    if (discountStore) {
      form.setFieldsValue(discountStore);
    }
  }, [discountStore]);

  return (
    <DiscountPercentageStyled>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Box bgColor="fill_1" p="3" borderRadius="5px">
          <Text variant="H9" color="text_3" className='section-title'>
            Discount Percent
          </Text>
          <BoxDiscountPercent>
            <Row gutter={16} align={'bottom'}>
              <Col span={7}>
                <Form.Item name="discountStaff" label="Staff">
                  <PercentDecimalInputPrecision maxValue={100} onChange={(val) => handleStaffChange(val, 'discountOwner')} />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item name="discountOwner" label="Company">
                  <PercentDecimalInputPrecision maxValue={100} onChange={(val) => handleStaffChange(val, 'discountStaff')} />
                </Form.Item>
              </Col>
              <Col span={10}>
                <div className='ant-col ant-form-item-label label-cash-incentive'>
                  <label style={{ marginRight: '8px' }}>Cash Incentive</label>
                  <Form.Item noStyle name={'enableCashIncentive'}>
                    <SwitchBtn />
                  </Form.Item>
                </div>
                <DiscountWrapper >
                  <Form.Item noStyle name={'discountType'}>
                    <Select placeholder='%' options={[
                      { label: 'Percent', value: DISCOUNT_VALUE_TYPE.PERCENT },
                      { label: 'Money', value: DISCOUNT_VALUE_TYPE.DOLLAR },
                    ]}
                      onChange={handleChangeType}
                      style={v2 ? { width: '15rem' } : {}}
                    />
                  </Form.Item>
                  <Form.Item shouldUpdate noStyle>
                    {({ getFieldValue }) => {
                      const discountType = getFieldValue('discountType');
                      return (
                        <Form.Item noStyle name={'discountValue'}>
                          {(discountType === DISCOUNT_VALUE_TYPE.PERCENT) ?
                            <PercentDecimalInputPrecision maxValue={100} inputRef={inputPerRef} height={'3.5rem'} />
                            : <CurrencyInputPrecision className='CurrencyInputPrecision' inputRef={inputDolRef} height={'3.5rem'} />}
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </DiscountWrapper>
              </Col>
            </Row>
          </BoxDiscountPercent>
          {v2 ? <ActionButtons /> : <Box display="flex" gap="3" mt="4" justifyContent="end">
            <Button width="10rem" ntype="SECONDARY" htmlType="reset">
              Reset
            </Button>
            <Form.Item>
              <Button width="10rem" ntype="PRIMARY" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Box>}
        </Box>
      </Form>
    </DiscountPercentageStyled>
  );
};

export default DiscountPercentage;

const BoxDiscountPercent = styled(Box)`
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-col.ant-form-item-label {
    height: 32px;
    display: flex;
    align-items: center;
  }
  .label-cash-incentive {
    display: flex;
    align-items: center;
  }
  .ant-input {
    text-align: right;
    font-size: 20px !important;
  }
`;

const DiscountPercentageStyled = styled.div`
  background: var(--fill-fill-3, #e5e5e5);
  border-radius: 5px;
  flex-grow: 1;
`;

const DiscountWrapper = styled.div`
display: flex;
align-items: center;
.ant-select {
  height: 3.5rem;
  width: 10rem;
}
`;
