import { ButtonProps } from 'antd';
import { typeIcon } from 'assets/Icons';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { SortableList } from 'features/settingService/components/SortableList';
import settingApis from 'features/settingService/services/apis';
import { useState } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import shopActions from 'services/shop/actions';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import toast from 'utils/toast';
import sort_check_in_icon from './sort-check-in.svg';

interface Props extends Omit<ButtonProps, 'icon'> {
  icon?: typeIcon,
  title?: string;
  height?: number;
  v2?: boolean;
}

const ArrangeCateModal = ({ v2, icon, title, height, ...btnProps }: Props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const categories = shopSelectors.data.categories();
  const setLoading = useSetLoadingPage();
  const [items, setItems] = useState<{ id: string, name: string, totalServices: number }[]>([]);
  const handleOpen = () => {
    setItems(categories.map(o => ({ id: o.id, name: o.categoryName, totalServices: o.services.length })));
    setOpen(true);
  };

  const handleAccept = async () => {
    setOpen(false);

    setLoading(true);
    const ids = items.map(o => o.id);
    try {
      const res: IResponseDataBody<boolean> = await settingApis.sortCategories(ids);
      if (res.data.data) {
        toast.success('Reorder successful');
        dispatch(shopActions.getNewCategories.fetch());
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  };

  return (
    <>
      {v2 ? <ButtonV2Styled onClick={handleOpen}>
        <img src={sort_check_in_icon} alt="sort_check_in_icon" style={{ width: 80, height: 80 }} />
        <Text className='label'>Pre-Order</Text>
      </ButtonV2Styled> :
        <Button size='small' icon={icon || 'arrange'} ntype='LIGHT_BLUE' height={height} {...btnProps} onClick={handleOpen}>{title || 'Reorder'}</Button>
      }

      <Modal
        visible={open}
        onClose={() => setOpen(false)}
        onSubmit={handleAccept}
        modalTitle={title || 'Reorder'}
        v2={v2}
        width={v2 ? 880 : undefined}
        noneBodyStyle={v2}
        containerPadding={v2 ? 1 : undefined}
      >
        <SortableList
          items={items}
          onChange={setItems}
          renderItem={(item) => (
            <SortableList.Item id={item.id}>
              <CateWrap>
                <Text variant="H7" color="text_3">
                  {item.name}
                </Text>
                <Text variant="CONTENT_1" color="text_2">
                  {item.totalServices} Services
                </Text>
              </CateWrap>
            </SortableList.Item>
          )}
        />
      </Modal>
    </>
  );
};

export default ArrangeCateModal;
const ButtonV2Styled = styled.button`
display: flex;
padding: 16px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 2px;
flex: 1 0 0;
align-self: stretch;
background: #FCFCFD;
box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
.label {
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
`;
const CateWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  .label {
    font-size: 18px;
    font-weight: 600;
  }
`;