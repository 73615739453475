import { Form, Input } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Button from 'components/Button';
import MultipleSelect from 'components/MultipleSelect';
import Text from 'components/Text';
import customerActions from 'features/customer/services/actions';
import {
  optionsPoint,
  optionsSpendingAmount,
  optionsStatus,
} from 'features/customer/services/constants';
import customerSelectors, {
  getPointByKey,
  getSpendingAmountByKey,
} from 'features/customer/services/selectors';
import { IGetCustomersParam } from 'features/customer/services/types/api';
import { debounce } from 'lodash';
import React, { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import ButtonAddCustomer from 'widgets/Customer/AddCustomer';
import SelectFromToOptions from './SelectFromToOptions';

export interface IFormSearchValues {
  spendingAmount: string;
  point: string;
  status: string[];
  lastPhoneNumber: string;
}

type ICustomersHeaderProps = {
  v2?: boolean;
};
// type ChangeDisplayTypeIconProps = {
//   disabled?: boolean;
// };
const CustomersHeader: React.FC<ICustomersHeaderProps> = ({ v2 }) => {
  const totalElement = customerSelectors.customersTableData.totalElement();
  const dispatch = useAppDispatch();
  // const isWindows = customerSelectors.getIsWindowDisplay();
  const paramStatus = customerSelectors.paramStatus();
  // const handleChangeDisplayType = () => {
  //   dispatch(customerActions.handleChangeDisplayType());
  // };

  const handleChangeSpendingAmount = (value: string) => {
    const getData: IGetCustomersParam = getSpendingAmountByKey(value);
    dispatch(customerActions.setCustomerParam(getData));
  };
  const handleChangePoint = (value: string) => {
    const getData: IGetCustomersParam = getPointByKey(value);
    dispatch(customerActions.setCustomerParam(getData));
  };
  const handleChangeStatus = (value: string[]) => {
    const param: IGetCustomersParam = { types: value.join(',') };
    dispatch(customerActions.setCustomerParam(param));
  };

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      const param: IGetCustomersParam = { lastPhoneNumber: value };
      dispatch(customerActions.setCustomerParam(param));
    }, 300),
    []
  );

  const handleChangeLastNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    debouncedSearch(newValue || '');
  };

  const handleFinishForm = (values: any) => {
    const data = values as IFormSearchValues;
    const spendingAmount: IGetCustomersParam = getSpendingAmountByKey(data.spendingAmount);
    const point: IGetCustomersParam = getPointByKey(data.point);
    const status: IGetCustomersParam = { types: data.status?.join(',') };
    const searchText = { lastPhoneNumber: data.lastPhoneNumber };
    const params = {
      ...spendingAmount,
      ...point,
      ...status,
      ...searchText,
    };
    dispatch(customerActions.setCustomerParam(params));
  };

  if (v2) {
    return (
      <CustomersHeaderStyled className={'v2'}>
        <Box display="flex" gap="2" alignItems="center">
          <Text className='total-text' variant="H5" style={{ flex: 1, flexGrow: 1 }}>
            Customers ({totalElement})
          </Text>
          <Form onFinish={handleFinishForm} style={{ display: 'flex', alignItems: 'center', flex: 1, gap: 16, justifyContent: 'flex-end' }}>
            <Box width={'15rem'}>
              <Form.Item noStyle name={'spendingAmount'}>
                <SelectFromToOptions placeholder="Spending Amount" options={optionsSpendingAmount} />
              </Form.Item>
            </Box>
            <Box width={'15rem'}>
              <Form.Item noStyle name={'point'}>
                <SelectFromToOptions placeholder="Point" options={optionsPoint} />
              </Form.Item>
            </Box>
            <Box width={'15rem'}>
              <Form.Item noStyle name={'status'}>
                <MultipleSelect placeholder="Status" width="100%" height="3.5rem" options={optionsStatus} />
              </Form.Item>
            </Box>
            <Box width={'20rem'}>
              <Form.Item noStyle name={'lastPhoneNumber'}>
                <InputSearch placeholder="Last 4 number / Name" size="middle" suffix={<Icon className="search-icon" type="search" />} />
              </Form.Item>
            </Box>
            <Button htmlType='submit' cardCashier ntype='PRIMARY' ><span style={{ color: '#fff' }}>Search</span></Button>
            <Box className="center" gap="3">
              <ButtonAddCustomer v2 model="new-ticket" />
            </Box>
          </Form>
        </Box>
      </CustomersHeaderStyled>
    );
  }

  return (
    <CustomersHeaderStyled>
      <Box display="flex" gap="2" alignItems="center" pb={'4'} px={'4'}>
        <Text className='total-text' variant="H5" style={{ flex: 1, flexGrow: 1 }}>
          Customers ({totalElement})
        </Text>
        <Box width={'13.5rem'}>
          <SelectFromToOptions
            placeholder="Spending Amount"
            onChange={handleChangeSpendingAmount}
            options={optionsSpendingAmount}
          />
        </Box>
        <Box width={'10rem'}>
          <SelectFromToOptions
            placeholder="Point"
            onChange={handleChangePoint}
            options={optionsPoint}
          />
        </Box>
        <Box width={'10rem'}>
          <MultipleSelect
            placeholder="Status"
            width="100%"
            height="3.5rem"
            value={paramStatus}
            onChange={handleChangeStatus}
            options={optionsStatus}
          />
        </Box>
        <Box width={'16.5rem'}>
          <InputSearch
            placeholder="Last 4 number / Name"
            size="middle"
            suffix={<Icon className="search-icon" type="search" />}
            onChange={handleChangeLastNumber}
          />
        </Box>
        <Box className="center" gap="3">
          <ButtonAddCustomer model="new-ticket" />
        </Box>
      </Box>
    </CustomersHeaderStyled>
  );
};

export default CustomersHeader;
const CustomersHeaderStyled = styled.div`
  padding-top: 1.5rem;

&.v2 {
  align-self: stretch;
  padding: 0;

  .total-text {
    color: #1D2129;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .ant-select .ant-select-selector {
    .tag-label .ant-checkbox-wrapper, .ant-select-selection-item, .ant-select-selection-search-input, .ant-select-selection-placeholder {
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
    }
    .ant-select-selection-placeholder {
      color: #64748B;
    }
  }

  .ant-input-affix-wrapper .ant-input {
    color: #1D2129;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    &::placeholder {
      color: #64748B;
      opacity: 1;
    }

    &::-ms-input-placeholder {
      color: #64748B;
    }
  }
}
`;
// const ChangeDisplayTypeIcon = styled.div<ChangeDisplayTypeIconProps>`
//   cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

//   // Optionally, you can add other styles for the disabled state:
//   opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
//   pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
// `;

const InputSearch = styled(Input)`
  height: 3.5rem;
  .ant-input-suffix {
    opacity: 0.5;
  }
`;
