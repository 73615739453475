import { Radio, RadioChangeEvent, Row } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import TurnService from './Components/TurnService';
import TurnTicket from './Components/TurnTicket';
import select_turn_icon from './assets/select-box.svg';
import service_active_icon from './assets/service-active.png';
import service_icon from './assets/service.png';
import ticket_active_icon from './assets/ticket.png';
import ticket_icon from './assets/ticket.png';
import range_icon from './assets/range.png';
import range_active_icon from './assets/range-active.png';
import clsx from 'clsx';
import TurnRange from './Components/TurnRange';
type ITurnSetting = {
    v2?: boolean;
};
type TypaRadio = 'TICKET' | 'SERVICE' | 'RANGE' | string;
const TurnSetting: React.FC<ITurnSetting> = ({ v2 }: ITurnSetting) => {
    const [typeRadio, setTypeRadio] = useState<TypaRadio>('TICKET');
    const dispatch = useAppDispatch();
    const turnSetting = settingServiceSelectors.getTurnSetting();
    const reloadTurnSetting = settingServiceSelectors.reloadTurnSetting();
    useEffect(() => {
        dispatch(settingServiceActions.getTurnSetting.fetch({}));
    }, []);
    useEffect(() => {
        if (reloadTurnSetting) {
            dispatch(settingServiceActions.getTurnSetting.fetch({}));
        }
    }, [reloadTurnSetting]);

    const onChangeType = (e: RadioChangeEvent) => {
        setTypeRadio(e.target.value);
    };
    return (
        <TurnSettingStyled style={v2 ? { background: '#fff' } : {}} className={v2 ? 'v2' : ''}>
            <Box bgColor={'fill_1'} style={v2 ? { background: '#fff' } : {}} p="3" borderRadius="5px" >
                {v2 ? <RadioType>
                    <div className={clsx('radio-item', typeRadio === 'TICKET' && 'active')} onClick={() => setTypeRadio('TICKET')}>
                        <img src={select_turn_icon} alt="select_turn_icon" className="select_turn_icon" />
                        <span>By Ticket</span>
                        <img className='radio-service-icon' src={typeRadio === 'TICKET' ? ticket_active_icon : ticket_icon} alt="ticket_icon" />
                    </div>
                    <div className={clsx('radio-item', typeRadio === 'SERVICE' && 'active')} onClick={() => setTypeRadio('SERVICE')}>
                        <img src={select_turn_icon} alt="select_turn_icon" className="select_turn_icon" />
                        <span>By Service</span>
                        <img className='radio-service-icon' src={typeRadio === 'SERVICE' ? service_active_icon : service_icon} alt="service_icon" />
                    </div>
                    <div className={clsx('radio-item', typeRadio === 'RANGE' && 'active')} onClick={() => setTypeRadio('RANGE')}>
                        <img src={select_turn_icon} alt="select_turn_icon" className="select_turn_icon" />
                        <span>Range Sales</span>
                        <img className='radio-service-icon' src={typeRadio === 'RANGE' ? range_active_icon : range_icon} alt="service_icon" />
                    </div>
                </RadioType>
                    :
                    <Radio.Group onChange={onChangeType} value={typeRadio} style={{ width: '100%' }}>
                        <Row wrap={false}>
                            <SelectBox onClick={() => setTypeRadio('TICKET')}>
                                <Icon type='turnTicketSetting' />
                                <Box bgColor="fill_1" width={'100%'} justifyContent='center' p="3" display='flex'>
                                    <Radio value={'TICKET'}>By Ticket</Radio>
                                </Box>
                            </SelectBox>
                            <SelectBox onClick={() => setTypeRadio('SERVICE')}>
                                <Icon type='turnServiceSetting' />
                                <Box bgColor="fill_1" width={'100%'} justifyContent='center' p="3" display='flex'>
                                    <Radio value={'SERVICE'} >By Service</Radio>
                                </Box>
                            </SelectBox>
                        </Row>
                    </Radio.Group>}
                {typeRadio == 'TICKET' && <TurnTicket v2={v2} key={'TICKET'} data={turnSetting?.turnTicket} />}
                {typeRadio == 'SERVICE' && <TurnService v2={v2} key={'SERVICE'} data={turnSetting?.turnServices} />}
                {typeRadio == 'RANGE' && <TurnRange key={'RANGE'} />}
            </Box>
        </TurnSettingStyled>
    );
};

export default TurnSetting;
const RadioType = styled.div`
display: flex;
align-items: center;
gap: 24px;
align-self: stretch;
.radio-item {
    display: flex;
    height: 160px;
    padding: 8px 24px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    border-radius: 4px;
    border: 1px solid #86909C;
    background: #F6F7FC;
    span {
        color: #2D6772;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    img.select_turn_icon {
        display: none;
        width: 48px;
        height: 48px;
        flex-shrink: 0;
    }
    img.radio-service-icon {
        width: 80px;
        height: 80px;
        flex-shrink: 0;
    }

    &.active {
        img.select_turn_icon {
            display: flex;
        }
        border-radius: 4px;
        border: 3px solid #6FABB6;
        background: #fff;
    }
}
`;

const TurnSettingStyled = styled.div`
  flex-grow: 1;
  background: var(--fill-fill-3, #e5e5e5);
  border-radius: 5px;
  .ant-input-number-input-wrap {
    height: 100%;
    input {
      height: 100%;
    }
  }

  &.v2 {
    background: #fff;
    padding: 16px;
    .ant-form-item .ant-form-item-label label {
        color: #1D2129;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .dollar-input-selector {
        display: flex;
        height: 56px;
        padding: 0px 10px;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid #86909C;
        background: #FFF;
        .dollar-input-text {
            color: #1D2129;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    .ant-checkbox-wrapper{
        .ant-checkbox {
            zoom: 1.5;    
        }
        span {
            color: #1D2129;
            font-family: Poppins;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
    .group-checkbox {
        margin-top: 16px;
    }
    .ExamBox {
        padding-left: 34px;
        .CONTENT_1 {
            color: #505050;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    .actions-bottom {
        button {
            display: flex;
            width: 208px;
            padding: 16px 0px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 4px;
            flex-shrink: 0;
            border-radius: 4px;
            background: #E5E6EB !important;
            box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset  !important;

            .CONTENT_2 {
                color: #1D2129;
                text-align: center;
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            &.PRIMARY {
                border-radius: 4px;
                background: #FF8890 !important;
                box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -4px 0px 0px #E7646D inset  !important;

                .CONTENT_2 {
                    color: #fff;
                }
            }
        }
    }

    .FromToInputStyled {
        .ant-input {
            text-align: right;
        }
    }
  }
`;

const SelectBox = styled.div`
    height: 232px;
    flex-shrink: 0;
    border-radius: 5px;
    background: var(--fill-fill-2, #ADADAD);
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin: 0px 10px;
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
`;