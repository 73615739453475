import { Form, Input, Select } from 'antd';
import { SearchIcon } from 'assets/Icons/AppointmentIcons';
import Text from 'components/Text';
import SelectMerge from 'features/cashier/components/SelectMerge';
import settingStaffActions from 'features/settingStaff/services/actions';
import { STAFF_POSITION, STAFF_STATUS } from 'features/settingStaff/services/constants';
import settingStaffSelectors from 'features/settingStaff/services/selectors';
import { IStaffsParams } from 'features/settingStaff/services/types/api';
import { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import ModalAddStaff, { useModalAddStaffRef } from '../../ModalAddStaff';
import ArrangeStaffModal from '../components/ArrangeStaffModal';
import add_staff_icon from './icons/add-staff.svg';
import reorder_icon from './icons/reorder.svg';

interface IFormSearchValues {
  keySearch: string;
  status: string;
  staffType: string;
}

const { Option } = Select;

const STAFF_POSITION_OPTIONS = [
  { value: '', label: 'Position: All' },
  { value: 'ADMIN', label: 'Admin' },
  { value: 'TECHNICAL', label: 'Technician' },
  { value: 'CASHIER', label: 'Cashier' },
  { value: 'MANAGER', label: 'Manager' },
];
const renderOptions = STAFF_POSITION_OPTIONS.map((item) => {
  return (
    <Option key={item.value} value={item.value}>
      {item.label}
    </Option>
  );
});

const STAFF_STATUS_OPTIONS = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' },
  { value: 'DELETE', label: 'DELETE' },
];

const renderStaffStatusOptions = STAFF_STATUS_OPTIONS.map((item) => {
  return (
    <Option key={item.value} value={item.value}>
      {item.label}
    </Option>
  );
});
const renderButtonReorder = (onClick: () => void) => {
  return <button type='button' className="btn btn-reorder" onClick={onClick}>
    <img src={reorder_icon} alt="reorder_icon" style={{ width: 24, height: 24 }} />
    <span>Reorder Booking</span>
  </button>;
};
const TableControl = () => {
  const dispatch = useAppDispatch();
  const total = settingStaffSelectors.getTotal();
  const [form] = Form.useForm();
  const addStaff = useModalAddStaffRef();
  const params = settingStaffSelectors.staffsTableData.params();

  useEffect(() => {
    if (!params) return;
    form.setFieldsValue(params);
  }, [params]);

  const handleFinish = (values: IFormSearchValues) => {
    const params: IStaffsParams = {
      keySearch: String(values.keySearch).trim(),
      status: values.status as STAFF_STATUS,
      staffType: values.staffType as STAFF_POSITION,
    };
    dispatch(settingStaffActions.setParams(params));
  };

  const handleAddStaff = () => addStaff.current?.open();

  return (
    <TableControlStyled<any> form={form} onFinish={handleFinish}>
      <Text mr={1} className='overview-label'>{`Staffs(${total})`}</Text>
      <div style={{ flex: 1 }}></div>
      <Form.Item noStyle name="keySearch">
        <Input
          autoComplete="off"
          defaultValue=""
          style={{ width: '20rem' }}
          placeholder="Staff name"
          suffix={<SearchIcon />}
        />
      </Form.Item>
      <Form.Item noStyle name="status">
        <SelectMerge style={{ width: '20rem' }}>
          {renderStaffStatusOptions}
        </SelectMerge>
      </Form.Item>
      <Form.Item noStyle name="staffType">
        <SelectMerge style={{ width: '20rem' }}>
          {renderOptions}
        </SelectMerge>
      </Form.Item>
      <button type='submit' className="btn btn-search">
        <span>Search</span>
      </button>
      <button type='button' className="btn btn-add-staff" onClick={handleAddStaff}>
        <img src={add_staff_icon} alt="add_staff_icon" style={{ width: 24, height: 24 }} />
        <span>Add Staff</span>
      </button>
      <ArrangeStaffModal v2 renderButton={renderButtonReorder} />
      <ModalAddStaff ref={addStaff} />
    </TableControlStyled>
  );
};

export default TableControl;
const TableControlStyled = styled(Form)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  align-self: stretch;
  gap: 16px;
  .overview-label {
    color: #1D2129;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .ant-select .ant-select-selection-item {
    height: auto;
  }
  .ant-select .ant-select-arrow {
    color: #1D2129;
    font-size: 18px;
    transform: scaleX(1.1) scaleY(0.9);
  }
  .ant-select .ant-select-selector {
    background: #ffffff;
    border: 1px solid #86909C;
    .ant-select-selection-item-content {
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .ant-select .ant-select-selector {
    background: #ffffff;
    border: 1px solid #86909C;
    .ant-select-selection-placeholder, .ant-select-selection-search-input, .ant-select-selection-item {
      color: rgb(29, 33, 41);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .ant-input-affix-wrapper {
    background: #ffffff;
    border: 1px solid #86909C;
    .ant-input {
      background: transparent;
      color: rgb(29, 33, 41);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .btn {
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: #FF8890;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -4px 0px 0px #E7646D inset;
    span {
      color: #FFF;
      text-align: center;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    &.btn-add-staff {
      background: #6FABB6;
      box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -4px 0px 0px #144B55 inset;
    }
    &.btn-reorder {
      background: #C6E9EF;
      box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #6FABB6 inset;
      span {
        color: #1D2129;
      }
    }
  }
`;
