import { DndContext, DragEndEvent, DragOverlay } from '@dnd-kit/core';
import { signal } from '@preact/signals-react';
import { Spin } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import Modal from 'components/Modal';
import ModalConfirm, { useModalConfirmRef } from 'components/Modal/ModalConfirm';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import { Draggable } from 'features/cashier/components/DragAndDrop/Draggable';
import { Droppable } from 'features/cashier/components/DragAndDrop/Droppable';
import ticketListActions from 'features/ticketList/services/actions';
import ticketListApis from 'features/ticketList/services/apis';
import ticketListSelectors from 'features/ticketList/services/selectors';
import { IPayloadAptConfirm, Service, StaffItem } from 'features/ticketList/services/types/api';
import { remove, sumBy } from 'lodash';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState, useTransition } from 'react';
import { createPortal } from 'react-dom';
import { IResponseDataBody } from 'services/response';
import { IStaffItemData } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { v4 as uuid } from 'uuid';
import ModalChooseStaffFilter from 'widgets/Staff/DropDown/ModalChooseStaffFilter';
const containerView = signal<HTMLElement | null>(null);
type DraggableData = {
  service: Service;
  staffId: string;
};
type Props = {
  hook?: boolean;
  v2?: boolean;
};
type Ref = {
  open: () => void;
};
export const useEditStaffTicketRef = () => useRef<Ref>(null);
const EditStaffTicket = forwardRef<Ref, Props>(({ hook, v2 }, ref) => {
  const modalConfirm = useModalConfirmRef();
  const dispatch = useAppDispatch();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [visibleChooseStaff, setVisibleChooseStaff] = useState(false);
  const [open, setOpen] = useState(false);
  const [, startTransition] = useTransition();
  const [staffs, setStaffs] = useState<StaffItem[]>([]);
  const details = ticketListSelectors.ticketDetailsData.data();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const view = document.getElementById('drag-service');
    containerView.value = view;
  }, []);

  const handleSubmitModal = async () => {
    const billId = details.billId || '';
    if (!billId) return;
    setLoading(true);
    const body: IPayloadAptConfirm = {
      billId,
      items: staffs.map(o => ({
        staffId: o.staffId,
        detailIds: o.services.map(s => s.id),
      }))
    };
    try {
      const res: IResponseDataBody<boolean> = await ticketListApis.updateTicketClosed(body);
      if (res.data.data) {
        dispatch(ticketListActions.getTicketDetails.fetch(billId));
        setOpen(false);
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  };

  const removeStaff = (list: StaffItem[], staffId: string) => {
    remove(list, o => o.staffId === staffId);
    setStaffs(list);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const dragData = event.active.data.current as DraggableData;
    if (!dragData) return;
    const currentStaffId = dragData.staffId;
    const currentService = { ...dragData.service };
    const targetStaffId = event.over?.id || '';

    if (!targetStaffId) return;

    if (currentStaffId === targetStaffId) return;
    let isLessOne = false;
    let currentStaff: StaffItem | null = null;
    const newList = staffs.map(o => {
      if (o.staffId === currentStaffId) {
        const newSer = [...o.services.filter(ser => ser.uuid_local !== currentService.uuid_local)];
        if (newSer.length === 0) {
          isLessOne = true;
          currentStaff = o;
        }
        return ({
          ...o,
          services: newSer,
          subTotal: sumBy(newSer, s => s.price || 0),
        });
      }

      if (o.staffId === targetStaffId) {
        const newSer = [...o.services];
        newSer.push(currentService);
        return ({
          ...o,
          services: newSer,
          subTotal: sumBy(newSer, s => s.price || 0),
        });
      }

      return o;
    });

    if (isLessOne && currentStaff) {
      modalConfirm.current?.open(`Do you want to delete ${(currentStaff as StaffItem)?.staffName}?`, () => {
        removeStaff(newList, currentStaff?.staffId || '');
      });
      return;
    }

    setStaffs(newList);
  };

  const onAddNewStaff = () => {
    setVisibleChooseStaff(true);
  };

  const handleAddStaff = (val: IStaffItemData) => {
    const exist = staffs.find(o => o.staffId === val.id);
    if (exist) return;
    const newList = [...staffs];

    newList.push({
      staffId: val.id,
      services: [],
      staffName: val.firstName,
      subTotal: 0,
      tip: 0
    });

    setStaffs(newList);
    setTimeout(() => {
      scrollRef.current?.scrollBy({ behavior: 'smooth', left: -Number.MIN_SAFE_INTEGER });
    }, 0);
  };

  const filterStaff = useCallback((list: IStaffItemData[]) => {
    return list.filter(o => staffs.findIndex(s => o.id === s.staffId) === -1);
  }, [staffs]);

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleShowModal = () => startTransition(() => {
    setOpen(true);
    setStaffs((details?.items || []).map(staff => {
      return ({
        ...staff,
        services: staff.services.map(ser => {
          return ({
            ...ser,
            uuid_local: uuid(),
          });
        })
      });
    }));
  });

  useImperativeHandle(ref, () => ({
    open: handleShowModal,
  }));

  return (
    <>
      {!hook && <Button
        ntype='LIGHT_BLUE'
        icon='split'
        onClick={handleShowModal}
      >Edit ticket</Button>}
      <Modal
        width={900}
        modalTitle="Edit ticket"
        className='modal-split-ticket'
        visible={open}
        onClose={handleCloseModal}
        onSubmit={handleSubmitModal}
        noneBodyStyle
        noneFooterStyle
        containerPadding={v2 ? 1 : 2}
        footerPadding={v2 ? 1 : 2}
        okTitle="Done"
        disableOk={loading}
        v2={v2}
      >
        <Spin spinning={loading}>
          <div id='drag-service'>
            <DndContext onDragEnd={handleDragEnd}>
              <TicketContainerStyled className={v2 ? 'v2' : ''} >
                <div className='flex-grow' ref={scrollRef}>
                  {staffs.map(o => (<TicketDisplay key={o.staffId} item={o} />))}
                </div>
              </TicketContainerStyled>
              <DraggableOverlay />
            </DndContext>

            <Box>
              <Button cardCashier={v2} ntype="LIGHT_BLUE" width="100%" onClick={onAddNewStaff}>
                + Add New Staff
              </Button>
            </Box>
          </div>
        </Spin>
      </Modal>
      <ModalChooseStaffFilter
        filter={filterStaff}
        visible={visibleChooseStaff}
        onChooseStaff={handleAddStaff}
        onClose={() => setVisibleChooseStaff(false)}
      />
      <ModalConfirm ref={modalConfirm} />
    </>
  );
});
EditStaffTicket.displayName = 'EditStaffTicket';
export default EditStaffTicket;

const TicketContainerStyled = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  display: flex;
  position: relative;
  .ticket-item {
    width: 25%;
    min-width: 25%;
    height: 21.5rem;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
  }

  .flex-grow {
    overflow: auto;
    flex-grow: 1;
    display: flex;
    flex-wrap: nowrap;
    .ticket-item {
      width: 33.33%;
      min-width: 33.33%;
    }  
  }
  
  .ticket-item:last-child {
    .ticket-wrapper {
      margin-right: 0;
    }
  }
  .ticket-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem;
    border-radius: 5px;
  }
  .service-item {
    &:hover {
      cursor: move; /* fallback if grab cursor is unsupported */
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
      background: #fff;
    }
  }

&.v2 {
  padding-top: 0;
  .ticket-item .ticket-wrapper {
    .H9 {
      color: #1D2129;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .service-item .BODY_1 {
      color: #505050;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
`;


const TicketStyled = styled.div`
  margin-right: 1rem;
  background-color: ${colorTheme.fill_3};
  min-height: calc(100% - 1rem);
`;

const TicketDisplay = ({ item }: { item: StaffItem }) => {
  const TicketContent = useCallback(() => {
    if (!item) return null;
    return (
      <TicketStyled className="ticket-wrapper">
        <Box className="space-between">
          <Text variant="H9" color="text_3">
            {item.staffName}
          </Text>
          <Text variant="H9" color="text_3">
            {formatCurrency(item.subTotal)}
          </Text>
        </Box>
        {/* mapping here */}
        {item?.services?.map((service, index) => {
          const col: DraggableData = { service, staffId: item.staffId };
          return (
            <Draggable key={service.uuid_local} id={`${item.staffId}_${service.uuid_local}_${index}`} data={col}>
              <Box className="service-item space-between" px="2" py="3">
                <Text variant="BODY_1" color="text_3">
                  {service.itemName}
                </Text>
                <Box display="flex" gap="2" alignItems="center">
                  <Text variant="BODY_1" color="text_3">
                    {formatCurrency(service.price)}
                  </Text>
                </Box>
              </Box>
            </Draggable>
          );
        })}
      </TicketStyled>
    );
  }, [item]);

  return (
    <Droppable
      id={item?.staffId}
      className="ticket-item"
    >
      <TicketContent />
    </Droppable>
  );
};

const DragOverlayStyled = styled(DragOverlay)`
  &:hover {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }
`;

function DraggableOverlay() {
  if (!containerView.value) return null;
  return createPortal(
    <DragOverlayStyled />,
    containerView.value
  );
}