import React, { useEffect } from 'react';
import Box from 'components/Box';
import settingServiceActions from 'features/settingService/services/actions';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import DiscountPercentage from './components/DiscountPercentage';
import DiscountSpecial from './components/DiscountSpecial';
type IDiscountProps = {
  v2?: boolean;
};
const Discount: React.FC<IDiscountProps> = ({ v2 }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(settingServiceActions.getSpecialDiscount.fetch());
  }, []);

  return (
    <DiscountStyled className={v2 ? 'v2' : ''}>
      <DiscountPercentage v2={v2} />
      <Box mt="4">
        <DiscountSpecial v2={v2} />
      </Box>
    </DiscountStyled>
  );
};

export default Discount;
const DiscountStyled = styled.div`
  flex-grow: 1;
  background: var(--fill-fill-3, #e5e5e5);
  border-radius: 5px;
  .ant-input-number-input-wrap {
    height: 100%;
    input {
      height: 100%;
    }
  }

  &.v2 {
    background: unset;
    .section-title {
      color: #1D2129;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 8px;
    }

    .ant-form-item-label label {
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .ant-input-affix-wrapper {
      .ant-input, .ant-input-suffix {
        color: #1D2129;
        font-family: Poppins;
        font-size: 24px !important;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    .ant-input.CurrencyInputPrecision {
      color: #1D2129;
      font-family: Poppins;
      font-size: 24px !important;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .ant-select .ant-select-selector .ant-select-selection-item {
      color: #1D2129;
      font-family: Poppins;
      font-size: 24px !important;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
`;
