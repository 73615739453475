import { Button, Form, Input, InputRef, Spin } from 'antd';
import { FormInstance } from 'antd/lib';
import clsx from 'clsx';
import { toCurrency } from 'components/CurrencyInputMasked/currency-conversion';
import CurrencyInputPrecision from 'components/CurrencyInputPrecision';
import NumberPadV2 from 'components/NumberKeyBoard/Currency/NumberPadV2';
import Text from 'components/Text';
import cashierApis from 'features/cashier/services/apis';
import { IGiftCardDetail } from 'features/cashier/services/types/giftCard';
import { find, first } from 'lodash';
import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { IResponseDataBody } from 'services/response';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { checkGiftCardV2Ref } from './CheckGiftCard';
import giftCardBgIcon from './gift-card-bg-icon.svg';
import qrScanIcon from './QR-scan.svg';
import { ContainerKeyboard, FooterActions, GiftCardDetailContainer, GiftCardNumberContainer, GiftCardTabs, HeaderModal } from './styles';
import GiftCardTab, { GIFT_CARD_RADIO_TYPE } from './Tab';
import shopSelectors from 'services/shop/selectors';
import { InputWithKeyboardStyled, useDollarAmountInputRef } from 'components/DollarAmount/Input';
import DollarAmountKeyboard from 'components/DollarAmount/Keyboard';

type Props = {
  form: FormInstance<{ giftNumber: string, amount: string, expiration: number }>
  onClose: () => void;
  setRadioType: (val: GIFT_CARD_RADIO_TYPE) => void;
  checkExistGiftCard: (code: string) => Promise<void>;
  onSubmit?: (values: any) => void;
  visible?: boolean;
};
const noLimitValue = moment('9999-12-12').valueOf();
const MONTH_12 = moment().add('month', 12).valueOf();
type Ref = {
  onActive: () => void;
};
export const issueGiftCardV2Ref = React.createRef<Ref>();
const IssueGiftCardV2 = forwardRef<Ref, Props>(({ visible, onSubmit, form, onClose, setRadioType, checkExistGiftCard }, ref) => {
  const allSetting = shopSelectors.data.allSetting();
  const inputAmountRef = useRef<InputRef>(null);
  const dollarAmountRef = useDollarAmountInputRef();
  const inputGiftCardRef = useRef<InputRef>(null);
  const [isNumberCard, setIsNumberCard] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!visible) return;
    setTimeout(() => inputGiftCardRef.current?.focus(), 100);
  }, [visible]);

  const onScan: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    inputGiftCardRef.current?.focus();
  };

  const periodDates = useMemo(() => [
    { label: '1 month', value: moment().add('month', 1).valueOf(), },
    { label: '3 month', value: moment().add('month', 3).valueOf(), },
    { label: '6 month', value: moment().add('month', 6).valueOf(), },
    { label: '12 month', value: MONTH_12, },
    { label: 'No limit', value: noLimitValue, },
  ], []);

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const code = form.getFieldValue('giftNumber');
      await checkExistGiftCard(code);
      setTimeout(() => inputGiftCardRef.current?.blur(), 100);
      return;
    }
  };

  useImperativeHandle(ref, () => ({
    onActive: () => {
      form.resetFields();
      inputGiftCardRef.current?.focus();
    }
  }));

  const validation = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  };

  const submit = async () => {
    if (!await validation()) return;
    form.submit();
  };

  return (
    <Spin spinning={loading}>
      <ContainerModal form={form} onFinish={onSubmit} initialValues={{ expiration: MONTH_12 }}>
        <ContainerKeyboard>
          <HeaderModal>
            <div className="modal-title">gift cards</div>
          </HeaderModal>
          <GiftCardTabs>
            <GiftCardTab active type={GIFT_CARD_RADIO_TYPE.ISSUED} />
            <GiftCardTab type={GIFT_CARD_RADIO_TYPE.BALANCE} onClick={() => {
              checkGiftCardV2Ref.current?.onActive();
              setRadioType(GIFT_CARD_RADIO_TYPE.BALANCE);
            }} />
          </GiftCardTabs>

          <GiftCardNumberContainer style={{ marginBottom: 0 }}>
            <Text className='modal-label' mb={0.5}>
              Gift Card Number:
            </Text>
            <div className="input-selector-container">
              <Form.Item noStyle name={'giftNumber'}
                rules={[{
                  validator: (_, text) => {
                    const value = text?.trim() || '';
                    if (!value) return Promise.reject('Please enter gift number');
                    if (value?.length < 4) return Promise.reject('Gift number code is at least 4 letters long');
                    return Promise.resolve();
                  },
                },
                ]}
              >
                <Input
                  autoFocus
                  ref={inputGiftCardRef}
                  onFocus={() => setIsNumberCard(true)}
                  inputMode='none'
                  className='gift-card-number-input fs-medium' size='small'
                  onKeyDown={handleKeyDown}
                  onChange={() => [form.validateFields(['giftNumber']), form.resetFields(['giftNumberValid'])]}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <button type='button' className='btn-scan' onClick={onScan} ><img src={qrScanIcon} alt="qrScanIcon" className='qr-scan-icon' /></button>
            </div>
          </GiftCardNumberContainer>
          <Form.Item noStyle name={'giftNumberValid'} rules={[
            ({ getFieldValue }) => ({
              async validator() {
                const code = getFieldValue('giftNumber');
                const value = code?.trim() || '';
                if (!value) return Promise.reject('Please enter gift number');
                if (value?.length < 4) return Promise.reject('Gift number code is at least 4 letters long');
                setLoading(true);
                try {
                  const res: IResponseDataBody<IGiftCardDetail> = await cashierApis.getGiftCardInfo(value);
                  if (res.data.data) return Promise.reject('Gift number exist, please try again');
                } catch (error) { } finally {
                  setLoading(false);
                }
                return Promise.resolve();
              }
            }),
          ]} />
          <Form.Item shouldUpdate noStyle>{({ getFieldsError }) => {
            const errors = getFieldsError().filter(o => o.errors.length > 0).map(o => ({ errors: o.errors, name: first(o.name) }));
            const error = first(find(errors, o => o.name === 'giftNumber' || o.name === 'giftNumberValid')?.errors) || '';
            return <Text mt={0.5} style={{ opacity: !error ? 0 : 1, transform: 'translateY(0)', alignSelf: 'stretch', textAlign: 'center' }} variant='ERROR'>{error || '--'}</Text>;
          }}</Form.Item>
          <GiftCardNumberContainer style={{ marginTop: 0 }}>
            <Text className='modal-label' mb={0.5}>
              Enter amount:
            </Text>
            <div className={clsx('input-selector-container', allSetting?.activeFormatMoney && 'dollar-input')} style={{ height: 'unset' }}>
              <Form.Item noStyle name={'amount'} rules={[{
                validator(_, value) {
                  if (!value) return Promise.reject('Please enter amount');
                  return Promise.resolve();
                },
              }]}>
                {!allSetting?.activeFormatMoney ? <>
                  <CurrencyInputPrecision inputRef={inputAmountRef}
                    onFocus={() => setIsNumberCard(false)}
                    height={64}
                    inputMode='none' className='gift-card-number-input fs-big' size='small'
                    onChangeDebounce={() => form.validateFields(['amount'])}
                  />
                </> : <>
                  <InputWithKeyboardStyled
                    ref={dollarAmountRef}
                    inputMode='none'
                    onFocus={() => setIsNumberCard(false)}
                    autoFocus
                    onChange={() => form.validateFields(['amount'])}
                  />
                </>}
              </Form.Item>
            </div>
          </GiftCardNumberContainer>
          <Form.Item shouldUpdate noStyle>{({ getFieldsError }) => {
            const errors = getFieldsError().filter(o => o.errors.length > 0).map(o => ({ errors: o.errors, name: first(o.name) }));
            const error = first(find(errors, o => o.name === 'amount')?.errors) || '';
            return <Text style={{ opacity: !error ? 0 : 1, transform: 'translateY(-20px)', alignSelf: 'stretch', textAlign: 'center' }} variant='ERROR'>{error || 'error'}</Text>;
          }}</Form.Item>
          <Form.Item noStyle name={'expiration'} rules={[{ required: true, message: 'Please choose expiration' }]}><ExpireDate periodDates={periodDates} /></Form.Item>
          <Form.Item shouldUpdate noStyle>{({ getFieldsError }) => {
            const errors = getFieldsError().filter(o => o.errors.length > 0).map(o => ({ errors: o.errors, name: first(o.name) }));
            const error = first(find(errors, o => o.name === 'expiration')?.errors) || '';
            return <Text mt={0.5} style={{ opacity: !error ? 0 : 1, alignSelf: 'stretch', textAlign: 'center' }} variant='ERROR'>{error || '--'}</Text>;
          }}</Form.Item>
          <Form.Item hidden={!isNumberCard} noStyle name={'giftNumber'}>
            <NumberPadV2
              type={'NUMBER'}
              onChange={() => {
                inputGiftCardRef.current?.focus();
                form.validateFields(['giftNumber']);
                form.resetFields(['giftNumberValid']);
              }}
            />
          </Form.Item>
          <Form.Item hidden={isNumberCard} noStyle name={'amount'}>
            {!allSetting?.activeFormatMoney ? <>
              <NumberPadCurrency
                onChange={() => {
                  inputAmountRef.current?.focus();
                  form.validateFields(['amount']);
                }} />
            </> : <>
              <DollarAmountKeyboard
                controlRef={dollarAmountRef}
                onChange={() => form.validateFields(['amount'])} />
            </>}
          </Form.Item>
          <FooterActions>
            <Button onClick={onClose} htmlType='button' type='default'><span>Close</span></Button>
            <Button onClick={submit} type='primary'><span>Issue</span></Button>
          </FooterActions>
        </ContainerKeyboard >
        <Form.Item shouldUpdate noStyle>
          {() => {
            const values = form.getFieldsValue();
            return (
              <GiftCardDetailContainer className='issues-tab'>
                <img src={giftCardBgIcon} alt="giftCardBgIcon" className='gift-card-bg-icon' />
                <div className="detail-item">
                  <div className="detail-item-label">Gift Card Number</div>
                  <div className="detail-item-amount">
                    <div className="detail-item-amount-text">
                      {values.giftNumber || '--'}
                    </div>
                  </div>
                </div>
                <div className="detail-item">
                  <div className="detail-item-label">Amount</div>
                  <div className="detail-item-amount">
                    <div className="gift-amount-box">
                      <div className="detail-item-amount-text">
                        {formatCurrency(values.amount || 0)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detail-item">
                  <div className="detail-item-label">Expiration</div>
                  <div className="detail-item-amount">
                    <div className="detail-item-amount-text">
                      {find(periodDates, o => o.value === values.expiration)?.label || '--'}
                    </div>
                  </div>
                </div>
              </GiftCardDetailContainer>
            );
          }}
        </Form.Item>
      </ContainerModal >
    </Spin>
  );
});
IssueGiftCardV2.displayName = 'IssueGiftCardV2';
export default IssueGiftCardV2;

const NumberPadCurrency = ({ value, onChange = () => undefined }: { value?: string, onChange?: (value: number) => void; }) => {
  const displayValue = `${toCurrency(parseFloat(value || '').toFixed(2))}`;
  const handleInputChange = (e: string) => {
    const rawValue = e?.replace(/[^0-9]/g, ''); // Strip non-numeric characters
    let numericValue = 0.0;

    if (rawValue) {
      const intValue = parseInt(rawValue);
      numericValue = intValue / 100;
    }
    onChange(numericValue);
  };
  return (
    <NumberPadV2 type={'NUMBER'} value={displayValue} onChange={handleInputChange} />
  );
};

const ContainerModal = styled(Form)`
  display: flex;
`;

const ExpireDateStyled = styled.div`
display: flex;
padding: 0px 24px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 4px;
align-self: stretch;
.modal-label {
  color: #64748B;
  align-self: stretch;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.radios {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  .radio-item {
    display: flex;
    align-items: center;
    gap: 12px;
    .checkbox-icon {
      display: flex;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      gap: 10px;
      border-radius: 100px;
      border: 1px solid #86909C;
      background: #F5F5F5;

      &.active {
        border-radius: 100px;
        border: 1px solid #2D6772;
        background: #2D6772;
      }
    } 

    .checkbox-label {
      color: #1D2129;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal; 
    }
  }
}
`;
type ExpireDateProps = {
  value?: number;
  onChange?: (value?: number) => void;
  periodDates: { label: string; value: number; }[];

};
const ExpireDate = ({ periodDates, value, onChange = () => undefined }: ExpireDateProps) => {
  return (
    <ExpireDateStyled>
      <div className="modal-label">Expiration</div>
      <div className="radios">
        {periodDates.map(o => (
          <button onClick={() => onChange(o.value)} type='button' className={clsx('radio-item', value === o.value && 'active')} key={o.value}>
            <div className={clsx('checkbox-icon', value === o.value && 'active')}>
              {value === o.value &&
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                  <path d="M12.1586 22.5C11.7584 22.5 11.3783 22.3326 11.0981 22.0396L5.43522 16.117C4.85493 15.5101 4.85493 14.5056 5.43522 13.8987C6.0155 13.2918 6.97598 13.2918 7.55627 13.8987L12.1586 18.7121L22.4437 7.95518C23.024 7.34827 23.9845 7.34827 24.5648 7.95518C25.1451 8.56209 25.1451 9.56662 24.5648 10.1735L13.2192 22.0396C12.939 22.3326 12.5588 22.5 12.1586 22.5Z" fill="white" />
                </svg>}
            </div>
            <div className="checkbox-label">{o.label}</div>
          </button>
        ))}
      </div>
    </ExpireDateStyled>
  );
};
