import { Form, Row, Switch } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import PercentDecimalInputPrecision from 'components/PercentDecimalInputPrecision';
import Text from 'components/Text';
import settingApis from 'features/settingService/services/apis';
import { IApiUpdateTipOptionItem } from 'features/settingService/services/types/api';
import React, { useEffect, useState } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import shopActions from 'services/shop/actions';
import shopSelectors from 'services/shop/selectors';
import { IOptionTipObjectItem } from 'services/shop/types/optionTip';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import TipOptionConfigV2Form from './TipOptionConfigV2Form';
type IDiscountProps = {
  v2?: boolean;
};
const TipOptionsConfig: React.FC<IDiscountProps> = ({ v2 }) => {
  const dispatch = useAppDispatch();
  const optionTips = shopSelectors.data.optionTipsAll();
  const setLoading = useSetLoadingPage();
  const [form] = Form.useForm();
  const [enable, setEnable] = useState(false);

  useEffect(() => {
    dispatch(shopActions.get.optionTipsAll.fetch());
  }, []);

  useEffect(() => {
    form.setFieldValue('optionTips', optionTips);
    setEnable(optionTips.some(o => !!o.active));
  }, [optionTips]);

  const onSwitchEnable = (value: boolean) => {
    setEnable(value);
    if (value === enable) return;
    form.setFieldValue('optionTips', optionTips.map(o => ({ ...o, active: value })));
  };

  const handleFinish = async (values: any) => {
    const body: IApiUpdateTipOptionItem[] = (values.optionTips as IOptionTipObjectItem[]).map(o => ({
      active: o.active,
      id: o.id,
      option: o.option,
    }));
    setLoading(true);
    try {
      const res: IResponseDataBody<boolean> = await settingApis.updateOptionTipsAll(body);
      if (res.data.data) {
        dispatch(shopActions.get.optionTips.fetch());
        dispatch(shopActions.get.optionTipsAll.fetch());
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <TipStyled className={v2 ? 'v2' : ''}>
      <Form form={form} onFinish={handleFinish}
      >
        {v2 ? <TipOptionConfigV2Form checked={enable} onChange={onSwitchEnable} /> :
          <Box bgColor="fill_1" p="3" borderRadius="5px">
            <Row align={'middle'} style={{ gap: 16, marginBottom: 16 }}>
              <Text variant="H9" color="text_3">
                TIP OPTIONS
              </Text>
              <Switch checked={enable} onChange={onSwitchEnable} />
            </Row>
            <Form.List name={'optionTips'}>
              {(fields) => <>
                <div className='list-container'>
                  {fields.map(({ key, name }, i) => (
                    <Form.Item noStyle name={name} key={key}>
                      <TipOptionItem name={name} index={i} />
                    </Form.Item>
                  ))}
                </div>
              </>}
            </Form.List>
            <Box display="flex" gap="3" mt="4" justifyContent="end">
              <Button onClick={() => form.setFieldValue('optionTips', optionTips)} width="10rem" ntype="SECONDARY">
                Reset
              </Button>
              <Form.Item>
                <Button width="10rem" ntype="PRIMARY" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Box>
          </Box>}
      </Form>
    </TipStyled>
  );
};

export default TipOptionsConfig;
const TipStyled = styled.div`
  flex-grow: 1;
  background: #e5e5e5;
  border-radius: 5px;
  .ant-input-number-input-wrap {
    height: 100%;
    font-size: 22px;
    text-align: right;
    input {
      height: 100%;
    }
  }
  .percent-decimal-input-precision.ant-input-affix-wrapper .ant-input {
    font-size: 22px;
    text-align: right;
  }
  .ant-input-suffix {
    font-size: 22px;
  }
    .list-container {
      display: grid; 
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 16px;
    }

  &.v2 {
    background: unset;
  }
`;

const TipOptionItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: #E5E6EB;
  padding: 8px;
`;
type Props = {
  value?: IOptionTipObjectItem;
  name?: any;
  index: number;
};
const TipOptionItem = ({ name, index }: Props) => {
  return <TipOptionItemStyled>
    <Row align={'middle'} justify={'space-between'} style={{ gap: 16 }}>
      <Text variant="H9" color="text_3">
        Option {index + 1}
      </Text>
      <Form.Item
        noStyle
        valuePropName="checked"
        name={[name, 'active']}
      >
        <Switch />
      </Form.Item>
    </Row>
    <Form.Item
      noStyle
      name={[name, 'option']}
    >
      <PercentDecimalInputPrecision />
    </Form.Item>
  </TipOptionItemStyled>;
};