import { ITimelineItem } from 'features/appointment/utils/getTimeLines';
import { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';

type Ref = {
  setLoading: (value: boolean) => void;
};

type TimeValue = {
  hours: string;
  minutes: string;
  period: 'AM' | 'PM';
};

type Props = {
  value?: ITimelineItem;
  onChange?: (value?: ITimelineItem) => void;
  defaultPeriod?: 'AM' | 'PM';
};

export const useCustomTimePickerRef = () => useRef<Ref>(null);

const CustomTimePicker = ({
  value,
  onChange = () => undefined,
  defaultPeriod = 'AM',
}: Props) => {
  const [timeValue, setTimeValue] = useState<TimeValue>({
    hours: defaultPeriod == 'AM' ? '09' : '21',
    minutes: '00',
    period: defaultPeriod,
  });
  const [isFocused, setIsFocused] = useState({ hours: false, minutes: false });

  useEffect(() => {
    if (!value?.id) return;

    const timeParts = parseTimeString(value.id);
    if (timeParts) {
      setTimeValue(timeParts);
      return;
    }

    if (value.value) {
      const hours = value.value.hours();
      const minutes = value.value.minutes();
      const period = hours >= 12 ? 'PM' : 'AM';
      const displayHours = hours % 12 || 12;

      setTimeValue({
        hours: displayHours.toString().padStart(2, '0'),
        minutes: minutes.toString().padStart(2, '0'),
        period,
      });
    }
  }, [value]);

  const parseTimeString = (timeString: string): TimeValue | null => {
    // Try format with AM/PM (e.g., "09:00 AM")
    let match = timeString.match(/^(\d{1,2}):(\d{2})\s?(AM|PM)$/i);
    if (match) {
      return {
        hours: match[1].padStart(2, '0'),
        minutes: match[2],
        period: match[3].toUpperCase() as 'AM' | 'PM',
      };
    }

    // Try 24-hour format (e.g., "14:00")
    match = timeString.match(/^(\d{1,2}):(\d{2})$/);
    if (match) {
      const hours = parseInt(match[1], 10);
      const period = hours >= 12 ? 'PM' : 'AM';
      const displayHours = hours % 12 || 12;

      return {
        hours: displayHours.toString().padStart(2, '0'),
        minutes: match[2],
        period,
      };
    }

    return null;
  };

  const createTimeItem = (newTimeValue: TimeValue): ITimelineItem => {
    const formattedTime = `${newTimeValue.hours}:${newTimeValue.minutes} ${newTimeValue.period}`;
    const hours = parseInt(newTimeValue.hours, 10);
    const minutes = parseInt(newTimeValue.minutes, 10);

    // Convert to 24-hour format
    let adjustedHours = hours;
    if (newTimeValue.period === 'PM' && hours < 12) {
      adjustedHours = hours + 12;
    } else if (newTimeValue.period === 'AM' && hours === 12) {
      adjustedHours = 0;
    }

    // Create moment object
    const timeValue = moment().set({
      hour: adjustedHours,
      minute: minutes,
      second: 0,
      millisecond: 0,
    });

    return {
      id: formattedTime,
      value: timeValue,
    };
  };

  const handleHoursChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let hours = e.target.value.replace(/\D/g, '');

    if (hours.length > 2) hours = hours.slice(0, 2);

    const hoursNum = parseInt(hours, 10);
    if (hours === '' || (!isNaN(hoursNum) && hoursNum >= 0 && hoursNum <= 12)) {
      setTimeValue((prev) => ({ ...prev, hours }));
    }
  };

  const handleMinutesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let minutes = e.target.value.replace(/\D/g, '');

    if (minutes.length > 2) minutes = minutes.slice(0, 2);

    const minutesNum = parseInt(minutes, 10);
    if (
      minutes === '' ||
      (!isNaN(minutesNum) && minutesNum >= 0 && minutesNum <= 59)
    ) {
      setTimeValue((prev) => ({ ...prev, minutes }));
    }
  };

  const handlePeriodChange = (period: 'AM' | 'PM') => {
    setTimeValue((prev) => ({ ...prev, period }));

    if (timeValue.hours && timeValue.minutes) {
      setTimeout(() => updateSelectedTime({ ...timeValue, period }), 0);
    }
  };

  const handleFocus = (field: 'hours' | 'minutes') => {
    setIsFocused((prev) => ({ ...prev, [field]: true }));
  };

  const handleBlur = (field: 'hours' | 'minutes') => {
    setIsFocused((prev) => ({ ...prev, [field]: false }));

    // Format values on blur
    const updatedHours =
      timeValue.hours === '' ? '12' : timeValue.hours.padStart(2, '0');
    const updatedMinutes =
      timeValue.minutes === '' ? '00' : timeValue.minutes.padStart(2, '0');

    setTimeValue((prev) => ({
      ...prev,
      hours: field === 'hours' ? updatedHours : prev.hours,
      minutes: field === 'minutes' ? updatedMinutes : prev.minutes,
    }));

    if (timeValue.hours && timeValue.minutes) {
      updateSelectedTime({
        hours: updatedHours,
        minutes: updatedMinutes,
        period: timeValue.period,
      });
    }
  };

  const updateSelectedTime = (newTimeValue: TimeValue) => {
    if (!newTimeValue.hours || !newTimeValue.minutes) return;

    const newTimeItem = createTimeItem(newTimeValue);
    onChange(newTimeItem);
  };

  return (
    <Container className='TimeInput'>
      <div className='time-input-container'>
        <div className='time-fields'>
          <div className='inputs-wrapper'>
            <div className='time-input-group'>
              <div className='time-input-column'>
                <input
                  type='text'
                  className={`time-input hours ${
                    isFocused.hours ? 'focused' : ''
                  }`}
                  value={timeValue.hours}
                  onChange={handleHoursChange}
                  onFocus={() => handleFocus('hours')}
                  onBlur={() => handleBlur('hours')}
                  maxLength={2}
                  placeholder='09'
                  autoComplete='off'
                />
                <div className='input-labels'>Hours</div>
              </div>
              <span className='time-separator'>:</span>
              <div className='time-input-column'>
                <input
                  type='text'
                  className={`time-input minutes ${
                    isFocused.minutes ? 'focused' : ''
                  }`}
                  value={timeValue.minutes}
                  onChange={handleMinutesChange}
                  onFocus={() => handleFocus('minutes')}
                  onBlur={() => handleBlur('minutes')}
                  maxLength={2}
                  placeholder='00'
                  autoComplete='off'
                />
                <div className='input-labels'>Minutes</div>
              </div>
            </div>
          </div>
          <div className='period-selector'>
            <button
              type='button'
              className={`period-button ${
                timeValue.period === 'AM' ? 'active' : ''
              }`}
              onClick={() => handlePeriodChange('AM')}
            >
              AM
            </button>
            <button
              type='button'
              className={`period-button ${
                timeValue.period === 'PM' ? 'active' : ''
              }`}
              onClick={() => handlePeriodChange('PM')}
            >
              PM
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};

CustomTimePicker.displayName = 'CustomTimePicker';
export default CustomTimePicker;

interface ContainerProps {
  $hasError?: boolean;
}

const Container = styled.div<ContainerProps>`
  .time-input-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .time-fields {
    display: flex;
    gap: 12px;
    align-items: flex-start;
  }

  .inputs-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .time-input-group {
    display: flex;
    align-items: center;
  }

  .time-input {
    width: 80px;
    height: 62px;
    border: none;
    outline: none;
    font-family: Poppins;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: 0%;
    text-align: center;
    color: #000;
    background: transparent;
    caret-color: #2d6772;
    border: 1px solid #ccd4dc;
    background: #fff;
    border-radius: 6px;
    margin-bottom: 4px;
    transition: border-color 0.3s ease;

    &.focused {
      border-color: #d2464f;
    }
  }

  .time-input-column {
    display: flex;
    flex-direction: column;
  }

  .time-separator {
    margin: 0 4px;
    font-size: 20px;
    font-weight: 500;
    color: #000;
  }

  .input-labels {
    display: flex;
    width: 100%;
    color: #666;
    text-align: center;
    font-family: Poppins;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0%;
    margin-bottom: 4px;
  }

  .period-selector {
    width: 78px;
    height: 64px;
    display: flex;
    flex-direction: column;
    border: 1px solid #86909c;
    border-radius: 6px;
    overflow: hidden;
  }

  .period-button {
    flex: 1;
    padding: 6px 12px;
    border: none;
    background: #f6f7fc;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    width: 100%;
    font-family: Poppins;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0%;
    text-align: center;

    &.active {
      background: #232f3e;
      color: #fff;
    }

    &:first-child {
      border-bottom: 1px solid #ccd4dc;
    }
  }
`;
