import { Form, Input, message, Row } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import settingServiceActions from 'features/settingService/services/actions';
import settingApis from 'features/settingService/services/apis';
import settingServiceSelectors from 'features/settingService/services/selectors';
import {
  EdiscountType,
  ETypeReferral,
  IReferralConfig,
  IUpdateReferralConfig,
} from 'features/settingService/services/types/paymentSetting';
import React, { useEffect } from 'react';
import shopActions from 'services/shop/actions';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import SwitchBtn from '../../ServicesSettingV2/SwitchBtn';
import MyRadio from '../components/MyRadio';
import DiscountInput from './component/DiscountInput';
import IcReceiver from './icon/IcReceiver';
import IcSender from './icon/IcSender';

interface ReferralFormData {
  // Sender
  referralSender: boolean;
  senderType: keyof typeof ETypeReferral;
  senderPoint: number;
  senderDiscountValue: number;
  senderDiscountType: keyof typeof EdiscountType;

  // Receiver
  referralReceiver: boolean;
  receiverType: keyof typeof ETypeReferral;
  receiverPoint: number;
  receiverDiscountValue: number;
  receiverDiscountType: keyof typeof EdiscountType;
}

const ReferralConfigScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<ReferralFormData>();
  const setLoading = useSetLoadingPage();

  const referralConfigs = settingServiceSelectors.getReferralConfig() || [];

  const receiverDiscountValueFormData = Form.useWatch(
    'receiverDiscountValue',
    form
  );
  const receiverDiscountTypeFormData = Form.useWatch(
    'receiverDiscountType',
    form
  );
  const senderDiscountValueFormData = Form.useWatch(
    'senderDiscountValue',
    form
  );
  const senderDiscountTypeFormData = Form.useWatch('senderDiscountType', form);

  useEffect(() => {
    dispatch(settingServiceActions.getReferralConfig.fetch());
  }, [dispatch]);

  const defaultValues: ReferralFormData = {
    referralSender: true,
    senderType: 'POINT',
    senderPoint: 0,
    senderDiscountValue: 0,
    senderDiscountType: 'PERCENT',

    referralReceiver: true,
    receiverType: 'POINT',
    receiverPoint: 0,
    receiverDiscountValue: 0,
    receiverDiscountType: 'PERCENT',
  };

  const mapApiToFormData = (configs: IReferralConfig[]): ReferralFormData => {
    const formData: ReferralFormData = { ...defaultValues };

    const senderConfig = configs.find(
      (config) => config.categoryType === 'REFERRAL_SENDER'
    );
    const receiverConfig = configs.find(
      (config) => config.categoryType === 'REFERRAL_RECEIVER'
    );

    if (senderConfig) {
      formData.referralSender = senderConfig.enable;
      formData.senderType = senderConfig.type;
      formData.senderPoint = senderConfig.point || 0;
      formData.senderDiscountValue = senderConfig.discountValue || 0;
      formData.senderDiscountType = senderConfig.discountType;
    }

    if (receiverConfig) {
      formData.referralReceiver = receiverConfig.enable;
      formData.receiverType = receiverConfig.type;
      formData.receiverPoint = receiverConfig.point || 0;
      formData.receiverDiscountValue = receiverConfig.discountValue || 0;
      formData.receiverDiscountType = receiverConfig.discountType;
    }

    return formData;
  };

  useEffect(() => {
    if (referralConfigs && referralConfigs.length > 0) {
      setTimeout(() => {
        const formData = mapApiToFormData(referralConfigs);
        form.setFieldsValue(formData);
      }, 100);
    }
  }, [referralConfigs, form]);

  const mapFormToApiData = (formData: ReferralFormData): IReferralConfig[] => {
    const result: IReferralConfig[] = [];

    if (!referralConfigs || referralConfigs.length === 0) {
      return result;
    }

    const senderConfig = referralConfigs.find(
      (config) => config.categoryType === 'REFERRAL_SENDER'
    );
    const receiverConfig = referralConfigs.find(
      (config) => config.categoryType === 'REFERRAL_RECEIVER'
    );

    if (senderConfig) {
      result.push({
        ...senderConfig,
        enable: formData.referralSender,
        type: formData.senderType,
        point: formData.senderType === 'POINT' ? formData.senderPoint : 0,
        discountValue:
          formData.senderType === 'DISCOUNT' ? formData.senderDiscountValue : 0,
        discountType: formData.senderDiscountType,
      });
    }

    if (receiverConfig) {
      result.push({
        ...receiverConfig,
        enable: formData.referralReceiver,
        type: formData.receiverType,
        point: formData.receiverType === 'POINT' ? formData.receiverPoint : 0,
        discountValue:
          formData.receiverType === 'DISCOUNT'
            ? formData.receiverDiscountValue
            : 0,
        discountType: formData.receiverDiscountType,
      });
    }

    return result;
  };

  const onFinish = async (values: ReferralFormData): Promise<void> => {
    try {
      setLoading(true);

      const apiData = mapFormToApiData(values);

      if (apiData.length === 0) {
        message.error('No referral configuration to save');
        return;
      }

      const payloadArray: IUpdateReferralConfig[] = apiData.map((config) => ({
        id: config.id,
        categoryType: config.categoryType,
        enable: config.enable,
        type: config.type,
        point: config.point,
        discountValue: config.discountValue,
        discountType: config.discountType,
      }));

      try {
        const res = await settingApis.updateReferralConfig(payloadArray);
        if (res.data?.data) {
          message.success('Referral configuration saved successfully');
          dispatch(settingServiceActions.getReferralConfig.success(res.data.data));
          dispatch(shopActions.get.settingReferral.success(res.data.data));
        }
      } catch (error) {
        console.error('API error:', error);
        message.error('Failed to save referral configuration');
      }
    } catch (error) {
      console.error('Save error:', error);
      message.error('Failed to save referral configuration');
    } finally {
      setLoading(false);
    }
  };

  const onReset = (): void => {
    if (referralConfigs && referralConfigs.length > 0) {
      const formData = mapApiToFormData(referralConfigs);
      form.setFieldsValue(formData);
    } else {
      form.setFieldsValue(defaultValues);
    }
  };

  const handleDiscountChange = (
    field: string,
    typeField: string,
    onChangeValue: any
  ) => {
    if (onChangeValue) {
      form.setFieldsValue({
        [field]: onChangeValue.value,
        [typeField]: onChangeValue.type,
      });
    }
  };

  return (
    <Container>
      <Form<ReferralFormData>
        form={form}
        layout='vertical'
        initialValues={defaultValues}
        onFinish={onFinish}
        onReset={onReset}
      >
        <Content>
          <Card>
            <Row align='middle' style={{ padding: '24px 0px' }}>
              <Form.Item noStyle name={'referralSender'} valuePropName='value'>
                <SwitchBtn />
              </Form.Item>
              <Text variant='H4' className='section-label' ml={1}>
                REFERRAL SENDER
              </Text>
              <IconBox>
                <IcSender />
              </IconBox>
            </Row>

            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev.referralSender !== next.referralSender
              }
            >
              {({ getFieldValue }) => {
                const enable = getFieldValue('referralSender');
                if (!enable) return null;
                return (
                  <>
                    <Row>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prev, next) =>
                          prev.senderType !== next.senderType
                        }
                      >
                        {({ getFieldValue, setFieldsValue }) => {
                          const currentType = getFieldValue('senderType');
                          return (
                            <>
                              <MyRadio
                                value={currentType === 'POINT'}
                                style={{ marginRight: 20 }}
                                label='By Point'
                                onChange={(checked) => {
                                  if (checked)
                                    setFieldsValue({ senderType: 'POINT' });
                                }}
                              />
                              <MyRadio
                                value={currentType === 'DISCOUNT'}
                                label='By Discount'
                                onChange={(checked) => {
                                  if (checked)
                                    setFieldsValue({ senderType: 'DISCOUNT' });
                                }}
                              />
                            </>
                          );
                        }}
                      </Form.Item>
                      <Form.Item name='senderType' hidden />
                    </Row>

                    <Form.Item
                      noStyle
                      shouldUpdate={(prev, next) =>
                        prev.senderType !== next.senderType
                      }
                    >
                      {({ getFieldValue }) => {
                        const senderType = getFieldValue('senderType');
                        if (senderType !== 'POINT') return null;
                        return (
                          <Box
                            alignSelf='stretch'
                            display='flex'
                            style={{ gap: 16 }}
                          >
                            <Form.Item
                              name='senderPoint'
                              style={{ margin: 0, flex: 1 }}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter points',
                                },
                              ]}
                            >
                              <Input
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                style={{ height: '3.5rem' }}
                              />
                            </Form.Item>
                          </Box>
                        );
                      }}
                    </Form.Item>

                    <Form.Item
                      noStyle
                      shouldUpdate={(prev, next) =>
                        prev.senderType !== next.senderType
                      }
                    >
                      {({ getFieldValue }) => {
                        const senderType = getFieldValue('senderType');
                        if (senderType !== 'DISCOUNT') return null;
                        return (
                          <Box
                            alignSelf='stretch'
                            display='flex'
                            style={{ gap: 16 }}
                          >
                            <Form.Item
                              style={{ margin: 0, flex: 1 }}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter discount value',
                                },
                                {
                                  type: 'number',
                                  min: 0,
                                  message: 'Discount must be a positive number',
                                },
                              ]}
                            >
                              <DiscountInput
                                value={{
                                  value: senderDiscountValueFormData || 0,
                                  type: senderDiscountTypeFormData || 'PERCENT',
                                }}
                                onChange={(onChangeValue) =>
                                  handleDiscountChange(
                                    'senderDiscountValue',
                                    'senderDiscountType',
                                    onChangeValue
                                  )
                                }
                              />
                            </Form.Item>
                            <Form.Item name='senderDiscountValue' hidden />
                            <Form.Item name='senderDiscountType' hidden />
                          </Box>
                        );
                      }}
                    </Form.Item>
                  </>
                );
              }}
            </Form.Item>
          </Card>

          <Card>
            <Row align='middle' style={{ padding: '24px 0px' }}>
              <Form.Item
                noStyle
                name={'referralReceiver'}
                valuePropName='value'
              >
                <SwitchBtn />
              </Form.Item>
              <Text variant='H4' className='section-label' ml={1}>
                REFERRAL RECEIVER
              </Text>
              <IconBox>
                <IcReceiver />
              </IconBox>
            </Row>

            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev.referralReceiver !== next.referralReceiver
              }
            >
              {({ getFieldValue }) => {
                const enable = getFieldValue('referralReceiver');
                if (!enable) return null;
                return (
                  <>
                    <Row>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prev, next) =>
                          prev.receiverType !== next.receiverType
                        }
                      >
                        {({ getFieldValue, setFieldsValue }) => {
                          const currentType = getFieldValue('receiverType');
                          return (
                            <>
                              <MyRadio
                                value={currentType === 'POINT'}
                                style={{ marginRight: 20 }}
                                label='By Point'
                                onChange={(checked) => {
                                  if (checked)
                                    setFieldsValue({ receiverType: 'POINT' });
                                }}
                              />
                              <MyRadio
                                value={currentType === 'DISCOUNT'}
                                label='By Discount'
                                onChange={(checked) => {
                                  if (checked)
                                    setFieldsValue({
                                      receiverType: 'DISCOUNT',
                                    });
                                }}
                              />
                            </>
                          );
                        }}
                      </Form.Item>
                      <Form.Item name='receiverType' hidden />
                    </Row>

                    <Form.Item
                      noStyle
                      shouldUpdate={(prev, next) =>
                        prev.receiverType !== next.receiverType
                      }
                    >
                      {({ getFieldValue }) => {
                        const receiverType = getFieldValue('receiverType');
                        if (receiverType !== 'POINT') return null;
                        return (
                          <Box
                            alignSelf='stretch'
                            display='flex'
                            style={{ gap: 16 }}
                          >
                            <Form.Item
                              name='receiverPoint'
                              style={{ margin: 0, flex: 1 }}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter points',
                                },
                              ]}
                            >
                              <Input
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                style={{ height: '3.5rem' }}
                              />
                            </Form.Item>
                          </Box>
                        );
                      }}
                    </Form.Item>

                    <Form.Item
                      noStyle
                      shouldUpdate={(prev, next) =>
                        prev.receiverType !== next.receiverType
                      }
                    >
                      {({ getFieldValue }) => {
                        const receiverType = getFieldValue('receiverType');
                        console.log('receiverType', receiverType);
                        if (receiverType !== 'DISCOUNT') return null;
                        return (
                          <Box
                            alignSelf='stretch'
                            display='flex'
                            style={{ gap: 16 }}
                          >
                            <Form.Item
                              style={{ margin: 0, flex: 1 }}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter discount value',
                                },
                                {
                                  type: 'number',
                                  min: 0,
                                  message: 'Discount must be a positive number',
                                },
                              ]}
                            >
                              <DiscountInput
                                value={{
                                  value: receiverDiscountValueFormData || 0,
                                  type:
                                    receiverDiscountTypeFormData || 'PERCENT',
                                }}
                                onChange={(onChangeValue) =>
                                  handleDiscountChange(
                                    'receiverDiscountValue',
                                    'receiverDiscountType',
                                    onChangeValue
                                  )
                                }
                              />
                            </Form.Item>
                            <Form.Item name='receiverDiscountValue' hidden />
                            <Form.Item name='receiverDiscountType' hidden />
                          </Box>
                        );
                      }}
                    </Form.Item>
                  </>
                );
              }}
            </Form.Item>
          </Card>
        </Content>

        <Box display='flex' gap='3' mt='4' justifyContent='end'>
          <Button width='10rem' ntype='SECONDARY' htmlType='reset'>
            Reset
          </Button>
          <Button width='10rem' ntype='PRIMARY' htmlType='submit'>
            Save
          </Button>
        </Box>
      </Form>
    </Container>
  );
};

export default ReferralConfigScreen;

// Styled components
const Container = styled.div`
  flex-grow: 1;
  background: #fff;
  border-radius: 5px;
  padding: 16px;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  padding: 16px;
  margin-bottom: auto;
`;

const Card = styled.div`
  position: relative;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: flex-start;
  border-radius: 4px;
  border: 1px solid #ccd4dc;
  background: #f6f7fc;
`;

const IconBox = styled.div`
  position: absolute;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  color: #d85c50;
`;
