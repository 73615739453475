import React from 'react';
import styled from 'styled-components';
import check_icon from './check.svg';
import new_feature_icon from '../PrintCheckManualButton/new.svg';
import { useNavigate } from 'react-router-dom';
import storage from 'utils/sessionStorage';
import ModalContactUs, { useModalContactUsRef } from '../ModalContactUs';
import shopSelectors from 'services/shop/selectors';
const ButtonStyled = styled.div`
display: inline-flex;
padding: 16px 24px;
justify-content: center;
align-items: center;
gap: 4px;
border-radius: 4px;
position: relative;
border-radius: 4px;
background: #FF7D00;
box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #DC5500 inset;
span {
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

&:hover {
  background: #C84B31;
  cursor: pointer;
}

.new-feature-icon {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(20px, -14px);
}
`;
type Props = {
  startDate: string;
  endDate: string;
};
const ButtonPrintCheckAll = ({ startDate, endDate }: Props) => {
  const allSetting = shopSelectors.data.allSetting();
  const navigate = useNavigate();
  const modalContactUsRef = useModalContactUsRef();
  const onPress = () => {
    if (!allSetting?.displayPrintCheck) return modalContactUsRef.current?.open();
    navigate(`/store/${storage.shop_id.get()}/report/preview-check-all?startDate=${startDate}&endDate=${endDate}`);
  };
  return (
    <>
      <ButtonStyled onClick={onPress}>
        <img src={check_icon} alt="check_icon" style={{ width: 24, height: 24 }} />
        <img src={new_feature_icon} className='new-feature-icon' alt="new_feature_icon" style={{ width: 55, height: 55 }} />
        <span>Print Check All</span>
      </ButtonStyled>
      <ModalContactUs ref={modalContactUsRef} />
    </>
  );
};

export default ButtonPrintCheckAll;
