import { EMainLayout } from 'features/user/services/constants';
import { FLOW_ADD_TIP, HOME_SORT_BY } from '../constants';
import { ThemeHomeCheckIn } from 'features/checkIn/services/constants';

export interface IShopAllSetting {
    loyaltySetting: ILoyaltySetting;
    confirmSendSmsOrEmail: boolean;
    enableDiscountSpecial: boolean;
    enablePrintCheckIn: boolean;
    enablePrintCoupon: boolean;
    percentCreditDiscount: number;
    singlePOSPayment: boolean;
    enablePaymentMobile: boolean;
    skipChooseStaffCheckIn: boolean;
    printVoidedTicket: boolean;
    flowAddTip: FLOW_ADD_TIP;
    enableAutoFillAmountMultiPay: boolean;
    ignorePassAdminDiscount: boolean;
    enableMultipleCards: boolean;
    enablePriceService: boolean;
    skipWaitingAndPending: boolean;
    initUIHomeDashboard: EMainLayout;
    showPointCheckIn: boolean;
    themeCheckIn: ThemeHomeCheckIn,
    labelSalaryCashOrBonus: string,
    showModalTip: boolean;
    defaultSortHome: HOME_SORT_BY,
    showHeaderStaffTicket: boolean;
    printMultiplePrint: boolean;
    voiceCallStaff: boolean;
    showSupplyFeeCashier: boolean;
    chooseEmployeeQueue: boolean;
    uiTurnManual: boolean;
    autoCutPrintReport: boolean;
    skipConfirmNewTicket: boolean;
    showUIStaffCashier: boolean;
    uiButtonPayment: CashierButtonPaymentUI;
    versionUI: string;
    autoShowAddPrice: boolean;
    verifyPassDiscountItem: boolean;
    showAverageTicket: boolean;
    activeFormatMoney: boolean;
    printNoReceipt: boolean;
    displayPrintCheck: boolean;
    doublePrintReceipt: boolean;
    displayCallPhoneStillWaiting: boolean;
    displayOptionCashPrint: boolean;
}

export interface ILoyaltySetting {
    active: boolean;
    oneDollarToPoint: number;
}

export enum CashierButtonPaymentUI {
    NORMAL = 'NORMAL',
    BACKGROUND = 'BACKGROUND',
}
