import { Form, Input, Select } from 'antd';
import DollarInputPayment from 'components/DollarAmount/DollarInputPayment';
import { FormItem } from 'components/Form';
import Modal from 'components/Modal';
import { EPeriodDates } from 'features/cashier/components/CenterMainBody/ExtendFeatures/GiftCard/IssueGiftCard';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
import CustomerSearchInput, { unknownCustomerId } from './CustomerSearchInput';
import InputGiftCard from './InputGiftCard';
import { ICustomerItemData } from 'services/shop/types/customer';
import giftCardsApis from 'features/giftCards/services/apis';
import { IAddManUalGiftCardBody } from 'features/giftCards/services/types/api';
import moment from 'moment';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import giftCardsActions from 'features/giftCards/services/actions';
import { useAppDispatch } from 'store/hooks';

const FormStyled = styled(Form)`
  .ant-form-item .ant-form-item-label label {
    color: #1D2129;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .dollar-input-selector {
    height: 3.5rem;
    padding: 0px 11px;
    .dollar-input-text {
      color: #1D2129;
      font-family: Poppins;
      font-size: 22px;
      height: unset;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .ant-input.note-input, .ant-select.expireDate .ant-select-selector .ant-select-selection-item {
    color: #1D2129;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
  }
`;

const RowAmount = styled.div`
display: flex;
gap: 16px;
align-self: stretch;
`;

const periodDates = [
  { label: '1 month', value: EPeriodDates.MONTH_1, },
  { label: '3 month', value: EPeriodDates.MONTH_3 },
  { label: '6 month', value: EPeriodDates.MONTH_6 },
  { label: '12 month', value: EPeriodDates.MONTH_12 },
  { label: 'No limit', value: EPeriodDates.NO_LIMIT },
];


interface IFormValues {
  giftNumber: string;
  note: string;
  amount: number;
  expireDate: number;
  customer: ICustomerItemData | null;
}

type Props = {};
type Ref = {
  open: () => void;
};
const PAGE_SIZE = 20;
export const useModalAddGiftCardRef = () => useRef<Ref>(null);
const ModalAddGiftCard = forwardRef<Ref, Props>(({ }, ref) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const setLoadingPage = useSetLoadingPage();
  const dispatch = useAppDispatch();
  const onClose = () => setOpen(false);
  const onOpen: Ref['open'] = () => {
    form.resetFields();
    setOpen(true);
  };
  const handleFinish = async (values: IFormValues) => {
    const body: IAddManUalGiftCardBody = {
      amount: values.amount,
      expireDate: moment(values.expireDate).format('MM-DD-YYYY'),
      customerId: values.customer?.id ? (values.customer?.id !== unknownCustomerId ? values.customer?.id : '') : '',
      giftCode: values.giftNumber,
      note: values.note,
    };
    setLoadingPage(true);
    const nowStr = moment().format('MM-DD-YYYY');
    try {
      const res: IResponseDataBody<any> = await giftCardsApis.addManualGiftCard(body);
      if (res.data.data) {
        const payload = {
          page: 1,
          size: PAGE_SIZE,
          startDate: nowStr,
          endDate: nowStr,
        };
        dispatch(giftCardsActions.getListGiftCard.fetch(payload));
        onClose();
      }
    } catch (error) {
    } finally {
      setLoadingPage(false);
    }
  };

  useImperativeHandle(ref, () => ({
    open: onOpen
  }));

  return (
    <Modal
      visible={open}
      modalTitle='ADD GIFT CARD'
      onClose={onClose}
      okTitle='Add'
      onSubmit={() => form.submit()}
      containerPadding={1}
      noneBodyStyle
      noneFooterStyle
      v2
      className={'modal-form-input-check modal-non-opacity modal-bg-v2'}
      width={'80vw'}
    >
      <FormStyled<any> form={form} onFinish={handleFinish} layout='vertical' initialValues={{ expireDate: EPeriodDates.MONTH_12 }}>
        <FormItem label='Customer' name={'customer'}>
          <CustomerSearchInput v2NewApt />
        </FormItem>
        <RowAmount>
          <Form.Item label='Gift Code' name={'giftNumber'} style={{ flex: 1 }}>
            <InputGiftCard setLoading={setLoadingPage} />
          </Form.Item>
          <FormItem label='Amount' name={'amount'} rules={[{ required: true }]} style={{ flex: 1 }}>
            <DollarInputPayment />
          </FormItem>
        </RowAmount>
        <FormItem label={'Expiration'} name={'expireDate'} rules={[{ required: true }]}>
          <Select className='expireDate' options={periodDates} />
        </FormItem>
        <FormItem label='Note' name={'note'} >
          <Input.TextArea className='note-input' rows={4} placeholder='Please enter' />
        </FormItem>
      </FormStyled>
    </Modal>
  );
});
ModalAddGiftCard.displayName = 'ModalAddGiftCard';
export default ModalAddGiftCard;