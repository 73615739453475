import { Form, InputNumber, Switch } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import settingServiceActions from 'features/settingService/services/actions';
import React, { useEffect } from 'react';
import shopActions from 'services/shop/actions';
import shopSelectors from 'services/shop/selectors';
import { IProductTaxConfigResData } from 'services/shop/types/tax';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import SectionV2 from './components/SectionV2';
import SwitchBtn from '../ServicesSettingV2/SwitchBtn';
import ActionButtons from './components/ActionButtons';
type ITaxProps = {
  v2?: boolean;
};
const ProductTax: React.FC<ITaxProps> = ({ v2 }) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<IProductTaxConfigResData>();
  const taxStore = shopSelectors.data.productTaxConfig();
  const onFinish = (values: IProductTaxConfigResData) => {
    const body = { ...taxStore, ...values };
    dispatch(settingServiceActions.postProductTax.fetch(body));
  };

  useEffect(() => {
    if (taxStore) {
      form.setFieldsValue(taxStore);
    }
  }, [taxStore]);

  useEffect(() => {
    dispatch(shopActions.get.productTaxConfig.fetch());
  }, []);

  return (
    <TaxStyled className={v2 ? 'v2' : ''}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ saleTaxPercent: 0, useTaxPercent: 0 }}
      >
        {v2 ? <FormV2 /> :
          <>
            <Box bgColor="fill_1" p="3" borderRadius="5px">
              <Box display="flex" gap="3" alignItems="center" mb='2'>
                <FormItemStyled name="enableTax" valuePropName="checked">
                  <Switch />
                </FormItemStyled>
                <Text variant="CONTENT_2" color="text_3">
                  Product Tax
                </Text>
              </Box>
              <Form.Item name="taxPercent" noStyle>
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) => `${value}%`}
                  parser={(value: any) => value?.replace('%', '')}
                />
              </Form.Item>
            </Box>
            <Box display="flex" gap="3" mt="4" justifyContent="end">
              <Button width="10rem" ntype="SECONDARY" htmlType="reset">
                Reset
              </Button>
              <Form.Item>
                <Button width="10rem" ntype="PRIMARY" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Box>
          </>}
      </Form>
    </TaxStyled>
  );
};

export default ProductTax;
const TaxStyled = styled.div`
  flex-grow: 1;
  background: var(--fill-fill-3, #e5e5e5);
  border-radius: 5px;
  .ant-input-number-input-wrap {
    height: 100%;
    input {
      height: 100%;
      color: var(--text-text-3, #1d2129);
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  &.v2 {
    background: unset;
  }
`;
const FormItemStyled = styled(Form.Item)`
  margin: 0;
`;
const FormV2Container = styled.div`
  background: #fff;
  padding: 16px;
  .ant-input-number .ant-input-number-input {
    color: #1D2129;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
const FormV2 = () => {
  return (
    <FormV2Container>
      <SectionV2>
        <Box display="flex" gap="3" alignItems="center">
          <Form.Item
            noStyle name="enableTax"
          >
            <SwitchBtn />
          </Form.Item>
          <span className='section-label'>
            Product Tax
          </span>
        </Box>
        <Form.Item name="taxPercent" noStyle>
          <InputNumber
            style={{ width: '100%' }}
            formatter={(value) => `${value}%`}
            parser={(value: any) => value?.replace('%', '')}
          />
        </Form.Item>
      </SectionV2>
      <ActionButtons />
    </FormV2Container>
  );
};