import React from 'react';
import { IPrintCheckInfoDataSubmitValues } from './type';
import CheckUI from './CheckUI';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import shopSelectors from 'services/shop/selectors';
type Props = {
  value?: IPrintCheckInfoDataSubmitValues | null;
  printRef?: React.RefObject<HTMLDivElement>;
  manual?: boolean;
  className?: string;
};
const ReceiptPrinter = ({ value: data, printRef, manual, className }: Props) => {
  const printCheckSetting = shopSelectors.data.printCheckSetting();
  return (
    <Container ref={printRef} className={className}>
      <div style={{ padding: '0 24px' }}>
        <CheckUI
          checkNum={data?.checkNo}
          date={data?.date.format('MM/DD/YYYY')}
          name={data?.staffName}
          amount={data?.total}
          signature={data?.signature}
          checkBank={data?.checkBank}
          forCheckNote={data?.forCheckNote}
        />
      </div>
      <ReceiptInfo style={manual ? { display: 'none' } : { marginTop: '36px' }}>
        <Total>
          <span>TOTAL</span>
          <span className="value">{formatCurrency(data?.total)}</span>
        </Total>
        <Ticket>
          <span>TOTAL SALES</span>
          <span className="value">{formatCurrency(data?.totalSales)}</span>
        </Ticket>
        <Ticket>
          <span>SUPPLY FEES</span>
          <span className="value">{formatCurrency(data?.supplyFee)}</span>
        </Ticket>
        <Ticket>
          <span>COMMISSION ({data?.commissionPercent || 0}%)</span>
          <span className="value">{formatCurrency(data?.commission)}</span>
        </Ticket>
        <Ticket>
          <span>COLLECTED TIPS</span>
          <span className="value">{formatCurrency(data?.collectedTips)}</span>
        </Ticket>
        <div className="divider" />
        <Total>
          <span>TOTAL PAID OUT</span>
          <span className="value">{formatCurrency(data?.totalPaidOut)}</span>
        </Total>
        <Ticket>
          <span>BONUS ({data?.bonusPercent || 0}%)</span>
          <span className="value">{formatCurrency(data?.bonus)}</span>
        </Ticket>
        <Ticket>
          <span>CHECK ({data?.checkPercent || 0}%)</span>
          <span className="value">{formatCurrency(data?.check)}</span>
        </Ticket>
        <Ticket>
          <span>TIP TO BONUS ({data?.tipToBonusPercent || 0}%)</span>
          <span className="value">{formatCurrency(data?.tipToBonus)}</span>
        </Ticket>
        <Ticket>
          <span>TIP TO CHECK</span>
          <span className="value">{formatCurrency(data?.tipToCheck)}</span>
        </Ticket>
        <div className="divider" />
        <Total>
          <span>TOTAL BONUS</span>
          <span className="value">{formatCurrency(data?.totalBonus)}</span>
        </Total>
        <Total>
          <span>TOTAL CHECK</span>
          <span className="value">{formatCurrency(data?.totalCheck)}</span>
        </Total>
      </ReceiptInfo>
      <ReceiptInfo style={manual ? { display: 'none' } : { marginTop: '200px' }}>
        <Ticket>
          <span>NOTE: {printCheckSetting?.note || '--'}</span>
        </Ticket>
      </ReceiptInfo>
    </Container>
  );
};

export default ReceiptPrinter;
const Container = styled.div`
padding-top: 16px;
.page-break {
  margin: 16px;
}
`;
const Total = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  span {
    color: black;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;

    &.value {
      flex: 1;
      text-align: right;
    }
  }
`;
const Ticket = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  span {
    color: black;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 13px;
    &.value {
      color: black;
      text-align: right;
    }
  }
`;
const ReceiptInfo = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0 16px;
margin-top: 60px;

.divider {
  background-color: black;
  height: 1px;
  width: 100%;
  align-self: stretch;
}
`;