import React, { useEffect } from 'react';
import Sidebar from 'components/layout/Sidebar';
import styled from 'styled-components';
import StaffTable from './components/StaffTable';
import TableControl from './components/TableControl';
import SettingStaffListStyled from './styles';
import { useAppDispatch } from 'store/hooks';
import settingStaffActions from 'features/settingStaff/services/actions';
import { STAFF_STATUS } from 'features/settingStaff/services/constants';
import shopSelectors from 'services/shop/selectors';
import StaffsSettingV2 from './v2';
type ISettingStaffListProps = any;
const SettingStaffList: React.FC<ISettingStaffListProps> = () => {
  const dispatch = useAppDispatch();
  const versionUI = shopSelectors.data.versionUI();
  useEffect(() => {
    dispatch(settingStaffActions.doInit());
    const params = {
      page: 1,
      size: 20,
      ignoreAnyBody: true,
      status: STAFF_STATUS.ACTIVE,
    };
    dispatch(settingStaffActions.setParams(params));
  }, []);

  if (versionUI === 'V2') return <StaffsSettingV2 />;

  return (
    <SettingStaffListStyled>
      <Sidebar />
      <BodyStyled>
        <BodyContentStyled>
          <TableControl />
          <StaffTable />
        </BodyContentStyled>
      </BodyStyled>
    </SettingStaffListStyled>
  );
};

export default SettingStaffList;

const BodyStyled = styled.div`
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
`;

const BodyContentStyled = styled.div`
  padding: 1.5rem 2rem;
`;
