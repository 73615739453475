import { Avatar, Form } from 'antd';
import clsx from 'clsx';
import Box from 'components/Box';
import Button from 'components/Button';
import DollarInputPayment from 'components/DollarAmount/DollarInputPayment';
import Text from 'components/Text';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import ticketListActions from 'features/ticketList/services/actions';
import ticketListApis from 'features/ticketList/services/apis';
import ticketListSelectors from 'features/ticketList/services/selectors';
import { IApiUpdateSupplyFeeBody, Service, StaffItem } from 'features/ticketList/services/types/api';
import React, { useEffect, useMemo } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { formatPhone, maskPhone } from 'utils/formatPhone';
import { momentTimezone } from 'utils/time';
import EditStaffButton from '../components/ModalButton/EditStaffButton';
import credit_card_no_icon from './icons/credit-card.svg';
import cash_icon from './icons/payment/cash.svg';
import credit_card_icon from './icons/payment/credit-card.svg';
import other_icon from './icons/payment/epay.svg';
import gift_card_icon from './icons/payment/gift-card.svg';
import loyalty_point_icon from './icons/payment/loyalty-point.svg';
import refund_icon from './icons/refund.svg';
import voided_icon from './icons/voided.svg';
import { get } from 'lodash';
import toast from 'utils/toast';

const TicketInfoDetail = () => {
  const dispatch = useAppDispatch();
  const details = ticketListSelectors.ticketDetailsData.data();
  const creditPaymentItem = details?.payments?.find(o => o.paymentType === PAYMENT_TYPE.CREDIT_CARD);
  const [form] = Form.useForm();
  const setLoadingPage = useSetLoadingPage();
  useEffect(() => {
    if (!details) return;
    form.setFieldValue('items', details.items);
  }, [details]);

  const handleFinish = async (values: any) => {
    const data = values as ({ items: StaffItem[] });
    setLoadingPage(true);
    const items: { itemId: string; supplyFee: number }[] = [];
    data.items.map((item: StaffItem) => {
      item.services.map((service: Service) => {
        if (service.itemType !== 'SERVICE') return;
        items.push({
          itemId: service.id,
          supplyFee: service.supplyFee || 0,
        });
      });
    });

    const body: IApiUpdateSupplyFeeBody = {
      billId: details?.billId,
      items,
    };
    try {
      const res: IResponseDataBody<any> = await ticketListApis.updateSupplyFee(body);
      if (res.data.data) {
        dispatch(ticketListActions.getTicketDetails.fetch(details?.billId || ''));
        toast.success('Update supply fee successfully');
      }
    } catch (error) {
      const msg = get(error, 'response.data.message');
      toast.error(msg || 'Update supply fee failed');
    } finally {
      setLoadingPage(false);
    }
  };

  if (!details) return null;

  return (
    <Container>
      <div className="scroll-container">
        <ContainerTicketSummary>
          <StatusBox status={details?.billStatus} />
          <TicketSummaryStyled>
            <div className="ticket-number"><span>#{details.ticketNumber}</span></div>
            <div style={{ flex: 1 }}>
              <div className="create-date">{momentTimezone(details?.startTime).format('DD/MM/YYYY')} {momentTimezone(details?.startTime).format('hh:mm A')} - {momentTimezone(details?.endTime).format('hh:mm A')}</div>
              <div className="customer-name">{details?.customerName || 'Unknown Customer'}- {details?.customerPhone ? maskPhone(details?.customerPhone) : formatPhone('00000000000')}</div>
            </div>
            {creditPaymentItem && <div className="card-trans-container">
              <div className="card-num">
                <img src={credit_card_no_icon} alt="credit_card_no_icon" style={{ width: 40, height: 40 }} />
                <span>{creditPaymentItem.last4}</span>
              </div>
              <div className="card-trans"><span>Trans: #{creditPaymentItem?.transNumber || ''}</span></div>
            </div>}
          </TicketSummaryStyled>
        </ContainerTicketSummary>
        <Form form={form} className="staff-items" onFinish={handleFinish}>
          <Form.List name={'items'}>
            {(fields) => (
              <>
                {fields.map(field => (
                  <Form.Item name={field.name} key={field.key} noStyle>
                    <StaffFormItem billStatus={details?.billStatus} name={field.name} />
                  </Form.Item>
                ))}
              </>
            )}
          </Form.List>
          <Box display='flex' alignItems='center' gap='3' justifyContent='end' alignSelf='stretch' mt='1'>
            <Button htmlType='button' onClick={() => {
              console.log('cancel');
              form.setFieldValue('items', [...details.items]);

            }} cardCashier><span style={{ fontSize: 20 }}>Cancel</span></Button>
            <Button htmlType='submit' cardCashier ntype='PRIMARY'><span style={{ color: '#fff', fontSize: 20 }}>Update Supply Fee</span></Button>
          </Box>
        </Form>
      </div>
    </Container>
  );
};

export default TicketInfoDetail;
type StaffFormItemProps = {
  value?: StaffItem;
  billStatus?: string | undefined;
  name?: any;
};
const StaffFormItem = ({ value, billStatus, name }: StaffFormItemProps) => {
  if (!value) return null;
  return (
    <StaffItemUI billStatus={billStatus} data={value} name={name} />
  );
};

export const StatusBox = ({ status, className, reasonText = '', enableReason }: { status?: string, className?: string, reasonText?: string, enableReason?: boolean }) => {
  if (enableReason) {

    if (!reasonText) return null;

    switch (status) {
      case 'VOID':
        return (
          <StatusStyled className={className}>
            <div className="box-content">
              <span>Reason: {reasonText}</span>
            </div>
          </StatusStyled>
        );
      case 'REFUND':
        return (
          <StatusStyled className={clsx(className, 'REFUND')}>
            <div className="box-content">
              <span>Reason: {reasonText}</span>
            </div>
          </StatusStyled>
        );

      default:
        return null;
    }
  }
  switch (status) {
    case 'VOID':
      return (
        <StatusStyled className={className}>
          <div className="box-content">
            <img src={voided_icon} alt="voided_icon" style={{ width: 40, height: 40 }} />
            <span>Voided</span>
          </div>
        </StatusStyled>
      );
    case 'REFUND':
      return (
        <StatusStyled className={clsx(className, 'REFUND')}>
          <div className="box-content">
            <img src={refund_icon} alt="refund_icon" style={{ width: 40, height: 40 }} />
            <span>Refunded</span>
          </div>
        </StatusStyled>
      );

    default:
      return null;
  }
};
const ContainerTicketSummary = styled.div`  
border-radius: 4px;
background: #F6F7FC;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;
const StatusStyled = styled.div`
display: flex;
padding: 16px 16px;
justify-content: center;
align-items: center;
gap: 8px;
align-self: stretch;
border-radius: 4px 4px 0px 0px;
background: #FFE5E7;
.box-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  span {
    color: #D2464F;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 166.667% */
    text-transform: capitalize;
  }
}
&.REFUND {
  background: #FFEAD6;
  .box-content span {
    color: #C84B31;
  }
}
`;
const Container = styled.div`
flex: 1;
align-self: stretch;
position: relative;
.scroll-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 16px;
  position: absolute;
  padding-bottom: 40px;
  inset: 0;
  z-index: 99;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.staff-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
`;

const TicketSummaryStyled = styled.div`
  display: flex;
  padding: 24px;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 4px;
  background: #FFF;
  position: sticky;
  top: 0;
  z-index: 100;

  .ticket-number {
    display: flex;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #232F3E;
    span {
      color: #FFF;
      font-family: Poppins;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .create-date {
    color: #505050;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .customer-name {
    color: #1D2129;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .card-trans-container {
    display: flex;
    align-items: center;
    gap: 24px;
    .card-num {
      display: flex;
      align-items: center;
      gap: 12px;
      span {
        color: #1D2129;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .card-trans {
      display: flex;
      padding: 4px 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      background: #CBD5E1;
      span {
        color: #1D2129;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

  }

  
`;
type IStaffItemProps = {
  data: StaffItem;
  billStatus?: string;
  name?: any;
};
const StaffItemUI = ({ data, billStatus, name }: IStaffItemProps) => {
  const paymentAmounts = useMemo(() => [
    { type: PAYMENT_TYPE.CASH, value: data.cashAmount },
    { type: PAYMENT_TYPE.CREDIT_CARD, value: data.creditAmount },
    { type: PAYMENT_TYPE.LOYALTY_POINT, value: data.loyaltyAmount },
    { type: PAYMENT_TYPE.CHECK, value: data.checkAmount },
    { type: PAYMENT_TYPE.GIFT_CARD, value: data.giftCardAmount },
    { type: 'other', value: data.otherAmount },
  ].filter(o => !!o.value), [data]);
  const isVoided = billStatus === 'VOID';
  return (
    <StaffItemStyled>
      <div className="staff-avatar-container">
        <Avatar src={data.avatar || 'Fail'} size={100}>{data.staffName?.[0] || 'A'}</Avatar>
        <div className="staff-name ">{data.staffName || 'Anyone'}</div>
        {!isVoided && <EditStaffButton v2 staffId={data?.staffId} />}
      </div>
      <div className="staff-info-container">
        <div className="info-section" style={{ gap: 16 }}>
          <Form.List name={[name, 'services']}>
            {(fields) => (
              <>
                {fields.map(o => (
                  <Form.Item name={o.name} key={o.key} noStyle>
                    <ServiceItem name={o.name} />
                  </Form.Item>
                ))}
                <Text mt={0.25} />
              </>
            )}
          </Form.List>
        </div>
        <div className="info-section" style={{ border: 'none' }}>
          <div className="info-section-item">
            <span className='service-name'>Tip:</span>
            <div style={{ flex: 1 }} />
            <span className="total tip">{formatCurrency(data.tip)}</span>
          </div>
          <div className="info-section-item">
            <span className='service-name'>Subtotal:</span>
            <div style={{ flex: 1 }} />
            <span className="total sub-total">{formatCurrency(data.subTotal)}</span>
          </div>
        </div>
        <div className="payments">
          {paymentAmounts.map((o, i) => (
            <React.Fragment key={o.type}>
              <PaymentItem key={o.type} type={o.type} value={o.value} />
              {paymentAmounts.length - 1 !== i && <div className="divider" />}
            </React.Fragment>
          ))}
        </div>
      </div>
    </StaffItemStyled>
  );
};
type ServiceItemProps = {
  value?: Service;
  name?: any;
};
const ServiceItem = ({ value: ser, name }: ServiceItemProps) => {
  if (!ser) return null;

  return (<div key={ser.itemId} className="info-section-item">
    <span className='service-name'>{ser.itemName}</span>
    {ser.itemType === 'SERVICE' && <SupplyFee>
      <span>Supply fee:</span>
      <Form.Item name={[name, 'supplyFee']} noStyle>
        <DollarInputPayment withForm />
      </Form.Item>
    </SupplyFee>}
    <div style={{ flex: 1 }} />
    <span className="total">{formatCurrency(ser.price)}</span>
  </div>);
};
const SupplyFee = styled.div`
display: flex;
align-items: center;
gap: 16px;
span {
  color: #2D6772;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dollar-input-selector {
  display: flex;
  width: 150px;
  padding: 16px 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #86909C;
  background: #F5F5F5;
  .dollar-input-text {
    color: #1D2129;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  input.dollar-input-text {
    background: #F5F5F5;
  }
}
`;

const StaffItemStyled = styled.div`
display: flex;
padding: 16px 0px;
align-items: flex-start;
gap: 16px;
align-self: stretch;
border-radius: 4px;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

.staff-avatar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0 24px;
  .staff-name {
    color: #1D2129;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    min-width: 140px;
    max-width: 140px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-avatar .ant-avatar-string {
    transform: scale(2) translateX(-50%) !important;
  }
}

.payments {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 0px;
  padding-right: 24px;
  margin: 0 -16px;
  .divider { 
    width: 1px;
    align-self: stretch;
    background: #CCD4DC;
  }
}

.staff-info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  flex: 1 0 0;
  .info-section {
    display: flex;
    padding: 0px 24px 16px 24px;
    padding-bottom: 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-bottom: 1px dashed #86909C;
    .info-section-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      .service-name {
        width: 300px;
        flex-shrink: 0;
        color: #1D2129;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .supply-fee {
        color: #2D6772;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      span.total {
        color: #1D2129;
        text-align: right;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      span.tip {
        color: #DA072D;
      }
      span.sub-total {
        color: #293A80;
      }
    }
  }
}
`;
type PaymentItemProps = {
  type: string;
  value?: number;
};
const PaymentItem = ({ type, value }: PaymentItemProps) => {
  if (type === 'other' || type === PAYMENT_TYPE.CHECK) {
    return (
      <PaymentItemStyled>
        <img src={other_icon} alt="other_icon" className="icon" />
        <span>{type === PAYMENT_TYPE.CHECK ? 'Check' : 'Other'}:</span>
        <span className="price">{formatCurrency(value)}</span>
      </PaymentItemStyled>
    );
  }
  if (type === PAYMENT_TYPE.LOYALTY_POINT) {
    return (
      <PaymentItemStyled>
        <img src={loyalty_point_icon} alt="loyalty_point_icon" className="icon" />
        <span>Point:</span>
        <span className="price">{formatCurrency(value)}</span>
      </PaymentItemStyled>
    );
  }
  if (type === PAYMENT_TYPE.GIFT_CARD) {
    return (
      <PaymentItemStyled>
        <img src={gift_card_icon} alt="gift_card_icon" className="icon" />
        <span>Gift Card:</span>
        <span className="price">{formatCurrency(value)}</span>
      </PaymentItemStyled>
    );
  }
  if (type === PAYMENT_TYPE.CASH) {
    return (
      <PaymentItemStyled>
        <img src={cash_icon} alt="cash_icon" className="icon" />
        <span>Cash:</span>
        <span className="price">{formatCurrency(value)}</span>
      </PaymentItemStyled>
    );
  }
  if (type === PAYMENT_TYPE.CREDIT_CARD)
    return (
      <PaymentItemStyled>
        <img src={credit_card_icon} alt="credit_card_icon" className="icon" />
        <span>Credit Card:</span>
        <span className="price">{formatCurrency(value)}</span>
      </PaymentItemStyled>
    );
  return null;
};
const PaymentItemStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  img.icon {
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
span {
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &.price {
    margin-left: 24px;
    font-weight: 600;
  }
}
`;