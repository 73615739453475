import { Form, Input } from 'antd';
import Box from 'components/Box';
import PercentDecimalInputPrecision from 'components/PercentDecimalInputPrecision';
import styled from 'styled-components';
import FromToInput from '../../QuickpayTab/FromToInput';
import SwitchBtn from '../../ServicesSettingV2/SwitchBtn';
import add_new_icon from '../../v2-icons/add-new-icon.svg';
import discount_delete_icon from '../../v2-icons/discount-delete.svg';
import ActionButtons from '../components/ActionButtons';
import SectionV2 from '../components/SectionV2';
import StaffListChooseUI from './StaffListChooseUI';

const FormV2 = () => {
  return (
    <Container>
      <CommissionGroup>
        <div className="section-header">
          <Box display="flex" gap="3" alignItems="center">
            <Form.Item name="divideCommissions" noStyle>
              <SwitchBtn />
            </Form.Item>
            <span className='section-label'>
              Divide commissions to Staff
            </span>
          </Box>
        </div>
        <div className="commission-content-container">
          <ContainerStaffList>
            <Form.Item noStyle name={'staffSelected'}>
              <StaffListChooseUI />
            </Form.Item>
          </ContainerStaffList>
          <OptionCommissions>
            <table>
              <thead>
                <tr>
                  <th>Range sale</th>
                  <th>Comm fee</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <Form.List name="optionCommissions">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <tr key={key}>
                          <td>
                            <FromToInput
                              formListName="optionCommissions"
                              name={name}
                            />
                          </td>
                          <td>
                            <Form.Item
                              {...restField}
                              name={[name, 'commission']}
                              noStyle
                            >
                              <PercentDecimalInputPrecision />
                            </Form.Item>
                          </td>
                          <td>
                            <button type='button' className="center" onClick={() => remove(name)} >
                              <img src={discount_delete_icon} alt='discount_delete_icon' style={{ width: 32, height: 32 }} />
                            </button>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={3}>
                          <button
                            type='button'
                            className='btn-add-new'
                            onClick={() => add({ commission: 0 })}
                          >
                            <img src={add_new_icon} alt="add_new_icon" style={{ width: 24, height: 24 }} />
                            <span>Add New</span>
                          </button>
                        </td>
                      </tr>
                    </>
                  )}
                </Form.List>
              </tbody>
            </table>
          </OptionCommissions>
        </div>
      </CommissionGroup>
      <SectionV2 style={{ marginTop: 16 }}>
        <span className="section-label">Max Reset Time</span>
        <Form.Item name="maxReset" noStyle>
          <Input onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }} />
        </Form.Item>
      </SectionV2>
      <ActionButtons />
    </Container>
  );
};

export default FormV2;

const Container = styled.div``;

const OptionCommissions = styled.div`
display: flex;
padding: 16px 24px;
flex-direction: column;
align-items: flex-start;
gap: 10px;
flex: 1 0 0;
align-self: stretch;
border-right: 1px solid #CCD4DC;

table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 4px;
  overflow: hidden;

  border-radius: 4px;
  border: 1px solid #CCD4DC;
  background: #F6F7FC;

  thead tr th {
    border-bottom: 1px solid #CCD4DC;
    background: #E5E6EB;
    color: #505050;
    padding: 6px 0;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }

  tbody tr td {
    padding: 16px 8px;
    align-items: center;
    gap: 24px;
    align-self: stretch;

    .ant-input {
      color: #1D2129;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .percent-decimal-input-precision .ant-input {
      text-align: right;
    }
  }
}

.btn-add-new {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: #6FABB6;
  box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -4px 0px 0px #144B55 inset;
  span {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
`;

const CommissionGroup = styled.div`
border-bottom: 1px solid #CCD4DC;
display: flex;
flex-direction: column;
flex: 1;
.section-header {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-top: 1px solid #CCD4DC;
  border-right: 1px solid #CCD4DC;
  border-left: 1px solid #CCD4DC;
  border-bottom: 1px solid #86909C;
  background: #F6F7FC;

}
.commission-content-container {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex: 1;
}
`;

const ContainerStaffList = styled.div`
  flex: 1;
  height: 60vh;
  position: relative;
  display: flex;
  border-right: 1px solid #CCD4DC;
  border-left: 1px solid #CCD4DC;
`;