import fetch from 'services/request';
import { IApiBodyMergeTicket, IApiBodySplitTicket, IApiBodyVoidTicketOpen, ICreateCustomerBody, ISaveApiBodyType } from './types/api';

const baseURL = 'api/v1';

export const getDetailsTicket = (checkInId: string) => {
  return fetch({
    method: 'get',
    url: `${baseURL}/check-in/detail/${checkInId}`,
  });
};

export const getDetailBill = (checkInId: string) => {
  return fetch({
    method: 'get',
    url: `${baseURL}/bill/detail/${checkInId}`,
  });
};

export const getCategories = () => {
  return fetch({
    method: 'get',
    url: `${baseURL}/category/get-all-by-category`,
  });
};

export const getAddOnList = () => {
  return fetch({
    method: 'get',
    url: `${baseURL}/service/list-add-on`,
  });
};

export const getStaffList = () => {
  const staffType = 'TECHNICAL';
  return fetch({
    method: 'get',
    url: `${baseURL}/staff/get-all-by-shop`,
    params: { staffType },
  });
};

export const editTicket = (body: ISaveApiBodyType) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/bill/${body.billId ? 'update-ticket' : 'save-ticket'}`,
    body,
  });
};

export const editTicketBackground = (body: ISaveApiBodyType) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/bill/${body.billId ? 'update-ticket' : 'save-ticket'}`,
    body,
    autoToast: true,
  });
};

export const getCustomerList = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/customer/list-active',
    params: { page: 1, size: 999999 },
  });
};

export const addNewCustomer = (body: ICreateCustomerBody) => {
  return fetch({
    method: 'post',
    url: 'api/v1/customer/create',
    body,
  });
};

export const addTicket = () => {
  return fetch({
    method: 'post',
    url: 'api/v1/bill/add-new-ticket',
    autoToast: false,
  });
};

export const getNumberTicket = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/bill/get-number-ticket',
    autoToast: false,
  });
};

export const getGiftCardInfo = (giftCode: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/gift-card/get-by-code',
    params: { giftCode },
  });
};

export const mergeTickets = (body: IApiBodyMergeTicket) => {
  return fetch({
    method: 'post',
    url: 'api/v1/bill/merge-ticket',
    body,
  });
};

export const splitTicket = (body: IApiBodySplitTicket) => {
  return fetch({
    method: 'post',
    url: 'api/v1/bill/split-ticket',
    body,
  });
};


export const getVIPPackageWithCustomerId = (customerId: string) => {
  return fetch({
    method: 'get',
    url: `api/v1/customer/discount-ticket/${customerId}`,
  });
};

export const voidTicketOpen = (body: IApiBodyVoidTicketOpen) => {
  return fetch({
    method: 'post',
    url: '/api/v1/payment/void-ticket-open',
    body
  });
};

const checkReferralSender = (customerId: string, phoneReferral: string) => {
  return fetch({
    method: 'post',
    url: '/api/v1/customer/check-referral-sender',
    body: {
      customerId,
      phoneReferral,
    }
  });
};

const cashierApis = {
  getDetailsTicket,
  getDetailBill,
  getCategories,
  getAddOnList,
  getStaffList,
  editTicket,
  getCustomerList,
  addNewCustomer,
  addTicket,
  getGiftCardInfo,
  mergeTickets,
  splitTicket,
  getVIPPackageWithCustomerId,
  getNumberTicket,
  editTicketBackground,
  voidTicketOpen,
  checkReferralSender,
};

export default cashierApis;
