import { useTicketWrapper } from 'features/ShopWrapperLayout/TicketWrapperContext';
import { parseDecimal } from 'features/cashier/services/constants';
import cashierSelectors from 'features/cashier/services/selectors';
import ConfirmTipTicket, { useConfirmTipTicketRef } from 'features/payment/components/ConfirmTipTicket';
import Signature, { ISignatureRef } from 'features/payment/components/CustomerSignature';
import PaymentLoading, { IPaymentLoadingRef } from 'features/payment/components/PaymentLoading';
import CreditCardLoading, { ICreditPaymentLoadingRef } from 'features/payment/components/PaymentLoading/CreditCardLoading';
import useSMSEmailSignature from 'features/payment/hooks/useSMSEmailSignature';
import BillPaymentConfirmModal, { useBillPaymentConfirmModalRef } from 'features/payment/pages/PaymentPage/CashierSide/BillPaymentConfirmModal';
import AddATip, { ModalAddTipRef } from 'features/payment/pages/PaymentPage/CustomerSide/components/AddATip';
import multiplePaymentActions from 'features/payment/pages/PaymentPage/services/actions';
import multiplePaymentSelectors from 'features/payment/pages/PaymentPage/services/selectors';
import { ICustomerReceiveConfigs, PaymentSocketData, TypePaymentActors, TypePaymentPassData } from 'features/payment/pages/PaymentPage/services/types/socketPayment';
import paymentApis from 'features/payment/services/apis';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import { IBodyAPIPayment, IPaymentInfoItem } from 'features/payment/services/types/api';
import { IBillDetailData } from 'features/payment/services/types/bill';
import { useSocketContext } from 'hooks/useSocket';
import { set } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IResponseDataBody } from 'services/response';
import { Message } from 'stompjs';
import { useAppDispatch } from 'store/hooks';
import { removeBase64Prefix } from 'utils/removeBase64Prefix';
import storage from 'utils/sessionStorage';
import { PAYMENT_PASS_DATA_TOPIC } from 'utils/socket';
import CashierActionButton from '../../CashierActionBtn';
import useUpdateTicket from '../../useUpdateTicket';
import paymentActions from 'features/payment/services/actions';
type Props = {
  v2?: boolean;
};
const CreditCardTipBeforePayment = ({ v2 }: Props) => {
  const navigate = useNavigate();
  const isCashierActorDoPostPayment = useRef(true);
  const billConfirmRef = useBillPaymentConfirmModalRef();
  const dispatch = useAppDispatch();
  const updateTicket = useUpdateTicket();
  const loadingRef = useRef<IPaymentLoadingRef>(null);
  const creditCardLoadingRef = useRef<ICreditPaymentLoadingRef>(null);
  const loadingSignatureRef = useRef<IPaymentLoadingRef>(null);
  const completedTicketModalRef = useConfirmTipTicketRef();
  const ticketContext = useTicketWrapper();
  const tipRef = useRef<ModalAddTipRef>(null);
  const cashierMsg = multiplePaymentSelectors.getCashierSocketMsg();
  const enableCardFee = cashierSelectors.getEnableCardFee();
  const detailRef = useRef<IBillDetailData | null>(null);

  const socketContext = useSocketContext();
  const signatureRef = useRef<ISignatureRef>(null);
  const [detail, setDetailTicket] = useState<IBillDetailData | null>(null);
  const SMSEmailSignature = useSMSEmailSignature();

  const total = useMemo(() => {
    return (detail?.total || 0) + (detail?.cardFee || 0);
  }, [detail]);

  const onDone = async (ignorePrint?: boolean) => {
    loadingRef.current?.setVisible(false);
    creditCardLoadingRef.current?.setVisible(false);
    loadingSignatureRef.current?.setVisible(false);
    const id = detailRef.current?.billId || '';
    dispatch(multiplePaymentActions.setCashierMsg.fetch(null));
    socketContext.switchCustomerScreen(`/store/${storage.shop_id.get()}/ticket/payment/customer-side/rating/${id}`);

    const callback = () => {
      if (ignorePrint) {
        ticketContext.doPrintNoReceipt(id, true);
        navigate(`/store/${storage.shop_id.get()}`);
        return;
      }
      ticketContext.completedTicket(id, true);
    };
    if (!completedTicketModalRef.current) return callback();
    completedTicketModalRef.current.open(id, callback);
  };

  const listeningData = (message: Message) => {
    if (!message.body) return;
    const payment: PaymentSocketData = JSON.parse(message.body);
    if (!payment) return;
    if (payment.actor !== TypePaymentActors.CUSTOMER) return;

    switch (payment.action) {
      case TypePaymentPassData.COMPLETED_PAYMENT: {
        onDone();
        break;
      }
      case TypePaymentPassData.COMPLETED_PAYMENT_WITHOUT_RECEIPT: {
        onDone(true);
        break;
      }
      case TypePaymentPassData.TIP_BEFORE_PAYMENT_SEND_TIP_VALUE: {
        if (detailRef.current) {
          isCashierActorDoPostPayment.current = false;
          postPayment(detailRef.current.billId, detailRef.current, payment.data?.tip || 0);
        }
        break;
      }
      case TypePaymentPassData.TIP_BEFORE_PAYMENT_SEND_TIP_AFTER_TYPING: {
        tipRef.current?.setSoftAmount(payment.data?.tip || 0);
        break;
      }
      case TypePaymentPassData.TIP_BEFORE_PAYMENT_REQUEST_TIP: {
        tipRef.current?.open();
        break;
      }
      case TypePaymentPassData.TIP_BEFORE_PAYMENT_REQUEST_CANCEL: {
        tipRef.current?.close();
        billConfirmRef.current?.close();
        break;
      }
      case TypePaymentPassData.ON_CUSTOMER_SIGNATURE_CHANGE: {
        signatureRef.current?.setValue(payment.data);
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (!cashierMsg) return;
    listeningData(cashierMsg);
  }, [cashierMsg]);

  const getDetailTicket = async (billId: string) => {
    try {
      const res: IResponseDataBody<IBillDetailData> = await paymentApis.getBillDetail(billId);
      if (res.data.data) return res.data.data;
      else return null;
    } catch (error) {
      return null;
    }
  };

  const postPayment = async (billId: string, detailTicket: IBillDetailData, tip: number) => {
    tipRef.current?.close();
    const payment: IPaymentInfoItem = {
      paymentType: PAYMENT_TYPE.CREDIT_CARD,
      amount: detailTicket.total,
    };
    if (tip) {
      set(payment, 'tip', tip);
    }

    const body: IBodyAPIPayment = {
      billId,
      paymentInfo: [payment]
    };
    const finalTotal = billConfirmRef.current?.getTotal() || detailTicket.total;
    creditCardLoadingRef.current?.open(parseDecimal(finalTotal + (payment.tip || 0)), 120000);
    try {
      const res: IResponseDataBody<true> = await paymentApis.payment(body);
      if (res?.data?.data) {
        signatureRef.current?.open();
        socketContext.send(PAYMENT_PASS_DATA_TOPIC, {
          billId: detailTicket?.billId || '',
          actor: TypePaymentActors.CASHIER,
          action: TypePaymentPassData.TIP_BEFORE_PAYMENT_REQUEST_SIGNATURE
        });

      } else {
        throw 'fail';
      }
    } catch (error) {
      socketContext.backToCheckIn();
      billConfirmRef.current?.close();
    } finally {
      creditCardLoadingRef.current?.setVisible(false);
    }
  };

  const handleAddATip = async (val: number) => {
    if (!detailRef.current) return;
    socketContext.send(PAYMENT_PASS_DATA_TOPIC, {
      billId: detailRef.current?.billId || '',
      actor: TypePaymentActors.CASHIER,
      action: TypePaymentPassData.TIP_BEFORE_PAYMENT_SEND_TIP_VALUE,
      data: { tip: val },
    });
    isCashierActorDoPostPayment.current = true;
    await postPayment(detailRef.current.billId, detailRef.current, val || 0);
  };
  const onSkipAddATip = async () => {
    if (!detailRef.current) return;
    isCashierActorDoPostPayment.current = true;
    // call open credit dialog
    await postPayment(detailRef.current.billId, detailRef.current, 0);
  };

  const onCard = async () => {
    detailRef.current = null;
    try {
      const rest: { billId: string } | null = await updateTicket();
      if (!rest) return;
      const billId = rest.billId;
      const detailTicket = await getDetailTicket(billId);
      if (!detailTicket) throw 'fail';
      const screen = `/store/${storage.shop_id.get()}/ticket/payment/customer-side/${billId}`;
      socketContext.switchCustomerScreen(screen);
      detailRef.current = detailTicket;
      setDetailTicket(detailTicket);
      billConfirmRef.current?.open(billId);
      setTimeout(() => {
        const configs: ICustomerReceiveConfigs = {
          creditCard: true,
          enableTipBeforeMode: true,
          enableCardFee,
        };
        socketContext.send(PAYMENT_PASS_DATA_TOPIC, {
          billId: detailRef.current?.billId || '',
          actor: TypePaymentActors.CASHIER,
          action: TypePaymentPassData.CUSTOMER_RECEIVE_CONFIGS,
          data: configs,
        });
        dispatch(multiplePaymentActions.customerSide.setReceiveConfigs(configs));
      }, 100);
    } catch (error) { }
  };

  const onConfirm = () => {
    if (!detailRef.current) return;
    tipRef.current?.open();
    socketContext.send(PAYMENT_PASS_DATA_TOPIC, {
      billId: detailRef.current?.billId || '',
      actor: TypePaymentActors.CASHIER,
      action: TypePaymentPassData.TIP_BEFORE_PAYMENT_REQUEST_TIP
    });
  };

  const handleClose = () => {
    socketContext.backToCheckIn();
    navigate(`/store/${storage.shop_id.get()}`);
  };
  const onCreditCardCancel = () => {
    dispatch(paymentActions.cancelTransaction.fetch(detailRef.current?.billId || ''));
    socketContext.backToCheckIn();
    navigate(`/store/${storage.shop_id.get()}`);
  };

  const handleChangeTip = (val: number) => {
    socketContext.send(PAYMENT_PASS_DATA_TOPIC, {
      billId: detailRef.current?.billId || '',
      actor: TypePaymentActors.CASHIER,
      action: TypePaymentPassData.TIP_BEFORE_PAYMENT_SEND_TIP_AFTER_TYPING,
      data: { tip: val }
    });
  };

  const handleCancelTipBefore = () => {
    socketContext.backToCheckIn();
    tipRef.current?.close();
    billConfirmRef.current?.close();
  };

  const onSignatureDone = async (signatureValue: string) => {
    signatureRef.current?.close();
    loadingRef.current?.setVisible(true);
    try {
      const res: IResponseDataBody<boolean> = await paymentApis.updateSignature(detailRef.current?.billId || '', removeBase64Prefix(signatureValue));
      if (res.data.data) {
        onDone();
        return;
      }
    } catch (error) { }
    finally {
      loadingRef.current?.setVisible(false);
    }
  };

  return (
    <div style={{ alignSelf: 'stretch', display: 'flex' }}>
      <CashierActionButton feature='CARD' v2UI={v2} ntype='LIGHT_BLUE' label='Card' onClick={onCard} />
      <BillPaymentConfirmModal ref={billConfirmRef} onConfirm={onConfirm} onClose={handleClose} />
      <AddATip
        total={total}
        onChangeTip={handleChangeTip}
        isTipBefore ref={tipRef}
        onAddATip={handleAddATip} onCancel={handleCancelTipBefore}
        onSkipAddATip={onSkipAddATip}
      />
      <PaymentLoading ref={loadingRef} />
      <CreditCardLoading ref={creditCardLoadingRef} />
      <PaymentLoading ref={loadingSignatureRef} />
      <Signature
        ref={signatureRef}
        onAgree={onSignatureDone}
        onNoReceipt={SMSEmailSignature.onNoReceipt(detailRef.current?.billId || '', () => onDone(true))}
        onEmail={SMSEmailSignature.onEmail(detailRef.current?.billId || '', () => onDone(true))}
        onSMS={SMSEmailSignature.onSMS(detailRef.current?.billId || '', () => onDone(true))}
        defaultSMSPhone={detail?.customerPhone}
      />
      <ConfirmTipTicket ref={completedTicketModalRef} />
    </div>
  );
};

export default CreditCardTipBeforePayment;