import React from 'react';
import { Col, DatePicker, Form, Row } from 'antd';
import styled from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';
import { RangePickerProps } from 'antd/es/date-picker';
import Text from 'components/Text';
import Box from 'components/Box';
import Button from 'components/Button';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useAppDispatch } from 'store/hooks';
import settingStaffActions from 'features/settingStaff/services/actions';
import settingStaffSelectors from 'features/settingStaff/services/selectors';
import Icon from 'assets/Icons';
import { momentTimezone } from 'utils/time';
const { RangePicker } = DatePicker;

type IDaysOffBoxProps = {
  v2?: boolean;
};
const DaysOffBox: React.FC<IDaysOffBoxProps> = ({ v2 }) => {
  const form = useFormInstance();
  const dispatch = useAppDispatch();

  const staffSelected = settingStaffSelectors.selectedStaffData.data();
  const disableDateList = settingStaffSelectors.selectedStaffData.dataDaysOff();
  const dateRangerWatch = Form.useWatch('date_ranger', form);

  const totalDays = disableDateList.reduce((sum, data) => {
    const startTime = momentTimezone(data.startTime);
    const endTime = momentTimezone(data.endTime);
    const daysCount = endTime.diff(startTime, 'day') + 1;
    return sum + daysCount;
  }, 0);

  const isDateRangeFilled = () => {
    return dateRangerWatch && dateRangerWatch[0] && dateRangerWatch[1];
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    const currentStartOfDay = current?.startOf('day');
    return disableDateList.some(({ startTime, endTime }) => {
      const startDate: Dayjs = dayjs(startTime, 'MM-DD-YYYY').startOf('day');
      const endDate: Dayjs = dayjs(endTime, 'MM-DD-YYYY').startOf('day');
      return (
        currentStartOfDay &&
        (currentStartOfDay.isAfter(startDate) ||
          currentStartOfDay.isSame(startDate)) &&
        (currentStartOfDay.isBefore(endDate) ||
          currentStartOfDay.isSame(endDate))
      );
    });
  };

  const handleCreateDayOff = () => {
    const rangerDate = form.getFieldValue('date_ranger');
    const startTime = dayjs(rangerDate[0]).format('MM-DD-YYYY 00:00:00');
    const endTime = dayjs(rangerDate[1]).format('MM-DD-YYYY 23:59:00');
    const param = {
      staffId: staffSelected.id,
      startTime,
      endTime,
    };
    form.setFieldsValue({ date_ranger: null });
    dispatch(settingStaffActions.createStaffDayOff.fetch(param));
  };

  const handleDelete = (id: string) => {
    dispatch(settingStaffActions.deleteStaffDayOff.fetch(id));
  };

  return (
    <DaysOffBoxStyled>
      <Box>
        {v2 && (
          <Box mb='2' mt='2'>
            <Text variant='H7' color='text_3'>
              Select To Create Day Off
            </Text>
          </Box>
        )}
        <Row style={v2 ? { margin: 0 } : {}}>
          {!v2 && (
            <Col span={5}>
              <Box className='center' height={'100%'}>
                <Text variant='H7' color='text_3'>
                  Select To Create Day Off
                </Text>
              </Box>
            </Col>
          )}
          <Col span={!v2 ? 19 : 24}>
            <Box width='100%'>
              <RangerContainerStyled>
                <Box width='100%'>
                  <ItemFormStyled
                    noStyle={v2}
                    name='date_ranger'
                    label='Select Time'
                  >
                    {v2 ? (
                      <DateRangeSelector
                        disabledDate={disabledDate}
                        placement='bottomLeft'
                      />
                    ) : (
                      <RangePicker
                        style={{ height: '4rem' }}
                        // renderExtraFooter={() => 'extra footer'}
                        disabledDate={disabledDate}
                        placement='bottomLeft'
                      />
                    )}
                  </ItemFormStyled>
                </Box>

                <Button
                  size='large'
                  disabled={!isDateRangeFilled()}
                  ntype='PRIMARY'
                  onClick={handleCreateDayOff}
                  cardCashier={v2}
                >
                  {v2 ? (
                    <span
                      style={{ color: '#fff', fontWeight: '600', fontSize: 16 }}
                    >
                      Create
                    </span>
                  ) : (
                    'Create'
                  )}
                </Button>
              </RangerContainerStyled>
            </Box>
          </Col>
        </Row>
      </Box>

      <Box mt='4'>
        {v2 && (
          <NumberOfDayOff>
            <Text variant='BODY_1' color='text_3'>
              Number Of Day Off:
            </Text>
            <Text variant='H2' color='text_3'>
              {totalDays} days
            </Text>
          </NumberOfDayOff>
        )}
        <Row style={v2 ? { margin: 0 } : {}}>
          {!v2 && (
            <Col span={5}>
              <Box>
                <Text variant='H7' color='text_3'>
                  Number Of Day Off
                </Text>
                <Text variant='H2' color='text_3'>
                  {totalDays} days
                </Text>
              </Box>
            </Col>
          )}
          <Col span={v2 ? 24 : 19}>
            <Row gutter={[16, 16]}>
              {disableDateList.map((item) => {
                const startTime = momentTimezone(item.startTime);
                const endTime = momentTimezone(item.endTime);
                const daysCount = endTime.diff(startTime, 'day') + 1;
                return (
                  <Col
                    key={item.id}
                    xs={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 12 }}
                    xl={{ span: 12 }}
                  >
                    <Box display='flex' gap='2'>
                      <Box
                        display='flex'
                        alignItems='center'
                        className='item-grow'
                        gap='1'
                      >
                        <Icon type='calendar' />{' '}
                        <Text variant='H8' color='text_3'>
                          {startTime.format('MM-DD-YYYY')}
                        </Text>
                      </Box>
                      <Box
                        display='flex'
                        alignItems='center'
                        className='item-grow'
                        gap='1'
                      >
                        <Icon type='calendar' />{' '}
                        <Text variant='H8' color='text_3'>
                          {endTime.format('MM-DD-YYYY')}
                        </Text>
                      </Box>
                      <Box
                        display='flex'
                        alignItems='center'
                        onClick={() => handleDelete(item.id)}
                      >
                        <Icon type='circleClose' />
                      </Box>
                    </Box>
                    <Text variant='H9' color='primary_active'>
                      {daysCount} Day Off
                    </Text>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Box>
    </DaysOffBoxStyled>
  );
};

export default DaysOffBox;

const DaysOffBoxStyled = styled.div`
  margin-top: 1.5rem;
  padding: 1rem 0;
  border-top: 1px solid #c9cdd4;
  .item-grow {
    flex-grow: 1;
  }
`;
const ItemFormStyled = styled(Form.Item)`
  margin: 0 !important;
`;
const RangerContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 1rem;
`;

const NumberOfDayOff = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .BODY_1 {
    color: #1d2129;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .H2 {
    color: #d2464f;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

const DateRangeWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledRangePicker = styled(RangePicker)`
  height: 4rem;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  padding: 0 16px;

  .ant-picker-input {
    flex: 1;

    &:first-child {
      margin-left: 60px;
    }
  }

  .ant-picker-suffix {
    color: #7c8db5;
  }

  .ant-picker-range-separator {
    padding: 0 16px;
    color: #7c8db5;
    font-size: 18px;
    font-weight: 500;
  }

  input {
    font-family: Poppins;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    color: #333;
    &::placeholder {
      color: #7c8db5;
      font-family: Poppins;
      font-weight: 500;
      font-size: 18px;
    }
  }

  &:hover,
  &:focus {
    border-color: #d2464f;
    box-shadow: 0 0 0 2px rgba(210, 70, 79, 0.1);
  }

  &.ant-picker-focused {
    border-color: #d2464f;
    box-shadow: 0 0 0 2px rgba(210, 70, 79, 0.1);
  }
`;

const ToLabel = styled.span`
  font-family: Poppins;
  font-weight: 500;
  font-size: 18px;
  color: #7c8db5;
`;

const FromLabel = styled(ToLabel)`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`;
interface DateRangeSelectorProps extends Omit<RangePickerProps, 'onChange'> {
  value?: RangePickerProps['value'];
  onChange?: (
    value: RangePickerProps['value'],
    dateStrings: [string, string]
  ) => void;
  className?: string;
}

const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({
  value,
  onChange,
  disabledDate,
  placement = 'bottomLeft',
  format = 'MM-DD-YYYY',
  className,
  ...restProps
}) => {
  const handleChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (onChange) {
      onChange(dates, dateStrings as [string, string]);
    }
  };

  return (
    <DateRangeWrapper className={className}>
      <FromLabel>From:</FromLabel>
      <StyledRangePicker
        placeholder={['mm-dd-yyyy', 'mm-dd-yyyy']}
        format={format}
        separator={<ToLabel>To:</ToLabel>}
        disabledDate={disabledDate}
        placement={placement}
        onChange={handleChange}
        value={value}
        {...restProps}
      />
    </DateRangeWrapper>
  );
};
