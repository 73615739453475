import { Divider, Form, InputNumber, Switch } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import { ITaxParams } from 'features/settingService/services/types/paymentSetting';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import ActionButtons from './components/ActionButtons';
import SwitchBtn from '../ServicesSettingV2/SwitchBtn';
type ITaxProps = {
  v2?: boolean;
};
const Tax: React.FC<ITaxProps> = ({ v2 }) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<ITaxParams>();
  const taxStore = settingServiceSelectors.paymentSetting.tax();

  const saleTaxWatch = Form.useWatch('enableSaleTax', form);
  const usedTaxWatch = Form.useWatch('enableUseTax', form);

  const onFinish = (values: ITaxParams) => {
    const body = { ...taxStore, ...values };
    dispatch(settingServiceActions.postTax.fetch(body));
  };

  useEffect(() => {
    if (taxStore) {
      form.setFieldsValue(taxStore);
    }
  }, [taxStore]);

  useEffect(() => {
    dispatch(settingServiceActions.getTax.fetch());
  }, []);

  return (
    <TaxStyled className={v2 ? 'v2' : ''}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ saleTaxPercent: 0, useTaxPercent: 0 }}
      >
        {v2 ? <TaxFormV2 /> :
          <>
            <Box bgColor="fill_1" p="3" borderRadius="5px">
              <Box display="flex" gap="3" alignItems="center">
                <FormItemStyled name="enableSaleTax" valuePropName="checked">
                  <Switch />
                </FormItemStyled>
                <Text variant="CONTENT_2" color="text_3">
                  Sale Tax
                </Text>
              </Box>
              {saleTaxWatch && (
                <Form.Item name="saleTaxPercent" label="Sale Tax">
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}%`}
                    parser={(value: any) => value?.replace('%', '')}
                  />
                </Form.Item>
              )}
              <Divider />
              <Box display="flex" gap="3" alignItems="center">
                <FormItemStyled name="enableUseTax" valuePropName="checked">
                  <Switch />
                </FormItemStyled>
                <Text variant="CONTENT_2" color="text_3">
                  Used Tax
                </Text>
              </Box>
              {usedTaxWatch && (
                <Form.Item
                  label="Used Tax"
                  name="useTaxPercent"
                  style={{ width: '100%' }}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}%`}
                    parser={(value: any) => value?.replace('%', '')}
                  />
                </Form.Item>
              )}
            </Box>
            <Box display="flex" gap="3" mt="4" justifyContent="end">
              <Button width="10rem" ntype="SECONDARY" htmlType="reset">
                Reset
              </Button>
              <Form.Item>
                <Button width="10rem" ntype="PRIMARY" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Box>
          </>}
      </Form>
    </TaxStyled>
  );
};

export default Tax;

const TaxStyled = styled.div`
  flex-grow: 1;
  background: var(--fill-fill-3, #e5e5e5);
  border-radius: 5px;
  .ant-input-number-input-wrap {
    height: 100%;
    input {
      height: 100%;
      color: var(--text-text-3, #1d2129);
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  &.v2 .ant-form {
    height: 100%;
  }
`;
const FormItemStyled = styled(Form.Item)`
  margin: 0;
`;

const TaxFormV2Styled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  .quick-pay {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
    background: #fff;

    .quick-pay-form {
      display: flex;
      height: 500px;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      border-radius: 4px;
      border: 1px solid #CCD4DC;
      background: #F6F7FC;
      .title {
        display: flex;
        padding: 16px 24px;
        align-items: center;
        gap: 24px;
        align-self: stretch;
        border-radius: 4px 4px 0px 0px;
        border-bottom: 1px solid #CCD4DC;
        background: #E5E6EB;
        span {
          color: #505050;
          font-family: Poppins;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: uppercase;
        }
      }
    }
  }

  .level-container {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;
    flex: 1 0 0;
    align-self: stretch;

    .level-item-container {
      display: flex;
      padding: 24px 0px;
      align-items: center;
      gap: 24px;
      align-self: stretch;

      .CONTENT_2 {
        color: #1D2129;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      input {
        color: #1D2129;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  .actions-bottom {
    display: flex;
    padding: 16px 24px 24px 0px;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    background: #F5F5F5;
    box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.15);
  }
`;
const TaxFormV2 = () => {
  return <TaxFormV2Styled>
    <div className="quick-pay">
      <div className="quick-pay-form">
        <div className="title">
          <span style={{ width: 200 }}>Tax type</span>
          <span>Percent</span>
        </div>
        <div className="level-container">
          <div className="level-item-container">
            <Box style={{ minWidth: 200 }} display="flex" gap="3" alignItems="center">
              <FormItemStyled noStyle name="enableSaleTax">
                <SwitchBtn />
              </FormItemStyled>
              <Text variant="CONTENT_2" color="text_3">
                Sale Tax
              </Text>
            </Box>
            <Form.Item noStyle name="saleTaxPercent" style={{ width: '100%' }}>
              <InputNumber
                style={{ width: '100%' }}
                formatter={(value) => `${value}%`}
                parser={(value: any) => value?.replace('%', '')}
              />
            </Form.Item>
          </div>
          <div className="level-item-container">
            <Box style={{ minWidth: 200 }} display="flex" gap="3" alignItems="center">
              <FormItemStyled noStyle name="enableUseTax">
                <SwitchBtn />
              </FormItemStyled>
              <Text variant="CONTENT_2" color="text_3">
                Used Tax
              </Text>
            </Box>
            <Form.Item
              label="Used Tax"
              name="useTaxPercent"
              noStyle
              style={{ width: '100%' }}
            >
              <InputNumber
                style={{ width: '100%' }}
                formatter={(value) => `${value}%`}
                parser={(value: any) => value?.replace('%', '')}
              />
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
    <ActionButtons className={'actions-bottom'} />
  </TaxFormV2Styled>;
};