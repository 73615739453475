import fetch from 'services/request';
import { IBodyAPIPayment, IBodyApiSplitTipPayment, IBodyApiUpdateDiscountPayment, IBodyApiUpdateTipPayment } from './types/api';


export const getBillDetail = (billId: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/bill/transaction-detail/' + billId,
  });
};

export const payment = (body: IBodyAPIPayment) => {
  return fetch({
    method: 'post',
    url: 'api/v1/payment/completed',
    body,
    configs: {
      timeout: 300000, // 5*60*1000= 300.000ms
    }
  });
};

export const splitTip = (body: IBodyApiSplitTipPayment) => {
  return fetch({
    method: 'post',
    url: 'api/v1/bill/split-tip',
    body,
  });
};

const updatePaymentTip = (body: IBodyApiUpdateTipPayment) => {
  return fetch({
    method: 'post',
    url: 'api/v1/bill/update-tip',
    body,
  });
};

const updatePaymentDiscountTicket = (body: IBodyApiUpdateDiscountPayment) => {
  return fetch({
    method: 'post',
    url: 'api/v1/bill/update-discount-item',
    body,
  });
};


const getTransactionDetail = (id: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/bill/transaction-detail/' + id,
  });
};

const getCustomerInfo = (phoneNumber: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/check-in/get-info-by-phone',
    params: { phoneNumber },
  });
};

const updateSignature = (billId: string, signature: string) => {
  return fetch({
    method: 'post',
    url: 'api/v1/payment/update-signature',
    body: { billId, signature },
  });
};

const addTipCreditCard = (billId: string, tip: number) => {
  return fetch({
    method: 'post',
    url: 'api/v1/payment/add-tip-credit-card',
    body: { billId, tip },
  });
};

const makeRating = (billId: string, stars: number) => {
  return fetch({
    method: 'post',
    url: 'api/v1/review',
    body: {
      stars,
      billId,
    }
  });
};

const getInfoPrintWaiting = (type: string, id: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/check-in/get-info-print-waiting/' + id,
    params: { type }
  });
};

const getInfoPrintGroupWaiting = (type: string, ids: string[]) => {
  return fetch({
    method: 'post',
    url: `api/v1/check-in/get-list-info-print-waiting/${type}`,
    body: ids
  });
};

const getBillInfoWithQRCode = (qrCode: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/bill/get-by-qrcode',
    params: { qrCode }
  });
};

const sendSMSEmailReceipt = (billId: string, type: 'SMS' | 'EMAIL', signatureValue: string) => {
  return fetch({
    method: 'post',
    url: 'api/v1/payment/send-sms-email-receipt',
    body: {
      billId: billId,
      value: signatureValue,
      type,
    },
  });
};

const cancelTransaction = (billId: string) => {
  return fetch({
    method: 'post',
    url: `api/v1/payment/cancel-transaction/${billId}`,
  });
};

const paymentApis = {
  getBillDetail,
  payment,
  splitTip,
  updatePaymentTip,
  updatePaymentDiscountTicket,
  getTransactionDetail,
  getCustomerInfo,
  updateSignature,
  addTipCreditCard,
  makeRating,
  getInfoPrintWaiting,
  getInfoPrintGroupWaiting,
  getBillInfoWithQRCode,
  sendSMSEmailReceipt,
  cancelTransaction,
};

export default paymentApis;
