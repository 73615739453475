import { Col, Row } from 'antd';
import IconLogo from 'assets/Icons/logo';
import Box from 'components/Box';
import Text from 'components/Text';
import Sidebar from 'components/layout/Sidebar';
import reportActions from 'features/report/services/actions';
import reportSelectors from 'features/report/services/selectors';
import { IPrintSalaryStaffDetailRangeDate, IPrintSalaryStaffDetailRangeDate_Detail } from 'features/report/services/types/reportStaff';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import PrintHeader from '../../../components/PrintHeader';
import { ReportType } from '../../../constants';
import { renderTime } from '../../../utils';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import shopSelectors from 'services/shop/selectors';

interface IEmployeeSalaryDateRangeReportPrintPage { }

const RowItem = (item: IPrintSalaryStaffDetailRangeDate_Detail) => {
    return (
        <Row style={{ padding: '1px 0px' }}>
            <ColHeader span={10}>
                <Text variant='CAPTION_3' printMode>{item?.billDate || moment(item?.createdDate).format(DATE_FORMAT)}</Text>
            </ColHeader>
            <ColHeader span={7} style={{ textAlign: 'end' }}><Text variant='CAPTION_3' printMode >{formatCurrency(item?.price)}</Text></ColHeader>
            <ColHeader span={7} style={{ textAlign: 'end' }}><Text variant='CAPTION_3' printMode >{formatCurrency(item?.tip)}</Text></ColHeader>
        </Row>
    );
};
const UserDetail = (data: IPrintSalaryStaffDetailRangeDate, cashLabel = 'Cash') => {
    return (
        <Box px='4'>
            <Box className="center" highlight>
                <Text printMode variant="CAPTION_2" className='title-highlight'>
                    {data?.staffName}
                </Text>
            </Box>
            <Box>
                <Header>
                    <ColHeader span={10}>DATE</ColHeader>
                    <ColHeader span={7} style={{ textAlign: 'end' }}>PRICE</ColHeader>
                    <ColHeader span={7} style={{ textAlign: 'end' }}>TIPS</ColHeader>
                </Header>
                {/* @ts-ignore */}
                {data?.details?.map(item => RowItem(item))}
                <Discount>
                    <ColHeader span={17}><Text printMode variant='CAPTION_2' >TOTAL SALES</Text></ColHeader>
                    <ColHeader span={7} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_2' >{formatCurrency(data?.totalSaleService)}</Text></ColHeader>
                </Discount>
                <Discount>
                    <ColHeader span={17}><Text printMode variant='CAPTION_2' >SUPPLY FEES</Text></ColHeader>
                    <ColHeader span={7} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_2' >{formatCurrency(data?.supplyFee)}</Text></ColHeader>
                </Discount>
                <Box className="space-between" highlight>
                    <Text printMode variant="CAPTION_2" className='title-highlight' >
                        TOTAL
                    </Text>
                    <Text printMode variant="CAPTION_2" className='title-highlight'>
                        {formatCurrency((data?.totalSaleService || 0) - (data?.supplyFee || 0))}
                    </Text>
                </Box>
                <Discount>
                    <ColHeader span={17}><Text printMode variant='CAPTION_2' >COMMISSION ({data?.commissionPercent}%)</Text></ColHeader>
                    <ColHeader span={7} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_2' >{formatCurrency(data?.totalCommission)}</Text></ColHeader>
                </Discount>
                {!!data?.staffDiscount && <Discount>
                    <ColHeader span={17}><Text printMode variant='CAPTION_2' >DISCOUNT</Text></ColHeader>
                    <ColHeader span={7} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_2' >{formatCurrency(data?.staffDiscount)}</Text></ColHeader>
                </Discount>}
                <>
                    {!!data?.tipCollected && <Discount>
                        <ColHeader flex={1}><Text printMode variant='CAPTION_2' >COLLECTED TIPS</Text></ColHeader>
                        <ColHeader span={7} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_2' >{formatCurrency(data?.tipCollected)}</Text></ColHeader>
                    </Discount>}
                    {!!data?.tipFee && <>
                        <Discount>
                            <ColHeader flex={1}><Text printMode variant='CAPTION_2' >TIP FEE {(data?.tipFeeType === 'PERCENT' && `(${data?.tipFeeValue || 0}%)`)}</Text></ColHeader>
                            <ColHeader span={7} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_2' >
                                {formatCurrency(data?.tipFee) + ' '}
                            </Text>
                            </ColHeader>
                        </Discount>
                        <Discount>
                            <ColHeader flex={1}><Text printMode variant='CAPTION_2' >TOTAL TIP</Text></ColHeader>
                            <ColHeader span={7} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_2' >{formatCurrency(data?.totalTip)}</Text></ColHeader>
                        </Discount>
                    </>}
                    <Box className="space-between" highlight>
                        <Text printMode variant="CAPTION_2" className='title-highlight' >
                            PAYMENT TYPES
                        </Text>
                        <Text printMode variant="CAPTION_2" className='title-highlight'></Text>
                    </Box>
                    <BoxInfo className='bb'>
                        <Text textTransform='capitalize' printMode variant='CAPTION_2' color="text_3" >
                            Cash
                        </Text>
                        <Text printMode variant='CAPTION_2' color="text_3" >
                            {formatCurrency(data?.cashAmount)}
                        </Text>
                    </BoxInfo>
                    <BoxInfo className='bb'>
                        <Text textTransform='capitalize' printMode variant='CAPTION_2' color="text_3" >
                            Credit Card
                        </Text>
                        <Text printMode variant='CAPTION_2' color="text_3" >
                            {formatCurrency(data?.creditAmount)}
                        </Text>
                    </BoxInfo>
                    <BoxInfo className='bb'>
                        <Text textTransform='capitalize' printMode variant='CAPTION_2' color="text_3" >
                            Gift Card
                        </Text>
                        <Text printMode variant='CAPTION_2' color="text_3" >
                            {formatCurrency(data?.giftCardAmount)}
                        </Text>
                    </BoxInfo>
                    {!!data?.loyaltyAmount &&
                        <BoxInfo className='bb'>
                            <Text textTransform='capitalize' printMode variant='CAPTION_2' color="text_3" >
                                Loyalty
                            </Text>
                            <Text printMode variant='CAPTION_2' color="text_3" >
                                {formatCurrency(data?.loyaltyAmount)}
                            </Text>
                        </BoxInfo>}
                    {!!data?.checkAmount &&
                        <BoxInfo className='bb'>
                            <Text textTransform='capitalize' printMode variant='CAPTION_2' color="text_3" >
                                Check
                            </Text>
                            <Text printMode variant='CAPTION_2' color="text_3" >
                                {formatCurrency(data?.checkAmount)}
                            </Text>
                        </BoxInfo>}

                    <BoxInfo className='bb'>
                        <Text textTransform='capitalize' printMode variant='CAPTION_2' color="text_3" >
                            Other
                        </Text>
                        <Text printMode variant='CAPTION_2' color="text_3" >
                            {formatCurrency(data?.otherAmount)}
                        </Text>
                    </BoxInfo>
                </>
                <Box className="space-between" highlight>
                    <Text printMode variant="CAPTION_2" className='title-highlight' >
                        PAID OUT
                    </Text>
                    <Text printMode variant="CAPTION_2" className='title-highlight'>
                        {formatCurrency(data?.totalSalary)}
                    </Text>
                </Box>
                <BoxInfo>
                    <Text textTransform='capitalize' printMode variant='CAPTION_3' color="text_3" >
                        {cashLabel} ({data?.cashPercent}%)
                    </Text>
                    <Text printMode variant='CAPTION_3' color="text_3" >
                        {formatCurrency(data?.totalPayCash)}
                    </Text>
                </BoxInfo>
                <BoxInfo>
                    <Text printMode variant='CAPTION_3' color="text_3" >
                        Check ({data?.checkPercent}%)
                    </Text>
                    <Text printMode variant='CAPTION_3' color="text_3" >
                        {formatCurrency(data?.totalPayCheck)}
                    </Text>
                </BoxInfo>
                {!!data?.tipToCash &&
                    <BoxInfo>
                        <Text textTransform='capitalize' printMode variant='CAPTION_3' color="text_3" >
                            Tip to {cashLabel} {data?.tipCashPercent ? ` (${data?.tipCashPercent}%)` : ''}
                        </Text>
                        <Text printMode variant='CAPTION_3' color="text_3" >
                            {formatCurrency(data?.tipToCash)}
                        </Text>
                    </BoxInfo>}
                <BoxInfo>
                    <Text textTransform='capitalize' printMode variant='CAPTION_3' color="text_3" >
                        Tip to Check {data?.tipCheckPercent ? ` (${data?.tipCheckPercent}%)` : ''}
                    </Text>
                    <Text printMode variant='CAPTION_3' color="text_3" >
                        {formatCurrency(data?.tipToCheck)}
                    </Text>
                </BoxInfo>
                <Box bt='print_line'>
                    <BoxInfo>
                        <Text textTransform='capitalize' printMode variant='CAPTION_2' color="text_3" >
                            Total {cashLabel}
                        </Text>
                        <Text printMode variant='CAPTION_2' color="text_3" >
                            {formatCurrency(data?.totalCash)}
                        </Text>
                    </BoxInfo>
                    <BoxInfo>
                        <Text printMode variant='CAPTION_2' color="text_3">
                            Total Check
                        </Text>
                        <Text printMode variant='CAPTION_2' color="text_3">
                            {formatCurrency(data?.totalCheck)}
                        </Text>
                    </BoxInfo>
                </Box>
            </Box>
        </Box>
    );
};
const StaffSalaryDateRangeReportPrintPage: React.FC<IEmployeeSalaryDateRangeReportPrintPage> = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { staff_id = '' } = useParams();

    const dataAppointment = reportSelectors.getParams()?.[ReportType.STAFF_SALARY_DETAIL];
    useEffect(() => {
        if (!dataAppointment) return;
        dispatch(reportActions.getPrintSalaryStaffDetailRangeDate.fetch({
            startDate: dataAppointment.startTime,
            endDate: dataAppointment.endTime,
            staffId: staff_id
        }));
    }, [dataAppointment, staff_id]);

    const onBack = () => {
        navigate(-1);
    };
    const divRef = useRef<HTMLDivElement>(null);
    const onPrint = useReactToPrint({
        content: () => divRef.current,
        copyStyles: true,
    });
    return (
        <div style={{ display: 'flex' }}>
            <Sidebar />
            <ContainerStyled>
                <PrintHeader onBack={onBack} onPrint={onPrint} />
                <div ref={divRef}>
                    <StaffSalaryDateRangeReportPrintBodyUI />
                </div>
            </ContainerStyled>
        </div>
    );
};

export default StaffSalaryDateRangeReportPrintPage;

export const StaffSalaryDateRangeReportPrintBodyUI = () => {
    const dataAppointment = reportSelectors.getParams()?.[ReportType.STAFF_SALARY_DETAIL];
    const dataPrintSalaryStaffDetail = reportSelectors.getPrintSalaryStaffDetailRangeDate();
    const allSetting = shopSelectors.data.allSetting();
    return (
        <Box className="center" overflow="auto">
            <TicketBox>
                <Box px='4'>
                    <LogoBox>
                        <IconLogo />
                    </LogoBox>
                    <Box className="center" pt='1'>
                        <Text printMode variant="CONTENT_2" color="text_2">
                            {renderTime(dataAppointment)}
                        </Text>
                    </Box>
                </Box>
                {UserDetail(dataPrintSalaryStaffDetail, allSetting?.labelSalaryCashOrBonus || 'Cash')}
            </TicketBox>
        </Box>
    );
};

const TicketBox = styled.div`
  border-radius: 20px;
  background: #fff;
  width: 20.5rem;
  height: fit-content;
`;

const ContainerStyled = styled.div`
  background-color: #e5e5e5;
  height: 100vh;
  position: relative;
  flex-grow: 1;
  width: 100%;
  overflow: auto;
`;

const LogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

const Header = styled(Row)`
  border-top:1px solid black;
  border-bottom: 1px dashed black;
`;

const ColHeader = styled(Col)`
    color:  black;
    padding: 1px 0px;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const Discount = styled(Header)`
  border-top: unset;
`;

const BoxInfo = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.bb {
        border-bottom: 1px dashed black;
    }
`;