import fetch from 'services/request';
import { IApiHistoryClockInOut, IFilterDataParams } from './types/api';
import moment from 'moment';
import { STAFF_CLOCK_IN_OUT_ACTIONS } from './constants';
import { IApiVoiceCallCustomerBody } from './types/waiting';

const getWaitingList = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/check-in/get-list-waiting',
  });
};

const getSummaryData = (params: IFilterDataParams) => {
  return fetch({
    method: 'get',
    url: 'api/v1/check-in/dashboard',
    params,
  });
};

const getBillWaitingTicket = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/bill/list-waiting-ticket',
  });
};

const doClockIn = (pin: number | string) => {
  return fetch({
    method: 'post',
    url: 'api/v1/staff/clock-in-out',
    body: {
      pin,
      action: STAFF_CLOCK_IN_OUT_ACTIONS.CLOCK_IN,
      currentDate: moment().format(),
    }
  });
};

const doClockOut = (pin: number | string) => {
  return fetch({
    method: 'post',
    url: 'api/v1/staff/clock-in-out',
    body: {
      pin,
      action: STAFF_CLOCK_IN_OUT_ACTIONS.CLOCK_OUT,
      currentDate: moment().format(),
    }
  });
};

const getStaffAvailable = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/staff/list-available-clock-in',
  });
};

const getHistoryClockInOut = (params: IApiHistoryClockInOut) => {
  return fetch({
    method: 'get',
    url: 'api/v1/staff/history-clock-in-out',
    params,
  });
};
const getListOpenTicket = () => {
  const now = moment().format('MM-DD-YYYY');
  return fetch({
    method: 'get',
    url: 'api/v1/bill/list-ticket-by-status',
    params: {
      status: 'OPEN_TICKET',
      startDate: now,
      endDate: now,
    },
  });
};

const removePendingTicket = (billId: string) => {
  return fetch({
    method: 'delete',
    url: `/api/v1/bill/delete-ticket/${billId}`,
    autoToast: true,
  });
};

const removeWaitingTicket = (billId: string) => {
  return fetch({
    method: 'delete',
    url: `/api/v1/check-in/delete-waiting-list/${billId}`,
    autoToast: true,
  });
};

const getListAvailableWorking = () => {
  return fetch({
    method: 'get',
    url: '/api/v1/staff/list-available-working',
  });
};

const sortCheckIn = (ids: string[]) => {
  return fetch({
    method: 'post',
    url: '/api/v1/staff/sort-check-in',
    body: ids,
  });
};

const callVoiceNotification = (staffId: string) => {
  return fetch({
    method: 'post',
    url: `/api/v1/staff/voice-notification/${staffId}`,
  });
};

const syncWaitingToTicket = (staffId: string, checkInId: string) => {
  return fetch({
    method: 'post',
    url: '/api/v1/bill/sync-waiting-to-ticket',
    body: { staffId, checkInId }
  });
};
const voiceCustomer = (body: IApiVoiceCallCustomerBody) => {
  return fetch({
    method: 'post',
    url: '/api/v1/check-in/voice-customer-still-waiting',
    body
  });
};

const userApis = {
  getWaitingList,
  getSummaryData,
  getBillWaitingTicket,
  doClockIn,
  doClockOut,
  getStaffAvailable,
  getHistoryClockInOut,
  getListOpenTicket,
  removePendingTicket,
  removeWaitingTicket,
  getListAvailableWorking,
  sortCheckIn,
  callVoiceNotification,
  syncWaitingToTicket,
  voiceCustomer,
};

export default userApis;
