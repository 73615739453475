import { Menu, MenuProps } from 'antd';
import Box from 'components/Box';
import React, { useState } from 'react';
import styled from 'styled-components';
import Check from './PaymentTabMenu/Check';
import Discount from './PaymentTabMenu/Discount';
import GiftCard from './PaymentTabMenu/GiftCard';
import LoyaltyRewards from './PaymentTabMenu/LoyaltyRewards';
import Tax from './PaymentTabMenu/Tax';
import WorkingTime from './GeneralConfigurationTabMenu/WorkingTime';
import TipOptionsConfig from './PaymentTabMenu/TipOptionConfig';
import ProductTax from './PaymentTabMenu/ProductTax';
import tab_tax_icon from './v2-icons/tab-tax.svg';
import tab_tax_active_icon from './v2-icons/tab-tax-active.svg';
import tab_discount_icon from './v2-icons/tab-discount.svg';
import tab_discount_active_icon from './v2-icons/tab-discount-active.svg';
import tab_tip_options_icon from './v2-icons/tab-tip-options.svg';
import tab_tip_options_active_icon from './v2-icons/tab-tip-options-active.svg';
import tab_loyalty_rewards_icon from './v2-icons/tab-loyalty-rewards.svg';
import tab_loyalty_rewards_active_icon from './v2-icons/tab-loyalty-rewards-active.svg';
import tab_referral_icon from './v2-icons/tab-referral.svg';
import tab_referral_active_icon from './v2-icons/tab-referral-active.svg';
import tab_check_icon from './v2-icons/tab-check.svg';
import tab_check_active_icon from './v2-icons/tab-check-active.svg';
import tab_gift_card_icon from './v2-icons/tab-gift-card.svg';
import tab_gift_card_active_icon from './v2-icons/tab-gift-card-active.svg';
import tab_working_time_icon from './v2-icons/tab-working-time.svg';
import tab_working_time_active_icon from './v2-icons/tab-working-time-active.svg';
import tab_role_icon from './v2-icons/tab-role.png';
import tab_role_active_icon from './v2-icons/tab-role-active.png';
import { get } from 'lodash';
import clsx from 'clsx';
import PrintCheck from './PaymentTabMenu/PrintCheck';
import ModalContactUs, { useModalContactUsRef } from 'features/report/pages/ReportPage/EmployeeSalary/PrintCheck/ModalContactUs';
import shopSelectors from 'services/shop/selectors';
import { MenuTabs } from '../v2/style';
import ReferralConfig from './PaymentTabMenu/Referral';

type IPaymentsTabProps = {
  v2?: boolean;
};
type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

enum MenuKeys {
  TAX = 'tax',
  DISCOUNT = 'discount',
  TIP_OPTIONS = 'tip_options',
  LOYALTY_REWARDS = 'loyalty_rewards',
  REFERRAL = 'referral',
  CHECK = 'check',
  GIFT_CARD = 'gift_card',
  WORKING_TIME = 'working',
  PRODUCT_TAX = 'product_tax',
  PRINT_CHECK = 'print_check',
}

const items: MenuItem[] = [
  getItem('Tax', MenuKeys.TAX),
  getItem('Discount', MenuKeys.DISCOUNT),
  getItem('Tip Options', MenuKeys.TIP_OPTIONS),
  getItem('Loyalty Rewards', MenuKeys.LOYALTY_REWARDS),
  getItem('Referral', MenuKeys.REFERRAL),
  getItem('Check (Others)', MenuKeys.CHECK),
  getItem('Gift Card', MenuKeys.GIFT_CARD),
  getItem('Working Time', MenuKeys.WORKING_TIME),
  getItem('Product Tax', MenuKeys.PRODUCT_TAX),
  getItem('Print Check', MenuKeys.PRINT_CHECK),
];

const PaymentsTab: React.FC<IPaymentsTabProps> = ({ v2 }) => {
  const allSetting = shopSelectors.data.allSetting();
  const modalContactUsRef = useModalContactUsRef();
  const [selectedKey, setSelectedKey] = useState<MenuKeys>(MenuKeys.TAX);

  const handleMenuSelect = (info: { key: string }) => {
    setSelectedKey(info.key as MenuKeys);
  };
  const onChooseTab = (key: MenuKeys) => () => {
    if (key === MenuKeys.PRINT_CHECK) {
      if (!allSetting?.displayPrintCheck) return modalContactUsRef.current?.open();
    }
    setSelectedKey(key);
  };

  return (
    <PaymentsTabStyled className={v2 ? 'v2' : ''}>
      <BoxStyled display="flex" p="4" gap="4" alignItems="stretch">
        <LeftBoxStyled className='LeftBoxStyled'>
          {v2 ? <MenuTabs>
            <MenuTabItem onClick={onChooseTab(MenuKeys.TAX)} active={selectedKey === MenuKeys.TAX} type={MenuKeys.TAX} />
            <MenuTabItem onClick={onChooseTab(MenuKeys.DISCOUNT)} active={selectedKey === MenuKeys.DISCOUNT} type={MenuKeys.DISCOUNT} />
            <MenuTabItem onClick={onChooseTab(MenuKeys.TIP_OPTIONS)} active={selectedKey === MenuKeys.TIP_OPTIONS} type={MenuKeys.TIP_OPTIONS} />
            <MenuTabItem onClick={onChooseTab(MenuKeys.LOYALTY_REWARDS)} active={selectedKey === MenuKeys.LOYALTY_REWARDS} type={MenuKeys.LOYALTY_REWARDS} />
            <MenuTabItem onClick={onChooseTab(MenuKeys.REFERRAL)} active={selectedKey === MenuKeys.REFERRAL} type={MenuKeys.REFERRAL} />
            <MenuTabItem onClick={onChooseTab(MenuKeys.CHECK)} active={selectedKey === MenuKeys.CHECK} type={MenuKeys.CHECK} />
            <MenuTabItem onClick={onChooseTab(MenuKeys.GIFT_CARD)} active={selectedKey === MenuKeys.GIFT_CARD} type={MenuKeys.GIFT_CARD} />
            <MenuTabItem onClick={onChooseTab(MenuKeys.WORKING_TIME)} active={selectedKey === MenuKeys.WORKING_TIME} type={MenuKeys.WORKING_TIME} />
            <MenuTabItem onClick={onChooseTab(MenuKeys.PRODUCT_TAX)} active={selectedKey === MenuKeys.PRODUCT_TAX} type={MenuKeys.PRODUCT_TAX} />
            <MenuTabItem onClick={onChooseTab(MenuKeys.PRINT_CHECK)} active={selectedKey === MenuKeys.PRINT_CHECK} type={MenuKeys.PRINT_CHECK} />
          </MenuTabs> :
            <MenuBoxStyled>
              <Menu
                style={{ width: '100%' }}
                defaultSelectedKeys={[MenuKeys.TAX]}
                items={items}
                onSelect={handleMenuSelect}
              />
            </MenuBoxStyled>}
        </LeftBoxStyled>
        {selectedKey === MenuKeys.TAX && <Tax v2={v2} />}
        {selectedKey === MenuKeys.DISCOUNT && <Discount v2={v2} />}
        {selectedKey === MenuKeys.TIP_OPTIONS && <TipOptionsConfig v2={v2} />}
        {selectedKey === MenuKeys.LOYALTY_REWARDS && <LoyaltyRewards v2={v2} />}
        {selectedKey === MenuKeys.REFERRAL && <ReferralConfig />}
        {selectedKey === MenuKeys.CHECK && <Check v2={v2} />}
        {selectedKey === MenuKeys.GIFT_CARD && <GiftCard v2={v2} />}
        {selectedKey === MenuKeys.WORKING_TIME && <WorkingTime v2={v2} />}
        {selectedKey === MenuKeys.PRODUCT_TAX && <ProductTax v2={v2} />}
        {selectedKey === MenuKeys.PRINT_CHECK && <PrintCheck />}
        <ModalContactUs ref={modalContactUsRef} />
      </BoxStyled>
    </PaymentsTabStyled>
  );
};

export default PaymentsTab;

const configs = {
  [MenuKeys.TAX]: {
    label: 'Tax',
    icon: tab_tax_icon,
    icon_active: tab_tax_active_icon,
  },
  [MenuKeys.DISCOUNT]: {
    label: 'Discount',
    icon: tab_discount_icon,
    icon_active: tab_discount_active_icon,
  },
  [MenuKeys.TIP_OPTIONS]: {
    label: 'Tip Options',
    icon: tab_tip_options_icon,
    icon_active: tab_tip_options_active_icon,
  },
  [MenuKeys.LOYALTY_REWARDS]: {
    label: 'Loyalty Rewards',
    icon: tab_loyalty_rewards_icon,
    icon_active: tab_loyalty_rewards_active_icon,
  },
  [MenuKeys.REFERRAL]: {
    label: 'Referral',
    icon: tab_referral_icon,
    icon_active: tab_referral_active_icon,
  },
  [MenuKeys.CHECK]: {
    label: 'Check (Others)',
    icon: tab_check_icon,
    icon_active: tab_check_active_icon,
  },
  [MenuKeys.GIFT_CARD]: {
    label: 'Gift Card',
    icon: tab_gift_card_icon,
    icon_active: tab_gift_card_active_icon,
  },
  [MenuKeys.WORKING_TIME]: {
    label: 'Working Time',
    icon: tab_working_time_icon,
    icon_active: tab_working_time_active_icon,
  },
  [MenuKeys.PRODUCT_TAX]: {
    label: 'Product Tax',
    icon: tab_role_icon,
    icon_active: tab_role_active_icon,
  },
  [MenuKeys.PRINT_CHECK]: {
    label: 'Print Check',
    icon: tab_check_icon,
    icon_active: tab_check_active_icon,
  },
};

type IMenuTabItemProps = {
  active?: boolean;
  onClick?: () => void;
  type: MenuKeys;
};
const MenuTabItem = ({ type, active, onClick }: IMenuTabItemProps) => {
  const config = get(configs, type);
  if (!config) return null;
  return (
    <div className={clsx('menu-tab-item', active && 'active')} onClick={onClick}>
      <img src={active ? config.icon_active : config.icon} alt={config.label} />
      <span>{config.label}</span>
    </div>
  );
};

const PaymentsTabStyled = styled.div`
  display: flex;
  flex-direction: column;
  background: #e5e5e5;
  height: calc(100vh - 3.125rem);
  min-width: 50rem;
  overflow: auto;
  &.v2 {
    background-color: #F5F5F5;
    height: 100%;
    .LeftBoxStyled {
      min-width: 20rem;
    }
  }
`;

const BoxStyled = styled(Box)`
  display: flex;
  flex-grow: 1;
  align-items: stretch;
`;

const LeftBoxStyled = styled.div`
  border-radius: 5px;
  min-width: 16.5rem;
  background-color: white;
  gap: 1.5rem;
`;

const MenuBoxStyled = styled.div`
  padding: 0.25rem 0;
  .ant-menu-item {
    margin: 0;
    width: 100%;
    border-radius: 0;
  }
`;
