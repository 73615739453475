import { typeIcon } from 'assets/Icons';
import Button, { BUTTON_VARIANT_TYPES } from 'components/Button';
import React from 'react';
import styled from 'styled-components';

export type BtnProps = {
  buttonName: string;
  onClick?: () => void;
  type: keyof typeof BUTTON_VARIANT_TYPES;
  iconType?: typeIcon;
  disabled?: boolean;
  cardCashier?: boolean;
};

const defaultButtonSequence: BtnProps[] = [
  {
    buttonName: 'OK',
    onClick: () => undefined,
    type: 'PRIMARY',
  },
];

interface IGroupButtonFooterModal {
  buttonSequence: BtnProps[];
  className?: string;
}

const GroupButtonFooterModal: React.FC<IGroupButtonFooterModal> = ({
  buttonSequence = defaultButtonSequence,
  className = '',
}) => {
  const GroupButton = buttonSequence.map((btn) => {
    return (
      <Button
        key={btn.buttonName}
        className="pay-footer-modal-btn"
        onClick={btn.onClick}
        ntype={btn.type}
        icon={btn.iconType ? btn.iconType : undefined}
        disabled={btn.disabled}
      >
        {btn.buttonName}
      </Button>
    );
  });
  return (
    <GroupButtonFooterModalStyled
      className={`${className ?? ''} pay-modal-group-btn-footer`}
    >
      {GroupButton}
    </GroupButtonFooterModalStyled>
  );
};

export default GroupButtonFooterModal;

export const GroupButtonFooterModalStyled = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    column-gap: 1.5rem;
    width: 100%;
    .pay-footer-modal-btn{
        width: 100%;
    }
`;

export const ButtonFooter = (btn: BtnProps) => (
  <Button
    key={btn.buttonName}
    className="pay-footer-modal-btn"
    onClick={btn.onClick}
    ntype={btn.type}
    icon={btn.iconType ? btn.iconType : undefined}
    disabled={btn.disabled}
    htmlType='button'
    cardCashier={btn.cardCashier}
  >
    {btn.cardCashier ? <span style={{ fontSize: 18, fontWeight: '600' }}>{btn.buttonName}</span> : btn.buttonName}
  </Button>
);
