import { Form } from 'antd';
import PercentDecimalInputPrecision from 'components/PercentDecimalInputPrecision';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import { IApiUpdateTurnAppointmentBody, ITurnAppointmentResData } from 'features/settingService/services/types/turn';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import SwitchBtn from './ServicesSettingV2/SwitchBtn';
import ActionButtons from './PaymentTabMenu/components/ActionButtons';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import settingApis from 'features/settingService/services/apis';
import { get } from 'lodash';
import toast from 'utils/toast';

const TurnAppointmentSetting = () => {
  const dispatch = useDispatch();
  const data: (ITurnAppointmentResData | null) = settingServiceSelectors.getTurnSettingAppointment();
  const [form] = Form.useForm();
  const setLoadingPage = useSetLoadingPage();

  useEffect(() => {
    dispatch(settingServiceActions.getTurnSettingAppointment.fetch());
  }, []);
  useEffect(() => {
    if (!data) return;
    form.setFieldsValue(data);
  }, [data]);

  const onFinish = async (values: any) => {
    const data = values as ITurnAppointmentResData;
    const body: IApiUpdateTurnAppointmentBody = {
      id: data.id,
      enable: !!data.enable,
      value: (data.value || 0).toString(),
    };
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<ITurnAppointmentResData> = await settingApis.updateTurnSettingAppointment(body);
      if (res.data.data) {
        dispatch(settingServiceActions.getTurnSettingAppointment.fetch());
      }
    } catch (error) {
      const msg = get(error, 'response.data.message');
      toast.error(msg || '');
    } finally {
      setLoadingPage(false);
    }
  };

  return (
    <Container layout='vertical' form={form} onFinish={onFinish}>
      <Form.Item name='id' noStyle />
      <Form.Item noStyle name={'enable'}><SwitchBtn label='Enable Turn' /></Form.Item>
      <Form.Item label='Turn' name={'value'}>
        <PercentDecimalInputPrecision nonSuffix />
      </Form.Item>
      <p className="explain-text">Ex:  Appointment = 0.5 turn</p>
      <ActionButtons />
    </Container>
  );
};

export default TurnAppointmentSetting;
const Container = styled(Form)`
  background: #fff;
  flex: 1;
  padding: 16px;
  .explain-text {
    color: #505050;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;