import * as React from 'react';
import { SVGProps } from 'react';
const IcSender = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={56}
    height={56}
    viewBox='0 0 56 56'
    fill='none'
    {...props}
  >
    <path
      fill={props?.fill || '#C84B31'}
      d='M17.36 2.582c2.999-.573 6.238.185 8.643 2.092 3.146 2.387 4.738 6.625 3.942 10.511-.5 2.776-2.184 5.312-4.534 6.885-2.24 1.554-5.108 2.165-7.791 1.702-3.46-.537-6.57-2.924-8.013-6.107a10.75 10.75 0 0 1-.518-7.662c1.11-3.756 4.422-6.717 8.272-7.42ZM41.549 9.8a1.806 1.806 0 0 0-.63-.037c-.13.148-.259.278-.388.426-2.813 2.794-5.608 5.626-8.42 8.401-.241.149-.297.426-.315.685 3.053 3.035 6.126 6.07 9.142 9.142.26.037.666.037.648-.314.037-1.629 0-3.239.018-4.849 2.499-.185 5.108.37 7.125 1.925 1.129.907 2.073 2.22 1.999 3.72.074 2.183-.815 4.404-2.48 5.829-.26.166-.407.648.056.592 2.498-.814 4.922-2.258 6.403-4.497 1.24-1.998 1.499-4.478 1.147-6.773-.407-2.813-1.962-5.441-4.238-7.125-2.85-2.128-6.514-2.813-10.012-2.795-.037-1.425.074-2.887-.055-4.33ZM38.754 48.534V37.985c-.092-3.498-1.758-6.866-4.404-9.123-.223-.185-.5-.315-.648-.593-.093.074-.185.13-.278.204 0-.037 0-.092-.018-.148l.055-.167a.14.14 0 0 0-.074-.037 13.908 13.908 0 0 0-2.128-1.147 9.592 9.592 0 0 0-1.591-.574.54.54 0 0 0-.167-.037h-.018c-.02 0-.038 0-.056-.018a3.031 3.031 0 0 1-.204-.056c-.277-.074-.555-.13-.832-.185-.611-.11-1.24-.166-1.888-.185h-.278c-.888-.018-1.776 0-2.665 0h-8.364c-.889 0-1.777-.018-2.665 0h-.278c-.63 0-1.258.074-1.887.185-.278.056-.556.111-.833.185l-.222.056c-.019 0-.037 0-.056.018h-.018c-.056.019-.111.037-.167.037a9.591 9.591 0 0 0-1.592.574c-.74.315-1.462.703-2.128 1.147a.14.14 0 0 1-.074.037l.056.167c0 .056-.019.111-.019.148-.092-.074-.185-.13-.277-.204-.148.26-.426.408-.648.593-2.61 2.257-4.275 5.625-4.386 9.123V53.568h.092c0 .018 0 .018.019.037.63-.019 1.277-.037 1.906-.037h3.11c1.091 0 2.164.018 3.256 0h22.023c1.092.018 2.165 0 3.257 0h3.109c.63 0 1.277.018 1.906.037 0-.019 0-.019.019-.037h.092c-.037-1.685-.037-3.369-.037-5.034Z'
    />
  </svg>
);
export default IcSender;
