import styled from 'styled-components';
import StaffInfo from './StaffInfo';
import { Tabs, TabsProps } from 'antd';
import StaffInformation from '../components/StaffInformation';
import StaffSecurity from '../components/StaffSecurity';
import StaffSalary from '../components/StaffSalary';
import StaffSchedule from '../components/StaffSchedule';
import { useNavigate, useParams } from 'react-router-dom';
import storage from 'utils/sessionStorage';
import clsx from 'clsx';
import StaffSpecialize from './StaffSpecialize';
const STAFF_DETAIL_KEYS = {
  INFORMATION: 'information',
  SECURITY: 'security',
  SALARY: 'salary',
  SPECIALIZE: 'Specialize',
  SCHEDULE: 'schedule',
};
const items: TabsProps['items'] = [
  {
    key: STAFF_DETAIL_KEYS.INFORMATION,
    label: 'Information',
    children: <StaffInformation v2 />,
  },
  {
    key: STAFF_DETAIL_KEYS.SECURITY,
    label: 'Security',
    children: <StaffSecurity v2 />,
  },
  {
    key: STAFF_DETAIL_KEYS.SALARY,
    label: 'Salary',
    children: <StaffSalary v2 />,
  },
  {
    key: STAFF_DETAIL_KEYS.SPECIALIZE,
    label: 'Specialize',
    children: <StaffSpecialize />,
  },
  {
    key: STAFF_DETAIL_KEYS.SCHEDULE,
    label: 'Schedule',
    children: <StaffSchedule v2 />,
  },
];
const renderTabBar = () => <></>;
const RightDetail = () => {
  const navigate = useNavigate();
  const { tab } = useParams();
  const onChange = (key: string) => {
    navigate(`/store/${storage.shop_id.get()}/setting-staff/${key}`);
  };

  return (
    <Container>
      <StaffInfo />
      <TabsContainer>
        <TabNavContainer>
          {items.map(o => (
            <button onClick={() => onChange(o.key)} type='button' key={o.key} className={clsx('nav-item', o.key == tab && 'active')}>
              <span>{o.label}</span>
            </button>
          ))}
        </TabNavContainer>
        <Tabs renderTabBar={renderTabBar} activeKey={tab} items={items} onChange={onChange} />
      </TabsContainer>
    </Container>
  );
};

export default RightDetail;
const TabNavContainer = styled.div`
display: flex;
height: 60px;
align-items: flex-start;
gap: 24px;
align-self: stretch;
border-bottom: 1px solid #86909C;
.nav-item {
  display: flex;
  padding: 0px 16px 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-bottom: 3px solid transparent;

  span {
    color: #64748B;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &.active {
    border-bottom: 3px solid #2D6772;
    span {
      color: #2D6772;
      font-weight: 600;
    }
  }
}
`;
const TabsContainer = styled.div`
flex: 1;
align-self: stretch;
display: flex;
flex-direction: column;
background: #fff;
.ant-tabs {
  padding: 16px;

  .ant-tabs-content {
    .ant-form-item .ant-form-item-label label{
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .ant-radio-wrapper {
      gap: 8px;
      .ant-radio {
        transform: scale(1.5);
      }
      span {
        color: #1D2129;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .ant-form .ant-form-item {
      margin-bottom: 16px;
      .ant-input {
        color: #1D2129;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .ant-picker .ant-picker-input input, .ant-input-number-input {
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .InputEmailStyled .suggestion {
      .box-item {
        flex: 1;
        display: flex;
        padding: 4px 24px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        flex: 1 0 0;
        border-radius: 100px;
        border: 1px solid #4384FF;
        background: #FFF;
        .CONTENT_1 {
          color: #4384FF;
          text-align: center;
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
        }
      }
    }  

  }


  .ant-select .ant-select-selection-item {
    height: auto;
  }
  .ant-select .ant-select-arrow {
    color: #1D2129;
    font-size: 18px;
    transform: scaleX(1.1) scaleY(0.9);
  }
  .ant-select .ant-select-selector {
    background: #ffffff;
    border: 1px solid #86909C;
    .ant-select-selection-item-content {
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .ant-select .ant-select-selector {
    background: #ffffff;
    border: 1px solid #86909C;
    .ant-select-selection-placeholder, .ant-select-selection-search-input, .ant-select-selection-item {
      color: rgb(29, 33, 41);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .ant-input, .ant-input-suffix {
    height: 3.5rem !important;
  }
  .ant-select {
    height: 3.5rem; 
  }
  .ant-input-number {
    height: 3.5rem !important; 
  }
  .ant-input-number-input {
    height: 3.5rem !important; 
  }
  .ant-input-affix-wrapper {
    background: #ffffff;
    border: 1px solid #86909C;
    .ant-input {
      background: transparent;
      color: rgb(29, 33, 41);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

}
`;
const Container = styled.div`
flex: 1;
display: flex;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
gap: 16px;
padding: 16px 0;
flex-shrink: 0;
background: #F6F7FC;
`;
