import { Form } from 'antd';
import Box from 'components/Box';
import PercentDecimalInputPrecision from 'components/PercentDecimalInputPrecision';
import React from 'react';
import { IOptionTipObjectItem } from 'services/shop/types/optionTip';
import styled from 'styled-components';
import add_new_icon from '../../v2-icons/add-new-icon.svg';
import discount_delete_icon from '../../v2-icons/discount-delete.svg';
import ActionButtons from '../components/ActionButtons';
import SwitchBtn from '../../ServicesSettingV2/SwitchBtn';

type TipOptionConfigV2Form = {
  checked: boolean;
  onChange: (value: boolean) => void;
};
const TipOptionConfigV2Form: React.FC<TipOptionConfigV2Form> = ({ checked, onChange }) => {

  return (
    <Container>
      <div className="wrap-table">
        <table>
          <thead>
            <tr>
              <th style={{ width: 300 }}>
                <Box display='flex' alignItems='center' justifyContent='center' style={{ gap: 12 }}>
                  <SwitchBtn value={checked} onChange={onChange} />
                  <span>Option</span>
                </Box>
              </th>
              <th>Percent</th>
              <th style={{ width: 80 }}></th>
            </tr>
          </thead>
          <Form.List name="optionTips">
            {(fields, { add, remove }) => (
              <tbody>
                {fields.map(({ key, name }, i) => (
                  <Form.Item noStyle name={name} key={key}>
                    <TipOptionItem name={name} index={i} remove={() => remove(name)} />
                  </Form.Item>
                ))}
                <tr><td colSpan={5}>
                  <button
                    type='button'
                    className='btn-add-new'
                    onClick={() =>
                      add({
                        active: true,
                        option: 0,
                      })
                    }
                  >
                    <img src={add_new_icon} alt="add_new_icon" style={{ width: 24, height: 24 }} />
                    <span>Add New</span>
                  </button>
                </td></tr>
              </tbody>
            )}
          </Form.List>
        </table>
      </div>
      <ActionButtons />
    </Container>
  );
};

export default TipOptionConfigV2Form;

const Container = styled(Box)`
background: #fff;
padding: 16px;
.wrap-table {
  border-radius: 4px;
  border: 1px solid #CCD4DC;
  background: #F6F7FC;
}
table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 4px;
  overflow: hidden;

  thead tr th {
    border-bottom: 1px solid #CCD4DC;
    background: #E5E6EB;
    color: #505050;
    padding: 6px 0;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }

  tbody tr td {
    padding: 16px 8px;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    background: #F6F7FC;

    .ant-input {
      color: #1D2129;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .percent-decimal-input-precision .ant-input {
      text-align: right;
    }
  }
}

.btn-add-new {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: #6FABB6;
  box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -4px 0px 0px #144B55 inset;
  span {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
`;
const TipOptionItemStyled = styled.tr`
  flex-direction: column;
  border-radius: 5px;
  background: #E5E6EB;
  padding: 8px;

  label.checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    span {
      color: #1D2129;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
`;
type Props = {
  value?: IOptionTipObjectItem;
  name?: any;
  index: number;
  remove: () => void;
};
const TipOptionItem = ({ name, index, remove }: Props) => {
  return <TipOptionItemStyled>
    <td>
      <label className='checkbox'>
        <Form.Item
          noStyle
          name={[name, 'active']}
        >
          <SwitchBtn />
        </Form.Item>
        <span>
          Option {index + 1}
        </span>
      </label>
    </td>
    <td>
      <Form.Item
        noStyle
        name={[name, 'option']}
      >
        <PercentDecimalInputPrecision />
      </Form.Item>
    </td>
    <td>
      <button type='button' className="center" onClick={remove} >
        <img src={discount_delete_icon} alt='discount_delete_icon' style={{ width: 32, height: 32 }} />
      </button>
    </td>
  </TipOptionItemStyled>;
};