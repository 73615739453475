import { Form, Spin } from 'antd';
import cashierApis from 'features/cashier/services/apis';
import { IGiftCardDetail } from 'features/cashier/services/types/giftCard';
import { debounce, find, first } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { IResponseDataBody } from 'services/response';
import { MULTI_PAYMENT_TYPES } from './constants';
type Props = {};
const GiftCodeInput = ({ }: Props) => {
  const form = Form.useFormInstance();
  const [loading, setLoading] = useState(false);
  const giftCode = Form.useWatch('giftCardCode', form);
  useEffect(() => {
    form.resetFields(['giftCardAvailable', 'giftCardId', 'giftCardCodeValid']);
    if (!giftCode) return;
    _debounce(giftCode?.trim());
  }, [giftCode]);

  const _debounce = useCallback(debounce(async (code: string) => {
    try {
      if (code?.length < 4) return;
      setLoading(true);
      try {
        const res: IResponseDataBody<IGiftCardDetail> = await cashierApis.getGiftCardInfo(code);
        const giftCardData = res.data.data;
        if (giftCardData) {
          const { amount = 0, usedAmount = 0, id } = giftCardData || {};
          form.setFieldsValue({
            giftCardId: id,
            giftCardAvailable: amount - usedAmount,
          });
        }
      } catch (error) { }
      finally {
        setLoading(false);
        form.validateFields(['giftCardCodeValid']);
      }
    } catch (error) {
    }
  }, 500), []);

  return (
    <Spin spinning={loading}>
      <Form.Item noStyle name={'giftCardCodeValid'} rules={[
        ({ getFieldValue }) => ({
          async validator() {
            const amount = getFieldValue(MULTI_PAYMENT_TYPES.GIFT_CARD);
            const giftCardId = getFieldValue('giftCardId');
            const available = getFieldValue('giftCardAvailable');
            if (amount > 0) {
              if (!giftCardId) return Promise.reject('Gift number not exist');
              if (amount > available) {
                return Promise.reject('Exceed balance');
              }
            }
            return Promise.resolve();
          }
        }),
      ]} />
      <Form.Item shouldUpdate noStyle>{({ getFieldsError }) => {
        const errors = getFieldsError().filter(o => o.errors.length > 0).map(o => ({ errors: o.errors, name: first(o.name) }));
        const error = first(find(errors, o => o.name === 'giftCardCodeValid')?.errors) || '';
        return <p className='ant-form-item-explain-error'>{error}</p>;
      }}</Form.Item>
    </Spin>
  );
};

export default GiftCodeInput;
