import { SVGProps } from 'react';
const IcManual = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={41}
    height={40}
    viewBox='0 0 41 40'
    fill='none'
    {...props}
  >
    <path
      fill={props?.fill || '#1D2129'}
      d='M3.354 3.71c-.042-1.08.938-2.084 2.028-2.04 9.41-.004 18.821-.004 28.232 0 1.091-.048 2.062.96 2.03 2.037.002 5.362 0 10.723 0 16.084-.822-.007-1.644-.007-2.466 0 .019-5.218-.002-10.435.01-15.653-9.12-.003-18.242.002-27.362-.003-.003 9.713-.002 19.425-.002 29.136 4.551-.006 9.1-.001 13.65-.003-.005.82-.009 1.639 0 2.46-4.748-.024-9.496.001-14.244-.012-1.07-.026-1.932-1.05-1.876-2.099V3.711Z'
    />
    <path
      fill={props?.fill || '#1D2129'}
      d='M10.92 12.082c.648-.107 1.707-.346 2.028.433.072.51.13 1.076-.062 1.564-.316.518-.977.372-1.485.384-.5.07-.937-.408-.877-.897.008-.516-.124-1.161.396-1.484ZM15.44 12.08c.305-.136.646-.078.97-.09 3.675.004 7.353.002 11.028 0 .51-.068.95.416.892.914.072.588.064 1.506-.718 1.552-3.903.017-7.807.004-11.71.007-.493.07-.915-.417-.855-.89.006-.52-.129-1.165.392-1.492ZM11.097 17.791c.59-.058 1.372-.215 1.79.336.186.478.124 1.016.08 1.515-.136.63-.894.657-1.409.614-.491.073-1.064-.301-1.03-.832-.018-.58-.133-1.405.569-1.633ZM15.317 17.973c.162-.128.364-.245.582-.227 3.936-.002 7.873.003 11.81-.002.765.283.653 1.181.618 1.836-.052.421-.48.689-.883.672-3.852-.002-7.705.002-11.557 0-.428.005-.872-.38-.835-.825.031-.486-.16-1.091.265-1.454ZM34.63 21.921c.287-.349.88-.339 1.164.01.722.739 1.502 1.422 2.175 2.205 0 .194 0 .39-.002.585-.332.4-.696.774-1.075 1.13a.796.796 0 0 1-1.127-.054c-.646-.641-1.3-1.278-1.943-1.921-.245-.227-.299-.592-.188-.896.294-.387.658-.711.995-1.059ZM10.93 23.607c.471-.154.982-.088 1.467-.063.7.17.623 1 .601 1.555.037.434-.312.899-.767.882-.591.003-1.455.174-1.679-.556-.01-.612-.229-1.417.378-1.818ZM15.444 23.608c.253-.105.532-.086.799-.091 3.732.006 7.464.001 11.195.003.503-.053.966.398.904.905.058.593.048 1.526-.747 1.554-3.898.017-7.795.004-11.693.007-.486.065-.914-.413-.855-.884 0-.521-.116-1.161.397-1.494ZM31.956 24.626c.277-.358.872-.381 1.18-.049.654.63 1.314 1.257 1.956 1.901.369.316.34.934-.036 1.232-3.149 3.217-6.325 6.41-9.494 9.606-1.338.383-2.712.648-4.055 1.018.311-1.344.592-2.692.927-4.028 3.125-3.273 6.342-6.46 9.522-9.68Zm-8.89 10.313c-.152.783-.364 1.553-.508 2.339.765-.196 1.532-.378 2.3-.563-.59-.598-1.21-1.168-1.792-1.776Z'
    />
  </svg>
);
export default IcManual;
