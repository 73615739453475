import { Form, FormInstance, InputRef } from 'antd';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
import InputEmail from './InputEmail';
import Modal from './Modal';
import { isMobileBrowser } from 'utils/detachBehavior';

const isMobile = isMobileBrowser();
interface IModalRef {
  open: (defaultValue?: string) => void;
  close: () => void;
}
export const useModalEmailRef = () => useRef<IModalRef>(null);
type FormValue = { email: string };
export type IModalEmailFuncFinish = (values: FormValue) => void;
type Props = {
  form: FormInstance<FormValue>;
  onFinish: IModalEmailFuncFinish;
  v2?: boolean;
};
const ModalEmail = forwardRef<IModalRef, Props>(({ form, onFinish, v2 }, ref) => {
  const inputRef = useRef<InputRef>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  useImperativeHandle(ref, () => ({
    open: (defaultValue?: string) => {
      setIsOpenModal(true);
      form.setFieldValue('email', defaultValue || '');
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    },
    close: () => setIsOpenModal(false),
  }));

  if (!isOpenModal) return null;

  return (
    <ModalEmailStyled
      visible
      modalTitle='Your Email'
      closable={false}
      onClose={() => setIsOpenModal(false)}
      noneBodyStyle
      okTitle='Send'
      centered={!isMobile}
      containerPadding={0}
      className='modal-signature'
      footerPadding={1.5}
      onSubmit={() => form.submit()}
      v2={v2}
      width={v2 ? 800 : undefined}
    >
      <FormStyled className={v2 ? 'v2' : ''}>
        <Form form={form} onFinish={onFinish} validateTrigger={['onSubmit']}>
          <InputEmail
            name={'email'}
            inputRef={inputRef}
            rules={[{ required: true, type: 'email', message: 'Please enter your email!' }]}
          />
        </Form>
      </FormStyled>
    </ModalEmailStyled>
  );
});
ModalEmail.displayName = 'ModalEmail';
export default ModalEmail;

const ModalEmailStyled = styled(Modal)``;

const FormStyled = styled.div`
padding: 1rem 1.5rem;
  .ant-input {
    height: 5rem;
    font-weight: 600;
    font-size: 1.8rem;
    text-align: center;
  }

&.v2 {
 .InputEmailStyled .suggestion {
  .box-item {
    flex: 1;
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 100px;
    border: 1px solid #4384FF;
    background: #FFF;
    .CONTENT_1 {
      color: #4384FF;
      text-align: center;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
    }
  }
 }
}
`;