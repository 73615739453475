import Box from 'components/Box';
import InputPhone from 'components/InputPhone';
import cashierApis from 'features/cashier/services/apis';
import { ICustomerReferralData } from 'features/cashier/services/types/customer';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { IResponseDataBody } from 'services/response';
import styled from 'styled-components';
import referral_icon from './referral.svg';

const ReferralIcon = (
  <Box display="flex" pr="1" mr="1" br="black">
    <img src={referral_icon} style={{ width: 24, height: 24 }} />
  </Box>
);

type Props = {
  customerId: string;
  onSaveCustomer?: (data: ICustomerReferralData) => void;
};

const ReferralInput = ({ customerId, onSaveCustomer = () => undefined }: Props) => {
  const [phone, setPhone] = useState('');
  const checkReferral = async (rawValue: string) => {
    try {
      const res: IResponseDataBody<ICustomerReferralData> = await cashierApis.checkReferralSender(customerId || '', rawValue);
      if (res.data.data) {
        onSaveCustomer(res.data.data);
      }
    } catch (error) {

    }
  };

  const debouncedSearch = useCallback(
    debounce(async (phone) => {
      const rawValue = phone?.replace(/[^0-9]/g, '') || '';
      if (rawValue?.length < 10) return;
      checkReferral(rawValue);
    }, 1000),
    [customerId]
  );

  useEffect(() => {
    if (phone) {
      debouncedSearch(phone);
    }
    return () => {
      debouncedSearch.cancel();
    };
  }, [phone, debouncedSearch]);

  return (
    <Container>
      <InputPhone
        prefix={ReferralIcon}
        placeholder="Referred by / Enter Phone Number"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />
    </Container>
  );
};

export default ReferralInput;

const Container = styled.div`
  .ant-input-affix-wrapper > input.ant-input {
    color: #64748B;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    min-width: 256px;
  }
`;