import * as React from 'react';
import { SVGProps } from 'react';
const IcCopy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={32}
    height={32}
    viewBox='0 0 32 32'
    fill='none'
    {...props}
  >
    <path
      fill={props?.fill || '#4384FF'}
      fillRule='evenodd'
      d='M2.712 10.174c0-1.371 1.075-2.433 2.693-2.433 1.619 0 4.94-.036 4.94-.036s-.051-1.142-.051-2.594 1.317-2.444 2.896-2.444c1.58 0 9.403.065 10.17.065.768 0 1.391.281 2.252.926.86.646 2.161 1.726 2.805 2.445.644.719.916 1.139.916 1.732s0 12.555-.01 13.941c-.01 1.386-1.304 2.41-2.837 2.431-1.533.02-4.824.02-4.824.02s.077 1.025.077 2.475c0 1.45-1.343 2.583-2.868 2.623-1.525.04-11.913-.08-13.393-.04-1.48.04-2.811-1.11-2.811-2.52 0-1.409.045-15.22.045-16.591Zm2.771.645-.19 15.168a.64.64 0 0 0 .188.463.655.655 0 0 0 .467.192l12.239.036a.648.648 0 0 0 .464-.188.63.63 0 0 0 .183-.461l-.036-1.453a.324.324 0 0 0-.21-.298.335.335 0 0 0-.128-.021s-3.621.104-5.26.048c-1.64-.057-2.761-1.112-2.762-2.535V10.475a.321.321 0 0 0-.205-.302.333.333 0 0 0-.127-.024l-3.953.014a.68.68 0 0 0-.47.193.662.662 0 0 0-.2.463Zm17.637-5.76c-.276-.237-.5-.137-.5.224v2.819c0 .18.148.328.331.329l3.456.016c.365.002.437-.189.161-.426L23.12 5.06Zm-9.54.138a.336.336 0 0 0-.308.2.325.325 0 0 0-.026.125l-.103 15.73a.48.48 0 0 0 .303.453c.06.024.125.037.19.037l12.452-.03a.342.342 0 0 0 .237-.097.334.334 0 0 0 .102-.232l.21-10.198a.315.315 0 0 0-.199-.302.326.326 0 0 0-.126-.025s-4.334.007-5.097.007c-.764 0-1.248-.363-1.248-1.059s-.015-4.242-.015-4.242a.33.33 0 0 0-.098-.232.34.34 0 0 0-.235-.097l-6.038-.038Z'
      clipRule='evenodd'
    />
  </svg>
);
export default IcCopy;
