import { AxiosResponse } from 'axios';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { setLoading, setLoadingPage } from 'services/UI/sagas';
import { ISagaFunc } from 'services/actionConfigs';
import { IResponseDataBody } from 'services/response';
import shopActions from 'services/shop/actions';
import {
  ICategoryItemData,
  IServiceItemData,
} from 'services/shop/types/categories';
import { IProductTaxConfigResData } from 'services/shop/types/tax';
import actions from './actions';
import apis from './apis';
import { dayOffParams, PATH_LOADING } from './constants';
import {
  IChangeCommissionPrice,
  IDayOffParams,
  IDeleteProduct,
  IDeleteTurnSettingService,
  IDeleteVIPs,
  IEditCateBodyType,
  IUpdateCateBodyForProduct,
  IUpdateLateForWork,
  IUpdateQuickPayBody,
  IUpdateTurnSettingService,
  IUpdateTurnSettingTicket,
} from './types/api';
import { DataItem, IBodyItemNotificationPut } from './types/generalSetting';
import {
  ILoyaltyDataBody,
  IPaymentMethodParams,
  IReferralConfig,
  IReward,
  IShopDiscountParams,
  ISpecialDiscount,
  ITaxParams,
} from './types/paymentSetting';
import {
  ICategories,
  IDayOffItem,
  IGiftCard,
  ILateForWork,
  IPageable,
  IProduct,
  ITurnSetting,
  IVIPs,
} from './types/reducer';
import { IQuickPayTurnItem, IUpdateQuickPayTurnItem } from './types/turn';

const editCategory: ISagaFunc<IEditCateBodyType> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const res: AxiosResponse<{ data: ICategoryItemData }> = yield call(
      apis.editCategory,
      payload
    );
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.editCategory.success(resData));
      yield put(shopActions.getCategoriesWithoutBE.fetch());
    }
  } catch (error) {
    yield put(actions.editCategory.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};
const createCategory: ISagaFunc<IEditCateBodyType> = function* ({ payload }) {
  // yield setLoading(PATH_LOADING.createCategory, true);
  // yield delay(200);
  try {
    const res: AxiosResponse<{ data: ICategoryItemData }> = yield call(
      apis.createCategory,
      payload
    );
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.createCategory.success(resData));
      yield put(shopActions.getNewCategories.fetch());
    }
  } catch (error) {
    yield put(actions.createCategory.fail({}));
  }
  //  finally {
  //   yield setLoading(PATH_LOADING.createCategory, false);
  // }
};

const addMoreServiceByCate: ISagaFunc<IEditCateBodyType> = function* ({
  payload,
}) {
  yield setLoadingPage(true);
  yield delay(200);
  try {
    const res: AxiosResponse<{ data: any }> = yield call(
      apis.addMoreServiceByCate,
      payload
    );

    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.addMoreServiceByCate.success(payload));
      yield put(shopActions.getNewCategories.fetch());
    }
  } catch (error) {
    yield put(actions.addMoreServiceByCate.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};

const createOrUpdateServiceAddOn: ISagaFunc<any> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const res: AxiosResponse<{ data: IServiceItemData }> = yield call(
      apis.createOrUpdateServiceAddOn,
      payload
    );
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.createOrUpdateServiceAddOn.success(resData));
      yield put(shopActions.getNewAddOnList.fetch());
    }
  } catch (error) {
    yield put(actions.createOrUpdateServiceAddOn.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};
const deleteAddOnService: ISagaFunc<{ id: string; pinCode: string }> =
  function* ({ payload }) {
    yield setLoadingPage(true);
    yield delay(100);
    try {
      const res: AxiosResponse<{ data: IServiceItemData }> = yield call(
        apis.deleteAddOnService,
        payload
      );
      const resData = res?.data?.data;
      if (resData) {
        yield put(actions.deleteAddOnService.success(resData));
        yield put(shopActions.getNewAddOnList.fetch());
      }
    } catch (error) {
      yield put(actions.deleteAddOnService.fail({}));
    } finally {
      yield setLoadingPage(false);
    }
  };

const getListCategoriesForProduct: ISagaFunc<any> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getListCategoriesForProduct, true);
  yield delay(100);

  try {
    const res: AxiosResponse<{ data: ICategories }> = yield call(
      apis.getListCategoriesForProduct,
      payload
    );
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.getListCategoriesForProduct.success(resData));
      yield put(shopActions.get.productCategories.success(resData));
    }
  } catch (error) {
    yield put(actions.getListCategoriesForProduct.fail(''));
  } finally {
    yield setLoading(PATH_LOADING.getListCategoriesForProduct, false);
  }
};

const createCategoryForProduct: ISagaFunc<IUpdateCateBodyForProduct> =
  function* ({ payload }) {
    yield setLoadingPage(true);
    yield delay(100);

    try {
      const res: AxiosResponse<{ data: ICategoryItemData }> = yield call(
        apis.createCategoryForProduct,
        payload
      );
      const resData = res?.data?.data;
      if (resData) {
        yield put(actions.reloadCategory(true));
      }
    } catch (error) {
      yield put(actions.reloadCategory(false));
    } finally {
      yield setLoadingPage(false);
    }
  };
const updateNameCategoryForProduct: ISagaFunc<IUpdateCateBodyForProduct> =
  function* ({ payload }) {
    yield setLoadingPage(true);
    yield delay(100);

    try {
      const res: AxiosResponse<{ data: ICategoryItemData }> = yield call(
        apis.updateNameCategoryForProduct,
        payload
      );
      const resData = res?.data?.data;
      if (resData) {
        yield put(
          actions.updateNameCategoryForProduct.success(payload.cateName)
        );
        yield put(shopActions.getNewCategories.fetch());
      }
    } catch (error) {
      yield put(actions.reloadCategory(false));
    } finally {
      yield setLoadingPage(false);
    }
  };
const deleteCategoryForProduct: ISagaFunc<{ id: string; pinCode: string }> =
  function* ({ payload }) {
    yield setLoadingPage(true);
    yield delay(100);

    try {
      const res: AxiosResponse<{ data: ICategoryItemData }> = yield call(
        apis.deleteCategoryForProduct,
        payload
      );
      const resData = res?.data?.data;
      if (resData) {
        yield put(actions.deleteCategoryForProduct.success(true));
        yield put(shopActions.getNewCategories.fetch());
      }
    } catch (error) {
      yield put(actions.reloadCategory(false));
    } finally {
      yield setLoadingPage(false);
    }
  };
const deleteCategoriesTabService: ISagaFunc<{ id: string; pinCode: string }> =
  function* ({ payload }) {
    yield setLoadingPage(true);
    yield delay(100);
    try {
      const res: AxiosResponse<{ data: ICategoryItemData }> = yield call(
        apis.deleteCategoryForProduct,
        payload
      );
      const resData = res?.data?.data;
      if (resData) {
        yield put(actions.deleteCategoriesTabService.success({}));
      }
    } catch (error) {
      yield put(actions.deleteCategoriesTabService.fail({}));
    } finally {
      yield setLoadingPage(false);
    }
  };

const getProductsByCategoryId: ISagaFunc<any> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getProductsByCategoryId, true);
  yield setLoadingPage(true);
  yield delay(100);

  try {
    const res: AxiosResponse<{
      data: IProduct[];
      pageable: IPageable;
    }> = yield call(apis.getProductsByCategoryId, payload);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.getProductsByCategoryId.success(resData));
    }
    const pageable = res?.data?.pageable;
    if (pageable) {
      yield put(actions.getProductsPageable.success(pageable));
    }
  } catch (error) {
    yield put(actions.getProductsByCategoryId.fail(error));
    yield put(actions.getProductsPageable.fail(error));
  } finally {
    yield setLoading(PATH_LOADING.getProductsByCategoryId, false);
    yield setLoadingPage(false);
  }
};
const createProduct: ISagaFunc<any> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.createProduct, true);
  yield setLoadingPage(true);
  yield delay(100);

  try {
    const res: AxiosResponse<{
      data: IProduct;
    }> = yield call(apis.createProduct, payload);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.reloadProduct(true));
    }
  } catch (error) {
    yield put(actions.reloadProduct(false));
  } finally {
    yield setLoading(PATH_LOADING.createProduct, false);
    yield setLoadingPage(false);
  }
};

const updateProduct: ISagaFunc<any> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.updateProduct, true);
  yield setLoadingPage(true);
  yield delay(100);

  try {
    const res: AxiosResponse<{
      data: IProduct;
    }> = yield call(apis.updateProduct, payload);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.reloadProduct(true));
    }
  } catch (error) {
    yield put(actions.reloadProduct(false));
  } finally {
    yield setLoading(PATH_LOADING.updateProduct, false);
    yield setLoadingPage(false);
  }
};

const deleteProduct: ISagaFunc<IDeleteProduct> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.deleteProduct, true);
  yield setLoadingPage(true);
  yield delay(100);

  try {
    const res: AxiosResponse<{
      data: IProduct;
    }> = yield call(apis.deleteProduct, payload);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.reloadProduct(true));
    }
  } catch (error) {
    yield put(actions.reloadProduct(false));
  } finally {
    yield setLoading(PATH_LOADING.deleteProduct, false);
    yield setLoadingPage(false);
  }
};

const changeCommissionPrice: ISagaFunc<IChangeCommissionPrice> = function* ({
  payload,
}) {
  yield setLoading(PATH_LOADING.deleteProduct, true);
  yield setLoadingPage(true);
  yield delay(100);

  try {
    const res: AxiosResponse<{
      data: IProduct;
    }> = yield call(apis.changeCommissionPrice, payload);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.reloadProduct(true));
    }
  } catch (error) {
    yield put(actions.reloadProduct(false));
  } finally {
    yield setLoading(PATH_LOADING.changeCommissionPrice, false);
    yield setLoadingPage(false);
  }
};

const getVIP: ISagaFunc<any> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getVIP, true);
  yield setLoadingPage(true);
  yield delay(100);

  try {
    const res: AxiosResponse<{
      data: IProduct;
    }> = yield call(apis.getVIP, payload);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.getVIP.success(resData));
    }
  } catch (error) {
    yield put(actions.getVIP.fail(error));
  } finally {
    yield setLoading(PATH_LOADING.getVIP, false);
    yield setLoadingPage(false);
  }
};

const createVIP: ISagaFunc<IVIPs> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.createVIP, true);
  yield setLoadingPage(true);
  yield delay(100);

  try {
    const res: AxiosResponse<{
      data: IVIPs;
    }> = yield call(apis.createVIP, payload);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.reloadVIPs(true));
    }
  } catch (error) {
    yield put(actions.reloadVIPs(false));
  } finally {
    yield setLoading(PATH_LOADING.createVIP, false);
    yield setLoadingPage(false);
  }
};

const updateVIP: ISagaFunc<IVIPs[]> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.updateVIP, true);
  yield setLoadingPage(true);
  yield delay(100);

  try {
    const res: AxiosResponse<{
      data: IVIPs;
    }> = yield call(apis.updateVIP, payload);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.reloadVIPs(true));
    }
  } catch (error) {
    yield put(actions.reloadVIPs(false));
  } finally {
    yield setLoading(PATH_LOADING.updateVIP, false);
    yield setLoadingPage(false);
  }
};

const deleteVIP: ISagaFunc<IDeleteVIPs> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.deleteVIP, true);
  yield setLoadingPage(true);
  yield delay(100);

  try {
    const res: AxiosResponse<{
      data: IProduct;
    }> = yield call(apis.deleteVIP, payload);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.reloadVIPs(true));
    }
  } catch (error) {
    yield put(actions.reloadVIPs(false));
  } finally {
    yield setLoading(PATH_LOADING.deleteVIP, false);
    yield setLoadingPage(false);
  }
};

const getTurnSetting: ISagaFunc<any> = function* () {
  yield setLoading(PATH_LOADING.getTurnSetting, true);
  yield setLoadingPage(true);
  yield delay(200);
  yield put(actions.getTurnSettingRangeSales.fetch());
  try {
    const res: AxiosResponse<{
      data: ITurnSetting;
    }> = yield call(apis.getTurnSetting);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.getTurnSetting.success(resData));
    }
    yield put(actions.reloadTurnSetting(false));
  } catch (error) {
    yield put(actions.getTurnSetting.fail(error));
  } finally {
    yield setLoading(PATH_LOADING.getTurnSetting, false);
    yield setLoadingPage(false);
  }
};

const getTurnSettingRangeSales = function* () {
  try {
    const res: IResponseDataBody<any> = yield call(apis.getTurnSettingRangeSales);
    if (res.data.data) {
      yield put(actions.getTurnSettingRangeSales.success(res.data.data));
    }
  } catch (error) {
    yield put(actions.getTurnSettingRangeSales.fail({}));
  }
};

const getTurnSettingAppointment = function* () {
  yield delay(200);
  try {
    const res: IResponseDataBody<any> = yield call(apis.getTurnSettingAppointment);
    if (res.data.data) {
      yield put(actions.getTurnSettingAppointment.success(res.data.data));
    }
  } catch (error) {
    yield put(actions.getTurnSettingAppointment.fail({}));
  }
};

const updateTurnSettingTiket: ISagaFunc<IUpdateTurnSettingTicket> = function* ({
  payload,
}) {
  yield setLoading(PATH_LOADING.updateTurnSettingTiket, true);
  yield setLoadingPage(true);
  yield delay(100);

  try {
    const res: AxiosResponse<{
      // TODO: update
      data: any;
    }> = yield call(apis.updateTurnSettingTiket, payload);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.updateTurnSettingTiket.success(resData));
      yield put(actions.reloadTurnSetting(true));
    }
  } catch (error) {
    yield put(actions.updateTurnSettingTiket.fail(error));
    yield put(actions.reloadTurnSetting(false));
  } finally {
    yield setLoading(PATH_LOADING.updateTurnSettingTiket, false);
    yield setLoadingPage(false);
  }
};
const updateTurnSettingService: ISagaFunc<IUpdateTurnSettingService> =
  function* ({ payload }) {
    yield setLoadingPage(true);
    yield delay(100);
    try {
      const res: AxiosResponse<{
        // TODO: update
        data: any;
      }> = yield call(apis.updateTurnSettingService, payload);
      const resData = res?.data?.data;
      if (resData) {
        yield put(actions.reloadTurnSetting(true));
      }
    } catch (error) {
      yield put(actions.reloadTurnSetting(false));
    } finally {
      yield setLoadingPage(false);
    }
  };

const deleteTurnSettingService: ISagaFunc<IDeleteTurnSettingService> =
  function* ({ payload }) {
    yield setLoadingPage(true);
    yield delay(100);
    try {
      const res: AxiosResponse<{
        // TODO: update
        data: any;
      }> = yield call(apis.deleteTurnSettingService, payload);
      const resData = res?.data?.data;
      if (resData) {
        yield put(actions.reloadTurnSetting(true));
      }
    } catch (error) {
      yield put(actions.reloadTurnSetting(false));
    } finally {
      yield setLoadingPage(false);
    }
  };
const getLateForWork: ISagaFunc<any> = function* () {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const res: AxiosResponse<{
      data: ILateForWork;
    }> = yield call(apis.getLateForWork);
    const resData = res?.data?.data;
    if (resData) {
      yield all([
        put(actions.getLateForWork.success(resData)),
        put(actions.reloadLateForWork(false)),
      ]);
    }
  } catch (error) {
    yield put(actions.getLateForWork.fail(error));
  } finally {
    yield setLoadingPage(false);
  }
};
const updateLateForWork: ISagaFunc<IUpdateLateForWork> = function* ({
  payload,
}) {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const res: AxiosResponse<{
      data: ILateForWork;
    }> = yield call(apis.updateLateForWork, payload);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.reloadLateForWork(true));
    }
  } catch (error) {
    yield put(actions.reloadLateForWork(false));
  } finally {
    yield setLoadingPage(false);
  }
};
const getQuickPayList: ISagaFunc<any> = function* () {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const res: AxiosResponse<{
      data: any;
    }> = yield call(apis.getQuickPayList);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.getQuickPayList.success(resData));
    }
  } catch (error) {
    yield put(actions.getQuickPayList.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};
const updateQuickPayList: ISagaFunc<IUpdateQuickPayBody> = function* ({
  payload,
}) {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const res: AxiosResponse<{
      data: any;
    }> = yield call(apis.updateQuickPayList, payload);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.getQuickPayList.success(resData));
    }
  } catch (error) {
    yield put(actions.updateQuickPayList.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};
// start saga Payment Setting
const getTax: ISagaFunc = function* () {
  yield setLoading(PATH_LOADING.getTax, true);
  yield delay(100);
  try {
    const res: AxiosResponse<{
      data: any;
    }> = yield call(apis.getTax);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.getTax.success(resData));
    }
  } catch (error) {
    yield put(actions.getTax.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getTax, false);
  }
};
const getDiscount: ISagaFunc = function* () {
  yield setLoading(PATH_LOADING.getDiscount, true);
  yield delay(100);
  try {
    const res: AxiosResponse<{
      data: any;
    }> = yield call(apis.getDiscount);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.getDiscount.success(resData));
    }
  } catch (error) {
    yield put(actions.getDiscount.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getDiscount, false);
  }
};
const getSpecialDiscount: ISagaFunc = function* () {
  yield setLoading(PATH_LOADING.getSpecialDiscount, true);
  yield delay(100);
  try {
    const res: AxiosResponse<{
      data: ISpecialDiscount[];
    }> = yield call(apis.getSpecialDiscount);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.getSpecialDiscount.success(resData));
    }
  } catch (error) {
    yield put(actions.getSpecialDiscount.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getSpecialDiscount, false);
  }
};
const getLoyaltyReward: ISagaFunc = function* () {
  yield setLoading(PATH_LOADING.getLoyaltyReward, true);
  yield delay(100);
  try {
    const res: AxiosResponse<{
      data: IReward[];
    }> = yield call(apis.getLoyaltyReward);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.getLoyaltyReward.success(resData));
    }
  } catch (error) {
    yield put(actions.getLoyaltyReward.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getLoyaltyReward, false);
  }
};
const getOtherPaymentMethod: ISagaFunc = function* () {
  yield setLoading(PATH_LOADING.getOtherPaymentMethod, true);
  yield delay(100);
  try {
    const res: AxiosResponse<{
      data: any;
    }> = yield call(apis.getOtherPaymentMethod);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.getOtherPaymentMethod.success(resData));
    }
  } catch (error) {
    yield put(actions.getOtherPaymentMethod.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getOtherPaymentMethod, false);
  }
};
const getPolicyTerm: ISagaFunc = function* () {
  yield setLoading(PATH_LOADING.getPolicyTerm, true);
  yield delay(100);
  try {
    const res: AxiosResponse<{
      data: any;
    }> = yield call(apis.getPolicyTerm);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.getPolicyTerm.success(resData));
    }
  } catch (error) {
    yield put(actions.getPolicyTerm.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getPolicyTerm, false);
  }
};
const getGiftCard: ISagaFunc = function* () {
  yield setLoading(PATH_LOADING.getGiftCard, true);
  yield delay(100);
  try {
    const res: AxiosResponse<{
      data: any;
    }> = yield call(apis.getGiftCard);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.getGiftCard.success(resData));
    }
  } catch (error) {
    yield put(actions.getGiftCard.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getGiftCard, false);
  }
};

const postTax: ISagaFunc<ITaxParams> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const res: AxiosResponse<{
      data: any;
    }> = yield call(apis.postTax, payload);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.postTax.success(resData));
    }
  } catch (error) {
    yield put(actions.postTax.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};
const postProductTax: ISagaFunc<IProductTaxConfigResData> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const res: AxiosResponse<{
      data: any;
    }> = yield call(apis.postProductTax, payload);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.postProductTax.success(resData));
      yield put(shopActions.get.productTaxConfig.success(resData));
    }
  } catch (error) {
    yield put(actions.postProductTax.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};
const postDiscount: ISagaFunc<IShopDiscountParams> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const res: AxiosResponse<{
      data: any;
    }> = yield call(apis.postDiscount, payload);
    const resData = res?.data?.data;
    if (resData) {
      yield put(shopActions.get.discountSetting.fetch(true));
    }
  } catch (error) {
    yield put(actions.postDiscount.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};
const postSpecialDiscount: ISagaFunc<any> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const res: AxiosResponse<{
      data: any;
    }> = yield call(apis.postSpecialDiscount, payload);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.getSpecialDiscount.fetch());
    }
  } catch (error) {
    yield put(actions.postSpecialDiscount.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};
const postLoyalty: ISagaFunc<ILoyaltyDataBody[]> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const res: AxiosResponse<{
      data: any;
    }> = yield call(apis.postLoyalty, payload);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.getLoyaltyReward.fetch());
    }
  } catch (error) {
    yield put(actions.postLoyalty.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};
const postOtherPaymentMethod: ISagaFunc<IPaymentMethodParams[]> = function* ({
  payload,
}) {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const res: AxiosResponse<{
      data: any;
    }> = yield call(apis.postOtherPaymentMethod, payload);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.postOtherPaymentMethod.success(resData));
      yield put(shopActions.get.otherPaymentMethod.fetch());
    }
  } catch (error) {
    yield put(actions.postOtherPaymentMethod.fail({}));
  } finally {
    yield setLoadingPage(false);
    yield put(actions.getOtherPaymentMethod.fetch());
  }
};
const postGiftCard: ISagaFunc<IGiftCard> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const res: AxiosResponse<{
      data: any;
    }> = yield call(apis.postGiftCard, payload);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.postGiftCard.success(resData));
    }
  } catch (error) {
    yield put(actions.postGiftCard.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};
// end saga Payment Setting

// start General Setting
const getNotification: ISagaFunc = function* () {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const res: AxiosResponse<{
      data: DataItem[];
    }> = yield call(apis.getNotification);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.getNotification.success(resData));
    }
  } catch (error) {
    yield put(actions.getNotification.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};
const postNotification: ISagaFunc<IBodyItemNotificationPut[]> = function* ({
  payload,
}) {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const res: AxiosResponse<{
      data: DataItem[];
    }> = yield call(apis.postNotification, payload);
    const resData = res?.data?.data;
    if (resData) {
      const resNewList: AxiosResponse<{
        data: DataItem[];
      }> = yield call(apis.getNotification);
      const resNewListData = resNewList?.data?.data;
      if (resNewListData) {
        yield put(actions.getNotification.success(resNewListData));
      }
    }
  } catch (error) {
    yield put(actions.postNotification.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};

const getScheduler: ISagaFunc = function* () {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const res: AxiosResponse<{
      data: DataItem[];
    }> = yield call(apis.getScheduler);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.getScheduler.success(resData));
    }
  } catch (error) {
    yield put(actions.getScheduler.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};

const getQuickPayTurnList = function* () {
  yield delay(200);
  try {
    const res: AxiosResponse<{ data: IQuickPayTurnItem[]; }> = yield call(apis.getQuickPayTurnList);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.getQuickPayTurnList.success(resData));
    }
  } catch (error) {
    yield put(actions.getQuickPayTurnList.fail({}));
  }
};

const updateQuickPayTurnList: ISagaFunc<IUpdateQuickPayTurnItem[]> = function* ({ payload }) {
  yield setLoadingPage(true);
  try {
    const res: AxiosResponse<{ data: IQuickPayTurnItem[]; }> = yield call(apis.updateQuickPayTurnList, payload);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.getQuickPayTurnList.success(resData));
    }
  } catch (error) {
    yield put(actions.updateQuickPayTurnList.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};

const postScheduler: ISagaFunc<any> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const res: AxiosResponse<{
      data: DataItem[];
    }> = yield call(apis.postScheduler, payload);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.postScheduler.success(resData));
    }
  } catch (error) {
    yield put(actions.postScheduler.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};

// end General Setting

// start day off

const getDayOffList: ISagaFunc = function* () {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const res: AxiosResponse<{
      data: IDayOffItem[];
    }> = yield call(apis.getDayOffList, dayOffParams);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.getDayOffList.success(resData));
    }
  } catch (error) {
    yield put(actions.getDayOffList.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};
const postDayOffList: ISagaFunc<IDayOffParams> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const res: AxiosResponse<{
      data: any;
    }> = yield call(apis.postDayOffList, payload);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.postDayOffList.success(resData));
      yield put(actions.getDayOffList.fetch());
    }
  } catch (error) {
    yield put(actions.postDayOffList.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};
const deleteDayOffList: ISagaFunc<string> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const res: AxiosResponse<{
      data: any;
    }> = yield call(apis.deleteDayOffList, payload);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.deleteDayOffList.success(resData));
      yield put(actions.getDayOffList.fetch());
    }
  } catch (error) {
    yield put(actions.deleteDayOffList.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};

// end day off

const deleteMultiple: ISagaFunc<string[]> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const res: AxiosResponse<{
      data: any;
    }> = yield call(apis.deleteMultiple, payload);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.deleteMultiple.success(payload));
    }
  } catch (error) {
    yield put(actions.deleteMultiple.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};

const getVIPProductList = function* () {
  yield delay(100);
  try {
    const res: IResponseDataBody<any> = yield call(apis.getVIPList);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.getVIPProductList.success(resData));
    }
  } catch (error) { }
};

const getReferralConfig = function* () {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const res: IResponseDataBody<IReferralConfig[]> = yield call(apis.getReferralConfig);
    const resData = res?.data?.data;
    if (resData) {
      yield put(actions.getReferralConfig.success(resData));
    }
  } catch (error) { 
  }
  finally
  {
    yield setLoadingPage(false);
  }
};

const getPrintCheckSetting = function* () {
  yield setLoadingPage(true);
  yield delay(100);
  yield put(shopActions.get.printCheckSetting.fetch());
  yield setLoadingPage(false);
};

export default function* settingServiceSagas() {
  yield takeLatest(actions.editCategory.fetch, editCategory);
  yield takeLatest(actions.createCategory.fetch, createCategory);
  yield takeLatest(actions.addMoreServiceByCate.fetch, addMoreServiceByCate);
  yield takeLatest(
    actions.createOrUpdateServiceAddOn.fetch,
    createOrUpdateServiceAddOn
  );
  yield takeLatest(
    actions.getListCategoriesForProduct.fetch,
    getListCategoriesForProduct
  );
  yield takeLatest(
    actions.createCategoryForProduct.fetch,
    createCategoryForProduct
  );
  yield takeLatest(
    actions.updateNameCategoryForProduct.fetch,
    updateNameCategoryForProduct
  );
  yield takeLatest(
    actions.getProductsByCategoryId.fetch,
    getProductsByCategoryId
  );
  yield takeLatest(
    actions.deleteCategoryForProduct.fetch,
    deleteCategoryForProduct
  );
  yield takeLatest(actions.createProduct.fetch, createProduct);
  yield takeLatest(actions.updateProduct.fetch, updateProduct);
  yield takeLatest(actions.deleteProduct.fetch, deleteProduct);
  yield takeLatest(actions.changeCommissionPrice.fetch, changeCommissionPrice);
  yield takeLatest(actions.getVIP.fetch, getVIP);
  yield takeLatest(actions.createVIP.fetch, createVIP);
  yield takeLatest(actions.updateVIP.fetch, updateVIP);
  yield takeLatest(actions.deleteVIP.fetch, deleteVIP);
  yield takeLatest(actions.getTurnSetting.fetch, getTurnSetting);
  yield takeLatest(
    actions.updateTurnSettingTiket.fetch,
    updateTurnSettingTiket
  );
  yield takeLatest(
    actions.updateTurnSettingService.fetch,
    updateTurnSettingService
  );
  yield takeLatest(
    actions.deleteTurnSettingService.fetch,
    deleteTurnSettingService
  );
  yield takeLatest(actions.getLateForWork.fetch, getLateForWork);
  yield takeLatest(actions.updateLateForWork.fetch, updateLateForWork);
  yield takeLatest(actions.getQuickPayList.fetch, getQuickPayList);
  yield takeLatest(actions.updateQuickPayList.fetch, updateQuickPayList);
  yield takeLatest(
    actions.deleteCategoriesTabService.fetch,
    deleteCategoriesTabService
  );
  yield takeLatest(actions.getTax.fetch, getTax);
  yield takeLatest(actions.getDiscount.fetch, getDiscount);
  yield takeLatest(actions.getSpecialDiscount.fetch, getSpecialDiscount);
  yield takeLatest(actions.getLoyaltyReward.fetch, getLoyaltyReward);
  yield takeLatest(actions.getOtherPaymentMethod.fetch, getOtherPaymentMethod);
  yield takeLatest(actions.getPolicyTerm.fetch, getPolicyTerm);
  yield takeLatest(actions.getGiftCard.fetch, getGiftCard);
  yield takeLatest(actions.postTax.fetch, postTax);
  yield takeLatest(actions.postProductTax.fetch, postProductTax);
  yield takeLatest(actions.postDiscount.fetch, postDiscount);
  yield takeLatest(actions.postSpecialDiscount.fetch, postSpecialDiscount);
  yield takeLatest(actions.postLoyalty.fetch, postLoyalty);
  yield takeLatest(
    actions.postOtherPaymentMethod.fetch,
    postOtherPaymentMethod
  );
  yield takeLatest(actions.postGiftCard.fetch, postGiftCard);
  yield takeLatest(actions.getNotification.fetch, getNotification);
  yield takeLatest(actions.postNotification.fetch, postNotification);
  yield takeLatest(actions.getScheduler.fetch, getScheduler);
  yield takeLatest(actions.postScheduler.fetch, postScheduler);
  yield takeLatest(actions.deleteAddOnService.fetch, deleteAddOnService);

  yield takeLatest(actions.getDayOffList.fetch, getDayOffList);
  yield takeLatest(actions.postDayOffList.fetch, postDayOffList);
  yield takeLatest(actions.deleteDayOffList.fetch, deleteDayOffList);
  yield takeLatest(actions.deleteMultiple.fetch, deleteMultiple);

  yield takeLatest(actions.getVIPProductList.fetch, getVIPProductList);

  yield takeLatest(actions.getQuickPayTurnList.fetch, getQuickPayTurnList);
  yield takeLatest(actions.updateQuickPayTurnList.fetch, updateQuickPayTurnList);
  yield takeLatest(actions.getPrintCheckSetting.fetch, getPrintCheckSetting);
  yield takeLatest(actions.getTurnSettingRangeSales.fetch, getTurnSettingRangeSales);
  yield takeLatest(actions.getTurnSettingAppointment.fetch, getTurnSettingAppointment);
  yield takeLatest(actions.getReferralConfig.fetch, getReferralConfig);
}
