import { message, QRCode } from 'antd';
import Icon from 'assets/Icons';
import clsx from 'clsx';
import Button from 'components/Button';
import Link from 'components/Link';
import Text from 'components/Text';
import {
  ICustomer,
  IResSummaryReport,
} from 'features/customer/services/types/api';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import moment from 'moment';
import { ReactNode, useRef } from 'react';
import styled from 'styled-components';
import { formatCurrency, formatNumber } from 'utils/formatCurrency';
import { maskPhone } from 'utils/formatPhone';
import edit_icon from '../icons/edit.svg';
import remove_icon from '../icons/remove.svg';
import CustomerNote from './CustomerNote';
type Props = {
  data?: ICustomer;
  summaryReportData?: IResSummaryReport;
  onDelete?: () => void;
  onEdit?: () => void;
};
const CustomerDetailHeaderV2WithReferral = ({
  data,
  onDelete,
  onEdit,
  summaryReportData,
}: Props) => {
  const qrCodeRef = useRef<HTMLDivElement>(null);

  const handleCopyLink = () => {
    if (data?.linkReferral) {
      navigator.clipboard
        .writeText(data?.linkReferral)
        .then(() => {
          message.success('Link copied to clipboard');
        })
        .catch(() => {
          message.error('Failed to copy link');
        });
    }
  };

  const handleDownloadQR = async () => {
    if (!qrCodeRef.current || !data?.linkReferral) {
      message.error('QR Code not available');
      return;
    }

    try {
      const element = qrCodeRef.current;

      const canvas = await html2canvas(element, {
        backgroundColor: '#FFFFFF',
        useCORS: true,
      });

      canvas.toBlob(
        (blob) => {
          if (blob) {
            saveAs(blob, 'referral-qrcode.png');
          } else {
            message.error('Failed to create image');
          }
        },
        'image/png',
        1.0
      );
    } catch (error) {
      console.error('Error downloading QR code:', error);
      message.error('Failed to download QR code');
    }
  };

  return (
    <Container>
      <Content>
        <DetailsContainer>
          <CustomerNameStyled>
            <div className='type-container'>
              <div
                className={clsx('button-type-customer', data?.accountPackage)}
              >
                <span>{data?.accountPackage || '--'}</span>
              </div>
              <div className='customer-name-text'>{data?.name || '--'}</div>
            </div>
            <div className='actions-container'>
              <button type='button' className='action-item' onClick={onEdit}>
                <img src={edit_icon} alt='edit_icon' className='action-icon' />
              </button>
              <button type='button' className='action-item' onClick={onDelete}>
                <img
                  src={remove_icon}
                  alt='remove_icon'
                  className='action-icon'
                />
              </button>
            </div>
          </CustomerNameStyled>
          <CustomerInfo>
            <div className='information-list'>
              <div className='information-item'>
                <span className='label'>Phone:</span>
                <span className='value'>
                  {data?.phone ? maskPhone(data?.phone) : '--'}
                </span>
              </div>
              <div className='information-item'>
                <span className='label'>Email:</span>
                <span className='value'>{data?.email || '--'}</span>
              </div>
              <div className='information-item'>
                <span className='label'>DOB:</span>
                <span className='value'>
                  {data?.birthday
                    ? moment(data?.birthday).format('MM-DD-YYYY')
                    : '--'}
                </span>
              </div>
            </div>
            <div className='information-list no-divider'>
              <div className='information-item'>
                <span className='label date'>Created Date:</span>
                <span className='value'>
                  {data?.createdDate
                    ? moment(data?.createdDate).format('MM-DD-YYYY')
                    : '--'}
                </span>
              </div>
              <div className='information-item'>
                <span className='label date'>Last Visit Date:</span>
                <span className='value'>
                  {data?.lastVisitedDate
                    ? moment(data?.lastVisitedDate).format('MM-DD-YYYY')
                    : '--'}
                </span>
              </div>
              <div className='information-item'>
                <span className='label date'>Visited:</span>
                <span className='value'>{data?.numberVisit || '--'}</span>
              </div>
            </div>
          </CustomerInfo>
          <ContainerNoteV2>
            <CustomerNote/>
          </ContainerNoteV2>
        </DetailsContainer>
        <ReferralContainer>
          <QrCodeBox ref={qrCodeRef}>
            <QRCode
              value={data?.linkReferral || ''}
              size={250}
              className='referral-qr-code'
            />
          </QrCodeBox>
          <ReferralInfo>
            <Text variant='H7'>Referral Link</Text>
            <Text variant='BODY_1'>
              Build your referral community and earn rewards
            </Text>
            <ReferralLink>
              <Icon type='link' />
              <Text variant='CONTENT_2'>Link:</Text>
              <Link url={data?.linkReferral || ''} className='text-link'>
                {data?.linkReferral && data?.linkReferral.length > 30
                  ? `${data?.linkReferral.substring(0, 28)}...`
                  : data?.linkReferral}
              </Link>
              <button type='button' onClick={handleCopyLink}>
                <Icon type='copy' />
              </button>
            </ReferralLink>
            <Button ghost className='download-qr' onClick={handleDownloadQR}>
              <Text variant='CONTENT_1' color='text_1'>
                Download QR
              </Text>
            </Button>
          </ReferralInfo>
        </ReferralContainer>
      </Content>
      <StatisticStyled>
        <StatisticItem
          label='spent'
          value={formatCurrency(summaryReportData?.amountSpend)}
          icon={<Icon type='IcGiftBox' />}
        />
        <StatisticItem
          label='point'
          value={formatNumber(summaryReportData?.point)}
          icon={<Icon type='IcPoint' />}
        />
        <StatisticItem
          label='walk-in'
          value={formatNumber(summaryReportData?.totalWalkIn)}
          icon={<Icon type='IcWalkin' />}
        />
        <StatisticItem
          label='appT'
          value={formatNumber(summaryReportData?.totalAppointment)}
          icon={<Icon type='IcApt' />}
        />
      </StatisticStyled>
    </Container>
  );
};

export default CustomerDetailHeaderV2WithReferral;
const StatisticItem = ({
  label,
  value,
  icon,
}: {
  label?: string;
  value?: string;
  icon?: ReactNode;
}) => {
  return (
    <div className='statistic-item'>
      <div className='statistic-item-content'>
        <p className='label'>{label}</p>
        <p className='value'>{value}</p>
      </div>
      <div className='icon-container'>{icon}</div>
    </div>
  );
};
const StatisticStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  padding: 16px;
  background: #f5f5f5;
  box-shadow: 0px 4px 8px 0px #00000026;

  .statistic-item {
    display: flex;
    padding: 16px 8px;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 4px;
    position: relative;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.15),
      0px 2px 4px -3px rgba(45, 35, 66, 0.15),
      0px -3px 0px 0px rgba(214, 214, 231, 0.15) inset;
    .statistic-item-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-right: 100px;
      padding-left: 20px;
      gap: 2px;
      p.label {
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px; /* 166.667% */
        text-transform: uppercase;
      }

      p.value {
        font-family: Poppins;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .icon-container {
      display: flex;
      width: 48px;
      height: 48px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      zoom: 1.2;
    }
  }
`;
const CustomerInfo = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  .information-list {
    display: flex;
    padding-right: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-right: 1px solid #ccd4dc;
    .information-item {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      span.label {
        width: 60px;
        color: #64748b;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        &.date {
          width: 130px;
        }
      }

      span.value {
        flex: 1;
        color: #1d2129;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  .no-divider {
    border-right: unset !important;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 12px;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex: 1;
  width: 100%;
  gap: 16px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #fff;
  border-radius: 10px;
  gap: 16px;
  padding: 24px 16px;
  flex: 1;
  width: 100%;
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25);
`;

const ReferralContainer = styled.div`
  display: inline-flex;
  background: #fff;
  align-items: center;
  max-height: 275px;
  border-radius: 8px;
  padding: 16px;
  gap: 16px;
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25);
`;
const QrCodeBox = styled.div`
  width: 250px;
  height: 250px;
  padding: 8px;
  background-color: #cbd5e1;

  .referral-qr-code {
    box-shadow: 0px 3.22px 3.22px 0px #00000040;
    background-color: white !important;
  }
`;

const ReferralInfo = styled.div`
  display: grid;
  grid-template-rows: auto auto auto 1fr;
  gap: 16px;
  .download-qr {
    width: 160px;
    background-color: #232f3e !important;
  }

  .download-qr:hover {
    background-color: #232f3eb6 !important;
  }
`;

const ReferralLink = styled.div`
  display: grid;
  grid-template-columns: auto auto auto 1fr;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  .text-link {
    color: #4384ff !important;
    text-decoration: unset !important;
    cursor: pointer;
  }
`;

const CustomerNameStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  .type-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    .button-type-customer {
      display: flex;
      height: 32px;
      padding: 0px 8px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 100px;
      background: #c6e9ef;
      span {
        color: #1d2129;
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      &.NEW {
        background: #feedbf;
      }
      &.VIP {
        background: #ffe5e7;
      }
      &.DELETE {
        background: #adadad;
      }
      &.REGULAR {
        background: #c6e9ef;
      }
    }
  }

  .customer-name-text {
    color: #1d2129;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 100% */
  }

  .actions-container {
    display: flex;
    align-items: center;
    gap: 16px;
    .action-item {
      display: flex;
      width: 80px;
      height: 80px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2px;
      border-radius: 4px;
      background: #fcfcfd;
      box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.4),
        0px 4px 8px -3px rgba(45, 35, 66, 0.3), 0px -3px 0px 0px #d6d6e7 inset;
      img.action-icon {
        display: flex;
        width: 64px;
        height: 64px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
      }
    }
  }
`;

const ContainerNoteV2 = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
`;
