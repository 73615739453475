import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { APPOINTMENT_LAYOUT, AllAppointmentStatus, CALENDAR_DISTANCE_TIMELINE, CALENDAR_ITEM_TYPES, CALENDAR_VIEW_TYPE, NAME_REDUCER, PROLONGED_TIME_STATUS } from './constants';
import { IState } from './types/reducer';

import authActions from 'features/auth/services/actions';
import _, { find, remove } from 'lodash';
import moment from 'moment';
import shopActions from 'services/shop/actions';
import { IServiceItemData } from 'services/shop/types/categories';
import { IStaffItemData } from 'services/shop/types/staff';
import { momentTimezone } from 'utils/time';
import uuid from 'utils/uuid';
import { DATE_FORMAT } from '../utils/format';
import getRangeDates from '../utils/getRangeDates';
import { IAppointmentServiceItem } from './types/appointment';

const initialState: IState = {
  params: {
    startTime: moment().format(DATE_FORMAT),
    endTime: moment().format(DATE_FORMAT),
  },
  appointmentList: [],
  dateRangeList: [],
  distanceTimeline: CALENDAR_DISTANCE_TIMELINE.QUARTER_HOUR,
  viewType: CALENDAR_VIEW_TYPE.DAY_VIEW,
  lockBreakTimes: [],
  lockBreakTimesForQuickBooking: [],
  appointmentLayout: APPOINTMENT_LAYOUT.CALENDAR,
  selectedCalendarItemTypes: Object.values(CALENDAR_ITEM_TYPES),
  appointmentStatus: AllAppointmentStatus,
  staffListPage: 1,
  staffTotalPage: 1,
  staffLimit: 8,
  staffs: [],
  availableStaffs: [],
  detail: {
    data: null,
    services: [],
  },
  multipleDetail: null,
  newAppointmentDraftData: null,
  newBlockHourDraftData: null,
  newBreakTimeDraftData: null,
  draftAppointmentData: null,
  table: {
    params: {
      startTime: moment().format(DATE_FORMAT),
      endTime: moment().format(DATE_FORMAT),
      page: 1,
      size: 20,
    },
    totalElements: 0,
    data: [],
    pagination: null,
  },
  newAptState: {
    services: []
  },
  anybodyBox: {
    open: false,
    data: [],
  },
  listStaffsOff: [],
  syncTimeItem: moment().format('MM-DD-YYYY HH:mm:ss'),
  staffCategories: [],
  staffCategoriesLoading: false,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {
    setDraftMultiAppointmentsDetail: (state, { payload }: { payload: IState['multipleDetail'] }) => {
      state.multipleDetail = payload;
    },
    syncTimeItem: (state, { payload }: { payload: string }) => {
      state.syncTimeItem = payload;
    },
    syncStaffPage: (state, { payload: staffId }: { payload: string }) => {
      const limit = state.staffLimit;
      const length = state.staffs.length;
      const totalPage = length ? Math.ceil(length / limit) : 1;
      const staffs = Array.from(Array(totalPage).keys()).map(page => {
        return _(state.staffs)
          .drop(page * limit)
          .take(limit)
          .value().map(s => s.id);
      });
      const activePage = staffs.findIndex(o => {
        return !!o.find(s => s === staffId);
      });
      state.staffListPage = activePage === -1 ? 1 : (activePage + 1);
    },
    detailChangeStaff: (state, { payload }: { payload: { id: string, staff: IStaffItemData } }) => {
      const item = find(state.detail.services, o => o.uuid_local === payload.id);
      if (!item) return;
      item.staffId = payload.staff.id;
    },
    detailRemoveService: (state, { payload }: { payload: IAppointmentServiceItem }) => {
      remove(state.detail.services, o => o.uuid_local === payload.uuid_local);
    },
    detailAddNewService: (state, { payload }: { payload: { staffId: string, data: IServiceItemData } }) => {
      const newItem: IAppointmentServiceItem = {
        appointmentDetailId: '',
        duration: payload.data.duration,
        price: payload.data.priceSell || 0,
        serviceId: payload.data.id,
        serviceName: payload.data.serviceName,
        staffId: payload.staffId,
        uuid_local: uuid(),
      };
      state.detail.services.push(newItem);
    }
  },
  extraReducers: builder => {
    builder
      .addCase(actions.getServicesByStaff.fetch, (state) => {
        state.staffCategories = [];
        state.staffCategoriesLoading = true;
      })
      .addCase(actions.getServicesByStaff.success, (state, { payload }) => {
        state.staffCategories = payload;
        state.staffCategoriesLoading = false;
      })
      .addCase(actions.getServicesByStaff.fail, (state) => {
        state.staffCategories = [];
        state.staffCategoriesLoading = false;
      })

      .addCase(actions.getListStaffsOff.fetch, (state) => {
        state.listStaffsOff = [];
      })
      .addCase(actions.getListStaffsOff.success, (state, { payload }) => {
        state.listStaffsOff = payload;
      })
      .addCase(actions.getListStaffAvailableBooking.success, (state, { payload }) => {
        state.availableStaffs = payload;
      })
      .addCase(actions.anybodyBox.setAnybodyAppointmentList, (state, { payload }) => {
        state.anybodyBox.data = payload;
      })
      .addCase(actions.anybodyBox.setOpen, (state, { payload }) => {
        state.anybodyBox.open = payload;
      })
      .addCase(actions.newApt.reset, (state) => {
        state.newAptState = {
          services: []
        };
      })
      .addCase(actions.newApt.removeService, (state, { payload }) => {
        const idx = state.newAptState.services.findIndex(o => o.id === payload.id);
        if (idx !== -1) state.newAptState.services.splice(idx, 1);
      })
      .addCase(actions.newApt.addService, (state, { payload }) => {
        state.newAptState.services.push(payload);
      })
      .addCase(actions.setStaffLimit, (state, { payload }) => {
        state.staffLimit = payload;
        const length = state.staffs?.length || 0;
        state.staffTotalPage = length ? Math.ceil(length / state.staffLimit) : 1;
      })
      .addCase(actions.setAppointmentStatusFilter, (state, { payload }) => {
        state.appointmentStatus = payload;
      })
      .addCase(shopActions.get.staffs.success, (state, { payload }) => {
        const length = payload?.length || 0;
        state.staffs = payload;

        state.staffTotalPage = length ? Math.ceil(length / state.staffLimit) : 1;
      })
      .addCase(actions.addMoreTimeAppointment, (state, { payload }) => {
        state.appointmentList = state.appointmentList.map(o => {
          if (o.appointmentId === payload.appointmentId) {
            const endTime = momentTimezone(o.endTime);
            if (payload.status === PROLONGED_TIME_STATUS.ADD) {
              endTime.add(15, 'minutes');
            } else {
              endTime.subtract(15, 'minutes');
            }
            return ({
              ...o,
              endTime: endTime.format('MM-DD-YYYY HH:mm:ss')
            });
          }
          return o;
        });
      })
      .addCase(actions.updateAppointmentWithDrop, (state, { payload }) => {
        state.appointmentList = state.appointmentList.map(o => {
          if (o.appointmentId === payload.appointmentId) {
            const endTime = momentTimezone(payload.startTime).add(payload.distance, 'minutes');
            return ({
              ...o,
              staffId: payload.staffId || o.staffId,
              startTime: payload.startTime,
              endTime: endTime.format('MM-DD-YYYY HH:mm:ss')
            });
          }
          return o;
        });
      })
      .addCase(actions.setDraftAppointmentDetail, (state, { payload }) => {
        state.draftAppointmentData = payload;
      })
      .addCase(actions.setNewBreakTimeDraftData, (state, { payload }) => {
        state.newBreakTimeDraftData = payload;
      })
      .addCase(actions.setNewBlockHourDraftData, (state, { payload }) => {
        state.newBlockHourDraftData = payload;
      })
      .addCase(actions.setNewAppointmentDraftData, (state, { payload }) => {
        state.newAppointmentDraftData = payload;
      })
      .addCase(actions.increaseStaffPage, (state) => {
        const nextPage = state.staffListPage + 1;
        if (nextPage > state.staffTotalPage) return;
        state.staffListPage = nextPage;
      })
      .addCase(actions.decreaseStaffPage, (state) => {
        const nextPage = state.staffListPage - 1;
        if (nextPage > 0) {
          state.staffListPage = nextPage;
        }
      })
      .addCase(actions.setAppointmentDetail, (state, { payload }) => {
        state.detail.data = payload;
        state.detail.services = payload.services.map(o => ({
          ...o,
          uuid_local: uuid(),
          staffId: payload?.staffId,
        }));
      })
      .addCase(actions.setCalendarViewType, (state, { payload }) => {
        state.viewType = payload;
      })
      .addCase(actions.setSelectedCalendarItemTypes, (state, { payload }) => {
        state.selectedCalendarItemTypes = payload;
      })
      .addCase(actions.setAppointmentLayout, (state, { payload }) => {
        state.appointmentLayout = payload;
      })
      .addCase(actions.getLockBreakTimes.success, (state, { payload }) => {
        state.lockBreakTimes = payload;
      })
      .addCase(actions.getLockBreakTimesForQuickBooking.success, (state, { payload }) => {
        state.lockBreakTimesForQuickBooking = payload;
      })
      .addCase(actions.getLockBreakTimesForQuickBooking.fail, (state) => {
        state.lockBreakTimesForQuickBooking = [];
      })
      .addCase(actions.getAppointments.success, (state, { payload }) => {
        state.appointmentList = payload;
      })
      .addCase(actions.setParams, (state, { payload }) => {
        const _params = {
          ...state.params ?? {},
          ...payload ?? {},
        };
        state.params = _params;
        state.dateRangeList = getRangeDates(_params?.startTime, _params?.endTime, DATE_FORMAT);
        // state.appointmentList = [];
        // state.lockBreakTimes = [];
      })
      .addCase(actions.setTableParams, (state, { payload }) => {
        const _params = {
          ...state.table.params ?? {},
          ...payload ?? {},
        };
        if (_params.staffId === undefined) delete _params.staffId;
        state.table.params = _params;
      })
      .addCase(actions.getTableAppointments.success, (state, { payload }) => {
        state.table.data = payload.content;
        state.table.totalElements = payload.totalElements;
      })
      .addCase(authActions.ownerLogout, (state) => {
        state.params = {
          startTime: moment().format(DATE_FORMAT),
          endTime: moment().format(DATE_FORMAT),
        };
        state.appointmentList = [];
        state.dateRangeList = [];
        state.distanceTimeline = CALENDAR_DISTANCE_TIMELINE.QUARTER_HOUR;
        state.viewType = CALENDAR_VIEW_TYPE.DAY_VIEW;
        state.lockBreakTimes = [];
        state.appointmentLayout = APPOINTMENT_LAYOUT.CALENDAR;
        state.selectedCalendarItemTypes = Object.values(CALENDAR_ITEM_TYPES);
        state.staffListPage = 1;
        state.staffTotalPage = 1;
        state.detail = {
          data: null,
          services: [],
        };
        state.newAppointmentDraftData = null;
        state.newBlockHourDraftData = null;
        state.newBreakTimeDraftData = null;
        state.draftAppointmentData = null;
        state.table = {
          params: {
            startTime: moment().format(DATE_FORMAT),
            endTime: moment().format(DATE_FORMAT),
            page: 1,
            size: 20,
          },
          totalElements: 0,
          data: [],
          pagination: null,
        };
      })
      ;
  },
});
export const aptUIActions = Slice.actions;
const appointmentServiceReducer = Slice.reducer;
export default appointmentServiceReducer;
