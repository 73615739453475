import { Form, Input, Select } from 'antd';
import React from 'react';
import styled from 'styled-components';
import StaffAvatarForm from './StaffAvatarForm';
import InputPhone from 'components/InputPhone';
import InputDOB from 'components/InputDOB';
import { positionsArray } from 'features/settingStaff/services/constants';
import InputEmail from 'components/InputEmail';
import Text from 'components/Text';

const AddStaffInformationForm = () => {
  return (
    <Container>
      <Form.Item noStyle name={'avatar'}>
        <StaffAvatarForm />
      </Form.Item>
      <Text mt={1} />
      <Section>
        <div className="title-section">Staff Information</div>
        <GridContainer>
          <Form.Item label='First Name' name={'firstName'} rules={[{ required: true, message: 'Please enter!' }]}>
            <Input placeholder='Please enter...' />
          </Form.Item>
          <Form.Item label='Last Name' name={'lastName'} rules={[{ required: true, message: 'Please enter!' }]}>
            <Input placeholder='Please enter...' />
          </Form.Item>
          <Form.Item label='Phone' name={'phone'} rules={[{ required: true, message: 'Please enter!' }]}>
            <InputPhone placeholder='Please enter...' />
          </Form.Item>
          <Form.Item label='DOB' name={'dob'}>
            <InputDOB className='InputDOB' placeholder='Please enter...' />
          </Form.Item>
          <Form.Item label='Position' name={'staffType'}>
            <Select placeholder='Please select...' >
              {positionsArray.map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <InputEmail label='Email' name={'email'} />
        </GridContainer>
      </Section>
      <Section>
        <div className="title-section">Staff Address</div>
        <GridContainer>
          <Form.Item label='Street' name={'street'}>
            <Input placeholder='Please enter...' />
          </Form.Item>
          <Form.Item label='City' name={'city'}>
            <Input placeholder='Please enter...' />
          </Form.Item>
          <Form.Item label='State' name={'state'}>
            <Input placeholder='Please enter...' />
          </Form.Item>
          <Form.Item label='Zip Code' name={'zip'}>
            <Input placeholder='Please enter...' />
          </Form.Item>
        </GridContainer>
      </Section>
    </Container>
  );
};

export default AddStaffInformationForm;
const Container = styled.div`
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex: 1 0 0;


  .ant-form-item .ant-form-item-label label{
    color: #1D2129;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ant-input, .InputDOB {
    color: #1D2129;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-radio-wrapper {
    gap: 8px;
    .ant-radio {
      transform: scale(1.5);
    }
    span {
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .ant-form .ant-form-item {
    margin-bottom: 16px;
    .ant-input {
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    
  }
  .ant-picker .ant-picker-input input, .ant-input-number-input {
    color: #1D2129;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .InputEmailStyled .suggestion {
    .box-item {
      flex: 1;
      display: flex;
      padding: 4px 24px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      flex: 1 0 0;
      border-radius: 100px;
      border: 1px solid #4384FF;
      background: #FFF;
      .CONTENT_1 {
        color: #4384FF;
        text-align: center;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
      }
    }
  }  
  .ant-input, .ant-input-suffix {
    height: 3.5rem !important;
  }
  .ant-select {
    height: 3.5rem; 
  }
  .ant-input-number {
    height: 3.5rem !important; 
  }
  .ant-input-number-input {
    height: 3.5rem !important; 
  }
  .ant-select .ant-select-selection-item {
    height: auto;
  }
  .ant-select .ant-select-arrow {
    color: #1D2129;
    font-size: 18px;
    transform: scaleX(1.1) scaleY(0.9);
  }
  .ant-select .ant-select-selector {
    background: #ffffff;
    border: 1px solid #86909C;
    .ant-select-selection-item-content {
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .ant-select .ant-select-selector {
    background: #ffffff;
    border: 1px solid #86909C;
    .ant-select-selection-placeholder, .ant-select-selection-search-input, .ant-select-selection-item {
      color: rgb(29, 33, 41);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .ant-input-affix-wrapper {
    background: #ffffff;
    border: 1px solid #86909C;
    .ant-input {
      background: transparent;
      color: rgb(29, 33, 41);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

`;
const Section = styled.div`
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 8px;
  .title-section {
    color: #1D2129;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    align-self: stretch;
  }
 `;
const GridContainer = styled.div`
  align-self: stretch;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
`;