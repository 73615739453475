import { Form } from 'antd';
import Modal from 'components/Modal';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import PrintCheck from '.';
type Props = {};
type Ref = {
  open: () => void;
};
export const useModalSettingPrintCheckRef = () => useRef<Ref>(null);
const ModalSettingPrintCheck = forwardRef<Ref, Props>(({ }, ref) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const onOpen: Ref['open'] = () => setOpen(true);

  useImperativeHandle(ref, () => ({
    open: onOpen
  }));

  if (!open) return null;

  return (
    <Modal
      v2 modalTitle='Setting signature'
      visible={open} onClose={onClose} width={'1400px'}
      containerPadding={0}
      okTitle="OK"
      noneBodyStyle
      onSubmit={() => form.submit()}
      className='modal-overflow-unset modal-max-height-unset modal-non-opacity'
    >
      <PrintCheck form={form} modal onClose={onClose} />
    </Modal>
  );
});
ModalSettingPrintCheck.displayName = 'ModalSettingPrintCheck';
export default ModalSettingPrintCheck;