import Button from 'components/Button';
import ticketListSelectors from 'features/ticketList/services/selectors';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import ReAdjustTipModal, { useReAdjustTipModalRef } from './ReAdjustTipModal';
import ticketListActions from 'features/ticketList/services/actions';
import { useAppDispatch } from 'store/hooks';
type Props = {
  v2?: boolean;
  hook?: boolean;
};
type Ref = {
  open: () => void;
};
export const useReAdjustTipRef = () => useRef<Ref>(null);
const ReAdjustTip = forwardRef<Ref, Props>(({ v2, hook }, ref) => {
  const dispatch = useAppDispatch();
  const details = ticketListSelectors.ticketDetailsData.data();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const onCompleted = () => {
    dispatch(ticketListActions.getTicketDetails.fetch(details?.billId || ''));
  };

  const modalRef = useReAdjustTipModalRef();

  const onOpen = () => {
    modalRef.current?.open(details);
    setOpen(true);
  };

  useImperativeHandle(ref, () => ({
    open: onOpen,
  }));

  if (details?.items?.length <= 1) return null;

  return (
    <>
      {!hook && <Button
        ntype='YELLOW'
        icon='refundMoney'
        onClick={onOpen}
      >Re-Adjust tip</Button>}
      <ReAdjustTipModal v2={v2} ref={modalRef} visible={open} onClose={handleClose} onCompleted={onCompleted} />
    </>
  );
});
ReAdjustTip.displayName = 'ReAdjustTip';
export default ReAdjustTip;
