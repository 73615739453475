import { Col, Form, Input, Row } from 'antd';
import { FormItem, useFormInstance } from 'components/Form';
import { CSSProperties, useRef } from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';
import styled from 'styled-components';
import spellingDollar from 'utils/spellingDollar';
import checkImage from './checkBorder.png';
import { formatCurrency } from 'utils/formatCurrency';

function CheckUI({ isCashier = false, onSubmit = () => undefined }: { isCashier?: boolean, onSubmit?: () => void }) {
  const form = useFormInstance();
  const signRef = useRef<ReactSignatureCanvas>(null);

  const handleClear = () => {
    form.setFieldValue('signImg', '');
    signRef.current?.clear();
  };
  const handleAgree = () => {
    const val = signRef.current?.toDataURL();
    form.setFieldValue('signImg', val ?? '');
    onSubmit();
  };

  const name = Form.useWatch('name', form);
  const address = Form.useWatch('address', form);
  const phone = Form.useWatch('phone', form);
  const amount = Form.useWatch('amount', form);
  const bankNum = Form.useWatch('bankNum', form);

  const BankNum = () => {
    const [start = '', middle = '', end = ''] = bankNum?.split(',') || [];
    const _start = (start === 'undefined' || start === 'null') ? '' : start;
    const _middle = (middle === 'undefined' || middle === 'null') ? '' : middle;
    const _end = (end === 'undefined' || end === 'null') ? '' : end;

    return (
      <NormalLabel className='code'>
        <IconStartNumber />{_start || '---'}<IconEnd4Number /> <IconStartNumber />{_middle || '---'}<IconStartNumber /> {' '}{_end || '---'}<IconEnd4Number />
        <FormItem noStyle name={'bankNum'} />
      </NormalLabel>
    );
  };

  return (
    <BoxCheckUI>
      <RowFlex gap={2} mb={1.875}>
        <CustomerInfo>
          <FormItem noStyle name={'routingNumber'} />
          <FormItem noStyle name={'accountNumber'} />
          <FormItem noStyle name={'name'} >
            <p className="name">{name}</p>
          </FormItem>
          <FormItem noStyle name={'address'} >
            <p >{address}</p>
          </FormItem>
          <FormItem noStyle name={'phone'} >
            <p >{phone}</p>
          </FormItem>
        </CustomerInfo>
        <Col flex={'auto'} />
        <ColFlex justifyContent={'end'}>
          <FormItem noStyle name={'dlInformation'}>
            <InputLabelWrap bold >
              <p className="label">DL#</p>
              <FormItem noStyle name={'dlInformation'}>
                <Input />
              </FormItem>
            </InputLabelWrap>
          </FormItem>
        </ColFlex>
        <ColFlex span={6} justifyContent='space-between'>
          <InputLabelWrap>
            <p className="label">No.</p>
            <FormItem noStyle name={'no'}>
              <Input className='disabled' />
            </FormItem>
          </InputLabelWrap>
          <InputLabelWrap>
            <p className="label">DATE</p>
            <FormItem noStyle name={'currentDate'}>
              <Input className='disabled' />
            </FormItem>
          </InputLabelWrap>
        </ColFlex>
      </RowFlex>

      <RowFlex align={'bottom'} gap={3.125} px={3.125}>
        <ColFlex flex={'auto'}>
          <InputLabelWrap alignItems='center'>
            <p className="label">PAY TO THE ORDER OF</p>
            <FormItem noStyle name={'companyName'}>
              <Input />
            </FormItem>
          </InputLabelWrap>
        </ColFlex>
        <ColFlex>
          <DollarBox>
            <p className="label">$</p>
            <FormItem noStyle shouldUpdate>
              {() => {
                return (
                  <Input className='disabled' value={formatCurrency(form.getFieldValue('amount')).replace('$', '')} />
                );
              }}
            </FormItem>
            <FormItem name={'amount'} noStyle />
          </DollarBox>
        </ColFlex>
      </RowFlex>

      <RowFlex px={2} gap={3.5} align={'bottom'}>
        <ColFlex flex={'auto'}>
          <InputLabelWrap mb={0.15}>
            <Input value={spellingDollar(+amount) || ''} />
          </InputLabelWrap>
        </ColFlex>
        <ColFlex w={14}>
          <RowFlex align={'bottom'} justify={'space-between'}>
            <NormalLabel>Dollars</NormalLabel>
            <IconLocked />
          </RowFlex>
        </ColFlex>
      </RowFlex>
      <Col flex={'auto'} />
      <RowFlex pl={2} mb={1.875}>
        <ColFlex justifyContent='end' flex={'auto'} gap={1.5}>
          <InputLabelWrap isBank>
            <FormItem noStyle name={'bankName'}>
              <Input />
            </FormItem>
          </InputLabelWrap>
          <InputLabelWrap>
            <p className="label">FOR</p>
            <FormItem noStyle name={'bankFor'}>
              <Input />
            </FormItem>
          </InputLabelWrap>
        </ColFlex>
        <ColFlex span={2} />
        <ColFlex justifyContent='end'>
          <FormItem noStyle shouldUpdate>
            {() => {
              const _signImg = form.getFieldValue('signImg');
              return (
                <Form.Item noStyle name="signImg">
                  <SignatureBox>
                    <Row align={'bottom'}>
                      {!isCashier ? <button type='button' onClick={handleClear}><IconClear /></button> : <div style={{ width: 19 }} />}
                      {isCashier ? <>
                        <div className="signBox">
                          {_signImg ? <img height={200} width={400} src={_signImg} alt="Signature" /> : <div style={{ width: 400 }} />}
                        </div>
                        <Row style={{ opacity: 0, pointerEvents: 'none' }}>
                          <button type='button' onClick={handleAgree}><IconApproval /></button>
                        </Row>
                      </> : <>
                        <div className="signBox">
                          {
                            !_signImg ?
                              <ReactSignatureCanvas ref={signRef}
                                canvasProps={{
                                  className: 'sigCanvas',
                                  height: 200,
                                  width: 400,
                                }} />
                              :
                              <img
                                height={200}
                                width={400}
                                src={_signImg}
                                alt="Signature" />
                          }
                        </div>
                        <Row style={{ opacity: !_signImg ? 1 : 0, pointerEvents: !_signImg ? 'unset' : 'none' }}>
                          <button type='button' onClick={handleAgree}><IconApproval /></button>
                        </Row>
                      </>}

                    </Row>
                  </SignatureBox>
                </Form.Item>
              );
            }}
          </FormItem>
        </ColFlex>
      </RowFlex>

      <RowFlex pl={2} justify={'space-between'} align={'middle'}>
        <BankNum />
        <NormalLabel className='sub'>* There Will Be A $25 Service Charge On All Returned Checks</NormalLabel>
      </RowFlex>
    </BoxCheckUI>
  );
}

export default CheckUI;


const BoxCheckUI = styled.div`
  max-height: 37rem;
  height: 90vh;
  border: 20px solid transparent;
  padding: 15px;
  border-top-width: 20px;
  border-bottom-width: 20px;
  border-left-width: 25px;
  border-right-width: 25px;
  border-image: url(${checkImage}) 30 round;
  display: flex;
  flex-direction: column;
`;

const RowFlex = styled(Row) <{ gap?: number, mb?: number, px?: number, pl?: number }>`
  ${({ gap }) => gap && `gap: ${gap}rem;`}
  ${({ mb }) => mb && `margin-bottom: ${mb}rem;`}
  ${({ px }) => px && `
    padding-right: ${px}rem;
    padding-left: ${px}rem;
  `}
  ${({ pl }) => pl && `padding-left: ${pl}rem;`}
`;

const ColFlex = styled(Col) <{ gap?: number, w?: number, justifyContent?: CSSProperties['justifyContent'] }>`
  display: flex;
  flex-direction: column;
  ${({ justifyContent }) => justifyContent && `
    justify-content: ${justifyContent};
  `}
  ${({ w }) => w && `width: ${w}rem;`}
  ${({ gap }) => gap && `gap: ${gap}rem;`}
`;

const SignatureBox = styled.div`
border-bottom: 1.5px solid #86909C;
.signBox {
  transform: translateY(15px);
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}
canvas {}
`;

const NormalLabel = styled.p`
  color: var(--text-text-3, #1D2129);
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */

  &.sub {
    color: var(--text-text-3, #1D2129);
    text-align: center;
    font-family: Open Sans;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &.code {
    font-family: 'ocr-a-regular', sans-serif;
    color: var(--text-text-3, #1D2129);
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
  }
`;
const DollarBox = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 10px;
  .label {
    color: var(--text-text-3, #1D2129);
    font-family: Open Sans;
    font-size: 3.125rem;;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  input, input.ant-input-disabled {
    color: var(--info-infor-3, #6FABB6);
    font-family: Open Sans;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    padding: 0.625rem 0;
    width: 17.625rem;
    background: none;
    border: 3px solid #86909C;
    border-radius: 0;
    height: auto;
    padding-left: 0;
    &.ant-input:focus, &:hover  {
      border-color: #86909C;
      box-shadow: unset;
      border-inline-end-width: 3px;
      outline: 0;
    }
    color: var(--info-infor-3, #6FABB6);
    pointer-events: none;
  }
  input.ant-input-disabled {
    cursor: default;
  }
`;
const InputLabelWrap = styled.div<{
  bold?: boolean,
  alignItems?: CSSProperties['alignItems'],
  mb?: number,
  isBank?: boolean;
}>`
  display: flex;
  gap: 1rem;
  align-items: ${({ alignItems }) => alignItems ? alignItems : 'flex-end'};
  ${({ mb }) => mb && `margin-bottom: ${mb}rem;`}

  .label {
    display: flex;
    margin-bottom: 5px;
    max-width: 9rem;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  
   ${({ bold }) => bold ? `
    color: var(--text-text-3, #1D2129);
    font-family: Open Sans;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
   `: `
    color: var(--text-text-3, #1D2129);
    font-family: Open Sans;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
   `}
  }
  input, input.ant-input-disabled {
    color: var(--info-infor-3, #6FABB6);
    font-family: Open Sans;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: none;
    border: none;
    border-bottom: 1.5px solid #86909C;
    border-radius: 0;
    padding-left: 0;
    &.ant-input:focus, &:hover  {
      border-color: #86909C;
      box-shadow: unset;
      border-inline-end-width: 1.5px;
      outline: 0;
    }
    color: var(--info-infor-3, #6FABB6);
    pointer-events: none;
    input.ant-input-disabled {
      cursor: default;
    }
  }
  ${({ isBank }) => isBank && `
      input {
        color: #2178DD;
        font-family: Open Sans;
        font-size: 2.1875rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
  `}
`;

const CustomerInfo = styled(Col)`
  width: 15rem;
  color: var(--text-text-3, #1D2129);
  font-family: Open Sans;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  .name {
    color: var(--text-text-3, #1D2129);
    font-family: Roboto;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
 
`;

const IconStartNumber = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19" fill="none">
    <path d="M2.76712 3.22266H0.63214C0.286251 3.22266 0 3.50891 0 3.8548V14.8994C0 15.2453 0.286251 15.5315 0.63214 15.5315H2.76712C3.11301 15.5315 3.39926 15.2453 3.39926 14.8994V3.8548C3.38733 3.50891 3.11301 3.22266 2.76712 3.22266Z" fill="#1D2129" />
    <path d="M10.9969 0.742188H7.28754C6.94165 0.742188 6.6554 1.02844 6.6554 1.37433V5.08367C6.6554 5.42956 6.94165 5.71581 7.28754 5.71581H10.9969C11.3428 5.71581 11.629 5.42956 11.629 5.08367V1.37433C11.629 1.01651 11.3428 0.742188 10.9969 0.742188Z" fill="#1D2129" />
    <path d="M10.9969 13.04H7.28754C6.94165 13.04 6.6554 13.3263 6.6554 13.6722V17.3815C6.6554 17.7274 6.94165 18.0137 7.28754 18.0137H10.9969C11.3428 18.0137 11.629 17.7274 11.629 17.3815V13.6722C11.629 13.3263 11.3428 13.04 10.9969 13.04Z" fill="#1D2129" />
  </svg>
);

const IconEnd4Number = () => (
  <svg style={{ marginLeft: '2px' }} xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 22 21" fill="none">
    <path d="M1.8487 5.22168H0.632104C0.286215 5.22168 0 5.50793 0 5.85382V17.2204C0 17.5663 0.286215 17.8526 0.632104 17.8526H1.8487C2.19459 17.8526 2.48081 17.5663 2.48081 17.2204V5.85382C2.48081 5.50793 2.19459 5.22168 1.8487 5.22168Z" fill="#1D2129" />
    <path d="M6.79854 5.22168H5.58194C5.23606 5.22168 4.94977 5.50793 4.94977 5.85382V17.2204C4.94977 17.5663 5.23606 17.8526 5.58194 17.8526H6.79854C7.14443 17.8526 7.43065 17.5663 7.43065 17.2204V5.85382C7.43065 5.50793 7.14443 5.22168 6.79854 5.22168Z" fill="#1D2129" />
    <path d="M15.565 2.89648H10.5316C10.1858 2.89648 9.89954 3.18274 9.89954 3.52862V10.2675C9.89954 10.6134 10.1858 10.8996 10.5316 10.8996H15.565C15.9108 10.8996 16.1971 10.6134 16.1971 10.2675V3.52862C16.1971 3.18274 15.9108 2.89648 15.565 2.89648Z" fill="#1D2129" />
  </svg>
);

const IconLocked = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="42" viewBox="0 0 33 42" fill="none">
    <path d="M29.5851 41.0577C31.2908 41.0577 32.6513 39.6769 32.6513 37.9916V19.4933C32.6513 17.8485 31.3517 16.5084 29.707 16.4271C29.707 16.4271 29.707 16.4272 29.707 16.4068V13.3813C29.707 6.01042 23.6965 0 16.3256 0C8.93437 0 2.94432 6.01042 2.94432 13.3813V16.4068V16.4271C1.31988 16.5084 0 17.8282 0 19.4933V37.9916C0 39.6972 1.38079 41.0577 3.06615 41.0577H29.5851ZM18.3156 29.179L18.9044 35.0879C18.9247 35.4331 18.8029 35.7783 18.5593 36.0423C18.3156 36.2859 17.9907 36.4484 17.6455 36.4484H15.3916C15.0464 36.4484 14.7012 36.3062 14.4778 36.0423C14.2342 35.7783 14.1124 35.4534 14.1327 35.0879L14.7012 29.3211C12.9752 28.6714 11.7365 26.986 11.7365 25.0164C11.7365 22.4782 13.7874 20.4273 16.3459 20.4273C18.8841 20.4273 20.935 22.4985 20.935 25.0164C20.935 26.8642 19.8588 28.4277 18.3156 29.179ZM8.26435 13.3813C8.26435 8.93441 11.8787 5.29974 16.3459 5.29974C20.7928 5.29974 24.4274 8.91411 24.4274 13.3813V16.4068H8.28468V13.3813H8.26435Z" fill="#1D2129" />
  </svg>
);

const IconClear = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M40.365 7.63454C41.2688 8.53835 41.2688 10.0037 40.365 10.9075L10.9082 40.3643C10.0044 41.2681 8.53902 41.2681 7.63521 40.3643C6.7314 39.4605 6.7314 37.9952 7.63521 37.0914L37.092 7.63454C37.9958 6.73073 39.4612 6.73073 40.365 7.63454Z" fill="#FF8890" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.63499 7.63454C8.5388 6.73073 10.0042 6.73073 10.908 7.63454L40.3648 37.0914C41.2686 37.9952 41.2686 39.4605 40.3648 40.3643C39.461 41.2681 37.9956 41.2681 37.0918 40.3643L7.63499 10.9075C6.73119 10.0037 6.73119 8.53835 7.63499 7.63454Z" fill="#FF8890" />
  </svg>
);

const IconApproval = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="43" viewBox="0 0 44 43" fill="none">
    <path d="M42.3304 0.761718C42.8604 0.48886 43.4961 0.627432 43.8604 1.10029C41.9233 3.04743 39.8519 4.85743 38.0133 6.90315C33.9504 11.116 30.349 15.7717 27.2547 20.7374C26.3204 22.5232 25.1276 24.156 24.1818 25.9346C23.0847 27.7746 21.9504 29.5917 20.8233 31.4132C20.6518 31.816 20.1633 31.686 19.8218 31.6674C19.3976 31.126 19.109 30.4974 18.769 29.9046C18.0776 28.4546 17.319 27.0303 16.7918 25.5103C16.319 24.0874 15.6118 22.7589 14.9276 21.4303C14.4204 20.4503 13.7376 19.5217 12.789 18.9303C12.1933 18.5446 11.4576 18.646 10.7861 18.6503C10.9576 17.4189 11.9704 16.5031 13.0718 16.0489C13.8333 15.6903 14.7033 15.7346 15.519 15.8146C17.0876 16.1874 18.259 17.4203 19.1818 18.6717C19.6947 19.4017 20.1976 20.1432 20.6061 20.9374C20.6776 20.9374 20.8204 20.9403 20.8904 20.9403C22.0633 19.1417 23.4233 17.4732 24.6961 15.7446C25.9933 14.0889 27.359 12.4874 28.7804 10.9374C30.0418 9.63315 31.2204 8.24172 32.599 7.05457C35.4133 4.37315 38.7647 2.28743 42.3304 0.761718Z" fill="#6FABB6" />
    <path d="M5.68233 7.84996C9.59804 3.74282 15.3095 1.43853 20.9752 1.64568C25.0438 1.81853 29.068 3.21996 32.3266 5.66996C32.4723 5.90425 32.1395 6.01282 32.018 6.16282C31.4238 6.60568 30.9809 7.22425 30.3952 7.6671C30.0823 7.54568 29.8223 7.32282 29.5352 7.15425C25.1209 4.32711 19.4023 3.67425 14.4695 5.44853C10.5009 6.82139 7.10661 9.70996 5.0309 13.3485C3.12947 16.7114 2.34947 20.72 2.96804 24.54C3.52518 28.36 5.44233 31.9442 8.22947 34.6042C11.4409 37.6328 15.8266 39.4714 20.2623 39.3714C24.3209 39.4557 28.3452 37.9042 31.4452 35.3114C34.5023 32.7542 36.6538 29.1271 37.3866 25.2057C37.748 23.6042 37.6523 21.95 37.6195 20.3214C37.3809 17.4128 36.3109 14.6171 34.7466 12.1685C35.2695 11.3757 35.9495 10.7028 36.5823 9.99853C38.4552 12.5285 39.7409 15.4971 40.2252 18.6114C41.1052 23.7185 39.8938 29.1342 36.9438 33.3942C34.558 36.8714 31.0666 39.5842 27.0952 41.0114C22.5366 42.6785 17.3809 42.5971 12.8652 40.8242C10.3966 39.82 8.0709 38.4 6.18518 36.5042C3.48661 33.9557 1.55518 30.6157 0.61804 27.03C-0.0419596 24.6428 -0.0891024 22.1385 0.089469 19.6842C0.575183 15.2657 2.56375 11.0214 5.68233 7.84996Z" fill="#6FABB6" />
  </svg>
);
