import { Col, Row } from 'antd';
import Box from 'components/Box';
import Text from 'components/Text';
import cashierSelectors from 'features/cashier/services/selectors';
import { minusValue } from 'features/report/pages/ReportPage/SalonReport/EmployeeTable/calc';
import React from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
type IComponentProps = {};
const PaymentResult: React.FC<IComponentProps> = () => {
  const paymentTicket = cashierSelectors.getTicketTotalInfo();
  const customerPackage = cashierSelectors.getCustomerPackage();
  const productTax = cashierSelectors.getProductTax();

  return (
    <ComponentStyled>
      <Row gutter={16}>
        <Col span={12}>
        </Col>
        <Col span={12}>
          <SpaceBetweenStyled>
            <Text variant="H8" style={{ fontWeight: 'bolder' }}>Balance:</Text>
            <Text variant="H7" style={{ fontWeight: 'bolder' }}>{formatCurrency(paymentTicket?.subtotal)}</Text>
          </SpaceBetweenStyled>
        </Col>
      </Row>
      <Box bb="print_line" highlight>
      </Box>
      <Row gutter={16}>
        <Col span={12}>
          <SpaceBetweenStyled>
            <Text variant="H8">Disc. By Item:</Text>
            <Text variant="H7">{minusValue(paymentTicket?.discByItems)}</Text>
          </SpaceBetweenStyled>
        </Col>
        <Col span={12}>
          <SpaceBetweenStyled>
            <Text variant="H8">Sale Tax:</Text>
            <Text variant="H7">{formatCurrency((paymentTicket?.saleTax || 0) + (productTax || 0))}</Text>
          </SpaceBetweenStyled>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <SpaceBetweenStyled>
            <Text variant="H8">Disc. By Ticket:</Text>
            <Text variant="H7">{minusValue(paymentTicket?.discByTicket)}</Text>
          </SpaceBetweenStyled>
        </Col>
        <Col span={12}>
          <SpaceBetweenStyled>
            <Text variant="H8">Used Tax:</Text>
            <Text variant="H7">{formatCurrency(paymentTicket?.usedTax)}</Text>
          </SpaceBetweenStyled>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <SpaceBetweenStyled>
            <Text variant="H8" style={{ fontWeight: 'bolder' }}>Subtotal:</Text>
            <Text variant="H7" style={{ fontWeight: 'bolder' }}>{formatCurrency(paymentTicket?.subtotal - (paymentTicket?.discByTicket + paymentTicket?.discByItems))}</Text>
          </SpaceBetweenStyled>
        </Col>
        <Col span={12}>
          <SpaceBetweenStyled>
            <Text variant="H8">Tip:</Text>
            <Text variant="H7">{formatCurrency(paymentTicket?.tipTicket)}</Text>
          </SpaceBetweenStyled>
        </Col>
      </Row>
      {customerPackage && <RowVIPStyled justify={'space-between'}>
        <Text variant="H6" color="text_3" pb={0.25} mr={1.5} style={{ fontWeight: 'bolder' }}>
          VIP Member:
        </Text>
        <Text variant="H6" color="text_3" pb={0.25} style={{ fontWeight: 'bolder' }}>
          {customerPackage?.discountValue}% discount
        </Text>
      </RowVIPStyled>}
    </ComponentStyled>
  );
};

export default PaymentResult;
const ComponentStyled = styled.div`
  background: #E5E5E5;
  margin: 1rem 1.5rem;
  padding: 0.25rem 0.5rem;
`;
const SpaceBetweenStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const RowVIPStyled = styled(Row)`
  background: #f6f7fc;
  flex:1;
  padding: 4px;
  margin-top: 4px;
`;