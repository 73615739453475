import Box from 'components/Box';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import ticketListSelectors from 'features/ticketList/services/selectors';
import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import FakeVisa from '../FakeVisa';
import { momentTimezone } from 'utils/time';
import { InfoCircleOutlined } from '@ant-design/icons';
import { IPaymentMultipleCardItem } from 'features/ticketList/services/types/api';

type ICreditCardProps = {
  credit_card?: IPaymentMultipleCardItem;
  v2?: boolean;
};
const CreditCard: React.FC<ICreditCardProps> = ({ credit_card, v2 }) => {
  const details = ticketListSelectors.ticketDetailsData.data();

  const [open, setOpen] = useState(false);
  const handleShowModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleFinish = () => {
    handleCloseModal();
  };

  return (
    <CreditCardStyled>
      {
        v2 ?
          <CreditButton style={{ gap: '4px' }} onClick={handleShowModal}><span>Credit Card</span> <span style={{ color: '#6FABB6' }}>{credit_card?.last4 || ''}</span></CreditButton>
          :
          <CreditButton style={{ gap: '4px' }} onClick={handleShowModal}>
            <InfoCircleOutlined style={{ fontSize: 20 }} />
            <p>
              Credit Card <span style={{ color: '#6FABB6' }}>{credit_card?.last4 || ''}</span>
            </p>
          </CreditButton>
      }
      {!!open && <Modal
        modalTitle="Credit Card"
        visible={open}
        destroyOnClose={true}
        hiddenFooter={true}
        noneBodyStyle={true}
        v2={v2}
        width={v2 ? 800 : undefined}
      >
        <Box style={v2 ? { zoom: 1.3, alignItems: 'center', display: 'flex', flexDirection: 'column' } : undefined} p={v2 ? '4' : undefined} px={v2 ? undefined : '11'} py={v2 ? undefined : '4'} height={v2 ? undefined : '35rem'}>
          <FakeVisa
            v2={v2}
            number={'XXXX XXXX XXXX ' + credit_card?.last4 || ''}
            cardHolderName={
              credit_card?.cardHolder ? credit_card?.cardHolder : '----'
            }
            expires={
              credit_card?.expireDate
                ? moment(credit_card?.expireDate).format('MM/YY')
                : '--/--'
            }
          />
          {v2 && <AmountTT>
            <p className="label">Payment</p>
            <p className="value">{formatCurrency(credit_card?.amount)}</p>
          </AmountTT>}
          <Box style={v2 ? { alignSelf: 'stretch' } : undefined} display="flex" flexDirection="column" gap="2" my="2">
            <Box className="space-between">
              <Text variant="CONTENT_1" color="text_3">
                Ticket Number:
              </Text>
              <Text variant="H9" color="text_3">
                {details?.ticketNumber ? '#' + details?.ticketNumber : '--'}
              </Text>
            </Box>
            <Box className="space-between">
              <Text variant="CONTENT_1" color="text_3">
                Time:
              </Text>
              <Text variant="H9" color="text_3">
                {credit_card?.createDated
                  ? momentTimezone(credit_card?.createDated).format(
                    'DD/MM/YYYY hh:mm A'
                  )
                  : '--'}
              </Text>
            </Box>
            <Box className="space-between">
              <Text variant="CONTENT_1" color="text_3">
                Card Number:
              </Text>
              <Text variant="H9" color="text_3">
                {credit_card?.cardNumber || '--'}
              </Text>
            </Box>
            <Box className="space-between">
              <Text variant="CONTENT_1" color="text_3">
                Total:
              </Text>
              <Text variant="H9" color="text_3">
                {formatCurrency(credit_card?.amount || 0)}
              </Text>
            </Box>
            <Box className="space-between">
              <Text variant="CONTENT_1" color="text_3">
                Card Type:
              </Text>
              <Text variant="H9" color="text_3">
                {credit_card?.cardType || '--'}
              </Text>
            </Box>
          </Box>
          <Button cardCashier={v2} width="100%" ntype="PRIMARY" onClick={handleFinish}>
            {v2 ? <span style={{ fontSize: 16, color: '#fff', fontWeight: '600' }}>OK</span> : 'OK'}
          </Button>
        </Box>
      </Modal>}
    </CreditCardStyled>
  );
};

export default CreditCard;
const CreditCardStyled = styled.div``;
const CreditButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
    line-height: 30px; /* 166.667% */
    text-transform: capitalize;
  }
  
  &:hover {
    color: var(--info-infor-3, #6fabb6);
    text-decoration: underline;
  }
  
`;


const AmountTT = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap: 8px;
align-self: stretch;
margin-top: 32px;
  p.label {
    color: #64748B;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  p.value {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;