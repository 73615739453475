import { Form } from 'antd';
import { PaymentSocketData, TypePaymentActors, TypePaymentPassData } from 'features/payment/pages/PaymentPage/services/types/socketPayment';
import SimpleQRModal, { useSimpleQRModalRef } from 'features/payment/pages/QRPaymentPage/SimpleQRModal';
import { useSocketContext } from 'hooks/useSocket';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import storage from 'utils/sessionStorage';
import { PAYMENT_PASS_DATA_TOPIC } from 'utils/socket';
type Props = {};
type Ref = {
  requestQRPayment: (method: string, amount: number) => void;
};
export const useQRPaymentHandlerRef = () => useRef<Ref>(null);
const QRPaymentHandler = forwardRef<Ref, Props>(({ }, ref) => {
  const masterForm = Form.useFormInstance();
  const modalRef = useSimpleQRModalRef();
  const socketContext = useSocketContext();

  const requestQRPayment: Ref['requestQRPayment'] = (method, amount) => {
    modalRef.current?.open(method, amount);
  };

  const closeCustomerQRScreen = () => {
    const billId = masterForm.getFieldValue(['detail', 'billId']);
    if (!billId) return;
    if (!socketContext.isConnect()) return;

    const body: PaymentSocketData = {
      shopId: storage.shop_id.get(),
      billId,
      actor: TypePaymentActors.CASHIER,
      action: TypePaymentPassData.QR_MULTIPLE_PAYMENT_DONE,
    };
    socketContext.send(PAYMENT_PASS_DATA_TOPIC, body);
  };

  const handleDoneQRPayment = (otherMethod: string) => {
    masterForm.setFieldValue(`isValid_${otherMethod}`, true);
    closeCustomerQRScreen();
    setTimeout(() => masterForm.submit(), 100);
  };

  useImperativeHandle(ref, () => ({ requestQRPayment }));

  return (
    <SimpleQRModal ref={modalRef} onDone={handleDoneQRPayment} onCancel={closeCustomerQRScreen} />
  );
});
QRPaymentHandler.displayName = 'QRPaymentHandler';
export default QRPaymentHandler;
