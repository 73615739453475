import * as React from 'react';
import { SVGProps } from 'react';
const IcPercent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={41}
    height={40}
    viewBox='0 0 41 40 '
    fill='none'
    {...props}
  >
    <path
      fill={props?.fill || '#1D2129'}
      fillRule='evenodd'
      d='M32.91 7.424c.732.732.732 1.92 0 2.652l-22.5 22.5a1.875 1.875 0 1 1-2.652-2.652l22.5-22.5a1.875 1.875 0 0 1 2.651 0ZM12.209 9.375a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm-6.25 2.5a6.25 6.25 0 1 1 12.5 0 6.25 6.25 0 0 1-12.5 0ZM28.459 25.625a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm-6.25 2.5a6.25 6.25 0 1 1 12.5 0 6.25 6.25 0 0 1-12.5 0Z'
      clipRule='evenodd'
    />
  </svg>
);
export default IcPercent;
