import { createAsyncAction, createAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import {
  IAdjustTip,
  IApiGetTicketsParam,
  IApiModifyTipCashBody,
  IEditStaffPayload,
  IParamSummaryCreditCards,
  IRefundBody,
} from './types/api';
import { IApiSplitTicketItem } from './types/ticket';

const getTickets = createAsyncAction<IApiGetTicketsParam>(
  PREFIX_ACTIONS + 'getTickets'
);
const setTicketsParams = createAction<IApiGetTicketsParam>(
  PREFIX_ACTIONS + 'setTicketsParam'
);
const addTip = createAction<number>(PREFIX_ACTIONS + 'addTip');
const editStaff = createAsyncAction<IEditStaffPayload>(
  PREFIX_ACTIONS + 'editStaff'
);

const getTicketDetails = createAsyncAction<string>(
  PREFIX_ACTIONS + 'getTicketDetails'
);
const refundTicketByBillId = createAsyncAction<IRefundBody>(
  PREFIX_ACTIONS + 'refundTicketByBillId'
);
const voidTicketByBillId = createAsyncAction<{ id: string; reason: string }>(
  PREFIX_ACTIONS + 'voidTicketByBillId'
);
const adjustTip = createAsyncAction<{ billId?: string }>(
  PREFIX_ACTIONS + 'adjustTip'
);
const handleChangeAdjustTip = createAction<IAdjustTip>(
  PREFIX_ACTIONS + 'handleChangeAdjustTip'
);

const setVisibleCusPhone = createAction<boolean>(
  PREFIX_ACTIONS + 'setVisibleCusPhone'
);
const editCashByBillId = createAsyncAction<{ amount: number; billId: string }>(
  PREFIX_ACTIONS + 'editCashByBillId'
);

const doPrintVoidTicket = createAsyncAction<string>(PREFIX_ACTIONS + 'doPrintVoidTicket');

const doInit = createAction(PREFIX_ACTIONS + 'doInit');

const removePendingTicket = createAction<string>(PREFIX_ACTIONS + 'removePendingTicket');
const afterDeleteClosedTicket = createAction(PREFIX_ACTIONS + 'afterDeleteClosedTicket');

const getSummaryCreditCards = createAsyncAction<IParamSummaryCreditCards>(PREFIX_ACTIONS + 'getSummaryCreditCards');

const modifyTipCash = createAction<IApiModifyTipCashBody>(PREFIX_ACTIONS + 'modifyTipCash');
const splitCloseTicket = createAction<{ billId: string, data: IApiSplitTicketItem[] }>(PREFIX_ACTIONS + 'splitCloseTicket');
const getSummaryTickets = createAsyncAction<IApiGetTicketsParam>(PREFIX_ACTIONS + 'getSummaryTickets');
const ticketListActions = {
  removePendingTicket,
  afterDeleteClosedTicket,
  doInit,
  getTickets,
  setTicketsParams,
  addTip,
  editStaff,
  getTicketDetails,
  refundTicketByBillId,
  voidTicketByBillId,
  handleChangeAdjustTip,
  adjustTip,
  setVisibleCusPhone,
  editCashByBillId,
  doPrintVoidTicket,
  getSummaryCreditCards,
  modifyTipCash,
  splitCloseTicket,
  getSummaryTickets,
};

export default ticketListActions;
