import { Form } from 'antd';
import Modal from 'components/Modal';
import { IStaffPayload } from 'features/checkIn/services/types/demoData';
import settingStaffActions from 'features/settingStaff/services/actions';
import settingApis from 'features/settingStaff/services/apis';
import { STAFF_POSITION_INFO, STAFF_STATUS } from 'features/settingStaff/services/constants';
import moment from 'moment';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { IResponseDataBody } from 'services/response';
import { IStaffItemData } from 'services/shop/types/staff';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { removeBase64Prefix } from 'utils/removeBase64Prefix';
import AddStaffInformationForm from './Information';
type Props = {
  onDone?: (staff: IStaffItemData) => void;
};
type Ref = {
  open: () => void;
};
export const useModalAddStaffRef = () => useRef<Ref>(null);

export interface IAddStaffFormValues {
  avatar: string;
  firstName: string;
  lastName: string;
  phone: string;
  dob: string;
  staffType: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  email: string;
}

const ModalAddStaff = forwardRef<Ref, Props>(({ onDone = () => undefined }, ref) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const setLoading = useSetLoadingPage();
  const onOpen: Ref['open'] = () => {
    form.resetFields();
    setOpen(true);
  };
  const onClose = () => setOpen(false);

  const handleFinish = async (values: IAddStaffFormValues) => {
    setLoading(true);
    const body: IStaffPayload = {
      avatar: values.avatar ? removeBase64Prefix(values.avatar) : '',
      staffId: '',
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      email: values.email,
      staffType: values.staffType,
      dob: values.dob ? moment(values.dob, 'MM/DD/YYYY').format('MM-DD-YYYY') : '',
      street: values.street || '',
      city: values.city || '',
      state: values.state || '',
      zip: values.zip || '',
      status: '',
    };
    try {
      const res: IResponseDataBody<IStaffItemData> = await settingApis.createOrUpdateStaff(body);
      if (res.data.data) {
        onDone(res.data.data);
        dispatch(settingStaffActions.createOrUpdateStaff.success(res.data.data));
        dispatch(settingStaffActions.getStaffList.fetch({
          page: 1,
          size: 20,
          status: STAFF_STATUS.ACTIVE,
          ignoreAnyBody: true,
        }));
        onClose();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }

  };

  useImperativeHandle(ref, () => ({
    open: onOpen
  }));

  return (
    <Modal className='modal-overflow-unset' v2 width={1400} visible={open} onClose={onClose} noneBodyStyle noneFooterStyle hiddenHeader footer={null}>
      <Container<any> onFinish={handleFinish} form={form} layout='vertical' initialValues={{ staffType: STAFF_POSITION_INFO.TECHNICAL }}>
        <div className="add-staff-title">Add Staff</div>
        <AddStaffInformationForm />
        <Footers>
          <button type='button' onClick={onClose} className="btn btn-close"><span>Close</span></button>
          <button type='submit' className="btn btn-submit"><span>Add</span></button>
        </Footers>
      </Container>
    </Modal>
  );
});
ModalAddStaff.displayName = 'ModalAddStaff';
export default ModalAddStaff;

const Container = styled(Form)`
  display: flex;
  background: #FFF;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  padding: 16px;
  padding-top: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  .add-staff-title {
    align-self: stretch;
    color: #1D2129;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
const Footers = styled.div`
display: flex;
padding: 16px;
justify-content: flex-end;
align-items: flex-start;
gap: 16px;
align-self: stretch;
background: #FFF;
margin: 0 -16px;
margin-bottom: -16px;
box-shadow: 0px -4px 8px -4px rgba(0, 0, 0, 0.15);
button.btn {
  display: flex;
  height: 56px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  background: #E5E6EB;
  border-radius: 4px;
  box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
  span {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &.btn-submit {
    background: #FF8890;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -4px 0px 0px #E7646D inset;
    span {
      color: #fff;
    }
  }

  &:disabled {
    opacity: 0.5;
  }
  &:active, &:hover {
    opacity: 0.8;
  }
}
`;