import { Form, InputRef } from 'antd';
import CurrencyInputPrecision from 'components/CurrencyInputPrecision';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PercentInput from './PercentInput';
import { PercentInputRef } from './PercentInput/helpers';

export type DiscountType = 'PERCENT' | 'MONEY';

interface DiscountValue {
  type: DiscountType;
  value: number;
}

interface DiscountInputProps {
  value?: DiscountValue;
  onChange?: (value: DiscountValue) => void;
  defaultType?: DiscountType;
  maxValue?: number;
  className?: string;
  disabled?: boolean;
}

export interface DiscountInputRef {
  focus: () => void;
  clear: () => void;
}

const DiscountInput = forwardRef<DiscountInputRef, DiscountInputProps>(
  (
    {
      value = { type: 'PERCENT', value: 0 },
      onChange,
      defaultType = 'PERCENT',
      className,
      disabled = false,
    },
    ref
  ) => {
    // Use local state if no value is provided from Form
    const [localType, setLocalType] = useState<DiscountType>(
      value?.type || defaultType
    );
    const [localValue, setLocalValue] = useState<number>(value?.value || 0);

    useEffect(() => {
      if (value?.type && value?.value !== undefined) {
        setLocalType(value.type);
        if(value.type == 'PERCENT' && value.value > 100){
            setLocalValue(100);
        }else {
            setLocalValue(value.value);
        }
      }
    }, [value]);

    const percentInputRef = useRef<PercentInputRef>(null);
    const dollarInputRef = useRef<InputRef>(null);

    const handlePercentChange = (val: number) => {
      setLocalValue(val);
      if (onChange) {
        onChange({ type: 'PERCENT', value: val });
      }
    };

    const handleDollarChange = (val: number) => {
      setLocalValue(val);
      if (onChange) {
        onChange({ type: 'MONEY', value: val });
      }
    };

    const handleTypeChange = (newType: DiscountType) => {
      if (newType === localType) return;

      setLocalType(newType);
      setLocalValue(0);

      if (onChange) {
        onChange({ type: newType, value: localValue });
      }

      setTimeout(() => {
        if (newType === 'PERCENT' && percentInputRef.current) {
          percentInputRef.current.focus();
        } else if (newType === 'MONEY' && dollarInputRef.current) {
          dollarInputRef.current.focus();
        }
      }, 50);
    };

    React.useImperativeHandle(ref, () => ({
      focus: () => {
        if (localType === 'PERCENT' && percentInputRef.current) {
          percentInputRef.current.focus();
        } else if (localType === 'MONEY' && dollarInputRef.current) {
          dollarInputRef.current.focus();
        }
      },
      clear: () => {
        if (localType === 'PERCENT' && percentInputRef.current) {
          percentInputRef.current.clear();
        }
        setLocalValue(0);
        if (onChange) {
          onChange({ type: localType, value: 0 });
        }
      },
    }));

    return (
      <DiscountInputContainer className={className}>
        <InputWrapper>
          {localType === 'PERCENT' ? (
            <PercentInput
              ref={percentInputRef}
              value={localValue}
              onChange={handlePercentChange}
              maxValue={100}
              disabled={disabled}
            />
          ) : (
            <CurrencyInputPrecision
              height={56}
              inputRef={dollarInputRef}
              value={localValue.toFixed(2)}
              onChange={handleDollarChange}
              disabled={disabled}
              className='currency-input-precision'
            />
          )}
        </InputWrapper>

        <SegmentedButtons>
          <SegmentButton
            className={localType === 'PERCENT' ? 'active' : ''}
            onClick={() => !disabled && handleTypeChange('PERCENT')}
            type='button'
            disabled={disabled}
          >
            Percent %
          </SegmentButton>

          <SegmentButton
            className={localType === 'MONEY' ? 'active' : ''}
            onClick={() => !disabled && handleTypeChange('MONEY')}
            type='button'
            disabled={disabled}
          >
            Dollar $
          </SegmentButton>
        </SegmentedButtons>
      </DiscountInputContainer>
    );
  }
);

DiscountInput.displayName = 'DiscountInput';

// Form Item wrapper
interface DiscountFormItemProps {
  name: string | number | (string | number)[];
  label?: string;
  required?: boolean;
  defaultType?: DiscountType;
  maxValue?: number;
  disabled?: boolean;
}

export const DiscountFormItem: React.FC<DiscountFormItemProps> = ({
  name,
  label,
  required = false,
  defaultType = 'PERCENT',
  maxValue,
  disabled = false,
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      required={required}
      rules={[{ required, message: 'Vui lòng nhập giá trị' }]}
    >
      <DiscountInput
        defaultType={defaultType}
        maxValue={maxValue}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default DiscountInput;

const DiscountInputContainer = styled.div`
  display: flex;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  background: white;

  .dollar-input-selector,
  .percent-input-selector {
    border-radius: 4px 0 0 4px;
    border-right: none;
  }

  &:has(input:focus) {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
`;

const InputWrapper = styled.div`
  flex: 1;
  .currency-input-precision {
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
  }
`;

const SegmentedButtons = styled.div`
  display: flex;
  align-items: stretch;
  border: 1px solid #86909c;
  ${DiscountInputContainer}:has(input:focus) & {
    border: 1px solid #ffb0b3;
  }
`;

const SegmentButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  border: none;
  background: #e5e7eb;
  cursor: pointer;
  font-size: 14px;
  min-width: 90px;

  &:hover:not(:disabled) {
    background: #d1d5db;
  }

  &.active {
    background: #2c7d7d;
    color: white;
  }

  &:first-child {
    border-right: 1px solid #d9d9d9;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;
