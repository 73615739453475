import { Form } from 'antd';
import Box from 'components/Box';
import FormItemValue from 'components/Form/FormItemValue';
import Modal from 'components/Modal';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { IResponseDataBody } from 'services/response';
import { IStaffAvailableItem } from 'services/shop/types/staff';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import styled from 'styled-components';
import toast from 'utils/toast';
import phone_icon from './Phone.svg';
import userApis from 'features/user/services/apis';
type Props = {};
type Ref = {
  open: (staff: IStaffAvailableItem) => void;
};
export const useModalCallingStaffRef = () => useRef<Ref>(null);
const ModalCallingStaff = forwardRef<Ref, Props>(({ }, ref) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const setLoadingPage = useSetLoadingPage();
  const onClose = () => setOpen(false);
  const onOpen: Ref['open'] = (value) => {
    form.setFieldsValue(value);
    setOpen(true);
  };
  const handleFinish = async (values: any) => {
    const body = values as IStaffAvailableItem;
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await userApis.callVoiceNotification(body.staffId);
      if (res.data.data) {
        toast.success('Successful');
      }
    } catch (error) {
    } finally {
      setLoadingPage(false);
    }
    onClose();
  };

  useImperativeHandle(ref, () => ({
    open: onOpen
  }));

  return (
    <Modal
      visible={open}
      onClose={onClose}
      hiddenHeader
      okTitle={'Call'}
      cancelTitle={'Close'}
      onSubmit={() => form.submit()}
      v2
      width={'650px'}
    >
      <ModalStyled form={form} onFinish={handleFinish}>
        <Form.Item name={'staffId'} noStyle />
        <IconWarningBig />
        <Box display='flex' alignItems='center' className='customer-info'>
          <span>Call:</span>
          <FormItemValue name={'staffName'} render={val => <span className='customer-name'>{val}</span>} />
        </Box>
      </ModalStyled>
    </Modal>
  );
});
ModalCallingStaff.displayName = 'ModalCallingStaff';
export default ModalCallingStaff;


const ModalStyled = styled(Form)`
  text-align: center;
  zoom: 1.5;

  .customer-info {
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    gap: 8px;
    justify-content: center;
    span {
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
      &.customer-name {
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    }
    span.customer-phone {
      color: #1D2129;
      font-family: Poppins;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }
`;

const IconWarningBig = () => <img src={phone_icon} alt='phone_icon' style={{ width: 120, height: 120 }} />;

