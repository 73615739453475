import { Col, Form, Row } from 'antd';
import Button from 'components/Button';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
type IButtonGroupProps = {};
const ButtonGroup: React.FC<IButtonGroupProps> = () => {
  const versionUI = shopSelectors.data.versionUI();
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(`/store/${storage.shop_id.get()}/setting-staff`);
  };

  if (versionUI === 'V2') {
    return (
      <ButtonGroupStyledV2>
        <button onClick={handleBack} type='button' className="btn btn-back"><span>Back</span></button>
        <button type='submit' className="btn btn-submit"><span>Update</span></button>
      </ButtonGroupStyledV2>
    );
  }

  return (
    <ButtonGroupStyled>
      <Row gutter={24}>
        <Col span={8} offset={4}>
          <Button
            onClick={handleBack}
            width="100%"
            ntype="DEFAULT"
            icon="arrowLeft"
          >
            Back
          </Button>
        </Col>

        <Col span={8}>
          <Form.Item>
            <Button width="100%" ntype="PRIMARY" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </ButtonGroupStyled>
  );
};

export default ButtonGroup;
const ButtonGroupStyled = styled.div``;
const ButtonGroupStyledV2 = styled.div`
  display: flex;
  gap: 24px;
  justify-content: flex-end;
  button.btn {
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 172px;
    border-radius: 4px;
    background: #E5E6EB;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
    span {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &:hover {
      opacity: 0.8;
    }

    &.btn-submit {
      border-radius: 4px;
      background: #FF8890;
      box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -4px 0px 0px #E7646D inset;
      span {
        color: #fff;
      }
    }
  }
`;
