
import { DeleteOutlined } from '@ant-design/icons';
import { Avatar, Form } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import CurrencyInputPrecision from 'components/CurrencyInputPrecision';
import { ButtonFooter, GroupButtonFooterModalStyled } from 'components/GroupButtonFooterModal';
import Modal from 'components/Modal';
import { CurrencyKeyBoardRef } from 'components/NumberKeyBoard/Currency';
import Text from 'components/Text';
import { parseDecimal } from 'features/cashier/services/constants';
import ticketListActions from 'features/ticketList/services/actions';
import ticketListSelectors from 'features/ticketList/services/selectors';
import { IApiSplitTicketItem } from 'features/ticketList/services/types/ticket';
import { sumBy } from 'lodash';
import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IStaffItemData } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import ModalChooseStaffFilter from 'widgets/Staff/DropDown/ModalChooseStaffFilter';
type Ref = {
  open: () => void;
};
type Props = {
  v2?: boolean;
};
type FormValues = { staffs: IApiSplitTicketItem[] };
export const useModalSplitTicketRef = () => useRef<Ref>(null);
export const ModalSplitTicket = forwardRef<Ref, Props>(({ v2 }, ref) => {
  const dispatch = useAppDispatch();
  const currencyRef = useRef<CurrencyKeyBoardRef>(null);
  const details = ticketListSelectors.ticketDetailsData.data();
  const [visible, setVisible] = useState(false);
  const [visibleChooseStaff, setVisibleChooseStaff] = useState(false);
  const [form] = Form.useForm<{ staffs: IApiSplitTicketItem[] }>();
  const staffs = Form.useWatch('staffs', form);
  const setPageLoading = useSetLoadingPage();

  const addRef = useRef<(defaultValue?: IApiSplitTicketItem) => void>(() => undefined);
  const addNewItem = addRef.current;

  const filterStaff = useCallback((list: IStaffItemData[]) => {
    return list.filter(o => !o.anyBody && staffs?.findIndex(s => o.id === s.staffId) === -1);
  }, [staffs]);


  const handleAddStaff = (o: IStaffItemData) => {
    addNewItem({
      staffId: o.id,
      amount: 0,
      avatar: o.urlImage,
      staffName: o.firstName,
      discount: 0,
      supplyFee: 0,
      tip: 0,
    });
  };

  const handleClose = () => setVisible(false);

  const handleFinish = async (values: FormValues) => {
    handleClose();
    setPageLoading(true);
    const payload: IApiSplitTicketItem[] = values.staffs.map(o => ({
      amount: o.amount,
      discount: o.discount,
      staffId: o.staffId,
      supplyFee: o.supplyFee,
      tip: o.tip,
    }) as IApiSplitTicketItem);
    dispatch(ticketListActions.splitCloseTicket({ billId: details.billId, data: payload }));
  };

  const getDisabled = () => {
    return false;
  };

  const checkValid = (staffs: IApiSplitTicketItem[]) => {
    const subTotal = parseDecimal(sumBy(staffs, o => o.amount));
    const validAmount = subTotal === parseDecimal(details.subTotal);
    const tip = parseDecimal(sumBy(staffs, o => o.tip));
    const validTip = tip === parseDecimal(details.tip);
    const discount = parseDecimal(sumBy(staffs, o => o.discount));
    const validDiscount = discount === parseDecimal(details.discountTicket);
    const supplyFee = parseDecimal(sumBy(staffs, o => o.supplyFee));
    const validSupplyFee = supplyFee === parseDecimal(details.supplyFee);
    if (!subTotal && !tip && !discount && !supplyFee) return ({
      error: '',
      valid: false,
    });

    if (!validAmount) {
      return {
        error: 'The Sub-total does not match',
        valid: false,
      };
    }

    if (!validDiscount) {
      return {
        error: 'The discount does not match',
        valid: false,
      };
    }

    if (!validTip) {
      return {
        error: 'The tip does not match',
        valid: false,
      };
    }

    if (!validSupplyFee) {
      return {
        error: 'the supply fee does not match',
        valid: false,
      };
    }

    return {
      error: '',
      valid: validAmount && validTip && validDiscount && validSupplyFee,
    };
  };

  useImperativeHandle(ref, () => ({
    open() {
      const dataList = (details?.items || []).map(o => ({
        staffId: o.staffId,
        amount: 0,
        avatar: o.avatar,
        staffName: o.staffName,
        discount: 0,
        supplyFee: 0,
        tip: 0,
      }) as IApiSplitTicketItem);
      form.setFieldValue('staffs', dataList);
      setVisible(true);
      setTimeout(() => {
        currencyRef.current?.setValue(formatCurrency(details?.tip || 0));
      }, 100);
    },
  }));

  return (
    <Modal
      modalTitle="Split Master"
      okTitle={'Save'}
      closable={false}
      visible={visible}
      onClose={handleClose}
      width={'auto'}
      disableOk={getDisabled()}
      noneBodyStyle
      noneFooterStyle
      containerPadding={1}
      className='modal-overflow-unset modal-max-height-unset'
      centered
      footer={null}
      forceRender
      v2={v2}
    >
      <Container className={v2 ? 'v2' : ''}>
        <Form form={form} onFinish={handleFinish}>
          <Form.List name={'staffs'}>
            {(fields, { add, remove }) => {
              return (
                <div className='table-wrapper'>
                  <TableStyled className='table-container'>
                    <thead className='table-thead'>
                      <tr>
                        <th className='staff-info no-style' style={{ background: '#fff' }} />
                        <th>
                          <div className="container-box">
                            <span>Sub-total</span>
                          </div>
                        </th>
                        <th>
                          <div className="container-box">
                            <span>Tip</span>
                          </div>
                        </th>
                        <th>
                          <div className="container-box">
                            <span>Discount</span>
                          </div>
                        </th>
                        <th>
                          <div className="container-box">
                            <span>Supply Fee</span>
                          </div>
                        </th>
                        <th className='action'><div className="container-box" /></th>
                      </tr>
                      <tr className='thead-total-row'>
                        <th className='staff-info no-style' style={{ background: '#fff' }} />
                        <th>
                          <div className="container-box">
                            {formatCurrency(details.subTotal)}
                          </div>
                        </th>
                        <th>
                          <div className="container-box">
                            {formatCurrency(details.tip)}
                          </div>
                        </th>
                        <th>
                          <div className="container-box">
                            {formatCurrency(details.discountTicket)}
                          </div>
                        </th>
                        <th>
                          <div className="container-box">
                            {formatCurrency(details.supplyFee)}
                          </div>
                        </th>
                        <th className='action'><div className="container-box" /></th>
                      </tr>
                    </thead>
                    <tbody className='table-tbody'>
                      {fields.map((o, i) => (
                        <Form.Item noStyle name={o.name} key={o.key}>
                          <StaffRow name={o.name} remove={() => remove(i)}
                            disabledRemove={fields.length === 1}
                            disableDiscount={!details?.discountTicket}
                            disableSupplyFee={!details.supplyFee}
                          />
                        </Form.Item>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr className=''>
                        <th className='staff-info' style={{ background: '#fff' }} ></th>
                        <th colSpan={5} >
                          <Button cardCashier={v2} ntype="LIGHT_BLUE" width="100%" onClick={() => {
                            setVisibleChooseStaff(true);
                            addRef.current = add;
                          }}>
                            + Add New Staff
                          </Button>
                        </th>
                      </tr>
                    </tfoot>
                  </TableStyled>
                </div>
              );
            }}
          </Form.List>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldsValue }) => {
              const values: FormValues = getFieldsValue();
              const validForm = checkValid(values.staffs);
              return (
                <div className='footer-modal'>
                  <Text style={{ opacity: validForm.error ? 1 : 0, fontSize: '1.2rem', fontWeight: '500' }} m={1} textAlign='center' variant='CONTENT_1' color='error_6'>{validForm.error || 'fail'}</Text>
                  <GroupButtonFooterModalStyled >
                    <ButtonFooter
                      buttonName='Close'
                      type='DEFAULT'
                      onClick={() => setVisible(false)}
                      cardCashier={v2}

                    />
                    <ButtonFooter
                      buttonName='Apply'
                      type='PRIMARY'
                      disabled={!validForm.valid}
                      onClick={() => form.submit()}
                      cardCashier={v2}
                    />
                  </GroupButtonFooterModalStyled>
                </div>
              );
            }}
          </Form.Item>

        </Form>
      </Container>
      <ModalChooseStaffFilter
        filter={filterStaff}
        visible={visibleChooseStaff}
        onChooseStaff={handleAddStaff}
        onClose={() => setVisibleChooseStaff(false)}
      />
    </Modal>
  );
});
ModalSplitTicket.displayName = 'ModalSplitTicket';
const useModalSplitTicket = (): [React.RefObject<Ref>, JSX.Element] => {
  const ref = useRef<Ref>(null);
  const component = <ModalSplitTicket ref={ref} />;

  return [ref, component];
};
export default useModalSplitTicket;

const TableStyled = styled.table`
border-collapse: collapse;
  &.table-container {
    .table-thead {
      tr {
        th {
          background: #E5E6EB;
          padding: 8px;
          &.no-style {
            background: transparent ;
          }
          span {
            font-size: 1.2rem;
          }
         
        }
        &.thead-total-row {
          font-size: 1.5rem;
          .container-box {
            text-align: right;
            justify-content: flex-end;
            padding-right: 12px;

          }
          th {
            height: 48px;
            padding: 0;
          }
        }
      }
      .btn-add {
        background: #6FABB6;
        height: 48px;
        width: 48px;
        border-radius: 8px;
        color: #fff;
        span {
          font-size: 1.5rem;
        }
      }
    }
    th, td {
      position: relative;
      z-index: 1;
      min-width: 150px;
      height: 48px;
      &.staff-info {
        min-width: 120px;
      }
      &.action {
        min-width: 48px;
      }
      .container-box {
        position: absolute;
        inset: 0;
        background: transparent;
        border: 2px solid #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        &.with-input {
          justify-content: flex-end;
        }
        &.staff-col {
          justify-content: start;
        }
      }
      &.action {
        padding: 8px !important;
      }
    }
    .table-tbody {
      tr {
        td {
          background: #E5E6EB;
          text-align: right;
          padding: 4px 0px;
          padding-right: 2px;
          &.no-style {
            background: transparent ;
          }
          &.staff-info {
            min-width: 100px;
            .CONTENT_2 {
              font-size: 1.2rem;
            }
          }
          .ant-input {
            height: 48px !important;
            width: 80% !important;
            text-align: right;
            font-size: 1.5rem;
            &:disabled {
              // color: #1D2129;
              background: #fff;
            }
          }
        }
      }

      .btn-delete {
        background: #F53F3F;
        height: 48px;
        width: 48px;
        border-radius: 8px;
        color: #fff;
        span {
          font-size: 1.5rem;
        }
      }
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex:1;
  padding: 1rem;
  .currency-container {
    width: 500px;
  }
  .InputViewStyled {
    justify-content: center;
    input {
      width: 80%;
      flex: unset;
    }
  }
  .footer-modal {

  }
  .table-wrapper {
    max-height: 590px;
    overflow: auto;
    .table-thead {
      position: sticky;
      top:0;
      z-index: 99;
    }
    tfoot {
      position: sticky;
      bottom: 0;
      z-index: 99;
      tr th {
        background: #fff;
        padding-top: 6px;
        .ant-btn {
          box-shadow: none;
        }
      }
    }
  }

&.v2 {
  zoom: 1.2;
}
`;
type StaffRowProps = {
  value?: IApiSplitTicketItem;
  name: any;
  remove?: () => void;
  disabledRemove?: boolean;
  disableSupplyFee?: boolean;
  disableDiscount?: boolean;
};
const StaffRow = ({ value, name, remove, disabledRemove, disableDiscount, disableSupplyFee }: StaffRowProps) => {
  return (
    <tr>
      <td className='staff-info no-style'>
        <div className="container-box staff-col">
          <Box display='flex' gap='1' alignItems='center'>
            <Avatar src={value?.avatar || 'fail'}>{value?.staffName?.[0] || 'A'}</Avatar>
            <Text variant='CONTENT_2'>{value?.staffName}</Text>
          </Box>
        </div>
      </td>
      <td>
        <div className="container-box with-input">
          <Form.Item noStyle name={[name, 'amount']}>
            <CurrencyInputPrecision />
          </Form.Item>
        </div>
      </td>
      <td>
        <div className="container-box with-input">
          <Form.Item noStyle name={[name, 'tip']}>
            <CurrencyInputPrecision />
          </Form.Item>
        </div>
      </td>
      <td>
        <div className="container-box with-input">
          <Form.Item noStyle name={[name, 'discount']}>
            <CurrencyInputPrecision disabled={disableDiscount} />
          </Form.Item>
        </div>
      </td>
      <td>
        <div className="container-box with-input">
          <Form.Item noStyle name={[name, 'supplyFee']}>
            <CurrencyInputPrecision disabled={disableSupplyFee} />
          </Form.Item>
        </div>
      </td>
      <td className='action'>
        <div className="container-box">
          {!disabledRemove && <button type='button' className='btn-delete' onClick={remove}>
            <DeleteOutlined />
          </button>}
        </div>
      </td>
    </tr>
  );
};
