import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import settingStaffActions from 'features/settingStaff/services/actions';
import settingStaffSelectors from 'features/settingStaff/services/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import DaySchedule from './StaffSchedule/DaySchedule';
import dayjs from 'dayjs';
import Box from 'components/Box';
import DaysOffBox from './StaffSchedule/DaysOffBox';
import ButtonGroup from './ButtonGroup';
import { IStaffScheduler } from 'features/settingStaff/services/types/staff';
import Text from 'components/Text';
import { set } from 'lodash';
import DayScheduleV2 from './StaffSchedule/DayScheduleV2';

type IStaffScheduleProps = {
  v2?: boolean;
};

const StaffSchedule: React.FC<IStaffScheduleProps> = ({ v2 }) => {
  const [initialForm, setInitialForm] = useState<IStaffScheduler>();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const staffSelected = settingStaffSelectors.selectedStaffData.data();
  const staffSelectedScheduler =
    settingStaffSelectors.selectedStaffData.dataScheduler();

  const processInitialValues = (scheduler: IStaffScheduler) => {
    const initialValues = { ...scheduler };
    Object.keys(scheduler).forEach((key) => {
      if (key.endsWith('StartTime') || key.endsWith('EndTime')) {
        initialValues[key] = dayjs(scheduler[key], 'HH:mm');
      } else {
        initialValues[key] = scheduler[key];
      }
    });
    return initialValues;
  };

  const onFinish = async (values: IStaffScheduler) => {
    if (values) {
      const data = { ...values };
      Object.keys(data).forEach((key) => {
        if (key.endsWith('StartTime') || key.endsWith('EndTime')) {
          data[key] = dayjs(values[key], 'HH:mm').format('HH:mm');
        } else {
          data[key] = values[key];
        }
      });

      set(data, 'staffId', staffSelectedScheduler.staffId);

      dispatch(settingStaffActions.updateStaffScheduler.fetch(data));
    }
  };

  useEffect(() => {
    if (staffSelectedScheduler) {
      const dataForm = processInitialValues(staffSelectedScheduler);
      setInitialForm(dataForm);
      form.setFieldsValue(dataForm);
    }
  }, [staffSelectedScheduler]);

  useEffect(() => {
    if (staffSelected.id) {
      dispatch(settingStaffActions.staffScheduler.fetch(staffSelected.id));
      const params = {
        staffId: staffSelected.id,
      };
      dispatch(settingStaffActions.setStaffDayOffParams(params));
    }
  }, [staffSelected]);

  return (
    <StaffScheduleStyled className={v2 ? 'v2' : ''}>
      <Form
        form={form}
        onFinish={onFinish}
        layout='vertical'
        initialValues={initialForm}
      >
        <Text variant='H7' color='text_3' mb={1}>
          {v2 ? 'Workweek': 'Regular Time'}
        </Text>
        <RowScheduleStyled v2={v2} className='RowScheduleStyled'>
          {v2 ? (
            <>
              <DayScheduleV2
                day='Monday'
                enable='enableMon'
                start='monStartTime'
                end='monEndTime'
              />
              <DayScheduleV2
                day='Tuesday'
                enable='enableTue'
                start='tueStartTime'
                end='tueEndTime'
              />
              <DayScheduleV2
                day='Wednesday'
                enable='enableWed'
                start='wedStartTime'
                end='wedEndTime'
              />
              <DayScheduleV2
                day='Thursday'
                enable='enableThu'
                start='thuStartTime'
                end='thuEndTime'
              />
              <DayScheduleV2
                day='Friday'
                enable='enableFri'
                start='friStartTime'
                end='friEndTime'
              />
              <DayScheduleV2
                day='Saturday'
                enable='enableSat'
                start='satStartTime'
                end='satEndTime'
              />
              <DayScheduleV2
                day='Sunday'
                enable='enableSun'
                start='sunStartTime'
                end='sunEndTime'
              />
            </>
          ) : (
            <>
              <DaySchedule
                day='Monday'
                enable='enableMon'
                start='monStartTime'
                end='monEndTime'
              />
              <DaySchedule
                day='Tuesday'
                enable='enableTue'
                start='tueStartTime'
                end='tueEndTime'
              />
              <DaySchedule
                day='Wednesday'
                enable='enableWed'
                start='wedStartTime'
                end='wedEndTime'
              />
              <DaySchedule
                day='Thursday'
                enable='enableThu'
                start='thuStartTime'
                end='thuEndTime'
              />
              <DaySchedule
                day='Friday'
                enable='enableFri'
                start='friStartTime'
                end='friEndTime'
              />
              <DaySchedule
                day='Saturday'
                enable='enableSat'
                start='satStartTime'
                end='satEndTime'
              />
              <DaySchedule
                day='Sunday'
                enable='enableSun'
                start='sunStartTime'
                end='sunEndTime'
              />
            </>
          )}
        </RowScheduleStyled>
        <DaysOffBox v2={v2} />
        <Box mt='3'>
          <ButtonGroup />
        </Box>
      </Form>
    </StaffScheduleStyled>
  );
};

export default StaffSchedule;
const StaffScheduleStyled = styled.div`
  border-radius: 5px;
  border: 1px solid var(--line-line-3, #86909c);
  background: var(--fill-fill-0, #fff);
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem;

  &.v2 {
    padding: 0px;
    border: none;
    box-shadow: none;
    background: transparent;

    .H7 {
      color: #64748b;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .RowScheduleStyled {
      padding: 0px;
      border: none;
      box-shadow: none;
      background: #f6f7fc;

      .header {
        border-bottom: none;
        background: #cbd5e1;
        display: flex;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        .ant-form-item {
          padding: 0;
          margin: 0;

          .ant-checkbox-wrapper {
            &.ant-checkbox-wrapper-checked .ant-checkbox .ant-checkbox-inner {
              background-color: #d2464f;
              border-color: #d2464f;
            }
            .ant-checkbox .ant-checkbox-inner {
              background-color: #fff;
              border-color: #d2464f;
            }
          }

          .custom-checkbox .ant-checkbox .ant-checkbox-inner::after {
            transform: rotate(45deg) scale(1.5) translate(-50%, -50%);
          }
        }
      }
      .item-schedule {
        width: 100%;
        border-right: 1px solid #e9edf2;
        &:last-child {
          border-right: 1px solid transparent;
        }
        input {
          text-align: center;
        }

        .input-container {
          flex-direction: column;
          .ant-form-item {
            margin: 0;
            padding: 0;
            align-self: stretch;
            .ant-picker-input {
              display: flex;
              height: 48px;
              padding: 0px 10px;
              justify-content: center;
              align-items: center;
              input {
                color: #1d2129;
                text-align: center;
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
`;
const RowScheduleStyled = styled.div<{ v2?: boolean }>`
  border-radius: 5px;
  border: 1px solid var(--line-line-3, #86909c);
  background: var(--fill-fill-0, #fff);
  display: flex;
  flex-direction: ${(props) => (props.v2 ? 'column' : 'row')};
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  .header {
    border-bottom: 1px solid var(--line-line-3, #c9cdd4);
  }
  .item-schedule {
    width: 100%;
    border-right: 1px solid var(--line-line-3, #c9cdd4);
    input {
      text-align: center;
    }
  }
  .item-schedule:first-child .header {
    border-top-left-radius: 5px;
  }
  .item-schedule:last-child .header {
    border-top-right-radius: 5px;
  }
  .item-schedule:last-child {
    border-right: none;
  }
`;
