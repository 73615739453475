import { Avatar, message, QRCode, Upload } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { RcFile } from 'antd/lib/upload';
import settingStaffActions from 'features/settingStaff/services/actions';
import settingStaffSelectors from 'features/settingStaff/services/selectors';
import { IUpdateStaffAvatar } from 'features/settingStaff/services/types/api';
import moment from 'moment';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatPhone } from 'utils/formatPhone';
import { getBase64 } from 'utils/getBase64';
import Box from 'components/Box';
import { useMemo } from 'react';
import storage from 'utils/sessionStorage';
import camera_icon from './icons/camera.svg';

const StaffInfo = () => {
  const dispatch = useAppDispatch();
  const targetStaff = settingStaffSelectors.selectedStaffData.data();
  const qrCheckInValue = useMemo(() =>
    `https://customer.fozito.com/store/${storage.shop_id.get()}/check-in/${storage.station_number.get() || '1'}?staff_id=${targetStaff.id}`, [targetStaff]);
  const handleChangeAvatar = async (info: UploadChangeParam) => {
    // Check if file upload is done
    if (info.file) {
      message.success(`${info.file.name} file uploaded successfully`);
      try {
        const urlBase64 = await getBase64(info.file as RcFile);

        const params: IUpdateStaffAvatar = {
          staffId: targetStaff?.id || '',
          avatar: urlBase64 || '',
        };
        dispatch(settingStaffActions.updateStaffAvatar.fetch(params));
      } catch (error) {
        message.error('Failed to retrieve the new avatar.');
      }
    } else {
      message.error('File upload failed.');
    }
  };

  return (
    <StaffInfoStyled>
      <Upload
        beforeUpload={() => false}
        action="YOUR_CORRECT_API_ENDPOINT"
        onChange={handleChangeAvatar}
        maxCount={1}
        multiple={false}
        showUploadList={false}
      >
        <EditAvatar>
          <img src={camera_icon} alt="camera_icon" style={{ width: 24, height: 24 }} />
        </EditAvatar>
        <Avatar size={120} src={targetStaff?.urlImage || 'fail'}  >{targetStaff?.firstName?.[0] || 'A'}</Avatar>
      </Upload>
      <div className="info-container">
        <div className="staff-type-box"><span>{targetStaff?.staffType}</span></div>
        <div className="staff-name">{targetStaff?.firstName} {targetStaff?.lastName}</div>
        <StaffInfoContainer>
          <div className="information-list">
            <div className="information-item">
              <span className="label">Phone:</span>
              <span className="value">{targetStaff?.phone ? formatPhone(targetStaff?.phone) : '--'}</span>
            </div>
          </div>
          <div className="information-list" style={{ border: 'none' }}>
            <div className="information-item">
              <span className="label">Email:</span>
              <span className="value">{targetStaff?.email || '--'}</span>
            </div>
          </div>
        </StaffInfoContainer>
        <StaffInfoContainer>
          <div className="information-list" style={{ border: 'none' }}>
            <div className="information-item">
              <span className="label">Create Date:</span>
              <span className="value"> {`${moment(
                targetStaff?.createdDate,
                'M/D/YY, h:mm A'
              ).format('MM-DD-YYYY')}`}</span>
            </div>
          </div>
        </StaffInfoContainer>
      </div>
      <Box display='flex' flexDirection='column' gap='4' justifyContent='center' alignItems='center'>
        <Box display='flex'>
          <Box display='flex' flexDirection='column' className='qr-code'>
            <QRCode value={qrCheckInValue} bordered={false} size={200} />
            <p className='qr-text'>QR code check in by staff</p>
          </Box>
        </Box>
      </Box>
    </StaffInfoStyled>
  );
};
const EditAvatar = styled.div`
display: flex;
width: 48px;
height: 48px;
padding: 10px;
justify-content: center;
align-items: center;
gap: 10px;
aspect-ratio: 1/1;
position: absolute;
right: 0px;
bottom: 0px;
z-index: 2;
border-radius: 100px;
background: var(--Neutral-06, #232F3E);
`;
const StaffInfoStyled = styled.div`
background: #FFF;
box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25);
padding: 24px 16px;
display: flex;
align-self: stretch;
gap: 24px;
.ant-upload-select {
  position: relative;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}
.qr-code {
  border-radius: 4px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  .qr-text {
    border-radius: 0px 0px 4px 4px;
    background: #232F3E;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-self: stretch;
    padding: 8px 4px;
  }
}
.info-container {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
}
.staff-type-box {
  display: flex;
  height: 32px;
  padding: 0px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background: #C6E9EF;
  span {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.staff-name {
  align-self: stretch;
  color: #1D2129;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 100% */
}

.btn-edit-avatar {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 4px;
  background: #C6E9EF;
  box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #6FABB6 inset;
  span {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
`;

const StaffInfoContainer = styled.div`
display: flex;
align-items: flex-start;
gap: 16px;
align-self: stretch;
margin-top: 16px;
  .information-list {
    display: flex;
    padding-right: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-right: 1px solid #CCD4DC;
    .information-item {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      span.label {
        color: #64748B;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        &.date {
          width: 130px;
        }
      }

      span.value {
        flex: 1;
        color: #1D2129;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
`;
export default StaffInfo;
