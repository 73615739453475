import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import Modal from 'components/Modal';
import { useAppDispatch } from 'store/hooks';
import colorTheme from 'constants/color';
import ticketListActions from 'features/ticketList/services/actions';
import CurrencyKeyBoard, {
  CurrencyKeyBoardRef,
} from 'components/NumberKeyBoard/Currency';

type IRefundModalButtonProps = {
  billId: string;
  maxAmount: number;
  v2?: boolean;
};
const RefundModalButton: React.FC<IRefundModalButtonProps> = ({
  billId,
  maxAmount, v2,
}) => {
  const dispatch = useAppDispatch();
  const [openRefund, setOpenRefund] = useState(false);
  const currencyRef = useRef<CurrencyKeyBoardRef>(null);
  const [refundValue, setRefundValue] = useState<number | null | undefined>();

  const handleShowRefundModal = () => {
    setOpenRefund(true);
  };
  const handleCloseRefundModal = () => {
    currencyRef.current?.hideError();
    setRefundValue(0);
    setOpenRefund(false);
  };

  const handleSubmitRefund = () => {
    const payload = {
      billId: billId || '',
      amount: Number(refundValue),
    };
    dispatch(ticketListActions.refundTicketByBillId.fetch(payload));
    handleCloseRefundModal();
  };

  useEffect(() => {
    if (refundValue && openRefund && refundValue > maxAmount) {
      currencyRef.current?.showError(
        `Max refund must be $${maxAmount?.toFixed(2)}`
      );
    } else {
      currencyRef.current?.hideError();
    }
  }, [refundValue, openRefund]);

  return (
    <>
      <ActionButton
        v2={v2}
        color={colorTheme.text_3}
        bgColor={colorTheme.info_orange}
        onClick={handleShowRefundModal}
      >
        Refund
      </ActionButton>
      <Modal
        modalTitle={'REFUND'}
        visible={openRefund}
        onClose={handleCloseRefundModal}
        onSubmit={handleSubmitRefund}
        destroyOnClose={true}
        disableOk={!!(refundValue && refundValue > maxAmount)}
        v2={v2}
        width={800}
      >
        <CurrencyKeyBoard
          ref={currencyRef}
          value={refundValue}
          onChange={setRefundValue}
          v2={v2}
        />
      </Modal>
    </>
  );
};

export default RefundModalButton;
const ActionButton = styled(Button).withConfig({
  shouldForwardProp: (prop) => !['bgColor'].includes(prop),
}) <{ bgColor?: string; color?: string, v2?: boolean; }>`
  display: flex;
  padding: 8px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: ${(props) =>
    props.bgColor || 'var(--info-infor-3, #6fabb6)'} !important;
  flex-grow: 1;
  flex-basis: 1rem;
  cursor: pointer;
  color: ${(props) => props.color || 'var(--text-text-1, #fff)'} !important;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-color: ${(props) =>
    props.bgColor || 'var(--info-infor-3, #6fabb6)'} !important;
  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.5;
  }

  ${({ v2 }) => v2 && `
    display: flex;
    height: 38px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: #FF7D00;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #DC5500 inset;
    .CONTENT_2, span {
      color: #fff !important;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  `}
`;
