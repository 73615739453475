import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import Box from 'components/Box';
import Text from 'components/Text';
import styled from 'styled-components';
import SchedulerTable from './components/SchedulerTable';
import { useAppDispatch } from 'store/hooks';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import dayjs from 'dayjs';
import { IStaffScheduler } from 'features/settingStaff/services/types/staff';
import Button from 'components/Button';
import DaysOffList from './components/DaysOffList';
import ActionButtons from '../PaymentTabMenu/components/ActionButtons';

type IWorkingTimeProps = {
  v2?: boolean;
};
const WorkingTime: React.FC<IWorkingTimeProps> = ({ v2 }) => {
  const dispatch = useAppDispatch();
  const [initialForm, setInitialForm] = useState<IStaffScheduler>();
  const schedulerStore =
    settingServiceSelectors.notificationSetting.scheduler();
  const [form] = Form.useForm();

  const onFinish = (values: IStaffScheduler) => {
    if (values) {
      const data = { ...values };
      Object.keys(data).forEach((key) => {
        if (key.endsWith('StartTime') || key.endsWith('EndTime')) {
          data[key] = dayjs(values[key], 'HH:mm').format('HH:mm');
        } else {
          data[key] = values[key];
        }
      });
      const body = { ...data, id: schedulerStore.id };
      dispatch(settingServiceActions.postScheduler.fetch(body));
    }
  };

  const processInitialValues = (scheduler: IStaffScheduler) => {
    const initialValues = { ...scheduler };
    Object.keys(scheduler).forEach((key) => {
      if (key.endsWith('StartTime') || key.endsWith('EndTime')) {
        initialValues[key] = dayjs(scheduler[key], 'HH:mm');
      } else {
        initialValues[key] = scheduler[key];
      }
    });
    return initialValues;
  };

  useEffect(() => {
    if (schedulerStore) {
      const dataForm = processInitialValues(schedulerStore);
      setInitialForm(dataForm);
      form.setFieldsValue(dataForm);
    }
  }, [schedulerStore]);

  useEffect(() => {
    dispatch(settingServiceActions.getScheduler.fetch());
    dispatch(settingServiceActions.getDayOffList.fetch());
  }, []);

  return (
    <WorkingTimeStyled className={v2 ? 'v2' : ''}>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={initialForm}
      >
        <Box bgColor="fill_1" p="3" borderRadius="5px">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text variant="H7" color="text_3" className={'section-label'}>
              Regular Time
            </Text>
          </Box>
          <SchedulerTable v2={v2} />
          <DaysOffList v2={v2} />
        </Box>
        {v2 ? <ActionButtons /> : <Box display="flex" gap="3" mt="4" justifyContent="end">
          <Button width="10rem" ntype="SECONDARY" htmlType="reset">
            Reset
          </Button>
          <Button width="10rem" ntype="PRIMARY" htmlType="submit">
            Save
          </Button>
        </Box>}
      </Form>
    </WorkingTimeStyled>
  );
};

export default WorkingTime;
const WorkingTimeStyled = styled.div`
  flex-grow: 1;
  background: var(--fill-fill-3, #e5e5e5);
  border-radius: 5px;
  &.v2 {
    background: unset;
    .section-label {
      color: #1D2129;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal; 
    }
  }
`;
