import React from 'react';
import { Avatar, Input, message, Tabs, TabsProps, Upload } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import settingStaffSelectors from 'features/settingStaff/services/selectors';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { formatPhone } from 'utils/formatPhone';
import storage from 'utils/sessionStorage';
import StaffInformation from './StaffInformation';
import StaffSalary from './StaffSalary';
import StaffSchedule from './StaffSchedule';
import StaffSecurity from './StaffSecurity';
import StaffSpecialize from './StaffSpecialize';
import moment from 'moment';
import { UserOutlined } from '@ant-design/icons';
import { useAppDispatch } from 'store/hooks';
import settingStaffActions from 'features/settingStaff/services/actions';
import { getBase64 } from 'utils/getBase64';
import { UploadChangeParam } from 'antd/es/upload';
import { RcFile } from 'antd/lib/upload';
import { IUpdateStaffAvatar } from 'features/settingStaff/services/types/api';
import { debounce } from 'lodash';
import SearchIcon from 'assets/Icons/search';
type IStaffDetailsProps = {};
const TabLabelStyled = styled.div`
  font-weight: 600;
  text-transform: uppercase;
`;
const items: TabsProps['items'] = [
  {
    key: 'information',
    label: <TabLabelStyled>Information</TabLabelStyled>,
    children: <StaffInformation />,
  },
  {
    key: 'security',
    label: <TabLabelStyled>Security</TabLabelStyled>,
    children: <StaffSecurity />,
  },
  {
    key: 'salary',
    label: <TabLabelStyled>Salary</TabLabelStyled>,
    children: <StaffSalary />,
  },
  {
    key: 'specialize',
    label: <TabLabelStyled>Specialize</TabLabelStyled>,
    children: <StaffSpecialize />,
  },
  {
    key: 'schedule',
    label: <TabLabelStyled>Schedule</TabLabelStyled>,
    children: <StaffSchedule />,
  },
];

const StaffDetails: React.FC<IStaffDetailsProps> = () => {
  const navigate = useNavigate();
  const { tab } = useParams();
  const dispatch = useAppDispatch();
  const staffs = settingStaffSelectors.staffsTableData.data();
  const targetStaff = settingStaffSelectors.selectedStaffData.data();

  const handleChangeAvatar = async (info: UploadChangeParam) => {
    // Check if file upload is done
    if (info.file) {
      message.success(`${info.file.name} file uploaded successfully`);
      try {
        const urlBase64 = await getBase64(info.file as RcFile);

        const params: IUpdateStaffAvatar = {
          staffId: targetStaff?.id || '',
          avatar: urlBase64 || '',
        };
        dispatch(settingStaffActions.updateStaffAvatar.fetch(params));
      } catch (error) {
        message.error('Failed to retrieve the new avatar.');
      }
    } else {
      message.error('File upload failed.');
    }
  };

  const onChange = (key: string) => {
    navigate(`/store/${storage.shop_id.get()}/setting-staff/${key}`);
  };

  const handleAddStaff = () => {
    navigate(`/store/${storage.shop_id.get()}/setting-staff/add-new`);
  };

  const debouncedHandleChange = debounce((value: string) => {
    const filteredArray = staffs.filter((staff) =>
      `${staff.firstName} ${staff.lastName}`
        .toLowerCase()
        .includes(value.toLowerCase())
    );
    dispatch(settingStaffActions.searchStaff(filteredArray));
  }, 500); // 500ms delay

  return (
    <StaffDetailsStyled>
      {targetStaff && (
        <>
          <Box className="space-between">
            <Box display="flex" gap="2" alignItems="center">
              <AvatarWrapper>
                {targetStaff.urlImage ? (
                  <Avatar size={70} src={targetStaff.urlImage} />
                ) : (
                  <Avatar size={70} icon={<UserOutlined />} />
                )}
              </AvatarWrapper>
              <Box>
                <Text variant="H9" color="text_3">
                  {`${targetStaff?.firstName} ${targetStaff?.lastName}`}
                </Text>
                <Text variant="BODY_1" color="text_2">
                  {formatPhone(targetStaff?.phone)}
                </Text>
                <Text variant="BODY_1" color="text_2">
                  {`Created date: ${moment(
                    targetStaff?.createdDate,
                    'M/D/YY, h:mm A'
                  ).format('MM-DD-YYYY')}`}
                </Text>
              </Box>
            </Box>
            <Box width={'26rem'}>
              <Input
                autoComplete="off"
                name="keySearch"
                defaultValue=""
                onChange={(event) => {
                  debouncedHandleChange(event.target.value);
                }}
                placeholder="Staff name"
                suffix={<SearchIcon />}
              />
            </Box>
          </Box>
          <Box position='relative'>
            <ButtonAddStyledBox>
              <Upload
                beforeUpload={() => false}
                action="YOUR_CORRECT_API_ENDPOINT"
                onChange={handleChangeAvatar}
                maxCount={1}
                multiple={false}
                showUploadList={false}
              >
                <Button icon="edit" ntype="PRIMARY">
                  UPLOAD NEW AVATAR
                </Button>
              </Upload>
              <Button ntype="PRIMARY" icon="addPlus" onClick={handleAddStaff}>
                ADD STAFF
              </Button>
            </ButtonAddStyledBox>
            <Tabs activeKey={tab} items={items} onChange={onChange} />
          </Box>
        </>
      )}
    </StaffDetailsStyled>
  );
};

export default StaffDetails;
const StaffDetailsStyled = styled.div`
  padding: 1.5rem;
  background-color: #f6f7fc;
  gap: 1.5rem;
  .circle-avatar {
    width: 5.5rem;
    height: 5.5rem;
    border-radius: 50%;
  }
`;
const AvatarWrapper = styled.div`
  display: flex;
  width: 5rem;
  height: 5rem;
  padding: 0.5rem 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .circle-avatar {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
  }
`;

const ButtonAddStyledBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2px;
  position: absolute;
  right: 0;
  margin-top: -0.5rem;
  z-index: 1;
`;
