import { Row, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Table from 'components/Table';
import Text from 'components/Text';
import customerActions from 'features/customer/services/actions';
import customerSelectors from 'features/customer/services/selectors';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency, formatNumber } from 'utils/formatCurrency';
import { maskPhone } from 'utils/formatPhone';
import storage from 'utils/sessionStorage';
import { momentTimezone } from 'utils/time';
import StatusBox from './StatusBox';
import ModalConfirm, { useModalConfirmRef } from 'components/Modal/ModalConfirm';
import { ICustomer } from 'features/customer/services/types/api';
import { TableGreenEvenStyled } from 'features/report/components/Component';
import ModalUpdatePoint, { useModalUpdatePointRef } from './ModalUpdatePoint';
import RequestAdminLocalPW from 'widgets/RequestAdminLocalPW';
type ICustomersBodyDisplayProps = {
  v2?: boolean;
};
const scroll = { y: '65vh' };
const CustomersBodyDisplay: React.FC<ICustomersBodyDisplayProps> = ({ v2 }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const data = customerSelectors.customersTableData.data();
  const params = customerSelectors.customersTableData.params();
  const loading = customerSelectors.customersTableData.loading();
  const totalElement = customerSelectors.customersTableData.totalElement();
  const modalConfirm = useModalConfirmRef();
  const updatePointRef = useModalUpdatePointRef();
  const [visiblePW, setVisiblePW] = useState<ICustomer | null>(null);
  const handleClose = () => {
    setVisiblePW(null);
  };
  const onVerifyAdminSuccess = () => {
    if (visiblePW) onUpdatePoint(visiblePW)();
    setVisiblePW(null);
  };
  const onUpdatePoint= (data: ICustomer) => () => {
    updatePointRef.current?.open(data);
  };
  const handleDeleteCustomer = (cus: ICustomer) => {
    const cb = () => dispatch(customerActions.deleteCustomer.fetch(cus?.id));
    modalConfirm.current?.open(`Do you want to remove ${cus.name || ''}?`, cb);
  };

  
  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
      align: v2 ? 'left' : 'center',
      render(text) {
        return (
          <EllipsisText ellipsis={{ tooltip: text }}>
            {text || '--'}
          </EllipsisText>
        );
      },
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      align: v2 ? 'left' : 'center',
      render(phone) {
        return (
          <Text variant="BODY_1" color="text_3">
            {phone ? maskPhone(phone) : '--'}
          </Text>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      align: v2 ? 'left' : 'center',
      render(email) {
        return (
          <EllipsisText ellipsis={{ tooltip: email }}>
            {email || '--'}
          </EllipsisText>
        );
      },
    },
    {
      title: 'DOB',
      dataIndex: 'birthday',
      align: v2 ? 'left' : 'center',
      render(dob) {
        return (
          <Text variant="BODY_1" color="text_3">
            {dob ? momentTimezone(dob).format('MM/DD') : '--'}
          </Text>
        );
      },
    },
    {
      title: 'Point',
      dataIndex: 'point',
      align: v2 ? 'left' : 'center',
      render(point) {
        return (
          <Text variant="BODY_1" color="text_3">
            {formatNumber(point)}
          </Text>
        );
      },
    },
    {
      title: 'Amount Spent',
      dataIndex: 'amountSpent',
      align: v2 ? 'left' : 'center',
      render(amountSpent) {
        return (
          <Text variant="BODY_1" color="text_3">
            {formatCurrency(amountSpent)}
          </Text>
        );
      },
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      align: v2 ? 'left' : 'center',
      render(createdDate) {
        return (
          <Text variant="BODY_1" color="text_3">
            {createdDate ? momentTimezone(createdDate).format('MM-DD-YYYY') : ''}
          </Text>
        );
      },
    },

    {
      title: 'Visited',
      dataIndex: 'numberVisit',
      align: v2 ? 'left' : 'center',
      render(value) {
        return (
          <Text variant="BODY_1" color="text_3">
            {value}
          </Text>
        );
      },
    },
    {
      title: 'Last Visit Date',
      dataIndex: 'lastVisitedDate',
      align: v2 ? 'left' : 'center',
      render(lastVisitedDate) {
        return (
          <Text variant="BODY_1" color="text_3">
            {lastVisitedDate
              ? momentTimezone(lastVisitedDate).format('MM-DD-YYYY')
              : '--'}
          </Text>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: v2 ? 'left' : 'center',
      render(status: string) {
        return <StatusBox status={status} />;
      },
    },
    {
      title: '',
      dataIndex: 'id',
      align: v2 ? 'left' : 'center',
      width: 300,
      render(id, record) {
        return (
          <Row style={{ gap: '8px' }}>

            <ViewButtonStyled
              v2={v2}
              onClick={() =>
                navigate(`/store/${storage.shop_id.get()}/customers/${id}`)
              }
            >
              <Text variant="BODY_1" color="text_1">
                View
              </Text>
            </ViewButtonStyled>
            <ViewButtonStyled
              v2={v2}
              point
              onClick={() => setVisiblePW(record)}
            >
              <Text variant="BODY_1" color="text_1">
                Point
              </Text>
            </ViewButtonStyled>
            <ViewButtonStyled
              deleted
              v2={v2}
              onClick={() => handleDeleteCustomer(record)}
            >
              <Text variant="BODY_1" color="text_1">
                Delete
              </Text>
            </ViewButtonStyled>
          </Row >
        );
      },
    },
  ];

  const handleChangePage = (page: number, size?: number) => {
    dispatch(
      customerActions.setCustomerParam({
        page: page,
        size,
      })
    );
  };

  return (
    <>
      <CustomersBodyDisplayStyled className={v2 ? 'v2' : ''}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={data}
          rowKey={'id'}
          pagination={{
            total: totalElement ?? 0,
            pageSize: params.size,
            current: params.page,
            showSizeChanger: false,
          }}
          handleChangePage={handleChangePage}
          showSizeChanger={v2}
          scroll={v2 ? scroll : undefined}
        />
      </CustomersBodyDisplayStyled>
      <ModalConfirm ref={modalConfirm} />
      <ModalUpdatePoint ref={updatePointRef} />
      {!!visiblePW && <RequestAdminLocalPW
        onlyAdmin
        visible
        onCorrect={onVerifyAdminSuccess}
        onClose={handleClose}
        v2
      />}
    </>
  );
};

export default CustomersBodyDisplay;
const CustomersBodyDisplayStyled = styled(TableGreenEvenStyled)`
  padding: 0 1.5rem;

&.v2 {
  align-self: stretch;
  padding: 0;
  .ant-table-wrapper .ant-table {
    .ant-table-thead th.ant-table-cell, .ant-table-thead td.ant-table-cell {
      height: 48px;
      padding: 0px 8px;
      gap: 10px;
      align-self: stretch;
      border-bottom: 1px solid #CCD4DC;
      background: #6FABB6;
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      text-align: left !important;
      border-right: 1.5px solid #CCD4DC;
    }

    .ant-table-tbody td.ant-table-cell {
      .BODY_1, .CONTENT_2, .ant-typography {
        color: #1D2129;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: left !important;
      }
    }

    .ant-table-tbody .ant-table-row:nth-child(even) td {
      background: #E4F4F6;
      border-bottom: 1px solid #E4F4F6;
    }
  }

  .ant-table-wrapper .ant-pagination {
    position: relative;
    border: 0;
    .ant-pagination-options {
      position: absolute;
      left: 0;
      top: 50%;
      margin: 0;
      transform: translateY(-50%);

      .ant-select.ant-pagination-options-size-changer {
        height: 40px;
        .ant-select-selector {
            padding: 0 20px;
        }
      }
      
      .ant-select .ant-select-arrow {
        color: #1D2129;
        font-size: 18px;
        transform: scaleX(1.1) scaleY(0.9);
      }
      .ant-select .ant-select-selector {
        background: #ffffff;
        border: 1px solid #86909C;
        .ant-select-selection-item-content {
          color: #1D2129;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      .ant-select .ant-select-selector {
        background: #ffffff;
        border: 1px solid #86909C;
        .ant-select-selection-placeholder, .ant-select-selection-search-input, .ant-select-selection-item {
          color: rgb(29, 33, 41);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
 
    }
  }

}
`;

const EllipsisText = styled(Typography.Text)`
  width: 6rem;
  color: var(--text-text-3, #1d2129);
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const ViewButtonStyled = styled.div<{ deleted?: boolean, v2?: boolean, point?: boolean }>`
  width: 3rem;
  display: flex;
  height: 28px;
  padding: 0.25rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  background: var(--info-infor-3, #6fabb6);
  ${({ deleted }) => deleted ? `
  background: #FF8890;
  ` : ''}
  ${({ point }) => point ? `
    background: #A8DEE8;
     .BODY_1 {
      color: #1D2129 !important;
      font-weight: 600 !important;
    }
  ` : ''}

  &:hover {
    cursor: pointer;
  }

  ${({ v2, deleted, point }) => v2 && `
    width: 3rem;
    display: flex;
    height: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: ${deleted ? '#FF8890' : '#6FABB6'};
    box-shadow: ${deleted ? '0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -4px 0px 0px #E7646D inset' : '0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -4px 0px 0px #144B55 inset'};

    .BODY_1 {
      color: #fff !important;
      font-weight: 600 !important;
    }
    ${point && `
      background: #A8DEE8;
      .BODY_1 {
        color: #1D2129 !important;
        font-weight: 600 !important;
      } 
    `}
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  `}
`;