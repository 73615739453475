import { Tabs, TabsProps } from 'antd';
import styled from 'styled-components';
import ProductsSetting from '../../Products';
import VIPSetting from '../../VIP';
import PaymentsTab from '../components/PaymentsTab';
import QuickPaySetting from '../components/QuickPaySetting';
import ServiceSettingV2 from '../components/ServicesSettingV2';
import SettingSimpleMenu from '../components/SettingSimpleMenu';
import TurnTab from '../components/TurnTab';
import TabUI, { TabUIContainer } from './TabUI';

const items: TabsProps['items'] = [
  {
    key: 'services',
    label: 'SERVICES',
    children: <ServiceSettingV2 v2 />,
  },
  {
    key: 'simple-menu',
    label: 'SIMPLE MENU',
    children: <SettingSimpleMenu v2 />,
  },
  {
    key: 'products',
    label: 'PRODUCTS',
    children: <ProductsSetting v2 />,
  },
  {
    key: 'VIP',
    label: 'VIP',
    children: <VIPSetting  v2 />,
  },
  {
    key: 'quick_pay',
    label: 'QUICK PAYS',
    children: <QuickPaySetting v2 />,
  },
  {
    key: 'turn',
    label: 'TURNS',
    children: <TurnTab v2 />,
  },
  {
    key: 'payments',
    label: 'General Configurations',
    children: <PaymentsTab v2 />,
  },
];
type Props = {
  activeKey: string;
  onChange: (key: string) => void;
};
const TabsV2 = ({ activeKey: tab, onChange }: Props) => {
  return (
    <>
      <TabUIContainer>
        <TabUI first zIndex={7} tab={tab} onChange={onChange} type='SERVICES' />
        <TabUI zIndex={6} tab={tab} onChange={onChange} type='SIMPLE_MENU' />
        <TabUI zIndex={5} tab={tab} onChange={onChange} type='PRODUCTS' />
        <TabUI zIndex={4} tab={tab} onChange={onChange} type='VIP' />
        <TabUI zIndex={3} tab={tab} onChange={onChange} type='QUICK_PAY' />
        <TabUI zIndex={2} tab={tab} onChange={onChange} type='TURN' />
        <TabUI zIndex={1} tab={tab} onChange={onChange} type='CONFIGURATION' />
      </TabUIContainer>
      <TicketOnTabsStyled>
        <Tabs renderTabBar={() => <></>} items={items} activeKey={tab} defaultActiveKey="CLOSED" onChange={onChange} />
      </TicketOnTabsStyled>
    </>
  );
};

export default TabsV2;
const TicketOnTabsStyled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  padding: 0;
  .ant-tabs {
    flex: 1;
    .ant-tabs-content-holder {
      flex: 1;
      .ant-tabs-content {
        height: 100%;
        .ant-tabs-tabpane {
          height: 100%;
          position: relative;
        }
      }
    }
  }
`;
