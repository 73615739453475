import styled from 'styled-components';

export const MenuTabs = styled.div`
display: flex;
padding: 16px;
flex-direction: column;
align-items: flex-start;
gap: 8px;
flex-shrink: 0;
min-width: 20rem;
.menu-tab-item {
  display: flex;
  height: 80px;
  padding: 0 10px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  align-self: stretch;
  &:hover {
    cursor: pointer;
    background: #eee;
  }
  span {
    color: #1D2129;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &.active {
    background: #DC5500;
    span {
      color: #fff;
    }
  }
}
`;

export const TurnTableStyled = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 10px;
flex: 1 0 0;
align-self: stretch;

table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 4px;
  overflow: hidden;

  border-radius: 4px;
  border: 1px solid #CCD4DC;
  background: #F6F7FC;

  thead tr th {
    border-bottom: 1px solid #CCD4DC;
    background: #E5E6EB;
    color: #505050;
    padding: 6px 0;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    text-align: left;
    padding-left: 16px;
  }

  tbody tr td {
    padding: 16px 8px;
    align-items: center;
    gap: 24px;
    align-self: stretch;

    .ant-input {
      color: #1D2129;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .percent-decimal-input-precision .ant-input {
      text-align: right;
    }
  }
}
.level-text {
  color: #1D2129;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 16px;
}
.btn-add-new {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: #6FABB6;
  box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -4px 0px 0px #144B55 inset;
  span {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
`;
