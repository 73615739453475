import { Form, Input } from 'antd';
import Box from 'components/Box';
import Text from 'components/Text';
import styled from 'styled-components';
import discount_delete_icon from '../../v2-icons/discount-delete.svg';
import add_new_icon from '../../v2-icons/add-new-icon.svg';
import ActionButtons from './ActionButtons';
import AmountFormItem from './AmountFormItem';
import PercentDecimalInputPrecision from 'components/PercentDecimalInputPrecision';

type FormV2Props = {
  handleStaffChange: (val: number | null, name: number, targetName: string) => void
};
const DiscountSpecialFormV2 = ({ handleStaffChange }: FormV2Props) => {
  return (
    <DiscountSpecialFormV2Styled bgColor="fill_1" p="3" borderRadius="5px">
      <Text variant="H9" color="text_3" className='section-title'>
        Special Discount
      </Text>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Amount</th>
            <th>Staff</th>
            <th>Company</th>
            <th></th>
          </tr>
        </thead>
        <Form.List name="special_discount">
          {(fields, { add, remove }) => (
            <tbody>
              {fields.map(({ key, name, ...fieldKey }) => (
                <tr key={key}>
                  <td>
                    <FormItemStyled
                      {...fieldKey}
                      name={[name, 'discountName']}
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: 'Please input your discount Name!',
                        },
                      ]}
                    >
                      <Input style={{ height: '3.5rem' }} placeholder="Discount name" />
                    </FormItemStyled>
                  </td>
                  <td>
                    <Form.Item label="Amount" noStyle>
                      <AmountFormItem v2 name={name} />
                    </Form.Item>
                  </td>
                  <td>
                    <FormItemStyled
                      name={[name, 'staffDiscountPercent']}
                      noStyle
                    >
                      <PercentDecimalInputPrecision maxValue={100} height={'3.5rem'}
                        onChange={(val) => {
                          handleStaffChange(
                            val,
                            name,
                            'ownerDiscountPercent'
                          );
                        }}
                      />
                    </FormItemStyled>
                  </td>
                  <td>
                    <FormItemStyled
                      name={[name, 'ownerDiscountPercent']}
                      noStyle
                    >
                      <PercentDecimalInputPrecision maxValue={100} height={'3.5rem'}
                        onChange={(val) => {
                          handleStaffChange(
                            val,
                            name,
                            'staffDiscountPercent'
                          );
                        }}
                      />
                    </FormItemStyled>
                  </td>
                  <td>
                    <button type='button' className="center" onClick={() => remove(name)} >
                      <img src={discount_delete_icon} alt='discount_delete_icon' style={{ width: 32, height: 32 }} />
                    </button>
                  </td>
                </tr>
              ))}
              <tr><td colSpan={5}>
                <button
                  type='button'
                  className='btn-add-new'
                  onClick={() =>
                    add({
                      discountType: 'MONEY',
                      discountValue: 0,
                      ownerDiscountPercent: 0,
                      staffDiscountPercent: 0,
                    })
                  }
                >
                  <img src={add_new_icon} alt="add_new_icon" style={{ width: 24, height: 24 }} />
                  <span>Add New</span>
                </button>
              </td></tr>
            </tbody>
          )}
        </Form.List>
      </table>
      <ActionButtons />
    </DiscountSpecialFormV2Styled>
  );
};

export default DiscountSpecialFormV2;

const FormItemStyled = styled(Form.Item)`
  margin: 0;
`;

const DiscountSpecialFormV2Styled = styled(Box)`
table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 4px;
  overflow: hidden;

  border-radius: 4px;
  border: 1px solid #CCD4DC;
  background: #F6F7FC;

  thead tr th {
    border-bottom: 1px solid #CCD4DC;
    background: #E5E6EB;
    color: #505050;
    padding: 6px 0;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }

  tbody tr td {
    padding: 16px 8px;
    align-items: center;
    gap: 24px;
    align-self: stretch;

    .ant-input {
      color: #1D2129;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .percent-decimal-input-precision .ant-input {
      text-align: right;
    }
  }
}

.btn-add-new {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: #6FABB6;
  box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -4px 0px 0px #144B55 inset;
  span {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
`;