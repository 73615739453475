import * as React from 'react';
import { SVGProps } from 'react';
const IcLink = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill={props?.fill || '#1E1E1E'}
      fillRule='evenodd'
      d='M12.516 3.07a5.958 5.958 0 0 1 8.424 8.424l-.015.015-2.825 2.825a5.958 5.958 0 0 1-8.984-.644 1.25 1.25 0 1 1 2.002-1.497 3.46 3.46 0 0 0 5.214.373L19.15 9.75a3.458 3.458 0 0 0-4.89-4.888l-1.613 1.603a1.25 1.25 0 1 1-1.763-1.773l1.62-1.61.013-.012ZM8.03 8.296a5.958 5.958 0 0 1 6.853 2.014 1.25 1.25 0 1 1-2.002 1.497 3.457 3.457 0 0 0-5.214-.373L4.852 14.25a3.458 3.458 0 0 0 4.888 4.89l1.602-1.603a1.25 1.25 0 1 1 1.768 1.768l-1.61 1.61-.015.015a5.958 5.958 0 0 1-8.425-8.424l.016-.015L5.9 9.666a5.959 5.959 0 0 1 2.131-1.37Z'
      clipRule='evenodd'
    />
  </svg>
);
export default IcLink;
