import { Form } from 'antd';
import AddATip, { ModalAddTipRef } from 'features/payment/pages/PaymentPage/CustomerSide/components/AddATip';
import { PaymentSocketData, TypePaymentActors, TypePaymentPassData } from 'features/payment/pages/PaymentPage/services/types/socketPayment';
import { useSocketContext } from 'hooks/useSocket';
import { debounce } from 'lodash';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { PAYMENT_PASS_DATA_TOPIC } from 'utils/socket';
import { MULTI_PAYMENT_TYPES } from './constants';
import CreditCardLoading, { ICreditPaymentLoadingRef } from 'features/payment/components/PaymentLoading/CreditCardLoading';
import Signature, { ISignatureRef } from 'features/payment/components/CustomerSignature';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import paymentApis from 'features/payment/services/apis';
import { removeBase64Prefix } from 'utils/removeBase64Prefix';
import { IFormMultiPaymentValues } from './helpers';
import useSMSEmailSignature from 'features/payment/hooks/useSMSEmailSignature';
import multiplePaymentSelectors from 'features/payment/pages/PaymentPage/services/selectors';
import { Message } from 'stompjs';
import storage from 'utils/sessionStorage';
import { IBillDetailData } from 'features/payment/services/types/bill';
type Props = {
  value?: number;
  onChange?: (value: number) => void;
  billId?: string;
  onCCCompleted: (ignorePrint?: boolean) => void;
  onCreditCardCancel: () => void;
};
type Ref = {
  requestTip: (values: IFormMultiPaymentValues, totalCC: number) => void;
  openCCLoading: (totalCC: number) => void;
  closeCCLoading: () => void;
  requestSignature: () => void;
  closeSignature: () => void;
};
export const useCreditCardPaymentHandlerRef = () => useRef<Ref>(null);
const CreditCardPaymentHandler = forwardRef<Ref, Props>(({ onChange: setTip = () => undefined, billId, onCCCompleted, onCreditCardCancel }, ref) => {
  const masterForm = Form.useFormInstance();
  const socketContext = useSocketContext();
  const tipBeforeRef = useRef<ModalAddTipRef>(null);
  const creditCardLoadingRef = useRef<ICreditPaymentLoadingRef>(null);
  const signatureRef = useRef<ISignatureRef>(null);
  const setLoadingPage = useSetLoadingPage();
  const [customerPhone, setCustomerPhone] = useState('');
  const [detail, setDetail] = useState<IBillDetailData | null>(null);
  const SMSEmailSignature = useSMSEmailSignature();

  const cashierMsg = multiplePaymentSelectors.getCashierSocketMsg();
  const listeningData = (message: Message) => {
    if (!message.body) return;
    const payment: PaymentSocketData = JSON.parse(message.body);
    if (!payment) return;

    if (payment.shopId !== storage.shop_id.get()) return;

    if (payment.actor !== TypePaymentActors.CUSTOMER) return;


    switch (payment.action) {
      case TypePaymentPassData.COMPLETED_PAYMENT: {
        onCCCompleted();
        break;
      }
      case TypePaymentPassData.COMPLETED_PAYMENT_WITHOUT_RECEIPT: {
        onCCCompleted(true);
        break;
      }
      case TypePaymentPassData.TIP_BEFORE_PAYMENT_SEND_TIP_AFTER_TYPING: {
        tipBeforeRef.current?.setSoftAmount(payment.data?.tip || 0);
        break;
      }
      case TypePaymentPassData.TIP_BEFORE_PAYMENT_SEND_TIP_VALUE: {
        handleAddATipBefore(payment.data?.tip || 0);
        break;
      }
      case TypePaymentPassData.ON_CUSTOMER_SIGNATURE_CHANGE: {
        signatureRef.current?.setValue(payment.data);
        break;
      }
      default:
        break;
    }
  };
  useEffect(() => {
    if (!cashierMsg) return;
    listeningData(cashierMsg);
  }, [cashierMsg]);

  const requestTip: Ref['requestTip'] = (values, totalCC) => {
    setCustomerPhone(values.detail.customerPhone);
    setDetail(values.detail || null);
    tipBeforeRef.current?.openWithSpecificTotal(totalCC);
  };

  const requestSignature: Ref['requestSignature'] = () => {
    signatureRef.current?.open();
  };

  const handleChangeTip = debounce((val: number) => {
    const billId = masterForm.getFieldValue(['detail', 'billId']);
    socketContext.send(PAYMENT_PASS_DATA_TOPIC, {
      billId,
      actor: TypePaymentActors.CASHIER,
      action: TypePaymentPassData.TIP_BEFORE_PAYMENT_SEND_TIP_AFTER_TYPING,
      data: { tip: val },
    });
  }, 500);


  const handleAddATipBefore = async (val: number) => {
    tipBeforeRef.current?.close();
    setTip(val);
    masterForm.setFieldValue('isValid_' + MULTI_PAYMENT_TYPES.CARD, true);
    setTimeout(() => masterForm.submit(), 100);
  };

  const onSkipAddATipBefore = () => handleAddATipBefore(0);

  const onSignatureDone = async (signatureValue: string) => {
    const billId = masterForm.getFieldValue(['detail', 'billId']);
    if (!billId) return;
    signatureRef.current?.close();
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await paymentApis.updateSignature(billId || '', removeBase64Prefix(signatureValue));
      if (res.data.data) {
        onCCCompleted();
        return;
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };


  useImperativeHandle(ref, () => ({
    requestTip,
    openCCLoading: (total) => creditCardLoadingRef.current?.open(total, 120000),
    closeCCLoading: () => creditCardLoadingRef.current?.close(),
    requestSignature,
    closeSignature: () => signatureRef.current?.close(),
  }));

  return (
    <>
      <AddATip
        ref={tipBeforeRef}
        amountCalcTip={(detail?.total || 0)}
        onAddATip={handleAddATipBefore}
        onChangeTip={handleChangeTip}
        onSkipAddATip={onSkipAddATipBefore}
      />
      <CreditCardLoading ref={creditCardLoadingRef} />
      <Signature
        ref={signatureRef}
        onAgree={onSignatureDone}
        onNoReceipt={SMSEmailSignature.onNoReceipt(billId || '', () => onCCCompleted(true))}
        onEmail={SMSEmailSignature.onEmail(billId || '', () => onCCCompleted(true))}
        onSMS={SMSEmailSignature.onSMS(billId || '', () => onCCCompleted(true))}
        defaultSMSPhone={customerPhone}
      />
    </>
  );
});
CreditCardPaymentHandler.displayName = 'CreditCardPaymentHandler';
export default CreditCardPaymentHandler;