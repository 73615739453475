import { InputRef, Popover, Row } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Button from 'components/Button';
import { CurrencyInputRef } from 'components/CurrencyInputMasked';
import Form, { FormItem, useForm } from 'components/Form';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { useTicketWrapper } from 'features/ShopWrapperLayout/TicketWrapperContext';
import PaymentLoading, { IPaymentLoadingRef } from 'features/payment/components/PaymentLoading';
import CheckUI from 'features/payment/pages/PaymentPage/CashierSide/components/CheckOthers/CheckPayment/CheckUI';
import InfoForm from 'features/payment/pages/PaymentPage/CashierSide/components/CheckOthers/CheckPayment/InfoForm';
import multiplePaymentActions from 'features/payment/pages/PaymentPage/services/actions';
import { ICustomerReceiveConfigs, PaymentSocketData, TypePaymentActors, TypePaymentPassData } from 'features/payment/pages/PaymentPage/services/types/socketPayment';
import paymentApis from 'features/payment/services/apis';
import { OTHER_PAYMENT, PAYMENT_TYPE } from 'features/payment/services/constants';
import { IBodyAPIPayment } from 'features/payment/services/types/api';
import { useSocketContext } from 'hooks/useSocket';
import moment from 'moment';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IResponseDataBody } from 'services/response';
import shopSelectors from 'services/shop/selectors';
import { Message } from 'stompjs';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import { PAYMENT_PASS_DATA_TOPIC } from 'utils/socket';
import useGetDetailTicket from '../useGetDetailTicket';
import useUpdateTicket from '../useUpdateTicket';
import multiplePaymentSelectors from 'features/payment/pages/PaymentPage/services/selectors';
import ConfirmTipTicket, { useConfirmTipTicketRef } from 'features/payment/components/ConfirmTipTicket';
import cashierSelectors from 'features/cashier/services/selectors';
import { IBillDetailData } from 'features/payment/services/types/bill';
import { parseDecimal } from 'features/cashier/services/constants';
import { ISaveApiBodyType } from 'features/cashier/services/types/api';
import cashierApis from 'features/cashier/services/apis';
import { debounce } from 'lodash';
import CashierActionButton from '../CashierActionBtn';
import clsx from 'clsx';
import ModalGiftCredit, { useModalGiftCreditRef } from '../ModalGiftCredit';
import { ICheckPayment } from 'features/payment/services/types/payment';

export interface IFormCheckInfoValues {
  name: string;
  address: string;
  phone: string;
  no: string;
  dlInformation: string;
  companyName: string;
  amount: number;
  bankName: string;
  bankFor: string;
  routingNumber: string;
  accountNumber: string;
}

export interface IFormCheckSubmitValues {
  name: string;
  address: string;
  phone: string;
  dlInformation: string;
  no: string;
  currentDate: string;
  companyName: string;
  amount: number;
  bankName: string;
  bankFor: string;
  signImg: string;
  bankNum: string;
  routingNumber: string;
  accountNumber: string;
}
type Props = {
  v2?: boolean;
};
const ButtonCheckOthers = ({ v2 }: Props) => {
  const currencyInputRef = useRef<CurrencyInputRef>(null);
  const inputPayForRef = useRef<InputRef>(null);
  const loadingRef = useRef<IPaymentLoadingRef>(null);
  const [formInput] = useForm();
  const [formCheckNote] = useForm();
  const socketContext = useSocketContext();
  const [visibleFormCheck, setVisibleFormCheck] = useState(false);
  const [visibleCheckNote, setVisibleCheckNote] = useState(false);
  const [visiblePopover, setVisiblePopover] = useState(false);
  const payments = shopSelectors.data.orderPaymentMethods();
  const allSetting = shopSelectors.data.allSetting();
  const navigate = useNavigate();
  const completedTicketModalRef = useConfirmTipTicketRef();
  const giftCreditRef = useModalGiftCreditRef();

  const onlyCheck = useMemo(() => payments.length === 1 && !!payments.find(o => o.methodType === 'CHECK'), [payments]);

  const dispatch = useAppDispatch();
  const updateTicket = useUpdateTicket();
  const ticketContext = useTicketWrapper();
  const [detailTicket, getDetailTicket] = useGetDetailTicket();
  const noReceipt = useRef(false);

  const cashierMsg = multiplePaymentSelectors.getCashierSocketMsg();

  const paymentInfo = cashierSelectors.getPaymentInfo();
  const discountSetting = shopSelectors.data.discountSetting();
  const enableCashIncentive = cashierSelectors.enableCashIncentive();

  const handleClose = () => {
    setVisibleFormCheck(false);
    setVisibleCheckNote(false);
    formInput.resetFields();
    formCheckNote.resetFields();
    const screen = `/store/${storage.shop_id.get()}/check-in/sign-in`;
    socketContext.switchCustomerScreen(screen);
  };

  const formInfoSubmitValidation = async () => {
    try {
      await formInput.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  };
  const onFormInfoSubmit = async () => await formInfoSubmitValidation() && formInput.submit();

  const listeningData = (message: Message) => {
    if (!message.body) return;
    const payment: PaymentSocketData = JSON.parse(message.body);
    if (!payment) return;

    if (payment.shopId !== storage.shop_id.get()) return;

    if (payment.actor !== TypePaymentActors.CUSTOMER) return;


    switch (payment.action) {
      case TypePaymentPassData.SEND_INFO_SIGNATURE_CHECK: {
        formCheckNote.setFieldValue('signImg', payment.data);
        dispatch(multiplePaymentActions.setCashierMsg.fetch(null));
        break;
      }
      default:
        break;
    }
  };

  const getDiscount = (subTotal: number) => {
    if (!discountSetting) return 0;
    const { discountType, discountValue } = discountSetting;
    if (discountType === 'PERCENT') {
      return Math.round(subTotal * (discountValue / 100));
    }
    if (discountType === 'MONEY')
      return parseDecimal(discountValue);
    return 0;
  };

  const checkCashIncentiveValid = (): boolean => {
    if (!discountSetting) return false;
    if (!discountSetting?.enableCashIncentive) return false;
    if (!enableCashIncentive) return false;
    return true;
  };

  const calcTotal = (detail: IBillDetailData): number => {
    if (!checkCashIncentiveValid()) return detail.total;
    return parseDecimal(detail.total - getDiscount(detail.subTotal));
  };

  useEffect(() => {
    if (!cashierMsg) return;
    listeningData(cashierMsg);
  }, [cashierMsg]);

  const handleFormInfoSubmit = (values: IFormCheckInfoValues) => {
    if (!detailTicket) return;

    setVisibleFormCheck(false);
    const data: IFormCheckSubmitValues = {
      name: values.name,
      address: values.address,
      phone: values.phone,
      dlInformation: values.dlInformation,
      no: values.no,
      currentDate: moment().format('MM/DD/YYYY'),
      companyName: values.companyName,
      amount: values.amount,
      bankName: values.bankName,
      bankFor: values.bankFor,
      bankNum: values.no + ',' + values.routingNumber + ',' + values.accountNumber,
      signImg: '',
      accountNumber: values.accountNumber,
      routingNumber: values.routingNumber,
    };
    formCheckNote.setFieldsValue(data);

    const body: PaymentSocketData = {
      shopId: storage.shop_id.get(),
      billId: detailTicket?.billId || '',
      actor: TypePaymentActors.CASHIER,
      action: TypePaymentPassData.REQUEST_SIGNATURE_CHECK,
      data,
    };
    socketContext.send(PAYMENT_PASS_DATA_TOPIC, body);
    setVisibleCheckNote(true);
  };

  const onClickItem = (type: OTHER_PAYMENT) => async () => {
    setVisiblePopover(false);
    try {
      const rest: { billId: string } | null = await updateTicket();
      if (!rest?.billId) return;

      switch (type) {
        case OTHER_PAYMENT.CHECK: {
          const _detailTicket = await getDetailTicket(rest.billId);
          if (!_detailTicket) return;
          const screen = `/store/${storage.shop_id.get()}/ticket/payment/customer-side/${_detailTicket.billId}`;
          socketContext.switchCustomerScreen(screen);
          formInput.resetFields();
          const finalTotal = calcTotal(_detailTicket);

          formInput.setFieldsValue({
            name: _detailTicket?.customerName,
            phone: _detailTicket?.customerPhone,
            amount: finalTotal,
          });
          setVisibleFormCheck(true);
          setTimeout(() => {
            if (checkCashIncentiveValid()) {
              const configs: ICustomerReceiveConfigs = {
                enableCashIncentive: true
              };
              socketContext.send(PAYMENT_PASS_DATA_TOPIC, {
                billId: rest.billId,
                actor: TypePaymentActors.CASHIER,
                action: TypePaymentPassData.CUSTOMER_RECEIVE_CONFIGS,
                data: configs,
              });
              dispatch(multiplePaymentActions.customerSide.setReceiveConfigs(configs));
            }
            currencyInputRef.current?.setValue((finalTotal || 0)?.toFixed(2), true);
            inputPayForRef.current?.focus();
          }, 100);
          break;
        }
        case OTHER_PAYMENT.GIFT_CREDIT: {
          const _detailTicket = await getDetailTicket(rest.billId);
          if (!_detailTicket) return;
          socketContext.switchCusPayment(_detailTicket.billId);
          giftCreditRef.current?.open(_detailTicket);
          break;
        }
        default: {
          socketContext.switchCustomerScreen(`/store/${storage.shop_id.get()}/ticket/qr-payment/customer-side/${type}/${rest.billId}`);
          navigate(`/store/${storage.shop_id.get()}/ticket/qr-payment/${type}/${rest.billId}`);
          break;
        }
      }

    } catch (error) {

    }
  };
  const updateTicketWithCheckIncentive = async () => {
    if (!discountSetting || !detailTicket) throw 'fail';
    const discount = detailTicket.discountTicket + getDiscount(detailTicket.subTotal);
    const total = calcTotal(detailTicket);
    const _paymentInfo: ISaveApiBodyType = {
      ...paymentInfo,
      total,
      cashIncentiveDiscount: true,
      discount,
      discountSetting: { ownerPaidPercent: 100, staffPaidPercent: 0 },
    };
    const res: IResponseDataBody<{ billId: string }> = await cashierApis.editTicket(_paymentInfo);
    const resData = res?.data?.data;
    if (!resData) throw 'fail';
  };

  const handleSubmitFormCheck = debounce(async (values: IFormCheckSubmitValues) => {
    if (!detailTicket?.billId) return;
    const amount = +values.amount || 0;
    const finalTotal = calcTotal(detailTicket);
    const checkValue: ICheckPayment = {
      amount,
      otherMethod: OTHER_PAYMENT.CHECK,
      otherCheckInfo: {
        dlInformation: values.dlInformation,
        address: values.address,
        bankName: values.bankName,
        payTo: values.companyName,
        checkNumber: values.no,
        accountNumber: values.accountNumber,
        forValue: values.bankFor,
        routingNumber: values.routingNumber,
      },
    };

    if (amount < finalTotal) {
      dispatch(multiplePaymentActions.setDraftMultiPayment({ [PAYMENT_TYPE.CHECK]: checkValue }));
      dispatch(multiplePaymentActions.setActivePayment(PAYMENT_TYPE.CASH));
      navigate(`/store/${storage.shop_id.get()}/ticket/payment/cashier-side/${detailTicket.billId}`);
      return;
    }

    loadingRef.current?.setVisible(true);

    if (checkCashIncentiveValid()) {
      await updateTicketWithCheckIncentive().catch(() => loadingRef.current?.setVisible(false));
    }

    const body: IBodyAPIPayment = {
      billId: detailTicket?.billId,
      paymentInfo: [
        {
          paymentType: PAYMENT_TYPE.CHECK,
          ...checkValue,
        },
      ]
    };

    try {
      const res: IResponseDataBody<true> = await paymentApis.payment(body);
      if (res?.data?.data) {
        loadingRef.current?.setVisible(false);
        socketContext.switchCustomerScreen(`/store/${storage.shop_id.get()}/ticket/payment/customer-side/rating/${detailTicket.billId}`);
        const callback = () => {
          if (noReceipt.current) {
            ticketContext.doPrintNoReceipt(detailTicket?.billId, true);
            navigate(`/store/${storage.shop_id.get()}`);
            return;
          }
          ticketContext.completedTicket(detailTicket?.billId, true);
        };
        if (!completedTicketModalRef.current) callback();
        else {
          setVisibleFormCheck(false);
          setVisibleCheckNote(false);
          completedTicketModalRef.current.open(detailTicket?.billId || '', callback);
        }
      } else {
        loadingRef.current?.setVisible(false);
      }
    } catch (error) {
      loadingRef.current?.setVisible(false);
    }
  }, 100);

  const Contents = () => {
    return (
      <Box className="center" gap="4" flexDirection="column">
        {payments &&
          payments?.map((item) => (
            <button key={item.id} disabled={!item.active} onClick={onClickItem(item.methodType as OTHER_PAYMENT)}>
              <Box
                className="space-between"
                gap="4"
                b="info_3"
                bgColor="info_bg_3"
                p="4"
                width="12rem"
                borderRadius="5px"
                opacity={!item.active ? 0.5 : 1}
              >
                <Text variant="CONTENT_1" color="text_3">
                  {item?.methodName}
                </Text>
                <Icon type="arrowForwardOutline" />
              </Box>
            </button>
          ))}
      </Box>
    );
  };

  return (
    <div style={{ alignSelf: 'stretch', display: 'flex' }}>
      {onlyCheck ? <CashierActionButton v2UI={v2} onClick={onClickItem(OTHER_PAYMENT.CHECK)} feature='CHECK' ntype='ORANGE_LIGHT' label={'Check'} /> :
        <Popover
          placement={'top'}
          title=""
          content={<Contents />}
          trigger="click"
          arrow={false}
          open={visiblePopover}
          onOpenChange={setVisiblePopover}
        >
          <CashierActionButton v2UI={v2} feature='CHECK' ntype='ORANGE_LIGHT' label={'E-Pays'} />
        </Popover>
      }
      <ModalGiftCredit ref={giftCreditRef} />
      <Modal
        visible={visibleFormCheck}
        modalTitle='CHECK'
        onClose={handleClose}
        onSubmit={onFormInfoSubmit}
        containerPadding={v2 ? 1 : undefined}
        noneBodyStyle={v2}
        noneFooterStyle={v2}
        v2={v2}
        className={clsx('modal-form-input-check', v2 && 'modal-non-opacity modal-bg-v2')}
        width={'80vw'}
      >
        <Form form={formInput} onFinish={handleFormInfoSubmit}>
          <InfoForm v2={v2} inputRef={inputPayForRef} currencyRef={currencyInputRef} allSetting={allSetting} />
        </Form>
      </Modal>
      <Modal
        visible={visibleCheckNote}
        onClose={handleClose}
        footer={<></>}
        noneBodyStyle
        noneFooterStyle
        hiddenHeader
        className='modal-check'
        width={'auto'}
      >
        <BoxContainer>
          <Form form={formCheckNote} onFinish={handleSubmitFormCheck} >
            <CheckUI isCashier />
            <FormItem noStyle shouldUpdate>
              {() => {
                return (
                  <Buttons align={'middle'} justify={'center'}>
                    <Button ntype='SECONDARY' onClick={handleClose}>Cancel</Button>
                    <Button ntype='DARK' onClick={() => {
                      noReceipt.current = true;
                      formCheckNote.submit();
                    }}>No Receipt</Button>
                    <Button ntype='PRIMARY' icon='print' onClick={() => {
                      noReceipt.current = false;
                      formCheckNote.submit();
                    }}>Print</Button>
                  </Buttons>
                );
              }}
            </FormItem>
          </Form>
        </BoxContainer>
      </Modal>
      <PaymentLoading ref={loadingRef} />
      <ConfirmTipTicket ref={completedTicketModalRef} />
    </div>
  );
};

export default memo(ButtonCheckOthers);

const BoxContainer = styled.div`
  background-color: #E3F1E0;
  max-width: 85.5rem;
  width: 90vw;
  padding: 1rem;
`;

const Buttons = styled(Row)`
margin-top: 1.5rem;
margin-bottom: 0.5rem;
gap: 1.5rem;
button {
  width: 8.25rem;
}
`;

