import React from 'react';
import { styled } from 'styled-components';
import Icon from 'assets/Icons';

interface CurrencyInputPrecisionNumpadProps {
  value?: number;
  onInput: (input: string) => void;
  onClear: () => void;
  disabled?: boolean;
}

const CurrencyInputPrecisionNumpad: React.FC<
  CurrencyInputPrecisionNumpadProps
> = ({ onInput, onClear, disabled = false }) => {
  const handleButtonClick = (input: string) => {
    if (disabled) return;

    if (input === 'clear') {
      onClear();
    } else {
      onInput(input);
    }
  };

  return (
    <NumpadContainer>
      <NumpadGrid>
        <NumberPadButton
          onClick={() => handleButtonClick('1')}
          className='NUMBER-PAD'
        >
          1
        </NumberPadButton>
        <NumberPadButton
          onClick={() => handleButtonClick('2')}
          className='NUMBER-PAD'
        >
          2
        </NumberPadButton>
        <NumberPadButton
          onClick={() => handleButtonClick('3')}
          className='NUMBER-PAD'
        >
          3
        </NumberPadButton>

        <NumberPadButton
          onClick={() => handleButtonClick('4')}
          className='NUMBER-PAD'
        >
          4
        </NumberPadButton>
        <NumberPadButton
          onClick={() => handleButtonClick('5')}
          className='NUMBER-PAD'
        >
          5
        </NumberPadButton>
        <NumberPadButton
          onClick={() => handleButtonClick('6')}
          className='NUMBER-PAD'
        >
          6
        </NumberPadButton>

        <NumberPadButton
          onClick={() => handleButtonClick('7')}
          className='NUMBER-PAD'
        >
          7
        </NumberPadButton>
        <NumberPadButton
          onClick={() => handleButtonClick('8')}
          className='NUMBER-PAD'
        >
          8
        </NumberPadButton>
        <NumberPadButton
          onClick={() => handleButtonClick('9')}
          className='NUMBER-PAD'
        >
          9
        </NumberPadButton>

        <NumberPadButton
          onClick={() => handleButtonClick('clear')}
          className='clear NUMBER-PAD'
        >
          <Icon type="removeAll"> </Icon>
        </NumberPadButton>
        <NumberPadButton
          onClick={() => handleButtonClick('0')}
          className='NUMBER-PAD'
        >
          0
        </NumberPadButton>
        <NumberPadButton
          className='backspace'
          onClick={() => handleButtonClick('delete')}
        >
          <BackspaceIcon />
        </NumberPadButton>
      </NumpadGrid>
    </NumpadContainer>
  );
};

const BackspaceIcon: React.FC = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='41'
    height='40'
    viewBox='0 0 41 40'
    fill='none'
  >
    <path
      d='M12.3551 10H37.0834V30H12.3551L4.58337 20L12.3551 10Z'
      fill='#86909C'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M29.2693 14.1418C29.7575 14.6299 29.7575 15.4214 29.2693 15.9095L19.1123 26.0665C18.6241 26.5547 17.8327 26.5547 17.3445 26.0665C16.8564 25.5784 16.8564 24.7869 17.3445 24.2988L27.5015 14.1418C27.9897 13.6536 28.7812 13.6536 29.2693 14.1418Z'
      fill='#86909C'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.3445 14.1418C17.8327 13.6536 18.6241 13.6536 19.1123 14.1418L29.2693 24.2988C29.7575 24.7869 29.7575 25.5784 29.2693 26.0665C28.7812 26.5547 27.9897 26.5547 27.5015 26.0665L17.3445 15.9095C16.8564 15.4214 16.8564 14.6299 17.3445 14.1418Z'
      fill='#86909C'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M29.2693 14.1418C29.7575 14.6299 29.7575 15.4214 29.2693 15.9095L19.1123 26.0665C18.6241 26.5547 17.8327 26.5547 17.3445 26.0665C16.8564 25.5784 16.8564 24.7869 17.3445 24.2988L27.5015 14.1418C27.9897 13.6536 28.7812 13.6536 29.2693 14.1418Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.3445 14.1418C17.8327 13.6536 18.6241 13.6536 19.1123 14.1418L29.2693 24.2988C29.7575 24.7869 29.7575 25.5784 29.2693 26.0665C28.7812 26.5547 27.9897 26.5547 27.5015 26.0665L17.3445 15.9095C16.8564 15.4214 16.8564 14.6299 17.3445 14.1418Z'
      fill='white'
    />
  </svg>
);

const NumpadContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const NumpadGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
`;

const NumberPadButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 6px;
  background: #fcfcfd;
  box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.4),
    0px 4px 8px -3px rgba(45, 35, 66, 0.3), 0px -3px 0px 0px #d6d6e7 inset;
  border: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;

  &.NUMBER-PAD {
    color: #1d2129;
    font-family: Poppins, sans-serif;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &.clear,
  &.backspace {
    background: #e5e6eb;
  }

  &:hover {
    cursor: pointer;
  }

  &:focus-visible {
    outline: 4px solid #fff0f0;
    outline-offset: 1px;
    transition: outline-offset 0s, outline 0s;
  }
`;

export default CurrencyInputPrecisionNumpad;
