import { Avatar } from 'antd/lib';
import IcEven from 'assets/Icons/IcEven';
import IcManual from 'assets/Icons/IcManual';
import IcPercent from 'assets/Icons/IcPercent';
import Tick from 'assets/Icons/tick';
import CurrencyInputPrecision from 'components/CurrencyInputPrecision';
import Modal from 'components/Modal';
import Text from 'components/Text';
import paymentActions from 'features/payment/services/actions';
import paymentApis from 'features/payment/services/apis';
import paymentSelectors from 'features/payment/services/selectors';
import {
  IBodyApiSplitTipPayment,
  StaffWorkItem,
} from 'features/payment/services/types/api';
import { cloneDeep, last, set, sumBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import { useAppDispatch } from 'store/hooks';
import { styled } from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import GroupButtonFooterModal, { BtnProps } from '../GroupButtonFooterModal';
import CurrencyInputPrecisionNumpad from './BtnTipMethodSysModal.Numpad';

interface IModalTipMethodSystem {}

const enum ETipMethod {
  percent = 'Percent',
  even = 'Even',
  manual = 'Manual',
}

const ModalTipMethodSystem: React.FC<IModalTipMethodSystem> = ({}) => {
  const data = paymentSelectors.getData();
  const isOpen = paymentSelectors.getVisibleShareTip();
  const dispatch = useAppDispatch();
  const setPageLoading = useSetLoadingPage();
  const [staffWorkList, setListStaffWork] = useState<StaffWorkItem[]>([]);
  const [primevalStaffWorkList, setListPrimevalStaffWork] = useState<
    StaffWorkItem[]
  >([]);
  const [method, setMethod] = useState<ETipMethod>(ETipMethod.percent);
  const totalTip = data?.tip ?? 0;
  const [activeStaffId, setActiveStaffId] = useState<string | null>(null);
  const [keypadValue, setKeypadValue] = useState<number>(0);

  const handleClose = () => {
    dispatch(paymentActions.setVisibleShareTip(false));
    setMethod(ETipMethod.percent);
    setListStaffWork([]);
    setListPrimevalStaffWork([]);
    setActiveStaffId(null);
    setKeypadValue(0);
  };

  const handleApplyMethod = async () => {
    setPageLoading(true);
    const payload: IBodyApiSplitTipPayment = {
      billId: data?.billId || '',
      items: staffWorkList.map((o) => ({
        staffId: o.staffId,
        tip: o.tip,
      })),
    };
    try {
      const res: IResponseDataBody<boolean> = await paymentApis.splitTip(
        payload
      );
      if (res.data.data) {
        handleClose();
      }
    } catch (error) {
    } finally {
      setPageLoading(false);
    }
  };

  const handleClear = () => {
    if (method === ETipMethod.manual) {
      setActiveStaffId(null);
      setKeypadValue(0);
      const resetList = staffWorkList.map((staff) => ({
        ...staff,
        tip: 0,
        isInput: false,
      }));
      setListStaffWork(resetList);
    }
  };

  useEffect(() => {
    if (!data) return;
    const dataList = data?.items?.map(
      (o) =>
        ({
          staffId: o.staffId,
          name: o.staffName,
          price: sumBy(o.services, (a) => a.price),
          tip: 0,
          isInput: false,
          avatar: o.avatar,
        } as StaffWorkItem)
    );
    setListStaffWork(dataList);
    setListPrimevalStaffWork(dataList);
  }, [data]);

  useEffect(() => {
    if (activeStaffId) {
      const staff = staffWorkList.find((s) => s.staffId === activeStaffId);
      if (staff) {
        setKeypadValue(staff.tip || 0);
      }
    }
  }, [activeStaffId, staffWorkList]);

  const btnProps: BtnProps[] = [
    {
      buttonName: 'Close',
      onClick: () => handleClose(),
      type: 'DEFAULT',
    },
    {
      buttonName: 'Clear',
      onClick: () => handleClear(),
      type: 'LIGHT_BLUE',
    },
    {
      buttonName: 'Split',
      onClick: handleApplyMethod,
      type: 'PRIMARY',
      disabled:
        +sumBy(staffWorkList, (o) => o.tip || 0).toFixed(2) > totalTip ||
        +sumBy(staffWorkList, (o) => o.tip || 0).toFixed(2) == 0,
    },
  ];

  const sumPrice = useMemo(() => {
    return staffWorkList.reduce(
      (previousSum, item) => item.price + previousSum,
      0
    );
  }, [staffWorkList]);

  const isDisableInput = useMemo(() => {
    return method !== ETipMethod.manual;
  }, [method]);

  const computeTip = useCallback(() => {
    let target: StaffWorkItem[] = [];
    switch (method) {
      case ETipMethod.even: {
        let remainingTip = totalTip;

        target = staffWorkList.map((item) => {
          let staffTip = +(totalTip / staffWorkList.length).toFixed(2);

          if (remainingTip - staffTip <= 0) {
            staffTip = remainingTip;
          }

          remainingTip = +(remainingTip - staffTip).toFixed(2);

          return {
            ...item,
            tip: staffTip,
            isInput: false,
          };
        });

        if (remainingTip > 0) {
          const lastItem = last(target);
          if (lastItem) set(lastItem, 'tip', lastItem.tip + remainingTip);
        }

        break;
      }

      case ETipMethod.manual:
        target = staffWorkList.map((item) => {
          return {
            ...item,
            tip: item.tip || 0,
            isInput: item.isInput || false,
          };
        });
        break;
      case ETipMethod.percent: {
        let remainingTip = totalTip;
        target = staffWorkList.map((item) => {
          let staffTip = +((item.price / sumPrice) * totalTip).toFixed(2);

          if (remainingTip - staffTip <= 0) {
            staffTip = remainingTip;
          }

          remainingTip = +(remainingTip - staffTip).toFixed(2);
          return {
            ...item,
            tip: staffTip,
            isInput: false,
          };
        });
        if (remainingTip > 0) {
          const lastItem = last(target);
          if (lastItem) set(lastItem, 'tip', lastItem.tip + remainingTip);
        }
      }
    }
    setListStaffWork(target);
  }, [method, primevalStaffWorkList, totalTip, staffWorkList, sumPrice]);

  const handleChangeValue = (staffId: string, value: number) => {
    const cloneStaffWorkList = cloneDeep(staffWorkList);

    if (
      cloneStaffWorkList.filter((item) => item.isInput).length ===
      cloneStaffWorkList.length
    ) {
      for (let i = 0; i < cloneStaffWorkList.length; i++) {
        cloneStaffWorkList[i].isInput = false;
      }
    }
    const targetChange = cloneStaffWorkList.find(
      (item) => item.staffId === staffId
    );

    if (targetChange) {
      targetChange.tip = +value;
      targetChange.isInput = true;
      const sumAllInput: number = cloneStaffWorkList
        .filter((item) => item.isInput)
        .reduce((sum, item) => sum + item.tip, 0);
      const theRestTip = totalTip - sumAllInput;

      const sumPriceWithoutInput: number = cloneStaffWorkList
        .filter((item) => !item.isInput)
        .reduce((sum, item) => sum + item.price, 0);

      setListStaffWork(
        cloneStaffWorkList.map((item) => {
          if (item.staffId !== staffId && !item.isInput) {
            const tipValue = (item.price / sumPriceWithoutInput) * theRestTip;
            return {
              ...item,
              tip: tipValue > 0 ? tipValue : 0,
            };
          }
          return item;
        })
      );
    }
  };

  useEffect(() => {
    computeTip();
  }, [method, primevalStaffWorkList]);

  const handleChangeMethod = (value: ETipMethod) => {
    setMethod(value);
    setActiveStaffId(null);
    setKeypadValue(0);
  };

  const handleKeypadInput = (input: string) => {
    if (!activeStaffId) return;

    let currentValueInCents = Math.round(keypadValue * 100);

    if (input === 'delete') {
      currentValueInCents = Math.floor(currentValueInCents / 10);
    } else if (!isNaN(Number(input))) {
      currentValueInCents = currentValueInCents * 10 + parseInt(input);

      if (currentValueInCents > 1000000000) {
        currentValueInCents = 1000000000;
      }
    }

    const newValue = currentValueInCents / 100;

    setKeypadValue(newValue);

    if (activeStaffId) {
      handleChangeValue(activeStaffId, newValue);
    }
  };

  useEffect(() => {
    if (activeStaffId) {
      handleChangeValue(activeStaffId, keypadValue);
    }
  }, [keypadValue]);

  const handleStaffSelect = (staffId: string) => {
    if (method === ETipMethod.manual) {
      setActiveStaffId(staffId);
      const staff = staffWorkList.find((s) => s.staffId === staffId);
      if (staff) {
        setKeypadValue(staff.tip || 0);
      }
    }
  };

  const shareTipItem = (item: StaffWorkItem, index: number) => {
    const isActive = activeStaffId === item.staffId;

    return (
      <StaffCardContainer
        key={item.staffId}
        onClick={() => handleStaffSelect(item.staffId)}
        className={isActive ? 'active' : ''}
      >
        <CurrencyInputPrecision
          onChange={(val) => {
            handleChangeValue(item.staffId, val);
          }}
          value={item.tip?.toFixed(2)}
          disabled={isDisableInput}
          className={`staff-tip-input ${isActive ? 'active' : ''}`}
        />
        <div className='staff-info'>
          <Avatar
            size={52}
            src={item.avatar}
            style={{
              backgroundColor: !item.avatar ? '#1890ff' : undefined,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '24px',
              fontWeight: 600,
              marginTop: 10,
            }}
          >
            {!item.avatar && item.name.charAt(0)}
          </Avatar>
          <div className='staff-details'>
            <StaffName>{item.name}:</StaffName>
            <StaffPrice>{formatCurrency(item.price)}</StaffPrice>
          </div>
        </div>
      </StaffCardContainer>
    );
  };

  const renderItem = useMemo(
    () => staffWorkList.map((item, index) => shareTipItem(item, index)),
    [staffWorkList, activeStaffId]
  );

  return (
    <TipMethodSysModalStyled
      footer={<GroupButtonFooterModal buttonSequence={btnProps} />}
      modalTitle='TIP METHOD SYSTEM'
      closable={false}
      visible={isOpen}
      onClose={handleClose}
      width={'auto'}
      noneBodyStyle
      centered
      v2
    >
      <div className='tip-method-container'>
        <div id='total-tip'>
          <Text className='alignment' variant='H4' color='text_2'>
            Total Tip:
          </Text>
          <Text variant='H1'>{`${formatCurrency(totalTip)}`}</Text>
        </div>
        <MethodButtonGroup>
          <MethodButton
            $active={method === ETipMethod.percent}
            onClick={() => handleChangeMethod(ETipMethod.percent)}
            style={{ flex: 1 }}
          >
            {method === ETipMethod.percent && (
              <CheckIcon>
                <Tick fill='#232F3E' />
              </CheckIcon>
            )}
            <IcPercent
              fill={method === ETipMethod.percent ? '#fff' : '#232F3E'}
            />{' '}
            Percent
          </MethodButton>

          <MethodButton
            $active={method === ETipMethod.even}
            onClick={() => handleChangeMethod(ETipMethod.even)}
            style={{ flex: 1 }}
          >
            {method === ETipMethod.even && (
              <CheckIcon>
                <Tick fill='#232F3E' />
              </CheckIcon>
            )}
            <IcEven fill={method === ETipMethod.even ? '#fff' : '#232F3E'} />{' '}
            Even
          </MethodButton>

          <MethodButton
            $active={method === ETipMethod.manual}
            onClick={() => handleChangeMethod(ETipMethod.manual)}
            style={{ flex: 1 }}
          >
            {method === ETipMethod.manual && (
              <CheckIcon>
                <Tick fill='#232F3E' />
              </CheckIcon>
            )}
            <IcManual
              fill={method === ETipMethod.manual ? '#fff' : '#232F3E'}
            />
            Manual
          </MethodButton>
        </MethodButtonGroup>
        <StaffInputGrid>{renderItem}</StaffInputGrid>
        {method === ETipMethod.manual && (
          <div style={{ width: '100%' }}>
            <CurrencyInputPrecisionNumpad
              onInput={handleKeypadInput}
              onClear={() => {
                setKeypadValue(0);
                if (activeStaffId) {
                  handleChangeValue(activeStaffId, 0);
                }
              }}
              disabled={!activeStaffId}
            />
          </div>
        )}
      </div>
    </TipMethodSysModalStyled>
  );
};

export default ModalTipMethodSystem;

const TipMethodSysModalStyled = styled(Modal)`
  min-width: 800px;
  #status {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
  }
  #total-tip {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 0.75rem;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .alignment {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .tip-method-container {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    row-gap: 0.5rem;
  }
  .share-tip-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .share-tip-item .name-price-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 0.25rem;
    margin-right: 1rem;
  }

  .share-tip-item .ant-input {
    width: 8.625rem;
    color: var(--text-text-3, #1d2129);
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    &.ant-input-disabled {
      color: var(--text-text-3, #1d2129);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .share-tip-item-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .share-tip-item-container .ant-divider {
    margin: 1rem 0 0.5rem 0;
  }

  .tip-method-container .ant-radio-group {
    width: 100%;
    justify-content: space-between;
    display: flex;
  }

  .radio-text {
    font-size: 1.5rem;
  }

  .fs-lg {
    font-size: 2rem;
  }

  .fs-md {
    font-size: 1.5rem;
  }
  .share-tip-item .ant-input {
    font-size: 1.5rem !important;
  }
`;
const StaffCardContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background: #f8f9fc;
  box-shadow: 0px -3px 0px 0px #d6d6e7 inset, 0px 4px 8px -3px #2d23424d,
    0px 2px 4px 0px #2c234266;
  padding-top: 16px;
  padding-right: 24px;
  padding-bottom: 16px;
  padding-left: 24px;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 250px;
  position: relative;

  .staff-info {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 12px;
  }

  .staff-details {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }

  .staff-tip-input {
    border-radius: 8px;
    border: 1px solid #cfd3e2;
    padding: 12px;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    background: white;
    width: 100%;
    transition: all 0.2s ease;

    &.active {
      box-shadow: 0 0 0 2px #ff8890;
    }
    &[disabled] {
      color: black !important;
    }
  }
`;

const StaffName = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: #1d2129;
`;

const StaffPrice = styled(StaffName)`
  margin-left: 10px;
`;

const MethodButtonGroup = styled.div`
  display: flex;
  padding: 0px 10px;
  width: calc(100% - 16px);
  gap: 8px;
  margin-bottom: 16px;
`;

const MethodButton = styled.div<{ $active: boolean }>`
  padding: 12px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
  position: relative;
  font-size: 24px;
  font-weight: 500;

  background-color: ${(props) => (props.$active ? '#1f2937' : '#d1d5db')};
  color: ${(props) => (props.$active ? 'white' : '#374151')};

  box-shadow: ${(props) =>
    props.$active ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none'};

  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${(props) => (props.$active ? '#1f2937' : '#c1c5cc')};
  }
`;

const CheckIcon = styled.div`
  position: absolute;
  top: -12px;
  right: -6px;
  width: 24px;
  height: 24px;
  z-index: 9;
  border-color: white;
  border-width: 2px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 2px rgba(255, 255, 255, 0.776);
`;

const StaffInputGrid = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  overflow-x: auto;
`;
