import { Form, Switch } from 'antd';
import styled from 'styled-components';
import caution_icon from './icons/caution.svg';
import PassValueForm from './PassValueForm';
import { IFormMultiPaymentValues } from './helpers';
import { formatCurrency } from 'utils/formatCurrency';

const CardFeeDiscount = () => {
  return (
    <CardFee>
      <img src={caution_icon} alt="caution_icon" style={{ width: 32, height: 32 }} />
      <Form.Item noStyle name={'enableCardFee'}><SwitchFee label={
        <PassValueForm
          name={'cardFeeLabel'}
          render={(cardFeeLabel: IFormMultiPaymentValues['cardFeeLabel']) => <>Card Fee: {cardFeeLabel?.percent ? `${cardFeeLabel.percent}% (${formatCurrency(cardFeeLabel.amount)})` : formatCurrency(cardFeeLabel?.amount)}</>}
        />
      } /></Form.Item>
      <Form.Item noStyle name={'enableIncentive'}><SwitchFee label={
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const incentivePercent = getFieldValue('incentivePercent');
            const cashIncentiveValue = getFieldValue('cashIncentiveValue');
            const checkIncentiveValue = getFieldValue('checkIncentiveValue');
            const incentive = cashIncentiveValue + checkIncentiveValue;
            return <>Cash/Check Incentive: {incentivePercent ? `${incentivePercent}% (${formatCurrency(incentive)})` : formatCurrency(incentive)}</>;
          }}
        </Form.Item>
      } /></Form.Item>
    </CardFee>
  );
};

export default CardFeeDiscount;
const SwitchFee = ({ value, onChange = () => undefined, label }: { label?: any, value?: boolean, onChange?: (value?: boolean) => void }) => {
  return <SwitchStyled>
    <Switch checked={value} onChange={onChange} />
    <span className='prevent-select'>{label}</span>
  </SwitchStyled>;
};

const SwitchStyled = styled.label`
  display: flex;
  align-items: center;
  gap: 16px;
  &:hover {
    cursor: pointer;
  }
  .ant-switch {
    transform: scale(1.5);
  }
  span {
    color: #1D2129;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

const CardFee = styled.div`
margin-top: 16px;
display: flex;
height: 49px;
justify-content: center;
align-items: center;
gap: 32px;
align-self: stretch;
background: #FFF1F6;
`;
