import { Layout } from 'antd';
import Sidebar from 'components/layout/Sidebar';
import styled from 'styled-components';
import TableControl from './TableControl';
import StaffTable from '../components/StaffTable';

const StaffsSettingV2 = () => {
  return (
    <Container>
      <Sidebar />
      <ContentContainer>
        <TableControl />
        <StaffTableStyled className='StaffTableStyled' v2 />
      </ContentContainer>
    </Container>
  );
};

export default StaffsSettingV2;

const Container = styled(Layout)` 
    min-height: 100vh;
`;

const StaffTableStyled = styled(StaffTable)` &.StaffTableStyled {
  align-self: stretch;


  .ant-table-wrapper .ant-table .ant-table-content{
    .ant-table-thead th.ant-table-cell, .ant-table-thead td.ant-table-cell {
      height: 48px;
      padding: 0px 8px;
      gap: 10px;
      align-self: stretch;
      border-bottom: 1px solid #CCD4DC;
      background: #6FABB6;
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      text-align: left !important;
      border-right: 1.5px solid #CCD4DC;
      &::before {
        width: 1.5px !important;
        height: 100%  !important;
      }
    }

    .ant-table-tbody td.ant-table-cell {
      height: 48px;
      .BODY_1, .CONTENT_2 {
        color: #1D2129;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .ViewButtonStyled {
        width: 5.5rem;
        display: flex;
        height: 40px;
        padding: 0.25rem 1rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 4px;
        background: #6FABB6;
        box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -4px 0px 0px #144B55 inset;
        .BODY_1 {
          color: #fff;
          text-align: center;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        &.deleted {
          background: #FF8890;
          box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -4px 0px 0px #E7646D inset;
        }
      }
    }

    .ant-table-tbody .ant-table-row:nth-child(even) td {
      background: #E4F4F6;
      border-bottom: 1px solid #E4F4F6;
    }
  }


  .ant-table-wrapper .ant-pagination {
    position: relative;
    .ant-pagination-options {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);

      .ant-select.ant-pagination-options-size-changer {
        height: 40px;
        .ant-select-selector {
            padding: 0 20px;
        }
      }
      
      .ant-select .ant-select-arrow {
        color: #1D2129;
        font-size: 18px;
        transform: scaleX(1.1) scaleY(0.9);
      }
      .ant-select .ant-select-selector {
        background: #ffffff;
        border: 1px solid #86909C;
        .ant-select-selection-item-content {
          color: #1D2129;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      .ant-select .ant-select-selector {
        background: #ffffff;
        border: 1px solid #86909C;
        .ant-select-selection-placeholder, .ant-select-selection-search-input, .ant-select-selection-item {
          color: rgb(29, 33, 41);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
 
    }
  }


}
`;

const ContentContainer = styled.div`
display: flex;
flex: 1;
flex-direction: column;
align-items: flex-start;
gap: 16px;
padding: 16px;
background: #F6F7FC;
`;
