import React from 'react';
import styled from 'styled-components';
import check_icon from '../../v2-icons/check-icon.svg';

type MyRadioProps = {
  value?: boolean;
  onChange?: (val: boolean) => void;
  label?: string;
  style?: React.CSSProperties;
};
const MyRadio = ({ style, label, value, onChange = () => undefined }: MyRadioProps) => {
  return (
    <MyRadioStyled style={style} type='button' className={value ? 'active' : ''} onClick={() => onChange(!value)}>
      <div className='radio-box'>
        {value && <img src={check_icon} alt="check_icon" style={{ height: 30, width: 30 }} />}
      </div>
      <span>{label}</span>
    </MyRadioStyled>
  );
};

export default MyRadio;

const MyRadioStyled = styled.button`
display: flex;
align-items: center;
gap: 16px;
.radio-box {
  border-radius: 100px;
  border: 1px solid #86909C;
  background: #F5F5F5;
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  zoom: 1.2;
}
&.active .radio-box {
  border: 1px solid #2D6772;
  background: #2D6772;
}
span {
  color: #1D2129;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;  
}
`;