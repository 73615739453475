import * as React from 'react';
import { SVGProps } from 'react';
const IcGiftBox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={80}
    height={81}
    viewBox='0 0 80 80'
    fill='none'
    {...props}
  >
    <g fill={props?.fill || '#808836'} clipPath='url(#giftbox123)'>
      <path d='M52.883 20.501c-.395-.197-1.086-.394-1.974-.197-.593.197-1.284.493-1.777.986-.494.492-.987.886-1.481 1.38l-3.85 3.448h7.897c1.185-.098 2.271-.788 2.666-1.872.592-1.281-.099-3.055-1.481-3.745Z' />
      <path d='M67 28.187c-.395-2.365-2.27-5.124-5.528-5.814-.198-2.167-1.086-4.237-2.567-5.813-3.357-3.745-9.28-4.336-13.327-1.183-.889.789-1.777 1.577-2.567 2.267l-.198.197c-1.184-.395-2.369-.592-3.553-.493-.691 0-1.481.197-2.172.493-.889-.789-1.975-1.774-3.06-2.562l-.1-.099c-2.27-1.577-5.133-2.168-7.897-1.478a9.55 9.55 0 0 0-6.515 4.927c-.593 1.182-.889 2.463-.988 3.744-3.159.592-5.034 3.35-5.43 5.814l-.098 6.8.395.788c.494.985 1.382 2.07 2.567 2.76v22.959c0 3.153 2.566 5.814 5.725 5.814h35.54c1.777-.099 3.455-.887 4.738-2.267a6.645 6.645 0 0 0 1.777-4.927V38.633c1.284-.69 2.172-1.774 2.666-2.76l.395-.788v-6.11l.197-.788Zm-30.011 35.18h-.099c-1.48 0-2.961-.1-4.442-.1h-9.576c-.691 0-1.382-.295-1.974-.886-.593-.591-.889-1.38-.79-2.168V39.224h17.079v24.142h-.198Zm22.212-1.085c-.493.592-1.283.887-1.974.986-2.37.098-4.64 0-7.01 0H43.011V39.125H60.09v20.99c0 .788-.297 1.576-.889 2.167Zm3.85-28.182s0 .098-.099.197H17.047c-.098-.099-.098-.197-.098-.197v-5.223c.197-.985.987-2.463 2.468-2.562.395 0 .79-.098 1.184-.098h2.172l-.296-2.168c-.197-1.281 0-2.464.593-3.45.69-1.477 2.171-2.561 3.948-3.054 1.678-.394 3.357-.099 4.64.788 1.283.986 2.468 2.07 3.752 3.252l1.085.986 1.284-.69c.69-.394 1.283-.591 1.777-.591l.099-.099c.79-.098 1.678.099 2.566.591l1.284.69 2.171-2.07 2.37-2.069c2.27-1.773 5.923-1.478 7.897.789 1.185 1.28 1.679 2.956 1.481 4.73l-.296 2.168H59.3c.395 0 .79 0 1.184.098 1.481.197 2.271 1.478 2.468 2.562v5.42h.1Z' />
      <path d='M31.264 21.782c-.297-.197-.494-.492-.79-.69l-.099-.098-.099-.099-.099-.098-.098-.099c-.691-.492-1.678-.69-2.567-.394-.987.296-1.678.986-1.974 1.872-.297.789-.198 1.774.296 2.563a2.928 2.928 0 0 0 2.172 1.379H36.1l-3.85-3.449c-.296-.296-.691-.591-.987-.887Z' />
    </g>
    <defs>
      <clipPath id='giftbox123'>
        <path fill='#fff' d='M13 13.308h54v54H13z' />
      </clipPath>
    </defs>
  </svg>
);
export default IcGiftBox;
