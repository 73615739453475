import { Form } from 'antd';
import Box from 'components/Box';
import { useDollarAmountInputRef } from 'components/DollarAmount/Input';
import Modal from 'components/Modal';
import NumberInputPayment from 'components/NumberInputPayment';
import NumberAmountKeyboard from 'components/NumberInputPayment/Keyboard';
import Text from 'components/Text';
import customerApis from 'features/customer/services/apis';
import { customerUIActions } from 'features/customer/services/reducers';
import { ICustomer } from 'features/customer/services/types/api';
import { delay } from 'lodash';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { IResponseDataBody } from 'services/response';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatNumber } from 'utils/formatCurrency';
const FormStyled = styled(Form)`
.dollar-input-selector {
  margin: 0 16px;
  align-self: stretch;
  height: 5rem;
  justify-content: center;
  border: none;
  .dollar-input-text {
    font-size: 72px;
    color: #1D2129;
    font-weight: 600;
  }
}
`;
type Props = {
  mutate?: () => void;
};
type Ref = {
  open: (data: ICustomer) => void;
};
export const useModalUpdatePointRef = () => useRef<Ref>(null);
const ModalUpdatePoint = forwardRef<Ref, Props>(({ }, ref) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const setLoadingPage = useSetLoadingPage();
  const onOpen: Ref['open'] = (data) => {
    form.resetFields();
    form.setFieldValue('point', data.point || 0);
    form.setFieldValue('customerId', data.id || '');
    delay(() => dollarRef.current?.updateInputWidth(), 100);
    delay(() => dollarRef.current?.focus(formatNumber(data.point)), 200);
    setOpen(true);
  };
  const handleFinish = async (values: any) => {
    const body: { customerId: string, point: number } = values;
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<ICustomer> = await customerApis.updatePoint(body.customerId, body.point || 0);
      if (res.data.data) {
        dispatch(customerUIActions.updateCusListItem(res.data.data));
        onClose();
      }
    } catch (error) {
    } finally {
      setLoadingPage(false);
    }

  };
  const dollarRef = useDollarAmountInputRef();

  useImperativeHandle(ref, () => ({
    open: onOpen
  }));

  return (
    <Modal
      visible={open}
      modalTitle='UPDATE POINTS'
      showClose
      okTitle={'OK'}
      noneFooterStyle
      className='modal-overflow-unset modal-non-opacity'
      containerPadding={0}
      onClose={onClose}
      onSubmit={() => form.submit()}
      v2
      noneBodyStyle
      width={'1000px'}
    >
      <FormStyled form={form} onFinish={handleFinish}>
        <Box display='flex' flexDirection='column' gap='2'>
          <Form.Item noStyle name={'customerId'} />
          <Text className='modal-keyboard-label' mt={1} mb={-0.5}>
            Enter Point:
          </Text>
          <Form.Item noStyle name='point' rules={[{ required: true }]}>
            <NumberInputPayment
              ref={dollarRef}
              inputMode='none'
            />
          </Form.Item>
          <Form.Item noStyle name='point' rules={[{ required: true }]}>
            <NumberAmountKeyboard
              controlRef={dollarRef}
            />
          </Form.Item>
        </Box>
      </FormStyled>
    </Modal>
  );
});
ModalUpdatePoint.displayName = 'ModalUpdatePoint';
export default ModalUpdatePoint;