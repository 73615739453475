import React, { useMemo } from 'react';
import { ContentBoxStyled } from './styles';
import cashierSelectors from 'features/cashier/services/selectors';
import Text from 'components/Text';
import { Row } from 'antd';
import Icon from 'assets/Icons';
import { useAppDispatch } from 'store/hooks';
import cashierActions from 'features/cashier/services/actions';
import { formatCurrency } from 'utils/formatCurrency';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import ButtonAddCustomer from './AddCustomer';
import ReferralInput from './ReferralInput';
import { ICustomerReferralData } from 'features/cashier/services/types/customer';
import { cashierUIActions } from 'features/cashier/services/reducers';
type Props = {
  v2?: boolean;
};
const CustomerInfo = ({ v2 }: Props) => {
  const dispatch = useAppDispatch();
  const customerInfo = cashierSelectors.getSelectedCustomer();
  const customerReferral = cashierSelectors.customerReferral();
  const loyaltySetting = shopSelectors.data.getLoyaltySetting();
  const enableReferral = cashierSelectors.enableReferral();

  const saveCustomerReferral = (_customerReferral: ICustomerReferralData) => {
    dispatch(cashierUIActions.saveCustomerReferral(_customerReferral));
  };

  const handleRemoveCustomer = () => dispatch(cashierActions.removeSelectedCustomer());
  const onClearCustomerReferral = () => dispatch(cashierUIActions.saveCustomerReferral(null));
  const dollar = useMemo(() => {
    if (!loyaltySetting) return 0;

    return +(customerInfo?.point || '') / loyaltySetting?.oneDollarToPoint;
  }, [customerInfo, loyaltySetting]);


  if (v2) {
    if (!customerInfo?.customerId || !enableReferral) return (
      <CustomerStyled style={{ marginTop: 0, marginBottom: '0.5rem' }}>
        <ContentBoxStyled className='info-group'>
          <Text variant="CONTENT_2" color="text_3" style={{ fontWeight: '900', fontSize: '1.2rem' }}>
            {customerInfo?.customerName || 'UNKNOWN CUSTOMER'}
          </Text>
          <Row align={'middle'}>
            {loyaltySetting?.active && customerInfo?.customerId && <Text variant="CONTENT_2" mr={1} style={{ fontWeight: '900', fontSize: '1.5rem', color: '#F5767F' }}>
              {customerInfo?.point || 0} points = {formatCurrency(dollar)}
            </Text>}
            <button onClick={handleRemoveCustomer}><Icon type="changeCustomer" fill='#F5767F' /></button>
          </Row>
        </ContentBoxStyled>
      </CustomerStyled>
    );
    return (
      <CustomerStyled style={{ marginTop: 0, marginBottom: '0.5rem' }}>
        <ContentBoxStyled className='info-group referral'>
          <div>
            <Row align={'middle'} style={{ alignItems: 'center', gap: 8 }}>
              <Text variant="CONTENT_2" color="text_3" style={{ fontWeight: '900', fontSize: '1.4rem' }}>
                {customerInfo?.customerName || 'UNKNOWN CUSTOMER'}
              </Text>
              <button onClick={handleRemoveCustomer}><Icon type="changeCustomer" fill='#D2464F' /></button>
            </Row>
            {loyaltySetting?.active && customerInfo?.customerId && <Text variant="CONTENT_2" mr={1} style={{ fontSize: '1.2rem', color: '#D2464F' }}>
              {customerInfo?.point || 0} points = {formatCurrency(dollar)}
            </Text>}
          </div>
          {customerReferral?.id ? (
            <ContainerReferral onClick={onClearCustomerReferral}>
              <Text className='referral-text'>
                Referred by
              </Text>
              <Text className='customer-name'>
                {customerReferral.name || '--'}
              </Text>
            </ContainerReferral>
          ) : (
            <ReferralInput
              customerId={customerInfo?.customerId || ''}
              onSaveCustomer={saveCustomerReferral}
            />
          )}
        </ContentBoxStyled>
      </CustomerStyled>
    );
  }

  return (
    <CustomerStyled>
      <Row className='add-group'>
        <AccountIcon />
        <ButtonAddCustomer short />
      </Row>
      <ContentBoxStyled className='info-group'>
        <Text variant="CONTENT_2" color="text_3" style={{ fontWeight: '900', fontSize: '1.2rem' }}>
          {customerInfo?.customerName || 'UNKNOWN CUSTOMER'}
        </Text>
        <Row align={'middle'}>
          {loyaltySetting?.active && customerInfo?.customerId && <Text variant="CONTENT_2" mr={1} style={{ fontWeight: '900', fontSize: '1.5rem', color: '#F5767F' }}>
            {customerInfo?.point} points = {formatCurrency(dollar)}
          </Text>}
          <button onClick={handleRemoveCustomer}><Icon type="changeCustomer" fill='#F5767F' /></button>
        </Row>
      </ContentBoxStyled>
    </CustomerStyled>
  );
};

export default CustomerInfo;
const ContainerReferral = styled.div`
  padding: 0 4px;
  .referral-text {
    color: #2D6772;
    text-align: right;
    font-family: Poppins;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .customer-name {
    color: #2D6772;
    font-family: Poppins;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    text-transform: capitalize;
    text-align: right;
  }

  &:hover, &:active {
    cursor: pointer;
    background:rgba(152, 217, 231, 0.4);
    border-radius: 4px;
  }
`;
const CustomerStyled = styled(Row)`
  width: 100%;
  margin-top: 1rem;
  .add-group {
    background: #fff;
    padding: 0.5rem 1rem;
    gap: 1rem;
  }
  .info-group {
    background: #FFEEE4;
    flex: 1;
    padding: 0.5rem 1rem;
    &.referral {
      background: #FFF1F6;
    }
  }
`;

const AccountIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.65233 20.6194C7.90257 19.3691 9.59826 18.6667 11.3664 18.6667H22.033C23.8012 18.6667 25.4968 19.3691 26.7471 20.6194C27.9973 21.8696 28.6997 23.5653 28.6997 25.3334V28.0001C28.6997 28.7365 28.1028 29.3334 27.3664 29.3334C26.63 29.3334 26.033 28.7365 26.033 28.0001V25.3334C26.033 24.2726 25.6116 23.2551 24.8615 22.505C24.1113 21.7548 23.0939 21.3334 22.033 21.3334H11.3664C10.3055 21.3334 9.28809 21.7548 8.53795 22.505C7.7878 23.2551 7.36637 24.2725 7.36637 25.3334V28.0001C7.36637 28.7365 6.76942 29.3334 6.03304 29.3334C5.29666 29.3334 4.69971 28.7365 4.69971 28.0001V25.3334C4.69971 23.5653 5.40209 21.8696 6.65233 20.6194Z" fill="#1D2129" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.6999 5.33341C14.4907 5.33341 12.6999 7.12428 12.6999 9.33341C12.6999 11.5426 14.4907 13.3334 16.6999 13.3334C18.909 13.3334 20.6999 11.5426 20.6999 9.33341C20.6999 7.12428 18.909 5.33341 16.6999 5.33341ZM10.0332 9.33341C10.0332 5.65152 13.018 2.66675 16.6999 2.66675C20.3818 2.66675 23.3665 5.65152 23.3665 9.33341C23.3665 13.0153 20.3818 16.0001 16.6999 16.0001C13.018 16.0001 10.0332 13.0153 10.0332 9.33341Z" fill="#1D2129" />
  </svg>
);
