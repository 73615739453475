import { Form } from 'antd';
import Button from 'components/Button';
import ModalEmail, { IModalEmailFuncFinish, useModalEmailRef } from 'components/ModalEmail';
import paymentApis from 'features/payment/services/apis';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import toast from 'utils/toast';

type Props = {
  billId?: string;
  defaultEmail?: string;
  small?: boolean;
  v2?: boolean;
  hook?: boolean;
};
type Ref = {
  open: () => void;
};
export const useButtonTicketSendEmailRef = () => useRef<Ref>(null);
const ButtonTicketSendEmail = forwardRef<Ref, Props>(({ hook, v2, billId, defaultEmail, small }, ref) => {
  const setLoadingPage = useSetLoadingPage();
  const [emailForm] = Form.useForm();
  const emailRef = useModalEmailRef();

  const onFormEmailFinish: IModalEmailFuncFinish = async (values) => {
    if (!billId) return;
    emailRef.current?.close();
    setLoadingPage(true);
    try {
      const res = await paymentApis.sendSMSEmailReceipt(billId, 'EMAIL', values.email);
      if (res.data.data) {
        toast.success(res.data?.message || 'Success');
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };
  const onOpen = () => emailRef.current?.open(defaultEmail || '');

  useImperativeHandle(ref, () => ({
    open: onOpen,
  }));

  return (
    <>
      {!hook && <Button cardCashier={v2} size={small ? 'small' : undefined} icon='email' ntype='LIGHT_BLUE' onClick={onOpen} >Email</Button>}
      <ModalEmail v2={v2} ref={emailRef} form={emailForm} onFinish={onFormEmailFinish} />
    </>
  );
});
ButtonTicketSendEmail.displayName = 'ButtonTicketSendEmail';
export default ButtonTicketSendEmail;
