
import { conformToMask } from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export interface PercentInputRef {
  focus: (str_value?: string) => void;
  init: (str_value?: string, unfocus?:boolean) => void;
  clear: () => void;
  getText: () => string;
}
export const dollarAmountNumberMask = createNumberMask({
  prefix: '',
  suffix: '%',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 1,
  allowNegative: false,
  allowLeadingZeroes: false,
});

export const parseInputValue = (valueProp?: number | null) => {
  const conformedPhoneNumber = conformToMask(
    valueProp === 0 ? '' : valueProp?.toString() || '',
    dollarAmountNumberMask,
    { guide: false }
  );
  const newValue = conformedPhoneNumber.conformedValue;
  return newValue;
};
export const parseStrInputValue = (valueProp?: string | null) => {
  const conformedPhoneNumber = conformToMask(
    valueProp || '',
    dollarAmountNumberMask,
    { guide: false }
  );
  const newValue = conformedPhoneNumber.conformedValue;
  return newValue;
};

export const parseOutputValue = (value: string) => {
  return parseFloat((+(value?.replaceAll('%', '')?.replaceAll('$', '')?.replaceAll(',', '') ?? '')).toFixed(1));
};

