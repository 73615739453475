/**
 * NOTICE:
 *  PLEASE CHANGE ABSOLUTELY YOUR FEATURE NAME HERE
 * ex:
 *  NAME_REDUCER = 'NAME_REDUCER'
 *   => 
 *  NAME_REDUCER = 'booking'
 */
export const NAME_REDUCER = 'report';

/**
 * NOTICE:
 *  PLEASE CHANGE ABSOLUTELY YOUR PREFIX ACTION NAME HERE
 * ex:
 *  PREFIX_ACTIONS = 'PREFIX_ACTIONS';
 *   => 
 *  PREFIX_ACTIONS = 'booking_feature_';
 */
export const PREFIX_ACTIONS = 'report_feature_';


export const PATH_LOADING = {
  getData: `loading.${NAME_REDUCER}.getData`,
  getSummarySalon: `loading.${NAME_REDUCER}.getSummarySalon`,
  getPrintSummarySalon: `loading.${NAME_REDUCER}.getPrintSummarySalon`,
  getSummaryServiceCategory: `loading.${NAME_REDUCER}.getSummaryServiceCategory`,
  getSummaryTicket: `loading.${NAME_REDUCER}.getSummaryTicket`,
  getTopRankingService: `loading.${NAME_REDUCER}.getTopRankingService`,
  getSummarySalaryStaff: `loading.${NAME_REDUCER}.getSummarySalaryStaff`,
  getSummaryDashboardSalaryStaff: `loading.${NAME_REDUCER}.getSummaryDashboardSalaryStaff`,
  getSummarySalarySalon: `loading.${NAME_REDUCER}.getSummarySalarySalon`,
  getSalaryStaffDetail: `loading.${NAME_REDUCER}.getSalaryStaffDetail`,
  getPrintSummaryServiceCategory: `loading.${NAME_REDUCER}.getPrintSummaryServiceCategory`,
  getDashboardTimeClockReport: `loading.${NAME_REDUCER}.getDashboardTimeClockReport`,
  getTimeClockReportHistory: `loading.${NAME_REDUCER}.getTimeClockReportHistory`,
  getPrintSalaryStaffDetailOneDay: `loading.${NAME_REDUCER}.getPrintSalaryStaffDetailOneDay`,
  getPrintSalaryStaffDetailRangeDate: `loading.${NAME_REDUCER}.getPrintSalaryStaffDetailRangeDate`,
  getPrintSalarySummaryStaffOneDay: `loading.${NAME_REDUCER}.getPrintSalarySummaryStaffOneDay`,
  getPrintSalarySummaryStaffRangeDate: `loading.${NAME_REDUCER}.getPrintSalarySummaryStaffRangeDate`,
  getPrintTimeClockRange: `loading.${NAME_REDUCER}.getPrintTimeClockRange`,
};
