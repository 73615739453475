import check_icon from './icons/check.svg';
import cash_app_icon from './icons/cashapp.svg';
import paypal_icon from './icons/paypal.svg';
import venmo_icon from './icons/venmo.svg';
import zelle_icon from './icons/zelle.svg';
import credit_card_icon from './icons/credit-card.svg';
import cash_icon from './icons/cash.svg';
import gift_card_icon from './icons/gift-card.svg';
import loyalty_point_icon from './icons/loyalty-point.svg';

export enum MULTI_PAYMENT_TYPES {
  CARD = 'CREDIT_CARD',
  CASH = 'CASH',
  CHECK = 'CHECK',
  VENMO = 'VENMO',
  CASH_APPS = 'CASH_APPS',
  ZELLE = 'ZELLE',
  PAYPAL = 'PAYPAL',
  GIFT_CARD = 'GIFT_CARD',
  GIFT_CREDIT = 'GIFT_CREDIT',
  POINT = 'LOYALTY_POINT',
}
export type KB_TYPES = MULTI_PAYMENT_TYPES | 'CHECK_NO' | 'GIFT_CODE' | 'GIFT_CREDIT_CODE';
export const multiPaymentConfigs = {
  [MULTI_PAYMENT_TYPES.CARD]: {
    label: 'Card',
    icon: credit_card_icon,
  },
  [MULTI_PAYMENT_TYPES.CASH]: {
    label: 'Cash',
    icon: cash_icon,
  },
  [MULTI_PAYMENT_TYPES.POINT]: {
    label: 'Point',
    icon: loyalty_point_icon,
  },
  [MULTI_PAYMENT_TYPES.GIFT_CARD]: {
    label: 'Gift Card',
    icon: gift_card_icon,
  },
  [MULTI_PAYMENT_TYPES.CHECK]: {
    label: 'Check',
    icon: check_icon,
  },
  [MULTI_PAYMENT_TYPES.VENMO]: {
    label: 'Venmo',
    icon: venmo_icon,
  },
  [MULTI_PAYMENT_TYPES.CASH_APPS]: {
    label: 'Cash App',
    icon: cash_app_icon,
  },
  [MULTI_PAYMENT_TYPES.PAYPAL]: {
    label: 'Paypal',
    icon: paypal_icon,
  },
  [MULTI_PAYMENT_TYPES.ZELLE]: {
    label: 'Zelle',
    icon: zelle_icon,
  },
  [MULTI_PAYMENT_TYPES.GIFT_CREDIT]: {
    label: 'Gift Credit',
    icon: gift_card_icon,
  },
};

export const PAYMENT_FORM_NAMES = Object.values(MULTI_PAYMENT_TYPES);