import styled from 'styled-components';

const SectionV2 = styled.div`
  .ant-form-item-label label {
    color: #1D2129;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .ant-input {
    color: #1D2129;
    font-family: Poppins;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }


  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #CCD4DC;
  background: #F6F7FC;

  .section-label {
    color: #1D2129;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
  }
`;

export default SectionV2;