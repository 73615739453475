import { Form } from 'antd';
import Box from 'components/Box';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { TurnTableStyled } from '../../SettingPage/v2/style';
import FromToInput from '../../SettingPage/components/QuickpayTab/FromToInput';
import PercentDecimalInputPrecision from 'components/PercentDecimalInputPrecision';
import discount_delete_icon from '../assets/delete.svg';
import add_new_icon from '../../SettingPage/components/v2-icons/add-new-icon.svg';
import Text from 'components/Text';
import settingServiceSelectors from 'features/settingService/services/selectors';
import { IApiUpdateTurnRangeSaleBodyItem, ITurnRangeSaleItem } from 'features/settingService/services/types/turn';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import settingApis from 'features/settingService/services/apis';
import { IResponseDataBody } from 'services/response';
import toast from 'utils/toast';
import { get } from 'lodash';
import Button from 'components/Button';

const TurnRange = () => {
  const data = settingServiceSelectors.getTurnSettingRangeSales();
  const [form] = Form.useForm();
  const setLoadingPage = useSetLoadingPage();
  useEffect(() => {
    form.setFieldValue('data', data || []);
  }, [data]);

  const onFinish = async (_values: any) => {
    const values = _values as { data: ITurnRangeSaleItem[] };
    setLoadingPage(true);
    const body: IApiUpdateTurnRangeSaleBodyItem[] = values.data.map(o => ({
      fromValue: o.fromValue || 0,
      toValue: o.toValue || 0,
      turn: o.turn || 0
    }));

    try {
      const res: IResponseDataBody<ITurnRangeSaleItem[]> = await settingApis.updateTurnRangeSales(body);
      if (res.data.data) {
        form.setFieldValue('data', res.data.data);
      }
    } catch (error) {
      const msg = get(error, 'response.data.message');
      toast.error(msg || '');
    } finally {
      setLoadingPage(false);
    }
  };

  return (
    <Container form={form} layout='vertical' onFinish={onFinish}>
      <TurnTableStyled>
        <table>
          <thead>
            <tr>
              <th>Range sale</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <Form.List name="data">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <tr key={key}>
                      <td>
                        <FromToInput
                          formListName="data"
                          name={name}
                        />
                      </td>
                      <td>
                        <Form.Item
                          {...restField}
                          name={[name, 'turn']}
                          noStyle
                        >
                          <PercentDecimalInputPrecision nonSuffix />
                        </Form.Item>
                      </td>
                      <td>
                        <button type='button' className="center" onClick={() => remove(name)} >
                          <img src={discount_delete_icon} alt='discount_delete_icon' style={{ width: 32, height: 32 }} />
                        </button>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={4}>
                      <button
                        type='button'
                        className='btn-add-new'
                        onClick={() => add()}
                      >
                        <img src={add_new_icon} alt="add_new_icon" style={{ width: 24, height: 24 }} />
                        <span>Add Range Sales</span>
                      </button>
                      <Text mb={1}></Text>
                    </td>
                  </tr>
                </>
              )}
            </Form.List>
          </tbody>
        </table>
      </TurnTableStyled>
      <Box className='actions-bottom' display="flex" gap="3" mt="4" justifyContent="end">
        <Button width="10rem" ntype="SECONDARY">
          Close
        </Button>
        <Form.Item>
          <Button width="10rem" ntype="PRIMARY" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Box>
    </Container>
  );
};

export default TurnRange;
const Container = styled(Form)`
  margin-top: 16px;
`;
