import { IBillWaitingTicketResItem, IStaffTicketResItem } from 'features/user/services/types/ticket';
import { find, get } from 'lodash';
import uiSelector from 'services/UI/selectors';
import shopSelectors from 'services/shop/selectors';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { v4 as uuid } from 'uuid';
import { DiscountType, ITEM_HANDLE_MODE, PATH_LOADING, QUICK_PAY_ID } from './constants';
import { ISaveApiBodyType } from './types/api';
import { formatCurrency } from 'utils/formatCurrency';
import { SERVICE_ITEM_TYPE } from './types/constant';
import { IStaffItemData } from 'services/shop/types/staff';
/**
 * please change reducer name
 */
// @ts-ignore
type MyState = RootState['cashier']; // ex: RootState['booking']
// @ts-ignore
const getCurrentState = (state: RootState): MyState => state['cashier']; // ex: state['booking']

const selector = <T = MyState>(key: keyof T, defaultValue?: any) =>
  useAppSelector((state) => get(getCurrentState(state), key, defaultValue));

const getSelectedItems = () => selector('selectedItems') as MyState['selectedItems'];
export const getPureSelectedItems = (root: RootState) => {
  const state = getCurrentState(root);
  return state.selectedItems;
};
const getActiveStaffId = () => selector('activeStaffId') as MyState['activeStaffId'];

const getTicketDetails = () =>
  selector('ticketDetails') as MyState['ticketDetails'];

const getCategories = () => shopSelectors.data.categoriesIncludeAddon();

const getProductCategories = () => shopSelectors.data.productCategories();

const getAddOnList = () => shopSelectors.data.addonServices();

const getStaffList = () => shopSelectors.data.staffsAvailable();

const getActiveCategory = () => selector('activeCategory') as MyState['activeCategory'];
const getActiveProductCategory = () => selector('activeProductCategory') as MyState['activeProductCategory'];

const getVisibleChooseCustomer = () => selector('visibleChooseCustomer') as MyState['visibleChooseCustomer'];

const getVisibleVIP = () => selector('visibleVIP') as MyState['visibleVIP'];

const getVisibleQuickPay = () => selector('visibleQuickPay') as MyState['visibleQuickPay'];

const getVisibleIssueGiftCard = () => selector('visibleIssueGiftCard') as MyState['visibleIssueGiftCard'];

const getVisibleModalWarningVIPPackage = () => selector('visibleModalWarningVIPPackage') as MyState['visibleModalWarningVIPPackage'];

const getVisibleModalWarningEmptyServices = () => selector('visibleModalWarningEmptyServices') as MyState['visibleModalWarningEmptyServices'];

const getVisibleChooseStaff = () => {
  const visibleCustomer = cashierSelectors.getVisibleChooseCustomer();
  const visibleStaff = selector('visibleChooseStaff') as MyState['visibleChooseStaff'];
  return !visibleCustomer ? visibleStaff : null;
};

const getDraftServiceSwitchStaff = () => selector('draftServiceSwitchStaff') as MyState['draftServiceSwitchStaff'];

const getDraftQuickPay = () => selector('draftQuickPay') as MyState['draftQuickPay'];

const getServicesByCateActive = () => {
  const cateActive = getActiveCategory();
  return cateActive;
};

const getProductsByProductCateActive = () => {
  const cateActive = getActiveProductCategory();
  return cateActive;
};

const getAvailableStaff = () => {
  const staffServices = getSelectedItems();
  const staffList = shopSelectors.data.staffsAvailable();

  return staffList
    .filter(st => !staffServices.find(o => o.staffId === st.staffId))
    .map((item) => ({
      ...item,
      value: item.staffId,
      label: item.staffName,
    }));
};


const getCategoryIdSelected = () => {
  const selectedItems = getSelectedItems();
  const categories = getCategories();

  return categories.filter((item) => {
    return !!selectedItems.find(o => {
      return o.services.some(o => item.services.find(se => se.id === o.itemId));
    });
  }).map(o => o.id);
};

const getServiceIdsSelected = () => {
  const selectedItems = getSelectedItems();
  const selected: { id: string, count: number }[] = [];
  selectedItems.map(o => {
    o.services.map(s => {
      const exist = selected.find(o => o.id === s.itemId);
      if (exist) {
        exist.count++;
      } else {
        selected.push({ id: s.itemId, count: 1 });
      }
    });
  });
  return selected;
};

const getActiveItemsCollapse = () => selector('activeItemsCollapse') as MyState['activeItemsCollapse'];

const getCustomerList = () => selector('customerList') as MyState['customerList'];

const getSelectedCustomer = () => selector('selectedCustomer') as MyState['selectedCustomer'];

const getEnableCardFee = () => selector('enableCardFee') as MyState['enableCardFee'];

const loading = {
  getDetail: () => uiSelector.getLoading(PATH_LOADING.getDetail),
  getCategories: () => uiSelector.getLoading(PATH_LOADING.getCategories),
  getAddOnList: () => uiSelector.getLoading(PATH_LOADING.getAddOnList),
  getStaffList: () => uiSelector.getLoading(PATH_LOADING.getStaffList),
  getCustomerList: () => uiSelector.getLoading(PATH_LOADING.getCustomerList),
};

const getTicketTotalInfo = () => selector('ticketTotalInfo') as MyState['ticketTotalInfo'];
const getItemHandleMode = () => selector('itemHandleMode') as MyState['itemHandleMode'];
const getItemModeVisible = (mode: ITEM_HANDLE_MODE) => {
  const _mode = selector('itemHandleMode') as MyState['itemHandleMode'];
  return _mode === mode;
};

const getDiscountTicket = () => selector('discountTicket') as MyState['discountTicket'];

const getCustomerPackage = () => selector('customerPackage') as MyState['customerPackage'];

const getDraftDiscountItemModal = () => selector('draftDiscountItemModal') as MyState['draftDiscountItemModal'];

const getDraftSupplyFeeItemModal = () => selector('draftSupplyFeeItemModal') as MyState['draftSupplyFeeItemModal'];

const getProductTax = () => selector('productTaxValue') as MyState['productTaxValue'];

export const getPaymentInfo = () => {
  const ticketDetails = getTicketDetails();
  const selectedItems = getSelectedItems();
  const customer = getSelectedCustomer();
  const ticketTotalInfo = getTicketTotalInfo();
  const enableCardFee = getEnableCardFee();

  const discountTicket = getDiscountTicket();

  const items = selectedItems.filter(o => o.staffId)?.map((item) => ({
    staffName: item.staffName,
    staffId: item.staffId,
    services: item.services
    .map((service) => {
      const isSimpleMenu = service.itemType === SERVICE_ITEM_TYPE.SIMPLE_MENU;
      return ({
        itemId: isSimpleMenu ? QUICK_PAY_ID : service.itemId,
        itemName: service.itemName,
        itemType: isSimpleMenu ? SERVICE_ITEM_TYPE.SERVICE : service.itemType,
        price: service.price,
        discount: service.discountType === DiscountType.PERCENT ? ((service.price ?? 0) * service.discount / 100) : service.discount ?? 0,
        note: service.note || '',
        duration: service.duration,
        discountSetting: service.discountSetting || null,
        expiredDate: service.expireDate || null,
        supplyFee: service.supplyFee,
      });
    }),
  }));
  const body: ISaveApiBodyType = {
    checkInId: ticketDetails?.checkInId || '',
    billId: ticketDetails?.ticketInfo.billId || '',
    customerId: customer?.customerId || '',
    couponId: discountTicket?.couponId || '',
    items,
    subTotal: ticketTotalInfo.subtotal,
    discount: ticketTotalInfo.discByTicket,
    discountSetting: discountTicket?.discountSetting || null,
    tax: 0,
    tip: ticketTotalInfo.tipTicket,
    total: ticketTotalInfo.total,
    chargeFee: enableCardFee,
  };
  return body;
};

export const checkChangeStateTicket = (root: RootState) => {
  const state = getCurrentState(root);
  const initial = state.initialDetail;
  const paymentInfo = getPurePaymentInfo(root);

  const oldItems = initial?.items?.map(o =>
    (o?.staffId || '') + o.services.map(s => (s.itemId || '') + ',' + (s.price || 0) + ',' + (s.discount || 0)).join(',')
  ).join(',');
  const newItems = paymentInfo?.items?.map(o =>
    (o?.staffId || '') + o.services.map(s => (s.itemId || '') + ',' + (s.price || 0) + ',' + (s.discount || 0)).join(',')
  ).join(',');

  const oldTicketTotalInfo = [
    +(initial?.ticketInfo?.subTotal || '') || 0,
    initial?.ticketInfo.tip || 0,
    initial?.ticketInfo.tax || 0,
    initial?.ticketInfo.discount || 0,
  ].join(',');

  const newTicketTotalInfo = [
    paymentInfo.subTotal || 0,
    paymentInfo.tip || 0,
    paymentInfo.tax || 0,
    paymentInfo.discount || 0,
  ].join(',');


  return !!([
    (initial?.customerInfo.customerId || '') !== (paymentInfo.customerId || ''),
    oldItems !== newItems,
    oldTicketTotalInfo !== newTicketTotalInfo,
  ]).find(o => o);
};

export const getPurePaymentInfo = (root: RootState) => {
  const state = getCurrentState(root);

  const ticketDetails = state.ticketDetails;
  const selectedItems = state.selectedItems;
  const customer = state.selectedCustomer;
  const ticketTotalInfo = state.ticketTotalInfo;
  const enableCardFee = state.enableCardFee;
  const discountTicket = state.discountTicket;

  const items = selectedItems.filter(o => o.staffId)?.map((item) => ({
    staffName: item.staffName,
    staffId: item.staffId,
    services: item.services
    .map((service) => {
      const isSimpleMenu = service.itemType === SERVICE_ITEM_TYPE.SIMPLE_MENU;
      return ({
        itemId: isSimpleMenu ? QUICK_PAY_ID : service.itemId,
        itemName: service.itemName,
        itemType: isSimpleMenu ? SERVICE_ITEM_TYPE.SERVICE : service.itemType,
        price: service.price,
        discount: service.discountType === DiscountType.PERCENT ? ((service.price ?? 0) * service.discount / 100) : service.discount ?? 0,
        note: service.note || '',
        duration: service.duration,
        discountSetting: service.discountSetting || null,
        expiredDate: service.expireDate || null,
        supplyFee: service.supplyFee, 
      });
    }),
  }));
  const body: ISaveApiBodyType = {
    checkInId: ticketDetails?.checkInId || '',
    billId: ticketDetails?.ticketInfo.billId || '',
    customerId: customer?.customerId || '',
    couponId: discountTicket?.couponId || '',
    items,
    subTotal: ticketTotalInfo.subtotal,
    discount: ticketTotalInfo.discByTicket,
    discountSetting: discountTicket?.discountSetting || null,
    tax: 0,
    tip: ticketTotalInfo.tipTicket,
    total: ticketTotalInfo.total,
    chargeFee: enableCardFee,
  };
  return body;
};

const getInfoBillTicketDetail = () => {
  const ticketDetailStore = getTicketDetails();
  const staffs = shopSelectors.data.staffs();
  const result: IBillWaitingTicketResItem = {
    billId: ticketDetailStore?.ticketInfo.billId || '',
    customerName: ticketDetailStore?.customerInfo.customerName || '',
    customerPhone: ticketDetailStore?.customerInfo?.customerPhone || '',
    staffs: ticketDetailStore?.items.map(o => {
      const staff = find(staffs, (s: IStaffItemData) => s.id === o.staffId);
      const result: IStaffTicketResItem = {
        avatar: staff?.urlImage || '',
        services: o.services.map(a => ({
          ...a,
          uuid_local: uuid(),
        })),
        staffId: o.staffId,
        staffName: o.staffName,
      };
      return result;
    }) || [],
    ticketNumber: ticketDetailStore?.ticketInfo.ticketNumber.toString() || '',
    totalPrice: ticketDetailStore?.ticketInfo.total || 0,
  };
  return result;
};

const getFeatureProductMode = () => selector('featureProductMode') as MyState['featureProductMode'];

const getActiveQuickMenu = () => selector('activeQuickMenu') as MyState['activeQuickMenu'];

const getHighlightItemId = () => selector('highlightItemId') as MyState['highlightItemId'];

const getCalcFee = () => {
  const feeCreditCard = shopSelectors.data.feeCreditCard();
  const enableCardFee = getEnableCardFee();
  const ticketTotalInfo = getTicketTotalInfo();
  if (!enableCardFee) return '';
  if (feeCreditCard?.feeType === 'PERCENT') {
    return `(${formatCurrency(ticketTotalInfo.subtotal * (feeCreditCard?.feeValue / 100))})`;
  }
  return '';
};
const enableCashIncentive = () => selector('enableCashIncentive') as boolean;
const countStaffInRowUI = () => selector('countStaffInRowUI') as number;
const customerReferral = () => selector('customerReferral') as MyState['customerReferral'];
const enableReferral = () => selector('enableReferral') as MyState['enableReferral'];

const cashierSelectors = {
  loading,
  getAvailableStaff,
  getSelectedItems,
  getActiveStaffId,
  getTicketDetails,
  getCategories,
  getProductCategories,
  getAddOnList,
  getStaffList,
  getServicesByCateActive,
  getProductsByProductCateActive,
  getCategoryIdSelected,
  getServiceIdsSelected,
  getActiveItemsCollapse,
  getCustomerList,
  getSelectedCustomer,
  getTicketTotalInfo,
  getPaymentInfo,
  getActiveCategory,
  getActiveProductCategory,
  getVisibleChooseCustomer,
  getVisibleVIP,
  getVisibleQuickPay,
  getVisibleIssueGiftCard,
  getVisibleChooseStaff,
  getDraftServiceSwitchStaff,
  getItemModeVisible,
  getItemHandleMode,
  getDiscountTicket,
  getDraftDiscountItemModal,
  getDraftQuickPay,
  getInfoBillTicketDetail,
  getFeatureProductMode,
  getVisibleModalWarningEmptyServices,
  getVisibleModalWarningVIPPackage,
  getCustomerPackage,
  getEnableCardFee,
  getHighlightItemId,
  getCalcFee,
  getActiveQuickMenu,
  enableCashIncentive,
  getDraftSupplyFeeItemModal,
  countStaffInRowUI,
  getProductTax,
  customerReferral,
  enableReferral,
};
export default cashierSelectors;
