import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import ModalContactUs, { useModalContactUsRef } from '../ModalContactUs';
import check_icon from './check.svg';
import ModalPrintCheckManual, { useModalPrintCheckManualRef } from './ModalPrintCheckManual';
import new_feature_icon from './new.svg';

const ButtonStyled = styled.div`
display: inline-flex;
padding: 16px 24px;
justify-content: center;
align-items: center;
gap: 4px;
border-radius: 4px;
border: 1px solid #C84B31;
background: #FFF;
box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #C84B31 inset;
position: relative;
span {
  color: #C84B31;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

&:hover {
  background: #eee;
  cursor: pointer;
}

.new-feature-icon {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(20px, -14px);
}
`;

const ButtonPrintCheckBlank = () => {
  const modal = useModalPrintCheckManualRef();
  const allSetting = shopSelectors.data.allSetting();
  const modalContactUsRef = useModalContactUsRef();
  const onPress = () => {
    if (!allSetting?.displayPrintCheck) return modalContactUsRef.current?.open();
    modal.current?.open();
  };

  return (
    <>
      <ButtonStyled onClick={onPress}>
        <img src={check_icon} alt="check_icon" style={{ width: 24, height: 24 }} />
        <img src={new_feature_icon} className='new-feature-icon' alt="new_feature_icon" style={{ width: 55, height: 55 }} />
        <span>Print Check Blank</span>
      </ButtonStyled>

      <ModalPrintCheckManual ref={modal} />
      <ModalContactUs ref={modalContactUsRef} />
    </>
  );
};

export default ButtonPrintCheckBlank;
