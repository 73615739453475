import { Table as AntdTable, Empty } from 'antd';
import { ColumnsType, TablePaginationConfig, TableProps } from 'antd/es/table';
import { GetRowKey } from 'antd/es/table/interface';
import React, { useMemo } from 'react';
import styled from 'styled-components';

type ITableProps = {
  loading?: boolean;
  showSizeChanger?: boolean;
  columns?: ColumnsType<any>;
  dataSource?: any[];
  rowKey?: string | number | symbol | GetRowKey<any> | undefined
  pagination?: TablePaginationConfig;
  handleChangePage?: (page: number, size?: number) => void;
  emptyMsg?: string;
  rowClassName?: any;
  scroll?: TableProps<any>['scroll'],
};
const Table: React.FC<ITableProps> = ({
  loading = false,
  showSizeChanger = false,
  columns,
  dataSource,
  rowKey,
  pagination,
  handleChangePage,
  rowClassName,
  emptyMsg,
  scroll,
}) => {
  const locale = useMemo(() => ({
    emptyText: <Empty description={emptyMsg} image={Empty.PRESENTED_IMAGE_SIMPLE} />
  }), [emptyMsg]);
  return (
    <TableStyled>
      <AntdTable
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        rowKey={rowKey}
        locale={locale}
        rowClassName={rowClassName}
        pagination={{
          ...pagination,
          showSizeChanger: showSizeChanger,
          onChange: handleChangePage,
        }}
        scroll={scroll}
      />
    </TableStyled>
  );
};

export default Table;
const TableStyled = styled.div`
  .ant-table-wrapper {
    .ant-table {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      .ant-table-container {
        .ant-table-content {
          max-height: 80vh;
          overflow: auto;
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
          border: 1px solid #86909c;
          border-bottom: none;
          .ant-table-thead {
            .ant-table-cell {
              position: sticky;
              top: 0;
              z-index: 99;
              border-start-start-radius: 0;
              border-start-end-radius: 0;
              background: var(--fill-fill-2, #adadad);
              color: var(--text-text-1, #fff);
              text-align: center;
              font-size: 1rem;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              &::before {
                content: none;
              }
            }
          }
          .ant-table-tbody {
            .ant-table-cell {
              padding: 0.25rem 0.5rem;
              border-bottom: 1px solid #86909c;
              color: var(--text-text-3, #1d2129);
              text-align: right;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
    }
    .ant-table-pagination {
      background: #fff;
      margin: 0;
      padding-top: 1rem;
      padding-bottom: 1rem;
      border: 1px solid #86909c;
      border-top: none;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      .ant-pagination-item,
      .ant-pagination-next,
      .ant-pagination-prev {
        border-radius: 3.333px;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        a {
          color: var(--text-text-3, #1d2129);
          text-align: center;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 600;
          text-transform: capitalize;
        }
        &.ant-pagination-item-active {
          background: var(--primary-primary-active, #ff8890);
        }
      }
    }
  }
`;
