import Box from 'components/Box';
import Modal from 'components/Modal';
import CurrencyKeyBoard, { CurrencyKeyBoardRef } from 'components/NumberKeyBoard/Currency';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import { useTicketWrapper } from 'features/ShopWrapperLayout/TicketWrapperContext';
import cashierApis from 'features/cashier/services/apis';
import { parseDecimal } from 'features/cashier/services/constants';
import cashierSelectors from 'features/cashier/services/selectors';
import { ISaveApiBodyType } from 'features/cashier/services/types/api';
import ConfirmTipTicket, { useConfirmTipTicketRef } from 'features/payment/components/ConfirmTipTicket';
import PaymentLoading, { IPaymentLoadingRef } from 'features/payment/components/PaymentLoading';
import CountingFooter from 'features/payment/pages/PaymentPage/CashierSide/components/LoyaltyPoint/CountingFooter';
import multiplePaymentActions from 'features/payment/pages/PaymentPage/services/actions';
import { ICustomerReceiveConfigs, TypePaymentActors, TypePaymentPassData } from 'features/payment/pages/PaymentPage/services/types/socketPayment';
import paymentApis from 'features/payment/services/apis';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import { IBodyAPIPayment } from 'features/payment/services/types/api';
import { IBillDetailData } from 'features/payment/services/types/bill';
import { useSocketContext } from 'hooks/useSocket';
import { useRef, useState } from 'react';
import { IResponseDataBody } from 'services/response';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import { formatCurrency } from 'utils/formatCurrency';
import { PAYMENT_PASS_DATA_TOPIC } from 'utils/socket';
import CashierActionButton from '../CashierActionBtn';
import useGetDetailTicket from '../useGetDetailTicket';
import useUpdateTicket from '../useUpdateTicket';
import styled from 'styled-components';
import { InputWithKeyboardStyled, useDollarAmountInputRef } from 'components/DollarAmount/Input';
import DollarAmountKeyboard from 'components/DollarAmount/Keyboard';
import { useNavigate, useParams } from 'react-router-dom';

type Props = {
  v2?: boolean;
};
const ButtonCash = ({ v2 }: Props) => {
  const { shop_id = '' } = useParams<{ shop_id: string }>();
  const dispatch = useAppDispatch();
  const currencyRef = useRef<CurrencyKeyBoardRef>(null);
  const inputRefV2 = useDollarAmountInputRef();
  const loadingRef = useRef<IPaymentLoadingRef>(null);
  const updateTicket = useUpdateTicket();
  const [detailTicket, getDetailTicket] = useGetDetailTicket();
  const ticketContext = useTicketWrapper();
  const socketContext = useSocketContext();
  const completedTicketModalRef = useConfirmTipTicketRef();
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const [amount, setAmount] = useState<number | null>(null);
  const [total, setTotal] = useState<number>(0);
  const _amount = amount || 0;
  const remaining = total - _amount;
  const chance = remaining >= 0 ? 0 : remaining;

  const paymentInfo = cashierSelectors.getPaymentInfo();
  const discountSetting = shopSelectors.data.discountSetting();
  const allSetting = shopSelectors.data.allSetting();
  const enableCashIncentive = cashierSelectors.enableCashIncentive();

  const getDiscount = (subTotal: number) => {
    if (!discountSetting) return 0;
    const { discountType, discountValue } = discountSetting;
    if (discountType === 'PERCENT') {
      return Math.round(subTotal * (discountValue / 100));
    }
    if (discountType === 'MONEY')
      return parseDecimal(discountValue);
    return 0;
  };

  const checkCashIncentiveValid = (): boolean => {
    if (!discountSetting) return false;
    if (!discountSetting?.enableCashIncentive) return false;
    if (!enableCashIncentive) return false;
    return true;
  };

  const calcTotal = (detail: IBillDetailData): number => {
    if (!checkCashIncentiveValid()) return detail.total;
    return parseDecimal(detail.total - getDiscount(detail.subTotal));
  };

  const updateTicketWithCashIncentive = async () => {
    if (!discountSetting || !detailTicket) throw 'fail';
    const discount = detailTicket.discountTicket + getDiscount(detailTicket.subTotal);
    const total = calcTotal(detailTicket);
    const _paymentInfo: ISaveApiBodyType = {
      ...paymentInfo,
      total,
      cashIncentiveDiscount: true,
      discount,
      discountSetting: { ownerPaidPercent: 100, staffPaidPercent: 0 },
    };
    const res: IResponseDataBody<{ billId: string }> = await cashierApis.editTicket(_paymentInfo);
    const resData = res?.data?.data;
    if (!resData) throw 'fail';
  };

  const onCash = async () => {
    try {
      const rest: { billId: string } | null = await updateTicket();
      if (!rest) return;

      const billId = rest.billId;
      const detail = await getDetailTicket(billId);
      if (!detail) return;
      socketContext.switchCusPayment(billId);
      const finalTotal = calcTotal(detail);
      setTotal(finalTotal);
      setTimeout(() => {
        const finalValueStr = finalTotal?.toFixed(2);
        currencyRef.current?.setValue(finalValueStr);
        inputRefV2.current?.init(finalValueStr);

        if (checkCashIncentiveValid()) {
          const configs: ICustomerReceiveConfigs = {
            enableCashIncentive: true
          };
          socketContext.send(PAYMENT_PASS_DATA_TOPIC, {
            billId,
            actor: TypePaymentActors.CASHIER,
            action: TypePaymentPassData.CUSTOMER_RECEIVE_CONFIGS,
            data: configs,
          });
          dispatch(multiplePaymentActions.customerSide.setReceiveConfigs(configs));
        }
      }, 100);
      setVisible(true);
    } catch (error) { }
  };

  const onClose = () => {
    socketContext.backToCheckIn();
    setVisible(false);
  };

  const onNoReceipt = () => {
    navigate(`/store/${shop_id}`);
  };

  const handleSubmit = async () => {
    if (!detailTicket) return;
    setVisible(false);

    loadingRef.current?.setVisible(true);

    if (checkCashIncentiveValid()) {
      await updateTicketWithCashIncentive();
    }

    const body: IBodyAPIPayment = {
      billId: detailTicket.billId,
      paymentInfo: [
        {
          paymentType: PAYMENT_TYPE.CASH,
          amount: _amount,
          chance: Math.abs(chance),
        },
      ]
    };

    try {
      const res: IResponseDataBody<true> = await paymentApis.payment(body);
      if (res?.data?.data) {
        loadingRef.current?.setVisible(false);
        socketContext.switchCusRating(detailTicket.billId);
        const callback = () => ticketContext.completedTicket(detailTicket?.billId, true);
        if (!completedTicketModalRef.current) {
          callback();
        } else {
          completedTicketModalRef.current.open(detailTicket.billId, callback);
        }
      } else {
        loadingRef.current?.setVisible(false);
      }
    } catch (error) {
      loadingRef.current?.setVisible(false);
    }
  };

  const FooterContent = () => {
    return <ContainerAmount>
      <div className="amount-item">
        <div className="amount-label">Balance:</div>
        <div className="amount-value">{formatCurrency(remaining < 0 ? 0 : remaining)}</div>
      </div>
      <div className="amount-item">
        <div className="amount-label">Change:</div>
        <div className="amount-value">{formatCurrency(chance)}</div>
      </div>
    </ContainerAmount>;
  };

  return (
    <div style={{ alignSelf: 'stretch', display: 'flex' }}>
      <CashierActionButton v2UI={v2} onClick={onCash} feature='CASH' ntype='DANGER' label='Cash' />
      {v2 ?
        <Modal
          visible={visible}
          modalTitle='CASH'
          showClose
          okTitle={'Pay'}
          noneFooterStyle
          className='modal-overflow-unset modal-non-opacity'
          containerPadding={0}
          disableOk={!(_amount > 0 && remaining <= 0)}
          onClose={onClose}
          onSubmit={handleSubmit}
          v2
          noneBodyStyle
          width={'900px'}
        >
          {!allSetting?.activeFormatMoney ?
            <CurrencyKeyBoard v2
              ref={currencyRef}
              value={amount} onChange={val => setAmount(val || 0)}
              FooterContent={FooterContent}
            />
            :
            <Box display='flex' flexDirection='column' gap='2'>
              <Text className='modal-keyboard-label' mt={1} mb={-0.5}>
                Enter Amount:
              </Text>
              <InputWithKeyboardStyled
                ref={inputRefV2}
                value={amount}
                onChange={setAmount}
                inputMode='none'
                autoFocus
              />
              <FooterContent />
              <DollarAmountKeyboard
                controlRef={inputRefV2}
                value={amount}
                onChange={setAmount}
              />
            </Box>
          }
        </Modal>
        :
        <Modal
          // width="auto"
          footerBgColor={colorTheme.fill_5}
          headerBgColor={colorTheme.info_bg_3}
          visible={visible}
          onClose={onClose}
          onSubmit={onClose}
          modalTitle="CASH"
          className=' modal-max-height-unset'
          containerPadding={1.5}
          noneBodyStyle
          footer={
            <CountingFooter
              submitButtonTitle="Pay"
              isValid={_amount > 0 && remaining <= 0}
              onSubmit={handleSubmit}
              handleCloseModal={onClose}
              style={{ padding: '0', paddingTop: '1rem' }}
              closeButtonTitle='Close'
            >
              <Box className="center">
                <Box display="flex" alignItems="center" gap="4">
                  <Text variant="CONTENT_1" color="text_3" style={{ whiteSpace: 'nowrap' }}>
                    Balance:
                  </Text>
                  <Text variant="H5" color="text_3" whiteSpace='nowrap' >
                    {formatCurrency(remaining < 0 ? 0 : remaining)}
                  </Text>
                </Box>
                <Text mx={2} />
                <Box display="flex" alignItems="center" gap="4">
                  <Text variant="CONTENT_1" color="text_3" style={{ whiteSpace: 'nowrap' }}>
                    Change:
                  </Text>
                  <Text variant="H5" color="text_3" whiteSpace='nowrap' >
                    {formatCurrency(chance)}
                  </Text>
                </Box>
              </Box>
              <Text mb={1} />
            </CountingFooter>
          }
        >
          <CurrencyKeyBoard ref={currencyRef} value={amount} onChange={val => setAmount(val || 0)} />
        </Modal>
      }
      <PaymentLoading ref={loadingRef} />
      <ConfirmTipTicket ref={completedTicketModalRef} noReceipt={allSetting?.displayOptionCashPrint ? onNoReceipt : undefined} />
    </div>
  );
};

export default ButtonCash;

const ContainerAmount = styled.div`
display: flex;
height: 49px;
justify-content: center;
align-items: center;
gap: 32px;
align-self: stretch;
background: #F5F5F5;
margin: 0 32px;
.amount-item {
  display: flex;
  align-items: center;
  gap: 6px;
  .amount-label {
    color: #1D2129;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .amount-value {
    color: #1D2129;
    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
}
`;