import { SVGProps } from 'react';
const IcEven = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={41}
    height={40}
    viewBox='0 0 41 40'
    fill='none'
    {...props}
  >
    <circle cx={5.696} cy={13.848} r={5.196} fill={props?.fill || '#1D2129'} />
    <circle cx={35.304} cy={13.848} r={5.196} fill={props?.fill || '#1D2129'} />
    <path fill={props?.fill || '#1D2129'} d='M.5 19.046h40v2.459H.5z' />
    <path
      fill={props?.fill || '#1D2129'}
      d='m20.5 21.503 4.886 7.384h-9.771l4.885-7.384Z'
    />
  </svg>
);
export default IcEven;
