import { Form, Input } from 'antd';
import Box from 'components/Box';
import CurrencyInputPrecision from 'components/CurrencyInputPrecision';
import { RewardType } from 'features/settingService/services/types/paymentSetting';
import styled from 'styled-components';
import ActionButtons from '../components/ActionButtons';
import SwitchBtn from '../../ServicesSettingV2/SwitchBtn';
import MyRadio from '../components/MyRadio';

type Props = {
  onSwitchChange: (name: string, val: boolean) => void
};
const FormV2 = ({ onSwitchChange }: Props) => {
  return (
    <Container>
      <Section>
        <Box display='flex' alignItems='center' alignSelf='stretch'>
          <Form.Item
            noStyle
            name={RewardType.LOYALTY_REWARD + '_active'}
          >
            <MyRadio style={{ flex: 1 }} label='Reward Points Usage'
              onChange={(val) =>
                onSwitchChange(
                  `${RewardType.LOYALTY_ENROLLMENT_VISIT + '_active'}`,
                  val
                )
              }
            />
          </Form.Item>
          <Form.Item
            noStyle
            name={RewardType.LOYALTY_ENROLLMENT_VISIT + '_active'}
          >
            <MyRadio style={{ flex: 1 }} label='Loyalty By Each Visiting'
              onChange={(val) =>
                onSwitchChange(
                  `${RewardType.LOYALTY_REWARD + '_active'}`,
                  val
                )
              }
            />
          </Form.Item>
        </Box>

        {/* rewardPointsUsageWatch */}
        <Form.Item noStyle shouldUpdate dependencies={[RewardType.LOYALTY_REWARD + '_active']}>
          {({ getFieldValue }) => {
            const enable = getFieldValue(RewardType.LOYALTY_REWARD + '_active');
            if (!enable) return null;
            return (
              <Box alignSelf='stretch' display='flex' style={{ gap: 16 }}>
                <Form.Item
                  label="Dollar"
                  name={RewardType.LOYALTY_REWARD + '_minBill'}
                  style={{ margin: 0, flex: 1 }}
                >
                  <CurrencyInputPrecision
                    size="small"
                    height={'3.5rem'}
                  />
                </Form.Item>
                <Form.Item
                  label="Equal Point"
                  name={RewardType.LOYALTY_REWARD + '_point'}
                  style={{ margin: 0, flex: 1 }}
                >
                  <Input onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                    style={{ height: '3.5rem' }}
                  />
                </Form.Item>
              </Box>
            );
          }}
        </Form.Item>
        {/* rewardPointsUsageWatch */}
        <Form.Item noStyle shouldUpdate dependencies={[RewardType.LOYALTY_ENROLLMENT_VISIT + '_active']}>
          {({ getFieldValue }) => {
            const enable = getFieldValue(RewardType.LOYALTY_ENROLLMENT_VISIT + '_active');
            if (!enable) return null;
            return (
              <Box alignSelf='stretch' display='flex' style={{ gap: 16 }}>
                <Form.Item
                  label="Min Ticket"
                  name={RewardType.LOYALTY_ENROLLMENT_VISIT + '_minBill'}
                  style={{ margin: 0, flex: 1 }}
                >
                  <Input onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                    style={{ height: '3.5rem' }}
                  />
                </Form.Item>

                <Form.Item
                  label="Point"
                  name={RewardType.LOYALTY_ENROLLMENT_VISIT + '_point'}
                  style={{ margin: 0, flex: 1 }}
                >
                  <Input onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                    style={{ height: '3.5rem' }}
                  />
                </Form.Item>
              </Box>
            );
          }}
        </Form.Item>
      </Section>
      {/* <Section>
        <Box display="flex" gap="3" alignItems="center">
          <Form.Item
            noStyle
            name={RewardType.LOYALTY_ENROLLMENT_REFERRAL + '_active'}
          >
            <SwitchBtn />
          </Form.Item>
          <span className='section-label'>
            Referral
          </span>
        </Box>
        <Form.Item noStyle shouldUpdate dependencies={[RewardType.LOYALTY_ENROLLMENT_REFERRAL + '_active']}>
          {
            ({ getFieldValue }) => {
              const enable = getFieldValue(RewardType.LOYALTY_ENROLLMENT_REFERRAL + '_active');
              if (!enable) return null;
              return (
                <Box alignSelf='stretch' display='flex'>
                  <Form.Item
                    label="Loyalty Reward Each Referral"
                    name={RewardType.LOYALTY_ENROLLMENT_REFERRAL + '_amount'}
                    style={{ flex: 1, margin: 0 }}
                  >
                    <Input onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                      style={{ height: '3.5rem' }}
                    />
                  </Form.Item>
                </Box>
              );
            }
          }
        </Form.Item>
      </Section> */}

      <Section>
        <Box display="flex" gap="3" alignItems="center">
          <Form.Item
            noStyle
            name={RewardType.LOYALTY_REDEMPTION + '_active'}
          >
            <SwitchBtn />
          </Form.Item>
          <span className='section-label'>
            Redeem
          </span>
        </Box>
        <Form.Item noStyle shouldUpdate dependencies={[RewardType.LOYALTY_REDEMPTION + '_active']}>
          {
            ({ getFieldValue }) => {
              const enable = getFieldValue(RewardType.LOYALTY_REDEMPTION + '_active');
              if (!enable) return null;
              return (
                <Box alignSelf='stretch' display='flex' style={{ gap: 16 }}>
                  <Form.Item
                    label="Point"
                    name={RewardType.LOYALTY_REDEMPTION + '_point'}
                    style={{ margin: 0, flex: 1 }}
                  >
                    <Input onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                      style={{ height: '3.5rem' }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Dollar"
                    name={RewardType.LOYALTY_REDEMPTION + '_minBill'}
                    style={{ margin: 0, flex: 1 }}
                  >
                    <CurrencyInputPrecision height={'3.5rem'} size="small" />
                  </Form.Item>
                </Box>
              );
            }
          }
        </Form.Item>
      </Section>
      <ActionButtons />
    </Container>
  );
};

export default FormV2;
const Section = styled.div`

  .ant-form-item-label label {
    color: #1D2129;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .ant-input {
    color: #1D2129;
    font-family: Poppins;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }


  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #CCD4DC;
  background: #F6F7FC;

  .section-label {
    color: #1D2129;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
