import { Avatar, Input } from 'antd';
import SearchIcon from 'assets/Icons/search';
import Box from 'components/Box';
import settingStaffActions from 'features/settingStaff/services/actions';
import settingStaffSelectors from 'features/settingStaff/services/selectors';
import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IStaffItemData } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatPhone } from 'utils/formatPhone';
import storage from 'utils/sessionStorage';
import add_icon from './icons/add.svg';
import back_icon from './icons/back.svg';
import ModalAddStaff, { useModalAddStaffRef } from '../../ModalAddStaff';

const LeftDetail = () => {
  const dispatch = useAppDispatch();
  const [keyword, setKeyword] = useState('');
  const navigate = useNavigate();
  const _staffs = settingStaffSelectors.staffsTableData.data();
  const selectedStaff = settingStaffSelectors.selectedStaffData.data();

  const addStaff = useModalAddStaffRef();

  const handleAddStaff = () => addStaff.current?.open();
  const staffs = useMemo(() => _staffs.filter((staff) =>
    !staff.anyBody &&
    `${staff.firstName} ${staff.lastName}`
      .toLowerCase()
      .includes(keyword.toLowerCase())
  ), [keyword, _staffs]);

  const debouncedHandleChange = useCallback(debounce((value: string) => setKeyword(value || ''), 500), []);
  const chooseStaff = (id: string) => {
    dispatch(settingStaffActions.getSelectedStaffInfo.fetch(id));
  };
  const onDoneAddStaff = (newStaff: IStaffItemData) => chooseStaff(newStaff.id);
  return (
    <LeftDetailsStyled>
      <Header>
        <Box display='flex' alignItems='center'>
          <button onClick={() => navigate(`/store/${storage.shop_id.get()}/setting-staff`)} type='button' style={{ paddingRight: 24 }} className="btn-back"><img src={back_icon} alt="back_icon" style={{ width: 40, height: 40 }} /></button>
          <div className="overview-label">Staff Detail</div>
        </Box>
        <div style={{ flex: 1 }}></div>
        <button type='button' className="btn-add-staff" onClick={handleAddStaff}>
          <img src={add_icon} alt="add_icon" style={{ width: 24, height: 24 }} />
          <span>Add Staff</span></button>
      </Header>
      <Input
        autoComplete="off"
        name="keySearch"
        defaultValue=""
        onChange={(event) => debouncedHandleChange(event.target.value)}
        placeholder="Staff name"
        suffix={<SearchIcon />}
      />
      <StaffListScroll>
        <div className="container-scroll">
          {staffs.map(staff => (
            <StaffItem
              key={staff.id}
              staff={staff}
              active={staff?.id === selectedStaff?.id}
              onClick={chooseStaff}
            />
          ))}
        </div>
      </StaffListScroll>
      <ModalAddStaff ref={addStaff} onDone={onDoneAddStaff} />
    </LeftDetailsStyled>
  );
};

export default LeftDetail;
const StaffItemStyled = styled.button`
display: flex;
padding: 12px 16px;
justify-content: space-between;
align-items: center;
align-self: stretch;
border-bottom: 1px solid #CCD4DC;
background: #FFF;
.staff-name-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;

  p.staff-name {
    color: #1D2129;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 166.667% */
    text-transform: uppercase;
  }
  p.create-date {
    color: #64748B;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
  .staff-phone {
    color: #1D2129;
    text-align: right;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

&:hover {
  opacity: 0.8;
}

&.active {
  background: #F5767F;
  box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #E7646D inset;
  .staff-name-container p.staff-name, 
  .staff-name-container p.create-date, 
  .staff-phone {
    color: #fff;
  }
}
`;
type StaffItemProps = {
  staff: IStaffItemData;
  onClick?: (id: string) => void;
  active?: boolean;
};
const StaffItem = ({ active, staff, onClick = () => undefined }: StaffItemProps) => {
  return (
    <StaffItemStyled className={active ? 'active' : ''} type='button' onClick={() => onClick(staff.id)}>
      <div className="staff-name-container">
        <Box display='flex' gap='2'>
          <Avatar src={staff.urlImage || 'fail'} >{staff?.firstName?.[0] || 'A'}</Avatar>
          <p className="staff-name">{staff.firstName} {staff.lastName}</p>
        </Box>
        <p className="create-date">
          {staff.staffType}
          {/* {`Created date: ${moment(staff?.createdDate, 'M/D/YY, h:mm A').format('MM-DD-YYYY')}`} */}
        </p>
      </div>
      <span className="staff-phone">{formatPhone(staff.phone)}</span>
    </StaffItemStyled>
  );
};

const StaffListScroll = styled.div`
flex: 1;
position: relative;
.container-scroll {
  position: absolute;
  inset: 0;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
}
`;
const LeftDetailsStyled = styled.div`
align-self: stretch;
display: flex;
flex-direction: column;
gap: 16px;
padding: 16px;
.overview-label {
  color: #1D2129;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ant-input-affix-wrapper {
  background: #ffffff;
  border: 1px solid #86909C;
  .ant-input {
    background: transparent;
    color: rgb(29, 33, 41);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
`;

const Header = styled.div`
display: flex;
align-self: stretch;
height: 80px;
padding: 24px 0;
justify-content: space-between;
align-items: center;
gap: 24px;
.btn-add-staff {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: #FF8890;
  box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -4px 0px 0px #E7646D inset;
  span {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &:hover, &:active {
    opacity: 0.8;
  }
}

`;
