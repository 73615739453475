import fetch from 'services/request';
import {
  ICreateAddOnServiceBodyType,
  IEditCateBodyType,
  IGetProductsByCategoryId,
  IUpdateCateBodyForProduct,
  IUpdateLateForWork,
  IDeleteTurnSettingService,
  IUpdateTurnSettingService,
  IUpdateTurnSettingTicket,
  IgetVIPParams,
  IDeleteVIPs,
  IChangeCommissionPrice,
  IDeleteProduct,
  IUpdateQuickPayBody,
  IDayOffParams,
  IBodyUpdateEnableBookingCheckInItem,
  ICategoryUpdateInfoServiceItem,
  ICreateMultipleAddOnItem,
  IApiUpdateTipOptionItem,
} from './types/api';
import { IBodyItemNotificationPut } from './types/generalSetting';
import {
  IShopDiscountParams,
  ITaxParams,
  IPaymentMethodParams,
  IUpdateReferralConfig,
} from './types/paymentSetting';
import { IVIPs, IProduct, IGiftCard } from './types/reducer';
import { IBodyCreateSimpleMenu, IBodyUpdateSimpleMenuServiceItem } from './types/simpleMenu';
import { DISCOUNT_VALUE_TYPE } from './constants';
import { IVipProductItem } from './types/VIP';
import { IApiUpdateTurnAppointmentBody, IApiUpdateTurnRangeSaleBodyItem, IUpdateQuickPayTurnItem } from './types/turn';
import { IProductTaxConfigResData } from 'services/shop/types/tax';
import { IPrintCheckSettingBody } from './types/printCheck';

const baseURL = 'api/v1';
export const editCategory = (body: IEditCateBodyType) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/category/update`,
    body,
  });
};
export const createCategory = (body: IEditCateBodyType) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/category/create`,
    body,
  });
};
export const createOrUpdateServiceAddOn = (
  body: ICreateAddOnServiceBodyType
) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/service/create`,
    body,
  });
};

export const addMoreServiceByCate = (body: IEditCateBodyType) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/category/update-service-by-category`,
    body,
  });
};

export const getListCategoriesForProduct = (params: {}) => {
  return fetch({
    method: 'get',
    url: `${baseURL}/product/get-all-by-category`,
    params,
  });
};

export const createCategoryForProduct = (body: IUpdateCateBodyForProduct) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/category/create`,
    body,
  });
};

export const updateNameCategoryForProduct = (
  body: IUpdateCateBodyForProduct
) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/category/update`,
    body,
  });
};

export const deleteCategoryForProduct = (params: {
  id: string;
  pinCode: string;
}) => {
  return fetch({
    method: 'delete',
    url: `${baseURL}/category/delete/${params.id}`,
    pinCode: params.pinCode,
  });
};
export const deleteAddOnService = (params: { id: string; pinCode: string }) => {
  return fetch({
    method: 'delete',
    url: `${baseURL}/service/delete/${params.id}`,
    pinCode: params.pinCode,
  });
};

export const getProductsByCategoryId = (request: IGetProductsByCategoryId) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/product/get`,
    body: request.body,
    params: request.params,
  });
};

export const createProduct = (body: IProduct) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/product/create`,
    body,
  });
};

export const updateProduct = (body: IProduct) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/product/update`,
    body,
  });
};

export const deleteProduct = (body: IDeleteProduct) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/product/delete`,
    body,
  });
};
export const changeCommissionPrice = (body: IChangeCommissionPrice) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/product/change-commission-price`,
    body,
  });
};

export const getVIP = (params: IgetVIPParams) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/vip/get`,
    params,
  });
};

export const getVIPList = () => {
  return fetch({
    method: 'get',
    url: '/api/v1/vip/list',
  });
};

export const updateVIPList = (body: IVipProductItem[]) => {
  return fetch({
    method: 'post',
    url: '/api/v1/vip/update',
    body,
    autoToast: true,
  });
};


export const createVIP = (body: IVIPs) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/vip/create`,
    body,
  });
};
export const updateVIP = (body: IVIPs[]) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/vip/update`,
    body,
  });
};
export const deleteVIP = (body: IDeleteVIPs) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/vip/delete`,
    body,
  });
};
export const getQuickPayList = () => {
  return fetch({
    method: 'get',
    url: `${baseURL}/category/list-quick-pay`,
  });
};
export const updateQuickPayList = (body: IUpdateQuickPayBody) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/category/update-quick-pay`,
    body,
  });
};
export const getTurnSetting = () => {
  return fetch({
    method: 'get',
    url: `${baseURL}/turn/setting`,
  });
};
const getTurnSettingRangeSales = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/turn/setting/range-sales',
  });
};
const updateTurnRangeSales = (body:IApiUpdateTurnRangeSaleBodyItem[]) => {
  return fetch({
    method: 'post',
    url: 'api/v1/turn/setting/range-sales',
    body,
  });
};
const getTurnSettingAppointment = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/turn/setting/appointment',
  });
};
const updateTurnSettingAppointment = (body:IApiUpdateTurnAppointmentBody) => {
  return fetch({
    method: 'post',
    url: 'api/v1/turn/setting/appointment',
    body,
  });
};
export const updateTurnSettingTiket = (body: IUpdateTurnSettingTicket) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/turn/setting/ticket`,
    body,
  });
};
export const updateTurnSettingService = (body: IUpdateTurnSettingService) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/turn/setting/service`,
    body,
  });
};
export const deleteTurnSettingService = (data: IDeleteTurnSettingService) => {
  return fetch({
    method: 'delete',
    url: `${baseURL}/turn/setting/service`,
    configs: { data },
  });
};
export const getLateForWork = () => {
  return fetch({
    method: 'get',
    url: `${baseURL}/turn/setting/late-for-work`,
  });
};
export const updateLateForWork = (body: IUpdateLateForWork) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/turn/setting/late-for-work`,
    body,
  });
};
// start api for Payment Setting
export const getTax = () => {
  return fetch({
    method: 'get',
    url: `${baseURL}/setting/tax-config`,
  });
};
export const getDiscount = () => {
  return fetch({
    method: 'get',
    url: `${baseURL}/setting/discount-by-shop`,
  });
};
export const getSpecialDiscount = () => {
  return fetch({
    method: 'get',
    url: `${baseURL}/special-discount/list`,
  });
};
export const getLoyaltyReward = () => {
  return fetch({
    method: 'get',
    url: `${baseURL}/setting/get-loyalty-program`,
  });
};
export const getOtherPaymentMethod = () => {
  return fetch({
    method: 'get',
    url: `${baseURL}/setting/other-payment-method`,
  });
};
export const getPolicyTerm = () => {
  return fetch({
    method: 'get',
    url: `${baseURL}/setting/policy-term`,
  });
};
export const getGiftCard = () => {
  return fetch({
    method: 'get',
    url: `${baseURL}/setting/gift-card-commission`,
  });
};
export const postTax = (body: ITaxParams) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/setting/update-tax`,
    body,
  });
};
export const postProductTax = (body: IProductTaxConfigResData) => {
  return fetch({
    method: 'post',
    url: 'api/v1/product/setting-tax',
    body,
  });
};
export const postDiscount = (body: IShopDiscountParams) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/setting/update-discount-shop`,
    body,
  });
};
export const postSpecialDiscount = (body: any) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/special-discount/update`,
    body,
  });
};
export const postLoyalty = (body: any) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/setting/update-loyalty-program`,
    body,
  });
};
export const postOtherPaymentMethod = (body: IPaymentMethodParams[]) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/setting/other-payment-method`,
    body,
  });
};
export const postGiftCard = (body: IGiftCard) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/setting/gift-card-commission`,
    body,
  });
};

export const getNotification = () => {
  return fetch({
    method: 'get',
    url: `${baseURL}/configuration/notification`,
  });
};
export const postNotification = (body: IBodyItemNotificationPut[]) => {
  return fetch({
    method: 'put',
    url: `${baseURL}/configuration/notification`,
    body,
  });
};

export const getScheduler = () => {
  return fetch({
    method: 'get',
    url: `${baseURL}/shop/scheduler-booking`,
  });
};

export const postScheduler = (body: any) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/shop/scheduler`,
    body,
  });
};

// end api for Payment Setting

// start day off api
export const getDayOffList = (params: IDayOffParams) => {
  return fetch({
    method: 'get',
    url: `${baseURL}/off-day/shop`,
    params,
  });
};
export const postDayOffList = (body: IDayOffParams) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/off-day/shop`,
    body,
  });
};
export const deleteDayOffList = (id: string) => {
  return fetch({
    method: 'delete',
    url: `${baseURL}/off-day/shop/${id}`,
  });
};
export const deleteMultiple = (body: string[]) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/service/deleteMultiple`,
    body,
  });
};

const sortCategories = (ids: string[]) => {
  return fetch({
    method: 'post',
    url: 'api/v1/category/sorts',
    body: ids
  });
};

const sortServices = (ids: string[]) => {
  return fetch({
    method: 'post',
    url: 'api/v1/service/sorts',
    body: ids
  });
};

const updateEnableBookingCheckIn = (body: IBodyUpdateEnableBookingCheckInItem[]) => {
  return fetch({
    method: 'post',
    url: 'api/v1/category/update-enable-booking-check-in',
    body,
  });
};

// end day off api

// simple-menu
const simpleMenu = {
  updateInfoService: (body: IBodyUpdateSimpleMenuServiceItem[]) => {
    return fetch({
      method: 'post',
      url: 'api/v1/simple-menu/update-info-service',
      body,
    });
  },
  updateNameCategory: (id: string, name: string) => {
    return fetch({
      method: 'post',
      url: 'api/v1/simple-menu/update-name-category',
      body: { id, name },
    });
  },
  applySuppleFee: (value: number) => {
    return fetch({
      method: 'post',
      url: 'api/v1/simple-menu/apply-supple-fee',
      body: { value },
    });
  },
  create: (body: IBodyCreateSimpleMenu) => {
    return fetch({
      method: 'post',
      url: 'api/v1/simple-menu/create',
      body,
    });
  },
  enableCheckIn: (value: boolean) => {
    return fetch({
      method: 'post',
      url: 'api/v1/simple-menu/enable-check-in',
      body: { value },
    });
  },
  reorderCategories: (body: string[]) => {
    return fetch({
      method: 'post',
      url: 'api/v1/simple-menu/sorts',
      body,
    });
  },
  deleteCategory: (categoryId: string) => {
    return fetch({
      method: 'delete',
      url: `api/v1/simple-menu/delete/${categoryId}`,
    });
  },
};

const categoryApplySupplyFee = (categoryId: string | null, supplyFee: number) => {
  return fetch({
    method: 'post',
    url: '/api/v1/category/apply-supple-fee',
    body: { categoryId, supplyFee }
  });
};

const categoryEditName = (id: string, name: string) => {
  return fetch({
    method: 'post',
    url: '/api/v1/category/update-name-category',
    body: { id, name }
  });
};

const serviceEditName = (id: string, name: string) => {
  return fetch({
    method: 'post',
    url: '/api/v1/service/update-name',
    body: { id, name }
  });
};

const categoryUpdateInfoServices = (body: ICategoryUpdateInfoServiceItem[]) => {
  return fetch({
    method: 'post',
    url: '/api/v1/service/update-info',
    body,
  });
};

const categoryDelete = (categoryId: string) => {
  return fetch({
    method: 'delete',
    url: `/api/v1/category/delete/${categoryId}`,
  });
};

const createMultipleAddOn = (body: ICreateMultipleAddOnItem[]) => {
  return fetch({
    method: 'post',
    url: '/api/v1/service/create-multiple-add-on',
    body,
  });
};

const applyDiscountItemsForCate = (categoryId: string | null, discountValue: number, discountType: DISCOUNT_VALUE_TYPE) => {
  return fetch({
    method: 'post',
    url: '/api/v1/category/apply-discount-service',
    body: { categoryId, discountValue, discountType },
  });
};

// optionTipsAll
const updateOptionTipsAll = (body: IApiUpdateTipOptionItem[]) => {
  return fetch({
    method: 'put',
    url: '/api/v1/setting/update-option-tips',
    body,
  });
};

const getQuickPayTurnList = () => {
  return fetch({
    method: 'get',
    url: `${baseURL}/turn/list-quick-pay`,
  });
};

const updateQuickPayTurnList = (body:IUpdateQuickPayTurnItem[]) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/turn/update-quick-pay`,
    body,
  });
};

const updatePrintCheckSetting = (body:IPrintCheckSettingBody) => fetch({
  method: 'post',
  url: '/api/v1/shop/setting-print-check',
  body,
});

const getReferralConfig = () => fetch({
  method: 'get',
  url: 'api/v1/setting/setting-referral'
});

const updateReferralConfig = (body:IUpdateReferralConfig[]) => fetch({
  method: 'post',
  url: 'api/v1/setting/setting-referral',
  body
});

const settingApis = {
  sortServices,
  sortCategories,
  editCategory,
  createCategory,
  createOrUpdateServiceAddOn,
  addMoreServiceByCate,
  getListCategoriesForProduct,
  createCategoryForProduct,
  updateNameCategoryForProduct,
  deleteCategoryForProduct,
  getProductsByCategoryId,
  createProduct,
  updateProduct,
  deleteProduct,
  changeCommissionPrice,
  getVIP,
  createVIP,
  updateVIP,
  deleteVIP,
  getTurnSetting,
  updateTurnSettingTiket,
  updateTurnSettingService,
  deleteTurnSettingService,
  getLateForWork,
  updateLateForWork,
  getQuickPayList,
  updateQuickPayList,
  getTax,
  getDiscount,
  getSpecialDiscount,
  getLoyaltyReward,
  getOtherPaymentMethod,
  getPolicyTerm,
  getGiftCard,
  postTax,
  postProductTax,
  postDiscount,
  postSpecialDiscount,
  postLoyalty,
  postOtherPaymentMethod,
  postGiftCard,
  getNotification,
  postNotification,
  getScheduler,
  postScheduler,
  deleteAddOnService,
  getDayOffList,
  postDayOffList,
  deleteDayOffList,
  deleteMultiple,
  simpleMenu,
  updateEnableBookingCheckIn,
  categoryApplySupplyFee,
  categoryEditName,
  serviceEditName,
  categoryUpdateInfoServices,
  categoryDelete,
  createMultipleAddOn,
  applyDiscountItemsForCate,
  getVIPList,
  updateVIPList,
  updateOptionTipsAll,
  getQuickPayTurnList,
  updateQuickPayTurnList,
  updatePrintCheckSetting,
  getTurnSettingRangeSales,
  updateTurnRangeSales,
  getTurnSettingAppointment,
  updateTurnSettingAppointment,
  getReferralConfig,
  updateReferralConfig
};

export default settingApis;
