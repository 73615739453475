import { Form } from 'antd';
import Modal from 'components/Modal';
import Text from 'components/Text';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
type Props = {};
type Ref = {
  open: () => void;
};
export const useModalContactUsRef = () => useRef<Ref>(null);
const ModalContactUs = forwardRef<Ref, Props>(({ }, ref) => {
  const [visible, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const onOpen: Ref['open'] = () => setOpen(true);

  useImperativeHandle(ref, () => ({
    open: onOpen
  }));

  return (
    <Modal
      modalTitle='Contact Us'
      showClose
      okTitle={'Accept'}
      noneFooterStyle
      containerPadding={1.5}
      v2
      noneBodyStyle
      width={'900px'}
      visible={visible} onClose={onClose}
      onSubmit={onClose}
      hiddenOkButton
    >
      <Wrapper>
        <Text mt={1.5} textAlign='center' variant='CONTENT_2' style={{ fontSize: '1.5rem' }}>Please contact your system administrator for assistance regarding this upgrade function!</Text>
      </Wrapper>
    </Modal>
  );
});
ModalContactUs.displayName = 'ModalContactUs';
export default ModalContactUs;

const Wrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 120px;
  .CONTENT_2 {
    color: #64748B;
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;