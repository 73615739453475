import { AutoComplete, Col, Input, InputRef, Row } from 'antd';
import clsx from 'clsx';
import CurrencyInput, { CurrencyInputFormStyled, CurrencyInputRef } from 'components/CurrencyInputMasked';
import { InputFormV2Styled } from 'components/DollarAmount/Input';
import { FormItem, useFormInstance } from 'components/Form';
import InputPhone from 'components/InputPhone';
import { Ref } from 'react';
import { IShopAllSetting } from 'services/shop/types/setting';
import styled from 'styled-components';
const bankNameOptions = [
  { value: 'Wells Fargo' },
  { value: 'PNC Bank' },
  { value: 'Capital One Bank' },
  { value: 'Chase Bank' },
];

const InfoForm = ({ currencyRef, inputRef, v2, allSetting, disabledAmount }: { disabledAmount?: boolean, currencyRef: Ref<CurrencyInputRef>, inputRef: Ref<InputRef>, v2?: boolean, allSetting?: IShopAllSetting | null }) => {
  const form = useFormInstance();
  return (
    <InfoFormStyled className={clsx(v2 && 'v2')}>
      <FormItem noStyle name={'routingNumber'} />
      <FormItem noStyle name={'accountNumber'} />
      <FormItem label='Customer' name={'name'}  >
        <Input
          placeholder='Please enter'
        />
      </FormItem>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <FormItem label='Phone' name={'phone'}  >
            <InputPhone placeholder='Please enter' />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label='Address' name={'address'}  >
            <Input
              placeholder='Please enter'
            />
          </FormItem>
        </Col>
      </Row>
      <FormItem label='Amount' name={'amount'}  >
        {v2 && allSetting?.activeFormatMoney ? <>
          <InputFormV2Styled withForm disabled={disabledAmount} />
        </> : <CurrencyInputFormStyled>
          <CurrencyInput className='CurrencyInput' ref={currencyRef} onChange={val => form.setFieldValue('amount', val)} />
        </CurrencyInputFormStyled>
        }
      </FormItem>
      <WrapperCheckInformation className={v2 ? 'v2' : ''}>
        <div className="check-title">CHECK INFORMATION</div>
        <Row className='row-item' gutter={[16, 16]}>
          <Col span={12}>
            <FormItem label='Pay To The Order Of' name={'companyName'}  >
              <Input
                placeholder='Please enter'
              />
            </FormItem>

          </Col>
          <Col span={12}>
            <FormItem label='No.' name={'no'} rules={[{ required: true }]} >
              <Input
                ref={inputRef}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                placeholder='0'
              />
            </FormItem>
          </Col>
        </Row>
        <Row className='row-item' gutter={[16, 16]}>
          <Col span={12}>
            <FormItem label='Bank' name={'bankName'} >
              <AutoComplete
                options={bankNameOptions}
                filterOption={(inputValue, option) =>
                  option?.value?.toUpperCase()?.indexOf(inputValue.toUpperCase()) !== -1
                }
                popupClassName={v2 ? 'dropdown-v2' : ''}
                placeholder='Select/Input'
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label='For' name={'bankFor'} >
              <Input />
            </FormItem>
          </Col>
        </Row>
        <Row className='row-item' gutter={[16, 16]}>
          <Col span={8}>
            <FormItem label='DL#' name={'dlInformation'} >
              <Input
                placeholder='0'
              />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label='Routing Number' name={'routingNumber'} >
              <Input
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                placeholder='0'
              />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label='Account Number' name={'accountNumber'} >
              <Input
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                placeholder='0'
              />
            </FormItem>
          </Col>
        </Row>
      </WrapperCheckInformation>
    </InfoFormStyled>
  );
};

export default InfoForm;

const InfoFormStyled = styled.div`
.ant-input, .ant-input-suffix, .ant-input-number, .ant-select {
  height: 3rem;
  width: 100%;
}
.ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-input-wrap {
  height: 100%;
  input { 
    height: 100%;
  };
}

&.v2 {
  .ant-input, .ant-input-suffix, .ant-input-number, .ant-select, input.CurrencyInput {
    height: 4rem;
    width: 100%;
  }
  .ant-form-item .ant-form-item-label label {
    color: #1D2129;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .ant-form-item .ant-form-item-control .ant-form-item-control-input-content {
    .ant-select-selection-placeholder, input {
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
`;

const WrapperCheckInformation = styled.div`
  .check-title {
    display: none;
  }
  &.v2 {
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    background: #FFF;
    .row-item {
      align-self: stretch;
    }
    .check-title {
      display: flex;
      align-self: stretch;
      color: #000;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 24px;
    }
  }
`;

