import { Tabs, TabsProps } from 'antd';
import Text from 'components/Text';
import Sidebar from 'components/layout/Sidebar';
import colorTheme from 'constants/color';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import shopActions from 'services/shop/actions';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import ProductsSetting from '../Products';
import VIPSetting from '../VIP';
import PaymentsTab from './components/PaymentsTab';
import QuickPaySetting from './components/QuickPaySetting';
import TurnTab from './components/TurnTab';
import SettingServicePageStyled from './styles';
import SettingSimpleMenu from './components/SettingSimpleMenu';
import ServiceSettingV2 from './components/ServicesSettingV2';
import shopSelectors from 'services/shop/selectors';
import TabsV2 from './v2';
type ISettingServicePageProps = any;

const items: TabsProps['items'] = [
  {
    key: 'services',
    label: (
      <Text variant="H7" color="text_2">
        Services
      </Text>
    ),
    children: <ServiceSettingV2 />,
  },
  {
    key: 'simple-menu',
    label: (
      <Text variant="H7" color="text_2">
        SIMPLE MENU
      </Text>
    ),
    children: <SettingSimpleMenu />,
  },
  {
    key: 'products',
    label: (
      <Text variant="H7" color="text_2">
        Products
      </Text>
    ),
    children: <ProductsSetting />,
  },
  {
    key: 'VIP',
    label: (
      <Text variant="H7" color="text_2">
        VIP
      </Text>
    ),
    children: <VIPSetting />,
  },
  {
    key: 'quick_pay',
    label: (
      <Text variant="H7" color="text_2">
        Quick Pays
      </Text>
    ),
    children: <QuickPaySetting />,
  },
  {
    key: 'turn',
    label: (
      <Text variant="H7" color="text_2">
        Turns
      </Text>
    ),
    children: <TurnTab />,
  },
  // {
  //   key: 'general-configuration',
  //   label: (
  //     <Text variant="H7" color="text_2">
  //       General Configurations
  //     </Text>
  //   ),
  //   children: <ConfigurationTab />,
  // },
  {
    key: 'payments',
    label: (
      <Text variant="H7" color="text_2">
        General Configurations
      </Text>
    ),
    children: <PaymentsTab />,
  },
];
const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
  <StickyTabStyled>
    <DefaultTabBar {...props} className="tab-no-margin" />
  </StickyTabStyled>
);

const SettingServicePage: React.FC<ISettingServicePageProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const versionUI = shopSelectors.data.versionUI();
  const v2 = versionUI === 'V2';

  const { tab } = useParams();
  const onChange = (key: string) => {
    navigate(`/store/${storage.shop_id.get()}/setting-service/${key}`);
  };

  // const debouncedHandleChange = debounce(() => undefined, 300); // 300ms delay

  // const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   // @ts-ignore
  //   debouncedHandleChange(event.target.value);
  // };

  useEffect(() => {
    dispatch(shopActions.initShopData());
    if (!tab) {
      navigate('services');
    }
  }, []);

  if (v2) {
    return (
      <SettingServicePageStyled>
        <Sidebar />
        <BodyStyled className='v2'>
          <TabsV2
            activeKey={tab || ''}
            onChange={onChange}
          />
        </BodyStyled>
      </SettingServicePageStyled>
    );
  }

  return (
    <SettingServicePageStyled>
      <Sidebar />
      <BodyStyled>
        <Tabs
          activeKey={tab}
          renderTabBar={renderTabBar}
          className={tab}
          items={items}
          onChange={onChange}
          style={{ fontWeight: 600, textTransform: 'uppercase', fontSize: 18 }}
        />
      </BodyStyled>
    </SettingServicePageStyled>
  );
};

export default SettingServicePage;

const BodyStyled = styled.div`
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
  .ant-tabs-nav-wrap {
    padding: 0 1.5rem;
  }
  .simple-menu.ant-tabs, .services.ant-tabs {
    height: 100%;
    .ant-tabs-content-holder {
      height: 100%;
      .ant-tabs-content {
        height: 100%;
        .ant-tabs-tabpane {
          height: 100%;
        }
      }
    }
  }

  &.v2 {
    background-color: #F5F5F5;
    display: flex;
    flex-direction: column;
  }

`;

const StickyTabStyled = styled.div`
  /* position: sticky;
  top: 4.5rem;
  border-top: 1px solid ${colorTheme.line_3}; */
  z-index: 1;
  height: 48px;
  background-color: white;
  .ant-tabs-nav {
    margin: 0 !important;
  }
`;
