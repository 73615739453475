import { Form, Input, Select } from 'antd';
import { CALENDAR_VIEW_TYPE } from 'features/appointment/services/constants';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import ticketListActions from 'features/ticketList/services/actions';
import ticketListSelectors from 'features/ticketList/services/selectors';
import { IApiGetTicketsParam, TRANSACTION_STATUS } from 'features/ticketList/services/types/api';
import { ICreditCardFilterFunction } from 'features/ticketList/services/types/creditCard';
import AddCloseTicket from 'features/ticketList/widgets/AddCloseTicket';
import moment, { Moment } from 'moment';
import { CSSProperties, useEffect, useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import DropDownSelectStaff from 'widgets/Staff/DropDown';
import search_icon from '../../../icons/search.svg';
import { ALL_PAYMENT, paymentTypes } from '../../HeaderTickets';
import DatePicker from './DatePicker';
import DateType from './DateType';
import btn_search_icon from './search.svg';
const inputStyles: CSSProperties = { minWidth: '12rem' };

interface IFormSearchValues {
  viewType: string;
  dates: IFormSearchDatesValue;
  last4Num: string;
  ticketNumber: string;
  staffId: string;
  paymentType: string;
}

interface IFormSearchDatesValue {
  startTime: string;
  endTime: string;
}
const getViewType = (params: IApiGetTicketsParam) => {
  if (params.startDate === params.endDate) return CALENDAR_VIEW_TYPE.DAY_VIEW;
  const monthDate = moment(params.startDate, 'MM-DD-YYYY');
  if ((monthDate.startOf('month').format('MM-DD-YYYY') === params.startDate) && (monthDate.endOf('month').format('MM-DD-YYYY') === params.endDate)) return CALENDAR_VIEW_TYPE.MONTH_VIEW;
  return CALENDAR_VIEW_TYPE.WEEK_VIEW;
};
type Props = {
  type?: keyof typeof TRANSACTION_STATUS;
  quickView?: boolean;
  creditCardFilter?: ICreditCardFilterFunction;
  isCreditPage?: boolean;
};
const TicketFilterV2 = ({ isCreditPage, quickView, type = 'OPEN_TICKET', creditCardFilter = () => undefined }: Props) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const ticketParam = ticketListSelectors.ticketsTableData.params();
  const initialValues = useMemo(() => ({
    viewType: CALENDAR_VIEW_TYPE.DAY_VIEW,
    dates: {
      startTime: ticketParam.startDate ? ticketParam.startDate : ticketParam.date,
      endTime: ticketParam.endDate ? ticketParam.endDate : ticketParam.date,
    }
  }), []);

  const onAddCloseTicketMutate = (date: Moment) => {
    const startDate = moment(date).format(DATE_FORMAT);
    const endDate = moment(date).format(DATE_FORMAT);
    const params = {
      startDate, endDate, date: date.format('MM-DD-YYYY'), page: 1,
    };
    dispatch(ticketListActions.setTicketsParams(params));
  };

  useEffect(() => {
    const currentViewType = form.getFieldValue('viewType');
    const currentDates = form.getFieldValue('dates');
    const dates = { startTime: ticketParam.startDate, endTime: ticketParam.endDate };
    if (currentDates.startTime === dates.startTime && currentDates.endTime === dates.endTime) {
      return form.setFieldsValue({
        dates,
        paymentType: !ticketParam.paymentType ? ALL_PAYMENT : ticketParam.paymentType,
        viewType: currentViewType,
      });
    }
    form.resetFields();
    form.setFieldsValue({
      dates,
      paymentType: !ticketParam.paymentType ? ALL_PAYMENT : ticketParam.paymentType,
      viewType: getViewType(ticketParam),
    });
  }, [ticketParam]);

  const handleFinish = (values: any) => {
    const data = values as IFormSearchValues;
    const params: IApiGetTicketsParam = {
      startDate: data.dates.startTime, endDate: data.dates.endTime,
      date: data.dates.startTime, page: 1,
      last4Num: data.last4Num,
      paymentType: data.paymentType === ALL_PAYMENT ? undefined : data.paymentType as PAYMENT_TYPE,
      staffId: data.staffId,
      ticketNumber: data.ticketNumber,
    };
    creditCardFilter({ startDate: params.startDate || '', endDate: params.endDate || '' });
    dispatch(ticketListActions.setTicketsParams(params));
  };

  switch (type) {
    case TRANSACTION_STATUS.DELETE:
    case TRANSACTION_STATUS.REFUND:
    case TRANSACTION_STATUS.VOID:
      return (
        <Container form={form} onFinish={handleFinish} initialValues={initialValues}>
          <Form.Item name={'dates'} noStyle>
            <DateSearch />
          </Form.Item>
          <Form.Item noStyle name={'last4Num'}>
            <Last4NumInput style={inputStyles} />
          </Form.Item>
          <Form.Item noStyle name={'ticketNumber'}>
            <TicketNoInput style={inputStyles} />
          </Form.Item>
          <Form.Item noStyle name={'paymentType'}>
            <Select
              style={{ width: '100%' }}
              className='paymentTypeSelect'
              placeholder='All Payments'
              options={paymentTypes}
            />
          </Form.Item>
          <ButtonSearch />
        </Container>
      );
    case TRANSACTION_STATUS.CLOSED:
      return (
        <Container form={form} onFinish={handleFinish} initialValues={initialValues}>
          <Form.Item name={'dates'} noStyle>
            <DateSearch />
          </Form.Item>
          <Form.Item noStyle name={'last4Num'} />
          {!quickView && <Form.Item noStyle name={'last4Num'}>
            <Last4NumInput style={inputStyles} />
          </Form.Item>}
          <Form.Item noStyle name={'ticketNumber'}>
            <TicketNoInput style={quickView ? { width: '100%' } : inputStyles} />
          </Form.Item>
          <Form.Item noStyle name={'staffId'} />
          {(!quickView && !isCreditPage) &&
            <Form.Item noStyle name={'staffId'}>
              <StaffPicker />
            </Form.Item>}
          <Form.Item noStyle name={'paymentType'} />
          {(!quickView && !isCreditPage) && (
            <Form.Item noStyle name={'paymentType'}>
              <Select
                style={{ width: '100%' }}
                className='paymentTypeSelect'
                placeholder='All Payments'
                options={paymentTypes}
              />
            </Form.Item>
          )}
          <ButtonSearch />
          {!isCreditPage && <AddCloseTicket v2 mutate={onAddCloseTicketMutate} />}
        </Container>
      );
    default:
      return (
        <Container form={form} onFinish={handleFinish} initialValues={initialValues}>
          <Form.Item name={'dates'} noStyle>
            <DateSearch />
          </Form.Item>
          <Form.Item noStyle name={'ticketNumber'}>
            <TicketNoInput style={quickView ? { width: '20rem' } : inputStyles} />
          </Form.Item>
          <ButtonSearch />
        </Container>
      );
  }
};

export default TicketFilterV2;
const ButtonSearch = () => <button type='submit' className='btn-search'><img src={btn_search_icon} alt="btn_search_icon" style={{ height: 24, width: 24 }} /><span>Search</span></button>;

const Container = styled(Form)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  .ant-select .ant-select-selector .ant-select-selection-placeholder {
    color: #1D2129;
  }
  .ant-select .ant-select-selector .ant-select-selection-placeholder, .ant-select-selection-item, .ant-input-affix-wrapper >input.ant-input {
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  button.btn-search {
    display: flex;
    height: 56px;
    padding: 0px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: #6FABB6;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -4px 0px 0px #144B55 inset;
    span {
      color: #FFF;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &:hover {
      opacity: 0.8; 
    }
  }
`;
type StaffPickerProps = {
  value?: string;
  onChange?: (value?: string | null) => void;
};
const StaffPicker = ({ value, onChange = () => undefined }: StaffPickerProps) =>
  <DropDownSelectStaff v2 allStaff value={value} onChooseAllStaff={() => onChange(null)} onChange={staff => onChange(staff.id)} />;

type DateSearchProps = {
  value?: { startTime: string; endTime: string };
  onChange?: (value: { startTime: string; endTime: string }) => void;
  quickView?: boolean;
};
const ViewType = ({ quickView, onChange, value, onChangeDate }: { onChangeDate: DateSearchProps['onChange'], quickView?: boolean, value?: CALENDAR_VIEW_TYPE, onChange?: (value: CALENDAR_VIEW_TYPE) => void }) =>
  <DateType twoWeeks={!quickView} v2 viewType={value} onChangeValue={onChangeDate} onChangeViewType={onChange} />;
const DateSearch = ({ quickView, value, onChange = () => undefined }: DateSearchProps) => {

  return (
    <>
      <Form.Item name={'viewType'} noStyle>
        <ViewType quickView={quickView} onChangeDate={onChange} />
      </Form.Item>
      <Form.Item shouldUpdate dependencies={['viewType']} noStyle>
        {({ getFieldValue }) => <DatePicker v2 value={value} onChangeValue={onChange} viewType={getFieldValue('viewType')} />}
      </Form.Item>
    </>
  );
};

const searchSuffix = <img src={search_icon} alt="search_icon" style={{ height: 24, width: 24 }} />;
const TicketNoInput = ({ value, onChange = () => undefined, style }: { style?: CSSProperties, value?: string, onChange?: (val: string) => void }) => {
  return (
    <Input
      className='ticketNumberInput'
      placeholder='Ticket No.'
      type='number'
      value={value}
      onChange={e => {
        if (e.target.value.length > 4) return e.preventDefault();
        onChange(e.target.value);
      }}
      suffix={searchSuffix}
      style={style}
    />
  );
};

const Last4NumInput = ({ value, onChange = () => undefined, style }: { style?: CSSProperties, value?: string, onChange?: (val: string) => void }) => {
  return (
    <Input
      className='ticketNumberInput'
      placeholder='Last 4 Num'
      type='number'
      value={value}
      onChange={e => {
        if (e.target.value.length > 4) return e.preventDefault();
        onChange(e.target.value);
      }}
      suffix={searchSuffix}
      style={style}
    />
  );
};
