import { Checkbox, Col, Collapse, Form } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import Box from 'components/Box';
import Button from 'components/Button';
import ModalConfirm, { useModalConfirmRef } from 'components/Modal/ModalConfirm';
import Text from 'components/Text';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import { ITurnSettingService } from 'features/settingService/services/types/reducer';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { formatCurrency } from 'utils/formatCurrency';
import { ModalSelectService } from './ModalSelectService';
import styled from 'styled-components';
import delete_icon from '../assets/delete.svg';
import add_icon from '../assets/add.svg';
import up_icon from '../assets/up.svg';

const ExpandIcon = () => <img src={up_icon} alt="up_icon" style={{ width: 32, height: 32 }} />;

interface ITurnService {
    data?: ITurnSettingService[];
    v2?: boolean;
}
const TurnService: FC<ITurnService> = ({ data, v2 }) => {
    const modalConfirmRef = useModalConfirmRef();
    const dispatch = useAppDispatch();
    const reloadTurnSetting = settingServiceSelectors.reloadTurnSetting();
    const [selectedServices, setSelectedServices] = useState<ITurnSettingService[] | undefined>(undefined);
    const [selectedTurn, setSelectedTurn] = useState<1 | 0.5 | 0 | undefined>(undefined);
    const [fullTurnSerives, setFullTurnServices] = useState<ITurnSettingService[]>([]);
    const [halfTurnSerives, setHalfTurnServices] = useState<ITurnSettingService[]>([]);
    const [noneTurnSerives, setNoneTurnServices] = useState<ITurnSettingService[]>([]);
    const [fullTurnChecked, setFullTurnChecked] = useState<ITurnSettingService[]>([]);
    const isFullTurnCheckedAll = fullTurnChecked.length === fullTurnSerives.length;
    const isFullTurnIndeterminate = fullTurnChecked.length > 0 && fullTurnChecked.length < fullTurnSerives.length;
    const [halfTurnChecked, setHalfTurnChecked] = useState<ITurnSettingService[]>([]);
    const isHalfTurnCheckedAll = halfTurnChecked.length === halfTurnSerives.length;
    const isHalfTurnIndeterminate = halfTurnChecked.length > 0 && halfTurnChecked.length < halfTurnSerives.length;
    const [noneTurnChecked, setNoneTurnChecked] = useState<ITurnSettingService[]>([]);
    const isNoneTurnCheckedAll = noneTurnChecked.length === noneTurnSerives.length;
    const isNoneTurnIndeterminate = noneTurnChecked.length > 0 && noneTurnChecked.length < noneTurnSerives.length;
    const [form] = Form.useForm();
    useEffect(() => {
        const _fullTurn: ITurnSettingService[] = [];
        const _halfTurn: ITurnSettingService[] = [];
        const _noneTurn: ITurnSettingService[] = [];
        data?.forEach(service => {
            if (service.serviceId == 'QUICK_PAY_ID') {
                form.setFieldsValue({ quickPayAmount: service.amount, turn: service.turn });
            } else if (service.turn == 1) {
                return _fullTurn.push(service);
            }
            else if (service.turn == 0.5) {
                return _halfTurn.push(service);
            } else {
                return _noneTurn.push(service);
            }
        });
        setFullTurnServices(_fullTurn);
        setHalfTurnServices(_halfTurn);
        setNoneTurnServices(_noneTurn);

    }, [data]);
    useEffect(() => {
        if (reloadTurnSetting) {
            setSelectedTurn(undefined);
            setSelectedServices(undefined);
            setFullTurnChecked([]);
            setHalfTurnChecked([]);
            setNoneTurnChecked([]);
            dispatch(settingServiceActions.reloadTurnSetting(false));
        }
    }, [reloadTurnSetting]);
    const onFinish = () => {
        dispatch(settingServiceActions.updateTurnSettingService.fetch([
            ...fullTurnSerives,
            ...halfTurnSerives,
            ...noneTurnSerives,
            {
                serviceId: 'QUICK_PAY_ID',
                serviceName: 'QuickPay',
                // turn: values.turn ?? 1,
                // amount: values.quickPayAmount ?? 0
            }]));
    };

    // const genExtra = (turn: 1 | 0.5 | 0) => (
    //     <Row onClick={(event) => {
    //         // If you don't want click extra trigger collapse, you can prevent this:
    //         event.stopPropagation();
    //         let deleteArr = [];
    //         switch (turn) {
    //             case 1:
    //                 deleteArr = fullTurnChecked.map(x => x.id);
    //                 break;
    //             case 0.5:
    //                 deleteArr = halfTurnChecked.map(x => x.id);
    //                 break;
    //             case 0:
    //             default:
    //                 deleteArr = noneTurnChecked.map(x => x.id);
    //                 break;
    //         }
    //         dispatch(settingServiceActions.deleteTurnSettingService.fetch({ ids: deleteArr }));
    //     }}
    //         align={'middle'}
    //     >
    //         <Icon type='trash' width={20} height={20} /> <Text style={{ marginLeft: 10 }}>Delete</Text>
    //     </Row>
    // );

    const renderService = (data: ITurnSettingService[], turn?: 1 | 0.5 | 0) => {
        const mappingService = data.map((m) => {
            const handleCheckService = (e: CheckboxChangeEvent) => {
                if (e.target.checked) {
                    switch (turn) {
                        case 1:
                            return setFullTurnChecked([...fullTurnChecked, m]);
                        case 0.5:
                            return setHalfTurnChecked([...halfTurnChecked, m]);
                        case 0:
                        default:
                            return setNoneTurnChecked([...noneTurnChecked, m]);
                    }
                } else {
                    switch (turn) {
                        case 1: {

                            const _fullTurnChecked = [...fullTurnChecked];
                            const idxFull = _fullTurnChecked.findIndex(f => f.id == m.id);
                            if (idxFull != -1) {
                                _fullTurnChecked.splice(idxFull, 1);
                                setFullTurnChecked(_fullTurnChecked);
                            }
                        }

                            return;
                        case 0.5: {
                            const _halfTurnChecked = [...halfTurnChecked];
                            const idxHalf = _halfTurnChecked.findIndex(f => f.id == m.id);
                            if (idxHalf != -1) {
                                _halfTurnChecked.splice(idxHalf, 1);
                                setHalfTurnChecked(_halfTurnChecked);
                            }
                        }
                            return;
                        case 0:
                        default: {
                            const _noneTurnChecked = [...noneTurnChecked];
                            const idxNone = _noneTurnChecked.findIndex(f => f.id == m.id);
                            if (idxNone != -1) {
                                _noneTurnChecked.splice(idxNone, 1);
                                setNoneTurnChecked(_noneTurnChecked);
                            }
                        }
                            return;
                    }

                }
            };
            const checked = () => {
                switch (turn) {
                    case 1:
                        return fullTurnChecked?.findIndex(f => f.id == m.id) != -1;
                    case 0.5:
                        return halfTurnChecked?.findIndex(f => f.id == m.id) != -1;
                    case 0:
                    default:
                        return noneTurnChecked?.findIndex(f => f.id == m.id) != -1;
                }
            };

            if (v2) return (
                <label className='service-item'>
                    <Checkbox className='prevent-select' onChange={handleCheckService} checked={checked()}>
                        <Col><Text className='service-name' variant='CONTENT_2'>{m.serviceName || '--'}</Text><Text className='service-price'>{formatCurrency(m.amount)}</Text></Col>
                    </Checkbox>
                </label>
            );
            return (
                <Box key={m.id} width={'25%'}>
                    <Box p='2'>
                        <Checkbox onChange={handleCheckService} checked={checked()}>
                            <Col><Text variant='CONTENT_2'>{m.serviceName}</Text><Text>{formatCurrency(m.amount)}</Text></Col>
                        </Checkbox>
                    </Box>
                </Box>);
        });


        if (v2) {
            return <Services>{mappingService}</Services>;
        }
        return <Box width={'100%'} display='flex' flexWrap='wrap'>{mappingService}</Box>;
    };
    const onShowModal = (services: ITurnSettingService[], turn: 1 | 0.5 | 0) => {
        setSelectedTurn(turn);
        setSelectedServices(services);
    };
    const handleCheckAll = (e: CheckboxChangeEvent, turn: 1 | 0.5 | 0) => {
        switch (turn) {
            case 1:
                return setFullTurnChecked(e.target.checked ? fullTurnSerives : []);
            case 0.5:
                return setHalfTurnChecked(e.target.checked ? halfTurnSerives : []);
            case 0:
            default:
                return setNoneTurnChecked(e.target.checked ? noneTurnSerives : []);
        }
    };
    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={{ listQuickPayAmount: [{ quickPayAmount: 0, turn: 0 }] }}
            onFinish={onFinish}
        >
            <CollapseBox className={v2 ? 'v2' : ''} bgColor="fill_1" px="3" pt="3" borderRadius="5px">
                <Box pb="3" mb="4">
                    <Collapse
                        className='ant-collapse-center-turn'
                        collapsible="icon"
                        defaultActiveKey={['1']}
                        expandIconPosition={'end'}
                        expandIcon={ExpandIcon}
                        items={[
                            {
                                key: '1',
                                label: <Box display='flex'>
                                    <Checkbox
                                        onChange={event => handleCheckAll(event, 1)}
                                        indeterminate={isFullTurnIndeterminate} checked={isFullTurnCheckedAll}
                                    />

                                    <Box display='flex' alignItems='center' width={'100%'} pl='2'>
                                        <Text mr={1} className='collapse-title' variant='CONTENT_2' style={{ flex: 1 }} >Full Turn</Text>
                                        {v2 ?
                                            <HeaderActions
                                                onAdd={() => onShowModal(fullTurnSerives, 1)}
                                                onDelete={() => modalConfirmRef.current?.open('Do you like to remove full turn?', () => {
                                                    const deleteArr = fullTurnChecked.map(x => x.id);
                                                    dispatch(settingServiceActions.deleteTurnSettingService.fetch({ ids: deleteArr }));
                                                })}
                                            /> :
                                            <>
                                                <Button height={2} mr={1} ntype='LIGHT_BLUE' size='small' onClick={(e) => [e.stopPropagation(), onShowModal(fullTurnSerives, 1)]} >
                                                    Add Services
                                                </Button>
                                                <Button height={2} ntype='SECONDARY' size='small' onClick={(e) => {
                                                    e.stopPropagation();
                                                    modalConfirmRef.current?.open('Do you like to remove full turn?', () => {
                                                        const deleteArr = fullTurnChecked.map(x => x.id);
                                                        dispatch(settingServiceActions.deleteTurnSettingService.fetch({ ids: deleteArr }));
                                                    });
                                                }} >
                                                    Delete
                                                </Button>
                                            </>}
                                    </Box>
                                </Box>,
                                children: renderService(fullTurnSerives, 1),
                            },
                        ]}
                    />
                    <br />
                    <Collapse
                        className='ant-collapse-center-turn'
                        collapsible="icon"
                        defaultActiveKey={['1']}
                        expandIconPosition={'end'}
                        expandIcon={ExpandIcon}
                        items={[
                            {
                                key: '1',
                                label: <Box display='flex'>
                                    <Checkbox
                                        onChange={event => handleCheckAll(event, 0.5)}
                                        indeterminate={isHalfTurnIndeterminate} checked={isHalfTurnCheckedAll}
                                    />
                                    <Box display='flex' alignItems='center' width={'100%'} pl='2'>
                                        <Text mr={1} className='collapse-title' variant='CONTENT_2' style={{ flex: 1 }} >Half Turn</Text>
                                        {v2 ?
                                            <HeaderActions
                                                onAdd={() => onShowModal(halfTurnSerives, 0.5)}
                                                onDelete={() => modalConfirmRef.current?.open('Do you like to remove half turn?', () => {
                                                    const deleteArr = halfTurnChecked.map(x => x.id);
                                                    dispatch(settingServiceActions.deleteTurnSettingService.fetch({ ids: deleteArr }));
                                                })}
                                            /> :
                                            <>
                                                <Button height={2} mr={1} ntype='LIGHT_BLUE' size='small' onClick={(e) => [e.stopPropagation(), onShowModal(halfTurnSerives, 0.5)]} >
                                                    Add Services
                                                </Button>
                                                <Button height={2} ntype='SECONDARY' size='small' onClick={(e) => {
                                                    e.stopPropagation();
                                                    modalConfirmRef.current?.open('Do you like to remove half turn?', () => {
                                                        const deleteArr = halfTurnChecked.map(x => x.id);
                                                        dispatch(settingServiceActions.deleteTurnSettingService.fetch({ ids: deleteArr }));
                                                    });
                                                }} >
                                                    Delete
                                                </Button>
                                            </>
                                        }

                                    </Box>
                                </Box>,
                                children: renderService(halfTurnSerives, 0.5),
                            },
                        ]}
                    />
                    <br />
                    <Collapse
                        className='ant-collapse-center-turn'
                        collapsible="icon"
                        defaultActiveKey={['1']}
                        expandIconPosition={'end'}
                        expandIcon={ExpandIcon}
                        items={[
                            {
                                key: '1',
                                label: <Box display='flex'>
                                    <Checkbox
                                        onChange={event => handleCheckAll(event, 0)}
                                        indeterminate={isNoneTurnIndeterminate} checked={isNoneTurnCheckedAll}
                                    />
                                    <Box display='flex' alignItems='center' width={'100%'} pl='2'>
                                        <Text mr={1} className='collapse-title' variant='CONTENT_2' style={{ flex: 1 }} >Bonus</Text>
                                        {v2 ?
                                            <HeaderActions
                                                onAdd={() => onShowModal(noneTurnSerives, 0)}
                                                onDelete={() => modalConfirmRef.current?.open('Do you like to remove bonus?', () => {
                                                    const deleteArr = noneTurnChecked.map(x => x.id);
                                                    dispatch(settingServiceActions.deleteTurnSettingService.fetch({ ids: deleteArr }));
                                                })}
                                            /> :
                                            <>
                                                <Button height={2} mr={1} ntype='LIGHT_BLUE' size='small' onClick={(e) => [e.stopPropagation(), onShowModal(noneTurnSerives, 0)]} >
                                                    Add Services
                                                </Button>
                                                <Button height={2} ntype='SECONDARY' size='small' onClick={(e) => {
                                                    e.stopPropagation();
                                                    modalConfirmRef.current?.open('Do you like to remove bonus?', () => {
                                                        const deleteArr = noneTurnChecked.map(x => x.id);
                                                        dispatch(settingServiceActions.deleteTurnSettingService.fetch({ ids: deleteArr }));
                                                    });
                                                }} >
                                                    Delete
                                                </Button>
                                            </>}
                                    </Box>
                                </Box>,
                                children: renderService(noneTurnSerives, 0),
                            },
                        ]}
                    />
                </Box>
                {/* <Box bgColor="fill_1" p="3" borderRadius="5px">
                    <Box>
                        <Box
                            display="flex"
                            flexWrap="nowrap"
                            gap="4"
                            alignItems="end"
                        >
                            <ItemFormStyled>
                                <FormItemStyled name={'quickPayAmount'} label="Quick Pay Amount" rules={[{ required: true, message: 'Missing Quick Pay Amount' }]}>
                                    <InputNumber
                                        formatter={(value: any) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        // eslint-disable-next-line
                                        parser={(value: any) => value!.replace(/\$\s?|(,*)/g, '')}
                                        controls={false}
                                        placeholder="$"
                                        style={{ width: '100%', height: '4rem' }}
                                        min={0}
                                    />
                                </FormItemStyled>
                            </ItemFormStyled>
                            <ItemFormStyled>
                                <FormItemStyled name={'turn'} label="Turn" rules={[{ required: true, message: 'Missing Turn' }]}>
                                    <Select placeholder="Select a turn" style={{ width: '100%', height: '4rem' }}>
                                        <Option value={1}>Full Turn</Option>
                                        <Option value={0.5}>Half Turn</Option>
                                        <Option value={0}>Bonus</Option>
                                    </Select>
                                </FormItemStyled>
                            </ItemFormStyled>
                        </Box>
                    </Box>
                </Box> */}
                <Box className='actions-bottom' display="flex" gap="3" mt="4" justifyContent="end">
                    <Button width="10rem" ntype="SECONDARY">
                        Close
                    </Button>
                    <Form.Item>
                        <Button width="10rem" ntype="PRIMARY" htmlType="submit">
                            Save
                        </Button>
                    </Form.Item>
                </Box>
            </CollapseBox>
            <ModalConfirm v2={v2} ref={modalConfirmRef} />
            <ModalSelectService v2={v2} visible={!!selectedServices} services={selectedServices} setServices={(services) => {
                let _services = [];
                let _serTemp = [];
                switch (selectedTurn) {
                    case 1:
                        _serTemp = [...halfTurnSerives, ...noneTurnSerives].filter(f => !services.some(s => f.id == s.id || f.serviceId == s.serviceId));
                        break;
                    case 0.5:
                        _serTemp = [...fullTurnSerives, ...noneTurnSerives].filter(f => !services.some(s => f.id == s.id || f.serviceId == s.serviceId));
                        break;
                    case 0:
                    default:
                        _serTemp = [...fullTurnSerives, ...halfTurnSerives].filter(f => !services.some(s => f.id == s.id || f.serviceId == s.serviceId));
                        break;
                }
                _services = _serTemp.concat(services);
                dispatch(settingServiceActions.updateTurnSettingService.fetch(_services));
            }} onClose={() => { setSelectedTurn(undefined); setSelectedServices(undefined); }} turn={selectedTurn} />
        </Form>
    );
};
export default TurnService;
const Services = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(300px, 1fr));

    .service-item {
        display: flex;
        padding: 16px 24px;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        flex: 1 0 0;
        border-top: 1px solid #E9EDF2;
        border-bottom: 1px solid #E9EDF2;
        border-left: 1px solid #E9EDF2;
        &:hover, &:active {
            background: #E9EDF2;
        }
        .ant-checkbox-wrapper {
            align-self: stretch;
            .ant-checkbox {
                zoom: 2;
                align-self: start;
                transform: translateY(1px)
            }
            .service-name {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                align-self: stretch;
                overflow: hidden;
                color: #1D2129;
                text-overflow: ellipsis;
                font-family: Poppins;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            .service-price {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                align-self: stretch;
                overflow: hidden;
                color: #505050;
                text-overflow: ellipsis;
                font-family: Poppins;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    }
`;
const CollapseBox = styled(Box)`&.v2 {
    .ant-collapse-item {
        .ant-collapse-header {
            border-radius: 2px;
            background: #F5F5F5;
            display: flex;
            padding: 12px 24px;
            align-items: center;
            gap: 24px;
            align-self: stretch;

            .ant-collapse-expand-icon {
                padding: 0;
                margin: 0;
            }

            .ant-checkbox-wrapper .ant-checkbox {
                zoom: 2;
            }

            .collapse-title {
                color: #1D2129;
                font-family: Poppins;
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }

        &.ant-collapse-item-active {
            .ant-collapse-header .ant-collapse-expand-icon {
                transform: rotate(180deg);
            }
        }
    }

}
`;
type HeaderActionProps = {
    onDelete: () => void;
    onAdd: () => void;
};
const HeaderActions = ({ onAdd, onDelete }: HeaderActionProps) => {
    return (
        <HeaderActionsStyled>
            <button onClick={e => [e.stopPropagation(), onDelete()]} type='button'><img src={delete_icon} alt="delete_icon" /></button>
            <button onClick={e => [e.stopPropagation(), onAdd()]} type='button'><img src={add_icon} alt="add_icon" /></button>
        </HeaderActionsStyled>
    );
};
const HeaderActionsStyled = styled.div`
display: flex;
align-items: center;
gap: 8px;
button {
    display: flex;
    width: 64px;
    height: 64px;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    img {
        width: 32px;
        height: 32px;
    }

    &:hover {
        background: #eee;
    }
}
`;