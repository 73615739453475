import React from 'react';

const PrintCheck = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.0934 6.79894C20.6059 7.48309 20.1177 8.16822 19.6337 8.8535C19.3286 9.2492 19.0319 9.65332 18.7438 10.0658C18.7004 10.1219 18.6572 10.1779 18.6139 10.2338C17.6615 11.4658 16.7215 12.6817 15.698 13.8265L15.698 13.8265L15.6931 13.8321C15.0848 14.5333 14.4517 15.2045 13.7852 15.8502C13.5308 16.0856 13.2949 16.3007 13.0512 16.4806C13.0593 16.4548 13.0677 16.4277 13.0764 16.3994C13.6584 14.917 14.4985 13.5418 15.3655 12.1426C16.2412 10.7398 17.1911 9.38069 18.1672 8.03203C18.2496 7.92024 18.3317 7.80859 18.4136 7.69713C18.9179 7.01123 19.4172 6.33228 19.9383 5.66873C20.0309 5.55904 20.1177 5.45308 20.2016 5.35081C20.4584 5.03738 20.6869 4.75858 20.9662 4.5133C21.2243 4.29971 21.3758 4.23181 21.4853 4.24269C21.6207 4.26087 21.7753 4.35791 21.9815 4.55563C22.0794 4.6531 22.1233 4.82042 22.0816 4.96545L22.0812 4.9669C21.9671 5.36822 21.7638 5.75469 21.5119 6.14071L21.2526 6.53802L21.2701 6.55082L21.0938 6.79832L21.0934 6.79894Z" fill="#232F3E" stroke="#232F3E" />
      <path d="M1.77768 7.51369C2.03974 7.36333 2.34047 7.31607 2.6369 7.32037C7.40981 7.32037 12.187 7.32037 16.9599 7.32037C16.3155 8.21395 15.6625 9.09893 15.0353 10.0054C10.8252 10.014 6.61504 10.0097 2.40491 10.014C2.40491 12.8193 2.40491 15.6289 2.40491 18.4343C2.38772 18.5245 2.46076 18.5889 2.55097 18.576C7.69335 18.5803 12.8314 18.576 17.9738 18.576C18.064 18.5889 18.1542 18.5331 18.1328 18.4343C18.1371 16.66 18.1328 14.8857 18.1328 13.1114C18.3605 12.8279 18.5838 12.5487 18.8072 12.2651C19.0177 12.4413 19.2669 12.5616 19.5333 12.6174C19.5333 14.426 19.5333 16.2347 19.5333 18.0433C19.5333 18.2753 19.5419 18.5073 19.5075 18.735C19.4302 19.2033 19.1123 19.6243 18.6827 19.8262C18.4507 19.9422 18.1843 19.9765 17.9266 19.9765C12.8014 19.9765 7.67617 19.9765 2.54668 19.9765C2.09559 19.9851 1.64451 19.7918 1.35667 19.4438C1.14187 19.1947 1.02587 18.8639 1.00439 18.5331V8.75955C1.03447 8.24832 1.3223 7.75857 1.77768 7.51369Z" fill="#232F3E" />
      <path d="M5.00827 11.5992C5.04264 11.5218 5.14145 11.5261 5.21448 11.5218C5.36914 11.5218 5.5281 11.5047 5.67416 11.5691C5.73001 11.7323 5.68705 11.9085 5.73001 12.076C5.7386 12.1362 5.81593 12.1448 5.85889 12.1577C6.09517 12.2049 6.34005 12.2565 6.55485 12.3639C6.62359 12.3939 6.65366 12.4756 6.62788 12.5486C6.58492 12.7247 6.53337 12.9052 6.46893 13.077C6.44745 13.1543 6.36153 13.1801 6.29709 13.1372C5.97059 12.9911 5.60542 12.9052 5.24885 12.9653C5.07701 12.9954 4.90087 13.1329 4.90946 13.3262C4.91806 13.5195 5.09849 13.6355 5.24885 13.7171C5.59254 13.8933 5.97059 14.0007 6.2885 14.2284C6.63648 14.4775 6.85128 14.9071 6.81691 15.3367C6.79543 15.6718 6.62789 15.9897 6.37442 16.2045C6.20687 16.3592 5.98777 16.4322 5.78156 16.5096C5.6355 16.574 5.68705 16.763 5.67846 16.8919C5.66987 17.0036 5.71283 17.1583 5.60113 17.227C5.47654 17.2657 5.34336 17.2442 5.21878 17.2485C5.12856 17.2442 4.99538 17.2571 4.96102 17.1497C4.91806 16.9821 4.96961 16.8017 4.92235 16.6341C4.89658 16.5697 4.81065 16.574 4.75481 16.5568C4.47986 16.5096 4.20491 16.4451 3.95574 16.3076C3.79679 16.2217 3.89559 16.0327 3.92567 15.8995C3.98152 15.7663 3.97722 15.5773 4.09751 15.4871C4.27365 15.5086 4.42401 15.616 4.59156 15.6632C4.88369 15.7406 5.2016 15.8179 5.49373 15.7105C5.68705 15.6589 5.84171 15.4613 5.80304 15.2594C5.76438 15.0446 5.56676 14.92 5.38203 14.8341C4.96102 14.6494 4.49704 14.5033 4.17054 14.1596C3.71086 13.6656 3.85693 12.7849 4.41971 12.4326C4.58296 12.308 4.79777 12.2736 4.96102 12.1534C5.02546 11.9772 4.95672 11.7796 5.00827 11.5992Z" fill="#232F3E" />
      <path d="M8.14463 11.6852C8.14463 11.5778 8.25203 11.5048 8.35084 11.5176C10.2669 11.5133 12.1872 11.5176 14.1076 11.5176C13.9099 11.8355 13.7209 12.1578 13.519 12.4757C11.7963 12.4714 10.0736 12.4757 8.35084 12.4714C8.24773 12.4843 8.14033 12.4026 8.14463 12.2909C8.13604 12.089 8.13604 11.8871 8.14463 11.6852Z" fill="#232F3E" />
      <path d="M8.14436 13.2747C8.14436 13.1716 8.24747 13.0985 8.34628 13.1071C9.94441 13.1028 11.5468 13.1071 13.1493 13.1071C12.9731 13.425 12.7927 13.7472 12.6123 14.0651C11.1903 14.0651 9.76827 14.0651 8.35058 14.0651C8.24317 14.0737 8.14007 13.9921 8.14436 13.8847C8.14007 13.6785 8.13577 13.4766 8.14436 13.2747Z" fill="#232F3E" />
      <path d="M8.14448 14.8599C8.14448 14.7568 8.24759 14.6838 8.3464 14.6967C9.66099 14.6924 10.9756 14.6967 12.2902 14.6967C12.1484 15.0146 11.998 15.3325 11.8606 15.6504C10.7049 15.659 9.55359 15.6504 8.39795 15.6547C8.29484 15.6633 8.16596 15.6289 8.14878 15.5129C8.13159 15.2938 8.13589 15.079 8.14448 14.8599Z" fill="#232F3E" />
      <path d="M8.14023 16.4495C8.14453 16.3421 8.25193 16.2777 8.35074 16.2863C9.44194 16.282 10.5374 16.2863 11.6329 16.2863C11.547 16.5956 11.4955 16.9221 11.547 17.2443C10.4816 17.2443 9.42046 17.2443 8.35504 17.2443C8.24334 17.2572 8.13164 17.1756 8.14023 17.0596C8.13164 16.8577 8.13164 16.6514 8.14023 16.4495Z" fill="#232F3E" />
    </svg>
  );
};

export default PrintCheck;
