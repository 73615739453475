import { Avatar, Col, Row } from 'antd';
import { DropdownIcon } from 'assets/Icons/AppointmentIcons';
import Button from 'components/Button';
import Text from 'components/Text';
import { get } from 'lodash';
import { useMemo, useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import { IStaffItemData } from 'services/shop/types/staff';
import { styled } from 'styled-components';
import ModalChooseStaff from './ModalChooseStaff';
type Props = {
  value?: string;
  onChange?: (val: IStaffItemData) => void;
  onChooseAllStaff?: () => void;
  allStaff?: boolean;
  v2?: boolean;
};
const DropDownSelectStaff = ({ v2, value, onChange = () => undefined, allStaff, onChooseAllStaff }: Props) => {
  const staffs = shopSelectors.data.staffs();
  const [visible, setVisible] = useState(false);

  const activeStaff = useMemo(() => {
    return staffs.find(o => o.id === value);
  }, [value, staffs]);

  return (
    <>
      <Select style={v2 ? { width: '100%' } : undefined} className={allStaff ? 'no-shadow' : ''} textWidth='100%' onClick={() => setVisible(true)}>
        <Row align={'middle'} className='row-info' wrap={false}  >
          {
            !!allStaff && !activeStaff ? <Text style={{ flex: 1 }} variant='CONTENT_2' className='text-overflow' textAlign='left'  >All Staff</Text> :
              <>
                <Avatar size={'small'}
                  src={activeStaff?.urlImage}
                  style={{ backgroundColor: '#f56a00' }}>{get(activeStaff?.firstName, [0], '')}</Avatar>
                <Text textAlign='left' variant='H9' className='text-overflow'  >{activeStaff?.firstName}</Text>
                <Col flex={'auto'}></Col>
              </>
          }
          <DropdownIcon />
        </Row>
      </Select>
      <ModalChooseStaff preventAnyone={allStaff} onChooseAllStaff={onChooseAllStaff} visible={visible} onChooseStaff={onChange} onClose={() => setVisible(false)} />
    </>
  );
};

export default DropDownSelectStaff;

const Select = styled(Button)`
  width: 20rem;
  .row-info {
    gap: 0.5rem;
  }
  &.no-shadow {
    box-shadow: none;
  }

  .CONTENT_2, .H9 {
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;