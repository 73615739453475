import React, { useMemo } from 'react';
import { Button } from 'antd';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import ticketListActions from 'features/ticketList/services/actions';
import ticketListSelectors from 'features/ticketList/services/selectors';
import { Bill } from 'features/ticketList/services/types/api';

type IAdjustTipButtonProps = {
  record: Bill;
  v2?: boolean;
};
const AdjustTipButton: React.FC<IAdjustTipButtonProps> = ({ record, v2 }) => {
  const dispatch = useAppDispatch();
  const adjustTipStore = ticketListSelectors.adjustTip();

  const isDisabled = useMemo(() => {
    const exist = adjustTipStore?.find((o) => o.billId === record.billId);
    return !exist || !exist.tip;
  }, [adjustTipStore]);

  const handleClick = () => {
    const billId = record.billId;
    dispatch(ticketListActions.adjustTip.fetch({ billId }));
  };

  return (
    <ActionButton v2={v2} onClick={handleClick} disabled={isDisabled}>
      ADJUST
    </ActionButton>
  );
};

export default AdjustTipButton;
const ActionButton = styled(Button) <{ v2?: boolean }>`
  display: flex;
  padding: 8px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: var(--info-infor-3, #6fabb6) !important;
  flex-grow: 1;
  flex-basis: 1rem;
  cursor: pointer;
  color: var(--text-text-1, #fff) !important;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-color: var(--info-infor-3, #6fabb6) !important;
  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.5;
  }

  ${({ v2 }) => v2 && `
    display: flex;
    height: 38px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: #6FABB6 !important;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -4px 0px 0px #144B55 inset !important;
    .CONTENT_2, span {
      color: #fff !important;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  `}
`;
