import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import {
  PercentInputRef,
  dollarAmountNumberMask,
  parseInputValue,
  parseOutputValue,
  parseStrInputValue,
} from './helpers';
import { InputProps } from 'antd';
import { delay } from 'lodash';
const ContainerStyled = styled.label`
  border: 1px solid #86909c;
  min-width: 100px;
  border-radius: 4px;
  background: #fff;
  height: 52px;
  padding: 0 12px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  &:hover {
    cursor: text;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  input {
    padding: 0;
    border: none;
    outline: none;
    box-shadow: none;
    background: #fff;
    position: relative;
    z-index: 2;

    &::placeholder {
      color: #64748b;
      opacity: 1;
    }

    &::-ms-input-placeholder {
      color: #64748b;
    }
  }

  span {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
  }

  .dollar-input-text {
    color: #232f3e;
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  span.text-measure {
    visibility: hidden;
    white-space: pre;
    position: absolute;
  }
  span.text-placeholder {
    white-space: pre;
    position: absolute;
    color: #64748b !important;
    display: flex;
    align-items: center;
  }

  &.dollar-input-selector > div{
    margin-bottom: -2px;
  }

  &.dollar-input-selector-focus {
    border: 1px solid #ffb0b3;
  }
`;

type Props = {
  value?: number;
  onChange?: (value: number) => void;
  maxValue?: number;
  inputMode?: InputProps['inputMode'];
  onFocus?: InputProps['onFocus'];
  disabled?: boolean;
};

const PercentInput = forwardRef<PercentInputRef, Props>(
  (
    {
      disabled,
      value: valueProp,
      maxValue,
      onChange = () => undefined,
      inputMode,
      onFocus = () => undefined,
    },
    ref
  ) => {
    const [text, setText] = useState('');
    const selectorRef = useRef<HTMLLabelElement>(null);
    const inputSelectorRef = useRef<HTMLDivElement>(null);
    const textMeasureRef = useRef<HTMLSpanElement>(null);
    const textPlaceholderRef = useRef<HTMLSpanElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const value = text || parseInputValue(valueProp);
    const setValue: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      const numericValue = parseOutputValue(e.target.value);
      if (maxValue && numericValue > maxValue) return e.preventDefault();
      setText(e.target.value);
      onChange(numericValue);
    };

    const updateInputWidth = () => {
      if (textMeasureRef.current && inputRef.current) {
        const width = textMeasureRef.current.offsetWidth;
        inputRef.current.style.width = `${width}px`;
      }
      if (textPlaceholderRef.current && inputSelectorRef.current) {
        const width = textPlaceholderRef.current.offsetWidth;
        inputSelectorRef.current.style.width = `${width}px`;
      }
    };

    useEffect(() => {
      updateInputWidth();
    }, [value]);

    useImperativeHandle(ref, () => ({
      focus: (_value) => {
        const _text = _value || parseInputValue(0);
        const numericValue = parseOutputValue(_text);
        inputRef.current?.focus();
        delay(
          () =>
            inputRef.current?.setSelectionRange(
              _text.length - 1,
              _text.length - 1
            ),
          20
        );
        if (maxValue && numericValue > maxValue) return;
        setText(_text);
      },
      init: (_value, unFocus = false) => {
        const _text = parseStrInputValue(_value) || parseInputValue(0);
        const numericValue = parseOutputValue(_text);
        if (maxValue && numericValue > maxValue) return;
        setText(_text);
        onChange(numericValue);
        // @ts-ignore
        if (!unFocus) inputRef.current?.focus({ cursor: 'end' });
      },
      clear: () => {
        setText(parseInputValue(0));
        onChange(0);
      },
      getText: () => (text === '$0' ? '$' : text),
    }));
    const _onFocus: InputProps['onFocus'] = (e) => {
      selectorRef.current?.classList.add('dollar-input-selector-focus');
      delay(
        () =>
          inputRef.current?.setSelectionRange(
            value?.length - 1,
            value?.length - 1
          ),
        20
      );
      onFocus(e);
    };
    return (
      <ContainerStyled
        ref={selectorRef}
        className='dollar-input-selector'
        style={disabled ? { pointerEvents: 'none' } : {}}
      >
        <div
          ref={inputSelectorRef}
          style={{
            position: 'relative',
            display: 'inline-flex',
            alignSelf: 'stretch',
            alignItems: 'center',
          }}
        >
          {/* @ts-ignore */}
          <MaskedInput
            mask={dollarAmountNumberMask}
            value={value}
            inputMode={inputMode}
            onChange={setValue}
            render={(textMaskRef: any, props: any) => {
              return (
                <input
                  ref={(ref) => {
                    textMaskRef(ref);
                    // @ts-ignore
                    inputRef.current = ref;
                  }}
                  {...props}
                  value={value}
                  placeholder='0%'
                  onFocus={_onFocus}
                  onBlur={() =>
                    selectorRef.current?.classList.remove(
                      'dollar-input-selector-focus'
                    )
                  }
                  className='dollar-input-text'
                />
              );
            }}
          />
          <span className='dollar-input-text text-measure' ref={textMeasureRef}>
            {value || '0%'}
          </span>
          <span
            style={{ lineHeight: 'unset' }}
            ref={textPlaceholderRef}
            className='dollar-input-text text-placeholder'
          >
            %
          </span>
        </div>
      </ContainerStyled>
    );
  }
);

PercentInput.displayName = 'PercentInput';
export default PercentInput;
