import { Checkbox, Collapse, Form, FormInstance } from 'antd';
import { AbstractCheckboxProps } from 'antd/es/checkbox/Checkbox';
import { CollapseProps } from 'antd/lib';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Box from 'components/Box';
import settingStaffActions from 'features/settingStaff/services/actions';
import settingStaffSelectors from 'features/settingStaff/services/selectors';
import { find, unionBy } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { ADD_ON_CATE_ID } from 'services/shop/constants';
import shopSelectors from 'services/shop/selectors';
import { ICategoryItemData, IServiceItemData } from 'services/shop/types/categories';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import ButtonGroup from '../components/ButtonGroup';
type IStaffSpecializeProps = {
};

const panelStyle: React.CSSProperties = {
  marginBottom: 12,
  borderRadius: 4,
  border: '1px solid #CCD4DC',
};

const StaffSpecialize: React.FC<IStaffSpecializeProps> = ({ }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const servicesSelected = settingStaffSelectors.servicesSelected();
  const staffSelected = settingStaffSelectors.selectedStaffData.data();
  const _categories = shopSelectors.data.categories();
  const addonServices = shopSelectors.data.addonServices();

  const categories = useMemo(() => {
    const categoryAddon: ICategoryItemData = {
      categoryName: 'ADD-ON',
      id: ADD_ON_CATE_ID,
      services: addonServices.map(o => ({
        ...o,
        catId: ADD_ON_CATE_ID
      })),
    };
    return unionBy(_categories, [categoryAddon], 'id') ?? [];
  }, [_categories, addonServices]);

  useEffect(() => {
    form.setFieldValue('services', servicesSelected.map(o => ({
      label: o.serviceName,
      value: o.serviceId,
    })));
  }, [servicesSelected]);
  const handleFinish = (values: any) => {
    const body = {
      staffId: staffSelected.id,
      serviceIds: values.services.map((item: { value: string }) => item.value),
    };
    dispatch(settingStaffActions.staffUpdateMappingService.fetch(body));
  };

  const items: CollapseProps['items'] = useMemo(() => {
    return categories.map(o => ({
      key: o.id,
      label: <CategoryLabel data={o} form={form} />,
      children: <CateServices data={o} form={form} />,
      style: panelStyle,
      headerClass: 'custom-header',
    }));
  }, [categories, form]);

  return (
    <StaffSpecializeStyled form={form} onFinish={handleFinish} initialValues={{ services: [] }}>
      <Collapse
        bordered={false}
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => <ExpandIcon active={isActive} />}
        items={items}
        expandIconPosition='end'
      />
      <Form.Item noStyle name={'services'} />
      <Box mt="3">
        <ButtonGroup />
      </Box>
    </StaffSpecializeStyled>

  );
};

export default StaffSpecialize;
const StaffSpecializeStyled = styled(Form)`
.ant-collapse-header.headerClass {
    background: #CCD4DC;
    align-items: center;
    padding: 4px 16px;
  }
.ant-collapse-content>.ant-collapse-content-box  {
  padding: 8px;
}

.ant-checkbox-wrapper {
  zoom: 2.5;
  height: 20px;
}
.ant-checkbox-checked:after {
  border: 2px solid #2D6772;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, 
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: #6FABB6;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
    border-color: #6FABB6;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner, 
.ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  background-color: #6FABB6;
}
.ant-checkbox-indeterminate .ant-checkbox-inner:after , .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2D6772;
  border-color: #2D6772;
}
`;
const CategoryLabelStyled = styled.div`
display: flex;
align-items: center;
gap: 16px;
.category-label {
  flex: 1;
  color: #1D2129;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.category-active {
  color: #64748B;
  text-align: right;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
`;
type CategoryLabelProps = {
  form: FormInstance<any>;
  data: ICategoryItemData;
};
const CategoryLabel = ({ form, data }: CategoryLabelProps) => {
  const cateServices = data.services || [];
  const selectedServices = Form.useWatch('services', form) || [];
  const cateSelectedServices = useMemo(() => selectedServices?.filter((o: any) => cateServices.some(s => s.id === o.value)), [selectedServices, cateServices]) || [];

  const indeterminate = cateSelectedServices.length > 0 && cateSelectedServices.length < cateServices.length;
  const checkAll = cateSelectedServices.length > 0 && cateSelectedServices.length === cateServices.length;
  const onCheckAllChange: AbstractCheckboxProps<CheckboxChangeEvent>['onChange'] = (e) => {
    e.stopPropagation();
    const temp = [...selectedServices].filter((o: any) => !cateServices.find(s => s.id === o.value));

    if (e.target.checked) {
      cateServices.forEach(s => {
        temp.push({ label: s.serviceName, value: s.id });
      });
    }
    form.setFieldValue('services', temp);
  };
  return (
    <CategoryLabelStyled>
      <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} onClick={(e) => e.stopPropagation()} />
      <span className="category-label">
        {data.categoryName || '--'}
      </span>
      <span className='category-active'>Active {cateSelectedServices.length}/{cateServices.length} services</span>
    </CategoryLabelStyled>
  );
};
const ExpandIcon = ({ active }: { active?: boolean }) => (
  <svg style={{
    transform: `rotate(${!active ? -90 : 0}deg)`,
  }} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path d="M4 10.6667C4 10.2667 4.13333 10 4.4 9.73337C4.93333 9.20004 5.73333 9.20004 6.26667 9.73337L16 19.4667L25.7333 9.73337C26.2667 9.20004 27.0667 9.20004 27.6 9.73337C28.1333 10.2667 28.1333 11.0667 27.6 11.6L16.9333 22.2667C16.4 22.8 15.6 22.8 15.0667 22.2667L4.4 11.6C4.13333 11.3334 4 11.0667 4 10.6667Z" fill="#232F3E" />
  </svg>
);

const CateServicesStyled = styled.div`

.service-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 24px;
  border-radius: 4px;
  &:hover, &:active {
    cursor: pointer;
    background: #eee;
  }
  .service-name {
    color: #505050;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
`;
type CateServicesProps = {
  form: FormInstance<any>;
  data: ICategoryItemData;
};
const CateServices = ({ data, form }: CateServicesProps) => {
  const selectedServices = Form.useWatch('services', form) || [];
  const onChooseService = (o: IServiceItemData) => () => {
    const temp = [...selectedServices];
    const index = temp.findIndex((s: any) => s.value === o.id);
    if (index > -1) {
      temp.splice(index, 1);
    } else {
      temp.push({ label: o.serviceName, value: o.id });
    }
    form.setFieldValue('services', temp);
  };

  return (
    <CateServicesStyled>
      {data.services?.map(o => {
        const active = !!find(selectedServices, s => s.value === o.id);
        return (
          <label key={o.id} className='service-item'>
            <Checkbox checked={active} onClick={onChooseService(o)} />
            <span className='service-name'>
              {o.serviceName}
            </span>
          </label>
        );
      })}
    </CateServicesStyled>
  );
};