
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { setLoadingPage } from 'services/UI/sagas';
import { ISagaFunc } from 'services/actionConfigs';
import { IResponseDataBody } from 'services/response';
import { Message } from 'stompjs';
import actions from './actions';
import apis from './apis';
import { checkIsShareTip } from './selectors';
import { IBodyApiSplitTipPayment } from './types/api';
import { IBillDetailData } from './types/bill';
import { IInfoPrinterWaitingResData, IMessageCheckIn, IMessageGroupCheckIn } from './types/ticket';
import shopSelectors from 'services/shop/selectors';
import { IShopAllSetting } from 'services/shop/types/setting';

const getBillDetail: ISagaFunc<string> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(200);
  try {
    const res: IResponseDataBody<IBillDetailData> = yield call(apis.getBillDetail, payload);
    if (res.data.data) {
      yield put(actions.getBillDetail.success(res.data.data));
    }
  } catch (error) { } finally {
    yield setLoadingPage(false);
  }
};

const getPrintBillDetail: ISagaFunc<string> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(200);
  try {
    const res: IResponseDataBody<IBillDetailData> = yield call(apis.getBillDetail, payload);
    if (res.data.data) {
      yield put(actions.getPrintBillDetail.success(res.data.data));
    }
  } catch (error) { } finally {
    yield setLoadingPage(false);
  }
};

const checkPaymentInfoAfterPaid = function* () {
  const isShareTip: boolean = yield select(checkIsShareTip);
  yield put(actions.setVisibleShareTip(isShareTip));
};

const updateSplitTip: ISagaFunc<IBodyApiSplitTipPayment> = function* ({ payload }) {
  yield setLoadingPage(true);
  try {
    const res: IResponseDataBody<boolean> = yield apis.splitTip(payload);
    if (res.data.data) {

    }
  } catch (error) { }
  finally {
    yield setLoadingPage(false);
  }
};

const getInfoPrintWaiting: ISagaFunc<Message> = function* ({ payload }) {
  if (!payload.body) return;
  yield setLoadingPage(true);
  yield delay(100);

  const payment: IMessageCheckIn = JSON.parse(payload.body);
  try {
    const res: IResponseDataBody<IInfoPrinterWaitingResData> = yield call(apis.getInfoPrintWaiting, payment.type, payment.id);
    if (res.data.data) {
      yield put(actions.getInfoPrintWaiting.success(res.data.data));
    }
  } catch (error) { }
  finally {
    yield setLoadingPage(false);
  }
};
const getInfoPrintGroupWaiting: ISagaFunc<Message> = function* ({ payload }) {
  if (!payload.body) return;
  const allSetting: IShopAllSetting = yield select(shopSelectors.pureData.allSetting);
  if (!allSetting.enablePrintCheckIn) return;
  yield setLoadingPage(true);
  yield delay(100);

  const payment: IMessageGroupCheckIn = JSON.parse(payload.body);
  try {
    const res: IResponseDataBody<IInfoPrinterWaitingResData> = yield call(apis.getInfoPrintGroupWaiting, payment.type, payment.id);
    if (res.data.data) {
      yield put(actions.getInfoPrintGroupWaiting.success(res.data.data));
    }
  } catch (error) { }
  finally {
    yield setLoadingPage(false);
  }
};

const cancelTransaction: ISagaFunc<string> = function* ({ payload }) {
  try {
    yield call(apis.cancelTransaction, payload);
  } catch (error) {
  }
};

const initData = function* () {
  yield all([]);
};

export default function* paymentServiceSagas() {
  yield takeLatest(actions.getBillDetail.fetch, getBillDetail);
  yield takeLatest(actions.getPrintBillDetail.fetch, getPrintBillDetail);
  yield takeLatest(actions.initData, initData);
  yield takeLatest(actions.checkPaymentInfoAfterPaid, checkPaymentInfoAfterPaid);
  yield takeLatest(actions.updateSplitTip.fetch, updateSplitTip);
  yield takeLatest(actions.getInfoPrintWaiting.fetch, getInfoPrintWaiting);
  yield takeLatest(actions.getInfoPrintGroupWaiting.fetch, getInfoPrintGroupWaiting);
  yield takeLatest(actions.cancelTransaction.fetch, cancelTransaction);
}
