import { get, unionBy } from 'lodash';
import uiSelector from 'services/UI/selectors';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { ADD_ON_CATE_ID, PATH_LOADING } from './constants';
import { ICategoryItemData } from './types/categories';
import { ILoyaltySetting } from './types/setting';

export type MyState = RootState['shop'];

export type IShopState = MyState;

export const getCurrentState = (state: RootState): MyState => state['shop'];

export const getPureStoreData = (root: RootState) => {
  const state = getCurrentState(root);
  return ({
    categories: state?.categories ?? [],
    addonServices: state?.addonServices ?? [],
    staffs: state?.staffs ?? [],
    customers: state?.customers ?? [],
  });
};

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const getCategories = () => selector('categories') as MyState['categories'];

const pureData = {
  allSetting: (root: RootState) => getCurrentState(root).allSetting as MyState['allSetting'],
};

const data = {
  categories: () => selector('categories') as MyState['categories'],
  addonServices: () => selector('addonServices') as MyState['addonServices'],
  staffs: () => selector('staffs') as MyState['staffs'],
  staffsAvailable: () => selector('staffsAvailable') as MyState['staffsAvailable'],
  customers: () => selector('customers') as MyState['customers'],
  recentCustomers: () => selector('recentCustomers') as MyState['recentCustomers'],
  shopInfo: () => selector('shopInfo') as MyState['shopInfo'],
  specialDiscounts: () => selector('specialDiscounts') as MyState['specialDiscounts'],
  coupons: () => selector('coupons') as MyState['coupons'],
  discountSetting: () => selector('discountSetting') as MyState['discountSetting'],
  orderPaymentMethods: () => selector('orderPaymentMethods') as MyState['orderPaymentMethods'],
  allSetting: () => selector('allSetting') as MyState['allSetting'],
  getLoyaltySetting: () => selector('allSetting' + '.' + 'loyaltySetting', null) as ILoyaltySetting | null,
  checkInOnlineLink: () => selector('checkInOnlineLink') as MyState['checkInOnlineLink'],

  categoriesIncludeAddon: () => {
    const categories = data.categories();
    const addonServices = data.addonServices();
    const categoryAddon: ICategoryItemData = {
      categoryName: 'ADD-ON',
      id: ADD_ON_CATE_ID,
      services: addonServices.map(o => ({
        ...o,
        catId: ADD_ON_CATE_ID
      })),
    };
    return unionBy(categories, [categoryAddon], 'id') ?? [];
  },

  productCategories: () => selector('productCategories') as MyState['productCategories'],
  productsVIP: () => selector('productsVIP') as MyState['productsVIP'],
  totalStation: () => selector('totalStation') as MyState['totalStation'],
  scheduleBooking: () => selector('scheduleBooking') as MyState['scheduleBooking'],
  couponPrint: () => selector('couponPrint') as MyState['couponPrint'],
  feeCreditCard: () => selector('feeCreditCard') as MyState['feeCreditCard'],
  taxConfig: () => selector('taxConfig') as MyState['taxConfig'],
  productTaxConfig: () => selector('productTaxConfig') as MyState['productTaxConfig'],
  simpleMenu: () => selector('simpleMenu') as MyState['simpleMenu'],
  simpleMenuConfigCheckIn: () => selector('simpleMenuConfigCheckIn') as MyState['simpleMenuConfigCheckIn'],
  optionTips: () => selector('optionTips') as MyState['optionTips'],
  optionTipsAll: () => selector('optionTipsAll') as MyState['optionTipsAll'],
  quickBookingService: () => selector('quickBookingService') as MyState['quickBookingService'],
  showModalTip: () => {
    const allSetting = selector('allSetting');
    if (allSetting?.showModalTip === false) return false;
    return true;
  },
  versionUI: () => selector('versionUI') as MyState['versionUI'],
  staffsAvailableBooking: () => selector('staffsAvailableBooking') as MyState['staffsAvailableBooking'],
  printCheckSetting: () => selector('printCheckSetting') as MyState['printCheckSetting'],
  settingReferral: () => selector('settingReferral') as MyState['settingReferral'],
};

const loading = {
  categories: () => uiSelector.getLoading(PATH_LOADING.getCategories) as boolean,
  getCategoriesWithoutBE: () => uiSelector.getLoading(PATH_LOADING.getCategoriesWithoutBE) as boolean,
  productCategories: () => uiSelector.getLoading(PATH_LOADING.getProductCategories) as boolean,
  addonServices: () => uiSelector.getLoading(PATH_LOADING.getAddOnList) as boolean,
  staffs: () => uiSelector.getLoading(PATH_LOADING.getStaffList) as boolean,
  customers: () => uiSelector.getLoading(PATH_LOADING.getCustomerList) as boolean,
  productsVIP: () => uiSelector.getLoading(PATH_LOADING.getProductsVIP) as boolean,
  simpleMenu: () => uiSelector.getLoading(PATH_LOADING.getSimpleMenu) as boolean,
};


const shopSelectors = {
  data,
  pureData,
  loading,
  getCategories,
};
export default shopSelectors;
