import { Button, DatePicker, Form, Input, InputRef } from 'antd';
import DollarInputPayment from 'components/DollarAmount/DollarInputPayment';
import Modal from 'components/Modal';
import reportApis from 'features/report/services/apis';
import { reportUIActions } from 'features/report/services/reducers';
import ModalSettingPrintCheck, { useModalSettingPrintCheckRef } from 'features/settingService/pages/SettingPage/components/PaymentTabMenu/PrintCheck/Modal';
import dayjs from 'dayjs';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import shopSelectors from 'services/shop/selectors';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import CheckUI from '../CheckUI';
import { IPrintCheckInfoData, IPrintCheckInfoDataSubmitValues } from '../type';
import { useDollarAmountInputRef } from 'components/DollarAmount/Input';
import { delay } from 'lodash';
import Box from 'components/Box';
import UploadQRImage from 'features/settingService/pages/SettingPage/components/PaymentTabMenu/components/UploadQRImage';
import CheckSignature, { useCheckSignature } from '../Signature';
const FormStyled = styled(Form)`
  display: flex;
  width: 100%;
  .form-item
`;

const FormCheck = styled.div`
  display: flex;
  padding-bottom: 24px;
  flex-direction: column;
  align-items: flex-start;
  border-right: 1px solid #86909C;
  background: #F6F7FC;

  .staff-info {
    display: grid;
    padding: 0px 24px;
    flex-direction: column;
    align-items: flex-start;
    zoom: 1.5;
    column-gap: 16px;
    align-self: stretch;
    grid-template-columns: 1fr 1fr;
  }

  .check-ui {
    zoom: 1.5;
    align-self: stretch;
    padding: 24px 32px;
  }
  .ant-input-password, .ant-input-number, .ant-input, .ant-picker, .ant-select {
    height: 3rem;
  }
  .dollar-input-selector {
    height: 3rem;
    background: #fff;
    padding: 0 16px;
    .dollar-input-text {
    }
  }
`;
const FooterActions = styled.div`
  display: flex;
  padding: 16px !important;
  align-items: center;
  gap: 16px;
  button.ant-btn {
    color: #1D2129;
    border: 1px solid #86909C;
    display: flex;
    height: 70px;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0;
    border-radius: 4px;
    background: #E5E6EB;
    span {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &.ant-btn-primary {
      background-color: #ff8890;
      border: 1px solid #f5767f;
      color: #1d2129;
    }

    &:disabled {
      opacity: 0.7;
    }
  }
`;
const initialValues: IPrintCheckInfoData = {
  staffId: '',
  staffName: '',
  total: 0,
  commissionPercent: 0,
  commission: 0,
  totalSales: 0,
  supplyFee: 0,
  totalPaidOut: 0,
  bonus: 0,
  bonusPercent: 0,
  check: 0,
  checkPercent: 0,
  tipToBonus: 0,
  tipToBonusPercent: 0,
  tipToCheck: 0,
  tipCheckPercent: 0,
  totalBonus: 0,
  totalCheck: 0,
  collectedTips: 0,
  date: dayjs(),
};
const FormItems = () => {
  return <>{Object.keys(initialValues).map((key) => {
    return (
      <Form.Item key={key} noStyle name={key} />
    );
  })}</>;
};

type Props = {};

type Ref = {
  open: () => void;
};
export const useModalPrintCheckManualRef = () => useRef<Ref>(null);
const ModalPrintCheckManual = forwardRef<Ref, Props>(({ }, ref) => {
  const dispatch = useAppDispatch();
  const printCheckSetting = shopSelectors.data.printCheckSetting();
  const { staff_id = '', shop_id = '' } = useParams<{ tab: string, staff_id: string, shop_id: string }>();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const setLoading = useSetLoadingPage();
  const dollarRef = useDollarAmountInputRef();
  const checkRef = useCheckSignature();
  const inputStaffRef = useRef<InputRef>(null);

  useEffect(() => {
    if (printCheckSetting) {
      const checkBank = {
        bankName: printCheckSetting.bankName,
        bankAddress: printCheckSetting.bankAddress,
        bankWebsite: printCheckSetting.bankWebsite,
        bankLogo: printCheckSetting.bankLogo,
      };
      const base64 = 'data:image/png;base64,' + printCheckSetting.signature;
      form.setFieldValue('signature', base64);
      form.setFieldValue('checkBank', checkBank);
      checkRef.current?.setValue(base64);
    }
  }, [printCheckSetting]);

  const getCheckNum = async () => {
    const res = await reportApis.getCheckNum();
    if (res.data.data) {
      delay(() => {
        dollarRef.current?.updateInputWidth();
        inputStaffRef.current?.focus();
      }, 100);
      form.setFieldValue('checkNo', res.data.data);
    }
  };

  const onOpen: Ref['open'] = async () => {
    form.resetFields();
    if (printCheckSetting) {
      const base64 = 'data:image/png;base64,' + printCheckSetting.signature;
      form.setFieldsValue({
        date: dayjs(),
        checkBank: {
          bankName: printCheckSetting.bankName,
          bankAddress: printCheckSetting.bankAddress,
          bankWebsite: printCheckSetting.bankWebsite,
          bankLogo: printCheckSetting.bankLogo,
        },
        signature: base64,
        forCheckNote: '',
      });
      delay(() => {
        checkRef.current?.setValue(base64);
      }, 100);
    }
    setLoading(true);
    try {
      await getCheckNum();
      setOpen(true);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const handleFinish = async (values: any) => {
    const data = values as IPrintCheckInfoDataSubmitValues;
    setLoading(true);
    try {
      await reportApis.savePrintCheckHistory(data.staffId, data.checkNo);
      dispatch(reportUIActions.savePrintCheckPreviewData(data));
      navigate(`/store/${shop_id}/report/preview-check`);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    open: onOpen
  }));

  return (
    <Modal
      v2 modalTitle='PRINT CHECK MANUAL'
      visible={open} onClose={onClose} width={'auto'}
      containerPadding={0}
      okTitle="OK"
      noneBodyStyle
      onSubmit={() => form.submit()}
      footer={null}
      className='modal-overflow-unset modal-max-height-unset'
    >
      <FormStyled layout='vertical' form={form} onFinish={handleFinish}
        initialValues={initialValues}
      >
        <FormItems />
        <Form.Item noStyle name={'checkBank'} />
        <Box display='flex'>
          <FormCheck style={{ width: '1200px' }}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldsValue }) => {
                const data = getFieldsValue();
                return (
                  <div className='check-ui'>
                    <CheckUI
                      checkNum={data?.checkNo}
                      date={data?.date?.format('MM/DD/YYYY') || '--'}
                      name={data?.staffName}
                      amount={data?.total}
                      signature={data?.signature}
                      checkBank={data?.checkBank}
                      forCheckNote={data?.forCheckNote}
                      checkingAccount={data?.checkingAccount}
                      routerNumber={data?.routerNumber}
                      manual
                    />
                  </div>

                );
              }}
            </Form.Item>
            <div className="staff-info">
              <Form.Item label='Staff’s Name' name={'staffName'}>
                <Input ref={inputStaffRef} placeholder='Staff name' />
              </Form.Item>
              <Form.Item label='Date' name={'date'}>
                <DatePicker format={'MM/DD/YYYY'} />
              </Form.Item>
              <Form.Item label='Amount' name={'total'}>
                <DollarInputPayment ref={dollarRef} />
              </Form.Item>
              <Form.Item style={{ flex: 1 }} label='For' name={'forCheckNote'}>
                <Input placeholder='For' />
              </Form.Item>
              <Box display='flex' gap='3' alignSelf='stretch' style={{ gridColumn: '1 / 3' }}>
                <Form.Item style={{ flex: 1 }} name={'routerNumber'} label="Routing Number">
                  <Input placeholder='Routing Number' />
                </Form.Item>
                <Form.Item style={{ flex: 1 }} name={'checkingAccount'} label="Checking Account">
                  <Input placeholder='Checking Account' />
                </Form.Item>
                <Form.Item label='No.' name={'checkNo'}>
                  <Input onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }} />
                </Form.Item>
              </Box>
            </div>
          </FormCheck>
          <Box p='4'>
            <Box style={{ flex: 1, zoom: 1.5 }} display='flex' flexDirection='column'>
              <Box display='flex' gap='3'>
                <Box display='flex' justifyContent='center'>
                  <Form.Item name={['checkBank', 'bankLogo']} label={<span style={{ opacity: 0 }}>Bank Logo</span>}>
                    <UploadQRImage />
                  </Form.Item>
                </Box>
                <Box style={{ flex: 1 }} display='flex' flexDirection='column'>
                  <Form.Item name={['checkBank', 'bankName']} label="Bank Name">
                    <Input placeholder='Bank name' />
                  </Form.Item>
                  <Form.Item name={['checkBank', 'bankAddress']} label="Bank address">
                    <Input placeholder='Bank address' />
                  </Form.Item>
                  <Form.Item name={['checkBank', 'bankWebsite']} label="Bank website">
                    <Input placeholder='Bank website' />
                  </Form.Item>
                </Box>
              </Box>
              <Form.Item noStyle name={'signature'} rules={[{ required: true, message: 'Please sign here' }]}>
                <CheckSignature ref={checkRef} style={{ padding: 0, margin: 0, zoom: 0.95 }} />
              </Form.Item>
            </Box>
          </Box>
        </Box>
        <Form.Item noStyle name={'signature'} />
      </FormStyled>
      <FooterActions>
        <Button onClick={onClose} htmlType='button' type='default'><span>Close</span></Button>
        <Button onClick={() => form.submit()} htmlType='button' type='primary'><span>OK</span></Button>
      </FooterActions>
    </Modal>
  );
});
ModalPrintCheckManual.displayName = 'ModalPrintCheckManual';
export default ModalPrintCheckManual;