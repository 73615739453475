import { Form, FormInstance, Input } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import CheckSignature, { useCheckSignature } from 'features/report/pages/ReportPage/EmployeeSalary/PrintCheck/Signature';
import settingServiceActions from 'features/settingService/services/actions';
import settingApis from 'features/settingService/services/apis';
import { IPrintCheckSettingBody } from 'features/settingService/services/types/printCheck';
import { set } from 'lodash';
import { useEffect } from 'react';
import { IResponseDataBody } from 'services/response';
import shopActions from 'services/shop/actions';
import shopSelectors from 'services/shop/selectors';
import { IPrintCheckSettingResData } from 'services/shop/types/printCheck';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { removeBase64Prefix } from 'utils/removeBase64Prefix';
import UploadQRImage from './UploadQRImage';
type Props = {
  form?: FormInstance<any>;
  modal?: boolean;
  onClose?: () => void;
};
const PrintCheck = ({ form: formInstance, modal, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const printCheckSetting = shopSelectors.data.printCheckSetting();
  const [form_local] = Form.useForm();
  const form = formInstance || form_local;
  const checkRef = useCheckSignature();
  const setLoading = useSetLoadingPage();
  useEffect(() => {
    dispatch(settingServiceActions.getPrintCheckSetting.fetch());
  }, []);

  useEffect(() => {
    if (printCheckSetting) {
      const base64 = 'data:image/png;base64,' + printCheckSetting?.signature;
      form.setFieldsValue({
        bankName: printCheckSetting.bankName,
        bankAddress: printCheckSetting.bankAddress,
        bankWebsite: printCheckSetting.bankWebsite,
        bankLogo: printCheckSetting.bankLogo,
        signature: base64,
        note: printCheckSetting.note,
        checkingAccount: printCheckSetting.checkingAccount,
        routerNumber: printCheckSetting.routerNumber,
        forCheckNote: printCheckSetting.forCheckNote,
      });
      checkRef.current?.setValue(base64);
    }
  }, [printCheckSetting]);

  const handleFinish = async (values: any) => {
    const data: IPrintCheckSettingResData = {
      ...printCheckSetting || {},
      ...values,
    };
    const body: IPrintCheckSettingBody = {
      bankAddress: data.bankAddress || '',
      bankName: data.bankName || '',
      bankWebsite: data.bankWebsite || '',
      urlLogo: data.bankLogo || '',
      signature: data.signature || '',
      note: data.note || '',
      checkingAccount: data.checkingAccount || '',
      routingNumber: data.routerNumber || '',
      forCheckNote: data.forCheckNote || '',
    };

    if (body.urlLogo?.includes('base64,')) {
      set(body, 'urlLogo', removeBase64Prefix(body.urlLogo));
    } else {
      delete body.urlLogo;
    }

    if (body.signature?.includes('base64,')) {
      set(body, 'signature', removeBase64Prefix(body.signature));
    } else {
      delete body.signature;
    }

    setLoading(true);
    try {
      const res: IResponseDataBody<any> = await settingApis.updatePrintCheckSetting(body);
      if (res.data.data) {
        dispatch(shopActions.get.printCheckSetting.success(res.data.data));
        onClose && onClose();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <FormStyled form={form} layout="vertical" onFinish={handleFinish}>
        <Box display='flex' gap='3'>
          <div>
            <Box display='flex' justifyContent='center'>
              <FormItemStyled name={'bankLogo'} noStyle>
                <UploadQRImage />
              </FormItemStyled>
            </Box>
            <Form.Item noStyle name={'signature'} rules={[{ required: true, message: 'Please sign here' }]}>
              <CheckSignature ref={checkRef} />
            </Form.Item>
          </div>
          <Box style={{ flex: 1, zoom: modal ? 1.5 : 1 }} display='flex' gap='3' flexDirection='column'>
            <FormItemStyled name={'bankName'} label="Bank Name">
              <Input placeholder='Bank name' />
            </FormItemStyled>
            <FormItemStyled name={'bankAddress'} label="Bank address">
              <Input placeholder='Bank address' />
            </FormItemStyled>
            <FormItemStyled name={'bankWebsite'} label="Bank website">
              <Input placeholder='Bank website' />
            </FormItemStyled>
            <Box display='flex' gap='3'>
              <FormItemStyled style={{ flex: 1 }} name={'routerNumber'} label="Routing Number">
                <Input placeholder='Routing Number' />
              </FormItemStyled>
              <FormItemStyled style={{ flex: 1 }} name={'checkingAccount'} label="Checking Account">
                <Input placeholder='Checking Account' />
              </FormItemStyled>
            </Box>
            <FormItemStyled name={'forCheckNote'} label="For">
              <Input placeholder='For' />
            </FormItemStyled>
          </Box>
        </Box>
        <FormItemStyled name={'note'} label="Note">
          <Input.TextArea rows={4} placeholder='Note ...' />
        </FormItemStyled>
      </FormStyled>
      {!modal &&
        <Box display="flex" gap="3" mt="4" justifyContent="end">
          <Button width="10rem" ntype="SECONDARY" onClick={() => {
            form.resetFields();
            if (!printCheckSetting) return;
            form.setFieldsValue(printCheckSetting);
          }}>
            Reset
          </Button>
          <Form.Item>
            <Button width="10rem" ntype="PRIMARY" onClick={() => form.submit()}>
              Save
            </Button>
          </Form.Item>
        </Box>
      }
    </Container>
  );
};

export default PrintCheck;
const Container = styled.div`
  flex-grow: 1;
  background: #e5e5e5;
  border-radius: 5px;
`;
const FormStyled = styled(Form)`
  padding: 16px;
  border-radius: 5px;
  background-color: #FFFFFF;
`;
const FormItemStyled = styled(Form.Item)`
  margin-bottom: 0;
`;
