import { IApiGetAppointments } from 'features/appointment/services/types/api';
import moment from 'moment';

export const renderTime = (
  dataAppointment: IApiGetAppointments | undefined
) => {
  if (!dataAppointment) return;
  const isSameDate = moment(dataAppointment?.startTime).isSame(
    dataAppointment?.endTime
  );
  return isSameDate
    ? moment(dataAppointment?.startTime, 'MM/DD/YYYY').format('MM/DD/YYYY')
    : `${moment(dataAppointment?.startTime, 'MM/DD/YYYY').format(
        'MM/DD/YYYY'
      )} - ${moment(dataAppointment?.endTime, 'MM/DD/YYYY').format(
        'MM/DD/YYYY'
      )}`;
};

export function formatPhoneNumber(phoneNumber?: string) {
  if (!phoneNumber) return '';
  const digitsOnly = phoneNumber.replace(/\D/g, '');

  if (digitsOnly.length < 10) {
    return phoneNumber;
  }

  // Format as (XXX) XXX-XXXX
  const areaCode = digitsOnly.slice(0, 3);
  const middle = digitsOnly.slice(3, 6);
  const last = digitsOnly.slice(6, 10);

  return `(${areaCode}) ${middle}-${last}`;
}
