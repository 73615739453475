import { createAction, createAsyncAction } from 'services/actionConfigs';
import { Message } from 'stompjs';
import { CREDIT_CARD_METHODS, PAYMENT_TYPE, PREFIX_ACTIONS } from './constants';
import { IBodyApiSplitTipPayment, StaffWorkItem } from './types/api';
import { IBillDetailData } from './types/bill';

const sendInfoPaySuccess = createAction<boolean>(PREFIX_ACTIONS + 'sendInfoPaySuccess');
const initSentInfoPay = createAction(PREFIX_ACTIONS + 'initSentInfoPay');
const setStaffWorkList = createAction<StaffWorkItem[]>(PREFIX_ACTIONS + 'setTipBill');

const getBillDetail = createAsyncAction<string>(PREFIX_ACTIONS + 'getBillDetail');

const getPrintBillDetail = createAsyncAction<string>(PREFIX_ACTIONS + 'getPrintBillDetail');

const setPrintBillDetailData = createAction<IBillDetailData>(PREFIX_ACTIONS + 'setPrintBillDetailData');

const initData = createAction(PREFIX_ACTIONS + 'initData');

const updateCashAmount = createAction<number | null>(PREFIX_ACTIONS + 'updateCashAmount');

const updateCardAmount = createAction<number | null>(PREFIX_ACTIONS + 'updateCardAmount');

const updateCreditCardMethod = createAction<CREDIT_CARD_METHODS>(PREFIX_ACTIONS + 'updateCreditCardMethod');

const resetPaymentAmount = createAction(PREFIX_ACTIONS + 'resetPaymentAmount');

const checkPaymentInfoAfterPaid = createAction(PREFIX_ACTIONS + 'checkPaymentInfoAfterPaid');

const setVisibleShareTip = createAction<boolean>(PREFIX_ACTIONS + 'setVisibleShareTip');

const updateSplitTip = createAsyncAction<IBodyApiSplitTipPayment>(PREFIX_ACTIONS + 'updateSplitTip');

const setActivePaymentType = createAction<PAYMENT_TYPE | null>(PREFIX_ACTIONS + 'setActivePaymentType');

const setSignatureValue = createAction<string | null>(PREFIX_ACTIONS + 'setSignatureValue');

const getInfoPrintWaiting = createAsyncAction<Message | null>(PREFIX_ACTIONS + 'getInfoPrintWaiting');

const getInfoPrintGroupWaiting = createAsyncAction<Message | null>(PREFIX_ACTIONS + 'getInfoPrintGroupWaiting');

const cancelTransaction = createAsyncAction<string>(PREFIX_ACTIONS + 'cancelTransaction');

const paymentActions = {
  sendInfoPaySuccess,
  initSentInfoPay,
  setStaffWorkList,

  getBillDetail,
  initData,
  updateCashAmount,
  updateCardAmount,
  updateCreditCardMethod,
  checkPaymentInfoAfterPaid,
  setVisibleShareTip,
  setSignatureValue,
  getPrintBillDetail,
  updateSplitTip,
  resetPaymentAmount,
  setActivePaymentType,
  getInfoPrintWaiting,
  getInfoPrintGroupWaiting,
  setPrintBillDetailData,
  cancelTransaction,
};

export default paymentActions;

