import { Form } from 'antd';
import Modal from 'components/Modal';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ITimelineItem } from 'features/appointment/utils/getTimeLines';
import { IApiCreateUpdateClockInOut } from 'features/report/services/types/api';
import { ITimeClockReportHistory } from 'features/report/services/types/reportStaff';
import moment, { Moment } from 'moment';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import AddTimeInOutV2 from './AddTimeInOutV2';
import AddTimeInOutV2TimePicker from './AddTimeInOutV2TimePicker';

dayjs.extend(customParseFormat);

export interface IFormValue {
  id: string;
  staff: string;
  date: Moment;
  startTime: ITimelineItem;
  endTime: ITimelineItem;
}
type Props = {
  onSubmit: (payload: IApiCreateUpdateClockInOut) => void;
};
type Ref = {
  open: (item: ITimeClockReportHistory) => void;
};
export const useModalEditTimeInOutV2 = () => useRef<Ref>(null);
const DATE_FORMAT = 'MM/DD/YYYY HH:mm A';
const PAYLOAD_DATE_FORMAT = 'MM-DD-YYYY HH:mm:ss';
const ModalEditTimeInOutV2 = forwardRef<Ref, Props>(({ onSubmit }, ref) => {
  const [isShown, setIsShown] = useState(false);
  const onClose = () => setIsShown(false);
  const [form] = Form.useForm();
  const handleCloseModal = () => onClose();
  const handleFinish = (values: any) => {
    const data = values as IFormValue;
    const payload: IApiCreateUpdateClockInOut = {
      staffId: data.staff || '',
      timeClockIn: moment(`${data.date.format('MM/DD/YYYY')} ${data.startTime?.value?.format('HH:mm A') || ''}`, DATE_FORMAT).format(PAYLOAD_DATE_FORMAT),
      timeClockOut: moment(`${data.date.format('MM/DD/YYYY')} ${data.endTime?.value?.format('HH:mm A') || ''}`, DATE_FORMAT).format(PAYLOAD_DATE_FORMAT),
      pinCode: '',
      clockInOutId: data.id,
    };
    onSubmit(payload);
    handleCloseModal();
  };

  const onOpen: Ref['open'] = (payload) => {
    console.log('payload', payload);
    form.resetFields();
    const date = moment(payload.createDated);
    const startTime = moment(payload.strClockInTime, 'hh:mm A');
    const endTime = moment(payload.strClockOutTime, 'hh:mm A');
    form.setFieldsValue({
      staff: payload.staffId,
      id: payload.id,
      date,
      startTime: {
        id: startTime.format('hh:mm A'),
        value: startTime,
      } as ITimelineItem,
      endTime: {
        id: endTime.format('hh:mm A'),
        value: endTime,
      } as ITimelineItem,
    });
    setIsShown(true);
  };

  useImperativeHandle(ref, () => ({
    open: onOpen
  }));

  return (
    <Modal
      modalTitle="EDIT TIME IN/OUT"
      v2
      visible={!!isShown}
      onClose={onClose}
      noneBodyStyle
      containerPadding={0}
      okTitle={'Done'}
      onSubmit={() => form.submit()}
      className={'modal-add-new-appointment modal-non-opacity'}
      width={'auto'}
      showClose={false}
    >
      <Form layout='vertical' form={form} onFinish={handleFinish}>
        {/* <AddTimeInOutV2 form={form} /> */}
        <AddTimeInOutV2TimePicker form={form} />
        <Form.Item name={'id'} noStyle />
      </Form>
    </Modal>
  );
});
ModalEditTimeInOutV2.displayName = 'ModalEditTimeInOutV2';
export default ModalEditTimeInOutV2;
