import { Form } from 'antd';
import Button from 'components/Button';
import SidebarV2 from 'components/layout/SidebarV2';
import reportSelectors from 'features/report/services/selectors';
import { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import ReceiptPrinter from './ReceiptPrinter';
import { useNavigate } from 'react-router-dom';
type Props = {
  manual?: boolean;
};
const PrintCheckPreviewPage = ({ manual }: Props) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const data = reportSelectors.getPrintCheckPreviewData();
  const printRef = useRef<HTMLDivElement>(null);
  const onPrint = useReactToPrint({
    content: () => printRef.current,
    copyStyles: true,
  });
  useEffect(() => {
    form.setFieldValue('data', data);
  }, [data]);
  return (
    <Container>
      <div style={{ background: '#FFF', display: 'flex' }}>
        <SidebarV2 />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, maxWidth: 'calc(100% - 104px)' }}>
        <HeaderContainer>
          <span>PRINT CHECK PREVIEW</span>
          <Actions>
            <Button onClick={() => navigate(-1)} icon='back' cardCashier>Back</Button>
            <Button icon='print' cardCashier onClick={onPrint}>Print</Button>
          </Actions>
        </HeaderContainer>

        <FormStyled form={form}>
          <Form.Item name={'data'} noStyle>
            <ReceiptPrinter printRef={printRef} manual={!!manual} />
          </Form.Item>
        </FormStyled>
      </div>
    </Container>
  );
};

export default PrintCheckPreviewPage;
const FormStyled = styled(Form)`
  background: #FFF;
  width: 1100px;
  padding: 32px 24px;
  align-self: center;
`;
const Actions = styled.div`
display: flex;
align-items: center;
gap: 16px;
`;
const HeaderContainer = styled.div`
display: flex;
height: 80px;
padding: 24px;
justify-content: space-between;
align-items: center;
flex-shrink: 0;
span {
  color: black;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
`;

const Container = styled.div`
    display: flex;
    height: 100vh;
    flex:1;
    width: 100vw;
    .search-staff {
        flex:1;
        input {
            height: 38px;
        }
        .ant-input-suffix {
            height: 38px;
        }
    }
    background: #F5F5F5;
    min-height: unset;
`;