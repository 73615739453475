import Button from 'components/Button';
import colorTheme from 'constants/color';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import reportActions from 'features/report/services/actions';
import reportSelectors from 'features/report/services/selectors';
import { IApiCreateUpdateClockInOut, IApiDeleteClockInOut } from 'features/report/services/types/api';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import fetch from 'services/request';
import shopActions from 'services/shop/actions';
import shopSelectors from 'services/shop/selectors';
import { IStaffItemData } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import AppointmentDatePicker from '../components/DatePicker';
import SwitchCalendarType from '../components/SwitchCalendarType';
import { ReportType } from '../constants';
import Dashboard from './components/Dashboard';
import ModalAddTimeInOut from './components/ModalAddTimeInOut';
import StaffSelector from './components/StaffSelector';
import StaffTableClock from './components/Table';
import { find } from 'lodash';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import Text from 'components/Text';
import ModalEditTimeInOutV2, { useModalEditTimeInOutV2 } from './components/ModalEditTimeInOutV2';
import { ITimeClockReportHistory } from 'features/report/services/types/reportStaff';
type IClockReportProps = {};
const ClockReport: React.FC<IClockReportProps> = () => {
    const editRef = useModalEditTimeInOutV2();
    const allSetting = shopSelectors.data.allSetting();
    const v2 = allSetting?.versionUI === 'V2';
    const setLoadingPage = useSetLoadingPage();
    const [showModalTimeInOut, setShowModalTimeInOut] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const dataDashboardTimeClock = reportSelectors.getDashboardTimeClockReport();
    const dataTimeClockHistory = reportSelectors.getTimeClockReportHistory();
    const dataAppointment = reportSelectors.getParams()?.[ReportType.CLOCK_TIME];
    const staffData = shopSelectors.data.staffs()?.filter(f => !f.anyBody && f.staffSalaryType === 'HOURS');
    const staffSelected = useMemo(() => {
        if (!dataAppointment?.staffId) return undefined;
        return find(staffData, (o: IStaffItemData) => o.id === dataAppointment.staffId);
    }, [dataAppointment, staffData]);

    useEffect(() => {
        if (staffData.length == 0) {
            dispatch(shopActions.get.staffs.fetch());
        }
        dispatch(reportActions.setParams({
            [ReportType.CLOCK_TIME]: {
                startTime: dataAppointment?.startTime || moment().format(DATE_FORMAT),
                endTime: dataAppointment?.endTime || moment().format(DATE_FORMAT)
            }
        }));
    }, []);

    useEffect(() => {
        fetchData();
    }, [dataAppointment]);

    const fetchData = () => {
        if (!dataAppointment) return;
        dispatch(reportActions.getDashboardTimeClockReport.fetch({
            startDate: dataAppointment.startTime,
            endDate: dataAppointment.endTime,
            staffId: staffSelected?.id
        }));
        dispatch(reportActions.getTimeClockReportHistory.fetch({
            startDate: dataAppointment.startTime,
            endDate: dataAppointment.endTime,
            staffId: staffSelected?.id
        }));
        if (v2) {
            dispatch(reportActions.getPrintTimeClockRange.fetch({
                startDate: dataAppointment.startTime,
                endDate: dataAppointment.endTime,
                staffId: staffSelected?.id
            }));
        }
    };

    const onChangeStaffSelected = (value: IStaffItemData | null) => {
        const params = {
            ...dataAppointment || {
                startTime: moment().format(DATE_FORMAT),
                endTime: moment().format(DATE_FORMAT),
            },
            staffId: value?.id || undefined,
        };
        if (!params.staffId) {
            delete params.staffId;
        }

        dispatch(reportActions.setParams({
            [ReportType.CLOCK_TIME]: params
        }));
    };

    const handleAddEditTimeInOut = (payload: IApiCreateUpdateClockInOut) => {
        setLoadingPage(true);
        fetch({
            method: 'post',
            url: '/api/v1/clock-in-out/add-or-edit',
            body: payload,
            pinCode: payload.pinCode
        }).then(response => {
            if (response?.data?.data) {
                fetchData();
            }
        }).finally(() => {
            setLoadingPage(false);
        });
    };

    const handleDeleteTimeInOut = (payload: IApiDeleteClockInOut) => {
        fetch({
            method: 'post',
            url: `/api/v1/clock-in-out/delete/${payload.clockId}`,
            // body: payload,
            pinCode: payload.pinCode
        }).then(response => {
            if (response?.data?.data) {
                fetchData();
            }
        });
    };
    const handlePrint = () => {
        if (v2) {
            navigate(`/store/${storage.shop_id.get()}/report/print/time-clock-range`);
        } else {
            navigate(`/store/${storage.shop_id.get()}/report/print/time-clock`);
        }
    };

    const onEditV2 = (item: ITimeClockReportHistory) => editRef.current?.open(item);

    return (
        <ClockReportStyled className={v2 ? 'v2' : ''}>
            {/* header */}
            <HeaderBox>
                {v2 ? <>
                    <Text style={{ flex: 1 }} variant="H5" className='title-page'>Time Clock Reports</Text>
                    <StaffSelector v2 size='middle' data={staffData} onChange={onChangeStaffSelected} selected={staffSelected} />
                    <SwitchCalendarType v2 actions={reportActions} selectors={reportSelectors} type={ReportType.CLOCK_TIME} />
                    <AppointmentDatePicker v2 size='middle' actions={reportActions} selectors={reportSelectors} type={ReportType.CLOCK_TIME} />
                    <Button width='9rem' cardCashier ntype='SECONDARY' className='btn-print' icon="print" onClick={handlePrint}>
                        Preview
                    </Button>
                    <Button cardCashier ntype="PRIMARY" icon='addPlus'
                        onClick={() => setShowModalTimeInOut(true)}
                    >
                        Add Time In/Out
                    </Button>
                </> : <>
                    <HeaderBox>
                        <StaffSelector size='middle' data={staffData} onChange={onChangeStaffSelected} selected={staffSelected} />
                        <AppointmentDatePicker size='middle' actions={reportActions} selectors={reportSelectors} type={ReportType.CLOCK_TIME} />
                        <SwitchCalendarType actions={reportActions} selectors={reportSelectors} type={ReportType.CLOCK_TIME} />
                        <Button ntype="PRIMARY" icon='addPlus'
                            onClick={() => setShowModalTimeInOut(true)}
                        >
                            Add Time In/Out
                        </Button>
                        <Button icon="print" onClick={handlePrint}>
                            Preview
                        </Button>
                    </HeaderBox>
                </>}
            </HeaderBox>
            <Dashboard v2={v2} data={dataDashboardTimeClock} />
            <StaffTableClock v2={v2}
                data={dataTimeClockHistory}
                onEdit={handleAddEditTimeInOut}
                onDelete={handleDeleteTimeInOut}
                onEditV2={onEditV2}
            />
            <ModalAddTimeInOut
                key={Math.random()}
                isShown={showModalTimeInOut}
                onClose={() => setShowModalTimeInOut(false)}
                onSubmit={handleAddEditTimeInOut}
                v2={v2}
            />
            <ModalEditTimeInOutV2
                ref={editRef}
                onSubmit={handleAddEditTimeInOut}
            />
        </ClockReportStyled>
    );
};


export default ClockReport;
const ClockReportStyled = styled.div`
  min-height: 100vh;
  background-color: ${colorTheme.fill_3};
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
    &.v2 {
        gap: 16px;
        background: #F5F5F5;
        padding: 16px;
        min-height: unset;
    }
`;
const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
`;
