import React from 'react';
import { Form, Checkbox } from 'antd';
import styled from 'styled-components';
import Box from 'components/Box';
import TimePicker from 'components/TimePicker';
import Text from 'components/Text';
import check_icon from '../../v2-icons/check-icon.svg';
import clsx from 'clsx';
// import useFormInstance from 'antd/lib/form/hooks/useFormInstance';

const DaySchedule: React.FC<{
  day: string;
  enable: string;
  start: string;
  end: string;
  v2?: boolean;
}> = ({ v2, day, enable, start, end }) => {
  //   const form = useFormInstance();

  return (
    <DayScheduleStyled className={clsx('item-schedule', v2 ? 'v2' : '')}>
      <Box className="center header" bgColor="fill_2">
        {v2 ? <FormItemNoMargin name={enable}>
          <CheckBoxV2 label={day} />
        </FormItemNoMargin> :
          <FormItemNoMargin name={enable} valuePropName="checked">
            <Checkbox className="custom-checkbox">
              <Text variant="H9" color="text_3">
                {day}
              </Text>
            </Checkbox>
          </FormItemNoMargin>}
      </Box>
      <Box className="center input-container">
        <FormItemNoMargin name={start}>
          <TimePickerStyled
            use12Hours
            bordered={false}
            allowClear={false}
            suffixIcon={null}
            className='TimePickerStyled'
            format="hh:mm a"
          />
        </FormItemNoMargin>
      </Box>
      <Box className="center input-container">
        <FormItemNoMargin name={end}>
          <TimePickerStyled
            format="hh:mm a"
            use12Hours
            bordered={false}
            allowClear={false}
            className='TimePickerStyled'
            suffixIcon={null}
          />
        </FormItemNoMargin>
      </Box>
    </DayScheduleStyled>
  );
};

export default DaySchedule;
const TimePickerStyled = styled(TimePicker)`
  .ant-picker-suffix {
    display: none;
  }
`;
const FormItemNoMargin = styled(Form.Item)`
  margin: 0;
  padding: 1rem 0.5rem;
`;
const DayScheduleStyled = styled.div`
&.v2 {
  background: #F6F7FC;
  .TimePickerStyled.ant-picker input {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-picker-input::after {
    left: 0;
    width: 100%;
    border-bottom-color: #777D7C;
  }
}
  .ant-picker-input {
    position: relative;
    display: inline-block; /* or block, depending on your layout */
  }
  .ant-picker-input::after {
    content: "";
    position: absolute;
    left: 10%; /* centers the border */
    bottom: 0;
    width: 80%;
    border-bottom: 1px solid #1d2129;
  }

  .ant-checkbox .ant-checkbox-inner {
    width: 2rem;
    height: 2rem;
    background-color: #e5e5e5;
    border-color: #e5e5e5;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    width: 2rem;
    height: 2rem;
    background-color: #ff8890;
    border-color: #ff8890;
  }

  .custom-checkbox .ant-checkbox .ant-checkbox-inner::after {
    left: 35%;
  }
`;
const CheckBoxStyled = styled.div`
display: flex;
align-items: center;
gap: 8px;
.check-box-icon {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 3px;
  border: 1px solid #D2464F;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

&.active .check-box-icon {
  border: 1px solid #D2464F;
  background: #D2464F;
}
span {
color: #1D2129;
text-align: center;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 111.111% */
text-transform: uppercase;
}
`;
type CheckBoxV2Props = {
  label?: string;
  value?: boolean;
  onChange?: (value: boolean) => void;
};
const CheckBoxV2 = ({ label, onChange, value }: CheckBoxV2Props) => {
  return (
    <CheckBoxStyled className={value ? 'active' : ''} onClick={() => onChange && onChange(!value)}>
      <div className="check-box-icon">
        {!!value && <img src={check_icon} alt="check_icon" style={{ width: 24, height: 24 }} />}
      </div>
      <span>{label}</span>
    </CheckBoxStyled>
  );
};