import { Form } from 'antd';
import Modal from 'components/Modal';
import ModalEmail, { IModalEmailFuncFinish, useModalEmailRef } from 'components/ModalEmail';
import ModalSMS, { IModalSMSFuncFinish, useModalSMSRef } from 'components/ModalSMS';
import Text from 'components/Text';
import GroupButtonFooterModal, { BtnProps } from 'features/payment/components/GroupButtonFooterModal';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';
import { styled } from 'styled-components';
type ICBActions = {
  close: () => void;
};

export type ISignatureActionFunc = (signature: string, cbActions: ICBActions) => void;
export type ISMSFuncType = (data: { signature: string, phone: string }, cbActions: ICBActions) => void;
export type IEmailFuncType = (data: { signature: string, email: string }, cbActions: ICBActions) => void;
interface ISignature {
  visible?: boolean;
  onClose?: () => void;
  onAgree: (val: string) => void;
  onNoReceipt?: ISignatureActionFunc;
  onEmail?: IEmailFuncType;
  onSMS?: ISMSFuncType;
  defaultSMSPhone?: string;
  onSignatureChange?: (dataUrl: string) => void;
}

export interface ISignatureRef {
  open: () => void;
  close: () => void;
  setValue: (data: string) => void;
}

const Signature = forwardRef<ISignatureRef, ISignature>(({ onAgree, onClose = () => undefined, onNoReceipt, onEmail, onSMS, defaultSMSPhone, onSignatureChange = () => undefined }, ref) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const signRef = useRef<ReactSignatureCanvas>(null);
  const [smsForm] = Form.useForm();
  const [emailForm] = Form.useForm();
  const smsRef = useModalSMSRef();
  const emailRef = useModalEmailRef();

  const handleClear = () => {
    signRef.current?.clear();
    onSignatureChange('');
  };

  const getSignatureValue = () => {
    if (signRef.current?.isEmpty()) return '';
    return signRef.current?.toDataURL();
  };

  const handleAgree = () => {
    const val = getSignatureValue();
    onAgree(val ?? '');
    onClose();
  };

  const onFormSMSFinish: IModalSMSFuncFinish = (values) => {
    const cbActions: ICBActions = {
      close: () => setIsOpenModal(false),
    };
    if (!onSMS) return;
    smsRef.current?.close();
    const val = getSignatureValue();
    onSMS({ signature: val || '', phone: values.phone }, cbActions);
  };

  const onFormEmailFinish: IModalEmailFuncFinish = (values) => {
    const cbActions: ICBActions = {
      close: () => setIsOpenModal(false),
    };
    if (!onEmail) return;
    emailRef.current?.close();
    const val = getSignatureValue();
    onEmail({ signature: val || '', email: values.email }, cbActions);
  };

  const btnProps: BtnProps[] = useMemo(() => {
    const cbActions: ICBActions = {
      close: () => setIsOpenModal(false),
    };
    const ButtonClear: BtnProps | null = { buttonName: 'Clear', onClick: handleClear, type: 'DEFAULT' };
    const ButtonNoReceipt: BtnProps | null = onNoReceipt ? {
      buttonName: 'No Receipt', onClick: () => {
        const val = getSignatureValue();
        onNoReceipt(val || '', cbActions);
      }, type: 'SECONDARY'
    } : null;
    const ButtonEmail: BtnProps | null = onEmail ? {
      buttonName: 'Email', onClick: () => emailRef.current?.open(), type: 'LIGHT_BLUE'
    } : null;
    const ButtonSMS: BtnProps | null = onSMS ? {
      buttonName: 'SMS', onClick: () => smsRef.current?.open(defaultSMSPhone || ''), type: 'YELLOW'
    } : null;
    const ButtonPrint: BtnProps | null = { buttonName: 'Print', onClick: handleAgree, type: 'PRIMARY' };
    return [ButtonClear, ButtonNoReceipt, ButtonEmail, ButtonSMS, ButtonPrint].filter(o => !!o) as BtnProps[];
  }, [onNoReceipt, onEmail, onSMS, defaultSMSPhone]);

  useImperativeHandle(ref, () => ({
    open: () => {
      setIsOpenModal(true);
      signRef.current?.clear();
    },
    close: () => setIsOpenModal(false),
    setValue: (val) => {
      signRef.current?.clear();
      signRef.current?.fromDataURL(val, { height: 300, width: 750 });
    },
  }));

  return (
    <>
      <SendEmailModalStyled
        footer={<GroupButtonFooterModal buttonSequence={btnProps} />}
        modalTitle='CUSTOMER SIGNATURE'
        closable={false}
        visible={isOpenModal}
        onClose={() => undefined}
        width={'auto'}
        noneBodyStyle
        containerPadding={0}
        className='modal-signature'
        footerPadding={1.5}
      >
        <div className="signature-container">
          <div className="signature-area">
            <ReactSignatureCanvas ref={signRef}
              canvasProps={{
                width: 750,
                height: 300,
                style: { backgroundColor: 'transparent' },
                className: 'sigCanvas',
              }}
              onEnd={(event: MouseEvent) => {
                const element = event?.target as HTMLCanvasElement;
                if (element?.toDataURL)
                  onSignatureChange(element?.toDataURL() || '');
              }}
            />
          </div>
          <div style={{ position: 'absolute', bottom: 0 }}>
            <Text variant="H8" textAlign='left' style={{ alignSelf: 'start', borderBottom: '1px solid' }} className='prevent-select' >Sign here X</Text>
            <Text className="signature-description prevent-select">
              Cardholder agrees to pay the card issuer the amount of service
              above, and it can not dispute or refundable.
            </Text>
          </div>
        </div>
        {onSMS && <ModalSMS ref={smsRef} form={smsForm} onFinish={onFormSMSFinish} />}
        {onEmail && <ModalEmail ref={emailRef} form={emailForm} onFinish={onFormEmailFinish} />}
      </SendEmailModalStyled>
    </>
  );
});
Signature.displayName = 'Signature';
export default Signature;

const SendEmailModalStyled = styled(Modal)`

    .signature-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        width: 750px;
        margin: auto;
        margin-bottom: 1.5rem;
    }
    .signature-area {
      height: 300px;
      flex-shrink: 0;
      border-radius: 0.3125rem;
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: end;
    }
    .signature-description {
        color: var(--text-text-2, #767676);
        text-align: center;
        font-family: Open Sans;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        padding:0 10%;
    }
    .signature-divider {
        width: 100%;
    }
    .ant-modal-footer .pay-modal-group-btn-footer {
      .pay-footer-modal-btn {
        width: 8.5rem;
        flex: unset;
      }
    }
`;
