import Box from 'components/Box';
import Spin from 'components/Spin';
import Text from 'components/Text';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import ticketListSelectors from 'features/ticketList/services/selectors';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { momentTimezone } from 'utils/time';
import CreditCard from './ModalButton/CreditCard';

type IRightTicketDetailsProps = {
  doPrint: () => void;
};
const RightTicketDetails: React.FC<IRightTicketDetailsProps> = () => {
  const details = ticketListSelectors.ticketDetailsData.data();
  const totalPayment = useMemo(() => {
    const creditPayment = details?.payments.find(o => o.paymentType === PAYMENT_TYPE.CREDIT_CARD);
    return [
      {
        name: 'Grand Subtotal:',
        price: formatCurrency(details?.subTotal || 0),
        color: 'info_4',
      },
      creditPayment ? {
        name: 'Card Fees:',
        price: formatCurrency(creditPayment?.feeCreditCard || 0),
        color: 'error_4',
      } : null,
      {
        name: 'Tip:',
        price: formatCurrency(details?.tip || 0),
        color: 'error_4',
      },
      {
        name: 'Sale Tax:',
        price: formatCurrency(details?.saleTax || 0),
      },
      {
        name: 'Used Tax:',
        price: formatCurrency(details?.useTax || 0),
      },
      {
        name: 'Discount Item:',
        price: formatCurrency(details?.discountItem || 0),
      },
      {
        name: 'Discount Ticket:',
        price: formatCurrency(details?.discountTicket || 0),
      },
    ].filter(o => !!o);
  }, [details]);
  const moneyCard = useMemo(() => {
    return [
      {
        type: PAYMENT_TYPE.LOYALTY_POINT,
        name: 'Points:',
        price: details?.payments.find(
          (o) => o.paymentType === PAYMENT_TYPE.LOYALTY_POINT
        )?.amount,
      },
      {
        type: PAYMENT_TYPE.GIFT_CARD,
        name: 'Gift Card:',
        price: details?.payments.find(
          (o) => o.paymentType === PAYMENT_TYPE.GIFT_CARD
        )?.amount,
      },
      {
        type: PAYMENT_TYPE.CHECK,
        name: 'Check /Other(S):',
        price: details?.payments.find(
          (o) => o.paymentType === PAYMENT_TYPE.CHECK
        )?.amount,
      },
      {
        type: PAYMENT_TYPE.CASH,
        name: 'Cash:',
        price: details?.payments.find(
          (o) => o.paymentType === PAYMENT_TYPE.CASH
        )?.amount,
      },
      {
        type: PAYMENT_TYPE.CREDIT_CARD,
        name: details?.billStatus === 'REFUND' ? 'Refunded:' : 'Voided:',
        price: details?.payments.find(
          (o) => o.paymentType === PAYMENT_TYPE.CREDIT_CARD
        )?.amount,
      },
    ].filter(o => !!o.price);
  }, [details]);

  const otherPayments = useMemo(() => {
    return [{ name: 'Cash pay', price: details?.payments.find(o => o.paymentType === PAYMENT_TYPE.CASH_APPS)?.amount },
    { name: 'Zelle', price: details?.payments.find(o => o.paymentType === PAYMENT_TYPE.ZELLE)?.amount },
    { name: 'Paypal', price: details?.payments.find(o => o.paymentType === PAYMENT_TYPE.PAYPAL)?.amount },
    { name: 'Venmo', price: details?.payments.find(o => o.paymentType === PAYMENT_TYPE.VENMO)?.amount },].filter(o => !!o.price);
  }, [details]);

  const isCreditCard = !!details?.payments.find(
    (item) => item.paymentType === PAYMENT_TYPE.CREDIT_CARD
  );
  const credit_card = details?.payments?.find(
    (item) => item.paymentType === PAYMENT_TYPE.CREDIT_CARD
  );
  const MultiCardPayments = useCallback(() => {
    return (
      <MultiCardPaymentsStyled>
        {details?.paymentMultipleCard?.map(item => (
          <Box key={item.paymentId}>
            <TotalPaymentBox>
              <SubTotalBox>
                <Box className="space-between">
                  <CreditCard key={item.paymentId} credit_card={item} />
                  <Box className="center" gap="2">
                    <Text variant="CONTENT_2" color="text_3" style={{
                      fontSize: 18,
                      fontWeight: '700',
                    }}>
                      {formatCurrency(item.amount)}
                    </Text>
                  </Box>
                </Box>
              </SubTotalBox>
            </TotalPaymentBox>
            {!!item.signature && <SignatureBox>
              <img className='img multi' src={item.signature?.includes('http') ? item.signature : 'data:image/png;base64,' + item.signature} />
            </SignatureBox>}
          </Box>
        ))}
      </MultiCardPaymentsStyled>
    );
  }, [details]);

  const loading = ticketListSelectors.ticketDetailsData.loading();
  if (loading) {
    return (
      <RightTicketDetailsStyled>
        <Spin></Spin>
      </RightTicketDetailsStyled>
    );
  }

  return (
    <RightTicketDetailsStyled>
      <Text variant="H5" color="text_3" textTransform='uppercase'>
        Payment Detail
      </Text>
      <Text variant="H8" color="text_2">
        {momentTimezone(details?.startTime).format('DD/MM/YYYY hh:mm A')}
      </Text>
      <Box mt='3'></Box>

      <SubTotalBox>
        {totalPayment?.map((item) => {
          return (
            <Box className="space-between" key={item?.name}>
              <Text variant="H8" color="text_3">
                {item?.name}
              </Text>
              <Box className="center" gap="2">
                <Text variant="H9" color={item?.color as any}>
                  {item?.price}
                </Text>
                {/* {isShowTip && <AddTipButton disabled={isDisableTip} />} */}
              </Box>
            </Box>
          );
        })}
      </SubTotalBox>
      <Divider />
      {details?.isMultiplePayCard ? <MultiCardPayments /> : <>
        <TotalPaymentBox>
          <SubTotalBox>
            {moneyCard?.map((item: any) => {
              return (
                <Box className="space-between" key={item.type}>
                  {item.type === PAYMENT_TYPE.CREDIT_CARD && <CreditCard credit_card={credit_card as any} />}
                  {!(item.type === PAYMENT_TYPE.CREDIT_CARD) && (
                    <Text variant="CONTENT_2" color="text_3" style={{
                      fontSize: 18,
                      fontWeight: '700',
                    }}>
                      {item.name}
                    </Text>
                  )}
                  <Box className="center" gap="2">
                    <Text variant="CONTENT_2" color="text_3" style={{
                      fontSize: 18,
                      fontWeight: '700',
                    }}>
                      {formatCurrency(item.price)}
                    </Text>
                  </Box>
                </Box>
              );
            })}
            {otherPayments?.map((item: any) => {
              return (
                <Box className="space-between" key={item.type}>
                  <Text variant="CONTENT_2" color="text_3" style={{
                    fontSize: 18,
                    fontWeight: '700',
                  }}>
                    Payment Type
                  </Text>
                  <Box highlight px='1' style={{ borderRadius: 4 }}>
                    <Text printMode variant="CONTENT_2" style={{ fontSize: 20 }} className='title-highlight'>
                      {item.name}
                    </Text>
                  </Box>
                </Box>
              );
            })}
          </SubTotalBox>
        </TotalPaymentBox>
        {
          isCreditCard && details.signature ? <>
            <SignatureBox>
              <img className='img' src={details.signature?.includes('http') ? details.signature : 'data:image/png;base64,' + details.signature} />
            </SignatureBox>
          </> : null
        }

      </>}
      {!!details?.reason && <>
        <Divider />
        <Box bgColor='info_bg_orange' py='2'>
          <Text variant="CONTENT_2" color="error_4" textAlign='center'>
            Reason: {details?.reason}
          </Text>
        </Box>
      </>}

    </RightTicketDetailsStyled>
  );
};

export default RightTicketDetails;
const RightTicketDetailsStyled = styled.div`
  padding: 1.5rem;
  width: 40%;
  overflow: auto;
`;
const TotalPaymentBox = styled.div`
  border-radius: 5px;
  // background: rgb(254 237 191 / 50%);
  padding-bottom: 0.5rem;
`;
const SubTotalBox = styled.div`
  margin-top: 1rem 0 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Divider = () => <Box bt="line_3" my='2'></Box>;

const SignatureBox = styled.div`
  // padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  .img {
    width: 100%;
    height: 215px;
    margin: auto;
    background-position-x: center;
    background-position-y: center;
    background-size: contain;
    background-repeat: no-repeat;
    &.multi {
      height: 180px;
      margin-top: -15px;
    }
  }
  .blank {
    width: 100%;
    height: 5.5rem;
  }
  align-items: end;
`;

const MultiCardPaymentsStyled = styled.div``;