import { Avatar, Row } from 'antd';
import { CALENDAR_VIEW_TYPE } from 'features/appointment/services/constants';
import appointmentSelectors from 'features/appointment/services/selectors';
import { ICalendarHeader, ICalendarItemData, ICalendarSchema } from 'features/appointment/services/types/calendar';
import { IDateRangeItem } from 'features/appointment/utils/getRangeDates';
import _, { get } from 'lodash';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { momentTimezone } from 'utils/time';
import AnyBodyImage from 'components/AnyBodyImage';
import { useAppDispatch } from 'store/hooks';
import appointmentActions from 'features/appointment/services/actions';
import { IModalConfirmRef } from 'components/Modal/ModalConfirm';
import add_icon from 'assets/Icons/appointments/Add.svg';
import minus_icon from 'assets/Icons/appointments/Minus.svg';
import styled from 'styled-components';
const ButtonAddMinus = styled.button`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  right: 6.333px;
  border-radius: 4px;
  background: #E4F4F6;
  img {
    width: 32px;
    height: 32px;
  }

  &:hover, &:active {
    background: #eee;
  }
`;


export const modalConfirmRef = React.createRef<IModalConfirmRef>();
type Props = {
  modal?: boolean;
};
const useCalendarFilter = ({ modal }: Props): ICalendarSchema => {
  const distanceTimeline = appointmentSelectors.getDistanceTimeline();
  const typeView = appointmentSelectors.getViewType();
  const dateRangeList = appointmentSelectors.getDateRangeList();
  const staffListStore = appointmentSelectors.getListStaffAvailableBooking();
  const staffPage = appointmentSelectors.getStaffPage();
  const staffLimit = appointmentSelectors.getStaffLimit();

  const dispatch = useAppDispatch();

  const onAddAnybody = () => dispatch(appointmentActions.addAnybody());
  const onRemoveAnybody = (staff_id: string) => () => modalConfirmRef.current?.open('Are you sure you want to remove this staff?', () => dispatch(appointmentActions.removeAnybody(staff_id)));

  const staffList = useMemo(() => {
    const limit = staffLimit;
    if (modal) return staffListStore.filter(o => !o.anyBody);
    return _(staffListStore)
      .drop((staffPage - 1) * limit)
      .take(limit)
      .value();
  }, [staffPage, staffListStore, staffLimit, modal]);

  const headers = useMemo(() => {
    if (typeView === CALENDAR_VIEW_TYPE.WEEK_VIEW) {
      return dateRangeList.map((item) => {
        const _date = moment(item.value);
        const result: ICalendarHeader = ({
          id: item.id,
          value: _date,
          label: _date.format('DD.MM'),
          type: 'date',
          anybody: false,
          modal,
        });
        return result;
      });
    }

    if (typeView === CALENDAR_VIEW_TYPE.DAY_VIEW) {
      const firstAnybody = staffListStore.find(o => o.anyBody);
      return staffList.map((item, i) => {
        const result: ICalendarHeader = ({
          id: item.id,
          value: item.id,
          label: item.firstName,
          type: 'staff',
          url: item.urlImage,
          anybody: !!item.anyBody,
          addAnybody: firstAnybody?.id === item.id,
          minusAnybody: !!item.anyBody,
          modal,
          workingTime: item.timeSchedule ? item.timeSchedule : undefined,
        });
        return result;
      });
    }
    return [];
  }, [typeView, dateRangeList, staffList, modal]);

  const renderHeader = useCallback((o: ICalendarHeader<any>) => {
    if (typeView === CALENDAR_VIEW_TYPE.WEEK_VIEW)
      return (
        <>
          <p className='subtext'>{o?.value?.format('ddd, MMMM DD')}</p>
        </>
      );

    return (
      <Row align={'middle'} justify={'center'} style={{ gap: '10px', position: 'relative' }} >
        {o.anybody ? <AnyBodyImage /> :
          <Avatar size={32} src={o.url} style={{ backgroundColor: '#f56a00' }}>{get(o.label, [0], '')}</Avatar>
        }
        <p className='subtext'>{o.label}</p>
        {o.anybody && (o.addAnybody ?
          <ButtonAddMinus onClick={onAddAnybody}>
            <img src={add_icon} alt="add_icon" />
          </ButtonAddMinus>
          : <ButtonAddMinus onClick={onRemoveAnybody(o.id)}>
            <img src={minus_icon} alt="minus_icon" />
          </ButtonAddMinus>)}
      </Row>

    );
  }, [typeView]);

  const filterAppointments = useCallback((order: ICalendarItemData, _col: ICalendarHeader<any>) => {
    if (typeView === CALENDAR_VIEW_TYPE.WEEK_VIEW) {
      const col = _col as ICalendarHeader<IDateRangeItem['value']>;
      return momentTimezone(order.startTime).isSame(col?.value, 'day');
    }

    if (typeView === CALENDAR_VIEW_TYPE.DAY_VIEW) {
      const col = _col as ICalendarHeader<any>;
      // if (col.id === SYSTEM_VARIABLES.UNASSIGNED) {
      //   if (order.book_assignment_services.length === 0) return true;
      //   else return !!order.book_assignment_services.find(service => !service?.assigned_employee || isEmpty(service?.assigned_employee) || !service?.assigned_employee?.employee);
      // }
      return order.staffId === col.id;
    }
    return false;
  }, [typeView]);

  return ({
    distanceTimeline,
    headers,
    typeView,
    renderHeader,
    filterAppointments,
    timeHourEnd: 22,
    timeHourStart: 8,
    timeMinuteEnd: 0,
    timeMinuteStart: 0,
  });
};

export default useCalendarFilter;
