import { Col, Form, Row } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Button from 'components/Button';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import { IUpdateQuickPayTurnItem } from 'features/settingService/services/types/turn';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import SubmitButton from './ButtonModal/SubmitButton';
import FromToInput from './QuickpayTab/FromToInput';
import PercentDecimalInputPrecision from 'components/PercentDecimalInputPrecision';
import add_new_icon from './v2-icons/add-new-icon.svg';
import discount_delete_icon from './v2-icons/discount-delete.svg';
import Text from 'components/Text';
import ActionButtons from './PaymentTabMenu/components/ActionButtons';
import { TurnTableStyled } from '../v2/style';
type IQuickPaySettingProps = {
  v2?: boolean;
};

const QuickPayTurnSetting: React.FC<IQuickPaySettingProps> = ({ v2 }) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const quickPayList = settingServiceSelectors.quickPayTurnList();

  const onFinish = (values: { data: IUpdateQuickPayTurnItem[] }) => {
    dispatch(settingServiceActions.updateQuickPayTurnList.fetch(values.data));
  };

  useEffect(() => {
    if (quickPayList) {
      form.setFieldValue('data', quickPayList);
    }
  }, [quickPayList]);

  useEffect(() => {
    dispatch(settingServiceActions.getQuickPayTurnList.fetch());
  }, []);

  if (v2) {
    return (
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        style={{ flex: 1, background: '#fff' }}
      >
        <TurnTableStyled>
          <table>
            <thead>
              <tr>
                <th>level</th>
                <th>Range sale</th>
                <th>turn</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <Form.List name="data">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <tr key={key}>
                        <td className='level-text'>Level {index + 1}</td>
                        <td>
                          <FromToInput
                            formListName="data"
                            name={name}
                          />
                        </td>
                        <td>
                          <Form.Item
                            {...restField}
                            name={[name, 'turn']}
                            noStyle
                          >
                            <PercentDecimalInputPrecision nonSuffix />
                          </Form.Item>
                        </td>
                        <td>
                          <button type='button' className="center" onClick={() => remove(name)} >
                            <img src={discount_delete_icon} alt='discount_delete_icon' style={{ width: 32, height: 32 }} />
                          </button>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={4}>
                        <button
                          type='button'
                          className='btn-add-new'
                          onClick={() => add()}
                        >
                          <img src={add_new_icon} alt="add_new_icon" style={{ width: 24, height: 24 }} />
                          <span>Add New</span>
                        </button>
                        <Text mb={1}></Text>
                      </td>
                    </tr>
                  </>
                )}
              </Form.List>
            </tbody>
          </table>
        </TurnTableStyled>
        <ActionButtonsStyled />
      </Form>
    );
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      style={{ flex: 1 }}
    >
      <QuickPaySettingStyled>
        <FormBox>
          <Box>
            <Form.List name="data">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => {
                    return (
                      <Box
                        key={key}
                        display="flex"
                        alignItems="center"
                        gap="1"
                        justifyContent="space-between"
                      >
                        <Row gutter={[16, 16]}>
                          <Col
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 11 }}
                            lg={{ span: 11 }}
                            xl={{ span: 11 }}
                            xxl={{ span: 11 }}
                          >
                            <Box>
                              <Form.Item noStyle name={'id'}><input hidden /></Form.Item>
                              <Form.Item noStyle name={'shopId'}><input hidden /></Form.Item>
                              <Form.Item label="Range Sale">
                                <FromToInput formListName="data" name={name} />
                              </Form.Item>
                            </Box>
                          </Col>
                          <Col
                            xs={{ span: 20 }}
                            sm={{ span: 20 }}
                            md={{ span: 11 }}
                            lg={{ span: 11 }}
                            xl={{ span: 11 }}
                            xxl={{ span: 11 }}
                          >
                            <Box>
                              <FormItemNoMargin
                                label="Turn"
                                {...restField}
                                name={[name, 'turn']}
                              >
                                <PercentDecimalInputPrecision nonSuffix />
                              </FormItemNoMargin>
                            </Box>
                          </Col>
                          <Col>
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              onClick={() => remove(name)}
                              height="100%"
                            >
                              <Icon type="circleClose" />
                            </Box>
                          </Col>
                        </Row>
                      </Box>
                    );
                  })}
                  <Button
                    ntype="LIGHT_BLUE"
                    icon="addPlus"
                    onClick={() => add()}
                  >
                    Add Level
                  </Button>
                </>
              )}
            </Form.List>
          </Box>
        </FormBox>
        <Box className="center" mt="3" gap="3">
          <Box width="10rem">
            <Button width="100%" ntype="SECONDARY" htmlType="reset">
              Reset
            </Button>
          </Box>
          <Box width="10rem">
            <SubmitButton label='Save' />
          </Box>
        </Box>
      </QuickPaySettingStyled>
    </Form>
  );
};

export default QuickPayTurnSetting;
const QuickPaySettingStyled = styled.div`
  background: var(--fill-fill-3, #e5e5e5);
  padding: 1.5rem;
  .ant-input-number-input-wrap {
    height: 100%;
    input {
      height: 100%;
      color: var(--text-text-3, #1d2129);
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      &:focus,
      &:hover,
      &:focus-visible {
        border: none;
        outline: none;
      }
    }
  }
`;

const FormBox = styled.div`
  border-radius: 5px;
  background: var(--fill-fill-1, #f6f7fc);
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormItemNoMargin = styled(Form.Item)`
  margin: 0;
`;
const ActionButtonsStyled = styled(ActionButtons)`
  padding-right: 16px;
`;
 