import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import Modal from './Modal';
import styled from 'styled-components';
import { Form, FormInstance, InputRef } from 'antd';
import InputPhone from './InputPhone';
import { formatPhone } from 'utils/formatPhone';
import { isMobileBrowser } from 'utils/detachBehavior';

const isMobile = isMobileBrowser();

interface IModalRef {
  open: (defaultValue?: string) => void;
  close: () => void;
}
export const useModalSMSRef = () => useRef<IModalRef>(null);
type FormValue = { phone: string };
export type IModalSMSFuncFinish = (values: FormValue) => void;
type Props = {
  form: FormInstance<FormValue>;
  onFinish: IModalSMSFuncFinish;
  v2?: boolean;
};
const ModalSMS = forwardRef<IModalRef, Props>(({ v2, form, onFinish }, ref) => {
  const inputRef = useRef<InputRef>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  useImperativeHandle(ref, () => ({
    open: (defaultValue?: string) => {
      setIsOpenModal(true);
      form.setFieldValue('phone', defaultValue ? formatPhone(defaultValue) : '');
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    },
    close: () => setIsOpenModal(false),
  }));

  if (!isOpenModal) return null;

  return (
    <ModalSMSStyled
      visible
      modalTitle='Your Phone Number'
      closable={false}
      onClose={() => setIsOpenModal(false)}
      noneBodyStyle
      okTitle='Send'
      centered={!isMobile}
      containerPadding={0}
      className='modal-signature'
      footerPadding={1.5}
      onSubmit={() => form.submit()}
      v2={v2}
    >
      <FormStyled>
        <Form form={form} onFinish={onFinish} validateTrigger={['onSubmit']}>
          <Form.Item className='phone' name={'phone'} rules={[{ required: true, message: 'Please enter your phone number!' }]}>
            <InputPhone className='inputPhone' inputRef={inputRef} placeholder='(000) 000-0000' />
          </Form.Item>
        </Form>
      </FormStyled>
    </ModalSMSStyled>
  );
});
ModalSMS.displayName = 'ModalSMS';
export default ModalSMS;

const ModalSMSStyled = styled(Modal)``;

const FormStyled = styled.div`
padding: 1rem 1.5rem;
  .ant-input {
    height: 5rem;
    font-weight: 600;
    font-size: 1.8rem;
    text-align: center;
  }
`;