import { Form } from 'antd';
import DollarInputPayment from 'components/DollarAmount/DollarInputPayment';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import React from 'react';
import styled from 'styled-components';
type IFromToInputProps = {
  formListName: string;
  name: number;
};
const FromToInput: React.FC<IFromToInputProps> = ({ name, formListName }) => {
  const form = Form.useFormInstance();
  return (
    <FromToInputStyled className='FromToInputStyled v2'>
      <Text variant="H8" color="text_2">
        From:
      </Text>
      <Form.Item
        noStyle
        name={[name, 'fromValue']}
        dependencies={[[formListName, name, 'toValue']]}
        rules={[
          {
            validator(_, value) {
              if (!value) {
                return Promise.reject('Please input a from value!');
              }
              const toValue = form.getFieldValue([
                formListName,
                name,
                'toValue',
              ]);
              if (toValue && value >= toValue) {
                return Promise.reject(
                  'From value should be less than To value!'
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <DollarInputPayment withForm />
      </Form.Item>

      <Text variant="H8" color="text_2">
        -
      </Text>
      <Text variant="H8" color="text_2">
        To
      </Text>
      <Form.Item
        noStyle
        name={[name, 'toValue']}
        dependencies={[[formListName, name, 'fromValue']]}
        rules={[
          {
            validator(_, value) {
              if (!value) {
                return Promise.reject('Please input a to value!');
              }
              const fromValue = form.getFieldValue([
                formListName,
                name,
                'fromValue',
              ]);
              if (fromValue && value <= fromValue) {
                return Promise.reject(
                  'To value should be greater than From value!'
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <DollarInputPayment withForm />
      </Form.Item>
    </FromToInputStyled>
  );
};

export default FromToInput;
const FromToInputStyled = styled.div`
  height: calc(4rem - 2px);
  border-radius: 5px;
  display: flex;
  border: 1px solid ${colorTheme.line_3};
  background-color: ${colorTheme.fill_1};
  gap: 1rem;
  align-items: center;
  padding: 0 1rem;
  &.FromToInputStyled.v2 {
    .dollar-input-selector {
      flex: 1;
      border: none;
      height: unset;
      justify-content: end;
      .dollar-input-text {
        color: #1D2129;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
`;
