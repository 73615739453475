import { Button as ButtonAnt } from 'antd';
import Button from 'components/Button';
import colorTheme from 'constants/color';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
import RequestAdminLocalPW from 'widgets/RequestAdminLocalPW';
import { Bill } from '../../services/types/api';
import ModalInputReasonVoid from './ModalReason';
export type IVoidFuncType = (val: {
  id: string;
  reason: string;
}) => void;

type Props = {
  isDetail?: boolean;
  ticketDetails?: Bill | undefined;
  disabled?: boolean;
  onVoid?: IVoidFuncType;
  v2?: boolean;
  hook?: boolean;
};
type Ref = {
  open: () => void;
};
export const useVoidButtonHandlerRef = () => useRef<Ref>(null);
const VoidButtonHandler = forwardRef<Ref, Props>(({ hook, isDetail, ticketDetails, disabled: _disabled, onVoid = () => undefined, v2 }, ref) => {
  const disabled = _disabled || ticketDetails?.billStatus !== 'CLOSED';
  const isCash = ticketDetails?.payments?.some(
    (item) => item?.paymentType === PAYMENT_TYPE.CASH
  );

  const [visiblePW, setVisiblePW] = useState(false);

  const [modalReason, setModalReason] = useState(false);

  const handleShowModal = () => {
    if (isCash) {
      setVisiblePW(true);
    } else {
      setModalReason(true);
    }
  };

  const onVerifyAdminSuccess = () => {
    setVisiblePW(false);
    setModalReason(true);
  };

  const handleVoid = (reason: string) => {
    const payload = {
      id: ticketDetails?.billId || '',
      reason,
    };
    onVoid(payload);
    setModalReason(false);
  };

  const ButtonUI = () => {
    if (isDetail) {
      return (
        <Button
          disabled={disabled}
          ntype="YELLOW"
          icon="voidList"
          onClick={handleShowModal}
        >
          Void
        </Button>
      );
    }

    return <ActionButton v2={v2} onClick={handleShowModal}>{v2 ? 'Void' : 'VOID'}</ActionButton>;
  };

  useImperativeHandle(ref, () => ({
    open: handleShowModal,
  }));

  if (!ticketDetails) return null;

  return (
    <>
      {!hook && <ButtonUI />}
      {visiblePW && (
        <RequestAdminLocalPW
          onlyAdmin
          visible
          v2={v2}
          onCorrect={onVerifyAdminSuccess}
          onClose={() => setVisiblePW(false)}
        />
      )}
      {modalReason && (
        <ModalInputReasonVoid v2={v2} visible={modalReason} onVoid={handleVoid} onClose={() => setModalReason(false)} />
      )}
    </>
  );
});
VoidButtonHandler.displayName = 'VoidButtonHandler';
export default VoidButtonHandler;

const ActionButton = styled(ButtonAnt) <{ v2?: boolean }>`
  display: flex;
  padding: 0 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: ${colorTheme.info_2} !important;
  flex-grow: 1;
  flex-basis: 1rem;
  cursor: pointer;
  color: ${colorTheme.text_3} !important;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-color: ${colorTheme.info_2} !important;
  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.5;
  }


  ${({ v2 }) => v2 && `
    display: flex;
    height: 38px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    border-radius: 4px;
    background: #FF7D00  !important;
    border-color: #FF7D00 !important;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #DC5500 inset  !important;
    .CONTENT_2, span {
      color: #fff !important;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  `}
`;
