import Icon from 'assets/Icons';
import Box from 'components/Box';
import Text from 'components/Text';
import React, { CSSProperties, forwardRef, useImperativeHandle, useRef } from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';
import styled from 'styled-components';
type Props = {
  onChange?: (dataUrl: string) => void;
  value?: string;
  style?:CSSProperties;
};
type Ref = {
  setValue: (val: string) => void;
};
export const useCheckSignature = () => useRef<Ref>(null);
const CheckSignature = forwardRef<Ref, Props>(({ style, onChange: onSignatureChange = () => undefined, value }, ref) => {
  const signRef = useRef<ReactSignatureCanvas>(null);

  const handleClear = () => {
    onSignatureChange('');
    signRef.current?.clear();
  };

  useImperativeHandle(ref, () => ({
    setValue: (val: string) => {
      signRef.current?.fromDataURL(val || '', { height: 250, width: 650 });
    },
  }));

  return (
    <Container style={style}>
      <div className="signature-area">
        <ReactSignatureCanvas ref={signRef}
          canvasProps={{
            width: 650,
            height: 250,
            className: 'sigCanvas',
          }}
          onEnd={(event: MouseEvent) => {
            const element = event?.target as HTMLCanvasElement;
            if (element?.toDataURL)
              onSignatureChange(element?.toDataURL() || '');
          }}
        />
      </div>
      <Box display='flex' justifyContent='space-between' alignSelf='stretch' style={{ borderBottom: '1px solid #86909C', }}>
        <Text variant="H8" textAlign='left' className='prevent-select' >Sign here X</Text>
        <button onClick={handleClear} style={{ alignSelf: 'flex-end' }} type='button'><Icon type='close' /></button>
      </Box>
      <Text mt={0.5} mb={1.5} className="signature-description prevent-select">
        Cardholder agrees to pay the card issuer the amount of service above, <br /> and it can not dispute or refundable.
      </Text>
    </Container>
  );
});
CheckSignature.displayName = 'CheckSignature';
export default CheckSignature;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-self: flex-end;
  padding: 0 24px;
  padding-bottom: 40px;
  .signature-description {
    color: #64748B;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
  }
`;