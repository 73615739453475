import { Col, DatePicker, Form, Row } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import React from 'react';
import styled from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';
import { useAppDispatch } from 'store/hooks';
import Icon from 'assets/Icons';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import { momentTimezone } from 'utils/time';

type IDaysOffListProps = {
  v2?: boolean;
};
const { RangePicker } = DatePicker;
const DaysOffList: React.FC<IDaysOffListProps> = ({ v2 }) => {
  const form = Form.useFormInstance();
  const dispatch = useAppDispatch();
  const disableDateList = settingServiceSelectors.getDayOffList();
  const dateRangerWatch = Form.useWatch('date_ranger', form);

  const totalDays = disableDateList.reduce((sum, data) => {
    const startTime = momentTimezone(data.startTime);
    const endTime = momentTimezone(data.endTime);
    const daysCount = endTime.diff(startTime, 'day') + 1;
    return sum + daysCount;
  }, 0);

  const isDateRangeFilled = () => {
    return dateRangerWatch && dateRangerWatch[0] && dateRangerWatch[1];
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    const currentStartOfDay = current?.startOf('day');
    return disableDateList.some(({ startTime, endTime }) => {
      const startDate: Dayjs = dayjs(startTime, 'MM-DD-YYYY').startOf('day');
      const endDate: Dayjs = dayjs(endTime, 'MM-DD-YYYY').startOf('day');
      return (
        currentStartOfDay &&
        (currentStartOfDay.isAfter(startDate) ||
          currentStartOfDay.isSame(startDate)) &&
        (currentStartOfDay.isBefore(endDate) ||
          currentStartOfDay.isSame(endDate))
      );
    });
  };

  const handleCreateDayOff = () => {
    const rangerDate = form.getFieldValue('date_ranger');
    const startTime = dayjs(rangerDate[0]).format('MM-DD-YYYY 00:00:00');
    const endTime = dayjs(rangerDate[1]).format('MM-DD-YYYY 23:59:00');
    const param = {
      startTime,
      endTime,
    };
    dispatch(settingServiceActions.postDayOffList.fetch(param));
    form.setFieldsValue({ date_ranger: null });
  };
  const handleDelete = (id: string) => {
    dispatch(settingServiceActions.deleteDayOffList.fetch(id));
  };

  return (
    <DaysOffListStyled className={v2 ? 'v2' : ''}>
      <Box>
        <Box display="flex" alignItems="center" height="100%">
          <Text variant="H7" color="text_3" className={'section-label'}>
            Select To Create Day Off
          </Text>
        </Box>
        <RangerContainerStyled className='RangerContainerStyled'>
          <Box width="100%">
            <ItemFormStyled name="date_ranger" label="Select Time" noStyle={v2}>
              <RangePicker
                style={{ height: v2 ? '3.5rem' : '4rem' }}
                renderExtraFooter={() => 'extra footer'}
                disabledDate={disabledDate}
                placement="bottomLeft"
              />
            </ItemFormStyled>
          </Box>

          <Button
            cardCashier={v2}
            size={v2 ? undefined : 'large'}
            height={!v2 ? undefined : 3.5}
            disabled={!isDateRangeFilled()}
            ntype="PRIMARY"
            onClick={handleCreateDayOff}
          >
            Create
          </Button>
        </RangerContainerStyled>
      </Box>

      <Box mt="4">
        <Row>
          <Col span={6}>
            <Box>
              <Text variant="H7" color="text_3" className={'section-label'}>
                Number Of Day Off
              </Text>
              <Text variant="H2" color="text_3">
                {totalDays} days
              </Text>
            </Box>
          </Col>
          <Col span={18}>
            <Row gutter={[16, 16]}>
              {disableDateList.map((item) => {

                const startTime = momentTimezone(item.startTime);
                const endTime = momentTimezone(item.endTime);
                const daysCount = endTime.diff(startTime, 'day') + 1;
                return (
                  <Col
                    key={item.id}
                    xs={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 12 }}
                    xl={{ span: 12 }}
                  >
                    <Box display="flex" gap="2">
                      <Box
                        display="flex"
                        alignItems="center"
                        className="item-grow"
                        gap="1"
                      >
                        <Icon type="calendar" />{' '}
                        <Text variant="H8" color="text_3" className='text-time-label'>
                          {startTime.format('MM-DD-YYYY')}
                        </Text>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        className="item-grow"
                        gap="1"
                      >
                        <Icon type="calendar" />{' '}
                        <Text variant="H8" color="text_3" className='text-time-label'>
                          {endTime.format('MM-DD-YYYY')}
                        </Text>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        onClick={() => handleDelete(item.id)}
                      >
                        <Icon type="circleClose" />
                      </Box>
                    </Box>
                    <Text variant="H9" color="primary_active" className='text-time-label'>
                      {daysCount} Day Off
                    </Text>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Box>
    </DaysOffListStyled>
  );
};

export default DaysOffList;
const DaysOffListStyled = styled.div`
  margin-top: 1.5rem;
  padding: 1rem 0;
  border-top: 1px solid #c9cdd4;
  .item-grow {
    flex-grow: 1;
  }

  &.v2 {
    .RangerContainerStyled .ant-picker .ant-picker-input input {
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .text-time-label {
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
`;
const RangerContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 1rem;
`;
const ItemFormStyled = styled(Form.Item)`
  margin: 0;
`;
