import { Form } from 'antd';
import InputPin from 'components/InputPin';
import colorTheme from 'constants/color';
import settingStaffActions from 'features/settingStaff/services/actions';
import settingStaffSelectors from 'features/settingStaff/services/selectors';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import ButtonGroup from './ButtonGroup';
type IStaffSecurityProps = {
  v2?: boolean;
};
const StaffSecurity: React.FC<IStaffSecurityProps> = ({ v2 }) => {
  const dispatch = useAppDispatch();
  const staffSelected = settingStaffSelectors.selectedStaffData.data();
  const [form] = Form.useForm();
  const { tab } = useParams();

  useEffect(() => {
    if (tab !== 'security') return;
    // form.setFieldsValue({ password1: staffSelected.password, password2: '' });
    form.resetFields();
  }, [tab, staffSelected]);

  const onFinish = async (values: { password2: string }) => {
    const body = {
      staffId: staffSelected.id,
      pinCode: values?.password2 || '',
    };
    dispatch(settingStaffActions.staffUpdatePin.fetch(body));
  };

  const validatePassCode = (_: any, value: string) => {
    const passCodeRegex = /^\d{4}$/;
    return passCodeRegex.test(value)
      ? Promise.resolve()
      : Promise.reject(
        new Error('Pass Code must be 4 digits long and contain only numbers.')
      );
  };

  return (
    <StaffSecurityStyled className={v2 ? 'v2' : ''}>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <ContainerBoxStyled className='ContainerBoxStyled'>
          <Form.Item
            name="password1"
            label="Pass Code"
            style={{ width: '100%' }}
            rules={[
              {
                validator: validatePassCode,
              },
            ]}
          >
            <InputPin />
          </Form.Item>
          <Form.Item
            style={{ width: '100%' }}
            name="password2"
            label="Confirm New Pass Code"
            rules={[
              { required: true, message: 'Please confirm your password!' },
              {
                validator: validatePassCode,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password1') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!'
                    )
                  );
                },
              }),
            ]}
          >
            <InputPin />
          </Form.Item>
        </ContainerBoxStyled>
        <ButtonGroup />
      </Form>
    </StaffSecurityStyled>
  );
};

export default StaffSecurity;
const StaffSecurityStyled = styled.div`
  border-radius: 5px;
  border: 1px solid ${colorTheme.line_3};
  background: #fff;
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1.5rem 4rem;
  .ant-input {
    height: 100%;
  }
  .ant-input-suffix {
    height: 3.5rem;
  }
  .ant-input-suffix {
    width: 5rem !important;
  }


&.v2 {
  padding: 0px;
  border: none;
  box-shadow: none;
  background: transparent;
  .ContainerBoxStyled {
    flex-direction: row;
    width: unset;
  }
}
`;
const ContainerBoxStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
  width: 30rem;
  margin: auto;
`;

