import AvatarBox from 'components/AvatarBox';
import Box from 'components/Box';
import shopSelectors from 'services/shop/selectors';
import { IStaffItemData } from 'services/shop/types/staff';
import check_icon from '../../v2-icons/check-icon.svg';
import styled from 'styled-components';
import SwitchBtn from '../../ServicesSettingV2/SwitchBtn';

type Props = {
  value?: string[];
  onChange?: (value: string[]) => void;
};
const StaffListChooseUI = ({ onChange = () => undefined, value: selectedStaffs = [] }: Props) => {
  const staffList = shopSelectors.data.staffs();

  const checkAll = staffList.length === selectedStaffs.length;

  const onPickStaff = (item: IStaffItemData) => () => {
    const staffId = item.id;
    const newSelected = selectedStaffs.includes(staffId)
      ? selectedStaffs.filter((id) => id !== staffId)
      : [...selectedStaffs, staffId];
    onChange([...newSelected]);
  };

  const onChangeAll = (value: boolean) => {
    if (value)
      onChange(staffList.map(o => o.id));
    else
      onChange([]);
  };

  return (
    <Container>
      <Box display='flex' pt='4' px='3' style={{ gap: 16 }}>
        <SwitchBtn value={checkAll} onChange={onChangeAll}
          label='Applies to all staff' labelClassName='section-label' />
      </Box>
      <div className="scroll-wrapper">
        <div className={'scroll-container'} >
          <div className="staff-list-container">
            {staffList.map((item) => {
              const selected = !!selectedStaffs.includes(item.id);
              return (
                <AvatarBox
                  key={item.id}
                  anybody={item.anyBody}
                  onClick={onPickStaff(item)}
                  src={item.urlImage}
                  name={item.firstName}
                  selected={selected}
                  cashier
                  className={'cashier-staff-box'}
                  SelectedIconRender={SelectedIconRender}
                />
              );
            })}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default StaffListChooseUI;

const IconBadgeStyled = styled.div`
width: 32px;
height: 32px;
flex-shrink: 0;
border-radius: 3px;
background: #2D6772;
display: flex;
align-items: center;
justify-content: center;
position: absolute;
top: 6px;
right: 6px;
z-index: 9;
`;
const SelectedIconRender = () => {
  return (
    <IconBadgeStyled>
      <img src={check_icon} alt="check_icon" style={{ width: 24, height: 24 }} />
    </IconBadgeStyled>
  );
};

const Container = styled.div`
    flex: 1;
    background-color: #fff;
    align-self: stretch;
    display: flex;
    flex-direction: column;

    .section-label {
      color: #1D2129;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600 !important;
      line-height: normal;
    }
    .group-zoom {
      display: flex;
      align-self: stretch;
      gap: 16px;
      justify-content: flex-end;
      padding: 8px 16px;
      padding-right: 0;
    }
    .scroll-wrapper {
      flex: 1;
      position: relative;
      .scroll-container {
        position: absolute;
        inset: 0;
        overflow: auto;
        scrollbar-width: none; /* Firefox 64 */
        &::-webkit-scrollbar {
          width: 0px;
          height: 8px;
          background-color: #F5F5F5;
        }
      }
    }
    .staff-list-container {
      padding: 24px 16px;
      padding-top: 16px;
      display: grid;
      column-gap: 8px;
      row-gap: 16px;

      grid-template-columns: repeat(auto-fill,minmax(120px, 1fr));
      .cashier-staff-box {
        width: 100%;
        padding: 0rem 0;
        padding-top: 8px;
        padding-bottom: 16px;
         .AnyBodyImage {
            width: 6rem !important;
            height: 6rem !important;
            min-height: unset !important;
          }
      }

      .circle-avatar {
        width: 6rem;
        height: 6rem;
        min-height: unset;
      }
    }
`;
