import React, { useState, useEffect } from 'react';
import { Form, Checkbox } from 'antd';
import styled from 'styled-components';
import Box from 'components/Box';
import dayjs from 'dayjs';

interface TimeBlockProps {
  time: string;
  isSelected: boolean;
  isActive?: boolean;
  isHovered?: boolean;
  isDisabled?: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const TimeBlock: React.FC<TimeBlockProps> = ({
  time,
  isSelected,
  isActive,
  isHovered,
  isDisabled,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <TimeBlockStyled
      className={`
        ${isSelected ? 'selected' : ''} 
        ${isActive ? 'active' : ''}
        ${isHovered ? 'hovered' : ''}
        ${isDisabled ? 'disabled' : ''} 
      `}
      onClick={() => !isDisabled && onClick()}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {time}
    </TimeBlockStyled>
  );
};

interface TimeRangeValue {
  start: string;
  end: string;
}

const DayScheduleV2: React.FC<{
  day: string;
  enable: string;
  start: string;
  end: string;
}> = ({ day, enable, start, end }) => {
  const form = Form.useFormInstance();

  const extractTimeString = (value: any): string => {
    if (!value) return '';

    try {
      const time = dayjs(value);
      
      if (time.isValid()) {
        return time.format('hh:mm A');
      }
      
      if (typeof value === 'string' && /^\d{1,2}:\d{2}$/.test(value)) {
        return dayjs(`2000-01-01 ${value}`).format('hh:mm A');
      }
      
      if (typeof value === 'string') {
        return value;
      }
      
      return '';
    } catch (e) {
      console.error('Error formatting time:', e);
      return '';
    }
  };

  const timeStringToDayjs = (timeStr: string): any => {
    if (!timeStr || timeStr === '--:-- --') return null;

    try {
      return dayjs(`2000-01-01 ${timeStr}`, 'YYYY-MM-DD hh:mm A');
    } catch (e) {
      console.error('Error converting time string to dayjs:', e);
      return null;
    }
  };

  const [isEnabled, setIsEnabled] = useState<boolean>(() => 
    form.getFieldValue(enable) || false
  );

  const [selectedRange, setSelectedRange] = useState<TimeRangeValue>(() => {
    const startVal = form.getFieldValue(start);
    const endVal = form.getFieldValue(end);

    return {
      start: extractTimeString(startVal) || '--:-- --',
      end: extractTimeString(endVal) || '--:-- --',
    };
  });

  const [hoveredTime, setHoveredTime] = useState<string | null>(null);
  const [activeSelection, setActiveSelection] = useState<'start' | 'end' | null>(null);
  const [showTimeGrid, setShowTimeGrid] = useState(false);

  const allTimes = [
    '09:00 AM', '09:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM',
    '12:00 PM', '12:30 PM', '01:00 PM', '01:30 PM', '02:00 PM', '02:30 PM',
    '03:00 PM', '03:30 PM', '04:00 PM', '04:30 PM', '05:00 PM', '05:30 PM',
    '06:00 PM', '06:30 PM', '07:00 PM', '07:30 PM', '08:00 PM', '08:30 PM',
  ];

  useEffect(() => {
    const startTime = form.getFieldValue(start);
    const endTime = form.getFieldValue(end);
    const enableValue = form.getFieldValue(enable);

    setIsEnabled(enableValue || false);

    setSelectedRange((prev) => {
      const newState = { ...prev };

      if (startTime) {
        const startStr = extractTimeString(startTime);
        if (startStr) newState.start = startStr;
      }

      if (endTime) {
        const endStr = extractTimeString(endTime);
        if (endStr) newState.end = endStr;
      }

      return newState;
    });
  }, [form.getFieldValue(start), form.getFieldValue(end), form.getFieldValue(enable)]);

  const handleCheckboxChange = (e: any) => {
    const checked = e.target.checked;
    setIsEnabled(checked);
    
    if (!checked) {
      setShowTimeGrid(false);
      setActiveSelection(null);
    }
  };

  const handleTimeBoxClick = (type: 'start' | 'end') => {
    if (isEnabled) {
      setActiveSelection(type);
      setShowTimeGrid(true);
    }
  };

  const isTimeDisabled = (time: string): boolean => {
    if (activeSelection !== 'end' || !selectedRange.start) return false;

    const timeIndex = allTimes.indexOf(time);
    const startIndex = allTimes.indexOf(selectedRange.start);

    return timeIndex < startIndex;
  };

  const isTimeActive = (time: string): boolean => {
    if (activeSelection === 'start' && time === selectedRange.start) return true;
    if (activeSelection === 'end' && time === selectedRange.end) return true;
    return false;
  };

  const isTimeSelected = (time: string): boolean => {
    if (selectedRange.start && selectedRange.end) {
      const startIndex = allTimes.indexOf(selectedRange.start);
      const endIndex = allTimes.indexOf(selectedRange.end);
      const currentIndex = allTimes.indexOf(time);

      if (currentIndex >= startIndex && currentIndex <= endIndex) {
        return true;
      }
    }
    return false;
  };

  const isTimeInHoverPreview = (time: string): boolean => {
    if (activeSelection !== 'end' || !selectedRange.start || !hoveredTime) {
      return false;
    }

    const startIndex = allTimes.indexOf(selectedRange.start);
    const hoverIndex = allTimes.indexOf(hoveredTime);
    const currentIndex = allTimes.indexOf(time);

    if (hoverIndex >= startIndex) {
      return currentIndex >= startIndex && currentIndex <= hoverIndex;
    }

    return false;
  };

  const handleTimeClick = (time: string) => {
    if (!isEnabled || isTimeDisabled(time)) return;

    if (activeSelection === 'start') {
      const dayjsValue = timeStringToDayjs(time);
      const endValue = timeStringToDayjs(selectedRange.end);
      form.setFieldsValue({
        [start]: dayjsValue,
        [end]: endValue,
      });

      setActiveSelection('end');
    } else if (activeSelection === 'end') {
      const startIndex = allTimes.indexOf(selectedRange.start);
      const currentIndex = allTimes.indexOf(time);

      if (currentIndex >= startIndex) {
        const newRange = { ...selectedRange, end: time };
        setSelectedRange(newRange);

        const dayjsValue = timeStringToDayjs(time);
        form.setFieldsValue({ [end]: dayjsValue });

        setShowTimeGrid(false);
        setActiveSelection(null);
        setHoveredTime(null);
      }
    } else {
      if (!selectedRange.start || (selectedRange.start && selectedRange.end)) {
        const newRange = { start: time, end: '' };
        setSelectedRange(newRange);

        const dayjsValue = timeStringToDayjs(time);
        form.setFieldsValue({
          [start]: dayjsValue,
          [end]: null,
        });

        setActiveSelection('end');
      } else if (selectedRange.start && !selectedRange.end) {
        const startIndex = allTimes.indexOf(selectedRange.start);
        const currentIndex = allTimes.indexOf(time);

        if (currentIndex >= startIndex) {
          const newRange = { ...selectedRange, end: time };
          setSelectedRange(newRange);

          const dayjsValue = timeStringToDayjs(time);
          form.setFieldsValue({ [end]: dayjsValue });

          setShowTimeGrid(false);
          setActiveSelection(null);
          setHoveredTime(null);
        }
      }
    }
  };

  return (
    <DayScheduleStyled className='item-schedule'>
      <HeaderRow>
        <Box
          bgColor='fill_2'
          display='flex'
          justifyContent='start'
          alignItems='center'
          style={{ padding: 24 }}
        >
          <FormItemNoMargin name={enable} valuePropName='checked'>
            <Checkbox 
              className='custom-checkbox'
              onChange={handleCheckboxChange}
            >
              <span>{day}</span>
            </Checkbox>
          </FormItemNoMargin>
        </Box>
        <TimeSelectionContainer>
          <TimeBox
            onClick={() => handleTimeBoxClick('start')}
            isActive={activeSelection === 'start'}
            isDisabled={!isEnabled}
          >
            {selectedRange.start || '--:-- --'}
          </TimeBox>

          <TimeBoxSeparator>-</TimeBoxSeparator>

          <TimeBox
            onClick={() => handleTimeBoxClick('end')}
            isActive={activeSelection === 'end'}
            isDisabled={!isEnabled}
          >
            {selectedRange.end || '--:-- --'}
          </TimeBox>
        </TimeSelectionContainer>

        <HiddenFormFields>
          <FormItemNoMargin name={start}>
            <input type='hidden' />
          </FormItemNoMargin>
          <FormItemNoMargin name={end}>
            <input type='hidden' />
          </FormItemNoMargin>
        </HiddenFormFields>
      </HeaderRow>
      <BodyRow>
        <div />
        {showTimeGrid && isEnabled && (
          <TimeGridContainer>
            <TimeGrid>
              {allTimes.map((time) => (
                <TimeBlock
                  key={time}
                  time={time}
                  isSelected={isTimeSelected(time)}
                  isHovered={isTimeInHoverPreview(time)}
                  isActive={isTimeActive(time)}
                  isDisabled={isTimeDisabled(time)}
                  onClick={() => handleTimeClick(time)}
                  onMouseEnter={() => setHoveredTime(time)}
                  onMouseLeave={() => setHoveredTime(null)}
                />
              ))}
            </TimeGrid>
          </TimeGridContainer>
        )}
      </BodyRow>
    </DayScheduleStyled>
  );
};

export default DayScheduleV2;

// Styled Components
const DayScheduleStyled = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  .ant-picker-input {
    position: relative;
    display: inline-block;
  }
  .ant-picker-input::after {
    content: '';
    position: absolute;
    left: 10%;
    bottom: 0;
    width: 80%;
    border-bottom: 1px solid #1d2129;
  }

  .ant-checkbox-wrapper {
    zoom: 2.5;
    height: 20px;
    align-items: center;
  }
  .ant-checkbox-checked:after {
    border: 2px solid #2d6772;
  }
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-inner,
  .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    border-color: #6fabb6;
  }
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
    border-color: #6fabb6;
  }
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner,
  .ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    background-color: #6fabb6;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner:after,
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2d6772;
    border-color: #2d6772;
  }
  .custom-checkbox > span {
    flex: 1;
    color: #1d2129;
    font-family: Poppins;
    font-size: 0.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

const HeaderRow = styled.div`
  display: grid;
  grid-template-columns: 250px auto 1fr;
  align-items: center;
`;

const BodyRow = styled.div`
  display: grid;
  grid-template-columns: 250px auto;
  align-items: center;
`;

const TimeSelectionContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0 16px;
`;

const TimeBox = styled.div<{ isActive?: boolean; isDisabled?: boolean }>`
  position: relative;
  padding: 12px 12px;
  min-width: 110px;
  border: 1px solid ${(props) => (props.isActive ? '#D2464F' : '#d9d9d9')};
  border-radius: 2px;
  color: ${(props) => (props.isDisabled ? '#999' : '#333')};
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  background-color: ${(props) => (props.isDisabled ? '#f5f5f5' : '#fff')};
  transition: all 0.2s;
  opacity: ${(props) => (props.isDisabled ? 0.7 : 1)};
  font-family: Poppins;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: center;
  vertical-align: middle;
  
  &:before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    border-radius: 4px;
    border: ${(props) => (props.isActive ? '2.5px solid #eb3f4a24' : '0px solid transparent')};
    transition: all 0.2s;
    pointer-events: none;
  }

  &:hover {
    border-color: ${(props) => (props.isDisabled ? '#d9d9d9' : '#d2464f')};
    color: ${(props) => (props.isDisabled ? '#999' : 'black')};
    
    &:before {
      border: ${(props) => (props.isDisabled ? '0px solid transparent' : '2.5px solid #eb3f4a24')};
    }
  }
`;

const TimeBoxSeparator = styled.span`
  margin: 0 8px;
  color: #999;
`;

const HiddenFormFields = styled.div`
  display: none;
`;

const TimeGridContainer = styled.div`
  padding: 16px;
`;

const TimeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(4, auto);
  gap: 4px;
`;

const TimeBlockStyled = styled.div`
  position: relative;
  flex: 1;
  text-align: center;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.2s;
  font-family: Poppins;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: center;
  vertical-align: middle;

  &:before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    border-radius: 4px;
    border: 0px solid transparent;
    transition: all 0.2s;
    pointer-events: none;
  }

  &:hover:not(.disabled) {
    background-color: #fff;
    border-color: #d2464f;
    color: black;
    
    &:before {
      border: 2.5px solid #eb3f4a24;
    }
  }

  &.selected {
    background-color: #2d6772;
    border-color: #2d6772;
    color: white;
    
    &:before {
      border: 2.5px solid transparent;
    }
  }

  &.hovered {
    background-color: rgba(45, 103, 114, 0.6);
    border-color: #2d6772;
    color: white;
    
    &:before {
      border: 2.5px solid transparent;
    }
  }

  &.active {
    background-color: #2d6772;
    border-color: #2d6772;
    color: white;
    
    &:before {
      border: 2.5px solid transparent;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: #f5f5f5;
  }
`;

const FormItemNoMargin = styled(Form.Item)`
  margin: 0 !important;
`;