import IconLogo from 'assets/Icons/logo';
import Box from 'components/Box';
import Sidebar from 'components/layout/Sidebar';
import reportSelectors from 'features/report/services/selectors';
import {
  IPrintTimeClockRange,
  IPrintTimeClockRangeStaff,
} from 'features/report/services/types/reportStaff';
import moment from 'moment';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import PrintHeader from '../../components/PrintHeader';
import { ReportType } from '../../constants';
import { formatPhoneNumber, renderTime } from '../../utils';
import Text from 'components/Text';

const TimeClockStaffReportPrintPage = () => {
  const navigate = useNavigate();
  const shopInfo = shopSelectors.data.shopInfo();
  const dataAppointment = reportSelectors.getParams()?.[ReportType.CLOCK_TIME];
  const isSameDay = moment(dataAppointment?.startTime).isSame(
    dataAppointment?.endTime
  );
  const dataPrintTimeClockRange =
    reportSelectors.getPrintTimeClockRange() as IPrintTimeClockRange;

  const onBack = () => {
    navigate(-1);
  };

  const divRef = useRef<HTMLDivElement>(null);
  const onPrint = useReactToPrint({
    content: () => divRef.current,
    copyStyles: true,
  });

  return (
    <TimeClockRangeReportPrintPageStyled>
      <Sidebar />
      <ContainerStyled>
        <PrintHeader onBack={onBack} onPrint={onPrint} />
        <div ref={divRef}>
          <Box className='center' overflow='auto'>
            <TicketBox>
              {/* Salon Header */}
              <SalonHeaderSection>
                <IconLogo />
                <SalonName>{shopInfo?.shopName}</SalonName>
                <SalonAddress>{[shopInfo?.address, shopInfo?.city, shopInfo?.state, shopInfo?.zip].filter(Boolean).join(', ')}</SalonAddress>
                <SalonPhone>{formatPhoneNumber(shopInfo?.phone)}</SalonPhone>
                <DateRange> {renderTime(dataAppointment)}</DateRange>
              </SalonHeaderSection>

              <Divider />
              {isSameDay ? (
                <>
                  <Text
                    textAlign='center'
                    variant='H9'
                    textTransform='uppercase'
                    py={0.5}
                  >
                    {dataPrintTimeClockRange.staffs?.[0].staffName}
                  </Text>
                  <Divider />
                  <SummarySection>
                    <SummaryRow>
                      <SummaryLabel>Clock In</SummaryLabel>
                      <SummaryValue>
                        {dataPrintTimeClockRange.staffs?.[0].items?.[0]
                          .clockInTime || 0}{' '}
                        hours
                      </SummaryValue>
                    </SummaryRow>
                    <SummaryRow>
                      <SummaryLabel>Clock Out</SummaryLabel>
                      <SummaryValue>
                        {dataPrintTimeClockRange.staffs?.[0].items?.[0]
                          .clockOutTime || 0}{' '}
                        hours
                      </SummaryValue>
                    </SummaryRow>
                    <SectionDivier />
                    <SummaryRow>
                      <SummaryTipLabel>Tips</SummaryTipLabel>
                      <SummaryValue>
                        {formatCurrency(dataPrintTimeClockRange?.totalTip || 0)}
                      </SummaryValue>
                    </SummaryRow>
                    <SectionDivier />
                    <PayoutRow>
                      <SummaryLabel>HOURLY PAYROLL</SummaryLabel>
                      <SummaryValue>
                        {dataPrintTimeClockRange?.totalHours || 0} hours
                      </SummaryValue>
                    </PayoutRow>
                    <PayoutRow>
                      <SummaryLabel>HOURLY PAY OUT</SummaryLabel>
                      <SummaryValue>
                        {formatCurrency(
                          dataPrintTimeClockRange?.totalPayout || 0
                        )}
                      </SummaryValue>
                    </PayoutRow>
                  </SummarySection>
                </>
              ) : (
                <>
                  {/* Summary Section */}
                  <SummarySection>
                    <SummaryRow>
                      <SummaryLabel>Hourly Payroll</SummaryLabel>
                      <SummaryValue>
                        {dataPrintTimeClockRange?.totalHours || 0} hours
                      </SummaryValue>
                    </SummaryRow>
                    <SummaryRow>
                      <SummaryLabel>Tips</SummaryLabel>
                      <SummaryValue>
                        {formatCurrency(dataPrintTimeClockRange?.totalTip || 0)}
                      </SummaryValue>
                    </SummaryRow>
                    <PayoutRow>
                      <SummaryLabel>HOURLY PAY OUT</SummaryLabel>
                      <SummaryValue>
                        {formatCurrency(
                          dataPrintTimeClockRange?.totalPayout || 0
                        )}
                      </SummaryValue>
                    </PayoutRow>
                  </SummarySection>

                  <Divider />

                  {/* Staff Sections */}
                  {dataPrintTimeClockRange?.staffs?.map(
                    (staff: IPrintTimeClockRangeStaff) => (
                      <StaffSection key={staff.staffId}>
                        {/* Staff Header */}
                        <StaffHeader>
                          <StaffName>
                            {staff.staffName}: {staff.totalHourly} HOURS
                          </StaffName>
                        </StaffHeader>

                        {/* Table Header */}
                        <TableHeader>
                          <DateColumn>DATE</DateColumn>
                          <ClockInColumn>CLOCK IN</ClockInColumn>
                          <ClockOutColumn>CLOCK OUT</ClockOutColumn>
                          <HoursColumn>HOURS</HoursColumn>
                          <PayoutColumn>PAY OUT</PayoutColumn>
                        </TableHeader>

                        {/* Staff Records */}
                        {staff.items.map((item, index) => (
                          <TableRow key={index}>
                            <DateColumn>{item.date}</DateColumn>
                            <ClockInColumn>{item.clockInTime}</ClockInColumn>
                            <ClockOutColumn>{item.clockOutTime}</ClockOutColumn>
                            <HoursColumn>{item.hourly}H</HoursColumn>
                            <PayoutColumn>
                              {formatCurrency(item.payout)}
                            </PayoutColumn>
                          </TableRow>
                        ))}
                      </StaffSection>
                    )
                  )}
                </>
              )}
            </TicketBox>
          </Box>
        </div>
      </ContainerStyled>
    </TimeClockRangeReportPrintPageStyled>
  );
};

const TimeClockRangeReportPrintPageStyled = styled.div`
  display: flex;
`;

const TicketBox = styled.div`
  background: #fff;
  width: 72mm;
  height: fit-content;
  padding: 1.5rem 0 0.5rem;
`;

const ContainerStyled = styled.div`
  background-color: #e5e5e5;
  height: 100vh;
  position: relative;
  flex-grow: 1;
  width: 100%;
  overflow: auto;
`;

const SalonHeaderSection = styled.div`
  padding: 16px;
  text-align: center;
`;

const SalonName = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 4px;
`;

const SalonAddress = styled.div`
  font-size: 12px;
  margin-bottom: 2px;
`;

const SalonPhone = styled.div`
  font-size: 12px;
  margin-bottom: 8px;
`;

const DateRange = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-top: 8px;
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid black;
  margin: 0 8px;
`;

const SectionDivier = styled(Divider)`
  border: none;
  border-top: 1px solid black;
  margin: 8px 0px;
`;

const SummarySection = styled.div`
  padding: 8px 0px;
  margin: 0 8px;
`;

const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 14px;
`;

const PayoutRow = styled(SummaryRow)`
  background-color: black;
  color: white;
  padding: 8px 1px;
  font-weight: 500;
`;

const SummaryLabel = styled.div`
  font-weight: 500;
`;

const SummaryValue = styled.div`
  text-align: right;
`;

const SummaryTipLabel = styled.div`
  font-weight: bold;
  text-transform: uppercase;
`;

const StaffSection = styled.div`
  margin: 0 8px;
  margin-bottom: 16px;
`;

const StaffHeader = styled.div`
  background-color: black;
  color: white;
  padding: 8px 16px;
  font-weight: bold;
  margin: 8px 0 0 0;
  display: inline-block;
`;

const StaffName = styled.div`
  font-size: 14px;
`;

const TableHeader = styled.div`
  display: flex;
  padding: 6px 0px;
  border-bottom: 1px dashed black;
  font-size: 10px;
  font-weight: 600;
`;

const TableRow = styled.div`
  display: flex;
  padding: 4px 0px;
  font-size: 10px;
`;

const DateColumn = styled.div`
  width: 12%;
`;

const ClockInColumn = styled.div`
  width: 25%;
  text-align: right;
`;

const ClockOutColumn = styled.div`
  width: 25%;
  text-align: right;
`;

const HoursColumn = styled.div`
  width: 16%;
  text-align: right;
`;

const PayoutColumn = styled.div`
  width: 22%;
  text-align: right;
`;

export default TimeClockStaffReportPrintPage;
