import { Form, InputRef } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import { DollarAmountInputRef } from 'components/DollarAmount/helpers';
import { useDollarAmountInputRef } from 'components/DollarAmount/Input';
import DollarAmountKeyboard from 'components/DollarAmount/Keyboard';
import NumberPadV2 from 'components/NumberKeyBoard/Currency/NumberPadV2';
import Text from 'components/Text';
import { find, first } from 'lodash';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import CardFeeDiscount from './CardFeeDiscount';
import { KB_TYPES, MULTI_PAYMENT_TYPES, PAYMENT_FORM_NAMES } from './constants';
import EPayInputs from './EPayInputs';
import { calcMaxPayment, calcRemaining } from './helpers';
import infor_icon from './icons/infor.svg';
import PassValueForm from './PassValueForm';
import PaymentInputItem from './PaymentInputItem';
import shopSelectors from 'services/shop/selectors';
import { PAYMENT_TYPE } from 'features/payment/services/constants';

type Props = {
  onClose?: () => void;
};
type Ref = {
  init: () => void;
};
const ERROR_NAME_ARRS = [...PAYMENT_FORM_NAMES, 'amountValid'];
export const useMultiPayFormRef = () => useRef<Ref>(null);
const MultiPayForm = forwardRef<Ref, Props>(({ onClose }, ref) => {
  const form = Form.useFormInstance();
  const cardRef = useDollarAmountInputRef();
  const cashRef = useDollarAmountInputRef();
  const giftCardRef = useDollarAmountInputRef();
  const giftCreditRef = useDollarAmountInputRef();
  const pointRef = useDollarAmountInputRef();

  const checkRef = useDollarAmountInputRef();
  const cashAppRef = useDollarAmountInputRef();
  const venmoRef = useDollarAmountInputRef();
  const paypalRef = useDollarAmountInputRef();
  const zelleRef = useDollarAmountInputRef();
  const checkNoRef = useRef<InputRef>(null);
  const giftCodeRef = useRef<InputRef>(null);
  const giftCreditCodeRef = useRef<InputRef>(null);

  const payments = shopSelectors.data.orderPaymentMethods();
  const giftCreditLabel = useMemo(() => find(payments, (o) => o.methodType === PAYMENT_TYPE.GIFT_CREDIT)?.name || 'Gift Credit', [payments]);

  const isGiftCredit = useMemo(() => !!find(payments, o => o.methodType === MULTI_PAYMENT_TYPES.GIFT_CREDIT), [payments]);

  const [kbType, setKeyboardType] = useState<KB_TYPES>(MULTI_PAYMENT_TYPES.CARD);
  const onFocus = (type: KB_TYPES) => () => {
    setKeyboardType(type);
    if (type == 'CHECK_NO' || type == 'GIFT_CODE' || type == 'GIFT_CREDIT_CODE') return;
    form.setFieldValue('kbType', type);
  };
  const handleClear = () => {
    form.resetFields(PAYMENT_FORM_NAMES);
    form.resetFields(['giftCardCode', 'giftCreditCode', 'otherCheckInfo.checkNumber']);
  };
  useImperativeHandle(ref, () => ({
    init: () => cardRef.current?.init(),
  }));

  return (
    <Container>
      <Header><span>PAYMENTS</span></Header>
      <Box display='flex' gap='4'>
        <Text className='modal-keyboard-label' mt={1} mb={-0.5}>
          Ticket Amount:
        </Text>
        <PassValueForm
          name={['detail', 'total']}
          render={e => <p className='total-ticket-amount'>{formatCurrency(e)}</p>}
        />
      </Box>
      <Form.Item noStyle name={'kbType'} />
      <Remaining>
        <div className="flex-container">
          <img src={infor_icon} alt="infor_icon" />
          <span className='label'>Remaining:</span>
        </div>
        <Form.Item shouldUpdate noStyle dependencies={['detail']}>
          {({ getFieldsValue }) => {
            const remaining = calcRemaining(getFieldsValue());
            return <span className="value">{formatCurrency(remaining)}</span>;
          }}
        </Form.Item>
        {/* <PassValueForm
          name={'chance'}
          render={chance => chance > 0 && <>
            <Text ml={2} />
            <div className="flex-container">
              <img src={infor_icon} alt="infor_icon" />
              <span className='label'>Chance:</span>
            </div>
            <span className="value">{formatCurrency(-chance)}</span>
          </>}
        /> */}
      </Remaining>
      <Payments>
        <PaymentInputItem
          active={kbType === MULTI_PAYMENT_TYPES.CARD}
          type={MULTI_PAYMENT_TYPES.CARD} inputRef={cardRef} onFocus={onFocus} />
        <PaymentInputItem
          active={kbType === MULTI_PAYMENT_TYPES.CASH}
          type={MULTI_PAYMENT_TYPES.CASH} inputRef={cashRef} onFocus={onFocus} />
        <PaymentInputItem
          active={kbType === MULTI_PAYMENT_TYPES.CHECK}
          type={MULTI_PAYMENT_TYPES.CHECK} inputRef={checkRef} onFocus={onFocus}
          checkNoRef={checkNoRef}
        />
        <PaymentInputItem
          type={MULTI_PAYMENT_TYPES.GIFT_CARD} inputRef={giftCardRef} onFocus={onFocus}
          giftRef={giftCodeRef}
        />
        <PaymentInputItem
          active={kbType === MULTI_PAYMENT_TYPES.POINT}
          type={MULTI_PAYMENT_TYPES.POINT} inputRef={pointRef} onFocus={onFocus}
        />
        <EPayInputs
          onFocus={onFocus}
          cashAppRef={cashAppRef}
          paypalRef={paypalRef}
          venmoRef={venmoRef}
          zelleRef={zelleRef}
        />
        {isGiftCredit && <PaymentInputItem
          type={MULTI_PAYMENT_TYPES.GIFT_CREDIT} inputRef={giftCreditRef} onFocus={onFocus}
          giftRef={giftCreditCodeRef}
          label={giftCreditLabel}
        />}
      </Payments>
      <Form.Item noStyle name={'amountValid'} dependencies={PAYMENT_FORM_NAMES} rules={[
        ({ getFieldsValue, getFieldValue }) => ({
          async validator() {
            const value = calcRemaining(getFieldsValue(), true);
            const chance = getFieldValue('chance');
            if (value > 0) return Promise.reject('Please enter amount payment');
            return Promise.resolve();
          }
        }),
      ]} />
      <KBDisplay type={MULTI_PAYMENT_TYPES.CARD} kbType={kbType} controlRef={cardRef} />
      <KBDisplay type={MULTI_PAYMENT_TYPES.CASH} kbType={kbType} controlRef={cashRef} />
      <KBDisplay type={MULTI_PAYMENT_TYPES.CHECK} kbType={kbType} controlRef={checkRef} />
      <KBDisplay type={MULTI_PAYMENT_TYPES.GIFT_CARD} kbType={kbType} controlRef={giftCardRef} />
      <KBDisplay type={MULTI_PAYMENT_TYPES.GIFT_CREDIT} kbType={kbType} controlRef={giftCreditRef} />

      <KBDisplay type={MULTI_PAYMENT_TYPES.PAYPAL} kbType={kbType} controlRef={paypalRef} />
      <KBDisplay type={MULTI_PAYMENT_TYPES.VENMO} kbType={kbType} controlRef={venmoRef} />
      <KBDisplay type={MULTI_PAYMENT_TYPES.CASH_APPS} kbType={kbType} controlRef={cashAppRef} />
      <KBDisplay type={MULTI_PAYMENT_TYPES.ZELLE} kbType={kbType} controlRef={zelleRef} />

      <KBDisplay type={MULTI_PAYMENT_TYPES.POINT} kbType={kbType} controlRef={pointRef} />
      <WrapperKBStyled hidden={kbType !== 'CHECK_NO'}>
        <Form.Item noStyle name={['otherCheckInfo', 'checkNumber']}>
          <NumberPadV2
            type={'NUMBER'}
            onChange={() => checkNoRef.current?.focus()}
          />
        </Form.Item>
      </WrapperKBStyled>
      <WrapperKBStyled hidden={kbType !== 'GIFT_CODE'}>
        <Form.Item noStyle name={'giftCardCode'}>
          <NumberPadV2
            type={'NUMBER'}
            onChange={() => giftCodeRef.current?.focus()}
          />
        </Form.Item>
      </WrapperKBStyled>
      <WrapperKBStyled hidden={kbType !== 'GIFT_CREDIT_CODE'}>
        <Form.Item noStyle name={'giftCreditCode'}>
          <NumberPadV2
            type={'NUMBER'}
            onChange={() => giftCreditCodeRef.current?.focus()}
          />
        </Form.Item>
      </WrapperKBStyled>
      <Buttons>
        <Button htmlType='button' height={4.375} onClick={onClose} block cardCashier ><span className="btn-label">Close</span></Button>
        <Button htmlType='button' height={4.375} onClick={handleClear} block cardCashier ntype='LIGHT_BLUE'><span className="btn-label">Clear</span></Button>
        <Form.Item shouldUpdate noStyle>{({ getFieldsError }) => {
          const errors = getFieldsError().filter(o => o.errors.length > 0).map(o => ({ errors: o.errors, name: first(o.name) }));
          const error = first(find(errors, o => ERROR_NAME_ARRS.includes(o.name as string))?.errors) || '';
          return <Button disabled={!!error} htmlType='submit' height={4.375} block cardCashier ntype='PRIMARY'><span className="btn-label">OK</span></Button>;
        }}</Form.Item>
      </Buttons>
      <CardFeeDiscount />
    </Container>
  );
});
MultiPayForm.displayName = 'MultiPayForm';
export default MultiPayForm;

const KBDisplay = ({ type, controlRef, kbType }: { kbType?: KB_TYPES, type: MULTI_PAYMENT_TYPES, controlRef?: React.RefObject<DollarAmountInputRef> | undefined }) => {
  const form = Form.useFormInstance();
  return (
    <WrapperKBStyled hidden={kbType !== type}>
      <Form.Item noStyle name={type}>
        <DollarAmountKeyboard
          controlRef={controlRef}
          onChange={() => form.validateFields([type])}
        />
      </Form.Item>
    </WrapperKBStyled>
  );
};

const WrapperKBStyled = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  .NUMBER-PAD-BUTTON {
    height: 70px;
  }
  ${({ hidden }) => hidden && `
    display: none;
  
  `}
`;

const Buttons = styled.div`
  display: flex;
  align-self: stretch;
  padding: 8px 16px;
  gap: 8px;
  .btn-label {
    font-size: 24px;
  }
`;
const Payments = styled.div`
display: flex;
padding: 8px 16px;
align-items: flex-start;
align-self: stretch;
  
`;

const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
flex-shrink: 0;
background: #fff;
.total-ticket-amount {
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.NumberPadStyled {
  padding: 8px 12px;
  align-self: stretch;
  .WRAPPER-KEYBOARD {
    margin: 0 !important;
  }
}
`;

const Remaining = styled.div`
display: flex;
padding: 4px 0px;
justify-content: center;
align-items: center;
gap: 24px;
align-self: stretch;
background: #FDF6F4;

span.label {
  color: #FF7D00;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
span.value {
  color: #FF7D00;
  text-align: right;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.flex-container {
  display: flex;
  align-items: center;
  gap: 8px;
}
`;

const Header = styled.div`
display: flex;
padding: 10px;
justify-content: center;
align-items: center;
gap: 10px;
align-self: stretch;
background: #232F3E;
span {
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
`;