import { Form, Input, InputNumber, Select } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import MyRadio from '../../SettingPage/components/PaymentTabMenu/components/MyRadio';
import ActionButtons from '../../SettingPage/components/PaymentTabMenu/components/ActionButtons';
const { Option } = Select;
type ILateForWork = {
  v2?: boolean;
};
const LateForWork: React.FC<ILateForWork> = ({ v2 }: ILateForWork) => {
  const dispatch = useAppDispatch();
  const reloadLateForWork = settingServiceSelectors.reloadLateForWork();
  const lateForWork = settingServiceSelectors.getLateForWork();
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(settingServiceActions.getLateForWork.fetch({}));
  }, []);
  useEffect(() => {
    form.setFieldsValue({ lateTime: lateForWork?.lateTime, turn: lateForWork?.turn });
  }, [lateForWork]);

  useEffect(() => {
    if (reloadLateForWork) {
      dispatch(settingServiceActions.getLateForWork.fetch({}));
    }
  }, [reloadLateForWork]);

  const onFinish = (values: any) => {
    const payload = { lateTime: +values.lateTime, turn: +values.turn };
    dispatch(settingServiceActions.updateLateForWork.fetch(payload));
  };
  if (v2) return (
    <ContainerV2>
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
      >
        <div className="form-ui">
          <Form.Item
            name={'turn'}
            noStyle
            rules={[{ required: true, message: 'Missing Turn' }]}
          >
            <TurnOptionTypes />
          </Form.Item>
          <Form.Item
            name={'lateTime'}
            label='Late Time'
            style={{ marginBottom: 0, alignSelf: 'stretch' }}
            rules={[{ required: true, message: 'Missing Late Time' }]}

          >
            <Input
              placeholder='0 minute'
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>
          <p className='note-text'>Ex: Late 45 minutes = - 1 turn </p>
        </div>
        <ActionButtons />
      </Form>
    </ContainerV2>
  );
  return (
    <LateForWorkStyled>
      <Box>
        <Form
          form={form}
          layout='vertical'
          onFinish={onFinish}
        >
          <Box bgColor='fill_1' p='3' borderRadius='5px'>
            <Box display='flex' flexWrap='nowrap' gap='4' alignItems='end'>
              <ItemFormStyled>
                <FormItemStyled
                  name={'lateTime'}
                  label='Late Time'
                  rules={[{ required: true, message: 'Missing Late Time' }]}
                >
                  <InputNumber
                    placeholder='0 minute'
                    style={{ width: '100%', height: '4rem' }}
                    min={0}
                    max={100}
                  />
                </FormItemStyled>
              </ItemFormStyled>
              <ItemFormStyled>
                <FormItemStyled
                  name={'turn'}
                  label='Turn'
                  rules={[{ required: true, message: 'Missing Turn' }]}
                >
                  <Select
                    placeholder='Select a turn'
                    style={{ width: '100%', height: '4rem' }}
                  >
                    <Option value={1}>Full Turn</Option>
                    <Option value={0.5}>Half Turn</Option>
                    <Option value={0}>0 Turn</Option>
                  </Select>
                </FormItemStyled>
              </ItemFormStyled>
            </Box>
          </Box>
          <Box display='flex' gap='3' mt='4' justifyContent='end'>
            <Button width='10rem' ntype='SECONDARY'>
              Close
            </Button>
            <Form.Item>
              <Button width='10rem' ntype='PRIMARY' htmlType='submit'>
                Save
              </Button>
            </Form.Item>
          </Box>
        </Form>
      </Box>
    </LateForWorkStyled>
  );
};

export default LateForWork;
const ContainerV2 = styled.div`
background: #fff;
flex: 1;
padding: 16px 24px;
.ant-form-item-label label {
    color: #1D2129;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.ant-input {
  color: #1D2129;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  height: 56px;
  padding: 0px 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.form-ui {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  align-self: stretch;
  background: var(--Neutral-02, #F6F7FC);
}

.note-text {
  color: #505050;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
`;


const LateForWorkStyled = styled.div`
  flex-grow: 1;
  background: var(--fill-fill-3, #e5e5e5);
  border-radius: 5px;
  .ant-input-number-input-wrap {
    height: 100%;
    input {
      height: 100%;
    }
  }
`;
const ItemFormStyled = styled.div`
  width: 100%;
`;

const FormItemStyled = styled(Form.Item)`
  margin: 0;
`;

type TurnOptionProps = {
  value?: number;
  onChange?: (value: number) => void;
};
const opts = [
  { value: 1, label: 'Full Turn' },
  { value: 0.5, label: 'Half Turn' },
  { value: 0, label: '0 Turn' },
];
const TurnOptionTypes = ({ value, onChange = () => undefined }: TurnOptionProps) => {
  return (
    <Box display='flex' style={{ gap: 16 }}>
      {opts.map((opt) => (
        <MyRadio
          key={opt.value}
          value={value == opt.value}
          label={opt.label}
          onChange={() => onChange(opt.value)}
        />
      ))}
    </Box>
  );
};
