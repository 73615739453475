import { Form, Input } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import styled from 'styled-components';
import check_link_input_icon from '../../v2-icons/check-link-input.svg';
import check_name_input_icon from '../../v2-icons/check-name-input.svg';
import close_icon from '../../v2-icons/close-icon.svg';
import ActionButtons from '../components/ActionButtons';
import MyRadio from '../components/MyRadio';
import SectionV2 from '../components/SectionV2';
import UploadQRImage from '../components/UploadQRImage';
const checkNameIcon =
  <Box display='flex' alignItems='center' pl='2' pr='3' mr='1' justifyContent='center' style={{ borderRight: '1px solid #86909C' }}>
    <img src={check_name_input_icon} alt="check_name_input_icon" style={{ width: 32, height: 32 }} />
  </Box>;
const checkLinkIcon =
  <Box display='flex' alignItems='center' pl='2' pr='3' mr='1' justifyContent='center' style={{ borderRight: '1px solid #86909C' }}>
    <img src={check_link_input_icon} alt="check_link_input_icon" style={{ width: 32, height: 32 }} />
  </Box>;
const FormV2 = () => {
  return (
    <Container>
      <SectionV2>
        <p className='section-label'>
          Check
        </p>
        <Form.Item label="Line" name="line" style={{ margin: 0, width: '100%' }}>
          <Input style={{ height: '3.5rem' }} placeholder="* There Will Be A $25 Service Charge On All Returned Checks" />
        </Form.Item>
      </SectionV2>
      <SectionV2>
        <p className='section-label'>
          Others
        </p>
        <Form.List name="others">
          {(fields, { add, remove }) => (
            <OtherPaymentGroupContainer>
              <OtherPaymentGroup>
                {fields.map(({ key, name, ...restField }, index) => {
                  return (
                    <OtherPaymentItem key={key}>
                      <button type='button' className="btn-delete" onClick={() => remove(index)}>
                        <img src={close_icon} alt="close_icon" style={{ width: 24, height: 24 }} />
                      </button>
                      <OtherPaymentItemImage>
                        <Form.Item {...restField} name={[name, 'base64']} noStyle>
                          <UploadQRImage />
                        </Form.Item>
                      </OtherPaymentItemImage>
                      <OtherPaymentItemContent>
                        <Form.Item {...restField} name={[name, 'type']} noStyle>
                          <OtherPaymentTypes />
                        </Form.Item>
                        <Box display='flex' style={{ gap: 16 }}>
                          <Form.Item {...restField} name={[name, 'name']} noStyle>
                            <Input style={{ height: '3.5rem' }} prefix={checkNameIcon} placeholder='Name' />
                          </Form.Item>
                          <Form.Item {...restField} name={[name, 'link']} noStyle>
                            <Input style={{ height: '3.5rem' }} prefix={checkLinkIcon} placeholder='Enter link' />
                          </Form.Item>
                        </Box>
                      </OtherPaymentItemContent>
                    </OtherPaymentItem>
                  );
                })}
              </OtherPaymentGroup>
              <Box mt="3">
                <Button
                  ntype="LIGHT_BLUE"
                  icon="addPlus"
                  onClick={() => add()}
                >
                  Add New Payment Type
                </Button>
              </Box>
            </OtherPaymentGroupContainer>
          )}
        </Form.List>
      </SectionV2>
      <ActionButtons />
    </Container>
  );
};

export default FormV2;
type OtherPaymentTypesProps = {
  value?: string;
  onChange?: (value: string) => void;
};
const checkOpts = [
  { value: 'CHECK', label: 'Check' },
  { value: 'VENMO', label: 'Venmo' },
  { value: 'CASH_APPS', label: 'Cash Apps' },
  { value: 'ZELLE', label: 'Zelle' },
  { value: 'PAYPAL', label: 'Paypal' },
];
const OtherPaymentTypes = ({ value, onChange = () => undefined }: OtherPaymentTypesProps) => {
  return (
    <Box display='flex' style={{ gap: 16 }}>
      {checkOpts.map((opt) => (
        <MyRadio
          key={opt.value}
          value={value === opt.value}
          label={opt.label}
          onChange={() => onChange(opt.value)}
        />
      ))}
    </Box>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const OtherPaymentGroupContainer = styled.div`
display: flex;
flex-direction: column;
gap: 16px;
align-self: stretch;
`;

const OtherPaymentGroup = styled.div`
display: flex;
flex-direction: column;
gap: 16px;
align-self: stretch;
`;

const OtherPaymentItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 5px;
  border: 1px solid #CCD4DC;
  position: relative;
  .btn-delete {
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    z-index: 2;
    right: -20px;
    top: -19px;
    border-radius: 100px;
    background: #CBD5E1;
  }
`;

const OtherPaymentItemImage = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const OtherPaymentItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  align-self: stretch;
  background: #FFF;
  padding: 24px 16px;
`;