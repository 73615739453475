import fetch from 'services/request';

import { IApiGetAppointments, IApiGetTableAppointment, IBodyApiMakeAppointment, IBodyApiMakeLockTime, IBodyApiUpdateAppointmentDetail, IBodyApiUpdateAppointmentWithDrop } from './types/api';
import { PROLONGED_TIME_STATUS } from './constants';
import queryString from 'query-string';
import { IBodyApiUpdateStatusApt } from 'services/response';
import { IBodyApiQuickBooking } from './types/appointment';

export const getAppointments = async (params: IApiGetAppointments) => {
  return fetch({
    method: 'get',
    url: '/api/v1/appointment/all',
    params,
  });
};

export const getTableAppointments = async (params: IApiGetTableAppointment) => {
  const paramsPasser = queryString.stringify(
    { ...params },
    { arrayFormat: 'bracket' }
  );
  return fetch({
    method: 'get',
    url: `/api/v1/appointment/list-table?${paramsPasser.replaceAll('[]', '')}`,
  });
};

const getLockBreakTimes = (params: IApiGetAppointments) => {
  return fetch({
    method: 'get',
    url: '/api/v1/appointment/lock-time',
    params
  });
};
const removeLockBreakTimes = (offDayStaffId: string) => {
  return fetch({
    method: 'delete',
    url: `/api/v1/appointment/lock-time/${offDayStaffId}`
  });
};

const makeNewAppointment = (body: IBodyApiMakeAppointment) => {
  return fetch({
    method: 'post',
    url: '/api/v1/appointment/booking',
    body,
  });
};

const makeLockTime = (body: IBodyApiMakeLockTime) => {
  return fetch({
    method: 'post',
    url: '/api/v1/appointment/lock-time',
    body,
  });
};

const getStaffTimeSlot = (staffId: string | null | undefined, date: string) => {
  return fetch({
    method: 'get',
    url: '/api/v1/appointment/available-time-slot-staff',
    params: { staffId: staffId || '', date },
  });
};

const getStaffTimeSlotByDate = (date: string) => {
  return fetch({
    method: 'get',
    url: '/api/v1/appointment/available-time-slot-staff',
    params: { date },
  });
};

const addMoreTimeClockTime = (clockId: string) => {
  return fetch({
    method: 'post',
    url: 'api/v1/appointment/lock-time/prolonged-time',
    body: { clockId },
  });
};

const addMoreTimeAppointment = (body: { status: PROLONGED_TIME_STATUS, appointmentId: string }) => {
  return fetch({
    method: 'post',
    url: 'api/v1/appointment/prolonged-time',
    body,
  });
};

const updateAppointmentWithDrop = (body: IBodyApiUpdateAppointmentWithDrop) => {
  return fetch({
    method: 'post',
    url: 'api/v1/appointment/update-with-drop',
    body,
  });

};

const deleteAppointment = (appointmentId: string) => {
  return fetch({
    method: 'delete',
    url: 'api/v1/appointment/' + appointmentId,
  });
};

const updateDetailAppointment = (body: IBodyApiUpdateAppointmentDetail) => {
  return fetch({
    method: 'put',
    url: '/api/v1/appointment/booking',
    body,
  });
};
const updateStatusApt = (body: IBodyApiUpdateStatusApt) => {
  return fetch({
    method: 'post',
    url: '/api/v1/appointment/update-status',
    body,
    autoToast: true,
  });
};

const getListStaffAvailableBooking = (date: string) => {
  return fetch({
    method: 'get',
    url: '/api/v1/staff/available-booking',
    params: { date }
  });
};

const getListStaffsOff = (startDate: string, endDate: string) => {
  return fetch({
    method: 'get',
    url: '/api/v1/staff/staff-off-booking',
    params: { startDate, endDate },
  });
};


const makeQuickBooking = (body: IBodyApiQuickBooking) => {
  return fetch({
    method: 'post',
    url: '/api/v1/appointment/quick-booking',
    body,
  });
};

const getCategoriesByStaff = (staffId: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/category/get-all-booking',
    params: {
      staffId,
    }
  });
};

const addAnybody = () => fetch({
  method: 'post',
  url: '/api/v1/staff/add-any-body',
});
const removeAnybody = (staffId: string) => fetch({
  method: 'delete',
  url: `/api/v1/staff/delete/${staffId}`,
});

const appointmentApis = {
  getAppointments,
  getTableAppointments,
  getLockBreakTimes,
  removeLockBreakTimes,
  makeNewAppointment,
  makeLockTime,
  getStaffTimeSlot,
  getStaffTimeSlotByDate,
  addMoreTimeAppointment,
  addMoreTimeClockTime,
  updateAppointmentWithDrop,
  deleteAppointment,
  updateDetailAppointment,
  updateStatusApt,
  getListStaffAvailableBooking,
  getListStaffsOff,
  makeQuickBooking,
  getCategoriesByStaff,
  addAnybody,
  removeAnybody,
};

export default appointmentApis;
