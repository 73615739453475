import Text from 'components/Text';
import React from 'react';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { formatPhone } from 'utils/formatPhone';
import spellingDollar from 'utils/spellingDollar';

type Props = {
  checkNum?: string;
  date?: string;
  name?: string;
  amount?: number;
  signature?: string;
  checkBank?: {
    bankName: string | null;
    bankAddress: string | null;
    bankWebsite: string | null;
    bankLogo: string | null;
  } | null;
  forCheckNote?: string;
  routerNumber?: string;
  checkingAccount?: string;
  manual?: boolean;

};
const CheckUI = ({ signature, checkNum, date, name, amount, checkBank, forCheckNote, manual, routerNumber, checkingAccount }: Props) => {
  const shopInfo = shopSelectors.data.shopInfo();
  const printCheckSetting = shopSelectors.data.printCheckSetting();

  return (
    <Container className='check-border-black-4'>
      <div className="check-container check-border-black-1">
        <CheckSalonItem>
          <div className="salon-info">
            <p className="salon-name">{shopInfo?.shopName || '--'}</p>
            <p className="salon-address">{
              shopInfo?.address || '--'
            }</p>
            <p className="salon-address">{
              [shopInfo?.city, shopInfo?.state, shopInfo?.zip].filter(Boolean).join(', ') || '--'
            }</p>
            <p className="salon-address">{formatPhone(shopInfo?.phone || '')}</p>
          </div>
          <div className="check-num">{checkNum || '--'}</div>
          <div className="date-container">
            <span style={{ fontSize: 14 }}>Date</span>
            <div className="date-label"><span>{date || '--'}</span></div>
          </div>
        </CheckSalonItem>
        <CheckItemPayInfo>
          <div className="amount-container">
            <div className="pay-to-order-container" style={{ position: 'relative' }}>
              <div style={{ width: '200px', position: 'absolute' }}>
                <div className="pay-to-order-label">PAY TO THE</div>
                <div className="pay-to-order-label">ORDER OFF</div>
              </div>
              <div className="staff-name-container" style={{ paddingLeft: '208px' }}><span>{name || '--'}</span></div>
            </div>
            <div className="amount-box">
              <div className="symbol">$</div>
              <div className="amount-box-container"><span>{formatCurrency(amount || 0)?.replace('$', '')}</span></div>
            </div>
          </div>
          <div className="dollar-container">
            <div className="amount-container">
              <span>{spellingDollar(amount || 0)}  and 0/100 ************************************************************************************************************************************************************************************************************************</span>
            </div>
            <div className="dollar-text"><span>Dollars</span></div>
          </div>
        </CheckItemPayInfo>
        <MemoItem>
          {checkBank && <div className="check-bank-container">
            {checkBank.bankLogo ? <img src={checkBank.bankLogo || ''} alt="check-bank" style={{ width: 44, height: 44 }} /> : <div style={{ width: 44, height: 44, border: '1px solid black' }} />}
            <div>
              <p>{checkBank.bankName}</p>
              <p>{checkBank.bankAddress}</p>
              <p>{checkBank.bankWebsite}</p>
            </div>
          </div>}
          <div className="top-container">
            <div className="memo-label">For</div>
            <div className="rent-text"><span>{manual ? forCheckNote : (forCheckNote || printCheckSetting?.forCheckNote)}</span></div>
            <div style={{ flex: 1 }}></div>
            <div className="signature-container">
              {signature ? <img src={signature} alt="signature" className="signature-image" /> : <div className='signature-image' />}
            </div>
          </div>
          <Text style={{ marginTop: '28px' }} />
          <div className="bottom-container">
            <div className="group">
              <IconStartNumber /><span>{(manual ? routerNumber : printCheckSetting?.routerNumber) || '---------'}</span><IconEnd4Number />
            </div>
            <div className="group">
              <IconStartNumber /><span>{(manual ? checkingAccount : printCheckSetting?.checkingAccount) || '---------'}</span><IconStartNumber />
            </div>
            <div className="group">
              <span>{checkNum}</span><IconEnd4Number />
            </div>
          </div>
        </MemoItem>
      </div>
    </Container>
  );
};

export default CheckUI;
const IconStartNumber = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19" fill="none">
    <path d="M2.76712 3.22266H0.63214C0.286251 3.22266 0 3.50891 0 3.8548V14.8994C0 15.2453 0.286251 15.5315 0.63214 15.5315H2.76712C3.11301 15.5315 3.39926 15.2453 3.39926 14.8994V3.8548C3.38733 3.50891 3.11301 3.22266 2.76712 3.22266Z" fill="#1D2129" />
    <path d="M10.9969 0.742188H7.28754C6.94165 0.742188 6.6554 1.02844 6.6554 1.37433V5.08367C6.6554 5.42956 6.94165 5.71581 7.28754 5.71581H10.9969C11.3428 5.71581 11.629 5.42956 11.629 5.08367V1.37433C11.629 1.01651 11.3428 0.742188 10.9969 0.742188Z" fill="#1D2129" />
    <path d="M10.9969 13.04H7.28754C6.94165 13.04 6.6554 13.3263 6.6554 13.6722V17.3815C6.6554 17.7274 6.94165 18.0137 7.28754 18.0137H10.9969C11.3428 18.0137 11.629 17.7274 11.629 17.3815V13.6722C11.629 13.3263 11.3428 13.04 10.9969 13.04Z" fill="#1D2129" />
  </svg>
);

const IconEnd4Number = () => (
  <svg style={{ marginLeft: '2px' }} xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 22 21" fill="none">
    <path d="M1.8487 5.22168H0.632104C0.286215 5.22168 0 5.50793 0 5.85382V17.2204C0 17.5663 0.286215 17.8526 0.632104 17.8526H1.8487C2.19459 17.8526 2.48081 17.5663 2.48081 17.2204V5.85382C2.48081 5.50793 2.19459 5.22168 1.8487 5.22168Z" fill="#1D2129" />
    <path d="M6.79854 5.22168H5.58194C5.23606 5.22168 4.94977 5.50793 4.94977 5.85382V17.2204C4.94977 17.5663 5.23606 17.8526 5.58194 17.8526H6.79854C7.14443 17.8526 7.43065 17.5663 7.43065 17.2204V5.85382C7.43065 5.50793 7.14443 5.22168 6.79854 5.22168Z" fill="#1D2129" />
    <path d="M15.565 2.89648H10.5316C10.1858 2.89648 9.89954 3.18274 9.89954 3.52862V10.2675C9.89954 10.6134 10.1858 10.8996 10.5316 10.8996H15.565C15.9108 10.8996 16.1971 10.6134 16.1971 10.2675V3.52862C16.1971 3.18274 15.9108 2.89648 15.565 2.89648Z" fill="#1D2129" />
  </svg>
);

const Container = styled.div`
display: flex;
padding: 8px;
flex-direction: column;
align-items: flex-start;
flex-shrink: 0;
background: white;
.check-container {
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  background: white;
}
`;

const MemoItem = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 4px;
align-self: stretch;
position: relative;
.check-bank-container {
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 8px;
  p {
    color: black;
    font-family: Poppins;
    font-size: 10px;
    line-height: 13px;
    font-style: normal;
    font-weight: 500;
  }
}
.bottom-container {
  display: flex;
  align-items: flex-end;
  align-self: stretch;
  gap: 8px;
  zoom: 0.7;
  .group {
    display: flex;
    align-items: center;
    gap: 4px;
  }
   span {
    font-family: ocr-a-regular;
    color: black;
    height: 18px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    opacity: 0.8;
    letter-spacing: 1px;
  }
}
.top-container {
  display: flex;
  align-items: flex-end;
  gap: 14px;
  align-self: stretch;
  .memo-label {
    color: black;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .rent-text {
    display: flex;
    width: 193px;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid black;
    span {
      color: black;
      font-family: Salsa;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .signature-container {
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid black;
    min-width: 40%;
    img.signature-image {
      width: auto;
      height: 85px;
    }
    div.signature-image {
      width: 100px;
      height: 85px;
    }
  }
}
`;

const CheckItemPayInfo = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 12px;
align-self: stretch;
margin-top: 34px;
.amount-container {
  display: flex;
  align-items: flex-end;
  gap: 24px;
  align-self: stretch;
  .pay-to-order-container {
    display: flex;
    align-items: flex-end;
    gap: 16px;
    flex: 1 0 0;
    .pay-to-order-label {
      color: black;
      font-family: Poppins;
      font-size: 10px;
      width: 100px;
      font-style: normal;
      font-weight: 500;
      line-height: 13px;
    }

    .staff-name-container {
      display: flex;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      border-bottom: 1px solid black;
      span {
        color: black;
        font-family: Salsa;
        font-size: 16px;
        font-style: normal;
        line-height: normal;
      }
    }
  }

  .amount-box {
    display: flex;
    align-items: center;
    gap: 8px;
    .symbol {
      color: black;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .amount-box-container {
      display: flex;
      padding: 0px 36px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border: 1px solid black;
      span {
        color: #1D2129;
        text-align: center;
        font-family: Salsa;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}


.dollar-container {
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  .amount-container {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-bottom: 1px solid black;
    overflow: hidden;
    white-space: nowrap;
    span {
      color: black;
      font-family: Salsa;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .dollar-text {
    display: flex;
    align-items: flex-end;
    gap: 16px;
    align-self: stretch;
    span {
      color: black;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      font-family: Salsa;
    }
  }
}
`;

const CheckSalonItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  position: relative;
  margin-bottom: 0px;
  margin-top: 15px;
  .salon-info {
    display: flex;
    width: 200px;
    flex-direction: column;
    align-items: flex-start;
    p.salon-name {
      align-self: stretch;
      color: black;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }

    p.salon-address {
      color: black;
      font-family: Poppins;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 13px;
    }
  }

  .check-num {
    color: black;
    text-align: right;
    font-family: Poppins;
    line-height: normal;
    font-size: 16px;
    font-weight: 500;
    font-family: Salsa;
    transform: translateY(-12px);
  }

  .date-container {
    display: flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    right: 79.13px;
    bottom: 12px;
    z-index: 2;
    span {
      color: black;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    div.date-label {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-bottom: 1px solid #777D7C;

      span {
        font-size: 16px;
        font-weight: 500;
        font-family: Salsa;
      }
    }
  }
`;