import { get } from 'lodash';
import uiSelector from 'services/UI/selectors';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { PATH_LOADING } from './constants';
import { IDEMOModelResDataItem } from './types/demoData';
import { IDashboardTimeClockReport, IPrintSalaryStaffDetailOneDay, IPrintSalaryStaffDetailRangeDate, IPrintSalarySummaryStaffOneDay, IPrintSalarySummaryStaffRangeDate, IPrintSummarySalon, IPrintSummaryServiceCategory, ISalarySalonInfo, ISalaryStaffDetail, ISalaryStaffInfo, ISummaryDashboardSalaryStaff, ISummarySalon, ISummaryServiceCategory, ISummaryTicket, ITimeClockReportHistory, ITopRankingService } from './types/reportStaff';
/**
 * please change reducer name
 */
// @ts-ignore
type MyState = RootState['report']; // ex: RootState['booking']
// @ts-ignore
const getCurrentState = (state: RootState): MyState => state['report']; // ex: state['booking']

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const getDemoData = () => selector('demoData') as IDEMOModelResDataItem[];

const getDemoDataLoading = () => uiSelector.getLoading(PATH_LOADING.getData) as boolean;

const getPrintSummarySalon = () => selector('dataPrintSummarySalon') as IPrintSummarySalon;

const getPrintSummarySalonLoading = () => uiSelector.getLoading(PATH_LOADING.getPrintSummarySalon) as boolean;

const getSummarySalon = () => selector('dataSummarySalon') as ISummarySalon;

const getSummarySalonLoading = () => uiSelector.getLoading(PATH_LOADING.getSummarySalon) as boolean;

const getSummaryServiceCategory = () => selector('dataSummaryServiceCategory') as ISummaryServiceCategory;

const getSummaryServiceCategoryLoading = () => uiSelector.getLoading(PATH_LOADING.getSummaryServiceCategory) as boolean;

const getSummaryTicket = () => selector('dataSummaryTicket') as ISummaryTicket;

const getSummaryTicketLoading = () => uiSelector.getLoading(PATH_LOADING.getSummaryTicket) as boolean;

const getTopRankingService = () => selector('dataTopRankingService') as ITopRankingService[];

const getTopRankingServiceLoading = () => uiSelector.getLoading(PATH_LOADING.getTopRankingService) as boolean;

const getSummarySalaryStaff = () => selector('dataTableSummarySalaryStaff') as ISalaryStaffInfo[];

const getSummarySalaryStaffLoading = () => uiSelector.getLoading(PATH_LOADING.getSummarySalaryStaff) as boolean;

const getSummarySalarySalon = () => selector('dataTableSummarySalarySalon') as ISalarySalonInfo[];

const getSummarySalarySalonLoading = () => uiSelector.getLoading(PATH_LOADING.getSummarySalarySalon) as boolean;

const getSummaryDashboardSalaryStaff = () => selector('dataSummaryDashboardSalaryStaff') as ISummaryDashboardSalaryStaff;

const getSummaryDashboardSalaryStaffLoading = () => uiSelector.getLoading(PATH_LOADING.getSummarySalaryStaff) as boolean;

const getViewType = () => selector('viewType') as MyState['viewType'];

const getParams = () => selector('params') as MyState['params'];

const getFilterTypeSalaryStaff = () => selector('filterTypeSalaryStaff') as MyState['filterTypeSalaryStaff'];

const getSalaryStaffDetail = () => selector('salaryStaffDetail') as ISalaryStaffDetail;

const getPrintSummaryServiceCategory = () => selector('dataPrintSummaryServiceCategory') as IPrintSummaryServiceCategory;

const getPrintSummaryServiceCategoryLoading = () => uiSelector.getLoading(PATH_LOADING.getPrintSummaryServiceCategory) as boolean;

const getDashboardTimeClockReport = () => selector('dataDashboardTimeClockReport') as IDashboardTimeClockReport;

const getDashboardTimeClockReportLoading = () => uiSelector.getLoading(PATH_LOADING.getDashboardTimeClockReport) as boolean;

const getTimeClockReportHistory = () => selector('dataTimeClockReportHistory') as ITimeClockReportHistory[];

const getTimeClockReportHistoryLoading = () => uiSelector.getLoading(PATH_LOADING.getTimeClockReportHistory) as boolean;

const getPrintSalaryStaffDetailOneDay = () => selector('dataPrintSalaryStaffDetailOneDay') as IPrintSalaryStaffDetailOneDay;

const getPrintSalaryStaffDetailOneDayLoading = () => uiSelector.getLoading(PATH_LOADING.getPrintSalaryStaffDetailOneDay) as boolean;

const getPrintSalaryStaffDetailRangeDate = () => selector('dataPrintSalaryStaffDetailRangeDate') as IPrintSalaryStaffDetailRangeDate;

const getPrintSalaryStaffDetailRangeDateLoading = () => uiSelector.getLoading(PATH_LOADING.getPrintSalaryStaffDetailRangeDate) as boolean;

const getPrintSalarySummaryStaffOneDay = () => selector('dataPrintSalarySummaryStaffOneDay') as IPrintSalarySummaryStaffOneDay;

const getPrintSalarySummaryStaffOneDayLoading = () => uiSelector.getLoading(PATH_LOADING.getPrintSalarySummaryStaffOneDay) as boolean;

const getPrintSalarySummaryStaffRangeDate = () => selector('dataPrintSalarySummaryStaffRangeDate') as IPrintSalarySummaryStaffRangeDate;

const getPrintSalarySummaryStaffRangeDateLoading = () => uiSelector.getLoading(PATH_LOADING.getPrintSalarySummaryStaffRangeDate) as boolean;

const getReviewReport = () => selector('review') as MyState['review'];

const getReviewReportParams = () => selector('review.params') as MyState['review']['params'];

const getPureReviewReportParams = (root: RootState) => getCurrentState(root)?.review?.params as MyState['review']['params'];

const getReviewReportSummary = () => selector('review.summary') as MyState['review']['summary'];

const getDataPrintSummaryReportPaymentTypes = () => selector('dataPrintSummaryReportPaymentTypes') as MyState['dataPrintSummaryReportPaymentTypes'];

const getPrintCheckPreviewData = () => selector('printCheckPreviewData') as MyState['printCheckPreviewData'];

const getPrintCheckAll = () => selector('printCheckAll') as MyState['printCheckAll'];

const getCheckNum = () => selector('checkNum') as MyState['checkNum'];

const getPrintTimeClockRange = () => selector('dataPrintTimeClockRange') as MyState['dataPrintTimeClockRange'];

const reportSelectors = {
  getDemoData,
  getDemoDataLoading,
  getPrintSummarySalon,
  getPrintSummarySalonLoading,
  getSummarySalon,
  getSummarySalonLoading,
  getSummaryServiceCategory,
  getSummaryServiceCategoryLoading,
  getSummaryTicket,
  getSummaryTicketLoading,
  getTopRankingService,
  getTopRankingServiceLoading,
  getSummarySalaryStaff,
  getSummarySalaryStaffLoading,
  getSummarySalarySalon,
  getSummarySalarySalonLoading,
  getSummaryDashboardSalaryStaff,
  getSummaryDashboardSalaryStaffLoading,
  getViewType,
  getParams,
  getFilterTypeSalaryStaff,
  getSalaryStaffDetail,
  getPrintSummaryServiceCategory,
  getPrintSummaryServiceCategoryLoading,
  getDashboardTimeClockReport,
  getDashboardTimeClockReportLoading,
  getTimeClockReportHistory,
  getTimeClockReportHistoryLoading,
  getPrintSalaryStaffDetailOneDay,
  getPrintSalaryStaffDetailOneDayLoading,
  getPrintSalaryStaffDetailRangeDate,
  getPrintSalaryStaffDetailRangeDateLoading,
  getPrintSalarySummaryStaffOneDay,
  getPrintSalarySummaryStaffOneDayLoading,
  getPrintSalarySummaryStaffRangeDate,
  getPrintSalarySummaryStaffRangeDateLoading,
  getReviewReport,
  getPureReviewReportParams,
  getReviewReportParams,
  getReviewReportSummary,
  getDataPrintSummaryReportPaymentTypes,
  getPrintCheckPreviewData,
  getPrintCheckAll,
  getCheckNum,
  getPrintTimeClockRange,
};
export default reportSelectors;
