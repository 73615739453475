import fetch from 'services/request';
import { AxiosResponse } from 'axios';
import { IStaffPayload } from 'features/checkIn/services/types/demoData';
import {
  ISortStaffV2,
  IStaffDayOffParams,
  IStaffsParams,
  IStaffUpdatePinBodyType,
  IUpdateStaffAvatar,
} from './types/api';
import { cleanParams } from 'utils/updateParamsFilter';
import { ISalaryConfig, IStaffOffDay, IStaffScheduler } from './types/staff';

const baseURL = 'api/v1';

export const getDetailsStaff = (
  staffId: string
): Promise<AxiosResponse<IStaffPayload>> => {
  return fetch({
    method: 'get',
    url: `${baseURL}/staff/get-detail/${staffId}`,
    params: {},
  });
};

export const createOrUpdateStaff = (body: IStaffPayload) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/staff/create-or-update`,
    body: body,
  });
};

export const getStaffs = (params: IStaffsParams) => {
  const _params = cleanParams(params);
  return fetch({
    method: 'get',
    url: `${baseURL}/staff/get-all-by-shop`,
    params: _params,
  });
};

export const staffUpdatePin = (
  body: IStaffUpdatePinBodyType
): Promise<AxiosResponse<IStaffPayload>> => {
  return fetch({
    method: 'put',
    url: `${baseURL}/staff/update-pin`,
    body: body,
  });
};
export const staffUpdateSalary = (
  body: ISalaryConfig
): Promise<AxiosResponse<ISalaryConfig>> => {
  return fetch({
    method: 'put',
    url: `${baseURL}/staff/update-salary-config`,
    body: body,
  });
};
export const staffScheduler = (
  staffId: string
): Promise<AxiosResponse<IStaffScheduler>> => {
  return fetch({
    method: 'get',
    url: `${baseURL}/staff/scheduler/${staffId}`,
  });
};
export const staffUpdateMappingService = (
  body: IStaffUpdatePinBodyType
): Promise<AxiosResponse<any>> => {
  return fetch({
    method: 'put',
    url: `${baseURL}/staff/update-mapping-service`,
    body,
  });
};
export const getStaffDayOff = (
  params: IStaffDayOffParams
): Promise<AxiosResponse<IStaffOffDay>> => {
  return fetch({
    method: 'get',
    url: `${baseURL}/off-day/staff`,
    params,
  });
};
export const createStaffDayOff = (body: IStaffDayOffParams) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/off-day/staff`,
    body,
  });
};
export const deleteStaffDayOff = (id: string) => {
  return fetch({
    method: 'delete',
    url: `${baseURL}/off-day/staff/${id}`,
  });
};

export const updateStaffSchedule = (
  body: IStaffScheduler
): Promise<AxiosResponse<any>> => {
  return fetch({
    method: 'put',
    url: `${baseURL}/staff/scheduler`,
    body: body,
  });
};

export const updateStaffAvatar = (
  body: IUpdateStaffAvatar
): Promise<AxiosResponse<any>> => {
  return fetch({
    method: 'put',
    url: `${baseURL}/staff/update-avatar`,
    body: body,
  });
};

const sortStaff = (ids: string[]) => {
  return fetch({
    method: 'post',
    url: 'api/v1/staff/sorts-booking',
    body: ids
  });
};

const deleteStaff = (id: string) => {
  return fetch({
    method: 'delete',
    url: `api/v1/staff/delete/${id}`,
  });
};

const sortStaffV2 = (body: ISortStaffV2[]) => {
  return fetch({
    method: 'post',
    url: '/api/v1/staff/config-booking',
    body,
  });
};

const enableBookingOnline = (body: { staffId: string, enableBookingOnline: boolean }) => {
  return fetch({
    method: 'post',
    url: '/api/v1/staff/enable-booking-online',
    body,
  });
};

const settingApis = {
  deleteStaff,
  sortStaff,
  getStaffs,
  getDetailsStaff,
  createOrUpdateStaff,
  staffUpdatePin,
  staffUpdateSalary,
  staffScheduler,
  staffUpdateMappingService,
  getStaffDayOff,
  createStaffDayOff,
  deleteStaffDayOff,
  updateStaffSchedule,
  updateStaffAvatar,
  sortStaffV2,
  enableBookingOnline,
};

export default settingApis;
