import { AutoComplete, Input, InputRef, Row } from 'antd';
import { SearchIcon } from 'assets/Icons/AppointmentIcons';
import { AxiosResponse } from 'axios';
import Spin from 'components/Spin';
import Text from 'components/Text';
import { debounce } from 'lodash';
import { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { getCustomersFilter } from 'services/shop/apis';
import shopSelectors from 'services/shop/selectors';
import { ICustomerItemData } from 'services/shop/types/customer';
import styled from 'styled-components';
import { formatPhone } from 'utils/formatPhone';
import ButtonAddCustomer, { useButtonAddCustomerRef } from 'widgets/Customer/AddCustomer';
import NotFoundCustomerBox from 'widgets/Customer/NotFoundCustomerBox';
export const unknownCustomerId = '_unknownCustomerId';
export const unknownCustomerItem: ICustomerItemData = {
  id: unknownCustomerId,
  email: '',
  imageAvatarUrl: '',
  name: 'Unknown Customer',
  phone: '0000000000',
  numberVisit: 0,
  point: 0,
};
export const maskPhone = (val: string) => {
  if (!val) return '';
  return '(***) ***-' + val.slice(val.length - 4, val.length);
};
type ICusOption = {
  value: string;
  name: string;
  keywords: string[];
  label: JSX.Element;
};
type CustomerSearchInputProps = {
  v2NewApt?: boolean;
  value?: ICustomerItemData;
  onChange?: (data: ICustomerItemData) => void;
};

type Ref = {
  open: () => void;
};
export const useCustomerSearchInputRef = () => useRef<Ref>(null);

const CustomerSearchInput = forwardRef<Ref, CustomerSearchInputProps>(({ v2NewApt, onChange = () => undefined, value }, ref) => {
  const inputRef = useRef<InputRef>(null);
  const [open, setOpen] = useState(false);
  const buttonAddRef = useButtonAddCustomerRef();
  const customerStore = shopSelectors.data.customers();
  const [customersFilter, setCustomersFilter] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const initialOptions = useMemo(() => {
    const data: ICusOption[] = customerStore.map(o => {
      const point = +o?.point || 0;
      const phone = maskPhone(o?.phone || '');
      return ({
        value: o.id,
        name: o.name || '',
        keywords: [o.name?.toLocaleLowerCase(), phone],
        label: renderLabel(o.name || '', point > 1 ? `${point} points` : point > 0 ? `${point} point` : '', phone),
      });
    });
    const unknownCus: ICusOption = {
      value: unknownCustomerId,
      name: unknownCustomerItem.name,
      keywords: [],
      label: renderLabel(unknownCustomerItem.name, '', formatPhone('00000000000') || ''),
    };
    return [unknownCus].concat(data);
  }, [customerStore]);

  const [searchText, setSearchText] = useState<string>('');

  const optionsFilter = useMemo(() => customersFilter.map(o => {
    const point = +o?.point || 0;
    const phone = maskPhone(o?.phone || '');
    return ({
      value: o.id,
      name: o.name,
      label: renderLabel(o.name, point > 1 ? `${point} points` : point > 0 ? `${point} point` : '', phone),
    });
  }), [customersFilter]);

  const options = useMemo(() => {
    if (!searchText) return initialOptions;
    return optionsFilter;
  }, [initialOptions, searchText, optionsFilter]);

  const handleSwitchCustomer = (data: string) => {
    inputRef.current?.blur();
    setSearchText('');
    if (data === unknownCustomerId) {
      onChange(unknownCustomerItem);
      return;
    }
    const result = (searchText ? customersFilter : customerStore)?.find((item) => item.id === data);
    if (!result) return;
    onChange(result);
  };

  const getList = useCallback(debounce(async (search: string) => {
    try {
      const res: AxiosResponse<{ data: { content: ICustomerItemData[] } }> = await getCustomersFilter(search);
      if (res.data.data.content) {
        setCustomersFilter(res.data.data.content);
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  }, 500), []);

  const onOpenAddNew = ({ phone, name }: { phone?: string, name?: string }) => {
    buttonAddRef.current?.open({ phone: phone ? formatPhone(phone) : '', name });
    setSearchText('');
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
      inputRef.current?.focus();
    },
  }));

  return (
    <Container wrap={false} align={'middle'} style={{ gap: '4px' }} className='CustomerSearchInput'>
      <div className="container-input">
        <AutoComplete
          style={{ width: '100%' }}
          options={options}
          dropdownRender={(menu) => (<>
            {searchText && loading ? <Row align={'middle'} justify={'center'}><Text py={1.5}><Spin /></Text></Row> : menu}
          </>)}
          notFoundContent={<NotFoundCustomerBox searchText={searchText} onOpenAddNew={onOpenAddNew} />}
          onSelect={handleSwitchCustomer}
          value={searchText}
          open={open}
          onDropdownVisibleChange={setOpen}
          maxLength={11}
          onChange={text => {
            if (text) setLoading(true);
            setSearchText(text);
            getList(text);
          }}
        >
          <Input
            ref={inputRef}
            prefix={!v2NewApt ? <SearchIcon /> : undefined}
            suffix={v2NewApt ? <SearchIcon /> : undefined}
            placeholder={`${value?.name || 'Walk-In Customer'} - ${(value?.id !== unknownCustomerId ? maskPhone(value?.phone || '') : formatPhone('00000000000')) || formatPhone('00000000000')}`}
          />
        </AutoComplete>
      </div>
      <ButtonAddCustomer ref={buttonAddRef} model={v2NewApt ? 'new-apt-v2' : 'icon'} onChooseCustomer={onChange} />
    </Container>
  );
});
CustomerSearchInput.displayName = 'CustomerSearchInput';
export default memo(CustomerSearchInput);

const Container = styled(Row)`

  .ant-select-selection-placeholder, input.ant-input {
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .container-input {
    display: flex;
    flex: 1;
    align-items: center;
    position: relative;
    input.ant-input::placeholder {
      color: #1D2129;
    }
  }
`;

const renderLabel = (name: string, point: string, tel: string) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
    >
      <Text variant="CONTENT_2" color="text_3">
        {name}
      </Text>
      <Text variant="BODY_1" color="text_2">
        {point}
      </Text>
    </div>
    <div>
      <Text variant="BODY_1" color="text_3">
        {tel}
      </Text>
    </div>
  </div>
);
