import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import storage from 'utils/sessionStorage';
import PrintBody from './components/PrintBody';
import PrintFooter from './components/PrintFooter';
import PrintHeader from './components/PrintHeader';
import PrintPageStyled from './styles';
import { useAppDispatch } from 'store/hooks';
import paymentActions from 'features/payment/services/actions';

type IPrintPageProps = any;
const PrintPage: React.FC<IPrintPageProps> = () => {
  const dispatch = useAppDispatch();
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const divRef = useRef<HTMLDivElement>(null);
  const doPrint = useReactToPrint({
    content: () => divRef.current,
  });

  const onBack = () => navigate('/store/' + storage.shop_id.get());
  useEffect(() => {
    dispatch(paymentActions.getPrintBillDetail.fetch(id));
  }, []);

  return (
    <PrintPageStyled>
      <PrintHeader />
      <PrintBody owner billRef={divRef} />
      <PrintFooter onPrint={doPrint} onBack={onBack} />
    </PrintPageStyled>
  );
};

export default PrintPage;
