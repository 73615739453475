import numeral from 'numeral';

export const formatCurrency = (
  value: string | number | null | undefined,
  showMinus?: boolean,
  showMoney = true
) => {
  const formatString = showMoney ? '$0,0.00' : '0,0.00';

  if (showMinus && Number(value) < 0) {
    return `- ${numeral(value || 0).format(formatString)}`;
  }

  return numeral(value || 0).format(formatString);
};


export const formatNumber = (value: string | number | null | undefined,) => {
  return numeral(value || 0).format('0,0');
};

export const parseFee = (value: number) => {
  const str = numeral(value || 0).format('0.00');
  return value ? parseFloat(str) : 0;
};