import { Avatar } from 'antd';
import clsx from 'clsx';
import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

const Container = styled.div`
display: flex;
padding: 8px 0px;
align-items: center;
gap: 24px;
align-self: stretch;
.ant-avatar .ant-avatar-string {
  top: 50%;
  transform: translate(-50%, -50%) !important;
  font-size: 24px;
  display: flex;
}

&.nbt {
  border-top: none;
}

.staff-upload {
display: flex;
flex-direction: column;
gap: 8px;
  p {
    color: #1D2129;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    &.note-text {
      color: #64748B;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
`;

const UploadPhotoContainer = styled.div`
display: flex;
align-items: center;
gap: 8px;

.btn-upload {
  display: flex;
  padding: 12px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid #01959C;
  background: #FFF;
  span {
    color: #01959C;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &:active {
    opacity: 0.8;
  }
}
span.note-text {
  color: #57636C;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

&.small {
  .btn-upload {
    padding: 12px 12px;
    span {
      font-size: 14px;  
    }
  }
  span.note-text {
    font-size: 14px;
  }
}
`;

type Props = {
  value?: string;
  onChange?: (value: string) => void;
  small?: boolean;
  nbt?: boolean;
};
const StaffAvatarForm = ({ nbt, small, value: file, onChange: setFile = () => undefined }: Props) => {

  const onChangeFile: InputHTMLAttributes<HTMLInputElement>['onChange'] = (e) => {
    const _file = e?.target?.files?.[0];
    if (!_file) return;
    setFile('');
    if (!_file.type.includes('video/')) {
      const reader = new FileReader();
      reader.addEventListener('load', function (e) {
        const readerTarget = e.target;
        setFile(readerTarget?.result as string);
      });
      reader.readAsDataURL(_file);
    }
  };
  return (
    <Container className={clsx(nbt && 'nbt')}>
      <label className="picture" htmlFor="picture__input">
        <Avatar size={112} src={file} style={{ background: '#C6E9EF', border: '3px dashed #2D6772' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M10 8C8.89543 8 8 8.89543 8 10V38C8 38.8576 8.5398 39.5892 9.29818 39.8734L30.5858 18.5858C31.3668 17.8047 32.6332 17.8047 33.4142 18.5858L40 25.1716V10C40 8.89543 39.1046 8 38 8H10ZM44 29.998V10C44 6.68629 41.3137 4 38 4H10C6.68629 4 4 6.68629 4 10V38C4 41.3137 6.68629 44 10 44H38C41.3137 44 44 41.3137 44 38V30.002C44 30.0007 44 29.9993 44 29.998ZM40 30.8284L32 22.8284L14.8284 40H38C39.1046 40 40 39.1046 40 38V30.8284ZM17 16C16.4477 16 16 16.4477 16 17C16 17.5523 16.4477 18 17 18C17.5523 18 18 17.5523 18 17C18 16.4477 17.5523 16 17 16ZM12 17C12 14.2386 14.2386 12 17 12C19.7614 12 22 14.2386 22 17C22 19.7614 19.7614 22 17 22C14.2386 22 12 19.7614 12 17Z" fill="#2D6772" />
          </svg>
        </Avatar>
      </label>
      <UploadPhotoContainer className={small ? 'small' : ''}>
        <input
          onChange={onChangeFile} type="file" name="picture__input" id="picture__input"
          accept="image/png, image/jpeg"
          style={{ opacity: 0, position: 'absolute' }}
        />
        <label className='staff-upload' htmlFor="picture__input">
          <p>Staff Avatar</p>
          <p className="note-text">Click on the image to change the image. PNG, JPG max size of 5MB</p>
        </label>
      </UploadPhotoContainer>
    </Container>
  );
};

export default StaffAvatarForm;
