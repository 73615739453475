import Sidebar from 'components/layout/Sidebar';
import colorTheme from 'constants/color';
import { useTicketWrapper } from 'features/ShopWrapperLayout/TicketWrapperContext';
import VoidPrintBody from 'features/ticketList/components/VoidTicketPrint/PrintBody';
import ticketListActions from 'features/ticketList/services/actions';
import ticketListSelectors from 'features/ticketList/services/selectors';
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import shopActions from 'services/shop/actions';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import LeftTicketDetails from './components/LeftTicketDetails';
import RightTicketDetails from './components/RightTicketDetails';
import TicketDetailsPageStyled from './styles';
import Actions from './components/Actions';
import shopSelectors from 'services/shop/selectors';
import TicketDetailV2 from './v2';

type ITicketDetailsPageProps = any;
const TicketDetailsPage: React.FC<ITicketDetailsPageProps> = () => {
  const { id } = useParams();
  const details = ticketListSelectors.ticketDetailsData.data();
  const dispatch = useAppDispatch();
  const divRef = useRef<HTMLDivElement>(null);
  const doPrint = useReactToPrint({ content: () => divRef.current, });
  const ticketWrapper = useTicketWrapper();
  const versionUI = shopSelectors.data.versionUI();

  const handlePrint = () => {
    if (!id) return;
    if (details?.billStatus !== 'VOID') return ticketWrapper.printWithoutCoupon(id);
    setTimeout(() => doPrint(), 10);
  };

  useEffect(() => {
    dispatch(ticketListActions.getTicketDetails.fetch(id || ''));
    dispatch(shopActions.get.staffsAvailable.fetch());
  }, []);

  if (versionUI === 'V2') return <TicketDetailV2 />;

  return (
    <>
      <TicketDetailsPageStyled>
        <Sidebar />
        <BodyStyled>
          <Container>
            <LeftTicketDetails doPrint={handlePrint} />
            <RightTicketDetails doPrint={handlePrint} />
          </Container>
          <FixedActions><Actions doPrint={handlePrint} /></FixedActions>
        </BodyStyled>
      </TicketDetailsPageStyled>
      <div style={{ display: 'none' }}><VoidPrintBody billRef={divRef} isPrinter /></div>
    </>
  );
};

const BodyStyled = styled.div`
  flex-grow: 1;
  height: 100vh;
  background-color: ${colorTheme.fill_3};
  display: flex;
  flex-direction: column;
`;
const Container = styled.div`
  display:flex;
  overflow: auto;
  background-color: ${colorTheme.fill_3};
  display: flex;
  flex: 1;
`;


const FixedActions = styled.div`
  height: 6.375rem;
  background-color: #fff;
  background: #FFF;
  box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;
`;
export default TicketDetailsPage;