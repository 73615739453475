import { Form, FormProps } from 'antd';
import Modal from 'components/Modal';
import { useTicketWrapper } from 'features/ShopWrapperLayout/TicketWrapperContext';
import { parseDecimal } from 'features/cashier/services/constants';
import ConfirmTipTicket, { useConfirmTipTicketRef } from 'features/payment/components/ConfirmTipTicket';
import { ICustomerPaymentDetail } from 'features/payment/pages/PaymentPage/services/types/customer';
import { ICustomerReceiveConfigs, PaymentSocketData, TypePaymentActors, TypePaymentPassData } from 'features/payment/pages/PaymentPage/services/types/socketPayment';
import paymentApis from 'features/payment/services/apis';
import { IBodyAPIPayment } from 'features/payment/services/types/api';
import { IBillDetailData } from 'features/payment/services/types/bill';
import { useSocketContext } from 'hooks/useSocket';
import { debounce } from 'lodash';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import { PAYMENT_PASS_DATA_TOPIC } from 'utils/socket';
import CashierActionButton from '../../CashierActionBtn';
import useUpdateTicket from '../../useUpdateTicket';
import { useMultiplePayment } from '../ButtonMultiPayment';
import CreditCardPaymentHandler, { useCreditCardPaymentHandlerRef } from './CreditCardPaymentHandler';
import MultiPayForm, { useMultiPayFormRef } from './MultiPayForm';
import QRPaymentHandler, { useQRPaymentHandlerRef } from './QRPaymentHandler';
import { MULTI_PAYMENT_TYPES, PAYMENT_FORM_NAMES } from './constants';
import { calcCashChance, calcRemaining, getMultiplePaymentInfo, IFormMultiPaymentValues, useCardFee, useCashIncentiveDiscount, usePointExchangeDollar } from './helpers';
import { IGiftCardV3PaymentValue } from 'features/cashier/services/types/giftCard';
import { useDispatch } from 'react-redux';
import paymentActions from 'features/payment/services/actions';
type Ref = {
  continueGiftCardPayWithMultiPay: (billId: string, val: IGiftCardV3PaymentValue) => void;
  continueLoyaltyPayWithMultiPay: (billId: string, val: {
    amount: number;
    point: number;
  }) => void;
};
export const cashierBtnMultiPayV3 = React.createRef<Ref>();

const ButtonMultiPayV3 = forwardRef<Ref>(({ }, ref) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [billId, setBillId] = useState('');
  const updateTicket = useUpdateTicket();
  const qrPaymentRef = useQRPaymentHandlerRef();
  const dispatch = useDispatch();

  const creditCardPaymentRef = useCreditCardPaymentHandlerRef();
  const multiFormRef = useMultiPayFormRef();

  const onClose = () => {
    socketContext.switchCustomerScreen(`/store/${storage.shop_id.get()}/check-in/sign-in`);
    setBillId('');
  };

  const onCreditCardCancel = () => {
    dispatch(paymentActions.cancelTransaction.fetch(billId || ''));
    socketContext.switchCustomerScreen(`/store/${storage.shop_id.get()}/check-in/sign-in`);
    setBillId('');
  };
  const { checkCashIncentiveValid, enableCardFee } = useMultiplePayment();
  const setLoadingPage = useSetLoadingPage();
  const ticketContext = useTicketWrapper();
  const completedTicketModalRef = useConfirmTipTicketRef();
  const socketContext = useSocketContext();
  const cashCheckDiscount = useCashIncentiveDiscount();
  const cardFeeHook = useCardFee();
  const exchangeLoyalty = usePointExchangeDollar();

  const fetchDetail = async (_payload: string) => {
    try {
      const res: IResponseDataBody<IBillDetailData> = await paymentApis.getTransactionDetail(_payload);
      const detail = res?.data?.data;
      if (detail) {
        form.resetFields(PAYMENT_FORM_NAMES.map(o => `isValid_${o}`));
        form.setFieldValue('detail', detail);
        form.setFieldValue('amount', detail?.total || 0);
        form.setFieldValue('enableCardFee', enableCardFee);
        form.setFieldValue('enableIncentive', checkCashIncentiveValid());
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const onOpenPayment = async () => {
    setBillId('');
    setLoadingPage(true);
    try {
      const rest: { billId: string } | null = await updateTicket();
      if (!rest) return;
      const billId = rest.billId;
      form.resetFields();
      const valid = await fetchDetail(billId);
      if (!valid) return;
      setBillId(billId);
      setTimeout(() => { multiFormRef.current?.init(); }, 200);
      socketContext.switchCustomerScreen(`/store/${storage.shop_id.get()}/ticket/payment/customer-side/${billId}`);
    } catch (error) {
    } finally {
      setLoadingPage(false);
    }
  };

  const onCompleted = async (_billId: string, ignorePrint?: boolean) => {
    if (!_billId) return;
    socketContext.switchCustomerScreen(`/store/${storage.shop_id.get()}/ticket/payment/customer-side/rating/${_billId}`);
    const callback = () => {
      if (ignorePrint) {
        navigate(`/store/${storage.shop_id.get()}`);
        return;
      }
      ticketContext.completedTicket(_billId, true);
    };
    if (!completedTicketModalRef.current) return callback();
    completedTicketModalRef.current.open(_billId, callback);
  };

  const onCCCompleted = (ignorePrint?: boolean) => {
    const _billId = form.getFieldValue(['detail', 'billId']);
    if (!_billId) return;
    creditCardPaymentRef.current?.closeSignature();
    socketContext.switchCustomerScreen(`/store/${storage.shop_id.get()}/ticket/payment/customer-side/rating/${_billId}`);
    const callback = () => {
      if (ignorePrint) {
        navigate(`/store/${storage.shop_id.get()}`);
        return;
      }
      ticketContext.completedTicket(_billId, true);
    };
    if (!completedTicketModalRef.current) return callback();
    completedTicketModalRef.current.open(_billId, callback);
  };


  // const doLoyaltyPoint = async (values: IFormMultiPaymentValues) => {
  //   loyaltyPointPaymentRef.current?.requestPoint(values);
  // };

  const doCreditCard = async (values: IFormMultiPaymentValues) => {
    if (!values.detail?.billId) return;
    const configs: ICustomerReceiveConfigs = {
      creditCard: true,
      enableTipBeforeMode: true,
      enableCardFee,
    };
    socketContext.send(PAYMENT_PASS_DATA_TOPIC, {
      billId: values.detail?.billId || '',
      actor: TypePaymentActors.CASHIER,
      action: TypePaymentPassData.CUSTOMER_RECEIVE_CONFIGS,
      data: configs,
    });
    const totalCC = values.CREDIT_CARD + values.cardFee;
    creditCardPaymentRef.current?.requestTip(values, totalCC);

    setTimeout(() => {
      socketContext.send(PAYMENT_PASS_DATA_TOPIC, {
        billId: values.detail?.billId,
        actor: TypePaymentActors.CASHIER,
        action: TypePaymentPassData.TIP_BEFORE_PAYMENT_REQUEST_TIP,
        data: { totalCC }
      });
    }, 500);
  };

  // const doCheck = async (values: IFormMultiPaymentValues) => {
  //   if (!values.detail?.billId) return;
  //   const configs: ICustomerReceiveConfigs = {
  //     enableCashIncentive: values.enableIncentive,
  //   };
  //   socketContext.send(PAYMENT_PASS_DATA_TOPIC, {
  //     billId: values.detail?.billId,
  //     actor: TypePaymentActors.CASHIER,
  //     action: TypePaymentPassData.CUSTOMER_RECEIVE_CONFIGS,
  //     data: configs,
  //   });
  //   checkPaymentRef.current?.requestSignature(values);
  // };

  const doQRPayment = async (values: IFormMultiPaymentValues, method: string, amount: number) => {
    const billId = values.detail.billId;
    if (!billId) return;
    const body: PaymentSocketData = {
      shopId: storage.shop_id.get(),
      billId,
      actor: TypePaymentActors.CASHIER,
      action: TypePaymentPassData.QR_MULTIPLE_PAYMENT_REQUEST_VISIBLE,
      data: { type: method, amount },
    };
    socketContext.send(PAYMENT_PASS_DATA_TOPIC, body);
    qrPaymentRef.current?.requestQRPayment(method, amount);
  };

  const completeWithCreditCard = async (values: IFormMultiPaymentValues) => {
    if (!values.detail?.billId) return;
    // TODO:
    // const cashDiscount = values.cashIncentiveValue + values.checkIncentiveValue;
    // if (values.enableIncentive && !!cashDiscount) {
    //   await updateDiscountCashIncentive(values);
    // }

    const creditValue = values.CREDIT_CARD + values.tip;
    const totalCC = creditValue + values.cardFee;
    socketContext.send(PAYMENT_PASS_DATA_TOPIC, {
      billId: values.detail?.billId,
      actor: TypePaymentActors.CASHIER,
      action: TypePaymentPassData.TIP_BEFORE_PAYMENT_SEND_TIP_VALUE,
      data: { tip: values.tip },
    });
    creditCardPaymentRef.current?.openCCLoading(totalCC);
    const paymentInfo = getMultiplePaymentInfo(values);
    const body: IBodyAPIPayment = {
      billId: values.detail.billId,
      paymentInfo,
    };
    const res: IResponseDataBody<true> = await paymentApis.payment(body);
    if (res?.data?.data) {
      creditCardPaymentRef.current?.requestSignature();
      socketContext.send(PAYMENT_PASS_DATA_TOPIC, {
        billId: values.detail?.billId,
        actor: TypePaymentActors.CASHIER,
        action: TypePaymentPassData.TIP_BEFORE_PAYMENT_REQUEST_SIGNATURE
      });
    } else {
      throw 'fail';
    }
  };

  // const updateDiscountCashIncentive = async (values: IFormMultiPaymentValues) => {
  //   const cashDiscount = values.cashIncentiveValue + values.checkIncentiveValue;
  //   const discount = values.detail?.discountTicket + cashDiscount;

  //   const payload: IBodyApiUpdateDiscountPayment = {
  //     billId: values.detail?.billId || '',
  //     discount: discount || 0,
  //     discountSetting: { ownerPaidPercent: 100, staffPaidPercent: 0 },
  //   };
  //   const res: IResponseDataBody<{ billId: string }> = await paymentApis.updatePaymentDiscountTicket(payload);
  //   const resData = res?.data?.data;
  //   if (!resData) throw 'fail';
  // };

  const completeWithCash = async (values: IFormMultiPaymentValues) => {

    // const cashDiscount = values.cashIncentiveValue + values.checkIncentiveValue;
    // if (values.enableIncentive && !!cashDiscount) {
    //   await updateDiscountCashIncentive(values);
    // }

    const paymentInfo = getMultiplePaymentInfo(values);
    const body: IBodyAPIPayment = {
      billId: values.detail.billId,
      paymentInfo,
    };

    const res: IResponseDataBody<true> = await paymentApis.payment(body);
    if (res?.data?.data) {
      onCompleted(values.detail.billId, values.ignorePrint);
    } else {
      throw 'fail';
    }
  };

  const doPayment = async (values: IFormMultiPaymentValues) => {
    // if (values.LOYALTY_POINT && !values.isValid_LOYALTY_POINT) {
    //   return await doLoyaltyPoint(values);
    // }
    // if (values.GIFT_CARD && !values.isValid_GIFT_CARD) {
    //   return;
    // }
    // if (values.CHECK && !values.isValid_CHECK) {
    //   return await doCheck(values);
    // }
    if (values.VENMO && !values.isValid_VENMO) {
      return await doQRPayment(values, MULTI_PAYMENT_TYPES.VENMO, values.VENMO);
    }
    if (values.PAYPAL && !values.isValid_PAYPAL) {
      return await doQRPayment(values, MULTI_PAYMENT_TYPES.PAYPAL, values.PAYPAL);
    }
    if (values.CASH_APPS && !values.isValid_CASH_APPS) {
      return await doQRPayment(values, MULTI_PAYMENT_TYPES.CASH_APPS, values.CASH_APPS);
    }
    if (values.ZELLE && !values.isValid_ZELLE) {
      return await doQRPayment(values, MULTI_PAYMENT_TYPES.ZELLE, values.ZELLE);
    }
    if (values.CREDIT_CARD && !values.isValid_CREDIT_CARD) {
      return await doCreditCard(values);
    }
    if (values.CREDIT_CARD) {
      return await completeWithCreditCard(values);
    }
    await completeWithCash(values);
  };

  const handleFinish = async (values: IFormMultiPaymentValues) => {
    if (values.CREDIT_CARD) {
      if (!values.isValid_CREDIT_CARD) setLoadingPage(true);
    } else {
      setLoadingPage(true);
    }

    try {
      await doPayment(values);
    } catch (error) {
      form.resetFields(PAYMENT_FORM_NAMES.map(o => `isValid_${o}`));
    } finally {
      creditCardPaymentRef.current?.closeCCLoading();
      setLoadingPage(false);
    }
  };

  const debounceValuesChange: FormProps['onValuesChange'] = debounce(() => {
    const values: IFormMultiPaymentValues = form.getFieldsValue();
    const billId = values.detail?.billId || '';
    const remaining = calcRemaining(values);
    if (!billId) return;
    const body: PaymentSocketData = {
      shopId: storage.shop_id.get(),
      billId,
      actor: TypePaymentActors.CASHIER,
      action: TypePaymentPassData.PASS_DATA,
      data: {
        cash: values.CASH || 0,
        remaining: remaining || 0,
        chanceCash: values.chance ? -values.chance : 0,
        loyalty: values.LOYALTY_POINT || 0,
        giftCard: values.GIFT_CARD || 0,
        checkOthers: values.CHECK || 0,
        creditCard: values.CREDIT_CARD || 0,
        multiPay: 1,
      } as ICustomerPaymentDetail
    };
    socketContext.send(PAYMENT_PASS_DATA_TOPIC, body);
  }, 1000);

  const onValuesChange: FormProps['onValuesChange'] = (changedValues, values: IFormMultiPaymentValues) => {
    const incentivePercent = cashCheckDiscount.getIncentivePercent();
    const cashIncentiveValue = cashCheckDiscount.calcAmountDiscount(values.CASH, values);
    const chance = parseDecimal(calcCashChance(values, cashIncentiveValue));
    const checkIncentiveValue = cashCheckDiscount.calcAmountDiscount(values.CHECK, values);

    const cardFee = cardFeeHook.getAmountCardFee(values);
    const cardFeeLabel = cardFeeHook.getLabelCardFee(cardFee);

    const availablePoint = values.detail?.customerPoint || 0;
    const availablePointExchange = parseDecimal(exchangeLoyalty.exchangeDollar(availablePoint));
    const redeemPoint = parseDecimal(exchangeLoyalty.exchangePoint(values.LOYALTY_POINT || 0));

    const balancePoint = availablePoint - redeemPoint;
    const balancePointExchange = parseDecimal(exchangeLoyalty.exchangeDollar(balancePoint));

    form.setFieldsValue({
      incentivePercent,
      cashIncentiveValue,
      checkIncentiveValue,
      chance,

      cardFee,
      cardFeeLabel,

      availablePoint,
      availablePointExchange,
      redeemPoint,
      balancePoint,
      balancePointExchange,
    });

    form.validateFields(['amountValid']);

    debounceValuesChange(changedValues, values);
  };

  useImperativeHandle(ref, () => ({
    async continueGiftCardPayWithMultiPay(billId, val: IGiftCardV3PaymentValue) {
      setLoadingPage(true);
      try {
        form.resetFields();
        const valid = await fetchDetail(billId);
        if (!valid) return;
        setBillId(billId);
        setTimeout(() => { multiFormRef.current?.init(); }, 200);
        socketContext.switchCustomerScreen(`/store/${storage.shop_id.get()}/ticket/payment/customer-side/${billId}`);
        const { amount = 0, usedAmount = 0, id } = val.data || {};
        form.setFieldsValue({
          giftCardCode: val.data?.giftCode,
          giftCardId: id,
          giftCardAvailable: amount - usedAmount,
          GIFT_CARD: val.amount,
        });
      } catch (error) {
      } finally {
        setLoadingPage(false);
      }
    },
    async continueLoyaltyPayWithMultiPay(billId, val) {
      setLoadingPage(true);
      try {
        form.resetFields();
        const valid = await fetchDetail(billId);
        if (!valid) return;
        setBillId(billId);
        setTimeout(() => { multiFormRef.current?.init(); }, 200);
        socketContext.switchCustomerScreen(`/store/${storage.shop_id.get()}/ticket/payment/customer-side/${billId}`);
        form.setFieldValue(MULTI_PAYMENT_TYPES.POINT, val.amount);
      } catch (error) {
      } finally {
        setLoadingPage(false);
      }
    },
  }));

  return (
    <>
      <CashierActionButton v2UI feature='MULTI-PAYS' ntype='YELLOW' label={'Multi-Pays'} onClick={onOpenPayment} />
      {!!billId &&
        <Modal visible
          onClose={onClose}
          width={'auto'} footer={null} className='modal-overflow-unset modal-non-opacity'
          hiddenHeader
          noneBodyStyle
        >
          <Container<any> form={form} onFinish={handleFinish} onValuesChange={onValuesChange}>
            <MultiPayForm ref={multiFormRef} onClose={onClose} />
            <Form.Item name={'detail'} noStyle />
            <Form.Item name={'ignorePrint'} noStyle />
            <Form.Item name={'chance'} noStyle />
            <Form.Item name={'cardFee'} noStyle />
            <Form.Item name={'cardFeeLabel'} noStyle />
            <Form.Item name={'giftCardId'} noStyle />
            <Form.Item name={'giftCardCode'} noStyle />
            <Form.Item name={'giftCardAvailable'} noStyle />
            <Form.Item name={'cashIncentiveValue'} noStyle />
            <Form.Item name={'checkIncentiveValue'} noStyle />
            <Form.Item name={'incentivePercent'} noStyle />
            <Form.Item name={'availablePoint'} noStyle />
            <Form.Item name={'availablePointExchange'} noStyle />
            <Form.Item name={'redeemPoint'} noStyle />
            <Form.Item name={'balancePoint'} noStyle />
            <Form.Item name={'balancePointExchange'} noStyle />
            <Form.Item name={'giftCreditCode'} noStyle />
            {PAYMENT_FORM_NAMES.map(o => <Form.Item key={o} noStyle name={`isValid_${o}`} />)}
            <QRPaymentHandler ref={qrPaymentRef} />
            <Form.Item name={'tip'} noStyle >
              <CreditCardPaymentHandler
                ref={creditCardPaymentRef}
                billId={billId}
                onCCCompleted={onCCCompleted}
                onCreditCardCancel={onCreditCardCancel}
              />
            </Form.Item>
          </Container>
        </Modal>}
      <ConfirmTipTicket ref={completedTicketModalRef} />
    </>
  );
});
ButtonMultiPayV3.displayName = 'ButtonMultiPayV3';
export default ButtonMultiPayV3;
const Container = styled(Form)`
  display: flex;
`;

