import { IGiftCardDetail, IResultGiftCardV3 } from 'features/cashier/services/types/giftCard';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import { IPaymentInfoItem } from 'features/payment/services/types/api';
import { IBillDetailData } from 'features/payment/services/types/bill';
import { ICheckPayment } from 'features/payment/services/types/payment';
import { set, sumBy } from 'lodash';
import shopSelectors from 'services/shop/selectors';
import { parseFee } from 'utils/formatCurrency';
import { MULTI_PAYMENT_TYPES, PAYMENT_FORM_NAMES } from './constants';

export interface IFormMultiPaymentValues {
  detail: IBillDetailData;
  ePayType: string;
  enableCardFee: boolean;
  ignorePrint: boolean;

  enableIncentive: boolean;
  cashIncentiveValue: number;
  checkIncentiveValue: number;
  incentivePercent: number;

  chance: number;
  otherCheckInfo: ICheckPayment['otherCheckInfo'];
  giftCardId: string;
  loyaltyPoint: number;
  tip: number;
  cardFee: number;
  cardFeeLabel: { percent: number, amount: number };
  giftCardInfo: IGiftCardDetail | null;
  resultGiftCard: IResultGiftCardV3 | null;

  availablePoint: number;
  availablePointExchange: number;
  redeemPoint: number;
  balancePoint: number;
  balancePointExchange: number;

  CREDIT_CARD: number;
  CASH: number;
  CHECK: number;
  VENMO: number;
  CASH_APPS: number;
  PAYPAL: number;
  ZELLE: number;
  GIFT_CARD: number;
  LOYALTY_POINT: number;
  GIFT_CREDIT: number;
  giftCreditCode: string;

  isValid_CREDIT_CARD: number;
  isValid_CASH: number;
  isValid_CHECK: number;
  isValid_VENMO: number;
  isValid_CASH_APPS: number;
  isValid_PAYPAL: number;
  isValid_ZELLE: number;
  isValid_GIFT_CARD: number;
  isValid_GIFT_CREDIT: number;

}

export const useCashIncentiveDiscount = () => {
  const discountSetting = shopSelectors.data.discountSetting();
  const getCashIncentiveDiscount = (subTotal: number, enableDiscountIncentive?: boolean) => {
    if (!enableDiscountIncentive) return 0;
    if (!subTotal) return 0;
    if (!discountSetting) return 0;
    const { discountType, discountValue } = discountSetting;
    if (discountType === 'PERCENT') {
      const amount = subTotal * (discountValue / 100);
      return Math.round(amount);

    }
    if (discountType === 'MONEY') {
      const amount = discountValue;
      return Math.round(amount);
    }
    return 0;
  };

  const getAmountCashDiscount = (values: IFormMultiPaymentValues) => {
    const cash = values.CASH || 0;
    const check = values.CHECK || 0;
    const enable = values.enableIncentive || false;
    const total = values.detail?.total || 0;

    const amount = (cash || 0) + (check || 0);

    const finalAmount = amount > total ? total : amount;

    const amountDiscount = getCashIncentiveDiscount(finalAmount, enable);
    return amountDiscount;
  };
  const calcAmountDiscount = (amount: number, values: IFormMultiPaymentValues) => {
    const enable = values.enableIncentive || false;
    const total = values.detail?.total || 0;
    if (!amount) return 0;
    const finalAmount = amount > total ? total : amount;
    const amountDiscount = getCashIncentiveDiscount(finalAmount, enable);
    return amountDiscount;
  };

  const getLabelCashDiscount = (amount: number) => {
    if (!discountSetting) return ({ percent: 0, amount: 0 });
    const { discountType, discountValue } = discountSetting;
    if (discountType === 'PERCENT') {
      return ({
        percent: discountValue,
        amount: amount || 0,
      });
    }
    if (discountType === 'MONEY')
      return ({
        percent: 0,
        amount: discountValue,
      });
  };
  const getIncentivePercent = () => {
    if (!discountSetting) return 0;
    const { discountType, discountValue } = discountSetting;
    if (discountType === 'PERCENT') {
      return discountValue;
    }
    if (discountType === 'MONEY')
      return 0;
  };

  return ({
    getAmountCashDiscount,
    getLabelCashDiscount,
    calcAmountDiscount,
    getIncentivePercent,
  });
};

export const useCardFee = () => {
  const feeCreditCard = shopSelectors.data.feeCreditCard();

  const getAmountCardFee = (values: IFormMultiPaymentValues) => {
    if (!values.enableCardFee) return 0;
    if (!values.CREDIT_CARD) return 0;
    if (!feeCreditCard) return 0;
    if (feeCreditCard?.feeType === 'PERCENT')
      return parseFee(values.CREDIT_CARD * (feeCreditCard?.feeValue / 100));
    if (feeCreditCard?.feeType === 'MONEY')
      return feeCreditCard?.feeValue || 0;
    return 0;
  };

  const getLabelCardFee = (amount: number) => {
    if (!feeCreditCard) return ({ percent: 0, amount: 0 });
    if (feeCreditCard?.feeType === 'PERCENT')
      return ({
        percent: feeCreditCard?.feeValue,
        amount: amount || 0,
      });
    if (feeCreditCard?.feeType === 'MONEY')
      return ({
        percent: 0,
        amount: feeCreditCard?.feeValue || 0,
      });
  };
  return ({ getAmountCardFee, getLabelCardFee });
};

export const calcCashChance = (values: IFormMultiPaymentValues, discount?: number) => {
  const cardFee = values.cardFee || 0;
  const remaining = sumBy([
    values.detail?.total || 0,
    values.CREDIT_CARD ? cardFee : 0,
  ]) - sumBy([
    values.CREDIT_CARD ? values.CREDIT_CARD + cardFee : values.CREDIT_CARD,
    values.CHECK, values.ZELLE,
    values.PAYPAL, values.CASH_APPS, values.VENMO,
    values.GIFT_CARD, values.LOYALTY_POINT, values.GIFT_CREDIT,
  ], o => o || 0) - (discount || 0);

  const _remaining = remaining < 0 ? 0 : remaining;
  const chance = values.CASH - _remaining;
  return chance > 0 ? chance : 0;
};

export const calcRemaining = (values: IFormMultiPaymentValues, pure?: boolean) => {
  const total = values.detail?.total || 0;
  const cardFee = values?.cardFee || 0;
  const remaining = sumBy([
    total,
    values.CREDIT_CARD ? cardFee : 0,
    values.CASH ? - values.cashIncentiveValue : 0,
    values.CHECK ? - values.checkIncentiveValue : 0,
  ]) - sumBy([
    values.CREDIT_CARD ? values.CREDIT_CARD + cardFee : values.CREDIT_CARD,
    values.CASH - values.cashIncentiveValue,
    values.CHECK - values.checkIncentiveValue,
    values.ZELLE, values.PAYPAL,
    values.CASH_APPS, values.VENMO,
    values.GIFT_CARD, values.LOYALTY_POINT, values.GIFT_CREDIT,
  ], o => o || 0);
  if (pure) return remaining;
  return remaining < 0 ? 0 : remaining;
};

export const calcMaxPayment = (values: IFormMultiPaymentValues, type: MULTI_PAYMENT_TYPES) => {
  const otherValueFields = PAYMENT_FORM_NAMES.filter(o => o !== type);
  const arrs: { key: string, value: any }[] = [];
  Object.entries(values).map(([key, value]) => {
    if (otherValueFields.includes(key as MULTI_PAYMENT_TYPES)) {
      arrs.push({ key, value: value || 0 });
    }
  });
  const total = values.detail?.total || 0;
  const paymentTotal = +sumBy(arrs, o => o.value).toFixed(2);
  const result = total - paymentTotal;
  return result > 0 ? result : 0;
};

export const getMultiplePaymentInfo = (values: IFormMultiPaymentValues) => {
  const paymentInfo: IPaymentInfoItem[] = [];
  if (values.CASH) {
    const result: IPaymentInfoItem = {
      paymentType: PAYMENT_TYPE.CASH,
      chance: Math.abs(values.chance || 0),
      amount: values.CASH ?? 0,
    };
    if (values.enableIncentive) {
      set(result, 'cashIncentiveDiscount', true);
    }
    paymentInfo.push(result);
  }
  if (values.CHECK) {
    const result: IPaymentInfoItem = {
      paymentType: PAYMENT_TYPE.CHECK,
      amount: values.CHECK ?? 0,
      otherCheckInfo: values.otherCheckInfo,
    };
    if (values.enableIncentive) {
      set(result, 'cashIncentiveDiscount', true);
    }
    paymentInfo.push(result);
  }
  if (values.VENMO) {
    const result: IPaymentInfoItem = {
      paymentType: PAYMENT_TYPE.CHECK,
      amount: values.VENMO ?? 0,
      otherMethod: PAYMENT_TYPE.VENMO,
    };
    paymentInfo.push(result);
  }
  if (values.CASH_APPS) {
    const result: IPaymentInfoItem = {
      paymentType: PAYMENT_TYPE.CHECK,
      amount: values.CASH_APPS ?? 0,
      otherMethod: PAYMENT_TYPE.CASH_APPS,
    };
    paymentInfo.push(result);
  }
  if (values.ZELLE) {
    const result: IPaymentInfoItem = {
      paymentType: PAYMENT_TYPE.CHECK,
      amount: values.ZELLE ?? 0,
      otherMethod: PAYMENT_TYPE.ZELLE,
    };
    paymentInfo.push(result);
  }
  if (values.PAYPAL) {
    const result: IPaymentInfoItem = {
      paymentType: PAYMENT_TYPE.CHECK,
      amount: values.PAYPAL ?? 0,
      otherMethod: PAYMENT_TYPE.PAYPAL,
    };
    paymentInfo.push(result);
  }
  if (values.GIFT_CARD) {
    const result: IPaymentInfoItem = {
      paymentType: PAYMENT_TYPE.GIFT_CARD,
      amount: values.GIFT_CARD ?? 0,
      giftCardId: values.giftCardId,
    };
    paymentInfo.push(result);
  }
  if (values.LOYALTY_POINT) {
    const result: IPaymentInfoItem = {
      paymentType: PAYMENT_TYPE.LOYALTY_POINT,
      amount: values.LOYALTY_POINT ?? 0,
      point: values.loyaltyPoint,
    };
    paymentInfo.push(result);
  }
  if (values.CREDIT_CARD) {
    const result: IPaymentInfoItem = {
      paymentType: PAYMENT_TYPE.CREDIT_CARD,
      amount: values.CREDIT_CARD ?? 0,
      tip: values.tip,
      chargeFee: !!values.enableCardFee,
    };
    paymentInfo.push(result);
  }
  if (values.GIFT_CREDIT) {
    const result: IPaymentInfoItem = {
      paymentType: PAYMENT_TYPE.GIFT_CREDIT,
      amount: values.GIFT_CREDIT ?? 0,
      otherCheckInfo: { checkNumber: values.giftCreditCode } as any,
    };
    paymentInfo.push(result);
  }

  return paymentInfo;
};

export const usePointExchangeDollar = () => {
  const loyaltySetting = shopSelectors.data.getLoyaltySetting();

  const exchangeDollar = (point: number) => {
    if (!loyaltySetting) return 0;

    return +(point) / loyaltySetting?.oneDollarToPoint;
  };

  const exchangePoint = (_amount: number) => {
    if (!loyaltySetting) return 0;
    return (+(_amount || 0)) * (loyaltySetting?.oneDollarToPoint || 0);
  };

  return ({
    exchangeDollar,
    exchangePoint,
  });
};
