import { PAYMENT_TYPE } from 'features/payment/services/constants';
import { useTicketWrapper } from 'features/ShopWrapperLayout/TicketWrapperContext';
import VoidButtonHandler, { IVoidFuncType, useVoidButtonHandlerRef } from 'features/ticketList/components/VoidButton';
import VoidPrintBody from 'features/ticketList/components/VoidTicketPrint/PrintBody';
import ticketListActions from 'features/ticketList/services/actions';
import ticketListSelectors from 'features/ticketList/services/selectors';
import { get } from 'lodash';
import { useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import ButtonTicketSendEmail, { useButtonTicketSendEmailRef } from 'widgets/Receipt/ButtonEmail';
import ButtonTicketSendSMS, { useButtonTicketSendSMSRef } from 'widgets/Receipt/ButtonSMS';
import EditStaffTicket, { useEditStaffTicketRef } from '../components/EditStaffTicket';
import RefundButton, { useRefundButtonRef } from '../components/ModalButton/RefundButton';
import { ModalModifyTipCash, useModalModifyTipCashRef } from '../components/ModifyTipCash/ModalModifyTipCash';
import ReAdjustTip, { useReAdjustTipRef } from '../components/ReAdjustTip';
import { ModalSplitTicket, useModalSplitTicketRef } from '../components/SplitTicket/ModalSplitTicket';
import back_icon from './icons/back.svg';
import edit_icon from './icons/edit.svg';
import email_icon from './icons/email.svg';
import modify_tip_icon from './icons/modify-tip.svg';
import refund_icon from './icons/refund.svg';
import reprint_icon from './icons/reprint.svg';
import sms_icon from './icons/SMS.svg';
import split_icon from './icons/split.svg';
import supply_fee_icon from './icons/supply-fee.svg';
import voided_icon from './icons/voided.svg';
import { formatPhone } from 'utils/formatPhone';

const ACTION_TYPES = {
  EDIT_TICKET: 'EDIT_TICKET',
  RE_PRINT: 'RE_PRINT',
  VOIDED: 'VOIDED',
  REFUND: 'REFUND',
  EMAIL: 'EMAIL',
  SMS: 'SMS',
  MODIFY_TIP: 'MODIFY_TIP',
  SPLIT_MASTER: 'SPLIT_MASTER',
  UPDATE_SUPPLY_FEE: 'UPDATE_SUPPLY_FEE',
};
const configs = {
  [ACTION_TYPES.EDIT_TICKET]: {
    label: 'Edit Ticket',
    icon: edit_icon,
  },
  [ACTION_TYPES.RE_PRINT]: {
    label: 'Reprint',
    icon: reprint_icon,
  },
  [ACTION_TYPES.VOIDED]: {
    label: 'Voided',
    icon: voided_icon,
  },
  [ACTION_TYPES.REFUND]: {
    label: 'Refund',
    icon: refund_icon,
  },
  [ACTION_TYPES.EMAIL]: {
    label: 'Email',
    icon: email_icon,
  },
  [ACTION_TYPES.SMS]: {
    label: 'SMS',
    icon: sms_icon,
  },
  [ACTION_TYPES.MODIFY_TIP]: {
    label: 'Modify Tip',
    icon: modify_tip_icon,
  },
  [ACTION_TYPES.SPLIT_MASTER]: {
    label: 'Split Master',
    icon: split_icon,
  },
  [ACTION_TYPES.UPDATE_SUPPLY_FEE]: {
    label: 'Re-Adjust tip',
    icon: supply_fee_icon,
  },
};
const TicketDetailHeader = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const details = ticketListSelectors.ticketDetailsData.data();
  const navigate = useNavigate();
  const ticketWrapper = useTicketWrapper();
  const editTicketRef = useEditStaffTicketRef();
  const voidTicketRef = useVoidButtonHandlerRef();
  const refundTicketRef = useRefundButtonRef();
  const emailRef = useButtonTicketSendEmailRef();
  const smsRef = useButtonTicketSendSMSRef();
  const modifyTipRef = useModalModifyTipCashRef();
  const modifySplitRef = useModalSplitTicketRef();
  const reAdjustTipRef = useReAdjustTipRef();

  const isVoided = details?.billStatus === 'VOID';
  const { isCreditCard, isIncludeCashPayment } = useMemo(() => {
    const isCreditCard = !!details?.isMultiplePayCard || !!details?.payments.find(
      (item) => item.paymentType === PAYMENT_TYPE.CREDIT_CARD
    );
    const isIncludeCashPayment = details?.payments?.find(o => o.paymentType === PAYMENT_TYPE.CASH);
    return ({
      isCreditCard, isIncludeCashPayment
    });
  }, [details]);
  const divRef = useRef<HTMLDivElement>(null);
  const doPrint = useReactToPrint({ content: () => divRef.current, });

  const onBack = () => navigate(`/store/${storage.shop_id.get()}/tickets`);
  const onEditTicket = () => editTicketRef.current?.open();

  const handlePrint = () => {
    if (!id) return;
    if (details?.billStatus !== 'VOID') return ticketWrapper.printWithoutCoupon(id);
    setTimeout(() => doPrint(), 10);
  };

  const handleVoid: IVoidFuncType = (payload) => {
    dispatch(ticketListActions.voidTicketByBillId.fetch(payload));
  };

  const onVoid = () => voidTicketRef.current?.open();
  const onRefund = () => refundTicketRef.current?.open();
  const onEmail = () => emailRef.current?.open();
  const onSMS = () => smsRef.current?.open();
  const onModifyTip = () => modifyTipRef.current?.open();
  const onSplitTicket = () => modifySplitRef.current?.open();
  const onReAdjustTip = () => reAdjustTipRef.current?.open();

  if (!details) return null;

  return (
    <Container>
      <div className="nav-container">
        <button type='button' onClick={onBack} className="btn-nav"><img src={back_icon} alt="back_icon" style={{ width: 40, height: 40 }} /></button>
        <span className="page-title">Tickets</span>
      </div>
      <div className="actions">
        <ActionItem type='EDIT_TICKET' onClick={onEditTicket} />
        <ActionItem type='RE_PRINT' onClick={handlePrint} />
        <ActionItem disabled={!details?.void || isVoided} type='VOIDED' onClick={onVoid} />
        <ActionItem disabled={!isCreditCard || !details?.refund || isVoided} type='REFUND' onClick={onRefund} />
        <ActionItem type='EMAIL' onClick={onEmail} />
        <ActionItem type='SMS' onClick={onSMS} />
        <ActionItem
          disabled={!isIncludeCashPayment}
          type='MODIFY_TIP' onClick={onModifyTip}
        />
        <ActionItem disabled={details?.billStatus !== 'CLOSED'} type='SPLIT_MASTER' onClick={onSplitTicket} />
        <ActionItem disabled={details?.items?.length <= 1} type='UPDATE_SUPPLY_FEE' onClick={onReAdjustTip} />
      </div>
      <EditStaffTicket v2 hook ref={editTicketRef} />
      <div style={{ display: 'none' }}><VoidPrintBody billRef={divRef} isPrinter /></div>
      <VoidButtonHandler
        hook ref={voidTicketRef}
        isDetail ticketDetails={details} onVoid={handleVoid}
        v2
      />
      <RefundButton v2 ref={refundTicketRef} hook maxAmount={details?.total} />
      <ButtonTicketSendEmail v2 billId={details.billId} ref={emailRef} hook />
      <ButtonTicketSendSMS v2 billId={details.billId} ref={smsRef} hook defaultSMSPhone={formatPhone(details.customerPhone)} />
      <ModalModifyTipCash v2 ref={modifyTipRef} />
      <ModalSplitTicket v2 ref={modifySplitRef} />
      <ReAdjustTip v2 ref={reAdjustTipRef} hook />
    </Container>
  );
};

export default TicketDetailHeader;
const Container = styled.div`
  display: flex;
  padding: 8px 24px;
  align-items: center;
  background: #FFF;
  align-self: stretch;

  .nav-container {
    display: flex;
    height: 56px;
    align-items: center;
    margin-right: 56px;
    .btn-nav {
      padding-right: 24px;
    }
    span.page-title {
      color: #505050;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .actions {
    flex: 1;
    justify-content: flex-end;
    display: flex;
    align-items: center;
  }
`;

type IActionItemProps = {
  type: keyof typeof ACTION_TYPES;
  onClick?: () => void;
  disabled?: boolean;
};
const ActionItem = ({ type, onClick, disabled }: IActionItemProps) => {
  const config = get(configs, type);
  if (!config) return null;
  return (
    <ActionStyled disabled={disabled} type='button' onClick={onClick}>
      <img src={config.icon} alt={config.label} className="icon" />
      <span>{config.label}</span>
    </ActionStyled>
  );
};
const ActionStyled = styled.button`
display: flex;
padding: 8px 16px;
flex: 1;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 2px;
background: #FCFCFD;
box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;

&:disabled {
  opacity: 0.7;
}
img.icon {
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
}

span {
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

`;