import Box from 'components/Box';
import Modal from 'components/Modal';
import NumberPadV2 from 'components/NumberKeyBoard/Currency/NumberPadV2';
import Text from 'components/Text';
import { get } from 'lodash';
import React, { forwardRef, useImperativeHandle, useRef, useState, useTransition } from 'react';
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';
import { IResponseDataBody } from 'services/response';
import shopApis from 'services/shop/apis';
import shopSelectors from 'services/shop/selectors';
import { uiLoadingBasicRef } from 'services/UI/LoadingPage/UILoadingRef';
import styled from 'styled-components';
import { formatPhone } from 'utils/formatPhone';
export enum FeatureVerify {
  LOGIN = 'LOGIN',
  REPORT_SALARY = 'REPORT_SALARY',
}

type Ref = {
  open: (success: (valid?: boolean) => void, feature: FeatureVerify, shop: { shopId: string, phone: string }) => void;
  openActiveSalon: (success: (valid?: boolean) => void, feature: FeatureVerify) => void;
};
export const OTPVerificationRef = React.createRef<Ref>();
const OTPVerification = forwardRef<Ref>(({ }, ref) => {
  const [shop, setShop] = useState<{ shopId: string, phone: string } | null>(null);
  const callback = useRef<(valid?: boolean) => void>(() => undefined);
  const [pin, setPin] = useState('');
  const [error, setError] = useState('');
  const [feature, setFeature] = useState<FeatureVerify | null>(null);
  const [, startTransition] = useTransition();
  const salonInfo = shopSelectors.data.shopInfo();

  const _onChange = (text: string) => {
    setPin(text);
    if (error) setError('');
  };
  const [open, setOpen] = useState(false);
  const onClose = () => {
    startTransition(() => {
      setOpen(false);
      setShop(null);
      setPin('');
      setError('');
      callback.current = () => undefined;
    });
  };

  const onResendOTP = () => {
    if (!feature || !shop) return;
    checkConfigVerifyOtp(feature, shop.shopId);
  };

  const onSubmit = async () => {
    if (!shop) return;
    uiLoadingBasicRef.current?.setVisible(true);
    try {
      const res: IResponseDataBody<{ verifyOtp: boolean; message: string; }> = await shopApis.verifyOTP2Factor(shop.shopId, { otpToken: '', otpValue: pin });
      if (res.data.data?.verifyOtp) {
        callback.current(true);
        onClose();
      } else {
        setError(res.data.data?.message);
      }
    } catch (error: any) {
      const msg = get(error, 'response.data.message');
      toast.error(msg ?? '');
    } finally {
      uiLoadingBasicRef.current?.setVisible(false);
    }
  };
  const checkConfigVerifyOtp = async (feature: FeatureVerify, shopId: string) => {
    uiLoadingBasicRef.current?.setVisible(true);
    try {
      const res = await shopApis.checkConfigVerifyOtp(feature, shopId);
      if (res.data.data) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    } finally {
      uiLoadingBasicRef.current?.setVisible(false);
    }
  };

  useImperativeHandle(ref, () => ({
    open: async (cb, _feature, _shop) => {
      callback.current = cb;
      setShop(_shop);
      setFeature(_feature);
      const result = await checkConfigVerifyOtp(_feature, _shop.shopId);
      if (result) {
        setOpen(true);
      } else {
        cb(false);
      }
    },
    async openActiveSalon(cb, _feature) {
      if (!salonInfo) return cb();
      callback.current = cb;
      const _shop = { phone: salonInfo.phone, shopId: salonInfo.id };
      setShop(_shop);
      setFeature(_feature);
      const result = await checkConfigVerifyOtp(_feature, _shop.shopId);
      if (result) {
        setOpen(true);
      } else {
        cb(false);
      }
    },
  }));

  if (!shop) return null;

  const MaskedInput = (props: any) => {
    const { value, ...otherProps } = props;
    
    const displayValue = value ? '•' : props.placeholder;
    
    return (
      <input
        {...otherProps}
        value={displayValue}
        data-actual-value={value} 
      />
    );
  };

  return (
    <Modal
      visible={open}
      v2
      modalTitle='OTP Verification'
      onClose={onClose}
      destroyOnClose
      onSubmit={onSubmit}
      okTitle='Verify'
      className='modal-overflow-unset modal-max-height-unset modal-bg-v2'
      containerPadding={0}
      noneBodyStyle
      width={'auto'}
    >
      <ContentContainer>
        <div className="header-info">
          <div className="placeholder-text">
            Enter the verification code we sent to
          </div>
          <div className="phone-text">{formatPhone(shop.phone)}</div>
        </div>
        <OtpInputStyled>
          <div className="section-label">Type 4 digit security code</div>
          <OtpInput
            value={pin}
            onChange={setPin}
            numInputs={6}
            placeholder='*'
            renderSeparator={<span>-</span>}
            // renderInput={(props: any) => <input {...props} className='input-otp' inputMode='none' placeholder='*' style={{ width: '132px' }} />}
            renderInput={(props: any, index) => {
              return (
                <MaskedInput
                  {...props}
                  className='input-otp'
                  inputMode='none'
                  placeholder='*'
                  style={{ width: '132px' }}
                />
              );
            }}
          />
          <Box display='flex' justifyContent='center'>
            <Text style={{ fontSize: 24, opacity: error ? 1 : 0 }} variant='ERROR'>{error || '--'}</Text>
          </Box>
          <Box display='flex' className='resend-container'>
            <span>Didn’t you receive the OTP?</span>
            <button onClick={onResendOTP} type='button' className='resent-button'><span>Resend OTP</span></button>
          </Box>
        </OtpInputStyled>
      </ContentContainer>
      <NumberPadV2
        value={pin} onChange={(val) => _onChange(val || '')}
        type='PIN-PASSWORD'
      />
    </Modal>
  );
});
OTPVerification.displayName = 'OTPVerification';
export default OTPVerification;
const ContentContainer = styled.div`
padding: 32px;
background: #fff;
padding-bottom: 16px;
  .header-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    .placeholder-text {
      color: #64748B;
      text-align: center;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .phone-text {
    text-align: center;
      color: #1D2129;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }
  }
`;
const OtpInputStyled = styled.div`
display: flex;
flex-direction: column;
.section-label {
  color: #64748B;
  align-self: stretch;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}
.input-otp {
  display: flex;
  height: 80px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 5px;
  border: 1px solid #C9CDD4;
  background: #FFF;
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &::placeholder {
    color: #64748B;
    opacity: 1; 
    transform: translateY(10px);
  }

  &::-ms-input-placeholder { 
    color: #64748B;
    transform: translateY(10px);
  }
}
.resend-container {
  align-self: stretch;
  justify-content: center;
  margin-top: 16px;
  gap: 8px;
  span {
    color: #64748B;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  button span {
    color: #6FABB6;
    font-weight: 600;
  }
}
`;