import Modal from 'components/Modal';
import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { IResponseDataBody } from 'services/response';
import styled from 'styled-components';
import { IBillDetailData } from '../services/types/bill';
import multiplePaymentSelectors from '../pages/PaymentPage/services/selectors';
import { formatCurrency } from 'utils/formatCurrency';
import paymentApis from '../services/apis';
import { uiLoadingBasicRef } from 'services/UI/LoadingPage/UILoadingRef';
import Text from 'components/Text';
import { Col, Row } from 'antd';
import { momentTimezone } from 'utils/time';
export type ConfirmTipTicketRef = {
  open: (id: string, cb: () => void) => void;
  close: () => void;
};

export const useConfirmTipTicketRef = () => useRef<ConfirmTipTicketRef>(null);
type Props = {
  noReceipt?: () => void;
};
const ConfirmTipTicket = forwardRef<ConfirmTipTicketRef, Props>(({ noReceipt }, ref) => {
  const [open, setOpen] = useState(false);
  const cbRef = useRef<() => void>(() => undefined);
  const detailTicket = multiplePaymentSelectors.getDetail();
  const [detail, setDetail] = useState<IBillDetailData | null>(null);

  const calc = useMemo(() => ({
    serviceCharge: formatCurrency(detail?.cardFee),
    discByItem: formatCurrency(-(detail?.discountItem || 0)),
    discByTicket: formatCurrency(-(detail?.discountTicket || 0)),
    subTotal: formatCurrency((detail?.subTotal || 0) - ((detail?.discountItem || 0) + (detail?.discountTicket || 0))),
    balance: formatCurrency(detail?.subTotal),
    saleTax: formatCurrency(detail?.saleTax),
    useTax: formatCurrency(detail?.useTax),
    tip: formatCurrency(detail?.tip),
    total: formatCurrency(detail?.total),
  }), [detail]);

  const getDetailTicket = async (billId: string) => {
    uiLoadingBasicRef.current?.setVisible(true);
    try {
      const res: IResponseDataBody<IBillDetailData> = await paymentApis.getBillDetail(billId);
      if (res.data.data) return res.data.data;
      else return null;
    } catch (error) {
      return null;
    } finally {
      uiLoadingBasicRef.current?.setVisible(false);
    }
  };

  useImperativeHandle(ref, () => ({
    open: async (id: string, cb) => {
      if (!id) return;
      const data = await getDetailTicket(id);
      setDetail(data || detailTicket);
      setOpen(true);
      cbRef.current = cb;
    },
    close: () => setOpen(false),
  }));

  const handleClose = () => {
    setOpen(false);
    cbRef.current();
  };

  if (!open) return null;

  return (
    <ModalStyled
      visible
      hiddenHeader
      hiddenFooter
      noneBodyStyle
      noneFooterStyle
      containerPadding={2}
      width={1000}
      className='modal-overflow-unset modal-max-height-unset'
      onClose={handleClose}
    >
      <TicketDetailStyled>
        <Row justify={'space-between'} style={{ marginBottom: '1rem' }}>
          <Row align={'middle'}>
            <Text variant='H5' mr={1} className='box-number'><span>#{detail?.ticketNumber}</span></Text>
            <Text variant='H5'>{detail?.startTime ? momentTimezone(detail?.startTime).format('hh:mm A') : null}</Text>
          </Row>
          <Text variant='H5'>{detail?.customerName || 'Unknown Customer'}</Text>
        </Row>
        <div className="items">
          {detail?.items.map(o => (
            <div key={o.staffId} className='item'>
              <Row align={'middle'} style={{ width: '100%', fontSize: '1.5rem' }} justify={'space-between'}><Text variant='H9'>{o.staffName}</Text><Text variant='H8' color='info_3' style={{ fontWeight: '700', fontSize: '1.5rem' }} >TIP: {formatCurrency(o.tip)}</Text></Row>
              <Text className='border-dotted' mb={0.5} />
              {o.services.map((s, i) => (
                <Row key={i} align={'middle'} justify={'space-between'} className='service'>
                  <Text variant='CONTENT_1'>{s.itemName}</Text>
                  <Text variant='CONTENT_2'>{formatCurrency(s.price || 0)}</Text>
                </Row>
              ))}
            </div>
          ))}
        </div>
        <Text className="border" mb={2} />
        <Row className="group-info">
          <Col flex={'auto'}>
            <div className="item">
              <Text variant='H8'>Processing Fee:</Text>
              <Text variant='H7'>{calc.serviceCharge}</Text>
            </div>
            <div className="item">
              <Text variant='H8'>Disc. By Item:</Text>
              <Text variant='H7'>{calc.discByItem}</Text>
            </div>
            <div className="item">
              <Text variant='H8'>Disc. By Ticket:</Text>
              <Text variant='H7'>{calc.discByTicket}</Text>
            </div>
            <div className="item">
              <Text variant='H8'>Subtotal:</Text>
              <Text variant='H7'>{calc.subTotal}</Text>
            </div>

          </Col>
          <Col flex={'auto'} />
          <Col flex={'auto'}>
            <div className="item">
              <Text variant='H8'>Balance:</Text>
              <Text variant='H7'>{calc.balance}</Text>
            </div>
            <div className="item">
              <Text variant='H8'>Sale Tax:</Text>
              <Text variant='H7'>{calc.saleTax}</Text>
            </div>
            <div className="item">
              <Text variant='H8'>Use Tax:</Text>
              <Text variant='H7'>{calc.useTax}</Text>
            </div>
            <div className="item">
              <Text variant='H8' color='primary_active' style={{ fontWeight: '700' }}>Tip:</Text>
              <Text variant='H7' color='primary_active' style={{ fontWeight: '700' }}>{calc.tip}</Text>
            </div>
          </Col>
        </Row>
        <Text className="border" mt={1} mb={1} />
        <Row justify={'end'} align={'middle'}>
          <Text variant='H5' mr={2} style={{ fontSize: '2.5rem' }}>Total:</Text>
          <Text variant='H3' color='primary_active' style={{ fontSize: '2.5rem' }}>{calc.total}</Text>
        </Row>
        <Text my={1} />
      </TicketDetailStyled>
      <Row justify={'center'} style={{ gap: 16 }}>
        {noReceipt && <button className='buttonCompleted no-receipt' onClick={noReceipt}>
          <div className='icon-tick'><IconTick /></div>
          <Text variant='H7' color='text_3' style={{ color: '#292C33', padding: '0 1rem' }}>No Receipt</Text>
        </button>}
        <button className='buttonCompleted' onClick={handleClose}>
          <div className='icon-tick'><IconTick /></div>
          <Text variant='H7' color='text_3' style={{ color: '#fff', padding: '0 1rem' }}>Completed</Text>
        </button>
      </Row>
    </ModalStyled>
  );
});
ConfirmTipTicket.displayName = 'ConfirmTipTicket';
export default ConfirmTipTicket;
const ModalStyled = styled(Modal)`
.buttonCompleted {
  display: flex;
  align-items: center;
  border-radius: 6px;
  height: 56px;
  background: #292C33;
  border-radius: 8px;
  overflow: hidden;
  .icon-tick {
    width: 56px;
    background: #759F50;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.no-receipt {
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    border: 1.5px solid #292C33;

  }
}
`;
const TicketDetailStyled = styled.div`
.btn-back {
  position: fixed;
  top: 0.5rem;
  right: 2.5rem;
}
.box-number {
  background: #000;
  padding: 4px 8px;
  border-radius: 4px;
  span {
    color: #fff;
  }
}
  .items {
    max-height: 50vh;
    overflow: auto;
    margin: 0 -2rem;
    padding: 0 2rem;
    margin-right: -1.5rem;
    padding-right: 1rem;
    .border-dotted {
      border: 1px dotted #86909C;
    }
    .item {
      margin-bottom: 1rem;
    }
    .service {
      margin-bottom: 0.5rem;
    }
  }
  .border {
    border: 0.75px solid #86909C;
  }
  .box-info {
    margin-bottom: 2rem;
  }
  .group-info {
    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      .H8, .H7 {
        font-size: 1.5rem;
      }
    }
  }
`;

const IconTick = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M9.72692 18C9.40676 18 9.10261 17.8661 8.8785 17.6317L4.34817 12.8936C3.88394 12.4081 3.88394 11.6045 4.34817 11.1189C4.8124 10.6334 5.58078 10.6334 6.04501 11.1189L9.72692 14.9697L17.955 6.36414C18.4192 5.87862 19.1876 5.87862 19.6518 6.36414C20.1161 6.84967 20.1161 7.6533 19.6518 8.13882L10.5753 17.6317C10.3512 17.8661 10.0471 18 9.72692 18Z" fill="#fff" />
  </svg>
);
