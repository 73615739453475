import * as React from 'react';
import { SVGProps } from 'react';
const IcApt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={80}
    height={81}
    viewBox='0 0 80 80'
    fill='none'
    {...props}
  >
    <path
      fill={props?.fill || '#346751'}
      fillRule='evenodd'
      d='M19 19.908a2.4 2.4 0 0 0-2.4 2.4v42a2.4 2.4 0 0 0 2.4 2.4h42a2.4 2.4 0 0 0 2.4-2.4v-42a2.4 2.4 0 0 0-2.4-2.4H19Zm-7.35 2.4c0-5.302 2.048-7.35 7.35-7.35h42c5.302 0 7.35 2.048 7.35 7.35v42c0 5.302-2.048 7.35-7.35 7.35H19c-5.302 0-7.35-2.048-7.35-7.35v-42Z'
      clipRule='evenodd'
    />
    <path
       fill={props?.fill || '#346751'}
      fillRule='evenodd'
      d='M52.375 8.808c1.864 0 3.375 1.322 3.375 2.953v9.844c0 1.63-1.511 2.953-3.375 2.953S49 23.236 49 21.605V11.76c0-1.631 1.511-2.953 3.375-2.953ZM27.625 8.808C29.489 8.808 31 10.13 31 11.76v9.844c0 1.63-1.511 2.953-3.375 2.953s-3.375-1.322-3.375-2.953V11.76c0-1.631 1.511-2.953 3.375-2.953ZM13 33.558c0-1.243 1.422-2.25 3.177-2.25h47.647c1.754 0 3.176 1.007 3.176 2.25 0 1.242-1.422 2.25-3.176 2.25H16.175c-1.754 0-3.176-1.008-3.176-2.25Z'
      clipRule='evenodd'
    />
  </svg>
);
export default IcApt;
