import { Col, Row, Select, Form as AntForm } from 'antd';
import { ServiceRadioIcon, ServiceRadioSelectedIcon } from 'assets/Icons/AppointmentIcons';
import Form, { FormItem } from 'components/Form';
import Modal from 'components/Modal';
import Text from 'components/Text';
import appointmentSelectors from 'features/appointment/services/selectors';
import { ICategory, IServiceCategory } from 'features/cashier/services/types/api';
import { ITurnSettingService } from 'features/settingService/services/types/reducer';
import { clone, find, first, remove } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { IServiceItemData } from 'services/shop/types/categories';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import ServiceHandler from 'widgets/ServiceHandler';


export const ModalSelectService = ({
  visible,
  onClose = () => undefined,
  services,
  setServices,
  turn, v2,
}: {
  visible?: boolean,
  onClose?: () => void,
  services: ITurnSettingService[] | undefined;
  setServices: (services: ITurnSettingService[]) => void;
  turn: 1 | 0.5 | 0 | undefined;
  v2?: boolean;
}) => {
  const [form] = AntForm.useForm();
  const categories = appointmentSelectors.getCategories();
  const [category, setCategory] = useState<ICategory | null>(null);
  const [_services, _setServices] = useState<ITurnSettingService[]>([]);
  const [lstServices, setLstServices] = useState<IServiceCategory[]>([]);
  const _preCategory = useRef<ICategory | null>(null);
  useEffect(() => {
    _setServices(services || []);
  }, [services]);

  const toggle = (ser: IServiceCategory) => {
    const exist = find(_services, (o: ITurnSettingService) => o.id === ser.id || o.serviceId === ser.id);
    if (exist) {
      remove(_services, o => o.id === ser.id || o.serviceId === ser.id);
    } else {
      _services.push({
        id: ser.id,
        serviceId: ser.id,
        serviceName: ser.serviceName,
        turn: turn ?? 0,
        // TODO: update
        amount: 0
      });
    }
    _setServices(clone(_services));
  };

  useEffect(() => {
    if (category) return;
    const _category = first(categories) || null;
    _preCategory.current = _category;
    setCategory(_category);
    setLstServices(_category?.services ?? []);
  }, [categories]);

  useEffect(() => {
    if (!visible) return;
    form.resetFields();
    if (!category) return;
    setLstServices(category?.services ?? []);
  }, [category, visible]);

  const onSubmit = () => {
    if (v2) return form.submit();
    setServices(clone(_services));
  };

  const handleSubmitFormV2 = (values: any) => {
    const data = values as { services: IServiceItemData[] };
    setServices(data.services.map((ser) => ({
      id: ser.id,
      serviceId: ser.id,
      serviceName: ser.serviceName,
      turn: turn ?? 0,
      amount: 0
    })));
    onClose();
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  // const onDebounceSearch = debounce((event:any) => {
  //   const searchText = event?.target?.value ?? '';
  //   if(searchText.trim()?.length > 0) {
  //     const searchResult = _preCategory?.current?.services?.filter(s => s.serviceName.toUpperCase().includes(searchText.trim().toUpperCase()));
  //     return setLstServices(searchResult ?? []);
  //   }
  //   setLstServices(_preCategory?.current?.services ?? []);
  // }, 300);

  return (
    <Modal
      modalTitle="SERVICES"
      visible={visible}
      onClose={handleClose}
      onSubmit={onSubmit}
      width={v2 ? '1600px' : '60rem'}
      noneBodyStyle
      noneFooterStyle={true}
      containerPadding={1.5}
      footerPadding={0.5}
      okTitle='Save'
      v2={v2}
    >
      <ChooseServicesStyled className={v2 ? 'v2' : ''}>
        <Form form={form} onFinish={handleSubmitFormV2} initialValues={{ services: [] }}>
          {v2 ? <FormItem noStyle name={'services'}><ServiceHandler /></FormItem> : <>
            <FormItem label="Catagories">
              <Select
                optionLabelProp="label"
                value={category?.id}
                onChange={id => {
                  const act = categories.find(o => o.id === id);
                  if (!act) return;
                  setCategory(act);
                }}
              >
                {categories.map(o => {
                  const count = o.services.filter(s => !!_services.find(d => d.id === s.id || d.serviceId === s.id)).length;
                  return (
                    <Select.Option key={o.id} value={o.id} label={o.categoryName}>
                      <Row justify={'space-between'}>
                        <Text>{o.categoryName} {count ? `(${count})` : null}</Text>
                      </Row>
                    </Select.Option>
                  );
                })}
              </Select>
            </FormItem>
            {/* <Search placeholder='Search' suffix={<Icon type='search' />} onChange={onDebounceSearch} /> */}
            <ListService>
              <Row gutter={[16, 16]}>
                {lstServices.map(o => (
                  <ServiceItem
                    key={o.id}
                    selected={!!_services.find(ser => ser.id === o.id || ser.serviceId === o.id)}
                    name={o.serviceName}
                    duration={o.duration}
                    onClick={() => toggle(o)}
                    price={o.priceSell}
                  />
                ))}
              </Row>
            </ListService>
          </>}
        </Form>
      </ChooseServicesStyled>
    </Modal>
  );
};

type ChooseServicesStyledProps = {}
const ChooseServicesStyled = styled.div<ChooseServicesStyledProps>`
  min-height: 40rem;
  &.v2 {
    zoom: 1.5;
  }
`;

const ListService = styled.div`
  .service-item {
    border-radius: 5px;
    border: 1px solid var(--line-line-3, #86909C);
    background: #FFF;
    display: flex;
    padding: 0.5rem 1rem;
    justify-content: space-between;
    align-items: center;
    &:hover {
      cursor: pointer;
      border: 1px solid #86909C !important;
      background-color: #E5E6EB !important;
    }
  }
`;

const ServiceItem = ({
  duration,
  name,
  onClick = () => undefined,
  selected, price }: { onClick?: () => void, name?: string, duration?: number, price?: number, selected?: boolean }) => (
  <Col span={6} onClick={onClick}>
    <Row className='service-item' justify={'space-between'} align={'middle'} wrap={false}>
      <Col flex={'auto'}>
        <Text variant='CAPTION_1' color='text_3' className='text-overflow' pr={0.25}>{name}</Text>
        {price ?
          <Text variant='CAPTION_1' color='text_3'>({formatCurrency(price)})</Text> :
          <Text variant='CAPTION_1' color='text_3'>({duration}m)</Text>}
      </Col>
      <Col>
        {selected ? <ServiceRadioSelectedIcon /> : <ServiceRadioIcon />}
      </Col>
    </Row>
  </Col>
);
