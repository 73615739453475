import React from 'react';
import styled from 'styled-components';
import DaySchedule from './DaySchedule';
type ISchedulerTableProps = {
  v2?: boolean;
};
const SchedulerTable: React.FC<ISchedulerTableProps> = ({ v2 }) => {
  return (
    <SchedulerTableStyled className={v2 ? 'v2' : ''}>
      <RowScheduleStyled className='RowScheduleStyled'>
        <DaySchedule
          day="Monday"
          enable="enableMon"
          start="monStartTime"
          end="monEndTime"
          v2={v2}
        />
        <DaySchedule
          day="Tuesday"
          enable="enableTue"
          start="tueStartTime"
          end="tueEndTime"
          v2={v2}
        />
        <DaySchedule
          day="Wednesday"
          enable="enableWed"
          start="wedStartTime"
          end="wedEndTime"
          v2={v2}
        />
        <DaySchedule
          day="Thursday"
          enable="enableThu"
          start="thuStartTime"
          end="thuEndTime"
          v2={v2}
        />
        <DaySchedule
          day="Friday"
          enable="enableFri"
          start="friStartTime"
          end="friEndTime"
          v2={v2}
        />
        <DaySchedule
          day="Saturday"
          enable="enableSat"
          start="satStartTime"
          end="satEndTime"
          v2={v2}
        />
        <DaySchedule
          day="Sunday"
          enable="enableSun"
          start="sunStartTime"
          end="sunEndTime"
          v2={v2}
        />
      </RowScheduleStyled>
    </SchedulerTableStyled>
  );
};

export default SchedulerTable;
const SchedulerTableStyled = styled.div`
  margin-top: 1rem;
&.v2 {
  .RowScheduleStyled {
    border: none;
    border-radius: 0;
    box-shadow: none;
    .item-schedule {
      border-right-color: #E9EDF2;
      .header {
        background: #CBD5E1;
        .ant-checkbox-wrapper {
          align-items: center;
        }
        .H9 {
          color: #1D2129;
          text-align: center;
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 111.111% */
          text-transform: capitalize;
        }
      }
    }
  }

}
`;
const RowScheduleStyled = styled.div`
  border-radius: 5px;
  border: 1px solid var(--line-line-3, #86909c);
  background: var(--fill-fill-0, #fff);
  display: flex;
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  .header {
    border-bottom: 1px solid var(--line-line-3, #c9cdd4);
  }
  .item-schedule {
    width: 100%;
    border-right: 1px solid var(--line-line-3, #c9cdd4);
    input {
      text-align: center;
    }
  }
  .item-schedule:first-child .header {
    border-top-left-radius: 5px;
  }
  .item-schedule:last-child .header {
    border-top-right-radius: 5px;
  }
  .item-schedule:last-child {
    border-right: none;
  }
`;
