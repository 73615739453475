import React, { useEffect } from 'react';
import { Col, Form, InputNumber, Radio, Row, Switch } from 'antd';
import Button from 'components/Button';
import Text from 'components/Text';
import { styled } from 'styled-components';
import RangeSaleInput from './StaffSalary/RangeSaleInput';
import SalaryPerTypes from './StaffSalary/SalaryPerTypes';
import PayrollTipOptions from './StaffSalary/PayrollTipOptions';
import ButtonGroup from './ButtonGroup';
import {
  ESalaryMode,
  initSalaryInfo,
} from 'features/settingStaff/services/constants';
import Box from 'components/Box';
import colorTheme from 'constants/color';
import settingStaffSelectors from 'features/settingStaff/services/selectors';
import { ISalaryConfig } from 'features/settingStaff/services/types/staff';
import { useAppDispatch } from 'store/hooks';
import settingStaffActions from 'features/settingStaff/services/actions';
import CurrencyInputPrecision from 'components/CurrencyInputPrecision';
type Props = {
  v2?: boolean;
};
const StaffSalary: React.FC<Props> = ({ v2 }) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const staffSelectedSalary =
    settingStaffSelectors.selectedStaffData.dataSalary();

  const salaryMode = Form.useWatch('salaryType', form) as ESalaryMode;
  const onFinish = async (values: ISalaryConfig) => {
    const payload = {
      ...values,
      payrollTypeCash: +values.payrollTypeCash,
      payrollTypeCheck: +values.payrollTypeCheck,
      staffId: staffSelectedSalary.staffId,
    };
    dispatch(settingStaffActions.staffUpdateSalary.fetch(payload));
  };

  useEffect(() => {
    if (staffSelectedSalary) {
      form.setFieldsValue({
        ...staffSelectedSalary,
        tipFeeType: staffSelectedSalary.tipFeeType || 'PERCENT',
        tipFeeValue: staffSelectedSalary.tipFeeValue || 0,
        enableMinComm: staffSelectedSalary.enableMinComm || false,
        minCommValue: staffSelectedSalary.minCommValue || 0,
      });
    }
  }, [staffSelectedSalary]);
  useEffect(() => {
    if (staffSelectedSalary) {
      if (salaryMode === ESalaryMode.HOURS) {
        form.setFieldsValue({ hourlyValue: staffSelectedSalary.hourlyValue });
      }
      if (salaryMode === ESalaryMode.COMMISSION_ACCELERATE) {
        form.setFieldsValue({
          salaryAccelerateOption: staffSelectedSalary.salaryAccelerateOption,
        });
      }
    }
  }, [salaryMode]);

  return (
    <SalaryTabStyled className={v2 ? 'v2' : ''}>
      <Form
        onFinish={onFinish}
        initialValues={initSalaryInfo}
        autoComplete="off"
        layout="vertical"
        form={form}
      >
        <Form.Item name={'salaryType'} label={'Salary'}>
          <Radio.Group>
            <Radio value={ESalaryMode.COMMISSION}>By Commission</Radio>
            <Radio value={ESalaryMode.HOURS}>By Hourly</Radio>
            <Radio value={ESalaryMode.COMMISSION_ACCELERATE}>
              Commission Accelerate
            </Radio>
          </Radio.Group>
        </Form.Item>
        {salaryMode === ESalaryMode.COMMISSION && (
          <Row align={'middle'} style={v2 ? { alignSelf: 'stretch', margin: 0 } : { width: '50%' }} gutter={[16, 16]}>
            <Col flex={1}>
              <Form.Item
                name="commission"
                label="Commission (%)"
                rules={[{ required: true, message: 'Missing commission' }]}
              >
                <InputNumberStyled
                  min={0}
                  max={100}
                  formatter={(value: any) => `${value}%`}
                  parser={(value: any) => value?.replace('%', '')}
                />
              </Form.Item>

            </Col>
            <CommValueLabelCol flex={1}>
              <Form.Item
                name="minCommValue"
                labelCol={{ className: 'minCommValueLabel' }}
                label={<>
                  <Form.Item noStyle name={'enableMinComm'} fieldId='checked' valuePropName='checked'>
                    <Switch checkedChildren="ON" unCheckedChildren="OFF" />
                  </Form.Item>
                  <Text mr={2} />
                  Minimum Paid By Weekly
                </>}
              >
                <CurrencyInputPrecision height={'3rem'} />
              </Form.Item>
            </CommValueLabelCol>
          </Row>
        )}
        {salaryMode === ESalaryMode.HOURS && (
          <Form.Item name="hourlyValue" label="Hourly">
            <InputNumberStyled
              formatter={(value: any) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value: any) => value?.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
        )}
        {salaryMode === ESalaryMode.COMMISSION_ACCELERATE && (
          <Box>
            <HeaderItemBySalaryMode className='HeaderItemBySalaryMode'>
              <Text variant="H7" color="text_3">
                Commission Accelerate
              </Text>
            </HeaderItemBySalaryMode>
            <BodyItemBySalaryMode className='BodyItemBySalaryMode'>
              <Box display="flex" flexDirection="column" gap="3">
                <Form.List name="salaryAccelerateOption">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field) => (
                        <RangeSaleInput
                          field={field}
                          remove={remove}
                          key={field.key}
                        />
                      ))}
                      <Box px="2">
                        <Button
                          ntype="LIGHT_BLUE"
                          onClick={() => add()}
                          icon="addPlus"
                        >
                          Add lever
                        </Button>
                      </Box>
                    </>
                  )}
                </Form.List>
              </Box>
            </BodyItemBySalaryMode>
          </Box>
        )}
        <Box mt="3">
          <HeaderItemBySalaryMode className='HeaderItemBySalaryMode'>
            <Text variant="H7" color="text_3">
              Salary Per Types
            </Text>
          </HeaderItemBySalaryMode>
          <SalaryPerTypes />
        </Box>

        <Box mt="3">
          <HeaderItemBySalaryMode className='HeaderItemBySalaryMode'>
            <Text variant="H7" color="text_3">
              Payroll Tip Options
            </Text>
          </HeaderItemBySalaryMode>
          <PayrollTipOptions />
        </Box>
        <Box mt="3">
          <ButtonGroup />
        </Box>
      </Form>
    </SalaryTabStyled>
  );
};

const SalaryTabStyled = styled.div`
  border-radius: 5px;
  border: 1px solid ${colorTheme.line_3};
  background: #fff;
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1.5rem 4rem;
  .ant-radio-checked .ant-radio-inner {
    background: linear-gradient(to bottom, #548088, #6faab6);
    border-color: #6faab6;
  }

&.v2 {
  padding: 0px;
  border: none;
  box-shadow: none;
  background: transparent;

  .HeaderItemBySalaryMode {
    background: #E5E6EB;
  }
  .BodyItemBySalaryMode, .PayrollTipOptionsStyled, .SalaryPerTypesStyled {
    background: #F6F7FC;
    box-shadow: none;
  }
}
`;

export default StaffSalary;

const HeaderItemBySalaryMode = styled.div`
  background: var(--fill-fill-2, #adadad);
  padding: 1rem;
`;
const BodyItemBySalaryMode = styled.div`
  background: var(--fill-fill-1, #f6f7fc);
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem;
`;

const InputNumberStyled = styled(InputNumber)`
  height: 3rem;
  width: 100%;
  .ant-input-number-input {
    height: 3rem;
    padding: 0 0.75rem;
  }
`;
const CommValueLabelCol = styled(Col)`
  .minCommValueLabel {
    label {
      width: 100%;
    }
  }
`;