import { Form, Input } from 'antd';
import cashierApis from 'features/cashier/services/apis';
import { IGiftCardDetail } from 'features/cashier/services/types/giftCard';
import React from 'react';
import { IResponseDataBody } from 'services/response';
import styled from 'styled-components';
type Props = {
  value?: string;
  onChange?: (val: string) => void;
  setLoading?: (val: boolean) => void;
};
const InputGiftCard = ({ value, onChange = () => undefined, setLoading = () => undefined }: Props) => {
  const form = Form.useFormInstance();
  const onChangeValue: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange(e.target.value);
    form.validateFields(['giftNumber']);
    form.resetFields(['giftNumberValid']);
  };
  return (
    <Container className="input-selector-container">
      <Input
        inputMode='none'
        value={value}
        placeholder='Please enter gift number'
        className='gift-card-number-input' size='small'
        onChange={onChangeValue}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
      />
      <Form.Item noStyle name={'giftNumberValid'} rules={[
        ({ getFieldValue }) => ({
          async validator() {
            const code = getFieldValue('giftNumber');
            const value = code?.trim() || '';
            if (!value) return Promise.reject('Please enter gift number');
            if (value?.length < 4) return Promise.reject('Gift number code is at least 4 letters long');
            setLoading(true);
            try {
              const res: IResponseDataBody<IGiftCardDetail> = await cashierApis.getGiftCardInfo(value);
              if (res.data.data) return Promise.reject('Gift number exist, please try again');
            } catch (error) { } finally {
              setLoading(false);
            }
            return Promise.resolve();
          }
        }),
      ]} />
    </Container>
  );
};

export default InputGiftCard;

const Container = styled.div`
&.input-selector-container {
  display: flex;
  align-items: center;
  position: relative;
  height: 3.5rem;
  
  .qr-scan-icon {
    width: 64px;
    height: 64px;
  }
  &.dollar-input {
    justify-content: center;
  }
}
.gift-card-number-input {
  color: #1D2129;
  font-family: Poppins;
  align-self: stretch;
  font-size: 22px;
  height: unset;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0 11px;
  &::placeholder {
    color: #64748B;
    opacity: 1; /* Firefox */
  }

  &::-ms-input-placeholder { /* Edge 12-18 */
    color: #64748B;
  }
  &.fs-big {
    font-size: 55px;
  }
  &.fs-medium {
    font-size: 45px;
  }
}
`;