import Button from 'components/Button';
import Text from 'components/Text';
import moment, { Moment } from 'moment';
import React from 'react';
import ModalAddCloseTicket, { IAddCloseTicketFormValue, useModalAddCloseTicket } from './ModalAddCloseTicket';
import { IAddTicketManualBody } from 'features/ticketList/services/types/ticket';
import { IResponseDataBody } from 'services/response';
import ticketListApis from 'features/ticketList/services/apis';
import toast from 'utils/toast';
import ticketListSelectors from 'features/ticketList/services/selectors';
import { FeatureVerify, OTPVerificationRef } from 'widgets/OTPVerification';
import { useAppDispatch } from 'store/hooks';
import { ticketListUIActions } from 'features/ticketList/services/reducers';
type Props = {
  mutate?: (date: Moment) => void;
  v2?: boolean;
};
export const useAddCloseTicket = ({ mutate = () => undefined }: Props) => {
  const dispatch = useAppDispatch();
  const modal = useModalAddCloseTicket();
  const isVerifyKeyIn = ticketListSelectors.isVerifyKeyIn();
  const onOpen = () => {
    if (!isVerifyKeyIn && OTPVerificationRef.current) {
      return OTPVerificationRef.current.openActiveSalon(() => {
        dispatch(ticketListUIActions.setIsVerifyKeyIn(true));
        modal.current?.open();
      }, FeatureVerify.REPORT_SALARY);
    }
    return modal.current?.open();
  };
  const onAdd = async (values: IAddCloseTicketFormValue, done?: boolean) => {
    const { date: formDate, customer, last4Digit, discount = 0, cardFee, paymentType, staffs } = values as IAddCloseTicketFormValue;
    const date = formDate ? formDate : moment();
    const body: IAddTicketManualBody = {
      createDate: date.format('MM-DD-YYYY'),
      note: '',
      customerId: customer?.id ? customer?.id !== 'unknownCustomer' ? customer?.id : '' : '',
      discount,
      feeCreditCard: cardFee,
      last4Digit,
      paymentType,
      items: staffs.map(o => ({
        staffId: o.id || '',
        amount: o.subTotal || 0,
        discountItem: o.discount || 0,
        supply: o.supplyFee || 0,
        tip: o.tip || 0,
      })),
    };
    try {
      const res: IResponseDataBody<boolean> = await ticketListApis.addTicketManual(body);
      if (res.data.data) {
        mutate(moment(date.valueOf()));
        toast.success('Successful');
        if (done) {
          modal.current?.close();
        } else {
          modal.current?.open();
        }
        return false;
      }
      return false;
    } catch (error) {
      return false;
    }
  };
  return ({
    modal, onAdd, onOpen
  });
};
const AddCloseTicket = (props: Props) => {
  const { onAdd: handleAddCloseTicket, modal, onOpen } = useAddCloseTicket(props);
  return (
    <>
      {props.v2 ?
        <Button htmlType='button' cardCashier icon='calculatorBtn' ntype="LIGHT_BLUE" onClick={onOpen}>
          <Text color="text_3" variant="CONTENT_2">
            Key In
          </Text>
        </Button>
        :
        <Button htmlType='button' icon='addPlus' ntype="PRIMARY" onClick={onOpen}>
          <Text color="text_3" variant="CONTENT_2">
            Key-In
          </Text>
        </Button>
      }
      <ModalAddCloseTicket
        ref={modal}
        v2={props.v2}
        onSubmit={handleAddCloseTicket}
      />
    </>
  );
};

export default AddCloseTicket;
