import { Checkbox, Form } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import DollarInputPayment from 'components/DollarAmount/DollarInputPayment';
import Text from 'components/Text';
import settingServiceActions from 'features/settingService/services/actions';
import { IUpdateTurnSettingTicket } from 'features/settingService/services/types/api';
import { ITurnSettingTicket } from 'features/settingService/services/types/reducer';
import { FC, useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
interface ITurnTicket {
    data?: ITurnSettingTicket;
    v2?: boolean;
}
const TurnTicket: FC<ITurnTicket> = ({ data, v2 }) => {
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    useEffect(() => {
        form.setFieldsValue({
            amount: data?.amount ?? 0,
            continuousByTotal: data?.continuousByTotal ?? false
        });
    }, [data]);
    const onFinish = (values: ITurnSettingTicket) => {
        const payload: IUpdateTurnSettingTicket = {
            amount: values.amount,
            continuousByTotal: values.continuousByTotal
        };
        dispatch(settingServiceActions.updateTurnSettingTiket.fetch(payload));
    };
    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={{ staff: 0, company: 0 }}
            onFinish={onFinish}
        >
            <Box bgColor={'fill_1'} style={v2 ? { background: '#fff' } : {}} p="3">
                <Box>
                    <ItemFormStyled>
                        <FormItemStyled name={'amount'} label="Amount" rules={[{ required: true, message: 'Missing Amount' }]}>
                            <DollarInputPayment />
                        </FormItemStyled>
                    </ItemFormStyled>
                    <ItemFormStyled className='group-checkbox'>
                        <FormItemStyled name={'continuousByTotal'} valuePropName="checked">
                            <Checkbox>Continuous by total</Checkbox>
                        </FormItemStyled>
                        <ExamBox className='ExamBox'>
                            <Text variant='CONTENT_1' color='text_2'>Ex:  $25 = 1 turn</Text>
                            <Text variant='CONTENT_1' color='text_2'>$100 = 4 turns</Text>
                        </ExamBox>
                    </ItemFormStyled>
                </Box>
            </Box>
            <Box className='actions-bottom' display="flex" gap="3" mt="4" justifyContent="end">
                <Button width="10rem" ntype="SECONDARY">
                    Close
                </Button>
                <Form.Item>
                    <Button width="10rem" ntype="PRIMARY" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Box>
        </Form>
    );
};
export default TurnTicket;
const ItemFormStyled = styled.div`
    width: 100%;
`;

const FormItemStyled = styled(Form.Item)`
    margin: 0;
`;

const ExamBox = styled(Box)`
    padding-left: 24px;
`;
