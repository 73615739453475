import React from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import styled from 'styled-components';
import Box from 'components/Box';
import Text from 'components/Text';
import CurrencyInputPrecision from 'components/CurrencyInputPrecision';
import PercentageInputPrecision from 'components/PercentageInputPrecision';
enum TYPE_TIP_FEE {
  PERCENT = 'PERCENT',
  MONEY = 'MONEY'
}

type IPayrollTipOptionsProps = {};
const PayrollTipOptions: React.FC<IPayrollTipOptionsProps> = () => {
  const form = Form.useFormInstance();
  const tipFeeType = Form.useWatch('tipFeeType', form);
 
  const handleCashChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let cashValue = Number(e.target.value);
    if (cashValue > 100) cashValue = 100; // Limiting max value to 100
    const checkValue = 100 - cashValue;
    form.setFieldsValue({
      payrollTypeCheck: checkValue,
    });
  };
  const handleCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let checkValue = Number(e.target.value);
    if (checkValue > 100) checkValue = 100; // Limiting max value to 100
    const cashValue = 100 - checkValue;
    form.setFieldsValue({
      payrollTypeCash: cashValue,
    });
  };

  return (
    <PayrollTipOptionsStyled className='PayrollTipOptionsStyled'>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Form.Item
            name="payrollTypeCash"
            label={
              <Box display="flex" gap="1">
                % Cash<Text color="error_6">(*)</Text>
              </Box>
            }
            rules={[
              {
                required: true,
                message: 'Missing cash',
              },
              () => ({
                validator(_, value) {
                  if (!value || (value >= 0 && value <= 100)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('Cash should be between 0 and 100')
                  );
                },
              }),
            ]}
          >
            <Input type="number" onChange={handleCashChange} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="payrollTypeCheck"
            label={
              <Box display="flex" gap="1">
                % Check<Text color="error_6">(*)</Text>
              </Box>
            }
            rules={[
              {
                required: true,
                message: 'Missing check',
              },
              () => ({
                validator(_, value) {
                  if (!value || (value >= 0 && value <= 100)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('Check should be between 0 and 100')
                  );
                },
              }),
            ]}
          >
            <Input type="number" onChange={handleCheckChange} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Tip Fee">
            <FormGroup>
              <Form.Item name={'tipFeeType'} noStyle>
                <Select defaultValue={TYPE_TIP_FEE.PERCENT}>
                  <Select.Option value={TYPE_TIP_FEE.PERCENT}>PERCENT</Select.Option>
                  <Select.Option value={TYPE_TIP_FEE.MONEY}>DOLLAR</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name={'tipFeeValue'} style={{ height: '100%', flex: 1 }} noStyle>
                {tipFeeType === TYPE_TIP_FEE.PERCENT ? <PercentageInputPrecision noStyle name={'tipFeeValue'} height={'3rem'} /> : <CurrencyInputPrecision height={'3rem'} />}
              </Form.Item>
            </FormGroup>
          </Form.Item>
        </Col>
      </Row>
    </PayrollTipOptionsStyled>
  );
};

export default PayrollTipOptions;
const PayrollTipOptionsStyled = styled.div`
  padding: 1rem;
  background: var(--fill-fill-1, #f6f7fc);
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  .ant-select {
    height: 3rem;
  }
`;

const FormGroup = styled.div`
  display: flex;
  .ant-select {
    height: 3rem;
    width: 10rem;  
    .ant-select-selector {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-width: 0.5px;
    }
    
  }
  .ant-form-item {
    margin-bottom: 0;
    .ant-form-item-control-input {
      min-height: unset;
    }
    .ant-input-number {
      height: 3rem;
      .ant-input-number-handler-wrap {
        display: none;
      }
    }
    .ant-input-number-input-wrap input {
      text-align: left;
      padding-left: 1rem;
    }
    .ant-input, .ant-input-suffix {
      text-align: left;
      padding-left: 1rem;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left-width: 0.5px;
    }
  }

`;