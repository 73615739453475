import React, { useRef } from 'react';
import styled from 'styled-components';
import { Form, InputRef, Select, Space } from 'antd';
import CurrencyInputPrecision from 'components/CurrencyInputPrecision';
import PercentageInputPrecision from 'components/PercentageInputPrecision';
import clsx from 'clsx';
import PercentDecimalInputPrecision from 'components/PercentDecimalInputPrecision';
import { DISCOUNT_VALUE_TYPE } from 'features/settingService/services/constants';
type IAmountFormItemProps = {
  name: number;
  v2?: boolean;
};
const AmountFormItem: React.FC<IAmountFormItemProps> = ({ name, v2 }) => {
  const form = Form.useFormInstance();
  const special_discount = Form.useWatch('special_discount', form);
  const discountType = special_discount?.[name]?.discountType;
  const inputPerRef = useRef<InputRef>(null);
  const inputDolRef = useRef<InputRef>(null);
  const handleChangeType = (e: DISCOUNT_VALUE_TYPE) => {
    setTimeout(() => {
      if (e === DISCOUNT_VALUE_TYPE.DOLLAR) {
        inputDolRef.current?.focus();
      } else {
        inputPerRef.current?.focus();
      }
    }, 100);
  };

  if (v2) {
    return (
      <DiscountWrapper >
        <Form.Item noStyle name={[name, 'discountType']}>
          <Select placeholder='%' options={[
            { label: 'Percent', value: 'PERCENT' },
            { label: 'Money', value: 'MONEY' },
          ]}
            style={{ width: '15rem' }}
            onChange={handleChangeType}
          />
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const discountType = getFieldValue(['special_discount', name, 'discountType']);
            return (
              <Form.Item noStyle name={[name, 'discountValue']}>
                {(discountType === 'PERCENT') ?
                  <PercentDecimalInputPrecision maxValue={100} inputRef={inputPerRef} height={'3.5rem'} />
                  : <CurrencyInputPrecision className='CurrencyInputPrecision' inputRef={inputDolRef} height={'3.5rem'} />}
              </Form.Item>
            );
          }}
        </Form.Item>
      </DiscountWrapper>
    );
  }

  return (
    <AmountFormItemStyled block className={clsx('AmountFormItemStyled', v2 && 'v2')}>
      {discountType === 'MONEY' && (
        <div style={{ width: '50%' }}>
          <FormItemStyled name={[name, 'discountValue']} noStyle>
            <CurrencyInputPrecision
              height="calc(4rem - 2px)"
              bordered={false}
            />
          </FormItemStyled>
        </div>
      )}

      {discountType === 'PERCENT' && (
        <div style={{ width: '50%' }}>
          <PercentageInputPrecision
            height="calc(4rem - 2px)"
            size="large"
            name={[name, 'discountValue']}
            bordered={false}
            noStyle
          />
        </div>
      )}
      <Form.Item
        name={[name, 'discountType']}
        style={{
          width: '50%',
          margin: 0,
        }}
      >
        <Select
          bordered={false}
          style={{
            backgroundColor: '#E5E6EB',
            borderRadius: '5px',
            height: '100%',
            flexGrow: 1,
            width: '100%',
          }}
        >
          <Select.Option value="MONEY">Dollar</Select.Option>
          <Select.Option value="PERCENT">Percent</Select.Option>
        </Select>
      </Form.Item>
    </AmountFormItemStyled>
  );
};

export default AmountFormItem;
const AmountFormItemStyled = styled(Space.Compact)`
  display: flex;
  height: calc(100%);
  border: 1px solid var(--line-line-3, #86909c);
  border-radius: 5px;
  box-sizing: border-box;
  .ant-row {
    height: 100%;
  }
  .ant-form-item-control-input {
    height: 100%;
  }
  .ant-form-item-control-input-content {
    height: 100%;
  }
`;
const FormItemStyled = styled(Form.Item)`
  margin: 0;
`;

const DiscountWrapper = styled.div`
display: flex;
align-items: center;
.ant-select {
  height: 3.5rem;
  width: 10rem;
}
input, .ant-input {
  text-align: right;
}
`;
