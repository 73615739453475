import Sidebar from 'components/layout/Sidebar';
import colorTheme from 'constants/color';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import ticketListActions from 'features/ticketList/services/actions';
import { IApiGetTicketsParam } from 'features/ticketList/services/types/api';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import HeaderTickets from './HeaderTickets';
import ListTicketPageStyled from '../ListTicketPage/styles';
import TabTicketByParams from './TabTicketByParams';
import Text from 'components/Text';
import shopSelectors from 'services/shop/selectors';
import TicketFilterV2 from '../ListTicketPage/components/TabsV2/Filter';
type IQuickViewTicketsProps = any;
const QuickViewTickets: React.FC<IQuickViewTicketsProps> = () => {
  const dispatch = useAppDispatch();
  const versionUI = shopSelectors.data.versionUI();
  const v2 = versionUI === 'V2';
  useEffect(() => {
    dispatch(ticketListActions.doInit());
    const params: IApiGetTicketsParam = {
      status: 'CLOSED',
      page: 1,
      size: 10,
      startDate: moment().format(DATE_FORMAT),
      endDate: moment().format(DATE_FORMAT),
      staffId: '',
    };
    dispatch(ticketListActions.setVisibleCusPhone(true));
    dispatch(ticketListActions.setTicketsParams(params));
  }, []);

  return (
    <ListTicketPageStyled>
      <Sidebar />
      <BodyStyled className={v2 ? 'v2' : ''}>
        {v2 ? <TicketFilterV2 quickView type='CLOSED' /> : <HeaderTickets />}
        <Text mb={2} />
        <div className="container-scroll">
          <TabTicketByParams v2={v2} tableKey={'CLOSED'} />
        </div>
      </BodyStyled>
    </ListTicketPageStyled>
  );
};

const BodyStyled = styled.div`
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
  background-color: ${colorTheme.fill_3};
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  &.v2 {
    height: calc(100vh - 32px);
    padding: 16px;
    background: #f5f5f5;
  }

  .container-scroll {
    flex: 1;
    position: relative;
  }
`;

export default QuickViewTickets;
