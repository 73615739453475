import { Button as AntButton, Empty, RefSelectProps, Select } from 'antd';
import clsx from 'clsx';
import Box from 'components/Box';
import Spin from 'components/Spin';
import appointmentSelectors from 'features/appointment/services/selectors';
import { find, first, remove, unionBy } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ADD_ON_CATE_ID } from 'services/shop/constants';
import shopSelectors from 'services/shop/selectors';
import { ICategoryItemData, IServiceItemData } from 'services/shop/types/categories';
import styled from 'styled-components';
import icon_check from './check.svg';

type Props = {
  value?: IServiceItemData[], onChange?: (val: IServiceItemData[]) => void;
};

const ServiceHandler = ({ value = [], onChange = () => undefined }: Props) => {
  const loading = appointmentSelectors.staffCategoriesLoading();
  const _categories = appointmentSelectors.staffCategories();
  const _shopCategories = shopSelectors.data.categories();
  const addonServices = shopSelectors.data.addonServices();
  const shopCategories = useMemo(() => {
    const categoryAddon: ICategoryItemData = {
      categoryName: 'ADD-ON',
      id: ADD_ON_CATE_ID,
      services: addonServices.map(o => ({
        ...o,
        catId: ADD_ON_CATE_ID
      })),
    };
    return unionBy(_shopCategories, [categoryAddon], 'id') ?? [];
  }, [_shopCategories, addonServices]);

  const category = useMemo(() => _categories.length ? _categories : shopCategories, [_categories, shopCategories]);

  useEffect(() => { setLastedOption(first(category)?.id || ''); }, [category]);

  const [lastedOption, setLastedOption] = useState('');
  const selectRef = useRef<RefSelectProps>(null);

  const options = useMemo(() => category.map(o => ({
    label: o.categoryName,
    value: o.id,
    id: o.id,
    optionDetails: o.services,
  })), [category]);


  const optionDetails = useMemo(() => options.find(o => o.id === lastedOption)?.optionDetails || [], [lastedOption, options]);
  const onRemove = (_value: string): React.MouseEventHandler<HTMLElement> => (e) => {
    e.preventDefault(); e.stopPropagation();
    const temp = [...value];
    remove(temp, o => o.id === _value);
    onChange(temp);
  };

  const onSelectOption = (_value: any) => {
    setLastedOption(_value);
  };
  const onSelectSubOption = (item: IServiceItemData) => {
    const temp = [...value];
    const idx = temp.findIndex(o => o.id === item.id);
    if (idx !== -1) {
      temp.splice(idx, 1);
    } else {
      temp.push(item);
    }
    onChange(temp);
  };

  const values = useMemo(() => value.map(o => ({ label: o.serviceName, value: o.id })), [value]);

  if (loading) return <Box display='flex' width={'100%'} justifyContent='center' alignItems='center'><Spin /></Box>;

  return (
    <Container>
      <Select
        mode="tags"
        style={{ width: '100%' }}
        placeholder="Enter your services"
        options={options}
        open={false}
        ref={selectRef}
        value={values}
        notFoundContent={<Empty />}
        // onSelect={onTypingOption}
        tagRender={(item) => {
          return (<AntButton className='tag-render-item' onClick={onRemove(item.value)}><span>{item.label}</span><IconClose /></AntButton>);
        }}
      />
      <Box mt='1' display='flex' gap='4' flexWrap='wrap' className='tag-suggestions categories' style={{ gap: '0px', margin: '0 0.75px' }}>
        {options.map(o => (
          <button type='button' onClick={() => onSelectOption(o.id)} key={o.value} className={`tag-item category ${lastedOption === o.id ? 'active' : ''}`}>
            <span>{o.label}</span>
          </button>
        ))}
      </Box>
      <Box mt='1' display='flex' gap='4' flexWrap='wrap' className='tag-suggestions services'>
        {optionDetails.map(o => {
          const selected = !!find(value, s => s.id === o.id);
          return (
            <button type='button' onClick={() => onSelectSubOption(o)} key={o.id} className={clsx('tag-item sub-tag', selected && 'selected')}>
              <span color="#C5DDF8">{o.serviceName}</span>
              {selected && <img src={icon_check} alt='icon_check' style={{ width: 32, height: 32 }} />}
            </button>
          );
        })}
      </Box>
    </Container>
  );
};

export default ServiceHandler;
const Container = styled.div`
align-self: stretch;
.ant-select-multiple {
  min-height: 64px;
  width: 100%;
  height: auto;

  .ant-select-arrow {
    display: none;
  }

  .ant-select-selector {
    padding: 12px;
    .ant-select-selection-search {
    width: 150px !important;
    }
    .ant-select-selection-search-input {
      color: #1D2129;
      font-family: Poppins;
      font-size: 22px;
      font-weight: 400;
      line-height: normal;
    }
    .ant-select-selection-overflow {
      gap: 12px;

      .ant-select-selection-overflow-item {
        .tag-render-item {
          display: flex;
          padding: 0;
          border: 1px solid #CECECE;
          padding: 2px 8px;
          height: 42px;
          gap: 8px;
          align-items: center;

          span {
            overflow: hidden;
            color: #1D2129;
            text-overflow: ellipsis;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
  }
}


.tag-suggestions {
  gap: 12px;
  &.categories {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  &.services {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .tag-item {
    border-radius: 100px;
    background: #fff;
    display: flex;
    padding: 12px 18px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;

    span {
      text-align: center;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }

    &.category {
      border-radius: 0;
      position: relative;
      z-index: 1;
      background: rgb(252, 252, 253);
      box-shadow: rgba(44, 35, 66, 0.4) 0px 2px 4px 0px, rgba(45, 35, 66, 0.3) 0px 4px 8px -3px, rgb(214, 214, 231) 0px -3px 0px 0px inset;
      display: flex;
      height: 60px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding: 0px 16px;
      span {
        color: rgb(80, 80, 80);
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        text-transform: capitalize;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }
    }

    &.sub-tag {
      background: rgb(246, 247, 252);
      border: 1px solid rgb(134, 144, 156);
      display: flex;
      height: 60px;
      justify-content: space-between;
      align-items: center;
      flex: 1 0 0px;
      padding: 0px 16px;
      border-radius: 4px;
      position: relative;
      box-shadow: rgba(0, 0, 0, 0.25) 0px -4px 4px 0px inset, rgba(255, 255, 255, 0.25) 0px 8px 4px 3px inset;
      span {
        color: rgb(29, 33, 41);
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
      }

      &.selected {
        border-radius: 4px;
        border: 1px solid #F5767F;
        background: #F6F7FC;
        box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.25) inset, 0px 8px 4px 3px rgba(255, 255, 255, 0.25) inset;
      }
    }

    &.active {
       &.category {
        z-index: 2;
        background: rgb(45, 103, 114);
        box-shadow: rgba(44, 35, 66, 0.4) 0px 2px 4px 0px, rgba(45, 35, 66, 0.3) 0px 4px 8px -3px, rgb(18, 63, 71) 0px -3px 0px 0px inset;
        span {
          color: #fff;
        }
      }
    }
  }
}

`;

const IconClose = () => (
  <div className='tag-close'>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.8186 1.18114C15.1952 1.55773 15.1952 2.16829 14.8186 2.54488L2.54491 14.8186C2.16833 15.1951 1.55776 15.1951 1.18117 14.8186C0.804584 14.442 0.804585 13.8314 1.18117 13.4548L13.4548 1.18114C13.8314 0.804552 14.442 0.804552 14.8186 1.18114Z" fill="#F05326" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1.18141 1.18114C1.558 0.804552 2.16857 0.804552 2.54515 1.18114L14.8188 13.4548C15.1954 13.8314 15.1954 14.442 14.8188 14.8186C14.4422 15.1951 13.8317 15.1951 13.4551 14.8186L1.18141 2.54488C0.804823 2.16829 0.804823 1.55773 1.18141 1.18114Z" fill="#F05326" />
    </svg>
  </div>
);
