import { Button, Form, Input, InputRef } from 'antd';
import Box from 'components/Box';
import DollarInputPayment from 'components/DollarAmount/DollarInputPayment';
import { useDollarAmountInputRef } from 'components/DollarAmount/Input';
import DollarAmountKeyboard from 'components/DollarAmount/Keyboard';
import Modal from 'components/Modal';
import NumberPadV2 from 'components/NumberKeyBoard/Currency/NumberPadV2';
import Text from 'components/Text';
import { useTicketWrapper } from 'features/ShopWrapperLayout/TicketWrapperContext';
import ConfirmTipTicket, { useConfirmTipTicketRef } from 'features/payment/components/ConfirmTipTicket';
import PaymentLoading, { IPaymentLoadingRef } from 'features/payment/components/PaymentLoading';
import paymentApis from 'features/payment/services/apis';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import { IBodyAPIPayment } from 'features/payment/services/types/api';
import { IBillDetailData } from 'features/payment/services/types/bill';
import { useSocketContext } from 'hooks/useSocket';
import { delay } from 'lodash';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { IResponseDataBody } from 'services/response';
import styled from 'styled-components';
import qrScanIcon from './QR-scan.svg';
const FormStyled = styled(Form)`
  width: 900px;
  .dollar-input-selector {
    margin: 0 16px;
    align-self: stretch;
    height: 5rem;
    border: none;
    justify-content: center;
    .dollar-input-text {
      font-size: 55px;
      color: #1D2129;
      font-weight: 600;
    }
  }
`;

interface IFormValues {
  detail: IBillDetailData;
  giftCreditNumber: string;
  amount: number;
}

type Props = {};
type Ref = {
  open: (_detailTicket: IBillDetailData) => void;
};
export const useModalGiftCreditRef = () => useRef<Ref>(null);
const ModalGiftCredit = forwardRef<Ref, Props>(({ }, ref) => {
  const socketContext = useSocketContext();
  const ticketContext = useTicketWrapper();
  const completedTicketModalRef = useConfirmTipTicketRef();
  const inputCodeRef = useRef<InputRef>(null);
  const currencyRef = useDollarAmountInputRef();
  const loadingRef = useRef<IPaymentLoadingRef>(null);
  const [form] = Form.useForm();
  const [isNumberCard, setIsNumberCard] = useState(false);
  const [detail, setDetail] = useState<IBillDetailData | null>(null);
  const onClose = () => setDetail(null);
  const onOpen: Ref['open'] = (_detail) => {
    form.resetFields();
    form.setFieldValue('detail', _detail);
    form.setFieldValue('amount', _detail.total);
    setDetail(_detail);
    delay(() => {
      currencyRef.current?.updateInputWidth();
      inputCodeRef.current?.focus();
    }, 100);
  };
  const handleFinish = async (values: any) => {
    const data = values as IFormValues;
    const billId = data.detail.billId;
    if (!billId) return;
    const body: IBodyAPIPayment = {
      billId,
      paymentInfo: [
        {
          paymentType: PAYMENT_TYPE.GIFT_CREDIT,
          amount: data.amount,
          otherCheckInfo: { checkNumber: data.giftCreditNumber } as any,
        },
      ]
    };

    loadingRef.current?.setVisible(true);
    try {
      const res: IResponseDataBody<true> = await paymentApis.payment(body);
      if (res?.data?.data) {
        loadingRef.current?.setVisible(false);
        socketContext.switchCusRating(billId);
        const callback = () => ticketContext.completedTicket(billId, true);
        if (!completedTicketModalRef.current) callback();
        else completedTicketModalRef.current.open(billId, callback);
      } else {
        loadingRef.current?.setVisible(false);
      }
    } catch (error) {
      loadingRef.current?.setVisible(false);
    }
  };

  const onScan = () => {
    form.resetFields(['giftCreditNumber']);
    setTimeout(() => { inputCodeRef.current?.focus(); }, 100);
  };
  const onKBFocus = (type: 'currency' | 'numberCard') => () => setIsNumberCard(type === 'numberCard');
  useImperativeHandle(ref, () => ({
    open: onOpen
  }));

  return (
    <Modal
      visible={!!detail}
      onClose={onClose}
      width={'auto'} footer={null} className='modal-overflow-unset modal-non-opacity'
      hiddenHeader
      noneBodyStyle
      okTitle='Redeem'
    >
      <FormStyled form={form} onFinish={handleFinish}>
        <Form.Item noStyle name={'detail'} />
        <HeaderModal>
          <div className="modal-title">gift credit</div>
        </HeaderModal>
        <GiftCardNumberContainer>
          <Text className='modal-label' mb={0.5}>
            Gift Credit Number:
          </Text>
          <div className="input-selector-container">
            <Form.Item name={'giftCreditNumber'} noStyle rules={[{ required: true, message: 'Please input gift credit number!' }]}>
              <Input
                ref={inputCodeRef}
                className='gift-card-number-input' size='small'
                onFocus={onKBFocus('numberCard')}
              />
            </Form.Item>
            <button type='button' className='btn-scan' onClick={onScan}><img src={qrScanIcon} alt="qrScanIcon" className='qr-scan-icon' /></button>
          </div>
        </GiftCardNumberContainer>
        <Box display='flex' flexDirection='column' gap='2'>
          <Text className='modal-keyboard-label' mt={1} mb={-0.5}>
            Enter Amount:
          </Text>
          <Form.Item name={'amount'} noStyle rules={[
            {
              required: true,
              message: 'Amount cannot be empty!',
            },
            {
              validator: (_, value) => {
                const total = detail?.total || 0;
                if (value > total) {
                  return Promise.reject(
                    new Error(`Amount cannot exceed the total (${total})!`)
                  );
                }
                return Promise.resolve();
              },
            },
          ]}>
            <DollarInputPayment
              ref={currencyRef}
              inputMode='none'
              onFocus={onKBFocus('currency')}
              maxValue={detail?.total}
            />
          </Form.Item>
        </Box>
        <div hidden={isNumberCard}>
          <Form.Item name={'amount'} noStyle>
            <DollarAmountKeyboard maxValue={detail?.total} controlRef={currencyRef} />
          </Form.Item>
        </div>
        <div hidden={!isNumberCard}>
          <Form.Item name={'giftCreditNumber'} noStyle>
            <NumberPadV2
              type={'NUMBER'}
              controlRef={inputCodeRef}
            />
          </Form.Item>
        </div>
        <FooterActions>
          <Button onClick={onClose} htmlType='button' type='default'><span>Close</span></Button>
          <Button htmlType='button' onClick={() => form.submit()} type='primary'><span>Redeem</span></Button>
        </FooterActions>
      </FormStyled>
      <PaymentLoading ref={loadingRef} />
      <ConfirmTipTicket ref={completedTicketModalRef} />
    </Modal>
  );
});
ModalGiftCredit.displayName = 'ModalGiftCredit';
export default ModalGiftCredit;
const HeaderModal = styled.div`
 border-radius: 5px 5px 0px 0px;
  background: #232F3E;
  padding: 12px 0;
  height: auto;
  .modal-title {
    color: #FFF;
    background: #232F3E;
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
`;
const GiftCardNumberContainer = styled.div`
margin-bottom: 1rem;
margin-top: 1rem;
display: flex;
flex-direction: column;  
.modal-label {
  color: #64748B;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.modal-warning {
  color: #ff7d00;
  text-align: center;
  font-family: Poppins;
  align-self: stretch;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.input-selector-container {
  display: flex;
  align-items: center;
  position: relative;
  height: 64px;
  margin: 0 40px;
  
  .qr-scan-icon {
    width: 64px;
    height: 64px;
  }

  .btn-scan {
    display: flex;
    width: 80px;
    height: 80px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 0px 4px 4px 0px;
    background: #FFF1F6;
    position: absolute;
    right: 0;
  }
}
.gift-card-number-input {
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  align-self: stretch;
  font-size: 30px;
  height: unset;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  outline: none;
  box-shadow: none;
}
`;
const FooterActions = styled.div`
  display: flex;
  padding: 16px !important;
  align-items: center;
  gap: 16px;
  button.ant-btn {
    color: #1D2129;
    border: 1px solid #86909C;
    display: flex;
    height: 70px;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0;
    border-radius: 4px;
    background: #E5E6EB;
    span {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &.ant-btn-primary {
      background-color: #ff8890;
      border: 1px solid #f5767f;
      color: #1d2129;
    }

    &:disabled {
      opacity: 0.7;
    }
  }
`;