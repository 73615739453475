import { Input, InputRef } from 'antd';
import Modal from 'components/Modal';
import NumberKeyBoard, { PIN_LENGTH } from 'components/NumberKeyBoard';
import NumberPadV2 from 'components/NumberKeyBoard/Currency/NumberPadV2';
import { IApiDeleteClockInOut } from 'features/report/services/types/api';
import { ITimeClockReportHistory } from 'features/report/services/types/reportStaff';
import { delay } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { InputUIV2 } from 'widgets/RequestAdminLocalPW';
type IModalPassword = {
  onSubmit?: (payload: IApiDeleteClockInOut) => void;
  data: ITimeClockReportHistory | undefined;
  isShown: boolean;
  onClose: () => void;
  v2?: boolean;
};
const ModalDeleteTimeInOut: React.FC<IModalPassword> = ({ onSubmit, isShown, onClose, data, v2 }) => {
  const inputRef = useRef<InputRef>(null);
  const [text, setText] = useState('');
  const handleCloseModal = () => {
    setText('');
    onClose();
  };

  useEffect(() => {
    if (text.length === PIN_LENGTH) {
      try {
        const isExcept = true;
        if (isExcept) {
          const payload: IApiDeleteClockInOut = {
            clockId: data?.id || '',
            pinCode: text
          };
          onSubmit && onSubmit(payload);
          handleCloseModal();
        }
      } catch (error) {
        setText('');
      }
    }
  }, [text]);

  useEffect(() => {
    if (!text) {
      setText(String(''));
    }
  }, []);

  useEffect(() => {
    if (!isShown) return;
    delay(() => inputRef.current?.focus(), 200);
  }, [isShown]);

  return (
    <Modal
      modalTitle="ADMIN PASSWORD"
      visible={isShown}
      onClose={onClose}
      hiddenOkButton={true}
      destroyOnClose={true}
      okTitle=""
      cancelTitle="Close"
      v2={v2}
      noneBodyStyle={v2}
      width={v2 ? '800px' : undefined}
    >
      {v2 ? <>
        <InputUIV2>
          <Input
            ref={inputRef}
            name="input_password"
            value={text}
            autoFocus
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            inputMode='none'
            className={'password-input'}
            onChange={(e) => {
              setText(e.target.value);
            }}
            maxLength={PIN_LENGTH}
            autoComplete="off"
          />
        </InputUIV2>
        <NumberPadV2
          value={text} onChange={(val) => setText(val || '')}
          type='PIN-PASSWORD'
        />
      </> :
        <NumberKeyBoard
          inputClassName="custom-number-input-keyboard"
          value={text}
          onChange={setText}
          type="PIN-PASSWORD"
        />}
    </Modal>
  );
};

export default ModalDeleteTimeInOut;

