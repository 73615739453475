import { Form, FormInstance } from 'antd';
import Box from 'components/Box';
import Text from 'components/Text';
import { ITimelineItem } from 'features/appointment/utils/getTimeLines';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CalendarPicker from './CalendarPicker';
import CustomTimePicker from './CustomTimePicker';
import StaffListChooseUI from './StaffListChooseUI';

const TIME_FORMAT = 'HH:mm';
const START_TIME = moment().set({
  hour: 9,
  minute: 0,
  second: 0,
  millisecond: 0,
});
const END_TIME = moment().set({
  hour: 21,
  minute: 0,
  second: 0,
  millisecond: 0,
});
export const getDefaultStartTime = (): ITimelineItem | undefined => {
  return {
    id: START_TIME.format(TIME_FORMAT),
    value: START_TIME,
  };
};

export const getDefaultEndTime = (): ITimelineItem | undefined => {
  return {
    id: END_TIME.format(TIME_FORMAT),
    value: END_TIME,
  };
};

interface Props {
  form: FormInstance;
}

const AddTimeInOutV2TimePicker: React.FC<Props> = ({ form }) => {
  const [selectedDate, setSelectedDate] = useState<Moment | undefined>();

  useEffect(() => {
    const unsubscribe = form.getFieldsValue(['date']);
    setSelectedDate(unsubscribe.date);
  }, [form]);

  useEffect(() => {
    form.setFieldsValue({
      startTime: getDefaultStartTime(),
      endTime: getDefaultEndTime(),
    });

    if (form.getFieldValue('date')) {
      setTimeout(() => {
        form.validateFields(['startTime', 'endTime']).catch(() => undefined);
      }, 0);
    }
  }, [selectedDate, form]);

  const isEndTimeAfterStartTime = (
    startTime?: ITimelineItem,
    endTime?: ITimelineItem
  ): boolean => {
    if (!startTime?.value || !endTime?.value) return true;
    return endTime.value > startTime.value;
  };

  const validateStartTime = (_: any, value?: ITimelineItem) => {

    if (!value) {
      return Promise.reject('Please select start time');
    }

    return Promise.resolve();
  };

  const validateEndTime = (_: any, value?: ITimelineItem) => {
    const startTime = form.getFieldValue('startTime') as
      | ITimelineItem
      | undefined;

    if (!startTime) {
      return Promise.reject('Please select start time first');
    }

    if (!value) {
      return Promise.reject('Please select end time');
    }

    if (!isEndTimeAfterStartTime(startTime, value)) {
      return Promise.reject('End time must be after start time');
    }

    return Promise.resolve();
  };

  const handleStartTimeChange = (value?: ITimelineItem) => {
    form.validateFields(['startTime']).catch(() => undefined);
  };

  const handleEndTimeChange = (value?: ITimelineItem) => {
    form.validateFields(['endTime']).catch(() => undefined);
  };

  return (
    <Container>
      <Box style={{ flex: 1 }} display='flex' flexDirection='column'>
        <Form.Item noStyle name='staff'>
          <StaffListChooseUI />
        </Form.Item>
      </Box>
      <Box style={{ width: '650px' }} display='flex' flexDirection='column'>
        <Form.Item
          noStyle
          name='date'
          rules={[{ required: true }]}
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.date !== currentValues.date
          }
        >
          <CalendarPicker
            onChange={(date) => {
              setSelectedDate(date);
            }}
            showDateInPast
          />
        </Form.Item>
        <Divider />
        <ContainerTimes>
          <div className='children-content'>
            <TimeRowContainer>
              <TimeColumnContainer>
                <Text
                  variant='H7'
                  color='text_3'
                  textTransform='uppercase'
                  className='text-lg'
                >
                  Start Time
                </Text>
                <Form.Item
                  name='startTime'
                  rules={[{ validator: validateStartTime }]}
                  dependencies={['date']}
                >
                  <CustomTimePicker
                    onChange={handleStartTimeChange}
                    defaultPeriod='AM'
                  />
                </Form.Item>
              </TimeColumnContainer>

              <TimeColumnContainer>
                <Text
                  variant='H7'
                  color='text_3'
                  textTransform='uppercase'
                  className='text-lg'
                >
                  End Time
                </Text>
                <Form.Item
                  name='endTime'
                  rules={[{ validator: validateEndTime }]}
                  dependencies={['startTime', 'date']}
                >
                  <CustomTimePicker
                    onChange={handleEndTimeChange}
                    defaultPeriod='PM'
                  />
                </Form.Item>
              </TimeColumnContainer>
            </TimeRowContainer>
          </div>
        </ContainerTimes>
      </Box>
    </Container>
  );
};

export default AddTimeInOutV2TimePicker;

const Container = styled.div`
  display: flex;
  padding: 16px;
  gap: 16px;
  zoom: 0.85;

  .ant-form-item-label label {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 166.667%;
    text-transform: capitalize;
    color: #1d2129;
  }

  .ant-input {
    color: #1d2129;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

const Divider = styled.hr`
  height: 1px;
  width: 100%;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
`;

const ContainerTimes = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  margin-top: 16px;

  .children-content {
    position: relative;
    inset: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const TimeRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
`;

const TimeColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  .text-lg {
    margin-bottom: 8px;
  }
`;
