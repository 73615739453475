import NumberPadV2, { NumberPadItem } from 'components/NumberKeyBoard/Currency/NumberPadV2';
import { styled } from 'styled-components';
import { DollarAmountInputRef, parseOutputValue, parseStrInputValue } from './helpers';
import Icon from 'assets/Icons';
type NumberPadProps = {
  value?: number | null;
  onChange?: (val: number) => void;
  hint?: boolean;
  modeTip?: boolean;
  maxValue?: number;
  controlRef?: React.RefObject<DollarAmountInputRef>;
};

const NumberAmountKeyboard = ({ controlRef, onChange = () => undefined, maxValue }: NumberPadProps) => {
  const handleInputChange = (symbol: string, keyType?: string) => {
    const inputText = controlRef?.current?.getText() || '';
    const _value = keyType === 'removeAll' ? '' : (keyType === 'backSpace' ? inputText?.slice(0, inputText?.length - 1) : inputText + symbol);
    const rawText = parseStrInputValue(_value);
    const numericValue = parseOutputValue(rawText);
    if (maxValue && numericValue > maxValue) return;
    onChange(numericValue);
    controlRef?.current?.focus(rawText);
  };
  const displayValue = controlRef?.current?.getText();
  return (
    <NumberPadV2 buttonNames={buttonNames} type={'NUMBER'} value={displayValue} onKeyChange={handleInputChange} />
  );
};
export default NumberAmountKeyboard;

export const ButtonNumberPad = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 6px;
  background: #FCFCFD;
  box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
  border: none;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  
  .NUMBER-PAD {
    color: #1D2129;
    font-family: Poppins;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &.clear, &.backspace {
    background: #E5E6EB;
  }

  &:hover {
    cursor: pointer;
  }
  &:focus-visible{
    outline: 4px solid #fff0f0;
    outline-offset: 1px;
    transition: outline-offset 0s,outline 0s;
  }
`;

const buttonNames: NumberPadItem[] = [
  {
    key: '1',
    value: '1',
    get: () => '1',
    acceptAll: true,
  },
  {
    key: '2',
    value: '2',
    get: () => '2',
    acceptAll: true,
  },
  {
    key: '3',
    value: '3',
    get: () => '3',
    acceptAll: true,
  },
  {
    key: '4',
    value: '4',
    get: () => '4',
    acceptAll: true,
  },
  {
    key: '5',
    value: '5',
    get: () => '5',
    acceptAll: true,
  },
  {
    key: '6',
    value: '6',
    get: () => '6',
    acceptAll: true,
  },
  {
    key: '7',
    value: '7',
    get: () => '7',
    acceptAll: true,
  },
  {
    key: '8',
    value: '8',
    get: () => '8',
    acceptAll: true,
  },
  {
    key: '9',
    value: '9',
    get: () => '9',
    acceptAll: true,
  },
  {
    key: 'removeAll',
    value: <Icon type="removeAll"> </Icon>,
    get: () => '',
    type: 'clear',
    acceptAll: true,
  },
  {
    key: '0',
    value: '0',
    get: () => '0',
    acceptAll: true,
  },
  {
    key: 'backSpace',
    value: (
      <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
        <path d="M12.3551 10H37.0834V30H12.3551L4.58337 20L12.3551 10Z" fill="#86909C" />
        <path fillRule="evenodd" clipRule="evenodd" d="M29.2693 14.1418C29.7575 14.6299 29.7575 15.4214 29.2693 15.9095L19.1123 26.0665C18.6241 26.5547 17.8327 26.5547 17.3445 26.0665C16.8564 25.5784 16.8564 24.7869 17.3445 24.2988L27.5015 14.1418C27.9897 13.6536 28.7812 13.6536 29.2693 14.1418Z" fill="#86909C" />
        <path fillRule="evenodd" clipRule="evenodd" d="M17.3445 14.1418C17.8327 13.6536 18.6241 13.6536 19.1123 14.1418L29.2693 24.2988C29.7575 24.7869 29.7575 25.5784 29.2693 26.0665C28.7812 26.5547 27.9897 26.5547 27.5015 26.0665L17.3445 15.9095C16.8564 15.4214 16.8564 14.6299 17.3445 14.1418Z" fill="#86909C" />
        <path fillRule="evenodd" clipRule="evenodd" d="M29.2693 14.1418C29.7575 14.6299 29.7575 15.4214 29.2693 15.9095L19.1123 26.0665C18.6241 26.5547 17.8327 26.5547 17.3445 26.0665C16.8564 25.5784 16.8564 24.7869 17.3445 24.2988L27.5015 14.1418C27.9897 13.6536 28.7812 13.6536 29.2693 14.1418Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M17.3445 14.1418C17.8327 13.6536 18.6241 13.6536 19.1123 14.1418L29.2693 24.2988C29.7575 24.7869 29.7575 25.5784 29.2693 26.0665C28.7812 26.5547 27.9897 26.5547 27.5015 26.0665L17.3445 15.9095C16.8564 15.4214 16.8564 14.6299 17.3445 14.1418Z" fill="white" />
      </svg>
    ),
    get: (text: string) => text.slice(0, text.length - 1),
    acceptAll: true,
    type: 'backspace',
  },
];
