import React, { useEffect } from 'react';
import { ColumnsType } from 'antd/es/table';
import Table from 'components/Table';
import Text from 'components/Text';
import customerActions from 'features/customer/services/actions';
import customerSelectors from 'features/customer/services/selectors';
import { IReferral } from 'features/customer/services/types/referral';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { maskPhone } from 'utils/formatPhone';
import { momentTimezone } from 'utils/time';
type IReferralsTableProps = {};

const columns: ColumnsType<IReferral> = [
  {
    title: 'Date',
    dataIndex: 'createDate',
    align: 'left',
    render(createDate) {
      return (
        <Text variant="BODY_1" color="text_3">
          {createDate ? momentTimezone(createDate).format('MM/DD/YYYY hh:mm a') : '--'}
        </Text>
      );
    },
  },
  {
    title: 'Name',
    dataIndex: 'customerName',
    align: 'left',
    render(customerName) {
      return (
        <Text variant="BODY_1" color="text_3">
          {customerName || '--'}
        </Text>
      );
    },
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    align: 'left',
    render(phone) {
      return (
        <Text variant="BODY_1" color="text_3">
          {phone ? maskPhone(phone) : '--'}
        </Text>
      );
    },
  },

  {
    title: 'Source type',
    dataIndex: 'type',
    align: 'left',
    render(type) {
      return (
        <Text variant="BODY_1" color="text_3">
          {type || '--'}
        </Text>
      );
    },
  },
  {
    title: 'Point Reward',
    dataIndex: 'totalReward',
    align: 'left',
    render(totalReward) {
      return (
        <Text variant="BODY_1" color="text_3">
          {totalReward} Points
        </Text>
      );
    },
  },
];

const ReferralsTable: React.FC<IReferralsTableProps> = () => {
  const dispatch = useAppDispatch();
  const customerDetails = customerSelectors.getCustomerDetails();
  const data = customerSelectors.referralTableData.data();
  const loading = customerSelectors.referralTableData.loading();
  const params = customerSelectors.referralTableData.params();
  const totalElement = customerSelectors.referralTableData.totalElement();

  const handlePageChange = async (page?: number) => {
    const params = {
      page: page,
    };
    dispatch(customerActions.setReferralsParams(params));
  };

  useEffect(() => {
    if (customerDetails?.id) {
      const params = {
        customerId: customerDetails?.id || '',
      };
      dispatch(customerActions.setReferralsParams(params));
    }
  }, [customerDetails?.id]);

  return (
    <ReferralsTableStyled>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data}
        rowKey={'id'}
        pagination={{
          total: totalElement ?? 0,
          pageSize: params.size,
          current: params.page,
          showSizeChanger: false,
        }}
        handleChangePage={handlePageChange}
      />
    </ReferralsTableStyled>
  );
};

export default ReferralsTable;
const ReferralsTableStyled = styled.div``;
