import { Row } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import paymentActions from 'features/payment/services/actions';
import { IWaitingListResItem } from 'features/user/services/types/waiting';
import moment from 'moment';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import uiSelector from 'services/UI/selectors';
import { useAppDispatch } from 'store/hooks';
import { styled } from 'styled-components';
import storage from 'utils/sessionStorage';
import { formatDuration } from 'utils/time';
import call_icon from './call-action.svg';

type IProps = {
  data: IWaitingListResItem;
  viewOnly?: boolean;
  removeMode?: boolean;
  onRemoveTicket: () => void;
  onClick?: () => void;
  onCalling?: () => void;
  active?: boolean;
  displayCallPhoneStillWaiting?: boolean;
};
const WalkInItem = ({ onCalling = () => undefined, active, data, viewOnly, removeMode, onRemoveTicket, onClick: onPropClick, displayCallPhoneStillWaiting }: IProps) => {
  const currentTime = uiSelector.getCurrentTime();
  const navigate = useNavigate();
  // const setting = shopSelectors.data.allSetting();
  const enablePrintCheckIn = true;
  const dispatch = useAppDispatch();
  const countDown = useMemo(() => {
    if (!data) return null;
    const now = moment(currentTime);
    const minutes = now.diff(moment(data.time, 'hh:mm A'), 'minutes');
    return formatDuration(minutes);
  }, [currentTime, data]);

  const onClick = () => {
    if (viewOnly) return;
    if (!data) return;
    if (onPropClick) return onPropClick();
    navigate(`/store/${storage.shop_id.get()}/ticket/edit/${data.checkInId}`);
  };

  const handlePrint = (e: any) => {
    e.stopPropagation();
    // @ts-ignore
    dispatch(paymentActions.getInfoPrintWaiting.fetch({ body: JSON.stringify({ id: data.checkInId, type: 'WALK_IN' }) }));
  };

  if (!data) return null;

  return (
    <Styled onClick={onClick} type='button'>

      <StyledBox className={active ? 'active' : ''}>
        <Row className='header-content' wrap={false}>
          {displayCallPhoneStillWaiting ? <>
            <TicketNumber variant='BODY_2' color='text_3' style={{ fontSize: '1.7rem' }}>#{data.ticketNumber}</TicketNumber>
            <div style={{ flex: 1, position: 'relative', alignSelf: 'stretch', marginLeft: 4 }}>
              <Box display='flex' alignItems='center' className='customer-block text-overflow' style={{ position: 'absolute', inset: 0 }}>
                <Text pr={0.25} className='customer-name'>{data?.customerName}</Text>
              </Box>
            </div>
            <button onClick={e => [e.stopPropagation(), onCalling()]} type='button'><img src={call_icon} alt="call_icon" style={{ width: 40, height: 40 }} /></button>
          </> : <>
            <div style={{ flex: 1, position: 'relative', alignSelf: 'stretch' }}>
              <Box display='flex' alignItems='center' className='customer-block text-overflow' style={{ position: 'absolute', inset: 0 }}>
                <div style={{ width: '10px' }}><Circle /></div>
                <Text pr={0.25} className='customer-name'>{data?.customerName}</Text>
              </Box>
            </div>
            <TicketNumber variant='BODY_2' color='text_3' style={{ fontSize: '1.7rem' }}>#{data.ticketNumber}</TicketNumber>
          </>}
        </Row>
        <Row className='box-time' justify={!enablePrintCheckIn ? 'center' : 'space-between'}>
          <Text variant='H9' style={{ fontSize: '1rem', fontWeight: '700' }}>{data.time}</Text>
          <Text variant='BODY_1' style={{ fontSize: '1rem' }} >{countDown}</Text>
        </Row>
        <Box alignSelf='center' display='flex'>
          <div style={{ flex: 1, position: 'relative' }}>
            <Text className='service-name'>
              <p className='text-overflow'>{data.serviceInfo.map(o => o.itemName).join(',') || '--'}</p>
            </Text>
          </div>
          {removeMode ?
            <Row justify={'center'} className='remove-row'>
              <Button icon='deleteIconCashier' size='small' height={2} style={{ height: '2rem', padding: '0 4px' }} onClick={e => [e.stopPropagation(), e.preventDefault(), onRemoveTicket()]} />
            </Row>
            :
            !!enablePrintCheckIn && <Row justify={'center'}><Button icon='print' size='small' height={2} style={{ height: '2rem', padding: '4px' }} onClick={handlePrint} /></Row>
          }
        </Box>
      </StyledBox>
    </Styled>
  );
};

export default WalkInItem;
const Styled = styled.button`
  width: 100%;
  // height: 100%;
`;
const StyledBox = styled.div`
padding: 3px 8px;
gap: 0.5rem;
flex-shrink: 0;
width: 100%;
border-radius: 10px;
border: 1px solid var(--line-line-3, #86909C);
background: #FFF;
  .box-time {
    color: #1D2129;
    text-align: center;
    border-radius: 10px;
    font-weight: 700;
    background: #F6F7FC;
    display: flex;
    align-items: center;
  }
  .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    .customer-block {
      display: flex;
      align-items: center;
      gap: 0.375rem;
    }
    .customer-name {
      overflow: hidden;
      color: #1D2129;
      text-overflow: ellipsis;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }
    .ticket-code {
      color: #1D2129;
      text-align: right;
    }
  }
  .service-staff {
    color: var(--text-text-3, #1D2129);
  }
  .service-name {
    color: #767676;
    display: flex;
    align-items: center;
    text-align: left;
    max-width: 100%;
    position: absolute;
    inset: 0;
  }

  &.active {
    box-shadow: inset #1eb99d 0 0 0 3px, rgba(201, 211, 219, 0.5) 20px 14px 24px;
  }
`;

const Circle = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
    <circle cx="5" cy="5" r="5" fill="#FF8890" />
  </svg>
);

const TicketNumber = styled(Text)`
  display: inline-flex;
  padding: 1px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #1D2129;
  color: white;
`;
