import * as React from 'react';
import { SVGProps } from 'react';
const IcReceiver = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={56}
    height={56}
    viewBox='0 0 56 56'
    fill='none'
    {...props}
  >
    <path
      fill={props?.fill || '#C84B31'}
      d='M17.359 2.473c2.998-.576 6.236.186 8.642 2.101 3.146 2.398 4.738 6.656 3.942 10.56-.5 2.79-2.184 5.337-4.534 6.918-2.24 1.561-5.108 2.175-7.791 1.71-3.46-.54-6.57-2.938-8.013-6.136a10.847 10.847 0 0 1-.518-7.697c1.11-3.775 4.423-6.75 8.272-7.456ZM46.247 21.066c.204-.056.426-.056.63-.037.129.149.258.279.388.428 2.813 2.807 5.607 5.652 8.42 8.44.241.15.296.429.315.689-3.053 3.05-6.126 6.098-9.142 9.185-.26.037-.666.037-.648-.316-.037-1.636 0-3.254-.018-4.89-2.499-.186-5.108.372-7.125 1.933-1.13.912-2.073 2.232-1.999 3.738-.074 2.194.814 4.425 2.48 5.857.259.167.407.65-.056.594-2.498-.818-4.922-2.268-6.403-4.518-1.24-2.008-1.499-4.5-1.147-6.805.407-2.826 1.962-5.466 4.238-7.158 2.85-2.138 6.514-2.826 10.012-2.808.037-1.413-.056-2.882.055-4.332Z'
    />
    <path
      fill={props?.fill || '#C84B31'}
      d='M5.367 28.113C7.42 26.774 9.79 25.937 12.25 25.9h11.307c1.98.019 3.998-.13 5.922.428 1.444.335 2.74 1.078 3.98 1.822l-.075.205a13.013 13.013 0 0 0-3.35 8.738c-.036 2.362.537 4.816 2 6.712 1.665 2.213 4.163 3.645 6.717 4.574-.055 1.71.019 3.421-.037 5.132a2.483 2.483 0 0 1-.074.204c-2.757-.074-5.515 0-8.254-.037H0c.019-5.224 0-10.43.019-15.655.092-3.514 1.758-6.898 4.404-9.167.222-.185.5-.316.648-.595.092.075.185.13.277.205 0-.074.019-.26.019-.353Z'
    />
  </svg>
);
export default IcReceiver;
