import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { cloneDeep, find, first, remove, set, sum, sumBy } from 'lodash';
import shopActions from 'services/shop/actions';
import { IFeeCreditCardConfig } from 'services/shop/types/feeCreditCard';
import { IProductItemData } from 'services/shop/types/product';
import { ISimpleMenuServiceItem } from 'services/shop/types/simpleMenu';
import { IProductVIPItemData } from 'services/shop/types/vip';
import { v4 as uuid } from 'uuid';
import actions from './actions';
import { DiscountType, FEATURE_PRODUCT_MODE, ITEM_HANDLE_MODE, NAME_REDUCER, QUICK_PAY_ID, decodeDraftServiceSwitchStaffId, parseDecimal } from './constants';
import {
  IService,
  IServiceCategory,
  IStaff,
  IStaffItem,
  ITicketDetails
} from './types/api';
import { SERVICE_ITEM_TYPE } from './types/constant';
import { ICustomerReferralData, ICustomerResItem } from './types/customer';
import { IGiftCardResult } from './types/giftCard';
import { IState } from './types/reducer';
import { IStaffAvailableItem } from 'services/shop/types/staff';
import { ISettingReferralItem } from 'services/shop/types/customer';

const initialState: IState = {
  ticketDetails: {
    checkInId: '',
    customerInfo: {
      customerName: '',
      customerId: '',
      point: 0,
    },
    ticketInfo: {
      ticketNumber: 0,
      createdDate: '',
      total: 0,
      subTotal: 0,
      tip: 0,
      discount: 0,
      tax: 0,
      saleTax: 0,
      useTax: 0,
      discountSetting: { ownerPaidPercent: 0, staffPaidPercent: 0 },
    },
    items: [],
  },
  ticketTotalInfo: {
    subtotal: 0,
    tipTicket: 0,
    saleTax: 0,
    usedTax: 0,
    discByItems: 0,
    discByTicket: 0,
    total: 0,
  },
  selectedCustomer: null,
  customerList: [],
  selectedItems: [],
  activeStaffId: null,
  activeItemsCollapse: [],
  activeCategory: null,
  activeProductCategory: null,
  visibleChooseCustomer: false,
  visibleVIP: false,
  visibleQuickPay: false,
  visibleIssueGiftCard: false,
  visibleModalWarningEmptyServices: false,
  visibleModalWarningVIPPackage: false,
  visibleChooseStaff: null,
  draftServiceSwitchStaff: null,
  itemHandleMode: null,
  discountTicket: null,
  draftDiscountItemModal: null,
  draftSupplyFeeItemModal: null,
  draftSelectService: null,
  draftSelectProduct: null,
  draftSimpleMenuServiceItem: null,
  draftSelectProductVIP: null,
  draftSelectGiftCard: null,
  draftStaffForNewTicket: null,
  draftQuickPay: null,
  featureProductMode: FEATURE_PRODUCT_MODE.SERVICE,
  customerPackage: null,
  enableCardFee: true,
  feeCreditCard: null,
  initialDetail: null,
  taxConfig: null,
  highlightItemId: '',
  activeQuickMenu: false,
  enableCashIncentive: true,
  previousDiscountTicket: null,
  countStaffInRowUI: 4,
  autoShowAddPrice: false,
  productTaxConfig: null,
  productTaxValue: 0,
  customerReferral: null,
  enableReferral: false,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {
    saveCustomerReferral: (state, { payload }: { payload: ICustomerReferralData | null }) => {
      state.customerReferral = payload;
    },
    setVisibleIssueGiftCard: (state, { payload }: { payload: boolean }) => {
      state.visibleIssueGiftCard = payload;
    },
    setDraftStaffForNewTicketV2: (state, { payload }: { payload: IStaffAvailableItem }) => {
      if (state.autoShowAddPrice) {
        state.visibleQuickPay = true;
      }
      state.draftStaffForNewTicket = payload;
    },
    setVisibleQuickPay: (state, { payload }: { payload: boolean }) => {
      state.visibleQuickPay = payload;
    },
    setActiveItemsCollapse: (state, { payload }: { payload: string }) => {
      if (state.activeItemsCollapse?.indexOf(payload) == -1)
        state.activeItemsCollapse.push(payload);
      else {
        remove(state.activeItemsCollapse, o => o === payload);
      }
    },
    switchService: (state, { payload }: { payload: { targetStaffId: string, dragStaffId: string, dragServiceId: string } }) => {
      const { dragServiceId, dragStaffId, targetStaffId } = payload;
      const draftItem = find(state.selectedItems, o => o.staffId === dragStaffId);
      const draftService = find(draftItem?.services, (o: IService) => o.uuid_local === dragServiceId);
      if (targetStaffId === dragStaffId) return;
      if (draftItem && draftService) {
        const switchService = cloneDeep(draftService);
        const targetSwitchStaff = find(state.selectedItems, o => o.staffId === targetStaffId);
        if (targetSwitchStaff) {
          targetSwitchStaff.services.push(switchService);
          remove(draftItem.services, o => o.uuid_local === dragServiceId);
        }
      }
    },
    setCountStaffInRowUI: (state, { payload }: { payload: number }) => {
      state.countStaffInRowUI = payload;
    },
    enableCashIncentive: (state, { payload }) => {
      state.enableCashIncentive = payload;
    },
    saveDiscountTicket: (state) => {
      state.previousDiscountTicket = state.discountTicket ? { ...state.discountTicket } : null;
    },
    revertDiscountTicket: (state) => {
      state.discountTicket = state.previousDiscountTicket;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(shopActions.get.settingReferral.success, (state, { payload }) => {
        state.enableReferral = !!find(payload, (o: ISettingReferralItem) => o.categoryType === 'REFERRAL_SENDER')?.enable;
      })
      .addCase(shopActions.get.allSetting.success, (state, { payload }) => {
        state.autoShowAddPrice = !!payload.autoShowAddPrice;
      })
      .addCase(shopActions.get.allSetting.fail, (state) => {
        state.autoShowAddPrice = false;
      })
      .addCase(actions.activeQuickMenu, (state, { payload }) => {
        state.activeQuickMenu = payload;
      })
      .addCase(actions.setHighlightItemId, (state, { payload }) => {
        state.highlightItemId = payload;
      })
      .addCase(shopActions.get.feeCreditCard.success, (state, { payload }) => {
        const _payload = payload as IFeeCreditCardConfig;
        state.enableCardFee = _payload.enable;
        state.feeCreditCard = _payload;
      })
      .addCase(shopActions.get.taxConfig.success, (state, { payload }) => {
        state.taxConfig = payload;
      })
      .addCase(actions.setVisibleVIP, (state, { payload }) => {
        state.visibleVIP = payload;
      })
      .addCase(actions.setEnableCardFee, (state, { payload }) => {
        state.enableCardFee = payload;
      })
      .addCase(actions.checkCustomerPackage.success, (state, { payload }) => {
        state.customerPackage = payload;
      })
      .addCase(actions.clearCustomer.success, (state) => {
        state.selectedCustomer = {
          customerName: null,
          customerId: null,
          customerPhone: null,
          point: null,
        };
        if (state.visibleChooseCustomer) {
          state.visibleChooseCustomer = false;
        }
      })
      .addCase(actions.removeStaffItem, (state, { payload }) => {
        remove(state.selectedItems, o => o.staffId === payload);
        const exist = state.selectedItems.find(o => o.staffId === state.activeStaffId);
        if (!exist) {
          state.activeStaffId = first(state.selectedItems)?.staffId || '';
        }
      })
      .addCase(actions.updateQuickPayItem, (state, { payload }) => {
        const staffExist = state.selectedItems.find(o => o.staffId === payload.staffId);
        if (staffExist) {
          staffExist.services = staffExist.services.map(o => {
            if (o.uuid_local === payload.itemId) {
              if (o.itemType === SERVICE_ITEM_TYPE.GIFT_CARD) {
                return ({
                  ...o,
                  price: payload.value || 0,
                });
              }
              return ({
                ...o,
                itemId: QUICK_PAY_ID,
                itemName: payload.itemName,
                price: payload.value || 0,
              });
            }
            return o;
          });
        }
        state.draftQuickPay = null;
      })
      .addCase(actions.setDraftQuickPay, (state, { payload }) => {
        state.draftQuickPay = payload;
      })
      .addCase(actions.switchStaffNew, (state, { payload }) => {
        state.selectedItems = state.selectedItems.map(o => {
          if (o.staffId === payload.currStaffId) {
            return ({
              ...o,
              staffId: payload.newStaff.staffId,
              staffName: payload.newStaff.staffName,
            });
          }
          return o;
        });
        state.activeStaffId = payload.newStaff.staffId;
        if (state.activeItemsCollapse?.indexOf(payload.newStaff.staffId) == -1)
          state.activeItemsCollapse.push(payload.newStaff.staffId);
      })
      .addCase(actions.setVisibleModalWarningVIPPackage, (state, { payload }) => {
        state.visibleModalWarningVIPPackage = payload;
      })
      .addCase(actions.setVisibleModalWarningEmptyServices, (state, { payload }) => {
        state.visibleModalWarningEmptyServices = payload;
      })
      .addCase(actions.setFeatureProductMode, (state, { payload }) => {
        state.featureProductMode = payload;
      })
      .addCase(actions.setDraftStaffForNewTicket, (state, { payload }) => {
        state.draftStaffForNewTicket = payload;
      })
      .addCase(actions.addGiftCard, (state, { payload }) => {
        const data = payload as IGiftCardResult;
        if (state.selectedItems.length === 0) {
          state.visibleChooseStaff = 'default';
          state.draftSelectGiftCard = data;
          return;
        }

        const { amount, code, expireDate } = payload;
        const staff = state.selectedItems.find(o => o.staffId === state.activeStaffId);
        const gifCardResult: IService = {
          discount: 0,
          duration: 0,
          itemId: code,
          itemName: 'Gift Card',
          itemType: SERVICE_ITEM_TYPE.GIFT_CARD,
          note: null,
          price: amount,
          expireDate,
          discountSetting: null,
          discountType: DiscountType.MONEY,
          uuid_local: uuid(),
        };
        if (staff) {
          staff.services.push(gifCardResult);
          const activeStaffId = staff.staffId;
          state.activeStaffId = activeStaffId;
          if (state.activeItemsCollapse?.indexOf(activeStaffId) == -1)
            state.activeItemsCollapse.push(activeStaffId);

          state.highlightItemId = gifCardResult.uuid_local;
        }
      })
      .addCase(actions.setDiscountItem, (state, { payload }) => {
        if (payload) {
          const { discountValue, masterData } = payload;
          const activeStaff = find(state.selectedItems, staff => staff.staffId === masterData.staffId);
          const activeService = find(activeStaff?.services, (o: IService) => o.uuid_local === masterData.service_uuid_local);
          if (activeService) {
            set(activeService, 'discount', discountValue.discount);
            set(activeService, 'discountType', discountValue.discountType);
            set(activeService, 'note', discountValue.reason);
            set(activeService, 'discountSetting', discountValue.discountSetting);
          }
          // state.itemHandleMode = null;
        }
      })
      .addCase(actions.updateSupplyFeeItem, (state, { payload }) => {
        const activeStaff = find(state.selectedItems, staff => staff.staffId === payload.staffId);
        const activeService = find(activeStaff?.services, (o: IService) => o.uuid_local === payload.service_uuid_local);
        if (!activeService) return;
        set(activeService, 'supplyFee', payload.supplyFee);
      })
      .addCase(actions.setDraftSupplyFeeItemModal, (state, { payload }) => {
        state.draftSupplyFeeItemModal = payload;
      })
      .addCase(actions.toggleVisibleSupplyFeeMode, (state) => {
        state.itemHandleMode = state.itemHandleMode !== ITEM_HANDLE_MODE.SUPPLY_FEE ? ITEM_HANDLE_MODE.SUPPLY_FEE : null;
      })
      .addCase(actions.setDraftDiscountItemModal, (state, { payload }) => {
        state.draftDiscountItemModal = payload;
      })
      .addCase(actions.setDiscountTicket, (state, { payload }) => {
        state.discountTicket = payload;
      })
      .addCase(actions.toggleVisibleDeleteItemMode, (state) => {
        state.itemHandleMode = state.itemHandleMode !== ITEM_HANDLE_MODE.DELETE_ITEM ? ITEM_HANDLE_MODE.DELETE_ITEM : null;
      })
      .addCase(actions.toggleVisibleDiscountItemMode, (state) => {
        state.itemHandleMode = state.itemHandleMode !== ITEM_HANDLE_MODE.DISCOUNT_ITEM ? ITEM_HANDLE_MODE.DISCOUNT_ITEM : null;
      })
      .addCase(actions.setDraftServiceSwitchStaff, (state, { payload }) => {
        if (state.draftServiceSwitchStaff === payload) {
          state.draftServiceSwitchStaff = null;
        } else {
          state.draftServiceSwitchStaff = payload;
        }
      })
      .addCase(actions.setVisibleChooseStaff, (state, { payload }) => {
        state.visibleChooseStaff = payload;
      })
      .addCase(actions.setVisibleChooseCustomer, (state, { payload }) => {
        state.visibleChooseCustomer = payload;
      })

      .addCase(shopActions.get.productTaxConfig.success, (state, { payload }) => {
        state.productTaxConfig = payload;
      })
      .addCase(actions.calculateTicketPaymentInfo, (state) => {
        // Subtotal = price của tất cả service (ko tính discount)
        const subtotal = sumBy(state.selectedItems, (o) =>
          sumBy(o.services, (s) => s.price ?? 0)
        );
        set(state.ticketTotalInfo, 'subtotal', parseDecimal(subtotal));

        // Tip ticket: nhập tip

        // Sale tax: nằm trong phần setting (max: 10%)

        // Used tax: nằm trong phần setting (max: 10%) (Shop trong 5 tiểu bang của Mỹ bán license sẽ có thuế này)

        // Discount item = total discount của các service
        const discByItems = sumBy(state.selectedItems, (o) =>
          sumBy(o.services, (s) => s.discountType === DiscountType.PERCENT ? ((s.price ?? 0) * s.discount / 100) : s.discount ?? 0)
        );
        set(state.ticketTotalInfo, 'discByItems', parseDecimal(discByItems));

        // Discount ticket: discount được add khi bấm button discount màu vàng bên dưới
        // state.ticketTotalInfo.discByTicket

        const discountTicket = (state.discountTicket?.discountType === DiscountType.PERCENT
          ? ((subtotal ?? 0) * state.discountTicket?.discount / 100)
          : state.discountTicket?.discount) || 0;

        set(state.ticketTotalInfo, 'discByTicket', parseDecimal(discountTicket));

        const subtotalForTax = sumBy(state.selectedItems, (o) =>
          sumBy(o.services, (s) =>
            (s.itemType === SERVICE_ITEM_TYPE.GIFT_CARD || s.itemType === SERVICE_ITEM_TYPE.VIP)
              ? 0 : (s.price ?? 0))
        );
        const amountForTax = subtotalForTax > 0 ? (subtotalForTax ?? 0) - (discByItems + discountTicket) : 0;

        if (state.taxConfig?.enableSaleTax) {
          const saleTax = amountForTax * (state.taxConfig.saleTaxPercent / 100);
          set(state.ticketTotalInfo, 'saleTax', parseDecimal(saleTax));
        }

        if (state.taxConfig?.enableUseTax) {
          const useTax = amountForTax * (state.taxConfig.useTaxPercent / 100);
          set(state.ticketTotalInfo, 'usedTax', parseDecimal(useTax));
        }
        const productTaxAmount = sumBy(state.selectedItems,
          item => sumBy(
            item.services.filter(s => s.itemType === SERVICE_ITEM_TYPE.PRODUCT),
            s => s.price ?? 0
          ));

        state.productTaxValue = state.productTaxConfig?.enableTax ? productTaxAmount * (state.productTaxConfig?.taxPercent || 0) / 100 : 0;

        // Total = subtotal + Tip + sale tax + used tax - discount item - discount ticket
        const total = sum([
          subtotal,
          state.ticketTotalInfo.tipTicket,
          state.ticketTotalInfo.saleTax,
          state.ticketTotalInfo.usedTax,
          -state.ticketTotalInfo.discByItems,
          -state.ticketTotalInfo.discByTicket,
        ]);
        set(state.ticketTotalInfo, 'total', parseDecimal(total));
      })

      .addCase(actions.removeSelectedCustomer, (state) => {
        state.visibleChooseCustomer = true;
      })

      .addCase(actions.switchSelectedCustomer, (state, { payload }) => {
        const data = payload as ICustomerResItem;
        state.customerReferral = null;
        if (state.visibleChooseCustomer) {
          state.visibleChooseCustomer = false;

          if (state.selectedItems.length === 0) {
            state.visibleChooseStaff = 'new-ticket';
          }
        }

        state.discountTicket = null;

        state.selectedCustomer = {
          customerId: data.id,
          customerName: data.name,
          point: data.point,
          customerPhone: data.phone,
        };

      })

      .addCase(actions.setActiveItemsCollapse, (state, { payload }) => {
        state.activeItemsCollapse = payload;
      })

      .addCase(actions.setTip, (state, { payload }) => {
        state.ticketTotalInfo.tipTicket = payload;
      })
      .addCase(actions.updateBillIdDetails, (state, { payload }) => {
        if (state.ticketDetails)
          set(state.ticketDetails, 'ticketInfo.billId', payload);
      })
      // getTicketDetails
      .addCase(actions.getTicketDetails.success, (state, { payload }) => {
        const resData = payload as ITicketDetails;

        state.ticketDetails = resData;
        state.initialDetail = resData;
        state.selectedItems = resData.items.map(o => ({
          ...o,
          services: o.services.map(s => ({
            ...s,
            uuid_local: uuid(),
          }))
        }));
        if (state.selectedItems.length === 0) {
          if (state.draftStaffForNewTicket) {
            const staffItem: IStaffItem = {
              services: [],
              staffId: state.draftStaffForNewTicket.staffId,
              staffName: state.draftStaffForNewTicket.staffName,
              bookingTime: null,
              tip: null,
            };
            state.selectedItems.push(staffItem);
            state.draftStaffForNewTicket = null;
          } else {
            state.visibleChooseStaff = 'new-ticket';
          }
        } else if (state.selectedItems.length === 1) {
          const item = first(state.selectedItems);
          if (item && !item.staffId) {
            state.visibleChooseStaff = 'new-ticket';
          }
        }

        const activeStaffId = first(state.selectedItems)?.staffId || null;
        state.activeStaffId = activeStaffId;
        state.activeItemsCollapse = state.selectedItems?.map(o => o.staffId) || [];

        state.selectedCustomer = cloneDeep(resData.customerInfo);
        // if (!state.selectedCustomer.customerId) {
        //   state.visibleChooseCustomer = true;
        // }

        const discByItems = sumBy(resData.items, (o) =>
          sumBy(o.services, (s) => s.discount)
        );

        state.ticketTotalInfo = {
          subtotal: +resData?.ticketInfo?.subTotal?.toFixed(2) || 0, // Subtotal = price của tất cả service (ko tính discount)
          tipTicket: resData?.ticketInfo.tip || 0, // Tip ticket: nhập tip
          saleTax: resData?.ticketInfo.saleTax || 0, // Sale tax: nằm trong phần setting (max: 10%)
          usedTax: resData?.ticketInfo.useTax || 0, // Used tax: nằm trong phần setting (max: 10%) (Shop trong 5 tiểu bang của Mỹ bán license sẽ có thuế này)
          discByItems: discByItems || 0, // Discount item = total discount của các service
          discByTicket: resData?.ticketInfo.discount || 0, // Discount ticket: discount được add khi bấm button discount màu vàng bên dưới
          total: +resData?.ticketInfo.total?.toFixed(2) || 0, // Total = subtotal + Tip + sale tax + used tax - discount item - discount ticket
        };
        state.discountTicket = {
          discount: resData?.ticketInfo?.discount,
          discountSetting: resData?.ticketInfo?.discountSetting,
          discountType: DiscountType.MONEY,
          couponId: null,
          reason: null,
        };
      })

      .addCase(actions.selectCategory, (state, { payload }) => {
        state.activeCategory = payload;
        state.activeQuickMenu = false;
      })
      .addCase(actions.selectProductCategory, (state, { payload }) => {
        state.activeProductCategory = payload;
        state.activeQuickMenu = false;
      })
      .addCase(actions.addQuickPay, (state, { payload }) => {
        const staff = state.selectedItems.find(
          (o) => o.staffId === state.activeStaffId
        );

        if (staff) {
          const ser: IService = {
            itemId: QUICK_PAY_ID,
            itemName: payload.name,
            itemType: SERVICE_ITEM_TYPE.SERVICE,
            price: payload.price,
            note: null,
            discount: 0,
            duration: 0,
            discountType: DiscountType.MONEY,
            uuid_local: uuid(),
          };
          staff.services.push(ser);
          if (state.activeItemsCollapse?.indexOf(staff.staffId) == -1)
            state.activeItemsCollapse.push(staff.staffId);
          state.highlightItemId = ser.uuid_local;
        }
      })
      .addCase(actions.selectProductVIPItem, (state, { payload }) => {
        const data = payload as IProductVIPItemData;
        if (state.selectedItems.length === 0) {
          state.visibleChooseStaff = 'default';
          state.draftSelectProductVIP = data;
          return;
        }

        state.selectedItems = state.selectedItems.map(o => ({
          ...o,
          services: o.services.filter(s => s.itemType !== SERVICE_ITEM_TYPE.VIP)
        }));

        const staff = state.selectedItems.find(
          (o) => o.staffId === state.activeStaffId
        );

        if (staff) {
          const ser: IService = {
            itemId: data.id,
            itemName: data.vipName,
            itemType: SERVICE_ITEM_TYPE.VIP,
            price: data.yearlyPrice || 0,
            note: null,
            discount: 0,
            duration: 0,
            discountType: DiscountType.MONEY,
            uuid_local: uuid(),
          };

          staff.services.push(ser);
          if (state.activeItemsCollapse?.indexOf(staff.staffId) == -1)
            state.activeItemsCollapse.push(staff.staffId);
          state.highlightItemId = ser.uuid_local;
        }
      })
      .addCase(actions.selectSimpleMenuServiceItem, (state, { payload }) => {
        const data = payload as ISimpleMenuServiceItem;
        if (state.selectedItems.length === 0) {
          state.visibleChooseStaff = 'default';
          state.draftSimpleMenuServiceItem = data;
          return;
        }
        const staff = state.selectedItems.find(
          (o) => o.staffId === state.activeStaffId
        );

        if (staff) {
          const ser: IService = {
            itemId: data.id,
            itemName: data.serviceName,
            itemType: SERVICE_ITEM_TYPE.SIMPLE_MENU,
            price: data.price || 0,
            note: null,
            discount: 0,
            duration: 0,
            discountType: DiscountType.MONEY,
            uuid_local: uuid(),
            supplyFee: data.supplyFee || 0,
          };

          staff.services.push(ser);
          if (state.activeItemsCollapse?.indexOf(staff.staffId) == -1)
            state.activeItemsCollapse.push(staff.staffId);

          state.highlightItemId = ser.uuid_local;
        }
      })
      .addCase(actions.selectProductItem, (state, { payload }) => {
        const data = payload as IProductItemData;
        if (state.selectedItems.length === 0) {
          state.visibleChooseStaff = 'default';
          state.draftSelectProduct = data;
          return;
        }
        const staff = state.selectedItems.find(
          (o) => o.staffId === state.activeStaffId
        );

        if (staff) {
          const ser: IService = {
            itemId: data.id,
            itemName: data.productName,
            itemType: SERVICE_ITEM_TYPE.PRODUCT,
            price: data.priceSell || 0,
            note: null,
            discount: 0,
            duration: 0,
            discountType: DiscountType.MONEY,
            uuid_local: uuid(),
          };

          staff.services.push(ser);
          if (state.activeItemsCollapse?.indexOf(staff.staffId) == -1)
            state.activeItemsCollapse.push(staff.staffId);

          state.highlightItemId = ser.uuid_local;
        }
      })
      .addCase(actions.selectServiceItem, (state, { payload }) => {
        const data = payload as IServiceCategory;
        if (state.selectedItems.length === 0) {
          state.visibleChooseStaff = 'default';
          state.draftSelectService = data;
          return;
        }
        const staff = state.selectedItems.find(
          (o) => o.staffId === state.activeStaffId
        );

        if (staff) {
          const ser: IService = {
            itemId: data.id,
            itemName: data.serviceName,
            itemType: SERVICE_ITEM_TYPE.SERVICE,
            price: data.priceCost || data.priceSell || 0,
            discount: 0,
            note: null,
            duration: data.duration,
            discountType: DiscountType.MONEY,
            uuid_local: uuid(),
            supplyFee: data.supplyFee || 0,
          };
          if (data.discountValue) {
            set(ser, 'discount', data.discountValue);
            set(ser, 'discountType', data.discountType);
            set(ser, 'note', '');
            set(ser, 'discountSetting', { ownerPaidPercent: 100, staffPaidPercent: 0 });
          }
          staff.services.push(ser);
          if (state.activeItemsCollapse?.indexOf(staff.staffId) == -1)
            state.activeItemsCollapse.push(staff.staffId);
          state.highlightItemId = ser.uuid_local;
        }
      })
      .addCase(actions.switchStaffItem, (state, { payload }) => {
        const targetStaffId = payload;
        const draftServiceSwitchStaff = state.draftServiceSwitchStaff;

        const draftSwitchStaffValue = decodeDraftServiceSwitchStaffId(draftServiceSwitchStaff ?? '');

        if (draftSwitchStaffValue) {
          const draftItem = find(state.selectedItems, o => o.staffId === draftSwitchStaffValue.staffId);
          const draftService = find(draftItem?.services, (o: IService) => o.uuid_local === draftSwitchStaffValue.itemId);
          if (draftItem && draftService) {
            const switchService = cloneDeep(draftService);
            const targetSwitchStaff = find(state.selectedItems, o => o.staffId === targetStaffId);
            if (targetSwitchStaff) {
              targetSwitchStaff.services.push(switchService);
              remove(draftItem.services, o => o.uuid_local === draftSwitchStaffValue.itemId);
              state.draftServiceSwitchStaff = null;
              state.activeStaffId = targetStaffId;
            }
          }
        }
      })
      .addCase(
        actions.addStaffItem,
        (state, { payload }: PayloadAction<IStaff>) => {
          const services: IService[] = [];
          if (state.draftSelectService) {
            const ser: IService = {
              itemId: state.draftSelectService.id,
              itemName: state.draftSelectService.serviceName,
              itemType: SERVICE_ITEM_TYPE.SERVICE,
              price: state.draftSelectService.priceCost || state.draftSelectService.priceSell || 0,
              discount: 0,
              note: null,
              duration: state.draftSelectService.duration,
              discountType: DiscountType.MONEY,
              uuid_local: uuid(),
              supplyFee: state.draftSelectService.supplyFee || 0,
            };
            if (state.draftSelectService.discountValue) {
              set(ser, 'discount', state.draftSelectService.discountValue);
              set(ser, 'discountType', state.draftSelectService.discountType);
              set(ser, 'note', '');
              set(ser, 'discountSetting', { ownerPaidPercent: 100, staffPaidPercent: 0 });
            }
            services.push(ser);
            state.draftSelectService = null;
            state.highlightItemId = ser.uuid_local;
          } else if (state.draftSelectProduct) {
            const ser: IService = {
              itemId: state.draftSelectProduct.id,
              itemName: state.draftSelectProduct.productName,
              itemType: SERVICE_ITEM_TYPE.PRODUCT,
              price: state.draftSelectProduct.priceSell || 0,
              note: null,
              discount: 0,
              duration: 0,
              discountType: DiscountType.MONEY,
              uuid_local: uuid(),
            };
            services.push(ser);
            state.draftSelectProduct = null;
            state.highlightItemId = ser.uuid_local;
          } else if (state.draftSelectProductVIP) {

            state.selectedItems = state.selectedItems.map(o => ({
              ...o,
              services: o.services.filter(s => s.itemId !== SERVICE_ITEM_TYPE.VIP)
            }));

            const ser: IService = {
              itemId: state.draftSelectProductVIP.id,
              itemName: state.draftSelectProductVIP.vipName,
              itemType: SERVICE_ITEM_TYPE.VIP,
              price: state.draftSelectProductVIP.yearlyPrice || 0,
              note: null,
              discount: 0,
              duration: 0,
              discountType: DiscountType.MONEY,
              uuid_local: uuid(),
            };
            services.push(ser);
            state.draftSelectProductVIP = null;
            state.highlightItemId = ser.uuid_local;
          } else if (state.draftSelectGiftCard) {
            const { amount, code, expireDate } = state.draftSelectGiftCard;
            const ser: IService = {
              discount: 0,
              duration: 0,
              itemId: code,
              itemName: 'Gift Card',
              itemType: SERVICE_ITEM_TYPE.GIFT_CARD,
              note: null,
              price: amount,
              expireDate,
              discountSetting: null,
              discountType: DiscountType.MONEY,
              uuid_local: uuid(),
            };
            services.push(ser);
            state.draftSelectGiftCard = null;
            state.highlightItemId = ser.uuid_local;
          } else if (state.draftSimpleMenuServiceItem) {
            const { price, serviceName, id } = state.draftSimpleMenuServiceItem;
            const ser: IService = {
              itemId: id,
              itemName: serviceName || '',
              itemType: SERVICE_ITEM_TYPE.SIMPLE_MENU,
              price: price || 0,
              note: null,
              discount: 0,
              duration: 0,
              discountType: DiscountType.MONEY,
              uuid_local: uuid(),
              supplyFee: state.draftSimpleMenuServiceItem.supplyFee || 0,
            };
            services.push(ser);
            state.draftSimpleMenuServiceItem = null;
            state.highlightItemId = ser.uuid_local;
          }

          const newData: IStaffItem = {
            staffId: payload.staffId,
            staffName: payload.staffName,
            services,
            bookingTime: null,
            tip: null,
          };

          const firstSelected = first(state.selectedItems);

          if (firstSelected && !firstSelected.staffId) {
            firstSelected.staffId = newData.staffId;
            firstSelected.staffName = newData.staffName;
            if (state.activeItemsCollapse?.indexOf(newData.staffId) == -1)
              state.activeItemsCollapse.push(newData.staffId);
            state.activeStaffId = newData.staffId;
          } else {
            const exist = state.selectedItems.find(
              (o) => o.staffId === newData.staffId
            );
            if (!exist) {
              state.selectedItems.push(newData);
              if (state.activeItemsCollapse?.indexOf(newData.staffId) == -1)
                state.activeItemsCollapse.push(newData.staffId);
              state.activeStaffId = newData.staffId;
            }
          }

        }
      )
      .addCase(actions.setActiveStaffId, (state, { payload }) => {
        state.activeStaffId = payload;
      })
      .addCase(actions.removeServiceOfItem, (state, { payload }) => {
        const data = payload as { staffId: string; serviceId: string };
        state.selectedItems = state.selectedItems.map((o) => {
          if (o.staffId === data.staffId)
            return {
              ...o,
              services: o.services.filter((s) => s.uuid_local !== data.serviceId),
            };

          return o;
        });
        // state.itemHandleMode = null;
      })
      .addCase(shopActions.get.categories.success, (state, { payload }) => {
        state.activeCategory = first(payload) || null;
      })
      .addCase(shopActions.get.productCategories.success, (state, { payload }) => {
        state.activeProductCategory = first(payload) || null;
      })
      .addCase(actions.clearTicket, (state) => {
        state.selectedItems = [];
        state.ticketTotalInfo = {
          subtotal: 0,
          tipTicket: 0,
          saleTax: 0,
          usedTax: 0,
          discByItems: 0,
          discByTicket: 0,
          total: 0,
        };
        state.productTaxValue = 0;
        state.activeStaffId = null;
        state.activeItemsCollapse = [];
        state.highlightItemId = null;
        state.discountTicket = null;
      })
      .addCase(actions.initData, (state) => {
        state.ticketDetails = {
          checkInId: '',
          customerInfo: {
            customerName: '',
            customerId: '',
            point: 0,
          },
          ticketInfo: {
            ticketNumber: 0,
            createdDate: '',
            total: 0,
            subTotal: 0,
            tip: 0,
            discount: 0,
            tax: 0,
            saleTax: 0,
            useTax: 0,
            discountSetting: { ownerPaidPercent: 0, staffPaidPercent: 0 },
          },
          items: [],
        };
        state.ticketTotalInfo = {
          subtotal: 0,
          tipTicket: 0,
          saleTax: 0,
          usedTax: 0,
          discByItems: 0,
          discByTicket: 0,
          total: 0,
        };
        state.productTaxValue = 0;
        state.enableCardFee = state.feeCreditCard?.enable || false;
        state.selectedCustomer = null;
        state.customerList = [];
        state.selectedItems = [];
        state.activeStaffId = null;
        state.activeItemsCollapse = [];
        state.activeCategory = null;
        state.itemHandleMode = null;
        state.featureProductMode = FEATURE_PRODUCT_MODE.SERVICE;
        state.draftSelectProduct = null;
        state.draftSelectProductVIP = null;
        state.draftSelectService = null;
        state.visibleModalWarningEmptyServices = false;
        state.initialDetail = null;
        state.highlightItemId = null;
        state.discountTicket = null;
        state.visibleChooseStaff = null;
        state.activeQuickMenu = false;
        state.enableCashIncentive = true;
        state.previousDiscountTicket = null;
        state.customerReferral = null;
      });
  },
});

const cashierServiceReducer = Slice.reducer;
export const cashierUIActions = Slice.actions;
export default cashierServiceReducer;
