import React from 'react';
import styled from 'styled-components';
type IActionButtonsProps = {
  className?: string;
};
const ActionButtons = ({ className }: IActionButtonsProps) => {
  return (
    <Container className={className}>
      <button type='reset' className="btn"><span>Reset</span></button>
      <button type='submit' className="btn btn-save"><span>Save</span></button>
    </Container>
  );
};

export default ActionButtons;
const Container = styled.div`
    display: flex;
    padding: 16px 24px 24px 0px;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    padding-right: 0;
    button.btn {
      display: flex;
      width: 208px;
      padding: 16px 24px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 4px;
      background: #E5E6EB;
      box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
      span {
        color: #1D2129;
        text-align: center;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      &.btn-save {
        background: #FF8890;
        box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -4px 0px 0px #E7646D inset;
        span {
          color: var(--Text-01, #FFF);
        }
      }
    }
`;