import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types/reducer';
import { CALENDAR_VIEW_TYPE } from 'features/appointment/services/constants';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import moment from 'moment';
import { IParamsReviewReport } from './types/review';
import { IPrintCheckInfoDataSubmitValues } from '../pages/ReportPage/EmployeeSalary/PrintCheck/type';

const initialState: IState = {
  dataDemo: [],
  dataPrintSummarySalon: {},
  dataSummarySalon: {},
  dataSummaryServiceCategory: {},
  dataSummaryTicket: {},
  dataTopRankingService: [],
  dataTableSummarySalarySalon: [],
  dataTableSummarySalaryStaff: [],
  dataSummaryDashboardSalaryStaff: {},
  selectedStaffInfo: {},
  filterTypeSalaryStaff: 'ALL',
  viewType: {
    DEFAULT: CALENDAR_VIEW_TYPE.DAY_VIEW
  },
  params: {
    DEFAULT: {
      startTime: moment().format(DATE_FORMAT),
      endTime: moment().format(DATE_FORMAT),
    }
  },
  salaryStaffDetail: {},
  dataPrintSummaryServiceCategory: {},
  dataDashboardTimeClockReport: {},
  dataTimeClockReportHistory: [],
  dataPrintSalaryStaffDetailOneDay: {},
  dataPrintSalaryStaffDetailRangeDate: {},
  dataPrintSalarySummaryStaffOneDay: {},
  dataPrintSalarySummaryStaffRangeDate: {},
  dataPrintSummaryReportPaymentTypes: [],
  dataPrintTimeClockRange: {},

  review: {
    data: [],
    pagination: null,
    params: {},
    summary: null,
  },

  printCheckPreviewData: null,
  printCheckAll: [],
  checkNum: '',
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {
    savePrintCheckPreviewData: (state, { payload }: { payload: IPrintCheckInfoDataSubmitValues | null }) => {
      state.printCheckPreviewData = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(actions.getCheckNum.fetch, (state) => {
        state.checkNum = '';
      })
      .addCase(actions.getCheckNum.success, (state, { payload }) => {
        state.checkNum = payload;
      })
      .addCase(actions.getCheckNum.fail, (state) => {
        state.checkNum = '';
      })

      .addCase(actions.getPrintCheckAll.fetch, (state) => {
        state.printCheckAll = [];
      })
      .addCase(actions.getPrintCheckAll.success, (state, { payload }) => {
        state.printCheckAll = payload;
      })
      .addCase(actions.getPrintCheckAll.fail, (state) => {
        state.printCheckAll = [];
      })

      .addCase(actions.getReviewReportSummary.success, (state, { payload }) => {
        state.review.summary = payload;
      })
      .addCase(actions.setReviewReportParams, (state, { payload }) => {
        const _params: IParamsReviewReport = {
          ...state.review.params || {},
          ...payload || {},
        };

        if (_params.staffId === undefined) {
          delete _params.staffId;
        }

        if (_params.stars === undefined) {
          delete _params.stars;
        }

        if (_params.customerName === undefined) {
          delete _params.customerName;
        }

        state.review.params = _params;
      })
      .addCase(actions.initReviewReportParams, (state) => {
        const today = moment().format('MM-DD-YYYY');
        const _params: IParamsReviewReport = {
          endDate: today,
          startDate: today,
          page: 1,
          size: 20,
        };
        state.review = {
          data: [],
          pagination: null,
          params: _params,
          summary: null,
        };
      })
      .addCase(actions.getPrintSummaryPaymentTypes.fetch, (state) => {
        state.dataPrintSummaryReportPaymentTypes = [];
      })
      .addCase(actions.getPrintSummaryPaymentTypes.success, (state, { payload }) => {
        state.dataPrintSummaryReportPaymentTypes = payload;
      })
      .addCase(actions.getReviewReport.success, (state, { payload }) => {
        state.review.data = payload?.data || [];
        state.review.pagination = payload?.pageable || null;
      })
      .addCase(actions.getDemoData.success, (state, { payload }) => {
        state.dataDemo = payload;
      })
      .addCase(actions.getSummarySalon.success, (state, { payload }) => {
        state.dataSummarySalon = payload;
      })
      .addCase(actions.getPrintSummarySalon.success, (state, { payload }) => {
        state.dataPrintSummarySalon = payload;
      })
      .addCase(actions.getSummaryServiceCategory.success, (state, { payload }) => {
        state.dataSummaryServiceCategory = payload;
      })
      .addCase(actions.getSummaryTicket.success, (state, { payload }) => {
        state.dataSummaryTicket = payload;
      })
      .addCase(actions.getTopRankingService.success, (state, { payload }) => {
        state.dataTopRankingService = payload;
      })
      .addCase(actions.getSummarySalaryStaff.success, (state, { payload }) => {
        state.dataTableSummarySalaryStaff = payload;
      })
      .addCase(actions.getSummarySalarySalon.success, (state, { payload }) => {
        state.dataTableSummarySalarySalon = payload;
      })
      .addCase(actions.getSummaryDashboardSalaryStaff.success, (state, { payload }) => {
        state.dataSummaryDashboardSalaryStaff = payload;
      })
      .addCase(actions.setParams, (state, { payload }) => {
        const _params = {
          ...state.params,
          ...payload
        };
        state.params = _params;
      })
      .addCase(actions.setCalendarViewType, (state, { payload }) => {
        state.viewType = {
          ...state.viewType,
          ...payload
        };
      })
      .addCase(actions.setFilterTypeSalaryStaff, (state, { payload }) => {
        state.filterTypeSalaryStaff = payload;
      })
      .addCase(actions.getSalaryStaffDetail.success, (state, { payload }) => {
        state.salaryStaffDetail = payload;
      })
      .addCase(actions.getPrintSummaryServiceCategory.success, (state, { payload }) => {
        state.dataPrintSummaryServiceCategory = payload;
      })
      .addCase(actions.getDashboardTimeClockReport.success, (state, { payload }) => {
        state.dataDashboardTimeClockReport = payload;
      })
      .addCase(actions.getTimeClockReportHistory.success, (state, { payload }) => {
        state.dataTimeClockReportHistory = payload;
      })
      .addCase(actions.getPrintSalarySummaryStaffOneDay.success, (state, { payload }) => {
        state.dataPrintSalarySummaryStaffOneDay = payload;
      })
      .addCase(actions.getPrintSalarySummaryStaffRangeDate.success, (state, { payload }) => {
        state.dataPrintSalarySummaryStaffRangeDate = payload;
      })
      .addCase(actions.getPrintSalaryStaffDetailOneDay.success, (state, { payload }) => {
        state.dataPrintSalaryStaffDetailOneDay = payload;
      })
      .addCase(actions.getPrintSalaryStaffDetailRangeDate.success, (state, { payload }) => {
        state.dataPrintSalaryStaffDetailRangeDate = payload;
      })
      .addCase(actions.getPrintTimeClockRange.fetch, (state) => {
        state.dataPrintTimeClockRange = {};
      })
      .addCase(actions.getPrintTimeClockRange.success, (state, { payload }) => {
        state.dataPrintTimeClockRange = payload;
      })
      ;
  },
});

/** TODO:
 * please replace report => your feature name
 * ex:
 *      my feature is booking 
 *  name sagas like
 *      reportServiceReducer => bookingServiceReducers
 * 
 * // TODO:
 * Move to rootReducer (src/store/root/reducers.ts) and import your feature reducer
 */
export const reportUIActions = Slice.actions;
const reportServiceReducer = Slice.reducer;
export default reportServiceReducer;
