import Icon from 'assets/Icons';
import Modal from 'components/Modal';
import ticketListActions from 'features/ticketList/services/actions';
import ticketListSelectors from 'features/ticketList/services/selectors';
import { IEditStaffPayload } from 'features/ticketList/services/types/api';
import React, { useState } from 'react';
import { IStaffItemData } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import RequestAdminLocalPW from 'widgets/RequestAdminLocalPW';
import SwitchStaff from '../SwitchStaff';
import AddStaffFormV2 from 'widgets/Staff/AddStaffFormV2';
import refresh_icon from './refresh.svg';

type IEditStaffButtonProps = {
  staffId: string;
  v2?: boolean;
};
const EditStaffButton: React.FC<IEditStaffButtonProps> = ({ staffId, v2 }) => {
  const details = ticketListSelectors.ticketDetailsData.data();
  const dispatch = useAppDispatch();
  const [pinCode, setPinCode] = useState<string>('');
  const [visiblePW, setVisiblePW] = useState(false);
  const [openStaff, setOpenStaff] = useState(false);

  const handleShowModal = () => {
    setVisiblePW(true);
  };

  const handleClose = () => setVisiblePW(false);

  const onVerifyAdminSuccess = (pinCode?: string) => {
    setPinCode(pinCode || '');
    setVisiblePW(false);
    setOpenStaff(true);
  };

  const handleCloseStaffModal = () => {
    setPinCode('');
    setOpenStaff(false);
  };

  const handleSwitchStaff = (data: IStaffItemData) => {
    const payload: IEditStaffPayload = {
      billId: details?.billId,
      existStaffId: staffId || '',
      newStaff: data,
      pinCode: pinCode,
    };
    dispatch(ticketListActions.editStaff.fetch(payload));
    handleCloseStaffModal();
  };

  return (
    <EditStaffButtonStyled>
      {v2 ?
        <button className='button-refresh' onClick={handleShowModal}>
          <img src={refresh_icon} alt="refresh_icon" style={{ width: 40, height: 40 }} />
        </button>
        : <button onClick={handleShowModal}>
          <Icon type='edit' fill='#6FABB6' />
        </button>}
      {visiblePW && (
        <RequestAdminLocalPW
          onlyAdmin
          visible
          v2={v2}
          onCorrect={onVerifyAdminSuccess}
          onClose={handleClose}
        />
      )}
      {v2 ? (
        <Modal
          v2
          visible={!!openStaff}
          modalTitle='SELECT STAFF'
          noneBodyStyle
          hiddenOkButton
          onClose={handleCloseStaffModal}
          width={1600}
        >
          <AddStaffFormV2
            onPickStaff={handleSwitchStaff}
          />
        </Modal>
      ) : <>
        {openStaff && (
          <Modal
            noneBodyStyle={true}
            visible={openStaff}
            // cancelTitle="Cancel"
            onClose={handleCloseStaffModal}
            modalTitle="EMPLOYEE LIST"
            hiddenOkButton={true}
            width={'70%'}
            footerPadding={1.5}
            destroyOnClose={true}
          >
            <SwitchStaff onPickStaff={handleSwitchStaff} />
          </Modal>
        )}
      </>}
    </EditStaffButtonStyled>
  );
};

export default EditStaffButton;
const EditStaffButtonStyled = styled.div`
.button-refresh {
  border-radius: 4px;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: #eee;
  }
}
`;
