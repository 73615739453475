import { Menu, MenuProps } from 'antd';
import Box from 'components/Box';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Turn from '../../Turn';
import LateForWork from '../../Turn/LateForWork';
import QuickPayTurnSetting from './QuickPayTurnSetting';
import { useAppDispatch } from 'store/hooks';
import settingServiceActions from 'features/settingService/services/actions';
import { MenuTabs } from '../v2/style';
import { get } from 'lodash';
import tab_turn_icon from './v2-icons/turn/tab-turn.svg';
import tab_turn_active_icon from './v2-icons/turn/tab-turn-active.svg';
import tab_quick_pay_icon from './v2-icons/turn/tab-quick-pay.svg';
import tab_quick_pay_active_icon from './v2-icons/turn/tab-quick-pay-active.svg';
import tab_late_for_work_icon from './v2-icons/turn/tab-late-time.svg';
import tab_late_for_work_active_icon from './v2-icons/turn/tab-late-time-active.svg';
import tab_calendar_icon from './v2-icons/turn/tab-calendar.svg';
import tab_calendar_active_icon from './v2-icons/turn/tab-calendar-active.svg';
import clsx from 'clsx';
import TurnAppointmentSetting from './TurnAppointmentSetting';

type MenuItem = Required<MenuProps>['items'][number];
type ITurnTabProps = {
  v2?: boolean;
};
enum MenuKeys {
  TURN = 'turn',
  LATE_FOR_WORK = 'late_for_work',
  QUICK_PAY = 'quick_pay',
  APPOINTMENT = 'appointment',
}

const items: MenuItem[] = [
  getItem('Turn', MenuKeys.TURN),
  getItem('Quick Pay', MenuKeys.QUICK_PAY),
  getItem('Late For Work', MenuKeys.LATE_FOR_WORK),
];
const TurnTab: React.FC<ITurnTabProps> = ({ v2 }) => {
  const dispatch = useAppDispatch();
  const [selectedKey, setSelectedKey] = useState<MenuKeys>(MenuKeys.TURN);

  const handleMenuSelect = (info: { key: string }) => {
    setSelectedKey(info.key as MenuKeys);
  };

  const onChooseTab = (key: MenuKeys) => () => {
    setSelectedKey(key);
  };

  useEffect(() => {
    dispatch(settingServiceActions.getQuickPayTurnList.fetch());
  }, []);

  return (
    <TurnTabStyled className={v2 ? 'v2' : ''}>
      <BoxStyled display="flex" p={v2 ? undefined : '4'} gap="4" alignItems="stretch">
        <LeftBoxStyled className='LeftBoxStyled'>
          {v2 ? <MenuTabs>
            <MenuTabItem onClick={onChooseTab(MenuKeys.TURN)} active={selectedKey === MenuKeys.TURN} type={MenuKeys.TURN} />
            <MenuTabItem onClick={onChooseTab(MenuKeys.QUICK_PAY)} active={selectedKey === MenuKeys.QUICK_PAY} type={MenuKeys.QUICK_PAY} />
            <MenuTabItem onClick={onChooseTab(MenuKeys.LATE_FOR_WORK)} active={selectedKey === MenuKeys.LATE_FOR_WORK} type={MenuKeys.LATE_FOR_WORK} />
            <MenuTabItem onClick={onChooseTab(MenuKeys.APPOINTMENT)} active={selectedKey === MenuKeys.APPOINTMENT} type={MenuKeys.APPOINTMENT} />
          </MenuTabs> :
            <MenuBoxStyled>
              <Menu
                style={{ width: '100%' }}
                defaultSelectedKeys={[MenuKeys.TURN]}
                items={items}
                onSelect={handleMenuSelect}
              />
            </MenuBoxStyled>}
        </LeftBoxStyled>
        {selectedKey === MenuKeys.TURN && <Turn v2={v2} />}
        {selectedKey === MenuKeys.LATE_FOR_WORK && <LateForWork v2={v2} />}
        {selectedKey === MenuKeys.QUICK_PAY && <QuickPayTurnSetting v2={v2} />}
        {selectedKey === MenuKeys.APPOINTMENT && <TurnAppointmentSetting />}
      </BoxStyled>
    </TurnTabStyled>
  );
};

export default TurnTab;
const configs = {
  [MenuKeys.TURN]: {
    label: 'Turn',
    icon: tab_turn_icon,
    icon_active: tab_turn_active_icon,
  },
  [MenuKeys.QUICK_PAY]: {
    label: 'Quick Pay',
    icon: tab_quick_pay_icon,
    icon_active: tab_quick_pay_active_icon,
  },
  [MenuKeys.LATE_FOR_WORK]: {
    label: 'Late For Work',
    icon: tab_late_for_work_icon,
    icon_active: tab_late_for_work_active_icon,
  },
  [MenuKeys.APPOINTMENT]: {
    label: 'Appointment',
    icon: tab_calendar_icon,
    icon_active: tab_calendar_active_icon,
  },
};

type IMenuTabItemProps = {
  active?: boolean;
  onClick?: () => void;
  type: MenuKeys;
};
const MenuTabItem = ({ type, active, onClick }: IMenuTabItemProps) => {
  const config = get(configs, type);
  if (!config) return null;
  return (
    <div className={clsx('menu-tab-item', active && 'active')} onClick={onClick}>
      <img src={active ? config.icon_active : config.icon} alt={config.label} />
      <span>{config.label}</span>
    </div>
  );
};

const TurnTabStyled = styled.div`
  display: flex;
  flex-direction: column;
  background: #e5e5e5;
  height: calc(100vh - 8.75rem);
  min-width: 50rem;
  overflow: auto;

  &.v2 {
    background-color: #F5F5F5;
  }
`;

const BoxStyled = styled(Box)`
  display: flex;
  flex-grow: 1;
  align-items: stretch;
`;

const LeftBoxStyled = styled.div`
  border-radius: 5px;
  min-width: 16.5rem;
  gap: 1.5rem;
`;

const MenuBoxStyled = styled.div`
  padding: 0.25rem 0;
  .ant-menu-item {
    margin: 0;
    width: 100%;
    border-radius: 0;
  }
`;

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}
