import { ISagaFunc } from 'services/actionConfigs';
import {
  Bill,
  IAdjustTip,
  IApiGetTicketsParam,
  IApiModifyTipCashBody,
  IEditStaffByBillId,
  IEditStaffPayload,
  IParamSummaryCreditCards,
  IRefundBody,
  IResponseTicket,
} from './types/api';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import apis from './apis';
import actions from './actions';
import { AxiosResponse } from 'axios';
import uiActions from 'services/UI/actions';
import { PATH_LOADING } from './constants';
import { getStoreAdjustTips, getTicketsFilterParams } from './selectors';
import shopSelectors from 'services/shop/selectors';
import { IShopAllSetting } from 'services/shop/types/setting';
import { setLoadingPage } from 'services/UI/sagas';
import { IResponseDataBody } from 'services/response';
import userActions from 'features/user/services/actions';
import { ISummaryCreditCardResData } from './types/creditCard';
import { IApiSplitTicketItem } from './types/ticket';

const getTicketsData: ISagaFunc<IApiGetTicketsParam> = function* ({ payload }) {
  yield put(
    uiActions.setLoading({ path: PATH_LOADING.getTicketsData, result: true })
  );
  yield delay(200);
  const params: IApiGetTicketsParam = payload;
  try {
    const resData: AxiosResponse<{ data: IResponseTicket }> = yield call(
      apis.getTicketsData,
      params
    );
    if (resData?.data?.data) {
      yield put(actions.getTickets.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getTickets.fail({}));
  } finally {
    yield put(
      uiActions.setLoading({ path: PATH_LOADING.getTicketsData, result: false })
    );
  }
};

const setTicketsParams: ISagaFunc<IApiGetTicketsParam> = function* ({
  payload,
}) {
  const storeParams = yield select(getTicketsFilterParams);
  const _params: IApiGetTicketsParam = {
    ...(storeParams ?? {}),
    ...(payload ?? {}),
  };

  if (_params.staffId === '' || _params.staffId === undefined || _params.staffId === null) {
    delete _params.staffId;
  }
  if (_params.ticketNumber === '') {
    delete _params.ticketNumber;
  }
  if (_params.last4Num === '') {
    delete _params.last4Num;
  }
  if (_params.paymentType === undefined) {
    delete _params.paymentType;
  }

  yield put(actions.getTickets.fetch(_params));
  if (_params.status === 'CLOSED')
    yield put(actions.getSummaryTickets.fetch(_params));
};

const getTicketDetailsData: ISagaFunc<string> = function* ({ payload }) {
  yield put(
    uiActions.setLoading({
      path: PATH_LOADING.getTicketDetailsData,
      result: true,
    })
  );
  yield delay(200);
  const id: string = payload;
  try {
    const resData: AxiosResponse<{ data: Bill }> = yield call(
      apis.getTicketDetailsData,
      id
    );
    if (resData?.data?.data) {
      yield put(actions.getTicketDetails.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getTicketDetails.fail({}));
  } finally {
    yield put(
      uiActions.setLoading({
        path: PATH_LOADING.getTicketDetailsData,
        result: false,
      })
    );
  }
};

const doPrintVoidTicket: ISagaFunc<string> = function* ({ payload }) {
  yield setLoadingPage(true);
  const id: string = payload;
  try {
    const resData: AxiosResponse<{ data: Bill }> = yield call(
      apis.getTicketDetailsData,
      id
    );
    if (resData?.data?.data) {
      yield put(actions.doPrintVoidTicket.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.doPrintVoidTicket.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};

const editStaffByBillId: ISagaFunc<IEditStaffPayload> = function* ({
  payload,
}) {
  yield put(
    uiActions.setLoading({
      path: PATH_LOADING.getTicketDetailsData,
      result: true,
    })
  );
  yield delay(200);
  try {
    const body: IEditStaffByBillId = {
      billId: payload.billId,
      staffIdNew: payload.newStaff.id,
      staffIdOld: payload.existStaffId,
      pinCode: payload.pinCode,
    };
    const resData: AxiosResponse<{ data: any }> = yield call(
      apis.changeStaffByBillId,
      body
    );
    if (resData?.data?.data) {
      yield put(actions.editStaff.success(payload));
    }
  } catch (error) {
    yield put(actions.editStaff.fail({}));
  } finally {
    yield put(
      uiActions.setLoading({
        path: PATH_LOADING.getTicketDetailsData,
        result: false,
      })
    );
  }
};

const refundTicketByBillId: ISagaFunc<IRefundBody> = function* ({ payload }) {
  yield put(yield put(uiActions.setLoadingPage(true)));
  yield delay(200);

  try {
    if (payload) {
      const resData: AxiosResponse<{ data: any }> = yield call(
        apis.refundByBillId,
        payload
      );
      if (resData?.data?.data) {
        yield put(actions.refundTicketByBillId.success(resData.data.data));
        yield put(actions.getTicketDetails.fetch(payload.billId));
      }
    }
  } catch (error) {
    yield put(actions.refundTicketByBillId.fail({}));
  } finally {
    yield put(uiActions.setLoadingPage(false));
  }
};
const voidTicketByBillId: ISagaFunc<{ id: string; reason: string }> =
  function* ({ payload }) {
    const allSetting: IShopAllSetting = yield select(shopSelectors.pureData.allSetting);

    yield put(uiActions.setLoadingPage(true));
    yield delay(200);

    try {
      if (payload.id) {
        const resData: AxiosResponse<{ data: any }> = yield call(
          apis.voidByBillId,
          payload
        );
        if (resData?.data?.data) {
          yield put(actions.voidTicketByBillId.success(resData.data.data));
          yield put(actions.getTicketDetails.fetch(payload.id));
          const storeParams = yield select(getTicketsFilterParams);
          yield put(actions.getTickets.fetch(storeParams));
          if (allSetting?.printVoidedTicket) {
            yield put(actions.doPrintVoidTicket.fetch(payload.id));
          }
        }
      }
    } catch (error) {
      yield put(actions.voidTicketByBillId.fail({}));
    } finally {
      yield put(uiActions.setLoadingPage(false));
    }
  };
const adjustTip: ISagaFunc<{ billId?: string }> = function* ({ payload }) {
  yield put(uiActions.setLoadingPage(true));
  yield delay(200);

  try {
    const storeAdjustTipList: IAdjustTip[] = yield select(getStoreAdjustTips);
    if (payload.billId) {
      const body = storeAdjustTipList.filter(
        (item) => item.billId === payload.billId
      );
      const resData: AxiosResponse<{ data: any }> = yield call(
        apis.adjustTip,
        body
      );
      if (resData?.data?.data) {
        const successPayload = storeAdjustTipList.filter(
          (item) => item.billId !== payload.billId
        );
        yield put(actions.adjustTip.success(successPayload));
        const storeParams = yield select(getTicketsFilterParams);
        yield put(actions.getTickets.fetch(storeParams));
      }
    } else {
      const resData: AxiosResponse<{ data: any }> = yield call(
        apis.adjustTip,
        storeAdjustTipList
      );
      if (resData?.data?.data) {
        yield put(actions.adjustTip.success([]));
        const storeParams = yield select(getTicketsFilterParams);
        yield put(actions.getTickets.fetch(storeParams));
      }
    }
  } catch (error) {
    yield put(actions.adjustTip.fail({}));
  } finally {
    yield put(uiActions.setLoadingPage(false));
  }
};

const removePendingTicket: ISagaFunc<string> = function* ({ payload }) {
  yield put(uiActions.setLoadingPage(true));
  try {
    const res: IResponseDataBody<boolean> = yield call(apis.removePendingTicket, payload);
    if (res.data.data) {
      const storeParams = yield select(getTicketsFilterParams);
      yield put(actions.getTickets.fetch(storeParams));
      yield put(userActions.getBillWaitingTicket.fetch());
    }
  } catch (error) { }
  finally {
    yield put(uiActions.setLoadingPage(false));
  }
};

const afterDeleteClosedTicket = function* () {
  const storeParams: IApiGetTicketsParam = yield select(getTicketsFilterParams);
  yield all([
    put(actions.getTickets.fetch(storeParams)),
    put(userActions.getBillWaitingTicket.fetch()),
  ]);
};

const editCashByBillId: ISagaFunc<{ amount: number; billId: string }> =
  function* ({ payload }) {
    yield put(uiActions.setLoadingPage(true));
    yield delay(200);
    try {
      if (payload.billId) {
        const resData: AxiosResponse<{ data: any }> = yield call(
          apis.editCashByBillId,
          payload
        );
        if (resData.data.data) {
          yield put(actions.editCashByBillId.success(payload));
        }
      }
    } catch (error) {
      yield put(actions.editCashByBillId.fail({}));
    } finally {
      yield put(uiActions.setLoadingPage(false));
    }
  };

const getSummaryCreditCards: ISagaFunc<IParamSummaryCreditCards> = function* ({ payload }) {
  yield delay(200);
  try {
    const res: IResponseDataBody<ISummaryCreditCardResData> = yield call(apis.getSummaryCreditCards, payload);
    if (res.data.data) {
      yield put(actions.getSummaryCreditCards.success(res.data.data));
    }
  } catch (error) { }
};

const modifyTipCash: ISagaFunc<IApiModifyTipCashBody> = function* ({ payload }) {
  yield put(uiActions.setLoadingPage(true));
  try {
    const res: IResponseDataBody<boolean> = yield call(apis.modifyTipCash, payload);
    if (res.data.data) {
      const resData: AxiosResponse<{ data: Bill }> = yield call(
        apis.getTicketDetailsData,
        payload.billId
      );
      if (resData?.data?.data) {
        yield put(actions.getTicketDetails.success(resData.data.data));
      }
    }
  } catch (error) { }
  finally {
    yield put(uiActions.setLoadingPage(false));
  }
};

const getSummaryTickets: ISagaFunc<IApiGetTicketsParam> = function* ({ payload }) {
  yield delay(200);
  try {
    const res: IResponseDataBody<any> = yield call(apis.getSummaryTickets, payload);
    if (res.data.data) {
      yield put(actions.getSummaryTickets.success(res.data.data));
    }
  } catch (error) {
    yield put(actions.getSummaryTickets.fail({}));
  }
};

const splitCloseTicket: ISagaFunc<{ billId: string, data: IApiSplitTicketItem[] }> = function* ({ payload }) {
  yield put(uiActions.setLoadingPage(true));
  try {
    const res: IResponseDataBody<boolean> = yield call(apis.splitCloseTicket, payload.billId, payload.data);
    if (res.data.data) {
      const resData: AxiosResponse<{ data: Bill }> = yield call(
        apis.getTicketDetailsData,
        payload.billId
      );
      if (resData?.data?.data) {
        yield put(actions.getTicketDetails.success(resData.data.data));
      }
    }
  } catch (error) { }
  finally {
    yield put(uiActions.setLoadingPage(false));
  }
};

export default function* ticketListServiceSagas() {
  yield takeLatest(actions.getTickets.fetch, getTicketsData);
  yield takeLatest(actions.getTicketDetails.fetch, getTicketDetailsData);
  yield takeLatest(actions.doPrintVoidTicket.fetch, doPrintVoidTicket);
  yield takeLatest(actions.setTicketsParams, setTicketsParams);
  yield takeLatest(actions.refundTicketByBillId.fetch, refundTicketByBillId);
  yield takeLatest(actions.voidTicketByBillId.fetch, voidTicketByBillId);
  yield takeLatest(actions.editStaff.fetch, editStaffByBillId);
  yield takeLatest(actions.adjustTip.fetch, adjustTip);
  yield takeLatest(actions.editCashByBillId.fetch, editCashByBillId);
  yield takeLatest(actions.removePendingTicket, removePendingTicket);
  yield takeLatest(actions.afterDeleteClosedTicket, afterDeleteClosedTicket);
  yield takeLatest(actions.getSummaryCreditCards.fetch, getSummaryCreditCards);
  yield takeLatest(actions.modifyTipCash, modifyTipCash);
  yield takeLatest(actions.splitCloseTicket, splitCloseTicket);
  yield takeLatest(actions.getSummaryTickets.fetch, getSummaryTickets);
}
