import styled from 'styled-components';
import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import Text from 'components/Text';
import { formatCurrency } from 'utils/formatCurrency';
import shopSelectors from 'services/shop/selectors';
import Button from 'components/Button';
import Modal from 'components/Modal';
type Props = {
  isCustomer?: boolean;
  onCancel?: () => void;
  onDone?: (activeMethod: string, amount: number) => void;
};
type Ref = {
  open: (activeMethod: string, amount: number) => void;
  close: () => void;
};
export const useSimpleQRModalRef = () => useRef<Ref>(null);
const SimpleQRModal = forwardRef<Ref, Props>(({ isCustomer, onCancel = () => undefined, onDone = () => undefined }, ref) => {
  const [visible, setVisible] = useState(false);
  const [amount, setAmount] = useState<number>(0);
  const [activeMethod, setActiveMethod] = useState<string | null>(null);
  const payments = shopSelectors.data.orderPaymentMethods();
  const activePayment = useMemo(() => {
    return payments.find(o => o.methodType === activeMethod);
  }, [activeMethod, payments]);
  const onClose = () => setVisible(false);
  const handleCancel = () => {
    onCancel();
    onClose();
  };

  const handleDone = () => {
    if (!activeMethod) return;
    onDone(activeMethod, amount);
    onClose();
  };

  useImperativeHandle(ref, () => ({
    open: (_activeMethod, _amount) => {
      setVisible(true);
      setActiveMethod(_activeMethod || null);
      setAmount(_amount || 0);
    },
    close: () => onClose(),
  }));

  if (!activeMethod) return null;

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      onSubmit={onClose}
      width={'100vw'}
      footer={<></>}
      noneBodyStyle
      noneFooterStyle
      hiddenHeader
      rootClassName={'modal-add-tip'}
      className='modal-overflow-unset modal-max-height-unset'
    >
      <QRPaymentPageStyled>
        <div className='QR-container'>
          <div className='wrapper-QR'>
            <Text className="price" >{formatCurrency(amount)}</Text>
            <Text className="title" >Scan to pay with {activePayment?.methodName}</Text>
            <BoxQR>
              <img
                src={activePayment?.imageQrCode}
                className='imageQR'
              />
            </BoxQR>
            {!isCustomer && <div className='qr-actions'>
              <Button onClick={handleCancel} width="8.25rem" ntype="DEFAULT" >
                Cancel
              </Button>
              <Button onClick={handleDone} width="8.25rem" ntype="PRIMARY" >
                Done
              </Button>
            </div>}
          </div>
        </div>
      </QRPaymentPageStyled>
    </Modal>
  );
});
SimpleQRModal.displayName = 'SimpleQRModal';
export default SimpleQRModal;

const BoxQR = styled.div`
  border-radius: 15.37px;
  background: #FFF;
  box-shadow: -0.76851px 0.76851px 3.07404px 0px rgba(0, 0, 0, 0.10) inset, 0px 3.07404px 3.07404px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // width: 50vw;
  padding: 1.5rem;
  max-width: 36.5rem;
  margin-bottom: 1rem;
  .imageQR {
    height: 35rem;
    width: 35rem;
    border-radius: 16px;
  }
  .imageIcon {
    width: 11rem;
    height:3rem;
    display: flex;
    justify-content: center;
    img {
      width: auto;
      height: 100%;
    }
  }
`;

const QRPaymentPageStyled = styled.div`
  background: #E5E5E5;
  display: flex;
  flex-direction: row;
  height: 100vh;

  .title { 
    color: var(--text-text-3, #1D2129);
    font-family: Open Sans;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .price {
    color: var(--text-text-3, #1D2129);
    font-family: Open Sans;
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 24px;
  } 
  .QR-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5%;
    .wrapper-QR {
      display: flex;
      flex-direction: column;
      align-items: center;
      
    }
    .qr-actions {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
`;