import { Form } from 'antd';
import clsx from 'clsx';
import { DollarAmountInputRef } from 'components/DollarAmount/helpers';
import { find, get } from 'lodash';
import React, { useMemo } from 'react';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import PassValueForm from './PassValueForm';
import { KB_TYPES, MULTI_PAYMENT_TYPES, multiPaymentConfigs } from './constants';
import e_pay_icon from './icons/e-pay.svg';
import DollarInputPayment from 'components/DollarAmount/DollarInputPayment';
import { calcMaxPayment, calcRemaining } from './helpers';

type Props = {
  onFocus: (type: KB_TYPES) => () => void;
  cashAppRef: React.RefObject<DollarAmountInputRef>;
  venmoRef: React.RefObject<DollarAmountInputRef>;
  paypalRef: React.RefObject<DollarAmountInputRef>;
  zelleRef: React.RefObject<DollarAmountInputRef>;
};
const cashAppConfig = get(multiPaymentConfigs, [MULTI_PAYMENT_TYPES.CASH_APPS]);
const venmoConfig = get(multiPaymentConfigs, [MULTI_PAYMENT_TYPES.VENMO]);
const paypalConfig = get(multiPaymentConfigs, [MULTI_PAYMENT_TYPES.PAYPAL]);
const zelleConfig = get(multiPaymentConfigs, [MULTI_PAYMENT_TYPES.ZELLE]);
const EPayInputs = ({ onFocus, cashAppRef, venmoRef, paypalRef, zelleRef }: Props) => {
  const payments = shopSelectors.data.orderPaymentMethods();

  const paymentConfigs = useMemo(() => {
    const venmo = find(payments, o => o.methodType === MULTI_PAYMENT_TYPES.VENMO);
    const cashApp = find(payments, o => o.methodType === MULTI_PAYMENT_TYPES.CASH_APPS);
    const zelle = find(payments, o => o.methodType === MULTI_PAYMENT_TYPES.ZELLE);
    const paypal = find(payments, o => o.methodType === MULTI_PAYMENT_TYPES.PAYPAL);

    return ({
      venmo, cashApp, zelle, paypal,
      length: [venmo, cashApp, zelle, paypal].filter(Boolean).length
    });
  }, [payments]);

  if (!payments.length) return null;

  return (
    <Container className={clsx(`length_${paymentConfigs.length}`)}>
      <div className="payment-label">
        <img src={e_pay_icon} alt={'e_pay_icon'} style={{ width: 48, height: 48 }} />
        <span>E-PAYS</span>
      </div>
      <Payments>
        {!!paymentConfigs.cashApp && <EPayItem
          name={MULTI_PAYMENT_TYPES.CASH_APPS} inputRef={cashAppRef}
          config={cashAppConfig} onFocus={onFocus}
        />}
        {!!paymentConfigs.venmo && <EPayItem
          name={MULTI_PAYMENT_TYPES.VENMO} inputRef={venmoRef}
          config={venmoConfig} onFocus={onFocus}
        />}
        {!!paymentConfigs.paypal && <EPayItem
          name={MULTI_PAYMENT_TYPES.PAYPAL} inputRef={paypalRef}
          config={paypalConfig} onFocus={onFocus}
        />}
        {!!paymentConfigs.zelle && <EPayItem
          name={MULTI_PAYMENT_TYPES.ZELLE} inputRef={zelleRef}
          config={zelleConfig} onFocus={onFocus}
        />}
      </Payments>
      <TotalContentStyled>
        <PassValueForm
          name={MULTI_PAYMENT_TYPES.CASH_APPS}
          render={value => {
            return <span>{formatCurrency(value)}</span>;
          }}
        />
      </TotalContentStyled>
    </Container>
  );
};

export default EPayInputs;
type EPayItemProps = {
  name: MULTI_PAYMENT_TYPES;
  inputRef: React.RefObject<DollarAmountInputRef>;
  onFocus: (type: KB_TYPES) => () => void;
  config: { label: string; icon: any; } | null
};
const EPayItem = ({ onFocus, name, inputRef, config }: EPayItemProps) => {
  const form = Form.useFormInstance();

  return (
    <div className="ePay-item">
      <Form.Item shouldUpdate noStyle dependencies={['detail']}>
        {({ getFieldValue, getFieldsValue }) => {
          const maxValue = calcMaxPayment(getFieldsValue(), name);
          return (
            <Form.Item name={name} style={{ alignSelf: 'stretch' }} rules={[
              ({ }) => ({
                validator(_, value) {
                  if (value && value > maxValue) {
                    return Promise.reject(new Error(`Value cannot exceed ${formatCurrency(maxValue)}`));
                  }
                  return Promise.resolve();
                },
              }),
            ]}>
              <DollarInputPayment
                ref={inputRef}
                inputMode='none'
                onFocus={onFocus(name)}
                onChange={() => form.validateFields([name])}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
      <div className="ePay payment-label">
        <img src={config?.icon} alt={config?.label} style={{ width: 32, height: 32 }} />
        <span>{config?.label}</span>
      </div>
    </div>
  );
};

const TotalContentStyled = styled.div`
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-top: 1px solid #CCD4DC;
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
  span {
    align-self: stretch;
    text-align: center;
    color: #1D2129;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
const Payments = styled.div`
display: flex;
align-items: flex-start;
padding: 0 16px;
flex: 1 0 0;
align-self: stretch;
gap: 4px;
.ePay-item {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 150px;
  border-right: 1px solid #CCD4DC;
  padding-right: 4px;

  &:last-child {
    border-right: none;
    padding-right: 0;
  }

  .custom-dollar-input {
    margin: unset !important;
  }
  div.ePay.payment-label {
    display: flex;
    align-items: center;
    span {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 166.667% */
      text-transform: capitalize;
    }
  }
}
`;
const Container = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
  position: relative;
  z-index: 1;

  &.length_1 {
    width: 270px;
    .ePay-item  {
      width: 100%;
      div.ePay.payment-label {
        font-size: 20px;
      }
    }
  }

  &.button:hover {
    cursor: pointer;
  }
  .overlay {
    position: absolute;
    z-index:99;
    inset: 0;
  }
  div.payment-label {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 42px;
    align-self: stretch;
    justify-content: center;
    margin-bottom: 12px;
    padding-top: 8px;
    span {
      color: #C84B31;
      text-align: center;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }

    &.button:hover {
      cursor: pointer;
    }
  }

.dollar-input-selector {
  align-self: stretch;
  height: 3.5rem;
  justify-content: center;
  .dollar-input-text {
    font-size: 32px;
    color: #1D2129;
    font-weight: 600;
  }

  &[aria-disabled="true"], &[aria-disabled="true"] input {
    background: #eee;
    opacity: 0.8;
  }
}
`;
