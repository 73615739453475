import { CALENDAR_TIME_SET } from 'features/appointment/services/constants';
import moment from 'moment';
import React, { memo, useEffect, useRef } from 'react';
import { styled } from 'styled-components';
import CalendarViewBody from './Body';
import HeadContent from './HeadContent';
import CalendarViewHeader from './Header';
import LoadingCalendar from './Loading';
import useCalendarFilter, { modalConfirmRef } from './hooks/useCalendarFilter';
import useCalendarMapping from './hooks/useCalendarMapping';
import CalendarStyled, { PIXEL_PER_MINUTE } from './styles';
import appointmentSelectors from 'features/appointment/services/selectors';
import { momentTimezone } from 'utils/time';
import ModalConfirm from 'components/Modal/ModalConfirm';

const getTop = (dateStr?: string) => { //TODO
  const { close, open } = CALENDAR_TIME_SET;
  const now = dateStr ? momentTimezone(dateStr) : moment();
  const currentSpaceMinutes = now.diff(open, 'minutes');
  let space = 0;
  if (now.isBetween(open, close))
    space = PIXEL_PER_MINUTE * currentSpaceMinutes;
  return space;
};

type ICalendarTableViewProps = {
  modal?: boolean;
  v2?: boolean;
};
const CalendarTableView: React.FC<ICalendarTableViewProps> = ({ modal, v2 }) => {
  const calendarRef = useRef<HTMLTableElement>(null);
  const schema = useCalendarFilter({ modal });
  const body = useCalendarMapping(schema);
  const startTime = appointmentSelectors.syncTimeItem();

  const scrollTo = (top: number) => {
    setTimeout(() => calendarRef.current?.scrollTo({ top: top, behavior: 'smooth' }), 0);
  };

  useEffect(() => {
    scrollTo(getTop(startTime));
  }, []);

  return (
    <>
      {!modal && (!v2 && <HeadContent />)}
      <CalendarTableViewStyled id='calendar' className={v2 ? 'v2' : ''}>
        <CalendarTableOverflowStyled ref={calendarRef}>
          <div className="calendar">
            <CalendarStyled.Table typeView={schema.typeView} >
              <CalendarViewHeader schema={schema} />
              <CalendarViewBody v2={v2} modal={modal} body={body} distanceTimeline={schema.distanceTimeline} />
            </CalendarStyled.Table>
          </div>
        </CalendarTableOverflowStyled>
        <LoadingCalendar />
        <ModalConfirm ref={modalConfirmRef} />
      </CalendarTableViewStyled>
    </>
  );
};

export default memo(CalendarTableView);

type CalendarTableViewStyledProps = {}
const CalendarTableViewStyled = styled.div<CalendarTableViewStyledProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 1;
  flex-shrink: 0;
  &.v2 {
    .fixedHigh {
      background: #fff;
    }
    table td .box-time .text-time {
      color: #1D2129;
      text-align: right;
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 19.5px; /* 150% */
    }
    table thead th {
      .subtext {
        font-size: 20px;
        text-transform: uppercase;
      }
      .AnyBodyImage {
        width: 32px !important;
        height: 32px !important;
      }
    } 
  }
`;

const CalendarTableOverflowStyled = styled.div`
position: relative;
  max-height: 100%;
  height:100%;
  z-index: 1;
  max-width: 100%;
  overflow: scroll;
  .calendar {
    position: absolute;
    inset: 0;
  }
`;