import { Avatar, Row, Switch } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import ModalConfirm, { useModalConfirmRef } from 'components/Modal/ModalConfirm';
import Table from 'components/Table';
import Text from 'components/Text';
import { TableGreenEvenStyled } from 'features/report/components/Component';
import settingStaffActions from 'features/settingStaff/services/actions';
import { getStaffPositionLabel } from 'features/settingStaff/services/constants';
import settingStaffSelectors from 'features/settingStaff/services/selectors';
import { useAppNavigate } from 'hooks/useAppNavigate';
import moment from 'moment';
import React, { useState } from 'react';
import { IStaffItemData } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatPhone } from 'utils/formatPhone';
import RequestAdminLocalPW from 'widgets/RequestAdminLocalPW';

type IStaffTableProps = { className?: string, v2?: boolean };
const StaffTable: React.FC<IStaffTableProps> = ({ className, v2 }) => {
  const modalConfirm = useModalConfirmRef();
  const { appNavigate } = useAppNavigate();
  const dispatch = useAppDispatch();

  const staffs = settingStaffSelectors.staffsTableData.data();
  const loading = settingStaffSelectors.staffsTableData.loading();
  const params = settingStaffSelectors.staffsTableData.params();
  const totalElement = settingStaffSelectors.staffsTableData.totalSize();
  const [showModalVerify, setShowModalVerify] = useState(false);
  const [isMaskPin, setMaskPin] = useState(true);

  const handleViewDetail = (id: string) => {
    dispatch(settingStaffActions.getSelectedStaffInfo.fetch(id));
    const url = 'setting-staff/information';
    appNavigate(url);
  };

  const handleDeleteStaff = (staff: IStaffItemData) => {
    const cb = () => dispatch(settingStaffActions.deleteStaff(staff.id));
    modalConfirm.current?.open(`Do you want to remove ${staff.firstName}?`, cb);
  };

  const handleToggleVerify = () => {
    const nextToggle = !isMaskPin;
    if (!nextToggle) return setShowModalVerify(true);
    setMaskPin(true);
  };

  const onVerifyAdminSuccess = () => {
    setMaskPin(false);
    setShowModalVerify(false);
  };

  const handleChangePage = (page: number, pageSize?: number) => {
    dispatch(settingStaffActions.setParams({ page: page, size: pageSize || 20 }));
  };

  const enableBookingOnline = (id: string, enable: boolean) => {
    dispatch(settingStaffActions.enableBookingOnline({ staffId: id, enableBookingOnline: enable }));
  };

  const columns: ColumnsType<IStaffItemData> = [
    {
      title: 'First name',
      dataIndex: 'firstName',
      key: 'firstName',
      align: 'left',
      render: (text, record) => (
        <Row align={'middle'} justify={'start'} style={{ gap: '10px' }} >
          <Avatar size={'small'} src={record.urlImage} style={{ backgroundColor: '#f56a00' }}>{text?.[0] || 'A'}</Avatar>
          <Text variant="BODY_1" color="text_3" textAlign="left">
            {text}
          </Text>
        </Row>

      ),
    },
    {
      title: 'Last name',
      dataIndex: 'lastName',
      key: 'lastName',
      align: 'left',
      render: (text) => (
        <Text variant="BODY_1" color="text_3" textAlign="left">
          {text}
        </Text>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'left',
      render: (text) => (
        <Text variant="BODY_1" color="text_3" textAlign="left">
          {text || '--'}
        </Text>
      ),
    },
    {
      title: <Row align={'middle'} justify={'center'} style={{ height: '40px', position: 'relative', minWidth: 75 }}>
        <p>Pin Code</p>
        <button style={{ position: 'absolute', right: -5, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '25px', height: '25px' }} onClick={handleToggleVerify}>{isMaskPin ? <EyeOpen /> : <EyeClose />}</button>
      </Row >,
      dataIndex: 'password',
      key: 'password',
      align: 'center',
      render: (text) => (
        <Text variant="BODY_1" color="text_3" textAlign="center">
          {isMaskPin ? '****' : text}
        </Text>
      ),
    },
    {
      title: 'DOB',
      dataIndex: 'birthday',
      key: 'DOB',
      align: 'left',
      render: (text) => {
        const date = moment(text);
        return (
          <Text variant="BODY_1" color="text_3" textAlign="left">
            {date.isValid() ? moment(text).format('MM/DD') : '--'}
          </Text>
        );
      },
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      align: 'left',
      render: (text) => (
        <Text variant="BODY_1" color="text_3" textAlign="left">
          {formatPhone(text)}
        </Text>
      ),
    },
    {
      title: 'Salary',
      dataIndex: 'staffSalaryType',
      key: 'salary',
      align: 'left',
      render: (text) => (
        <Text variant="BODY_1" color="text_3" textAlign="left">
          {text || '--'}
        </Text>
      ),
    },
    {
      title: 'Position',
      dataIndex: 'staffType',
      key: 'position',
      align: 'left',
      render: (text) => (
        <Text variant="BODY_1" color="text_3" textAlign="left">
          {getStaffPositionLabel(text)}
        </Text>
      ),
    },
    {
      title: 'Create Date',
      dataIndex: 'createdDate',
      key: 'createdDate',
      align: 'left',
      render: (text) => (
        <Text variant="BODY_1" color="text_3" textAlign="left">
          {moment(text).format('MM-DD-YYYY')}
        </Text>
      ),
    },
    {
      title: 'Booking Online',
      dataIndex: 'enableBookOnline',
      key: 'enableBookOnline',
      align: 'center',
      width: '150px',
      render: (enable, record) => (
        <Switch style={{zoom: 1.2}} checked={enable} onChange={(value) => enableBookingOnline(record.id, value)} />
      ),
    },

    {
      title: '',
      dataIndex: '',
      key: 'go',
      align: 'left',
      width: v2 ? '14rem' : undefined,
      render: (_, record: { id: React.Key }) => (
        <Row style={{ gap: '8px' }}>
          <ViewButtonStyled
            className='ViewButtonStyled'
            onClick={() => {
              handleViewDetail(record.id.toString());
            }}
          >
            <Text variant="BODY_1" color="text_1">
              {v2 ? 'Detail' : 'View'}
            </Text>
          </ViewButtonStyled>
          <ViewButtonStyled
            className='ViewButtonStyled deleted'
            deleted
            onClick={() => {
              handleDeleteStaff(record as IStaffItemData);
            }}
          >
            <Text variant="BODY_1" color="text_1">
              Delete
            </Text>
          </ViewButtonStyled>
        </Row>
      ),
    },
  ];

  return (
    <StaffTableStyled className={className}>
      <Table
        columns={columns}
        dataSource={staffs}
        loading={loading}
        rowKey={'id'}
        handleChangePage={handleChangePage}
        pagination={{
          total: totalElement ?? 0,
          pageSize: params.size,
          current: params.page,
        }}
        showSizeChanger={v2}
      />
      {!!showModalVerify && (
        <RequestAdminLocalPW
          onlyAdmin
          visible
          v2={v2}
          onCorrect={onVerifyAdminSuccess}
          onClose={() => setShowModalVerify(false)}
        />
      )}
      <ModalConfirm ref={modalConfirm} />
    </StaffTableStyled>
  );
};

export default StaffTable;
const StaffTableStyled = styled(TableGreenEvenStyled)``;
const ViewButtonStyled = styled.div<{ deleted?: boolean }>`
  width: 3rem;
  display: flex;
  height: 28px;
  padding: 0.25rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  background: var(--info-infor-3, #6fabb6);
  ${({ deleted }) => deleted ? `
  background: #FF8890;
  ` : ''}

  &:hover {
    cursor: pointer;
  }
`;

const EyeOpen = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M12.3521 15.8408C10.2323 15.8408 8.51172 14.1202 8.51172 12.0005C8.51172 9.88077 10.2323 8.16016 12.3521 8.16016C14.4718 8.16016 16.1924 9.88077 16.1924 12.0005C16.1924 14.1202 14.4718 15.8408 12.3521 15.8408ZM12.3521 9.49053C10.9685 9.49053 9.84209 10.6169 9.84209 12.0005C9.84209 13.3841 10.9685 14.5105 12.3521 14.5105C13.7356 14.5105 14.862 13.3841 14.862 12.0005C14.862 10.6169 13.7356 9.49053 12.3521 9.49053Z" fill="#fff" />
    <path d="M12.3526 20C9.01773 20 5.86918 18.0488 3.7051 14.6608C2.76497 13.1973 2.76497 10.8115 3.7051 9.33924C5.87805 5.95122 9.0266 4 12.3526 4C15.6785 4 18.8271 5.95122 20.9911 9.33924C21.9313 10.8027 21.9313 13.1885 20.9911 14.6608C18.8271 18.0488 15.6785 20 12.3526 20ZM12.3526 5.33038C9.4878 5.33038 6.74722 7.05099 4.83148 10.0576C4.1663 11.0954 4.1663 12.9047 4.83148 13.9424C6.74722 16.949 9.4878 18.6696 12.3526 18.6696C15.2173 18.6696 17.9579 16.949 19.8736 13.9424C20.5388 12.9047 20.5388 11.0954 19.8736 10.0576C17.9579 7.05099 15.2173 5.33038 12.3526 5.33038Z" fill="#fff" />
  </svg>
);

const EyeClose = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M6.11703 10.4412C5.97562 10.4412 5.83421 10.3891 5.72257 10.2775C5.11227 9.66719 4.77734 8.85593 4.77734 8.00002C4.77734 6.22123 6.22123 4.77734 8.00002 4.77734C8.85593 4.77734 9.66719 5.11227 10.2775 5.72257C10.3817 5.82677 10.4412 5.96818 10.4412 6.11703C10.4412 6.26588 10.3817 6.4073 10.2775 6.51149L6.51149 10.2775C6.39985 10.3891 6.25844 10.4412 6.11703 10.4412ZM8.00002 5.89375C6.83896 5.89375 5.89375 6.83896 5.89375 8.00002C5.89375 8.37216 5.9905 8.72941 6.16913 9.042L9.042 6.16913C8.72941 5.9905 8.37216 5.89375 8.00002 5.89375Z" fill="#fff" />
    <path d="M3.23576 12.8437C3.10924 12.8437 2.97527 12.799 2.87107 12.7097C2.0747 12.0324 1.3602 11.1989 0.749898 10.2313C-0.0390286 9.00326 -0.0390286 7.00117 0.749898 5.76567C2.56592 2.92255 5.20808 1.28516 7.99906 1.28516C9.63646 1.28516 11.2515 1.8508 12.6656 2.91511C12.9112 3.10118 12.9633 3.45098 12.7773 3.69659C12.5912 3.9422 12.2414 3.9943 11.9958 3.80823C10.7752 2.88534 9.39085 2.40156 7.99906 2.40156C5.5951 2.40156 3.2953 3.84545 1.68768 6.36852C1.12948 7.23934 1.12948 8.75765 1.68768 9.62844C2.24588 10.4992 2.88595 11.251 3.59301 11.8613C3.82374 12.0622 3.85351 12.412 3.65255 12.6502C3.54836 12.7767 3.39206 12.8437 3.23576 12.8437Z" fill="#fff" />
    <path d="M8.00049 14.7107C7.01062 14.7107 6.04303 14.5098 5.11269 14.1153C4.82987 13.9962 4.6959 13.6687 4.81498 13.3859C4.93406 13.1031 5.26154 12.9691 5.54437 13.0882C6.33329 13.4231 7.15947 13.5943 7.99305 13.5943C10.397 13.5943 12.6968 12.1504 14.3045 9.62736C14.8627 8.75656 14.8627 7.23825 14.3045 6.36746C14.0737 6.00276 13.8206 5.65296 13.5528 5.32548C13.3592 5.08731 13.3965 4.7375 13.6346 4.53655C13.8728 4.34304 14.2225 4.37281 14.4235 4.61842C14.7138 4.97567 14.9966 5.36269 15.2497 5.7646C16.0386 6.99264 16.0386 8.99473 15.2497 10.2302C13.4337 13.0733 10.7915 14.7107 8.00049 14.7107Z" fill="#fff" />
    <path d="M8.51239 11.1776C8.25189 11.1776 8.01372 10.9915 7.96163 10.7236C7.90208 10.4184 8.10304 10.1281 8.40819 10.076C9.22688 9.92719 9.91161 9.24247 10.0605 8.42377C10.12 8.11862 10.4103 7.92511 10.7154 7.97721C11.0206 8.03675 11.2215 8.32701 11.162 8.63216C10.9238 9.91975 9.89673 10.9394 8.61658 11.1776C8.57937 11.1701 8.5496 11.1776 8.51239 11.1776Z" fill="#fff" />
    <path d="M0.556342 15.9985C0.414931 15.9985 0.273519 15.9464 0.161879 15.8347C-0.0539596 15.6189 -0.0539596 15.2616 0.161879 15.0458L5.72158 9.4861C5.93742 9.27026 6.29467 9.27026 6.51051 9.4861C6.72638 9.70194 6.72638 10.0592 6.51051 10.275L0.950805 15.8347C0.839165 15.9464 0.697754 15.9985 0.556342 15.9985Z" fill="#fff" />
    <path d="M9.88251 6.67422C9.7411 6.67422 9.59969 6.62212 9.48805 6.51051C9.27221 6.29467 9.27221 5.93742 9.48805 5.72158L15.0478 0.161879C15.2636 -0.0539596 15.6208 -0.0539596 15.8367 0.161879C16.0525 0.377717 16.0525 0.734967 15.8367 0.950806L10.277 6.51051C10.1653 6.62212 10.0239 6.67422 9.88251 6.67422Z" fill="#fff" />
  </svg>
);