import { ISummarySalon, ISummaryTicket } from 'features/report/services/types/reportStaff';
import { get } from 'lodash';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import average_ticket_icon from './icons/average-ticket.svg';
import deduction_icon from './icons/deduction.svg';
import gross_sales_icon from './icons/gross-sales.svg';
import net_sale_icon from './icons/net-sales.svg';
import paid_out_icon from './icons/paid-out.png';
import { IIconAndPriceProps } from 'features/report/components/IconAndPrice';
import ProcessReportV2, { IListTotalProcessReport } from 'features/report/components/ProcessReportV2';

const DashboardSalaryTypes = {
  GROSS_SALES: 'GROSS_SALES',
  DEDUCTIONS: 'DEDUCTIONS',
  NET_SALES: 'NET_SALES',
  PAID_OUT: 'PAID_OUT',
  AVERAGE_TICKET: 'AVERAGE_TICKET',
};
const configs = {
  [DashboardSalaryTypes.GROSS_SALES]: {
    label: 'Gross Sales',
    bg: '#808836',
    icon: gross_sales_icon,
  },
  [DashboardSalaryTypes.DEDUCTIONS]: {
    label: 'Deductions',
    bg: '#4F1787',
    icon: deduction_icon,
  },
  [DashboardSalaryTypes.NET_SALES]: {
    label: 'Net Sales',
    bg: '#C84B31',
    icon: net_sale_icon,
  },

  [DashboardSalaryTypes.PAID_OUT]: {
    label: 'Paid Out',
    bg: '#C52884',
    icon: paid_out_icon,
  },
  [DashboardSalaryTypes.AVERAGE_TICKET]: {
    label: 'Average Tickets',
    bg: '#346751',
    icon: average_ticket_icon,
  },
};

type Props = {
  dataSale: ISummarySalon;
  dataTicket: ISummaryTicket;
  listTotal: IListTotalProcessReport;
  dataProgressList: IIconAndPriceProps[];
};
const SummarySalonReportV2 = ({ dataSale, dataTicket, dataProgressList, listTotal }: Props) => {
  return (
    <Container>
      <ContainerStatistic>
        <DashboardItem type='GROSS_SALES' value={formatCurrency(dataSale.crossSales)} />
        <DashboardItemStyled style={{ padding: 0 }}>
          <DashboardItem style={{ alignSelf: 'stretch', border: 'none', paddingBottom: 0 }} type='DEDUCTIONS' value={formatCurrency(dataSale.deductions)} />
          <DiscountContainer>
            <div className="deduction-item">
              <div className="content-item">
                <span className="label">Manual:</span>
                <span className="value">{formatCurrency(dataSale.discountManual)}</span>
              </div>
              <div className="content-item">
                <span className="label">Coupons:</span>
                <span className="value">{formatCurrency(dataSale.discountCoupon)}</span>
              </div>
            </div>
            <div className="content-item">
              <span className="label">Cash Incentives:</span>
              <span className="value">{formatCurrency(dataSale.discountIncentive)}</span>
            </div>
          </DiscountContainer>
        </DashboardItemStyled>
        <DashboardItem type='NET_SALES' value={formatCurrency(dataSale.netSales)} />
        <DashboardItem type='PAID_OUT' value={formatCurrency(dataSale.paidOut)} />
        <DashboardItem type='AVERAGE_TICKET' value={formatCurrency(dataTicket.averageTicket)} />
      </ContainerStatistic>
      <ProcessReportV2 list={dataProgressList} listTotal={listTotal} />
    </Container>
  );
};

export default SummarySalonReportV2;
const DiscountContainer = styled.div`
display: flex;
padding: 8px 16px;
flex-direction: column;
align-items: flex-start;
gap: 4px;
align-self: stretch;
background: #F5F5F5;
.deduction-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.content-item {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  span.label {
    color: #1D2129;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  span.value {
    color: #1D2129;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 166.667% */
    text-transform: capitalize;
  }
}
`;
const ContainerStatistic = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 16px;
  align-self: stretch;
`;
const Container = styled.div`
  padding: 24px 16px;
  align-self: stretch;
  gap: 16px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
`;

type DashboardItemProps = {
  type: keyof typeof DashboardSalaryTypes;
  value: string;
  style?: React.CSSProperties;
};
const DashboardItem = ({ type, value, style }: DashboardItemProps) => {
  const config = get(configs, type);
  if (!config) return null;
  return <DashboardItemStyled style={style} className={type}>
    <div className="content">
      <p className="label">{config.label}</p>
      <p className="value">{value}</p>
    </div>

    <div className="icon-button">
      <img src={config.icon} alt={config.label} />
    </div>

  </DashboardItemStyled>;
};
const DashboardItemStyled = styled.div`
display: flex;
padding: 24px;
flex-direction: column;
align-items: flex-start;
gap: 8px;
flex: 1 0 0;
border-radius: 8px;
border: 1px solid #CCD4DC;
background: #FFF;
position: relative;
align-self: stretch;
justify-content: center;

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  p.label {
    align-self: stretch;
    color: #505050;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    text-transform: capitalize;
  }
  p.value {
    transform: translateY(16px);
    align-self: stretch;
    color: #1D2129;
    font-family: Poppins;
    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.icon-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  right: 16px;
  border-radius: 100px;
  border: 1px solid #FFF;
  transform: translateY(-32px);
  img {
    height: 72px;
    width: 72px;
  }
}

&.DEDUCTIONS .icon-button {
  transform: translateY(-16px);
}
&.DEDUCTIONS .content p.value{
  transform: unset;
}
`;
