import { Calendar as CalendarAnt, CalendarProps } from 'antd';
import type { Moment, Moment as MomentType } from 'moment';
import moment from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import styled from 'styled-components';

const CalendarAntUI = CalendarAnt.generateCalendar<MomentType>(momentGenerateConfig);

type Props = {
  value?: Moment;
  onChange?: (val: Moment) => void;
  onPickDate?: (val: Moment) => void;
  showDateInPast?: boolean
};

const CalendarPicker = ({ value, onChange = () => undefined, onPickDate = () => undefined, showDateInPast }: Props) => {

  const handleChangeDate = (val: Moment) => {
    onChange(val);
    onPickDate(val);
  };

  const onFullRender: CalendarProps<Moment>['fullCellRender'] = (date) => {
    const day = date.date();
    const now = moment();
    return (
      <DateItemStyled
        today={now.isSame(date, 'date')}
        selected={value?.isSame(date, 'date')}
        onClick={() => handleChangeDate(date)}
        type='button'
      >
        <div className="box">{day}</div>
      </DateItemStyled>
    );
  };

  return (
    <Wrapper>
      <CalendarAntUI
        defaultValue={value}
        fullscreen={false}
        headerRender={({ value, onChange }) => {
          const year = value.year();
          const month = value.month();
          return (
            <HeaderFilter>
              <button onClick={() => onChange(value.clone().month(month - 1))} type='button' className="btn btn-nav">{prevMonthIcon}</button>
              <div className="month-container">
                <div className="month-text">{moment(month + 1, 'M').format('MMMM')}</div>
                <div className="year-text">{year}</div>
              </div>
              <button onClick={() => onChange(value.clone().month(month + 1))} type='button' className="btn btn-nav">{nextMonthIcon}</button>
            </HeaderFilter>
          );
        }}
        fullCellRender={onFullRender}
        disabledDate={(date) => {
          if(showDateInPast) return false;
          if (date.endOf('d').valueOf() < moment().valueOf()) {
            return true;
          }
          return false;
        }}
      />
    </Wrapper>
  );
};

export default CalendarPicker;

const HeaderFilter = styled.div`
  display: flex;
  align-items: center;

  .month-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .month-text {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 137.5%; /* 33px */
      letter-spacing: 0.48px;
    }

    .year-text {
      color: #505050;
      text-align: center;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 137.5%; /* 24.75px */
      letter-spacing: 0.36px;
    }
  }

  button.btn.btn-nav {
    display: flex;
    width: 56px;
    height: 56px;
    padding: 2px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #86909C;
    background: #F0F0F0;
  }
`;

const Wrapper = styled.div`
  .ant-select {
    width: 100%;
    border: none;
    box-shadow: none;
    background: transparent;
    .ant-select-selection-item {
      text-align: center;
      display: flex;
      flex: 1;
      justify-content: center;
      color: #000 !important;
    }
    &.ant-select-focused,
    &.ant-select-open {
      border: none;
      box-shadow: none;
      .ant-select-selector {
        border: none;
        box-shadow: none;
      } 
    }
    .ant-select-selector {
    border: none;
    }
  }
  .divider {
    background:#C9CDD4;
    width: 1px;
    height: 22px;
  }
  .label-picker {
    color: var(--text-text-3, #1D2129);
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-size: 1.25rem;
  }
  .ant-picker-calendar .ant-picker-panel {
    border: none;
  }
  .ant-picker-content thead th {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 137.5%; /* 27.5px */
    letter-spacing: 0.4px;
    text-transform: uppercase;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
  }
  .ant-picker-content tbody td {
    text-align: center;
    font-family: Open Sans;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    .ant-picker-calendar .ant-picker-cell-in-view {
      color: var(--text-text-3, #1D2129);
    }
    width: 40px;
    height: 40px;
    padding: 0;
  }
  .ant-picker-cell {
    &.ant-picker-cell-disabled::before {
      background: #fff;
      opacity: 0.8;
    }
  }
`;

const DateItemStyled = styled.button<{ selected?: boolean, today?: boolean }>`
width: 100%;
display: flex;
text-align: center;

align-items: center;
justify-content: center;
font-size: 1.75rem;
text-align: center;

color: #1D2129;
text-align: center;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.4px;


-webkit-user-select: none;
/* Safari */
-ms-user-select: none;
/* IE 10 and IE 11 */
user-select: none;
/* Standard syntax */

  .box {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;

    ${({ today }) => today && `
      color: #2D6772;
      font-weight: 600;
    `}
    ${({ selected }) => selected && `
    color: var(--fill-fill-0, #FFF);
    text-align: center;
    
    /* Title/H9 - 18 semibold */
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 166.667% */
    text-transform: capitalize;
    border-radius: 100px;
    background: #2D6772;
    `}
  }

`;
const prevMonthIcon = <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
  <g clipPath="url(#clip0_11085_41759)">
    <path fillRule="evenodd" clipRule="evenodd" d="M20.9428 8.04595C21.4635 8.56665 21.4635 9.41087 20.9428 9.93157L13.8856 16.9888L20.9428 24.046C21.4635 24.5667 21.4635 25.4109 20.9428 25.9316C20.4221 26.4523 19.5778 26.4523 19.0571 25.9316L11.0572 17.9316C10.5365 17.4109 10.5365 16.5666 11.0572 16.046L19.0571 8.04595C19.5778 7.52525 20.4221 7.52525 20.9428 8.04595Z" fill="#1D2129" />
  </g>
  <defs>
    <clipPath id="clip0_11085_41759">
      <rect width="32" height="32" fill="white" transform="translate(0 0.98877)" />
    </clipPath>
  </defs>
</svg>;

const nextMonthIcon = <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M11.3429 25.9316C10.8222 25.4109 10.8222 24.5667 11.3429 24.046L18.4001 16.9888L11.3429 9.93159C10.8222 9.41089 10.8222 8.56667 11.3429 8.04597C11.8636 7.52527 12.7078 7.52527 13.2285 8.04597L21.2285 16.046C21.7492 16.5667 21.7492 17.4109 21.2285 17.9316L13.2285 25.9316C12.7078 26.4523 11.8636 26.4523 11.3429 25.9316Z" fill="#1D2129" />
</svg>;