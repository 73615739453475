import { Form } from 'antd';

type IReceiveValueProps<T = any> = {
  value?: T,
  onChange?: (value: T) => void;
  render?: (value?: T, onChange?: (value: T) => any) => any
};
const ReceiveValue = <T = any>({ value, onChange, render }: IReceiveValueProps<T>) => {
  if (render) return render(value, onChange);
  return value;
};
type IFormItemValueProps<T = any> = {
  name?: any;
  render?: (value?: T, onChange?: (value: T) => any) => any;
};
const FormItemValue = <T = any>({ name, render }: IFormItemValueProps<T>) => {
  return <Form.Item noStyle name={name}>
    <ReceiveValue<T> render={render} />
  </Form.Item>;
};

export default FormItemValue;
