import Button from 'components/Button';
import SidebarV2 from 'components/layout/SidebarV2';
import dayjs from 'dayjs';
import reportActions from 'features/report/services/actions';
import reportSelectors from 'features/report/services/selectors';
import { delay } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import ReceiptPrinter from '../ReceiptPrinter';
import { IPrintCheckInfoDataSubmitValues } from '../type';

const PrintCheckAllPreviewPage = () => {
  const dispatch = useAppDispatch();
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const items = reportSelectors.getPrintCheckAll();
  const printRef = useRef<HTMLDivElement>(null);
  const printCheckSetting = shopSelectors.data.printCheckSetting();
  const divRefs = useRef<HTMLDivElement[]>([]);
  const checkNum = reportSelectors.getCheckNum();

  useEffect(() => {
    const endDate = search.get('endDate') || '';
    const startDate = search.get('startDate') || '';
    dispatch(reportActions.getCheckNum.fetch());
    dispatch(reportActions.getPrintCheckAll.fetch({ startDate, endDate }));
  }, []);

  const reports = useMemo(() => {
    const base64 = printCheckSetting?.signature ? 'data:image/png;base64,' + printCheckSetting?.signature || '' : '';
    return items.map(dataDashboard => {
      const total = (dataDashboard?.totalSaleService || 0) - (dataDashboard?.supplyFee || 0);
      const data: IPrintCheckInfoDataSubmitValues = {
        staffId: dataDashboard?.staffId || '',
        staffName: dataDashboard.staffName,
        total,
        collectedTips: dataDashboard?.tipCollected || 0,
        commissionPercent: dataDashboard?.commissionPercent || 0,
        commission: dataDashboard?.totalCommission || 0,
        totalSales: dataDashboard?.totalSales || 0,
        supplyFee: dataDashboard?.supplyFee || 0,
        totalPaidOut: dataDashboard?.totalSalary || 0,
        bonus: dataDashboard?.cashAmount || 0,
        bonusPercent: dataDashboard?.cashPercent || 0,
        check: dataDashboard?.checkAmount || 0,
        checkPercent: dataDashboard?.checkPercent || 0,
        tipToBonus: dataDashboard?.tipToCash || 0,
        tipToBonusPercent: dataDashboard?.tipCashPercent || 0,
        tipToCheck: dataDashboard?.tipToCheck || 0,
        tipCheckPercent: dataDashboard?.tipCheckPercent || 0,
        totalBonus: dataDashboard?.totalCash || 0,
        totalCheck: dataDashboard?.totalCheck || 0,
        date: dayjs(),
        checkNo: checkNum,
        checkBank: {
          bankName: printCheckSetting?.bankName || '',
          bankAddress: printCheckSetting?.bankAddress || '',
          bankWebsite: printCheckSetting?.bankWebsite || '',
          bankLogo: printCheckSetting?.bankLogo || '',
        },
        signature: base64,
        forCheckNote: printCheckSetting?.forCheckNote || '',
      };
      return data;
    });
  }, [items, checkNum]);

  const printIndex = useRef(0);
  const oPrintMultiple = useReactToPrint({
    content: () => divRefs.current[printIndex.current] || null,
    onAfterPrint: () => {
      if (printIndex.current < divRefs.current.length - 1) {
        printIndex.current++;
        delay(oPrintMultiple, 100);
      }
    },
  });

  const handlePrint = () => {
    const receipts = (printRef.current?.querySelectorAll('.receipt-print') || []) as HTMLDivElement[];
    if (!receipts.length) return;
    printIndex.current = 0;
    divRefs.current = receipts;
    setTimeout(oPrintMultiple, 500);
  };

  return (
    <Container>
      <div style={{ background: '#FFF', display: 'flex' }}>
        <SidebarV2 />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, maxWidth: 'calc(100% - 104px)', height: '100vh', overflow: 'auto' }}>
        <HeaderContainer>
          <span>PRINT CHECK PREVIEW</span>
          <Actions>
            <Button onClick={() => navigate(-1)} icon='back' cardCashier>Back</Button>
            <Button icon='print' cardCashier onClick={handlePrint}>Print</Button>
          </Actions>
        </HeaderContainer>
        <FormStyled ref={printRef}>
          {reports.map(data =>
            <div key={data.staffId} style={{ background: '#FFF' }}>
              <ReceiptPrinter value={data} className='receipt-print' />
            </div>
          )}
        </FormStyled>
      </div>
    </Container>
  );
};

export default PrintCheckAllPreviewPage;
const FormStyled = styled.div`
  width: 1100px;
  padding: 32px 24px;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 24px;
`;

const Container = styled.div`
    display: flex;
    height: 100vh;
    flex:1;
    width: 100vw;
    .search-staff {
        flex:1;
        input {
            height: 38px;
        }
        .ant-input-suffix {
            height: 38px;
        }
    }
    background: #F5F5F5;
    min-height: unset;
`;
const HeaderContainer = styled.div`
display: flex;
height: 80px;
padding: 24px;
justify-content: space-between;
align-items: center;
flex-shrink: 0;
span {
  color: black;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
`;
const Actions = styled.div`
display: flex;
align-items: center;
gap: 16px;
`;