import { get } from 'lodash';
import styled from 'styled-components';
import bg_tab_active_icon from './icons/bg-tab-active.svg';
import bg_tab_icon from './icons/bg-tab.svg';
import service_icon from './icons/service.svg';
import service_active_icon from './icons/service-active.svg';
import simple_menu_icon from './icons/simple-menu.svg';
import simple_menu_active_icon from './icons/simple-menu-active.svg';
import product_icon from './icons/product.svg';
import product_active_icon from './icons/product-active.svg';
import VIP_icon from './icons/VIP.svg';
import VIP_active_icon from './icons/VIP-active.svg';
import quick_pay_icon from './icons/quick-pay.svg';
import quick_pay_active_icon from './icons/quick-pay-active.svg';
import turn_icon from './icons/turn.svg';
import turn_active_icon from './icons/turn-active.svg';
import control_panel_icon from './icons/control-panel.svg';
import control_panel_active_icon from './icons/control-panel-active.svg';
import { CSSProperties, useMemo } from 'react';
export const TAB_KEYS = {
  SERVICES: 'services',
  SIMPLE_MENU: 'simple-menu',
  PRODUCTS: 'products',
  VIP: 'VIP',
  QUICK_PAY: 'quick_pay',
  TURN: 'turn',
  CONFIGURATION: 'payments',
};
const tabConfigs = {
  [TAB_KEYS.SERVICES]: {
    label: 'SERVICES',
    icon: service_icon,
    icon_active: service_active_icon,
  },
  [TAB_KEYS.SIMPLE_MENU]: {
    label: 'SIMPLE MENU',
    icon: simple_menu_icon,
    icon_active: simple_menu_active_icon,
  },
  [TAB_KEYS.PRODUCTS]: {
    label: 'PRODUCTS',
    icon: product_icon,
    icon_active: product_active_icon,
  },
  [TAB_KEYS.VIP]: {
    label: 'VIP',
    icon: VIP_icon,
    icon_active: VIP_active_icon,
  },
  [TAB_KEYS.QUICK_PAY]: {
    label: 'QUICK PAYS',
    icon: quick_pay_icon,
    icon_active: quick_pay_active_icon,
  },
  [TAB_KEYS.TURN]: {
    label: 'TURNS',
    icon: turn_icon,
    icon_active: turn_active_icon,
  },
  [TAB_KEYS.CONFIGURATION]: {
    label: 'CONFIGURATIONS',
    icon: control_panel_icon,
    icon_active: control_panel_active_icon,
  },

};
type TabV2Props = {
  type: keyof typeof TAB_KEYS,
  tab: string;
  onChange: (tab: string) => void;
  zIndex?: number;
  first?: boolean;
  style?: CSSProperties;
  width?: CSSProperties['width'];
};
const TabUI = ({ first, zIndex, type, tab, onChange, style, width }: TabV2Props) => {
  const key = get(TAB_KEYS, type);
  const config = get(tabConfigs, key || '');
  const active = tab === key;
  const _style = useMemo(() => {
    return ({
      zIndex, marginLeft: first ? 0 : '-50px',
      width,
      ...style,
    });
  }, [zIndex, style, first, width]);
  if (!config) return null;

  return <TabV2Styled style={_style} type='button' onClick={() => onChange(key)} className={active ? 'active' : ''}>
    <img src={active ? bg_tab_active_icon : bg_tab_icon} alt="bg_tab_icon" className='svg-bg' style={{ width: '100%', transform: 'scaleY(1.2)' }} />
    <div className="tab-item-container">
      <img src={active ? config.icon_active : config.icon} style={{ height: 32, width: 32 }} />
      <span>{config.label}</span>
    </div>
  </TabV2Styled>;
};

export default TabUI;

const TabV2Styled = styled.button`
display: flex;
height: 56px;
align-items: end;
gap: 16px;
width: 350px;
border-radius: 24px 24px 0px 0px;
position: relative;

span {
  color: #1D2129;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.tab-item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  z-index: 2;
  position: relative;
  padding-bottom: 6px;
  zoom: 0.9;
}
.svg-bg {
  position: absolute;
  left: 0;
  bottom: 0px;
  right: 0;
  width: 100%;
  // filter: drop-shadow(4px 0px 8px rgba(0, 0, 0, 0.15)) drop-shadow(-4px 0px 8px rgba(0, 0, 0, 0.15));
}

&.active {
  span {
    color: #FFF;
  }
}
`;
export const TabUIContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  display: flex;
  zoom: 1.2;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  overflow: hidden;
`;