import { PAYMENT_TYPE } from 'features/payment/services/constants';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import credit_card_icon from './icons/payment/credit-card.svg';
import cash_icon from './icons/payment/cash.svg';
import loyalty_point_icon from './icons/payment/loyalty-point.svg';
import gift_card_icon from './icons/payment/gift-card.svg';
import other_icon from './icons/payment/epay.svg';
import refunded_img from './icons/full-refunded.png';
import void_stamp_img from './icons/void-stamp.png';
import ticketListSelectors from 'features/ticketList/services/selectors';
import { momentTimezone } from 'utils/time';
import { formatCurrency } from 'utils/formatCurrency';
import { IPaymentMultipleCardItem, Payment } from 'features/ticketList/services/types/api';
import CreditCard from '../components/ModalButton/CreditCard';
import { StatusBox } from './TicketInfoDetail';
import Box from 'components/Box';

const TicketPaymentDetail = () => {
  const details = ticketListSelectors.ticketDetailsData.data();
  const creditPayment = details?.payments.find(o => o.paymentType === PAYMENT_TYPE.CREDIT_CARD);
  const paymentAmounts = useMemo(() => {
    let otherAmount, giftCardAmount, cashAmount, creditAmount, loyaltyAmount, checkAmount;
    let otherMethod: string | undefined = undefined;
    let credit_card: Payment | undefined = undefined;
    details?.payments.map(o => {
      switch (o.paymentType) {
        case PAYMENT_TYPE.CASH: {
          cashAmount = o.amount;
          break;
        }
        case PAYMENT_TYPE.CREDIT_CARD: {
          creditAmount = o.amount;
          credit_card = o;
          break;
        }
        case PAYMENT_TYPE.LOYALTY_POINT: {
          loyaltyAmount = o.amount;
          break;
        }
        case PAYMENT_TYPE.CHECK: {
          checkAmount = o.amount;
          break;
        }
        case PAYMENT_TYPE.GIFT_CARD: {
          giftCardAmount = o.amount;
          break;
        }
        default: {
          otherAmount = o.amount;
          otherMethod = o.otherMethod;
          break;
        }
      }
    });

    return [
      { type: PAYMENT_TYPE.CASH, value: cashAmount },
      { type: PAYMENT_TYPE.LOYALTY_POINT, value: loyaltyAmount },
      { type: PAYMENT_TYPE.CHECK, value: checkAmount },
      { type: PAYMENT_TYPE.GIFT_CARD, value: giftCardAmount },
      { type: 'other', value: otherAmount, otherMethod },
      { type: PAYMENT_TYPE.CREDIT_CARD, value: creditAmount, credit_card, signature: details?.signature || undefined },
    ].filter(o => !!o.value);
  }, [details]);

  if (!details) return null;

  return (
    <Container>
      <Box display='flex' flexDirection='column' style={{ alignSelf: 'stretch' }}>
        <Box display='flex' justifyContent='space-between' alignItems='start' alignSelf='stretch' pr='4'>
          <div className="top-container">
            <p className="title-page">Payment Detail</p>
            <p className="create-date">{momentTimezone(details?.startTime).format('DD-MM-YYYY hh:mm A')}</p>
          </div>
          <Box display='flex'>
            <StatusBoxInline status={details?.billStatus} />
          </Box>
        </Box>
        <StatusBoxInline enableReason reasonText={details.reason} status={details?.billStatus} />
      </Box>
      <DetailContainer>
        <div className="ticket-info">
          <div className="ticket-info-item">
            <span className="label">Grand Sub-Total</span>
            <span className="value sub-total">{formatCurrency(details?.subTotal || 0)}</span>
          </div>
          <div className="ticket-info-item">
            <span className="label">Card Fees</span>
            <span className="value card-fee">{formatCurrency(creditPayment?.feeCreditCard || 0)}</span>
          </div>
          <div className="ticket-info-item">
            <span className="label">Tip</span>
            <span className="value tip">{formatCurrency(details?.tip || 0)}</span>
          </div>
          <div className="ticket-info-item">
            <span className="label">Sale Tax</span>
            <span className="value">{formatCurrency(details?.saleTax || 0)}</span>
          </div>
          <div className="ticket-info-item">
            <span className="label">Used Tax</span>
            <span className="value">{formatCurrency(details?.useTax || 0)}</span>
          </div>
          <div className="ticket-info-item">
            <span className="label">Discount Item</span>
            <span className="value">{formatCurrency(details?.discountItem || 0)}</span>
          </div>
          <div className="ticket-info-item">
            <span className="label">Discount Ticket</span>
            <span className="value">{formatCurrency(details?.discountTicket || 0)}</span>
          </div>
        </div>
        <TotalRow>
          <span className='label'>TOTAL</span>
          <span className='value'>{formatCurrency(details?.total)}</span>
        </TotalRow>

        {details?.isMultiplePayCard ?
          <PaymentTypes>
            {details?.paymentMultipleCard?.map((item, i) => (
              <React.Fragment key={item.paymentId}>
                <PaymentItem
                  type={PAYMENT_TYPE.CREDIT_CARD} value={item.amount}
                  credit_card={item}
                  signature={item.signature}
                />
                {(details?.paymentMultipleCard?.length || 0) - 1 !== i && <div className="divider" />}
              </React.Fragment>
            ))}
          </PaymentTypes>
          :
          <PaymentTypes>
            {paymentAmounts.map((o, i) => (
              <React.Fragment key={o.type}>
                <PaymentItem
                  type={o.type} value={o.value}
                  credit_card={o.credit_card}
                  otherMethod={o.otherMethod}
                  signature={o.signature}
                />
                {paymentAmounts.length - 1 !== i && <div className="divider" />}
              </React.Fragment>
            ))}
            <FloatingImageStatus status={details?.billStatus} />
          </PaymentTypes>}
      </DetailContainer>
    </Container>
  );
};

export default TicketPaymentDetail;
const FloatingImageStatus = ({ status }: { status?: string }) => {
  switch (status) {
    case 'VOID':
      return (
        <div className="floating-image-status">
          <img src={void_stamp_img} style={{ height: 186, width: 'auto' }} />
        </div>
      );
    case 'REFUND':
      return (
        <div className="floating-image-status">
          <img src={refunded_img} style={{ height: 186, width: 'auto' }} />
        </div>
      );

    default:
      return null;
  }
};
const StatusBoxInline = styled(StatusBox)`
  zoom: 0.8;
`;
const Container = styled.div`
display: flex;
width: 700px;
padding-top: 16px;
flex-direction: column;
align-items: flex-start;
gap: 24px;
flex-shrink: 0;
align-self: stretch;
border-radius: 4px 0px 0px 0px;
background: #FFF;
.top-container {
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  .title-page {
    color: #1D2129;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .create-date {
    color: #505050;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
`;

const DetailContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 1px;
align-self: stretch;
flex: 1;

  .ticket-info {
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    .ticket-info-item {
      display: flex;
      padding: 12px 16px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-bottom: 1px solid #E9EDF2;
      span.label {
        color: #1D2129;
        text-align: right;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      span.value {
        color: #1D2129;
        text-align: right;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      span.value.sub-total {
        color: #293A80;
      }
      span.value.tip {
        color: #DA072D;
      }
      span.value.card-fee {
        color: #DA072D;
      }

    }
  }
`;

const TotalRow = styled.div`
display: flex;
height: 56px;
padding: 0px 24px;
justify-content: space-between;
align-items: center;
align-self: stretch;
background: #232F3E;
span.label {
  color: #FFF;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
span.value {
  color: #FFF;
  text-align: right;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
`;

const PaymentTypes = styled.div`
display: flex;
padding: 0px 16px;
flex-direction: column;
align-items: flex-end;
flex: 1 0 0;
align-self: stretch;
position: relative;
background: #F6F7FC;
  .payment-type-item {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-bottom: 1px dashed #232F3E;
  }
.divider {
  align-self: stretch;
  border-bottom: 1px dashed #232F3E;
  height: 1px;
}
.floating-image-status {
  position: absolute;
  z-index: 99;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 0%);
  zoom: 1.5;
}
`;

type PaymentItemProps = {
  type: string;
  value?: number;
  otherMethod?: string;
  credit_card?: IPaymentMultipleCardItem | undefined;
  signature?: string;
};

const PaymentItem = ({ signature, type, value, otherMethod, credit_card }: PaymentItemProps) => {
  if (type === 'other' || type === PAYMENT_TYPE.CHECK) {
    return (
      <PaymentItemStyled>
        <img src={other_icon} alt="other_icon" className="icon" />
        <span>{type === PAYMENT_TYPE.CHECK ? 'Check' : (otherMethod || 'Other')}:</span>
        <div style={{ flex: 1 }} />
        <span className="price">{formatCurrency(value)}</span>
      </PaymentItemStyled>
    );
  }
  if (type === PAYMENT_TYPE.LOYALTY_POINT) {
    return (
      <PaymentItemStyled>
        <img src={loyalty_point_icon} alt="loyalty_point_icon" className="icon" />
        <span>Point:</span>
        <div style={{ flex: 1 }} />
        <span className="price">{formatCurrency(value)}</span>
      </PaymentItemStyled>
    );
  }
  if (type === PAYMENT_TYPE.GIFT_CARD) {
    return (
      <PaymentItemStyled>
        <img src={gift_card_icon} alt="gift_card_icon" className="icon" />
        <span>Gift Card:</span>
        <div style={{ flex: 1 }} />
        <span className="price">{formatCurrency(value)}</span>
      </PaymentItemStyled>
    );
  }
  if (type === PAYMENT_TYPE.CASH) {
    return (
      <PaymentItemStyled>
        <img src={cash_icon} alt="cash_icon" className="icon" />
        <span>Cash:</span>
        <div style={{ flex: 1 }} />
        <span className="price">{formatCurrency(value)}</span>
      </PaymentItemStyled>
    );
  }
  if (type === PAYMENT_TYPE.CREDIT_CARD)
    return (
      <>
        <PaymentItemStyled>
          <img src={credit_card_icon} alt="credit_card_icon" className="icon" />
          {credit_card ? <CreditCard v2 credit_card={credit_card} /> : <span>Credit Card:</span>}
          <div style={{ flex: 1 }} />
          <span className="price">{formatCurrency(value)}</span>
        </PaymentItemStyled>
        {!!signature && <SignatureBox>
          <img className='img' src={signature?.includes('http') ? signature : 'data:image/png;base64,' + signature} />
        </SignatureBox>}
      </>
    );
  return null;
};
const PaymentItemStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  align-self: stretch;
  img.icon {
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
span {
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &.price {
    margin-left: 24px;
    font-weight: 600;
  }
}
`;

const SignatureBox = styled.div`
  // padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  .img {
    width: 100%;
    height: 215px;
    margin: auto;
    background-position-x: center;
    background-position-y: center;
    background-size: contain;
    background-repeat: no-repeat;
    &.multi {
      height: 180px;
      margin-top: -15px;
    }
  }
  .blank {
    width: 100%;
    height: 5.5rem;
  }
  align-items: end;
`;
