import { Carousel } from 'antd';
import { CarouselRef } from 'antd/es/carousel';
import Icon from 'assets/Icons';
import AvatarBox from 'components/AvatarBox';
import settingStaffActions from 'features/settingStaff/services/actions';
import settingStaffSelectors from 'features/settingStaff/services/selectors';
import React from 'react';
import { IStaffItemData } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
type ICarouselProps = {
  data: IStaffItemData[];
  choseStaff?: (value: string) => void;
};

const StaffCarousel: React.FC<ICarouselProps> = ({ data = [] }) => {
  const dispatch = useAppDispatch();
  const carouselRef = React.createRef<CarouselRef>();
  const totalPage = Math.ceil(data.length / 10);
  const arrayByPage = Array.from({ length: totalPage }, (_, i) => i + 1);
  const selectedStaff = settingStaffSelectors.selectedStaffData.data();

  const contentStyle = {
    display: 'flex',
    padding: '1.5rem',
    gap: '1rem',
    color: '#fff',
    background: 'none',
    overflow: 'auto',
    justifyContent: 'center',
  };

  const choseStaff = (id: string) => {
    dispatch(settingStaffActions.getSelectedStaffInfo.fetch(id));
  };
  return (
    <>
      <PreviousButton
        onClick={() => {
          carouselRef.current?.prev();
        }}
      >
        <Icon type="backCarousel" />
      </PreviousButton>
      <CarouselContainerStyled>
        <Carousel ref={carouselRef}>
          {arrayByPage.map((page) => (
            <div key={page} className='carousel-page'>
              <div style={contentStyle} className='carousel-col'>
                {data.slice((page - 1) * 10, page * 10).map((staff) => {
                  return (
                    <AvatarBox
                      key={staff.id}
                      src={staff.urlImage ?? ''}
                      name={staff.firstName}
                      selected={staff.id === selectedStaff?.id}
                      onClick={() => {
                        choseStaff(staff.id);
                        // carouselRef.current?.goTo(0);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          ))}
        </Carousel>
        <NextButton
          onClick={() => {
            carouselRef.current?.next();
          }}
        >
          <Icon type="nextCarousel"></Icon>
        </NextButton>
      </CarouselContainerStyled>
    </>
  );
};

export default StaffCarousel;
const CarouselContainerStyled = styled.div`
  position: relative;
`;
const NextButton = styled.div`
  position: absolute;
  top: 3.5rem;
  bottom: 0;
  right: 35px;
  z-index: 1;
  text-align: center;
  cursor: pointer;
`;
const PreviousButton = styled.div`
  position: absolute;
  top: 3.5rem;
  bottom: 0;
  left: 35px;
  z-index: 1;
  text-align: center;
  cursor: pointer;
`;
