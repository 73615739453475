import { useTicketWrapper } from 'features/ShopWrapperLayout/TicketWrapperContext';
import { parseDecimal } from 'features/cashier/services/constants';
import cashierSelectors from 'features/cashier/services/selectors';
import ConfirmTipTicket, { useConfirmTipTicketRef } from 'features/payment/components/ConfirmTipTicket';
import Signature, { ISignatureRef } from 'features/payment/components/CustomerSignature';
import PaymentLoading, { IPaymentLoadingRef } from 'features/payment/components/PaymentLoading';
import CreditCardLoading, { ICreditPaymentLoadingRef } from 'features/payment/components/PaymentLoading/CreditCardLoading';
import useSMSEmailSignature from 'features/payment/hooks/useSMSEmailSignature';
import AddATip, { ModalAddTipRef } from 'features/payment/pages/PaymentPage/CustomerSide/components/AddATip';
import multiplePaymentActions from 'features/payment/pages/PaymentPage/services/actions';
import multiplePaymentSelectors from 'features/payment/pages/PaymentPage/services/selectors';
import { ICustomerReceiveConfigs, PaymentSocketData, TypePaymentActors, TypePaymentPassData } from 'features/payment/pages/PaymentPage/services/types/socketPayment';
import paymentApis from 'features/payment/services/apis';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import { IBodyAPIPayment } from 'features/payment/services/types/api';
import { IBillDetailData } from 'features/payment/services/types/bill';
import { useSocketContext } from 'hooks/useSocket';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import { Message } from 'stompjs';
import { useAppDispatch } from 'store/hooks';
import { removeBase64Prefix } from 'utils/removeBase64Prefix';
import storage from 'utils/sessionStorage';
import { PAYMENT_PASS_DATA_TOPIC } from 'utils/socket';
import CashierActionButton from '../../CashierActionBtn';
import useUpdateTicket from '../../useUpdateTicket';
import paymentActions from 'features/payment/services/actions';
type Props = {
  v2?: boolean;
};
const CreditCardDefaultPayment = ({ v2 }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const updateTicket = useUpdateTicket();
  const loadingRef = useRef<IPaymentLoadingRef>(null);
  const signatureRef = useRef<ISignatureRef>(null);
  const creditCardLoadingRef = useRef<ICreditPaymentLoadingRef>(null);
  const ticketContext = useTicketWrapper();
  const tipRef = useRef<ModalAddTipRef>(null);
  const completedTicketModalRef = useConfirmTipTicketRef();

  const setLoadingPage = useSetLoadingPage();
  const cashierMsg = multiplePaymentSelectors.getCashierSocketMsg();
  const enableCardFee = cashierSelectors.getEnableCardFee();
  const [detail, setDetailTicket] = useState<IBillDetailData | null>(null);
  const billRef = useRef<{
    billId: string | null,
    detailTicket: IBillDetailData | null,
  }>({
    billId: null,
    detailTicket: null,
  });
  const socketContext = useSocketContext();
  const SMSEmailSignature = useSMSEmailSignature();

  const onDone = async (ignorePrint?: boolean) => {
    loadingRef.current?.setVisible(false);
    const id = billRef.current.billId || '';
    dispatch(multiplePaymentActions.setCashierMsg.fetch(null));
    socketContext.switchCustomerScreen(`/store/${storage.shop_id.get()}/ticket/payment/customer-side/rating/${billRef.current.billId}`);
    const callback = () => {
      if (ignorePrint) {
        ticketContext.doPrintNoReceipt(id, true);
        navigate(`/store/${storage.shop_id.get()}`);
        return;
      }
      ticketContext.completedTicket(id, true);
    };
    if (!completedTicketModalRef.current) return callback();
    completedTicketModalRef.current.open(id, callback);
  };

  const listeningData = (message: Message) => {
    if (!message.body) return;
    const payment: PaymentSocketData = JSON.parse(message.body);
    if (!payment) return;
    if (payment.actor !== TypePaymentActors.CUSTOMER) return;

    switch (payment.action) {
      case TypePaymentPassData.COMPLETED_PAYMENT: {
        onDone();
        break;
      }
      case TypePaymentPassData.COMPLETED_PAYMENT_WITHOUT_RECEIPT: {
        onDone(true);
        break;
      }
      case TypePaymentPassData.TIP_PAYMENT_SEND_TIP_AFTER_TYPING: {
        tipRef.current?.setSoftAmount(payment.data?.tip || 0);
        break;
      }
      case TypePaymentPassData.REQUEST_SIGNATURE_ONLY: {
        tipRef.current?.close();
        signatureRef.current?.open();
        break;
      }
      case TypePaymentPassData.ON_CUSTOMER_SIGNATURE_CHANGE: {
        signatureRef.current?.setValue(payment.data);
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (!cashierMsg) return;
    listeningData(cashierMsg);
  }, [cashierMsg]);

  const getDetailTicket = async (billId: string) => {
    try {
      const res: IResponseDataBody<IBillDetailData> = await paymentApis.getBillDetail(billId);
      if (res.data.data) return res.data.data;
      else return null;
    } catch (error) {
      return null;
    }
  };

  const postPayment = async (billId: string, detailTicket: IBillDetailData) => {
    const body: IBodyAPIPayment = {
      billId,
      paymentInfo: [
        {
          paymentType: PAYMENT_TYPE.CREDIT_CARD,
          // paymentType: PAYMENT_TYPE.CASH,
          amount: detailTicket.total,
        },
      ]
    };
    const cardPaymentValue = parseDecimal(detailTicket.total + detailTicket.cardFee);
    creditCardLoadingRef.current?.open(cardPaymentValue, 120000);
    socketContext.send(PAYMENT_PASS_DATA_TOPIC, {
      billId: detailTicket?.billId || '',
      actor: TypePaymentActors.CASHIER,
      action: TypePaymentPassData.CREDIT_CARD_PAYMENT_PROCESSING,
      data: { totalCC: cardPaymentValue, }
    });

    try {
      const res: IResponseDataBody<true> = await paymentApis.payment(body);
      if (res?.data?.data) {
        tipRef.current?.open();
        dispatch(multiplePaymentActions.getTransactionDetail.fetch(billId));
        socketContext.send(PAYMENT_PASS_DATA_TOPIC, {
          billId: detailTicket?.billId || '',
          actor: TypePaymentActors.CASHIER,
          action: TypePaymentPassData.REQUEST_SIGNATURE
        });
      } else {
        throw 'fail';
      }
    } catch (error) {
      const screen = `/store/${storage.shop_id.get()}/check-in/sign-in`;
      socketContext.switchCustomerScreen(screen);
      socketContext.send(PAYMENT_PASS_DATA_TOPIC, {
        billId: detailTicket?.billId || '',
        actor: TypePaymentActors.CASHIER,
        action: TypePaymentPassData.CREDIT_CARD_PAYMENT_DONE,
      });
    } finally {
      creditCardLoadingRef.current?.setVisible(false);
    }
  };

  const handleAddATip = async (val: number) => {
    const billId = billRef.current.billId;
    if (val && billId) {
      setLoadingPage(true);
      try {
        await paymentApis.addTipCreditCard(billId, val);
      } catch (error) { }
      finally {
        setLoadingPage(false);
      }
    }
    signatureRef.current?.open();
    socketContext.send(PAYMENT_PASS_DATA_TOPIC, {
      billId: billRef.current?.billId || '',
      actor: TypePaymentActors.CASHIER,
      action: TypePaymentPassData.REQUEST_SIGNATURE_ONLY,
    });
  };

  const onSkipAddATip = () => {
    signatureRef.current?.open();
  };

  const onCard = async () => {
    try {
      const rest: { billId: string } | null = await updateTicket();
      if (!rest) return;
      const billId = rest.billId;
      const detailTicket = await getDetailTicket(billId);
      if (!detailTicket) throw 'fail';

      billRef.current.billId = billId;
      billRef.current.detailTicket = detailTicket;
      setDetailTicket(detailTicket);
      const screen = `/store/${storage.shop_id.get()}/ticket/payment/customer-side/${billId}`;
      socketContext.switchCustomerScreen(screen);
      setTimeout(() => {
        const configs: ICustomerReceiveConfigs = {
          creditCard: true,
          enableCardFee,
        };
        socketContext.send(PAYMENT_PASS_DATA_TOPIC, {
          billId: detailTicket?.billId || '',
          actor: TypePaymentActors.CASHIER,
          action: TypePaymentPassData.CUSTOMER_RECEIVE_CONFIGS,
          data: configs,
        });
        dispatch(multiplePaymentActions.customerSide.setReceiveConfigs(configs));
      }, 100);
      await postPayment(billId, detailTicket);
    } catch (error) { }
  };

  const handleChangeTip = (val: number) => {
    socketContext.send(PAYMENT_PASS_DATA_TOPIC, {
      billId: billRef.current?.billId || '',
      actor: TypePaymentActors.CASHIER,
      action: TypePaymentPassData.TIP_PAYMENT_SEND_TIP_AFTER_TYPING,
      data: { tip: val }
    });
  };

  const onSignatureDone = async (signatureValue: string) => {
    signatureRef.current?.close();
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await paymentApis.updateSignature(billRef.current?.billId || '', removeBase64Prefix(signatureValue));
      if (res.data.data) {
        onDone();
        return;
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  const onCreditCardCancel = () => {
    dispatch(paymentActions.cancelTransaction.fetch(billRef.current.billId || ''));
    socketContext.backToCheckIn();
    navigate(`/store/${storage.shop_id.get()}`);
  };
  return (
    <div style={{ alignSelf: 'stretch', display: 'flex' }}>
      <CashierActionButton v2UI={v2} feature='CARD' ntype='LIGHT_BLUE' label='Card' onClick={onCard} />
      <PaymentLoading ref={loadingRef} />
      <CreditCardLoading ref={creditCardLoadingRef} />
      <AddATip onChangeTip={handleChangeTip} ref={tipRef} onAddATip={handleAddATip} onSkipAddATip={onSkipAddATip} />
      <Signature
        ref={signatureRef}
        onAgree={onSignatureDone}
        onNoReceipt={SMSEmailSignature.onNoReceipt(billRef.current?.billId || '', () => onDone(true))}
        onEmail={SMSEmailSignature.onEmail(billRef.current?.billId || '', () => onDone(true))}
        onSMS={SMSEmailSignature.onSMS(billRef.current?.billId || '', () => onDone(true))}
        defaultSMSPhone={detail?.customerPhone}
      />
      <ConfirmTipTicket ref={completedTicketModalRef} />
    </div>
  );
};

export default CreditCardDefaultPayment;