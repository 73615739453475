import { Form, Input, InputRef } from 'antd';
import clsx from 'clsx';
import DollarInputPayment from 'components/DollarAmount/DollarInputPayment';
import { DollarAmountInputRef } from 'components/DollarAmount/helpers';
import { IResultGiftCardV3 } from 'features/cashier/services/types/giftCard';
import { find, first, get } from 'lodash';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import GiftCodeInput from './GiftCodeInput';
import PassValueForm from './PassValueForm';
import { KB_TYPES, MULTI_PAYMENT_TYPES, multiPaymentConfigs } from './constants';
import { calcMaxPayment, IFormMultiPaymentValues } from './helpers';
type Props = {
  active?: boolean,
  onFocus: (type: KB_TYPES) => () => void;
  type: MULTI_PAYMENT_TYPES,
  inputRef: React.RefObject<DollarAmountInputRef>;
  checkNoRef?: React.RefObject<InputRef>;
  giftRef?: React.RefObject<InputRef>;
  button?: boolean;
  onClick?: () => void;
  label?: string;
};
const PaymentInputItem = ({ label, type, inputRef, onFocus, active, checkNoRef, button, onClick, giftRef }: Props) => {
  const config = get(multiPaymentConfigs, type);
  const form = Form.useFormInstance();

  const TotalContent = useCallback(() => {
    return (
      <TotalContentStyled>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            switch (type) {
              case MULTI_PAYMENT_TYPES.CARD: {
                const amount = getFieldValue(MULTI_PAYMENT_TYPES.CARD);
                const cardFee = getFieldValue('cardFee');
                return <span>{formatCurrency(amount ? amount + cardFee : 0)}</span>;
              }
              case MULTI_PAYMENT_TYPES.CASH: {
                const amount = getFieldValue(MULTI_PAYMENT_TYPES.CASH);
                const discount = getFieldValue('cashIncentiveValue');
                return <span>{formatCurrency(amount ? amount - discount : 0)}</span>;
              }
              case MULTI_PAYMENT_TYPES.CHECK: {
                const amount = getFieldValue(MULTI_PAYMENT_TYPES.CHECK);
                const discount = getFieldValue('checkIncentiveValue');
                return <span>{formatCurrency(amount ? amount - discount : 0)}</span>;
              }
              default: {
                const amount = getFieldValue(type);
                return <span>{formatCurrency(amount)}</span>;
              }
            }
          }}
        </Form.Item>
      </TotalContentStyled>
    );
  }, [type]);
  const SubContent = useCallback(() => {
    switch (type) {
      case MULTI_PAYMENT_TYPES.POINT:
        return <SubContentStyled>
          <Form.Item shouldUpdate noStyle dependencies={[
            MULTI_PAYMENT_TYPES.POINT, 'availablePoint', 'availablePointExchange',
            'redeemPoint', 'balancePoint', 'balancePointExchange',
          ]}>
            {({ getFieldsValue }) => {
              const values = getFieldsValue([
                MULTI_PAYMENT_TYPES.POINT, 'availablePoint', 'availablePointExchange',
                'redeemPoint', 'balancePoint', 'balancePointExchange',
              ]);

              return <div>
                <FeeRow className='point' style={{ justifyContent: 'center' }}>
                  <span>Available</span>
                </FeeRow>
                <FeeRow className='point' style={{ justifyContent: 'center' }}>
                  <span>{values.availablePoint} points = {formatCurrency(values.availablePointExchange)}</span>
                </FeeRow>
                {!!values.LOYALTY_POINT && <>
                  <FeeRow className='point' style={{ justifyContent: 'center' }}>
                    <span>Balance</span>
                  </FeeRow>
                  <FeeRow className='point' style={{ justifyContent: 'center' }}>
                    <span>{Math.round(values.balancePoint)} points = {formatCurrency(values.balancePointExchange)}</span>
                  </FeeRow>
                </>}
              </div>;
            }}
          </Form.Item>
        </SubContentStyled>;
      case MULTI_PAYMENT_TYPES.GIFT_CARD:
        return <SubContentStyled>
          <Form.Item noStyle name={'giftCardCode'}>
            <Input
              placeholder="Code"
              ref={giftRef}
              // @ts-ignore
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onFocus={onFocus('GIFT_CODE')}
            />
          </Form.Item>
          <GiftCodeInput />
          <PassValueForm
            name={'giftCardAvailable'}
            render={(available: IResultGiftCardV3['available']) => {
              return <FeeRow className='point'><span>Available:</span> <span>{formatCurrency(available)}</span></FeeRow>;
            }}
          />
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const available = getFieldValue('giftCardAvailable');
              const amount = getFieldValue(MULTI_PAYMENT_TYPES.GIFT_CARD);
              const giftCardId = getFieldValue('giftCardId');
              const balance = available ? available - amount : 0;
              if (!giftCardId) return null;
              return <FeeRow className={clsx('point', balance < 0 && 'minus')}><span>Balance:</span> <span>{formatCurrency(balance)}</span></FeeRow>;
            }}
          </Form.Item>
        </SubContentStyled>;
      case MULTI_PAYMENT_TYPES.GIFT_CREDIT:
        return <SubContentStyled>
          <Form.Item noStyle name={'giftCreditCode'} rules={[
            ({ getFieldValue }) => ({
              async validator(_, value) {
                const amount = getFieldValue(MULTI_PAYMENT_TYPES.GIFT_CREDIT);
                if (amount > 0) {
                  if (!value) return Promise.reject('Please enter Gift Credit No');
                }
                return Promise.resolve();
              }
            }),
          ]}>
            <Input
              placeholder="Code"
              ref={giftRef}
              // @ts-ignore
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onFocus={onFocus('GIFT_CREDIT_CODE')}
            />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>{({ getFieldsError }) => {
            const errors = getFieldsError().filter(o => o.errors.length > 0).map(o => ({ errors: o.errors, name: first(o.name) }));
            const error = first(find(errors, o => o.name === 'giftCreditCode')?.errors) || '';
            return <p className='ant-form-item-explain-error'>{error}</p>;
          }}</Form.Item>
        </SubContentStyled>;
      case MULTI_PAYMENT_TYPES.CHECK:
        return <SubContentStyled>
          <Form.Item noStyle name={['otherCheckInfo', 'checkNumber']} rules={[
            ({ getFieldValue }) => ({
              async validator(_, value) {
                const amount = getFieldValue(MULTI_PAYMENT_TYPES.CHECK);
                if (amount > 0) {
                  if (!value) return Promise.reject('Please enter check No');
                }
                return Promise.resolve();
              }
            }),
          ]}>
            <Input
              ref={checkNoRef}
              placeholder="No."
              // @ts-ignore
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onFocus={onFocus('CHECK_NO')}
            />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>{({ getFieldsError }) => {
            const errors = getFieldsError().filter(o => o.errors.length > 0).map(o => ({ errors: o.errors, name: first(o.name) }));
            const error = first(find(errors, o => o.name === 'otherCheckInfo')?.errors) || '';
            return <p className='ant-form-item-explain-error'>{error}</p>;
          }}</Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const percent = getFieldValue('incentivePercent');
              const amount = getFieldValue('checkIncentiveValue');
              return <FeeRow><span>Discount {percent ? `${percent}%` : ''}:</span> <span>{formatCurrency(-amount)}</span></FeeRow>;
            }}
          </Form.Item>
        </SubContentStyled>;
      case MULTI_PAYMENT_TYPES.CASH:
        return <SubContentStyled>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const percent = getFieldValue('incentivePercent');
              const amount = getFieldValue('cashIncentiveValue');
              return <FeeRow><span>Discount {percent ? `${percent}%` : ''}:</span> <span>{formatCurrency(-amount)}</span></FeeRow>;
            }}
          </Form.Item>
        </SubContentStyled>;
      case MULTI_PAYMENT_TYPES.CARD:
        return <SubContentStyled>
          <PassValueForm
            name={'cardFeeLabel'}
            render={(cardFeeLabel: IFormMultiPaymentValues['cardFeeLabel']) => {
              return <FeeRow><span>Fee {cardFeeLabel?.percent ? `${cardFeeLabel?.percent}%` : ''}:</span> <span>{formatCurrency(cardFeeLabel?.amount)}</span></FeeRow>;
            }}
          />
        </SubContentStyled>;

      default:
        return <></>;
    }
  }, [type]);

  if (!config) return null;

  return (
    <Container onClick={onClick} className={clsx('payment-item', active && 'active', button && 'button')}>
      {button && <div className='overlay'></div>}
      <div className="payment-label">
        <img src={config.icon} alt={config.label} style={{ width: 48, height: 48 }} />
        <span>{label || config.label}</span>
      </div>
      {type === MULTI_PAYMENT_TYPES.POINT ? (
        <Form.Item shouldUpdate noStyle dependencies={['availablePointExchange', 'availablePoint', 'detail']}>
          {({ getFieldValue }) => {
            const total = getFieldValue(['detail', 'total']);
            const availablePointExchange = getFieldValue('availablePointExchange');
            const maxValue = availablePointExchange > total ? total : availablePointExchange;
            const availablePoint = getFieldValue('availablePoint');
            return <Form.Item name={MULTI_PAYMENT_TYPES.POINT} style={{ alignSelf: 'stretch' }} rules={[
              ({ }) => ({
                validator(_, value) {
                  if (value && value > maxValue) {
                    return Promise.reject(new Error(`Value cannot exceed ${formatCurrency(maxValue)}`));
                  }
                  return Promise.resolve();
                },
              }),
            ]}>
              <DollarInputPayment
                ref={inputRef}
                inputMode='none'
                onFocus={onFocus(type)}
                disabled={!availablePoint}
              />
            </Form.Item>;
          }}
        </Form.Item>
      ) : (
        <Form.Item shouldUpdate noStyle dependencies={['detail']}>
          {({ getFieldsValue }) => {
            const maxValue = Math.round(calcMaxPayment(getFieldsValue(), type) * 100) / 100;
            return (
              <Form.Item style={{ alignSelf: 'stretch' }} name={type} rules={[
                ({ }) => ({
                  validator(_, value) {
                    if (value && value > maxValue) {
                      return Promise.reject(new Error(`Value cannot exceed ${formatCurrency(maxValue)}`));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}>
                <DollarInputPayment
                  ref={inputRef}
                  inputMode='none'
                  onFocus={onFocus(type)}
                  onChange={() => form.validateFields([type])}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      )}
      <SubContent />
      <TotalContent />
    </Container>
  );
};

export default PaymentInputItem;
const TotalContentStyled = styled.div`
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-top: 1px solid #CCD4DC;
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
  span {
    align-self: stretch;
    text-align: center;
    color: #1D2129;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
const SubContentStyled = styled.div`
  flex: 1;
  background: #F6F7FC;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  margin-top: 0px;
  padding: 8px 16px;
  border-top: 1px solid #CCD4DC;
  .ant-input {
    display: flex;
    height: 56px;
    padding: 0px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #86909C;
    background: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    &::placeholder {
      color: #1D2129;
      opacity: 1;
    }

    &::-ms-input-placeholder {
      color: #1D2129;
    }

    &:disabled {
      color: #1D2129;
    }
    &[aria-invalid="true"] {
      border-radius: 4px;
      border: 1px solid #D2464F;
      background: #FFF;
      box-shadow: 0px 0px 0px 3px rgba(208, 51, 63, 0.10);
    }
  }
`;
const FeeRow = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  padding: 0;
  height: 56px;
  &.point {
  height: auto;
  }
  span {
    color: #1D2129;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &.minus {
    span {
      color: red;
    }
  }
`;
const Container = styled.div`
&.payment-item {
    .ant-form-item-explain-error {
      text-align: center;
      color: #f53f3f;
    }
    width: 270px;
    display: flex;
    align-self: stretch;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
    position: relative;
    z-index: 1;
    &.button:hover {
      cursor: pointer;
    }
    .overlay {
      position: absolute;
      z-index:99;
      inset: 0;
    }
    div.payment-label {
      display: flex;
      align-items: center;
      gap: 4px;
      height: 42px;
      align-self: stretch;
      justify-content: center;
      margin-bottom: 12px;
      padding-top: 8px;
      span {
        color: #C84B31;
        text-align: center;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
      }

      &.button:hover {
        cursor: pointer;
      }
    }

    .dollar-input-selector {
      margin: 0 16px;
      align-self: stretch;
      height: 3.5rem;
      justify-content: center;
      .dollar-input-text {
        font-size: 32px;
        color: #1D2129;
        font-weight: 600;
      }

      &[aria-disabled="true"], &[aria-disabled="true"] input {
        background: #eee;
        opacity: 0.8;
      }
    }
  }
`;
