import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Button from 'components/Button';
import Modal from 'components/Modal';
import ticketListActions from 'features/ticketList/services/actions';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import ticketListSelectors from 'features/ticketList/services/selectors';
import moment from 'moment';
import CurrencyKeyBoard, {
  CurrencyKeyBoardRef,
} from 'components/NumberKeyBoard/Currency';
import Box from 'components/Box';
import Text from 'components/Text';
import { InputWithKeyboardStyled, useDollarAmountInputRef } from 'components/DollarAmount/Input';
import DollarAmountKeyboard from 'components/DollarAmount/Keyboard';
import { formatCurrency } from 'utils/formatCurrency';
type IRefundButtonProps = { v2?: boolean, hook?: boolean, maxAmount: number, disabled?: boolean };
type Ref = {
  open: () => void;
};
export const useRefundButtonRef = () => useRef<Ref>(null);
const RefundButton = forwardRef<Ref, IRefundButtonProps>(({ v2, maxAmount, disabled: _disabled, hook }, ref) => {
  const { id } = useParams();
  const ticketDetails = ticketListSelectors.ticketDetailsData.data();
  const currentTime = moment();
  const endTime = moment(ticketDetails?.endTime);
  const difference = endTime.diff(currentTime, 'hours');
  const disableButton = difference <= 24;
  const disabled = _disabled || ticketDetails?.billStatus !== 'CLOSED' && disableButton;
  const dispatch = useAppDispatch();
  const inputRefV2 = useDollarAmountInputRef();
  const currencyRef = useRef<CurrencyKeyBoardRef>(null);
  const [openRefund, setOpenRefund] = useState(false);
  const [refundValue, setRefundValue] = useState<number | null | undefined>();

  const handleShowRefundModal = () => {
    setOpenRefund(true);
    setTimeout(() => {
      currencyRef.current?.setValue(maxAmount?.toFixed(2));
      inputRefV2.current?.init(maxAmount?.toString());
    }, 100);
  };
  const handleCloseRefundModal = () => {
    currencyRef.current?.hideError();
    setRefundValue(0);
    setOpenRefund(false);
  };

  const handleSubmitRefund = () => {
    const payload = {
      billId: id || '',
      amount: Number(refundValue),
    };
    dispatch(ticketListActions.refundTicketByBillId.fetch(payload));
    handleCloseRefundModal();
  };

  useEffect(() => {
    if (refundValue && openRefund && refundValue > maxAmount) {
      currencyRef.current?.showError(
        `Max refund must be $${maxAmount?.toFixed(2)}`
      );
    } else {
      currencyRef.current?.hideError();
    }
  }, [refundValue, openRefund]);

  useImperativeHandle(ref, () => ({
    open: handleShowRefundModal,
  }));

  return (
    <>
      {!hook && <Button
        disabled={disabled}
        ntype="ORANGE"
        icon="refundMoney"
        onClick={handleShowRefundModal}
      >
        Refund
      </Button>}
      <Modal
        modalTitle={'REFUND'}
        visible={openRefund}
        onClose={handleCloseRefundModal}
        onSubmit={handleSubmitRefund}
        destroyOnClose={true}
        disableOk={!!(refundValue && refundValue > maxAmount)}
        v2={v2}
        noneBodyStyle={v2}
        width={v2 ? 900 : undefined}
        containerPadding={v2 ? 1 : undefined}
      >
        {v2 ? <>
          <Box display='flex' flexDirection='column' gap='2'>
            <Text className='modal-keyboard-label' mt={1} mb={-0.5}>
              Max amount: {formatCurrency(maxAmount)}
            </Text>
            <InputWithKeyboardStyled
              ref={inputRefV2}
              value={refundValue}
              onChange={setRefundValue}
              inputMode='none'
              autoFocus
              maxValue={maxAmount}
            />
            <DollarAmountKeyboard
              controlRef={inputRefV2}
              value={refundValue}
              onChange={setRefundValue}
              maxValue={maxAmount}
            />
          </Box>
        </> :
          <CurrencyKeyBoard
            ref={currencyRef}
            value={refundValue}
            onChange={setRefundValue}
          />}
      </Modal>
    </>
  );
});
RefundButton.displayName = 'RefundButton';
export default RefundButton;
