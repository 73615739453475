import { Form } from 'antd';
import Button from 'components/Button';
import ModalSMS, { IModalSMSFuncFinish, useModalSMSRef } from 'components/ModalSMS';
import paymentApis from 'features/payment/services/apis';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import toast from 'utils/toast';

type Props = {
  billId?: string;
  defaultSMSPhone?: string;
  small?: boolean;
  v2?: boolean;
  hook?: boolean;
};
type Ref = {
  open: () => void;
};
export const useButtonTicketSendSMSRef = () => useRef<Ref>(null);
const ButtonTicketSendSMS = forwardRef<Ref, Props>(({ hook, v2, billId, defaultSMSPhone, small }, ref) => {
  const setLoadingPage = useSetLoadingPage();
  const [smsForm] = Form.useForm();
  const smsRef = useModalSMSRef();
  const onFormSMSFinish: IModalSMSFuncFinish = async (values) => {
    if (!billId) return;
    smsRef.current?.close();
    setLoadingPage(true);
    try {
      const res = await paymentApis.sendSMSEmailReceipt(billId, 'SMS', values.phone?.replace(/[^0-9]/g, ''));
      if (res.data.data) {
        toast.success(res.data?.message || 'Success');
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  const onOpen = () => smsRef.current?.open(defaultSMSPhone || '');

  useImperativeHandle(ref, () => ({
    open: onOpen,
  }));

  return (
    <>
      {!hook && <Button cardCashier={v2} size={small ? 'small' : undefined} icon='sendSms' ntype='YELLOW' onClick={onOpen} >SMS</Button>}
      <ModalSMS v2={v2} ref={smsRef} form={smsForm} onFinish={onFormSMSFinish} />
    </>
  );
});
ButtonTicketSendSMS.displayName = 'ButtonTicketSendSMS';
export default ButtonTicketSendSMS;