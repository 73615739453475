import { Layout } from 'antd';
import Sidebar from 'components/layout/Sidebar';
import styled from 'styled-components';
import LeftDetail from './LeftDetail';
import RightDetail from './RightDetail';
import settingStaffSelectors from 'features/settingStaff/services/selectors';

const SettingStaffPageV2 = () => {
  const targetStaff = settingStaffSelectors.selectedStaffData.data();
  return (
    <Container>
      <Sidebar />
      <ContentContainer>
        <LeftDetail />
        {targetStaff && <RightDetail />}
      </ContentContainer>
    </Container >
  );
};

export default SettingStaffPageV2;

const Container = styled(Layout)` 
    min-height: 100vh;
`;

const ContentContainer = styled.div`
display: flex;
flex: 1;
align-items: flex-start;
padding: 0;
background: #f5f5f5;
overflow: auto;
`;
