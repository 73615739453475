import { Layout } from 'antd';
import Sidebar from 'components/layout/Sidebar';
import React from 'react';
import styled from 'styled-components';
import TicketDetailHeader from './Header';
import TicketInfoDetail from './TicketInfoDetail';
import TicketPaymentDetail from './TicketPaymentDetail';

const TicketDetailV2 = () => {
  return (
    <Container>
      <Sidebar />
      <BodyContainer>
        <TicketDetailHeader />
        <TicketInfoContainer>
          <TicketInfoDetail />
          <TicketPaymentDetail />
        </TicketInfoContainer>
      </BodyContainer>
    </Container>
  );
};

export default TicketDetailV2;
const Container = styled(Layout)` 
  min-height: 100vh;
`;
const BodyContainer = styled.div`
display: flex;
flex: 1;
flex-direction: column;
align-items: flex-start;
gap: 16px;
background: #F5F5F5;
`;

const TicketInfoContainer = styled.div`
  display: flex;
  align-self: stretch;
  flex: 1;
`;