import IconLogo from 'assets/Icons/logo';
import Box from 'components/Box';
import Text from 'components/Text';
import Sidebar from 'components/layout/Sidebar';
import reportActions from 'features/report/services/actions';
import reportSelectors from 'features/report/services/selectors';
import React, { useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency, formatNumber } from 'utils/formatCurrency';
import { ReportType } from '../constants';
import { renderTime } from '../utils';
import EmployeeTableV2 from './EmployeeTable/EmployeesV2';
import { minusValue } from './EmployeeTable/calc';
import IncomePrintHeader from '../components/IncomePrintHeader';
import PaymentTypesUIPrinter from './PaymentTypesUIPrinter';
import shopSelectors from 'services/shop/selectors';
import { capitalize, find } from 'lodash';
import { PAYMENT_TYPE } from 'features/payment/services/constants';

type IReportPrintPageProps = any;
const ReportPrintPageV2: React.FC<IReportPrintPageProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const divEmployees = useRef<HTMLDivElement>(null);

  const dataSummarySalon = reportSelectors.getPrintSummarySalon();
  const dataAppointment = reportSelectors.getParams()?.[ReportType.SALON];
  const versionUI = shopSelectors.data.versionUI();
  const payments = shopSelectors.data.orderPaymentMethods();
  const giftCreditLabel = useMemo(() => find(payments, (o) => o.methodType === PAYMENT_TYPE.GIFT_CREDIT)?.name || 'Gift Credit', [payments]);
  const v2 = versionUI === 'V2';

  useEffect(() => {
    if (!dataAppointment) return;
    dispatch(reportActions.getPrintSummarySalon.fetch({
      startDate: dataAppointment.startTime,
      endDate: dataAppointment.endTime
    }));
    dispatch(reportActions.getPrintSummaryPaymentTypes.fetch({
      startDate: dataAppointment.startTime,
      endDate: dataAppointment.endTime
    }));
  }, [dataAppointment]);

  const crossSaleList = [
    { name: 'Services', price: dataSummarySalon?.saleItem?.totalSaleService },
    { name: 'Total Services', price: dataSummarySalon?.totalServices, number: true },
    { name: 'Products', price: dataSummarySalon?.saleItem?.totalSaleProduct },
    { name: 'Sell Gift Cards', price: dataSummarySalon?.saleItem?.totalSaleGiftCard },
    { name: 'VIP Programs', price: dataSummarySalon?.saleItem?.totalSaleVIP },
    { name: 'Sale Taxes', price: dataSummarySalon?.feeItem?.saleTax },
    { name: 'Use Taxes', price: dataSummarySalon?.feeItem?.useTax },
    { name: 'Supply Fees', price: dataSummarySalon?.supplyFee },
  ];
  // const crossSaleListSecond = [
  //   { name: 'Sale Taxes', price: dataSummarySalon?.feeItem?.saleTax },
  //   { name: 'Use Taxes', price: dataSummarySalon?.feeItem?.useTax },
  //   { name: 'Owner Discount', price: dataSummarySalon?.ownerDiscount },
  // ];
  //TODO : update
  // const crossSaleListThird = [
  //   { name: '#123 - Students', price: '20' },
  //   { name: '#124 - Seniors', price: '10' },
  //   { name: '#125 - Military', price: '10' },
  //   { name: 'Discount', price: '10' },
  // ];
  // const crossSaleListFour = [

  //   { name: 'Loyalty Redeem', price: dataSummarySalon?.shopRedeemPoint },
  // ];
  const lostList = [
    { name: 'Staff Discounts', price: dataSummarySalon?.staffDiscount },
    { name: 'Refunds', price: dataSummarySalon?.totalRefund },
    { name: 'Voids', price: dataSummarySalon?.totalVoid },
    { name: 'Redeemed Gift Cards', price: dataSummarySalon?.paymentItem?.totalGiftCard },
    { name: 'Redeemed Loyalty Points ', price: dataSummarySalon?.paymentItem?.totalRedeemPoint },
  ];
  const staticList = [
    { name: 'Number Of Active Tickets', price: dataSummarySalon?.statistics?.numberTicket },
    { name: 'Number Of Voided Tickets', price: dataSummarySalon?.statistics?.numberTicketVoid },
    { name: 'Average Ticket', price: formatCurrency(dataSummarySalon?.statistics?.averageAmount) },
    { name: 'Return Customers', price: dataSummarySalon?.statistics?.returnCustomer },
    { name: 'Walk - In Customers', price: dataSummarySalon?.statistics?.totalWalkIn },
  ];

  const onBack = () => {
    navigate(-1);
  };
  const divRef = useRef<HTMLDivElement>(null);
  const onPrint = useReactToPrint({
    content: () => divRef.current,
    copyStyles: true,
  });

  const handlePrintOverview = () => {
    if (!divEmployees.current) {
      // @ts-ignore
      divEmployees.current.style.display = 'block';
    }
    setTimeout(() => onPrint(), 1);
  };

  const onPrintSummary = useReactToPrint({
    content: () => divRef.current,
    copyStyles: true,
    onAfterPrint() {
      if (!divEmployees.current) return;
      // @ts-ignore
      divEmployees.current.style.display = 'block';
    },
  });

  const handlePrintSummary = () => {
    if (!divEmployees.current) return;
    // @ts-ignore
    divEmployees.current.style.display = 'none';
    setTimeout(() => onPrintSummary(), 1);
  };
  const paymentTypesRef = useRef<HTMLDivElement>(null);
  const onPrintPaymentTypes = useReactToPrint({
    content: () => paymentTypesRef.current,
    copyStyles: true,
  });

  const handlePrintSummaryPaymentType = () => {
    if (!divEmployees.current) return;
    setTimeout(() => onPrintPaymentTypes(), 1);
  };

  return (
    <IReportPrintPage>
      <Sidebar />
      <ContainerStyled>
        <IncomePrintHeader v2={v2} onPrintSummaryPaymentType={handlePrintSummaryPaymentType} onBack={onBack} onPrint={handlePrintOverview} onPrintSummary={handlePrintSummary} />
        <div style={{ display: 'none' }}><PaymentTypesUIPrinter dataAppointment={dataAppointment} divRef={paymentTypesRef} /></div>
        <div ref={divRef}>
          <Box className="center" overflow="auto">
            <TicketBox>
              <LogoBox>
                <IconLogo />
              </LogoBox>
              <Box className="center">
                <Text printMode variant="CONTENT_2" color="text_2">
                  {renderTime(dataAppointment)}
                </Text>
              </Box>
              <Box>
                <CrossSales>
                  <Box className="space-between" bb="print_line" highlight>
                    <Text printMode variant="CAPTION_2" color="text_3" className='title-highlight fs-lg'>
                      Gross Sales
                    </Text>
                    <Text printMode variant="CAPTION_2" color="text_3" className='text-highlight fs-lg'>
                      {formatCurrency(dataSummarySalon?.saleItem?.crossSales)}
                    </Text>
                  </Box>
                  <Box
                    px="1"
                    display="flex"
                    flexDirection="column"
                    bb="print_line"
                  >
                    {crossSaleList.map((item, index) => (
                      <Box key={index} className="space-between">
                        <Text printMode variant="CAPTION_2" color="text_3">
                          {item.name}
                        </Text>
                        <Text printMode variant="CAPTION_2" color="text_3">
                          {item.number ? formatNumber(item.price) : formatCurrency(item.price)}
                        </Text>
                      </Box>
                    ))}
                  </Box>
                  <Text mb={0.5} />
                  <Box
                    display="flex"
                    flexDirection="column"
                  >
                    <Box px="1">
                      <Box className="space-between">
                        <Text printMode variant="CAPTION_2" color="text_3">
                          Credit Card
                        </Text>
                        <Text printMode variant="CAPTION_2" color="text_3">
                          {formatCurrency(dataSummarySalon?.paymentItem?.totalCreditCard)}
                        </Text>
                      </Box>
                      <Box bt="print_line" bb="print_line" bStyle="dotted">
                        <Box pl="3">
                          <Box className="space-between">
                            <Text printMode variant="CAPTION_2" color="text_3">
                              Credit Card Processing
                            </Text>
                            <Text printMode variant="CAPTION_2" color="text_3">
                              {formatCurrency(dataSummarySalon?.paymentItem?.saleCreditCard)}
                            </Text>
                          </Box>
                          <Box className="space-between">
                            <Text printMode variant="CAPTION_2" color="text_3">
                              Card Fees
                            </Text>
                            <Text printMode variant="CAPTION_2" color="text_3">
                              {formatCurrency(dataSummarySalon?.paymentItem?.feeCreditCard)}
                            </Text>
                          </Box>
                          <Box className="space-between">
                            <Text printMode variant="CAPTION_2" color="text_3">
                              Total Tips
                            </Text>
                            <Text printMode variant="CAPTION_2" color="text_3">
                              {formatCurrency(dataSummarySalon?.paymentItem?.tipCreditCard)}
                            </Text>
                          </Box>
                          <Box className="space-between">
                            <Text printMode variant="CAPTION_2" color="text_3">
                              Tip Fee
                            </Text>
                            <Text printMode variant="CAPTION_2" color="text_3">
                              {formatCurrency(dataSummarySalon?.totalTipFee)}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                      <Box display="flex" flexDirection="column">
                        <Box className="space-between">
                          <Text printMode variant="CAPTION_2" color="text_3">
                            Cash
                          </Text>
                          <Text printMode variant="CAPTION_2" color="text_3">
                            {formatCurrency(dataSummarySalon?.paymentItem?.totalCash)}
                          </Text>
                        </Box>
                        <Box className="space-between">
                          <Text printMode variant="CAPTION_2" color="text_3">
                            Gift Card
                          </Text>
                          <Text printMode variant="CAPTION_2" color="text_3">
                            {formatCurrency(dataSummarySalon?.paymentItem?.totalGiftCard)}
                          </Text>
                        </Box>
                        <Box className="space-between">
                          <Text printMode variant="CAPTION_2" color="text_3">
                            Checks
                          </Text>
                          <Text printMode variant="CAPTION_2" color="text_3">
                            {formatCurrency(dataSummarySalon?.paymentItem?.totalCheck)}
                          </Text>
                        </Box>
                        <Box className="space-between">
                          <Text printMode variant="CAPTION_2" color="text_3" style={{ textTransform: 'capitalize' }}>
                            {capitalize(giftCreditLabel)}
                          </Text>
                          <Text printMode variant="CAPTION_2" color="text_3">
                            {formatCurrency(dataSummarySalon?.paymentItem?.totalGiftCredit)}
                          </Text>
                        </Box>
                        <Box className="space-between">
                          <Text printMode variant="CAPTION_2" color="text_3">
                            Loyalty Point
                          </Text>
                          <Text printMode variant="CAPTION_2" color="text_3">
                            {formatCurrency(dataSummarySalon?.paymentItem?.totalRedeemPoint)}
                          </Text>
                        </Box>
                        <Box display="flex" flexDirection="column">
                          <Box className="space-between">
                            <Text printMode variant="CAPTION_2" color="text_3">
                              Other(s)
                            </Text>
                            <Text printMode variant="CAPTION_2" color="text_3">
                              {formatCurrency(dataSummarySalon?.paymentItem?.totalOther)}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </CrossSales>
                <Text mb={0.5} />

                <Box >
                  { /* px="4" <-- Chỉnh khoảng cách viền bill ở đây (1 unit = 4px) */}
                  <Box px="1">
                    <Text mb={0.5} />
                    <Box display="flex" flexDirection="column">
                      <Box className="space-between" highlight>
                        <Text printMode variant="CAPTION_2" color="text_3" className='title-highlight fs-lg'>
                          Deductions
                        </Text>
                        <Text printMode variant="CAPTION_2" color="text_3" className='text-highlight fs-lg'>
                          {minusValue(dataSummarySalon.deductions)}
                        </Text>
                      </Box>
                      <Box className="space-between">
                        <Text printMode variant="CAPTION_2" color="text_3">
                          Company Discounts
                        </Text>
                        <Text printMode variant="CAPTION_2" color="text_3">
                          {formatCurrency(dataSummarySalon?.ownerDiscount)}
                        </Text>
                      </Box>
                      <Box bt="print_line" bb="print_line" bStyle="dotted">
                        <Box pl="3">
                          <Box className="space-between">
                            <Text printMode variant="CAPTION_2" color="text_3">
                              Manual
                            </Text>
                            <Text printMode variant="CAPTION_2" color="text_3">
                              {formatCurrency(dataSummarySalon?.discountManual)}
                            </Text>
                          </Box>
                          <Box className="space-between">
                            <Text printMode variant="CAPTION_2" color="text_3">
                              Coupons
                            </Text>
                            <Text printMode variant="CAPTION_2" color="text_3">
                              {formatCurrency(dataSummarySalon?.discountCoupon)}
                            </Text>
                          </Box>
                          <Box className="space-between">
                            <Text printMode variant="CAPTION_2" color="text_3">
                              Cash Incentives
                            </Text>
                            <Text printMode variant="CAPTION_2" color="text_3">
                              {formatCurrency(dataSummarySalon?.discountCashIncentive)}
                            </Text>
                          </Box>
                        </Box>
                      </Box>

                      {lostList.map((item, index) => (
                        <Box key={index} className="space-between">
                          <Text printMode variant="CAPTION_2" color="text_3">
                            {item.name}
                          </Text>
                          <Text printMode variant="CAPTION_2" color="text_3">
                            {formatCurrency(item.price)}
                          </Text>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
                <Text mb={.5} />
                { /* px="1" <-- Chỉnh khoảng cách viền bill ở đây (1 unit = 4px) */}
                <Box px="1" >
                  <Box className="space-between" highlight bt="print_line" bb="print_line">
                    <Text printMode variant="CAPTION_2" color="text_3" className='text-highlight title-highlight fs-lg'>
                      Total Net Sale
                    </Text>
                    <Text printMode variant="CAPTION_2" color="text_3" className='title-highlight fs-lg'>
                      {formatCurrency(dataSummarySalon?.netSales)}
                    </Text>
                  </Box>
                </Box>

                <Text mb={1} />
                {/* Statistics */}
                <Box>
                  { /* px="4" <-- Chỉnh khoảng cách viền bill ở đây (1 unit = 4px) */}
                  <Box px="1">
                    <Box bb="print_line" highlight>
                      <Text printMode variant="CAPTION_2" color="text_3" className='title-highlight'>
                        Statistics
                      </Text>
                    </Box>
                    <Text mb={0.5} />
                    <Box display="flex" flexDirection="column">
                      {staticList.map((item, index) => (
                        <Box key={index} className="space-between">
                          <Text printMode variant="CAPTION_2" color="text_3">
                            {item.name}
                          </Text>
                          <Text printMode variant="CAPTION_2" color="text_3">
                            {item.price}
                          </Text>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
                <Text mb={.5} />
                {/* Employee */}
                <div ref={divEmployees}>
                  <EmployeeTableV2 dataSummarySalon={dataSummarySalon} />
                </div>
              </Box>
            </TicketBox>
          </Box>
        </div>
      </ContainerStyled>
    </IReportPrintPage>
  );
};
const IReportPrintPage = styled.div`
  display: flex;
`;
const TicketBox = styled.div`
  border-radius: 20px;
  background: #fff;
  width: 19.5rem;
  height: fit-content;
  padding: 1.5rem 0 2rem;
`;

const ContainerStyled = styled.div`
  background-color: #e5e5e5;
  height: 100vh;
  position: relative;
  flex-grow: 1;
  width: 100%;
  overflow: auto;
  .text-highlight {
    background: black;
    color: white !important;
  }
`;
const LogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

const CrossSales = styled.div`
/* 5px 4px <-- Chỉnh khoảng cách viền bill ở đây  */
  padding: 5px 4px; 
`;

export default ReportPrintPageV2;
