import Box from 'components/Box';
import React from 'react';
import styled from 'styled-components';
import bg_fake_visa from './BGFakeCard.svg';
import chip_icon from './chip.svg';
import visa_card_icon from './visa-card-icon.png';

type FakeVisaProps = {
  number: string;
  cardHolderName: string;
  expires: string;
  v2?: boolean;
};
const FakeVisa: React.FC<FakeVisaProps> = ({
  number,
  cardHolderName,
  expires, v2
}) => {

  return (
    <FakeVisaStyled className={v2 ? 'v2' : ''}>
      {v2 && <div className="bg-fake-visa" style={{ backgroundImage: `url(${bg_fake_visa})` }} />}
      <Box className="details-container">
        {v2 ?
          <img src={chip_icon} alt="chip_icon" style={{ width: '60.486px', height: 'auto' }} /> :
          <VisaMainTextStyled>VISA</VisaMainTextStyled>}
        <VisaNumberTextStyled>{number}</VisaNumberTextStyled>
        <Box className="space-between">
          <Box>
            <VisaItemTextStyled>CARD HOLDER</VisaItemTextStyled>
            <TitleTextStyled>{cardHolderName}</TitleTextStyled>
          </Box>
          <Box>
            <VisaItemTextStyled>EXPIRES</VisaItemTextStyled>
            <TitleTextStyled>{expires}</TitleTextStyled>
          </Box>
          {v2 && <Box>
            <img src={visa_card_icon} alt='visa_card_icon' style={{ height: 32, width: 'auto' }} />
          </Box>}
        </Box>
      </Box>
      {!v2 && <svg
        width="417"
        height="243"
        viewBox="0 0 417 243"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.483643"
          width="417"
          height="242.847"
          rx="9.67517"
          fill="url(#paint0_linear_938_121586)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_938_121586"
            x1="208.984"
            y1="0"
            x2="208.984"
            y2="242.847"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF8890" />
            <stop offset="1" stopColor="#FF9AC1" />
          </linearGradient>
        </defs>
      </svg>}
    </FakeVisaStyled>
  );
};

export default FakeVisa;
const FakeVisaStyled = styled.div`
  position: relative;
  width: 417px;
  height: 243px;

  .details-container {
    position: absolute;
    top: 0;
    left: 0;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &.v2 {
    border-radius: 27.055px;
    overflow: hidden;
    .bg-fake-visa {
      position: absolute;
      inset: 0;
      background-position: center;
      background-size: cover;
    }
  
  }
`;

const VisaTextStyled = styled.div`
  color: #fff;
  font-family: Open Sans;
  font-style: normal;
  line-height: normal;
  font-size: 23.22px;
`;
const VisaMainTextStyled = styled(VisaTextStyled)`
  font-weight: 700;
`;
const VisaNumberTextStyled = styled(VisaTextStyled)`
  font-weight: 600;
  letter-spacing: 2.322px;
`;

const VisaItemTextStyled = styled(VisaTextStyled)`
  font-size: 15.48px;
  font-weight: 400;
`;
const TitleTextStyled = styled(VisaTextStyled)`
  font-weight: 400;
`;
