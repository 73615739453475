import React from 'react';

const TicketWrapperContext = React.createContext<{
    print: (billId: string) => Promise<void>;
    printWithoutCoupon: (billId: string) => Promise<void>;
    completedTicket: (billId: string, useLoading?: boolean) => Promise<void>;
    printMultiCards: (billId: string) => Promise<void>;
    completedMultiCards: (billId: string, useLoading?: boolean) => Promise<void>;
    doPrintNoReceipt: (billId: string, useLoading?: boolean) => Promise<void>;
}>({
    print: () => new Promise(() => undefined),
    printWithoutCoupon: () => new Promise(() => undefined),
    completedTicket: () => new Promise(() => undefined),
    printMultiCards: () => new Promise(() => undefined),
    completedMultiCards: () => new Promise(() => undefined),
    doPrintNoReceipt: () => new Promise(() => undefined),
});

export const useTicketWrapper = () => React.useContext(TicketWrapperContext);

export default TicketWrapperContext.Provider;